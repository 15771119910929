var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: '90%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10","order":"1"}},[_c('atoms-title',[_vm._v(_vm._s(!_vm.id ? 'Tambah' : 'Perbarui')+" Testimoni")])],1),_c('v-col',{attrs:{"cols":"10","md":"5","order":"1","order-md":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return _c('div',{},[_c('atoms-image',{staticClass:"rounded-lg",staticStyle:{"background":"var(--v-accent-base)"},attrs:{"contain":true,"maxH":300,"src":(_vm.picture && _vm.picture.url) || '/assets/no-preview.svg'}},[_c('v-overlay',{attrs:{"value":!_vm.$vuetify.breakpoint.smAndDown && _vm.picture && _vm.picture.url ? (hover ? true : false) : true,"opacity":"0.5","absolute":""}},[_c('div',{staticClass:"pa-12",style:({
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                      })},[_c('atoms-text',{staticClass:"font-weight-medium",on:{"click":function($event){return _vm.openUploader('picture')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-camera ")]),_vm._v(_vm._s(!_vm.picture ? 'Upload' : 'Ganti')+" Foto")],1),_c('atoms-text',{staticClass:"font-weight-medium mt-4",on:{"click":function($event){_vm.picture &&
                            _vm.picture.url &&
                            _vm.$viewerApi({
                              options: {
                                toolbar: false,
                              },
                              images: [_vm.picture.url],
                            })}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-eye ")]),_vm._v("Perbesar")],1)],1)])],1),_c('input',{ref:"picture",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onPictureChange}})],1)}}])}),_c('atoms-button',{staticClass:"primary mt-4",staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.openUploader('picture')}}},[_vm._v("Ganti Foto Cover")])],1),_c('v-col',{attrs:{"cols":"10","md":"5","order":"2","order-md":"1","align":"center"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('atoms-text-field',{attrs:{"label":"Judul Testimonial","rules":_vm.rules.default},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('atoms-text-field',{attrs:{"label":"Caption Atau URL Youtube","rules":_vm.rules.caption},model:{value:(_vm.caption),callback:function ($$v) {_vm.caption=$$v},expression:"caption"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('atoms-text-field',{attrs:{"label":"Deskripsi"}},[_c('v-textarea',{staticClass:"rounded-lg",attrs:{"background-color":"accent","placeholder":"Deskripsi Testimonial","loader-height":"100","hide-details":"","dense":"","solo":"","flat":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10","order":"6"}},[_c('atoms-button',{class:['primary', 'mb-4'],style:({ width: '100%' }),attrs:{"type":"submit","loading":_vm.$store.state.loading}},[_vm._v("Simpan")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }