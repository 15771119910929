import Vue from 'vue'
import * as Realm from 'realm-web'
import jwt_decode from 'jwt-decode'
import provinces from '@/constant/provinces'
import cities from '@/constant/cities'
import kecamatan from '@/constant/kecamatan.type'
// import kelurahan from '@/constant/kelurahan.type'
import companyType from '@/constant/company-type'
import education from '@/constant/education.type'
import bank from '@/constant/bank.type'
import statusMarried from '@/constant/statusMarried.type'
import store from '../store'
import queryString from 'query-string'
import * as uuid from 'uuid'
import moment from 'moment'
import qrcode from 'qrcode'
import { io } from 'socket.io-client'
import Swal from 'sweetalert2'
const xlsx = require('json-as-xlsx')
// import "moment/locale/id"

moment.locale('id')

let result
for (const [key, value] of Object.entries(cities)) {
  result = {
    ...result,
    [key]: value.map((e) => e.label),
  }
}

let accountName
for (const [key, value] of Object.entries(bank)) {
  accountName = {
    ...accountName,
    [value.label]: value.value,
  }
}
const filter = {
  belum: 'Belum diproses',
  semua: 'Semua',
  proses: 'Proses',
  approve: 'Approved',
  reject: 'Rejected',
}
export const socket = io(process.env.VUE_APP_SOCKET)
export const roles = {
  All: 'All',
  Super_Admin: 'Super Admin',
  Manager: 'Manager',
  Admin: 'Admin',
  Finance: 'Finance',
  Staff: 'Staff',
  Sales: 'Sales',
  Area_Coordinator: 'Area Coordinator',
  Freelance: 'Freelance',
  Client: 'Client',
  Worker: 'Worker',
}
export const clientRoles = {
  Admin: 'Admin',
  Supervisor: 'Supervisor',
}
Vue.use({
  install() {
    Vue.prototype.$semua = 'Semua'
    Vue.prototype.$emitter = new Vue()
    Vue.prototype.$socket = socket
    Vue.prototype.$qrcode = qrcode
    Vue.prototype.$swal = Swal
    Vue.prototype.$chaining = (param) => eval(param)
    Vue.prototype.$isArrayExists = (payload) => Array.isArray(payload) && payload?.length > 0
    Vue.prototype.$getSafe = (data, fn) => {
      try {
        return fn().bind(data)
      } catch (e) {}
    }
    Vue.prototype.$toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    Vue.prototype.$isNumber = function (value) {
      if (value === null) return false
      if (value === undefined) return false
      if (isNaN(value)) return false
      if (value === '') return false
      value = Number(value)
      return typeof value === 'number' && isFinite(value)
    }
    Vue.prototype.$splitArray = function (arr, rows) {
      const itemsPerRow = Math.ceil(arr.length / rows)
      return arr.reduce((acc, val, ind) => {
        const currentRow = Math.floor(ind / itemsPerRow)
        if (!acc[currentRow]) {
          acc[currentRow] = [val]
        } else {
          acc[currentRow].push(val)
        }
        return acc
      }, [])
    }
    Vue.prototype.$salaryStatus = ['per month', 'per day']
    Vue.prototype.$postStatus = ['Published', 'Drafted', 'Closed']
    Vue.prototype.$contactStatus = {
      // Dihubungi: "Dihubungi",
      // Terima: "Terima",
      // Tolak: "Tolak",
      Contacted: 'Contacted',
      Not_Contacted: 'Not Contacted',
      Accepted: 'Accepted',
      Rejected: 'Rejected',
    }
    Vue.prototype.$postCategory = [
      'Pencari Kerja',
      'Pemberi Kerja',
      'Job',
      'Business',
      'Entertain',
      'K-Pop',
      'Travel',
      'Tech',
      'Lifestyle',
      'Health',
      'Food',
      'International',
    ]
    Vue.prototype.$salesStatus = {
      all: 'All',
      paid: 'Paid',
      outstanding: 'Outstanding',
    }
    Vue.prototype.$salesStatus = {
      prospect: 'Prospect',
      client: 'Client',
    }
    Vue.prototype.$salesStage = {
      prospect: 'Prospect',
      negotiation: 'Negotiation',
      proses: 'Proses PKS',
      kandidat: 'Cari Kandidat PHL',
      won: 'Won',
      lost: 'Lost',
    }
    Vue.prototype.$roles = roles
    Vue.prototype.$clientRoles = clientRoles
    Vue.prototype.$console = console
    Vue.prototype.$window = window
    Vue.prototype.$areIncludes = (payload, compare, strict = false) => {
      if (Array.isArray(payload) && Array.isArray(compare)) {
        let matches = 0
        for (let it = 0; it < compare.length; it++) {
          if (payload?.includes(compare[it])) {
            matches++
          }
        }
        if (matches === payload?.length || (strict && matches > 0)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
    Vue.prototype.$areOutcludes = (payload, compare, strict = false) => {
      if (Array.isArray(payload) && Array.isArray(compare)) {
        let matches = 0
        for (let it = 0; it < compare.length; it++) {
          if (!payload?.includes(compare[it])) {
            matches++
          }
        }
        if (matches === payload?.length || (strict && matches > 0)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
    Vue.prototype.$encodeObject = (obj) => {
      const str = JSON.stringify(obj)
      return window.btoa(str)
    }
    Vue.prototype.$decodeObject = (encodedText) => {
      const str = window.atob(encodedText)
      return JSON.parse(str)
    }
    Vue.prototype.$roleList = [
      {
        label: 'Super Admin (Semua Page)',
        value: roles.Super_Admin,
      },
      {
        label: 'Manager (Semua Page)',
        value: roles.Manager,
      },
      {
        label: 'Admin (Semua Page)',
        value: roles.Admin,
      },
      {
        label: 'Finance (Page Salary Weeks)',
        value: roles.Finance,
      },
      {
        label: 'Staff (Page Artikel, Promosi, Lowongan, FAQ, Refferals)',
        value: roles.Staff,
      },
      {
        label: 'Sales (Page Client, Kuotasi, Invoice, Sales, Artikel, Promosi, Lowongan, FAQ, Referrals)',
        value: roles.Sales,
      },
      {
        label: 'Area Coordinator (Page Worker, Absensi, Kasus Lapangan)',
        value: roles.Area_Coordinator,
      },
      {
        label: 'Freelance (Page Blog)',
        value: roles.Freelance,
      },

      {
        label: 'Client',
        value: roles.Client,
      },
      {
        label: 'Worker',
        value: roles.Worker,
      },
    ]
    Vue.prototype.$cases = {
      working: 'Working on it',
      stuck: 'Stuck',
      done: 'Done',
    }
    Vue.prototype.$requestPaymentType = {
      Regular: 'Regular',
      EWA: 'EWA',
    }
    Vue.prototype.$days = ['Semua', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']
    Vue.prototype.$inputType = {
      sprinter: 'Sprinter',
      processing: 'Processing',
    }
    Vue.prototype.$statusInvoice = {
      semua: 'Semua',
      lunas: 'Lunas',
      belum: 'Belum Lunas',
    }
    Vue.prototype.$gender = {
      Male: 'Laki-Laki',
      Female: 'Perempuan',
    }
    Vue.prototype.$bank = bank.map((e) => e.value)
    Vue.prototype.$accountName = accountName
    Vue.prototype.$workerTypes = ['Casual', 'Fulltime', 'Medical Courier']
    Vue.prototype.$companyType = companyType
    Vue.prototype.$cities = result
    Vue.prototype.$provinces = provinces.map((e) => e.value)
    Vue.prototype.$kecamatan = Object.assign(
      {},
      ...Object.entries(kecamatan).map(([key, value]) => ({
        [key]: value?.map((_item) => _item.label),
      })),
    )
    // Vue.prototype.$kelurahan = Object.assign(
    //   {},
    //   ...Object.entries(kelurahan).map(([key, value]) => ({
    //     [key]: value?.map((_item) => _item.label),
    //   })),
    // )
    Vue.prototype.$decoder = jwt_decode
    Vue.prototype.$status = filter
    Vue.prototype.$queryString = queryString
    Vue.prototype.$uuid = uuid
    Vue.prototype.$moment = moment
    Vue.prototype.$education = Object.values(education).map((x) => x.value)
    Vue.prototype.$statusMarried = Object.values(statusMarried).map((x) => x.value)
    Vue.prototype.$isRoles = (roles = []) => {
      const currentRoles = store.state.user?.roles || []
      return currentRoles.some((val) => roles.includes(val))
    }
    // realm
    Vue.prototype.$realm = () => {
      const REALM_APP_ID = process.env.VUE_APP_REALM_APP_ID
      const REALM_KEY = process.env.VUE_APP_REALM_KEY
      const app = new Realm.App({ id: REALM_APP_ID })
      const credentials = Realm.Credentials.apiKey(REALM_KEY)
      const realm = app.logIn(credentials)
      return realm
    }
    Vue.prototype.$xlsx = xlsx
    // calc
    Vue.prototype.$toCapitalize = (str, lower = true) =>
      str ? (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase()) : ''
    Vue.prototype.$idCurrency = (
      num,
      minDecimalDigit = 0,
      maxDecimalDigit = 10, //10
      hide = false,
      isUseBracket = false,
    ) => {
      num = Math.ceil(Number(num))
      let displayNum = num.toLocaleString('id', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: minDecimalDigit,
        maximumFractionDigits: maxDecimalDigit,
      })
      if (num == 0) {
        displayNum = 'Rp 0'
      }
      // hide Rp symbol
      // use bracket for negatif value
      if (isUseBracket) {
        if (/^- \d+/g.test(displayNum)) {
          displayNum = displayNum.replace(/^- /g, '')
          displayNum = `(${displayNum})`
        }
      }
      displayNum = !isNaN(num) ? displayNum : 'Rp 0'
      if (hide) {
        displayNum = displayNum.replace('Rp', '')
      }
      return displayNum
    }
    Vue.prototype.$addSeparator = (num, minDecimalDigit = 0, maxDecimalDigit = 0, isUseBracket = false) => {
      num = Number(num)
      let displayNum = num.toLocaleString('id', {
        minimumFractionDigits: minDecimalDigit,
        maximumFractionDigits: maxDecimalDigit,
      })
      if (displayNum === '-0') {
        displayNum = 0
      }
      // use bracket for negatif value
      if (isUseBracket) {
        if (/^-\d+/g.test(displayNum)) {
          displayNum = displayNum.replace(/^-/g, '')
          displayNum = `(${displayNum})`
        }
      }
      return !isNaN(num) ? displayNum : 0
    }
    Vue.prototype.$removeSeparator = (strNum) => {
      if (typeof strNum === 'string') {
        return Number(strNum.replace(/\./gi, '').replace(/,/gi, '.')) || null
      }
      return strNum
    }
    Vue.prototype.$separator = (strVal) => {
      return strVal
        ? strVal
            .replace(/\D/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            .trim()
        : ''
    }
    // Vue.prototype.$removeSeparator = (strNum) => {
    //   if (typeof strNum === "string") {
    //     return Number(strNum.replace(/\./gi, "").replace(/,/gi, ".")) || null;
    //   }
    //   return strNum;
    // };
    Vue.prototype.$convertRupiahtoNumber = (val) => {
      if (typeof val === 'string' && /Rp/gi.test(val)) {
        val = val.replace(/[a-zA-Z]/gi, '')
        val = val.replace(/\s/gi, '')
        val = val.replace(/\./gi, '')
        val = val.replace(/(,)/gi, '.')
        return Number(val) || 0
      }
      return val
    }
    Vue.prototype.$convertDecimaltoPercent = (num, fixed = 2) => {
      if (num) {
        num = Number(num)
        const numHundred = num * 100
        num = numHundred % 1 !== 0 ? numHundred.toFixed(fixed) : numHundred
        return (num + '%').replace('.', ',')
      }
      return '0%'
    }
    Vue.prototype.$convertPercenttoDecimal = (percent) => {
      if (isNaN(percent)) {
        percent = percent?.replace(/%/gi, '')
        percent = percent?.replace(',', '.')
        return Number(percent) / 100
      }
      return percent || 0
    }
    Vue.prototype.$convertWithPPH = (value, pph) => {
      return value / (1 - Number(pph) / 100) || 0
    }
    Vue.prototype.$thousandSeparator = (value) =>
      value ? value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : value || 0
    Vue.prototype.$autoFillQuoNum = async (clientId, date, clientCode, isInvoice = false, noQuo) => {
      // Validation
      if (!clientId) {
        return ''
      }
      try {
        // Auto fill Quotation Number
        const y = date.getFullYear()
        const m = date.getMonth()
        const startMonth = new Date(new Date(new Date(y, m, 1)).setHours(0))
        const endMonth = new Date(new Date(y, m + 1, 0).setHours(24))
        const getSerialNumber = (quoNumber) => {
          let serialNumber = quoNumber?.replace(/^.{2}/gm, '')
          serialNumber = serialNumber?.replace(/\/.+/gm, '')
          serialNumber = Number(serialNumber)
          return serialNumber
        }
        let serialNumber = 1
        // console.log('noquo', getSerialNumber(noQuo), noQuo)
        if (noQuo) {
          serialNumber = getSerialNumber(noQuo)
        } else {
          serialNumber = await Vue.prototype.$api
            .get(`Quotations/count`, {
              params: {
                jsonQuery: JSON.stringify({
                  date: {
                    $gte: startMonth,
                    $lte: endMonth,
                  },
                }),
              },
            })
            .then((response) => {
              let no = (response?.result || 0) + 1
              return no
            })

          // console.log(serialNumber)
        }
        let strSerialNumber = ((serialNumber || 1) + '').padStart(2, '0')
        const month = m + 1
        const strMonth = (month + '').padStart(2, '0')
        const tahun = date.getFullYear()
        const variety = !isInvoice ? 'QUO' : 'INV'
        const _result = (_strSerialNumber) => `${strMonth}${_strSerialNumber}/${variety}/${clientCode || ''}/${tahun}`

        return _result(strSerialNumber)
      } catch (err) {
        return Promise.reject(err)
      }
    }
    Vue.prototype.$filtering = (payload) => {
      // {
      //   workerId,
      //   dateStart,
      //   dateEnd,
      //   statusClient,
      //   areaId,
      //   locationId,
      //   $lookup,
      //   $lookup2,
      //   pipeline: [],
      // }
      const query = {
        // phlJamSelesai: { $exists: true, $ne: "" },
        phlJamMulai:
          payload?.dateStart && payload?.dateEnd
            ? {
                $gte: payload?.dateStart,
                $lte: payload?.dateEnd,
              }
            : undefined,
        pipeline: [
          {
            $sort: { phlJamMulai: -1 },
          },
        ],
      }
      // const query =
      // {
      //   // phlJamSelesai: { $exists: true, $ne: "" },
      //   phlJamMulai: {
      //     $gte: dateStart,
      //     $lte: dateEnd,
      //   },
      //   pipeline: [
      //     {
      //       $sort: { phlJamMulai: -1 },
      //     },
      //   ],
      // }

      if (payload?.$lookup) {
        query.pipeline.push({
          $lookup: payload.$lookup,
        })
      }
      if (payload?.$lookup2) {
        query.pipeline.push({
          $lookup2: payload.$lookup2,
        })
      }
      if (payload?.workerId) {
        query.ktpPhl = payload.workerId
      }
      if (payload?.areaId) {
        query.areaId = payload.areaId
      }
      if (payload?.locationId) {
        if (locationId !== 'Semua') {
          query.locationId = payload.locationId
        }
      }
      if (payload?.statusClient) {
        if (payload.statusClient === filter.approve) {
          query.supervisorApproval = filter.approve
        }
        if (payload.statusClient === filter.reject) {
          query.supervisorApproval = filter.reject
        }
        if (payload.statusClient === filter.proses) {
          query.supervisorApproval = { $exists: false }
        }
      }
      return JSON.stringify(query)
    }
  },
})
