<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '80%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="12" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
        <v-col v-if="$isRoles('Super_Admin', 'Admin', 'Manager')" cols="12" align="center">
          <v-tabs
            background-color="accent"
            color="primary"
            height="40"
            v-model="tabs"
            hide-slider
            :show-arrows="$vuetify.breakpoint.smAndDown ? true : false"
            :center-active="$vuetify.breakpoint.smAndDown ? true : false"
            class="rounded-lg d-flex justify-center align-center px-0 mb-5"
          >
            <v-tab
              v-for="i in ['General', 'Saldo']"
              :key="i"
              class="text-capitalize"
              active-class="primary lighten-5 primary--text rounded-lg ma-1"
            >
              <atoms-text class="font-weight-medium text-capitalize">{{ $toCapitalize(i) }}</atoms-text>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-container>
        <v-form v-if="tabs === 0" ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>Edit Client</atoms-title>
            </v-col>
            <v-col cols="10">
              <div>
                <v-avatar
                  color="accent"
                  size="250"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-overlay :value="true" opacity="0.5" absolute>
                    <div @click="!readOnly && openUploader" class="pa-12" :style="{ width: '100%', height: '100%' }">
                      <atoms-text v-show="!readOnly" class="font-weight-medium"
                        ><v-icon small> mdi-pencil </v-icon>Upload Logo</atoms-text
                      >
                      <input ref="uploader" type="file" accept="image/*" class="d-none" @change="onPhotoChange" />
                    </div>
                  </v-overlay>
                  <atoms-image
                    v-if="logo && logo.url"
                    :lazy-src="`/lazy-img.webp`"
                    aspect-ratio="1"
                    :src="logo.url || `/lazy-img.webp`"
                  />
                  <v-icon v-else> mdi-account </v-icon>
                </v-avatar>
                <atoms-text class="py-4">Logo Maks. 3mb (Bila ada)</atoms-text>
              </div>

              <v-row class="mt-4">
                <!-- row 1 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Nama Perusahaan"
                    :readonly="readOnly"
                    v-model="name"
                    :rules="[
                      (v) => !!v || 'Nama harap diisi!',
                      (v) => (!!v && duplicate.name !== v) || 'Nama sudah terdaftar!',
                    ]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Kode Perusahaan"
                    :readonly="readOnly"
                    v-model="code"
                    v-mask="'AAAAAA'"
                    :rules="[
                      (v) => !!v || 'Harap diisi!',
                      (v) => (v && v.length >= 4) || 'Minimal 4 Huruf!',
                      (v) => (v && v.length <= 6) || 'Maksimal 6 Huruf!',
                      (v) => /^[A-Z]{1,6}/.test(v) || 'Huruf Kapital!',
                    ]"
                  />
                </v-col>

                <!-- row 2 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Nama Kontak"
                    :readonly="readOnly"
                    v-model="contactPerson.name"
                    :rules="[(v) => !!v || 'Harap disii!']"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Email"
                    v-model="contactPerson.email"
                    :readonly="readOnly"
                    :rules="[
                      (v) => !!v || 'Email harap diisi!',
                      (v) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email tidak valid',
                      (v) => (!!v && duplicate.email !== v) || 'Email sudah terdaftar!',
                    ]"
                  />
                </v-col>

                <!-- row 3 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Nomor Telepon"
                    :mask="'############'"
                    v-model="contactPerson.phone"
                    :readonly="readOnly"
                    type="number"
                    :rules="[
                      (v) => !!v || 'Nomor harap diisi!',
                      (
                        v, ///^(62|0)8[1-9][0-9]{9,12}$/
                      ) => /^\d{9,12}/.test(v) || 'Nomor tidak valid (Contoh: 6287765347111/089666347816)',
                    ]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Alamat "
                    v-model="address"
                    :readonly="readOnly"
                    :rules="[(v) => !!v || 'Harap disii!']"
                  />
                </v-col>

                <!-- row 4 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field label="NPWP (Bila Ada)" :readonly="readOnly" v-model="npwp" :rules="[]" />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field label="Tipe Perusahaan">
                    <v-autocomplete
                      class="rounded-lg"
                      placeholder="Tipe Perusahaan"
                      :items="$companyType"
                      v-model="companyType"
                      :menu-props="{ offsetY: true }"
                      :readonly="readOnly"
                      :rules="[(v) => !!v || 'Harap disii!']"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>
                </v-col>
                <!-- row 4 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="URL Youtube Client (Bila Ada)"
                    v-model="socials.yt.url"
                    :readonly="readOnly"
                    :rules="[]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="URL Instagram Client (Bila Ada)"
                    v-model="socials.ig.url"
                    :readonly="readOnly"
                    :rules="[]"
                  />
                </v-col>
                <!-- row 5 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="URL Website Client (Bila Ada)"
                    v-model="website"
                    :readonly="readOnly"
                    :rules="[]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="URL Facebook Client (Bila Ada)"
                    v-model="socials.fb.url"
                    :readonly="readOnly"
                    :rules="[]"
                  />
                </v-col>
                <v-col cols="12" class="py-0">
                  <atoms-text-field>
                    <v-textarea
                      class="rounded-lg"
                      :readonly="readOnly"
                      v-model.trim="description"
                      placeholder="Contoh: Perusahaan bergerak dibidang apa dan kapan terbentuknya, dsb."
                      flat
                      solo
                    />
                  </atoms-text-field>
                </v-col>
              </v-row>
              <atoms-button
                v-if="!readOnly"
                :loading="$store.state.loading"
                type="submit"
                :style="{ width: '100%' }"
                class="primary mt-4"
                >Simpan</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
        <div v-else class="text-left mx-8 lg:mx-16">
          <div :class="['d-flex justify-space-between', $vuetify.breakpoint.smAndDown && 'flex-column ']">
            <div>
              <atoms-title>Saldo : {{ $idCurrency(currentBalance || 0) }} </atoms-title>
              <atoms-text class="mt-3 primary--text"
                >Terakhir diperbarui
                {{ `${$moment().format('DD/MM/YYYY, HH:mm:ss')} ${$moment().locale('en').format('A')}` }}</atoms-text
              >
            </div>
            <atoms-button @click="fetchBalance" class="primary mt-5">
              <v-icon>mdi-refresh</v-icon> Refresh</atoms-button
            >
          </div>
          <input ref="tandaTerima" type="file" accept="image/*, pdf/*" class="d-none" @change="onTandaTerimaChange" />

          <v-row class="mt-5" align="center">
            <v-col cols="12"><atoms-text-field label="Catatan" v-model="desc" /></v-col>
            <v-col cols="12"
              ><atoms-text-field
                label="Jumlah Saldo"
                v-model="balance"
                prefix="Rp."
                :rules="[(v) => !!v || 'Harap disii!']"
            /></v-col>
            <v-col @click="openWindow(getUrl)" cols="12" lg="10" align="center">
              <div style="cursor: pointer">
                <atoms-text-field placeholder="Upload tanda pembayaran" hide-detail readonly :value="getUrl" />
              </div>
            </v-col>
            <v-col cols="12" lg="2" align="end">
              <atoms-button @click="$refs.tandaTerima.click()" style="width: 100%" class="primary">Upload</atoms-button>
            </v-col>
            <v-col cols="12" lg="6">
              <atoms-button @click="updateBalance(false)" style="width: 100%" class="black white--text"
                >Tambah Saldo</atoms-button
              >
            </v-col>
            <v-col cols="12" lg="6">
              <atoms-button @click="updateBalance(true)" style="width: 100%" class="red white--text"
                >Kurangkan Saldo</atoms-button
              >
            </v-col>
          </v-row>

          <div v-if="historyBalance && historyBalance.length > 0" class="mt-10">
            <atoms-title h3 class="mb-5">Riwayat Transaksi</atoms-title>
            <div v-for="(x, i) in historyBalance.slice(0, limit)" :key="i" class="py-5 px-1">
              <v-row align="center">
                <v-col cols="8" md="6">
                  <atoms-text class="font-weight-bold">{{ x.desc || '-' }}</atoms-text>
                  <atoms-text class=""> {{ $moment(x._createdDate).locale('en').format('HH:mm:ss A') }}</atoms-text>
                </v-col>
                <v-col align="start">
                  <atoms-text class="primary--text">{{
                    (x.user.nickname && `Oleh ${x.user.nickname}`) || '-'
                  }}</atoms-text>

                  <atoms-text
                    v-if="x.fileTandaTerima && x.fileTandaTerima !== null"
                    style="cursor: pointer"
                    @click="openWindow(x.fileTandaTerima)"
                    class="red--text text-decoration-underline"
                    >Lihat tanda terima</atoms-text
                  >
                </v-col>
                <v-col cols="12" lg="auto" class="shrink">
                  <atoms-text>Rp.{{ $addSeparator(x.amount, 0, 0) }}</atoms-text>
                </v-col>
              </v-row>
              <v-divider class="mt-5"></v-divider>
            </div>
            <atoms-button
              :disabled="historyBalance.length <= limit"
              @click="
                () => {
                  if (historyBalance.length > limit) {
                    limit += 10
                  }
                }
              "
              style="width: 100%"
              >Lihat Lebih Banyak</atoms-button
            >
          </div>
        </div>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: {
    data: Object | Array,
    readOnly: Boolean,
  },
  data() {
    return {
      desc: null,
      balance: 0,
      limit: 10,
      historyBalance: null,
      currentBalance: 0,
      tabs: 0,
      logo: null,
      code: '',
      name: '',
      npwp: '',
      website: '',
      address: '',
      companyType: '',
      description: '',
      contactPerson: {
        name: '',
        phone: '',
        email: '',
      },
      fileTandaTerima: null,
      socials: {
        ig: {
          mediaName: 'instagram',
          url: '',
        },
        fb: {
          mediaName: 'facebook',
          url: '',
        },
        yt: {
          mediaName: 'youtube',
          url: '',
        },
      },
      duplicate: {
        name: '',
        email: '',
      },
      updatedBy: '',
      valid: true,
    }
  },
  computed: {
    getUrl() {
      return this.fileTandaTerima?.url || 'Upload tanda pembayaran'
    },
  },
  watch: {
    code: function (val) {
      if (val) {
        this.$nextTick(() => (this.code = String(val).toUpperCase()))
      }
    },
    balance: function (newValue) {
      const result = this.$separator(newValue) || '0'
      this.$nextTick(() => (this.balance = result))
    },
  },
  async mounted() {
    this.$store.commit('SET', { loading: true })
    if (this.data) {
      this.logo = {
        url: this.data?.logo,
      }
      this.code = this.data?.code
      this.name = this.data?.name
      this.npwp = this.data?.npwp
      this.website = this.data?.website
      this.address = this.data?.address?.streetAddress1
      this.companyType = this.data?.companyType
      this.description = this.data?.description
      this.contactPerson = {
        name: this.data?.contactPerson?.name,
        phone: this.data?.contactPerson?.phone,
        email: this.data?.contactPerson?.email,
      }
      this.socials = {
        ig: {
          mediaName: 'instagram',
          url: this.data?.socialMedia?.find((x) => x?.mediaName === this.socials.ig.mediaName)?.url,
        },
        fb: {
          mediaName: 'facebook',
          url: this.data?.socialMedia?.find((x) => x?.mediaName === this.socials.fb.mediaName)?.url,
        },
        yt: {
          mediaName: 'youtube',
          url: this.data?.socialMedia?.find((x) => x?.mediaName === this.socials.yt.mediaName)?.url,
        },
      }
      this.updatedBy = this.$store?.state?.user?._id
      document.documentElement.style.overflow = 'hidden'
      if (this.$isRoles('Super_Admin', 'Admin', 'Manager')) {
        await this.fetchBalance()
      }
    } else {
      this.$emit('closed')
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal memuat data`,
      })
    }
    this.$store.commit('SET', { loading: false })
  },
  beforeDestroy() {
    document.documentElement.style.overflow = 'auto'
  },
  methods: {
    openWindow(url) {
      if (url?.includes('http')) {
        window.open(url, '_blank')
      }
    },
    async onTandaTerimaChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran file adalah 3mb!`,
        })
      } else if (file) {
        this.fileTandaTerima = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.fileTandaTerima = null
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (file) {
        this.logo = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.logo = null
      }
    },
    async fetchBalance() {
      this.$store.commit('SET', { loading: true })
      const { result, ...rest } = await this.$api.get(`Clients/getBalance`, {
        params: {
          // jsonQuery: JSON.stringify({
          _id: this.data._id,
          // }),
        },
      })
      const { result: historyClient } = await this.$api.get(`ClientBalances/getHistory`, {
        params: {
          jsonQuery: JSON.stringify({
            clientId: this.data._id,
            pipeline: [
              {
                $sort: {
                  _createdDate: -1,
                },
              },
              {
                $lookup: {
                  from: 'Users',
                  localField: 'updatedBy',
                  foreignField: '_id',
                  as: 'user',
                },
              },
              {
                $unwind: {
                  path: '$user',
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $project: {
                  'user.password': 0,
                },
              },
            ],
          }),
        },
      })
      this.historyBalance = historyClient
      this.currentBalance = result
      this.$store.commit('SET', { loading: false })
    },
    async updateBalance(destruct) {
      this.$store.commit('SET', { loading: true })
      let balance = Math.abs(this.$removeSeparator(this.balance))
      if (this.data._id && this.$isRoles('Super_Admin', 'Admin', 'Manager') && balance > 0) {
        if (destruct) {
          balance = Number(this.currentBalance || 0) - balance
          if (balance < 0) {
            balance = 0
          }
        } else {
          balance += Number(this.currentBalance || 0)
        }

        if (
          window.confirm(
            'Apakah Anda yakin untuk memperbarui saldo? Saldo akhir dari ' +
              this.$idCurrency(this.currentBalance) +
              ' akan menjadi ' +
              this.$idCurrency(balance),
          )
        ) {
          const { result, success } = await this.$api.patch(
            'ClientBalances/update', //`Clients/updateBalance`,
            {
              _id: this.data._id,
              desc: this.desc,
              fileTandaTerima: this.fileTandaTerima?.image
                ? await this.$uploadFile(
                    this.fileTandaTerima.image,
                    `Clients/${this.data?._id}/Balance`,
                    `tanda-terima-${this.$uuid.v4()}`,
                  )
                : this.fileTandaTerima?.url || null,
              balance,
            },
          )

          if (success) {
            this.balance = 0
            this.desc = null
            this.fileTandaTerima = null
            await this.fetchBalance()
          }
        }
      } else {
        this.$showDialog({
          title: 'Perhatian',
          body: `Pastikan saldo tidak bernilai kosong`,
        })
      }
      this.$store.commit('SET', { loading: false })
    },

    // async kurangiSaldo() {
    //   this.$store.commit("SET", { loading: true })
    //   let balance =
    //     Number(this.currentBalance || 0) - this.$removeSeparator(this.balance)
    //   if (balance < 0) {
    //     balance = 0
    //   }
    //   if (this.data._id && this.$isRoles("Super_Admin", "Admin", "Manager")) {
    //     const { result, success } = await this.$api.patch(
    //       "ClientBalances/update", //`Clients/updateBalance`,
    //       {
    //         _id: this.data._id,
    //         balance,
    //         desc: this.desc,
    //       },
    //       {
    //         // params: {
    //         //   _id: this.data._id,
    //         //   balance,
    //         // },
    //       }
    //     )
    //     if (success) {
    //       this.balance = 0
    //       await this.fetchBalance()
    //     }
    //   }
    //   this.$store.commit("SET", { loading: false })
    // },

    async preValidate() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.contactPerson.email || '', $options: 'i' }
      try {
        if (this.contactPerson.email !== this.data?.contactPerson?.email) {
          this.contactPerson.email = this.contactPerson.email.trim()
          // this.contactPerson.email =
          //   this.contactPerson.email.split("@")[0].replaceAll(".", "") +
          //   "@" +
          //   this.contactPerson.email.split("@")[1]
          const { result: client } = await this.$api.get(`Clients/count`, {
            params: {
              jsonQuery: JSON.stringify({
                'contactPerson.email': term,
              }),
            },
          })
          const { result: user } = await this.$api.get(`Users/isExist`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          if (client > 0 || user) {
            this.duplicate.email = this.contactPerson.email
          } else {
            this.duplicate.email = ''
          }
        }
        if (this.name !== this.data?.name) {
          const check = await this.$api.get(`Clients/count`, {
            params: {
              jsonQuery: JSON.stringify({
                name: { $regex: this.name || '', $options: 'i' },
              }),
            },
          })
          if (check.result > 0) {
            this.duplicate.name = this.name
          } else {
            this.duplicate.name = ''
          }
        }
        await this.validate()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()
      try {
        if (!this.data?._id) {
          Promise.reject('Client tidak ditemukan!')
          return this.$emit('closed')
        }
        if (this.valid) {
          await this.$store.dispatch('client/update', {
            _id: this.data._id,
            name: this.name,
            code: this.code,
            companyType: this.companyType,
            npwp: this.npwp,
            description: this.description,
            website: this.website,

            address: {
              streetAddress1: this.address,
            },
            contactPerson: {
              name: this.contactPerson.name,
              phone: this.contactPerson.phone,
              email: this.contactPerson.email,
            },
            logo: this.logo?.image
              ? await this.$uploadFile(this.logo.image, `Clients/${this.data?._id}/Profil`, `picture`)
              : this.logo?.url || '',
            socialMedia: [this.socials.ig, this.socials.fb, this.socials.yt],
          })
          this.$showDialog({
            title: 'Berhasil',
            body: `${this.name} berhasil diperbarui`,
          })
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.fileTandaTerima = null
          this.$emit('closed')
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian`,
          })
        }
      } catch (error) {
        Promise.reject(error)
      }
    },
  },
}
</script>
