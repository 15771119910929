<template>
  <div
    id="dragdrop"
    @dragenter.prevent="dragenter"
    @dragover.prevent="dragOver"
    @dragleave="dragleave"
    @drop.stop.prevent="drop"
    :style="{
      position: 'absolute',
      top: 0,
      left: 0,
    }"
  >
    <input multiple ref="uploader" type="file" class="d-none" @change="onChange" />
    <v-overlay v-show="active" opacity="0.5" @click.stop="$emit('closed')" :dark="$vuetify.theme.dark">
      <div
        class="background d-flex justify-center align-center flex-column pa-12 rounded-lg"
        :style="{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
          'min-height': '10%',
        }"
      >
        <atoms-title>Lepaskan File Anda Disini</atoms-title>
        <atoms-image width="250" src="/assets/add-files.svg" class="ma-12" />
        <atoms-text v-if="file" class="my-2">
          {{ file.name }}
          <atoms-button :icon="true" @click="removeFile"> <v-icon x-small>mdi-close</v-icon></atoms-button></atoms-text
        >
        <atoms-button @click="$refs.uploader.click()" class="primary">Unggah File</atoms-button>
      </div>
    </v-overlay>
  </div>
</template>
<script>
export default {
  props: ['autoHide'],
  data() {
    return {
      file: null,
      active: false,
      counter: 0,
    }
  },
  created() {
    window.addEventListener('dragenter', this.dragenter)
    window.addEventListener('dragleave', this.dragleave)
    window.addEventListener('dragover', this.dragOver)
  },
  destroyed() {
    window.removeEventListener('dragenter', this.dragenter)
    window.removeEventListener('dragleave', this.dragleave)
    window.removeEventListener('dragover', this.dragOver)
  },
  methods: {
    removeFile() {
      this.$refs.uploader.value = ''
    },
    onChange() {
      this.file = {
        data: this.$refs.uploader.files[0],
        url: URL.createObjectURL(this.$refs.uploader.files[0]),
      }
      URL.revokeObjectURL(this.file)
      if (this.file.size < 60000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran file adalah 60mb!`,
        })
      }
      if (this.file.size < 60000000 || this.autoHide) {
        this.$emit('closed', this.file)
        this.active = false
      }
    },
    dragenter(event) {
      // Add some visual fluff to show the user can drop its files
      this.counter += 1
      if (!this.active) {
        this.active = true
      }
    },
    dragOver(event) {
      event.preventDefault()
    },
    dragleave(event) {
      this.counter -= 1
      if (this.counter == 0) {
        this.active = false
      }
    },
    drop(event) {
      this.$refs.uploader.files = event.dataTransfer.files
      this.onChange()
    },
  },
}
</script>
