var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('molecules-wrapper',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.preValidate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v("Member Baru")])],1),_c('v-col',{attrs:{"cols":"10"}},[(!_vm.$store.state.client.data || !_vm.client.enabled)?_c('atoms-text-field',{attrs:{"label":"Cari Client","placeholder":"Kosongkan untuk mencari semua client","rules":[]},model:{value:(_vm.client.term),callback:function ($$v) {_vm.$set(_vm.client, "term", $$v)},expression:"client.term"}}):_c('atoms-text-field',{attrs:{"label":"Pilih Client"}},[_c('v-combobox',{staticClass:"rounded-lg",attrs:{"item-text":"name","items":_vm.$store.state.client.data || [],"placeholder":"Pilih Client","menu-props":{ offsetY: true },"rules":[(v) => !!v || 'Harap disii!'],"dense":"","solo":"","flat":""},model:{value:(_vm.client.value),callback:function ($$v) {_vm.$set(_vm.client, "value", $$v)},expression:"client.value"}})],1),_c('atoms-button',{class:[!_vm.$store.state.client.data || !_vm.client.enabled ? 'primary' : 'red white--text', 'mb-4'],style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":() => {
                  if (!_vm.$store.state.client.data || !_vm.client.enabled) {
                    _vm.getClients()
                  } else {
                    _vm.client.term = ''
                    _vm.client.value = null
                    _vm.client.enabled = false
                  }
                }}},[_vm._v(_vm._s(!_vm.$store.state.client.data || !_vm.client.enabled ? 'Cari Client' : 'Reset Pencarian'))]),(_vm.client.value && _vm.client.enabled)?_c('div',{staticClass:"text-center my-4"},[_c('atoms-text',{staticClass:"my-1 font-weight-bold"},[_vm._v("Client Terpilih")]),_c('div',{staticClass:"pa-2 accent rounded-lg text-left"},[_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Nama:")]),_vm._v(" "+_vm._s(_vm.client.value.name))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Alamat:")]),_vm._v(" "+_vm._s(_vm.client.value.address.streetAddress1))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Area Terdaftar:")])]),_c('ul',_vm._l((_vm.client.area || []),function(x,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(x.title)+" "+_vm._s(x.inactive ? '(Tidak Aktif)' : '')+" ")])}),0)],1)],1):_vm._e()],1),(_vm.isShowArea)?_c('v-col',{attrs:{"cols":"10"}},[(!_vm.$store.state.area.data || !_vm.area.enabled)?_c('div',[_c('atoms-text-field',{attrs:{"label":"Cari Area","placeholder":"Biarkan kosong jika ingin cari semua","rules":[]},model:{value:(_vm.area.term),callback:function ($$v) {_vm.$set(_vm.area, "term", $$v)},expression:"area.term"}}),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":() => {
                    _vm.getAreas()
                    _vm.area.enabled = true
                  }}},[_vm._v("Cari Area")])],1):_c('div',[_c('atoms-text-field',{attrs:{"label":"Pilih Area"}},[_c('v-combobox',{staticClass:"rounded-lg",attrs:{"multiple":"","items":_vm.$store.state.area.data || [],"item-text":(area) => area.title + ' - ' + (area.inactive ? 'Tidak Aktif' : 'Aktif'),"prepend-inner-icon":"mdi-check-all","placeholder":"Pilih Area","menu-props":{ offsetY: true },"rules":[(v) => !!v || 'Harap disii!'],"dense":"","solo":"","flat":""},on:{"click:prepend-inner":() => {
                      if (_vm.$store.state.area.data && _vm.$store.state.area.data.length > 0)
                        _vm.area.value = _vm.$store.state.area.data
                    }},model:{value:(_vm.area.value),callback:function ($$v) {_vm.$set(_vm.area, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"area.value"}})],1),_c('atoms-button',{staticClass:"red white--text",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":() => {
                    _vm.getAreas()
                    _vm.area.term = ''
                    _vm.area.enabled = false
                  }}},[_vm._v("Reset Pencarian")])],1)]):_vm._e(),(_vm.isShowLocation)?_c('v-col',{attrs:{"cols":"10"}},[(!_vm.$store.state.location.data || !_vm.location.enabled)?_c('div',[_c('atoms-text-field',{attrs:{"label":"Cari Lokasi","placeholder":"Biarkan kosong jika ingin cari semua","rules":[]},model:{value:(_vm.location.term),callback:function ($$v) {_vm.$set(_vm.location, "term", $$v)},expression:"location.term"}}),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":() => {
                    _vm.getLocations()
                    _vm.location.enabled = true
                  }}},[_vm._v("Cari Lokasi")])],1):_c('div',[_c('atoms-text-field',{attrs:{"label":"Pilih Lokasi"}},[_c('v-combobox',{staticClass:"rounded-lg",attrs:{"multiple":"","items":_vm.$store.state.location.data || [],"item-text":(loc) => loc.name + ' - ' + (loc.areas && loc.areas.title) || loc.areaId,"prepend-inner-icon":"mdi-check-all","placeholder":"Pilih Lokasi","menu-props":{ offsetY: true },"rules":[(v) => !!v || 'Harap disii!'],"dense":"","solo":"","flat":""},on:{"click:prepend-inner":() => {
                      if (_vm.$store.state.location.data && _vm.$store.state.location.data.length > 0)
                        _vm.location.value = _vm.$store.state.location.data
                    }},model:{value:(_vm.location.value),callback:function ($$v) {_vm.$set(_vm.location, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"location.value"}})],1),_c('atoms-button',{staticClass:"red white--text",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":() => {
                    _vm.location.term = ''
                    _vm.location.enabled = false
                  }}},[_vm._v("Reset Pencarian")])],1)]):_vm._e(),(_vm.isShowUser)?_c('v-col',{attrs:{"cols":"10"}},[_c('v-container',{class:['pt-0 d-flex justify-center', _vm.$vuetify.breakpoint.smAndDown && 'px-0']},[_c('v-tabs',{staticClass:"rounded-lg d-flex justify-center",attrs:{"background-color":"accent","color":"primary","height":"40","hide-slider":"","show-arrows":_vm.$vuetify.breakpoint.smAndDown ? true : false,"center-active":_vm.$vuetify.breakpoint.smAndDown ? true : false},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((['Daftarkan', 'User Tersedia']),function(i){return _c('v-tab',{key:i,staticClass:"text-capitalize",attrs:{"active-class":"primary lighten-5 primary--text rounded-lg ma-1"}},[_c('atoms-text',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$toCapitalize(i)))])],1)}),1)],1),_c('div',[_c('v-fade-transition',[(_vm.tabs === 0)?_c('v-container',{staticClass:"px-0"},[_c('div',[_c('v-avatar',{style:({
                        cursor: 'pointer',
                      }),attrs:{"color":"accent","size":"150"}},[_c('v-overlay',{attrs:{"value":true,"opacity":"0.5","absolute":""}},[_c('div',{staticClass:"pa-12",style:({ width: '100%', height: '100%' }),on:{"click":_vm.openUploader}},[_c('atoms-text',{staticClass:"font-weight-medium"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v("Upload Foto")],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onPhotoChange}})],1)]),(_vm.photo && _vm.photo.url)?_c('atoms-image',{attrs:{"aspect-ratio":"1","src":_vm.photo.url || `/lazy-img.webp`}}):_c('v-icon',[_vm._v(" mdi-account ")])],1),_c('atoms-text',{staticClass:"py-4"},[_vm._v("Foto Maks. 3mb (Bila ada)")])],1),_c('atoms-text-field',{attrs:{"label":"Nama Pengguna","rules":[(v) => !!v || 'Harap disii!']},model:{value:(_vm.nickname),callback:function ($$v) {_vm.nickname=$$v},expression:"nickname"}}),_c('atoms-text-field',{attrs:{"label":"Email","rules":[
                      (v) => !!v || 'Email harap diisi!',
                      (v) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email tidak valid',
                      (v) => (!!v && this.duplicate !== v) || _vm.customEmailError || `Email sudah terdaftar`,
                    ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('atoms-text-field',{attrs:{"type":"password","label":"Password","rules":[
                      (v) => !!v || 'Harap disii!',
                      (v) => (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter',
                    ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_vm._e()],1),_c('v-fade-transition',[(_vm.tabs === 1)?_c('v-container',{staticClass:"px-0"},[(!_vm.$store.state.user.data || !_vm.user.enabled)?_c('atoms-text-field',{attrs:{"label":"Cari User","rules":[]},model:{value:(_vm.user.term),callback:function ($$v) {_vm.$set(_vm.user, "term", $$v)},expression:"user.term"}}):_c('atoms-text-field',{attrs:{"label":"Pilih User"}},[_c('v-combobox',{staticClass:"rounded-lg",attrs:{"items":_vm.$store.state.user.data || [],"item-text":(x) => x.nickname + ' → ' + x.email,"placeholder":"Pilih User","menu-props":{ offsetY: true },"rules":[(v) => !!v || 'Harap disii!'],"dense":"","solo":"","flat":""},model:{value:(_vm.user.value),callback:function ($$v) {_vm.$set(_vm.user, "value", $$v)},expression:"user.value"}})],1),_c('atoms-button',{class:[!_vm.$store.state.user.data || !_vm.user.enabled ? 'primary' : 'red white--text', 'mb-4'],style:({ width: '100%' }),attrs:{"disabled":!_vm.isShowUser,"loading":_vm.$store.state.loading},on:{"click":() => {
                        if (!_vm.$store.state.user.data || !_vm.user.enabled) {
                          _vm.getUsers()
                          _vm.client.term = ''

                          _vm.user.enabled = true
                        } else {
                          _vm.user.term = ''
                          _vm.user.value = null
                          _vm.user.enabled = false
                        }
                      }}},[_vm._v(_vm._s(!_vm.$store.state.user.data || !_vm.user.enabled ? 'Cari User' : 'Reset Pencarian'))]),(_vm.user.value && _vm.user.enabled)?_c('div',{staticClass:"text-center my-4"},[_c('atoms-text',{staticClass:"my-1 font-weight-bold"},[_vm._v("User Terpilih")]),_c('div',{staticClass:"pa-2 accent rounded-lg text-left"},[_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Nama:")]),_vm._v(" "+_vm._s(_vm.user.value.nickname))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.user.value.email))])],1)],1):_vm._e()],1):_vm._e()],1)],1),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"disabled":!_vm.isShowSubmit,"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v("Daftarkan Member")])],1):_vm._e()],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }