import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import client from './client'
import dialog from './dialog'
import area from './area'
import location from './location'
import worker from './worker'
import captain from './captain'
import member from './member'
import attendance from './attendance'
import quotation from './quotation'
import invoice from './invoice'
import cases from './cases'
import salesb2b from './salesb2b'
import promotion from './promotion'
import article from './article'
import vacancy from './vacancy'
import faq from './faq'
import redirects from './redirects'
import gmap from './gmap'
import requestPayments from './requestPayments'
import testimonial from './testimonial'
import headhunters from './headhunters'
import salaryWeek from './salaryWeek'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dark: localStorage.getItem('theme') === 'dark' ? true : false,
    loading: true,
    init: true,
    log: 'loading',

    // blast
    isReady: false,
    logs: 'Logs...',
    interval: null,
    chats: null,
    constant: {
      wa: 'creds-wa',
      chats: 'chats-wa',
      contacts: 'contacts-wa',
      br: 'broadcast-wa',
    },
    code: {
      default: '/media/default-qr.png',
      base: null,
    },
  },
  actions: {
    onConnect({ state, rootState }, payload) {
      state.loading = true
      state.logs = 'Logs...'
      this._vm.$socket.emit('login', {
        _id: rootState.user?._id,
        nickname: rootState.user?.nickname,
        avatar: rootState.user?.picture,
        creds: this._vm.$storage.getItem(state.constant.wa) || null,
      })
      state.loading = false
    },
    onLogout({ state, rootState }, payload) {
      state.loading = true
      state.logs = 'Logs...'
      this._vm.$storage.setItem(state.constant.wa, null)
      this._vm.$socket.emit('logout', { id: rootState.user?._id, ...payload })
      state.loading = false
    },
    startInterval({ state, dispatch }, payload) {
      let count = 0
      state.interval = setInterval(() => {
        let msg = '\nTrying to connect...'
        state.logs += msg
        this._vm.$socket.emit('status', msg)
        if (count > 10) {
          dispatch('stopInterval')
        }
        if (!this._vm.$socket.connected) {
          this._vm.$socket.connect()
        } else {
          dispatch('onConnect')
        }
        count++
      }, 5000)
    },
    stopInterval({ state }, payload) {
      clearInterval(state.interval)
      state.interval = null
    },
    SOCKET_connect({ dispatch }, payload) {
      dispatch('onConnect')
      dispatch('stopInterval')
    },
    SOCKET_error({ state }, error) {
      this._vm.$showDialog({
        title: 'Kesalahan',
        body:
          'Pastikan whatsapp mobile terhubung ke internet. Jika tetap gagal, coba untuk logout dan login kembali pada whatsapp blast : ' +
            error?.message || error,
      })
      state.loading = false
    },
    SOCKET_qr({ state }, payload) {
      state.code.base = payload
      state.logs += '\nRegenerating qr code'
    },
    SOCKET_disconnect({ state, dispatch }, payload) {
      // reset creds dan reconnect
      state.isReady = false
      state.code.base = null
      state.logs = 'Logs...'
      dispatch('startInterval')
      this._vm.$showDialog({
        title: 'Terputus',
        body: 'Koneksi dengan whatsapp terputus!',
      })
    },
    SOCKET_authenticated({ state }, payload) {
      state.logs = 'Logs...'
      this._vm.$storage.setItem(state.constant.wa, payload)
    },
    SOCKET_logout({ dispatch }, payload) {
      // sebatas remove persist di server
      dispatch('onLogout')
    },
    SOCKET_status({ state }, payload) {
      this._vm.$socket.emit('status', payload)
      state.logs += `\n${payload}`
      // this._vm.$showDialog({
      //   title: "Koneksi",
      //   body: payload,
      // });
    },
    SOCKET_ready({ state }, payload) {
      state.code.base = null
      state.isReady = payload
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    LOG(state, payload) {
      if (String(payload).includes(state.log)) {
        payload = 're-' + payload
      }
      state.log = payload || 'loading'
    },
    setTheme(state, payload) {
      const isDark = payload
      localStorage.setItem('theme', isDark ? 'dark' : 'light')
      state.dark = isDark
    },
  },
  modules: {
    user,
    client,
    dialog,
    area,
    location,
    worker,
    captain,
    member,
    attendance,
    quotation,
    invoice,
    cases,
    salesb2b,
    promotion,
    article,
    vacancy,
    faq,
    gmap,
    requestPayments,
    redirects,
    testimonial,
    headhunters,
    salaryWeek,
  },
})
