<template>
  <molecules-floating>
    <organism-attendance-position
      v-if="showEditPosition"
      :data="data"
      @close="
        (payload) => {
          showEditPosition = false
          if (payload) {
            data = {
              ...data,
              ...payload,
            }
          }
        }
      "
    />
    <organism-attendance-backdate
      v-if="showEditJamMulai"
      :data="data"
      @close="
        (payload) => {
          showEditJamMulai = false
          if (payload) {
            data = {
              ...data,
              ...payload,
            }
          }
        }
      "
    />
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="onClose"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <molecules-wrapper>
        <v-row justify="center">
          <v-col cols="10" class="py-0">
            <v-avatar color="grey lighten-3" size="100" class="rounded-lg">
              <atoms-image v-if="data.worker.photo" :width="100" :enableZoom="true" :src="data.worker.photo" />
              <v-icon v-else light>mdi-account</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="10" class="text-center">
            <atoms-title>{{ $toCapitalize(data.worker.fullName) }}</atoms-title>
            <atoms-text v-if="data.detail && data.detail.prefJobs" :span="true" class="px-4">{{
              data.detail.prefJobs.filter((x) => x.length > 0).toString()
            }}</atoms-text>
          </v-col>
          <v-col cols="10">
            <atoms-button
              @click="
                () => {
                  $viewerApi({
                    options: {
                      toolbar: false,
                    },
                    images: [data.phlSelfieMulai],
                  })
                }
              "
              :plain="true"
              class="primary--text"
              >Lihat Foto Absensi Mulai</atoms-button
            >
            <atoms-button
              @click="
                () => {
                  $viewerApi({
                    options: {
                      toolbar: false,
                    },
                    images: [data.phlSelfieSelesai],
                  })
                }
              "
              :plain="true"
              class="primary--text"
              >Lihat Foto Absensi Selesai</atoms-button
            >
          </v-col>
          <v-col cols="10" class="accent rounded-lg my-2">
            <atoms-text class="font-weight-bold text-left primary--text">Perusahaan</atoms-text>
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Nama : </span>
              <a
                v-if="data.client"
                target="_blank"
                :href="
                  $route.fullPath.includes('riwayat')
                    ? `/clients/review?_id=${data.client._id}`
                    : `/attendance/riwayat?search=${data.ktpPhl}&client=${data.client._id}`
                "
                >{{ $route.fullPath.includes('riwayat') ? 'Lihat Detail ' : '' }}
                {{ (data.client && data.client.name) || '-' }}</a
              >
            </atoms-text>
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Alamat:</span>
              {{ (data.client && data.client.address && data.client.address.streetAddress1) || '-' }}</atoms-text
            >
          </v-col>
          <v-col cols="10" class="accent rounded-lg my-2">
            <atoms-text class="font-weight-bold text-left primary--text">Keterangan</atoms-text>
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Tanggal: </span
              >{{ $moment(data.phlJamMulai).format('dddd, DD MMMM YYYY') }}</atoms-text
            >
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Supervisor: </span
              >{{ data.supervisorName ? data.supervisorName : '-' }}</atoms-text
            >
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Komentar: </span
              >{{ data.supervisorComment ? data.supervisorComment : 'Tidak ada Komentar' }}</atoms-text
            >
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Status: </span
              >{{ data.supervisorApproval ? data.supervisorApproval : 'Proses' }}</atoms-text
            >
          </v-col>
          <v-col cols="10" class="accent rounded-lg my-2">
            <atoms-text class="font-weight-bold text-left primary--text">Status Persetujuan Persyaratan</atoms-text>
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Tipe Pekerja: </span
              >{{ (data.worker && data.worker.workerType) || '-' }}</atoms-text
            >
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Setuju s&k PHL : </span
              >{{ (data.worker && data.worker.agreeTermPhl && 'Setuju') || '-' }}</atoms-text
            >
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Setuju s&k Pekerja : </span
              >{{ (data.worker && data.worker.agreeTerm && 'Setuju') || '-' }}</atoms-text
            >
          </v-col>

          <v-col cols="10" class="accent rounded-lg my-2">
            <div style="position: relative">
              <atoms-text class="font-weight-bold text-left primary--text">Posisi</atoms-text>
              <atoms-button
                v-if="!disableEdit"
                @click="showEditPosition = true"
                class="rounded-pill pa-0 ma-0 ml-auto"
                style="position: absolute; top: 0; right: 0"
                title="Edit Posisi"
                :fab="true"
                :small="true"
                ><v-icon small> mdi-pencil </v-icon></atoms-button
              >
            </div>
            <atoms-text class="text-left">
              <span class="font-weight-bold">Area:</span>
              <span v-if="data.area">
                {{ data.area.title }}
              </span>
              <span v-else> Tidak Diisi </span>
            </atoms-text>
            <atoms-text class="text-left">
              <span class="font-weight-bold">Location:</span>
              <span v-if="data.location">
                {{ data.location.name }}
              </span>
              <span v-else> Tidak Diisi </span>
            </atoms-text>
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Shift:</span>

              <a v-if="data.shift" target="_blank" :href="`/shifts/review?_id=${data.shift._id}`">
                {{ data.shift.title }}</a
              >

              <span v-else> Tidak Diisi </span>
            </atoms-text>
            <atoms-button
              v-if="data.shift"
              :href="`/attendance/semua?shift=${data.shift._id}`"
              :style="{ width: '100%' }"
              :class="[' lighten-4 mt-2']"
              >Lihat Semua Pekerja Pada Shift {{ data.shift.title }}</atoms-button
            >
          </v-col>
          <v-col cols="10" class="accent rounded-lg my-2">
            <atoms-text class="font-weight-bold text-left primary--text">Absensi</atoms-text>
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Jam Mulai:</span>
              {{ $moment(data.phlJamMulai).format('DD MMMM YYYY, H:mm:ss') }}
              <atoms-button
                v-if="!disableEdit"
                @click="showEditJamMulai = true"
                class="rounded-pill pa-0 ma-0"
                title="Edit Backdate"
                :fab="true"
                :small="true"
                ><v-icon small> mdi-pencil </v-icon></atoms-button
              >
            </atoms-text>
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Jam Selesai:</span>
              {{ data.phlJamSelesai ? $moment(data.phlJamSelesai).format('DD MMMM YYYY, H:mm:ss') : '-' }}</atoms-text
            >
          </v-col>
          <v-col cols="10" class="accent rounded-lg my-2">
            <atoms-text class="font-weight-bold text-left primary--text">Alamat</atoms-text>
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Lokasi Mulai: </span>{{ data.locationIn }}.</atoms-text
            >
            <atoms-text class="text-left"
              ><span class="font-weight-bold">Lokasi Selesai: </span
              >{{ data.locationOut ? data.locationOut : 'Absensi Belum Lengkap' }}.</atoms-text
            >
          </v-col>
          <v-col cols="10" class="accent rounded-lg my-2">
            <atoms-text
              ><span class="font-weight-bold primary--text">Note Masuk :</span> {{ data.noteIn || '-' }}</atoms-text
            >
            <atoms-text
              ><span class="font-weight-bold primary--text">Note Keluar :</span> {{ data.note || '-' }}</atoms-text
            >
          </v-col>
          <v-col cols="10" class="px-0">
            <!-- <atoms-button
              v-if="!hideRiwayat"
              :to="`/absensi/riwayat?search=${data.ktpPhl}`"
              :style="{ width: '100%' }"
              :class="['py-md-6', 'primary--text lighten-5']"
              ><v-icon>mdi-account-details</v-icon> Lihat Riwayat</atoms-button
            > -->
            <!-- (data.worker &&
                  data.worker.clientSuspends &&
                  data.worker.clientSuspends.find(
                    (x) => x._id === $store.state.client._id
                  )) -->
            <atoms-button
              v-if="!disableEdit"
              :disabled="
                Boolean(
                  !data.absensiCompleted ||
                    data.supervisorApproval !== $status.reject ||
                    (data.worker && data.worker.blacklist)
                    ? true
                    : false,
                )
              "
              @click="
                $emit('toggleApprove', {
                  ...data,
                  supervisorApproval: $status.approve,
                })
              "
              :style="{ width: '100%' }"
              :class="['green--text green lighten-4 mt-4']"
              >Approve</atoms-button
            >
            <atoms-button
              v-if="!disableEdit"
              :disabled="
                Boolean(
                  !data.absensiCompleted ||
                    data.supervisorApproval !== $status.approve ||
                    (data.worker && data.worker.blacklist)
                    ? true
                    : false,
                )
              "
              @click="
                $emit('toggleApprove', {
                  ...data,
                  supervisorApproval: $status.reject,
                })
              "
              :style="{ width: '100%' }"
              :class="['red--text red lighten-4 mt-4']"
              >Reject Absensi</atoms-button
            >
          </v-col>
        </v-row>
      </molecules-wrapper>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['data', 'hideRiwayat', 'disableEdit'],
  data() {
    return {
      showEditPosition: false,
      showEditJamMulai: false,
      url: '',
    }
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden'
  },
  methods: {
    onClose() {
      document.documentElement.style.overflow = 'auto'
      this.$emit('closed')
    },
  },
}
</script>
