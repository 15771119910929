var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-overlay',{staticClass:"d-flex align-center justify-center text-center",staticStyle:{"z-index":"2001"},attrs:{"value":_vm.show || false,"fixed":"","dark":_vm.$store.state.dark,"opacity":"0.7","height":"100%"}},[_c('v-card',{staticClass:"mx-auto pa-4",attrs:{"rounded":"lg","min-width":_vm.width || 300,"max-width":_vm.width || 344,"elevation":"0"}},[_c('v-card-title',[_c('atoms-title',{staticClass:"font-weight-bold",attrs:{"h3":true}},[_vm._v(_vm._s(_vm.title || 'Title'))])],1),(_vm.caption)?_c('v-card-text',{staticClass:"text-left"},[_c('atoms-text',[_vm._v(_vm._s(_vm.caption))])],1):_vm._e(),(!_vm.strict)?_c('v-card-text',{class:['text-left px-2 pt-0', _vm.override && _vm.override]},[_vm._t("default")],2):_vm._e(),(_vm.items)?_c('v-card-actions',{class:[
        'd-flex px-2',
        _vm.strict
          ? 'flex-column py-4'
          : !_vm.items.find((e) => e.disable) && _vm.items.length > 1
          ? 'justify-space-between'
          : 'justify-end',
      ]},[_vm._l((_vm.items),function({ title, override, exec, full, disable = false },i){return [(!disable)?_c('atoms-button',{key:i,class:[override ? override : 'primary--text', 'rounded-lg ', _vm.strict && 'my-2 mx-0'],style:({
            width: full ? '100%' : _vm.strict ? '90%' : 99 / _vm.items.length + '%',
          }),on:{"click":exec}},[_vm._v(_vm._s(title))]):_vm._e()]})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }