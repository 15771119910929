<template>
  <molecules-floating>
    <v-card
      elevation="0"
      rounded="lg"
      class="mx-auto pa-4 rounded-lg"
      :min-width="350"
      :max-width="$vuetify.breakpoint.smAndDown ? 350 : 700"
    >
      <v-card-title>
        <atoms-title :h3="true" class="font-weight-bold">Edit Posisi</atoms-title>
      </v-card-title>
      <v-card-text class="text-left pb-0">
        <atoms-text
          >Anda mengedit posisi absensi
          <span class="primary--text">{{ data.worker && data.worker.fullName }}</span>
        </atoms-text>
      </v-card-text>
      <v-card-text>
        <v-autocomplete
          class="rounded-lg mb-4"
          placeholder="Pilih Area"
          :items="areas"
          v-model="areaId"
          :menu-props="{ offsetY: true }"
          background-color="background"
          item-text="title"
          item-value="_id"
          hide-details
          solo
          flat
          dense
          required
          :disabled="areaLoading"
          :loading="areaLoading"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
        <v-autocomplete
          class="rounded-lg mb-4"
          placeholder="Pilih Lokasi"
          :items="locations"
          v-model="locationId"
          :menu-props="{ offsetY: true }"
          background-color="background"
          item-text="name"
          item-value="_id"
          hide-details
          solo
          flat
          dense
          required
          :disabled="locationLoading"
          :loading="locationLoading"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
        <v-autocomplete
          class="rounded-lg mb-4"
          placeholder="Pilih Shift"
          :items="shifts"
          v-model="shiftId"
          :menu-props="{ offsetY: true }"
          background-color="background"
          item-text="title"
          item-value="_id"
          hide-details
          solo
          flat
          dense
          required
          :disabled="shiftLoading"
          :loading="shiftLoading"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />

        <atoms-button @click="updateData" :loading="$store.state.loading" :style="{ width: '100%' }" class="primary"
          >Simpan</atoms-button
        >
        <atoms-button :style="{ width: '100%' }" class="mt-2 primary" @click="close">Kembali</atoms-button>
      </v-card-text>
    </v-card>
  </molecules-floating>
</template>
<script>
export default {
  props: {
    show: Boolean,
    data: Object,
  },
  data() {
    return {
      clicked: false,
      reset: false,
      clientId: '',
      areas: [],
      areaId: '',
      areaLoading: false,
      locations: [],
      locationId: '',
      locationLoading: false,
      shifts: [],
      shiftId: '',
      shiftLoading: false,
    }
  },
  async mounted() {
    await this.fetchData()
  },
  watch: {
    async areaId(areaId) {
      this.locationLoading = true
      this.shiftLoading = true
      this.locations = await this.$api
        .get('/Locations/get', {
          params: {
            jsonQuery: JSON.stringify({
              areaId,
              pipeline: [
                {
                  $sort: {
                    name: -1,
                  },
                },
              ],
            }),
          },
        })
        .then((data) => data?.result || [])
      this.locationId = this.locations.some((item) => item._id === this.locationId) ? this.locationId : ''
      this.locationLoading = false
      this.shiftLoading = false
    },
    async locationId(locationId) {
      this.shiftLoading = true
      this.shifts = await this.$api
        .get('/QuotationItems/getShifts', {
          params: {
            jsonQuery: JSON.stringify({
              clientId: this.clientId,
              areaId: this.areaId,
              locationId,
              pipeline: [
                {
                  $sort: {
                    startDate: -1,
                  },
                },
              ],
            }),
          },
        })
        .then((data) => data?.result || [])
      this.shiftId = this.shifts.some((item) => item._id === this.shiftId) ? this.shiftId : ''
      this.shiftLoading = false
    },
  },
  methods: {
    close(payload) {
      this.reset = false
      this.clicked = false
      this.$emit('close', payload)
    },
    async fetchData() {
      if (this.data && this.data?.worker) {
        // set Id
        this.clientId = this.data?.clientId
        this.areaId = this.data?.areaId
        this.locationId = this.data?.locationId
        this.shiftId = this.data?.shiftId
        // set data
        this.areaLoading = true
        const clientId = this.clientId
        this.areas = await this.$api
          .get('/Areas/getAreas', {
            params: {
              jsonQuery: JSON.stringify({
                clientId,
                pipeline: [
                  {
                    $sort: {
                      title: -1,
                    },
                  },
                ],
              }),
            },
          })
          .then((data) => data?.result || [])
        this.areaLoading = false
      } else {
        this.$showDialog({
          title: 'Kesalahan',
          body: 'Gagal memuat data absensi pekerja',
        })
        this.close()
      }
    },
    async updateData() {
      if (!this.areaId) {
        alert('Harap Area Diisi !')
        return
      }
      if (!this.locationId) {
        alert('Harap Location Diisi !')
        return
      }
      // if (!this.shiftId) {
      //   alert("Harap Shift Diisi !")
      //   return
      // }
      this.$store.commit('SET', { loading: true })
      try {
        const item = await this.$store.dispatch('attendance/update', {
          ...this.data,
          areaId: this.areaId,
          locationId: this.locationId,
          shiftId: this.shiftId,
        })
        if (item?.result) {
          const attendance = await this.$api
            .get('/Absensi/getMongo', {
              params: {
                jsonQuery: JSON.stringify({
                  _id: item.result._id,
                  pipeline: [
                    {
                      $lookup: {
                        from: 'QuotationItems',
                        localField: 'shiftId',
                        foreignField: '_id',
                        as: 'shift',
                      },
                    },
                    {
                      $unwind: {
                        path: '$shift',
                        preserveNullAndEmptyArrays: true,
                      },
                    },
                    {
                      $lookup: {
                        from: 'Areas',
                        localField: 'areaId',
                        foreignField: '_id',
                        as: 'area',
                      },
                    },
                    {
                      $unwind: {
                        path: '$area',
                        preserveNullAndEmptyArrays: true,
                      },
                    },
                    {
                      $lookup: {
                        from: 'Locations',
                        localField: 'locationId',
                        foreignField: '_id',
                        as: 'location',
                      },
                    },
                    {
                      $unwind: {
                        path: '$location',
                        preserveNullAndEmptyArrays: true,
                      },
                    },
                  ],
                }),
              },
            })
            .then((data) => data?.result?.[0] || {})
          this.$showDialog({
            title: 'Berhasil',
            body: `Absensi ${this.data?.worker?.fullName} berhasil diperbarui!`,
          })
          this.close(attendance)
        }
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: error,
        })
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
