<template>
  <v-fade-transition>
    <component
      v-if="layout"
      :is="layout"
      v-bind="$props"
      v-on="$listeners"
      :style="{
        height: '100%',
        width: '100%',
      }"
    >
      <slot />
    </component>
  </v-fade-transition>
</template>

<script>
import DefaultLayout from './defaultLayout.vue'
export default {
  name: 'AppLayout',
  data: () => ({
    layout: DefaultLayout,
  }),
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        try {
          const component = await import(`@/layouts/${route?.meta?.layout}.vue`)
          this.layout = component?.default || DefaultLayout
        } catch (e) {
          this.layout = DefaultLayout
        }
      },
    },
  },
}
</script>
