var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('closed')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),(_vm.target && _vm.target._id)?_c('v-container',{class:[(!_vm.$vuetify.breakpoint.smAndDown && 'px-10') || '']},[_c('div',{staticClass:"text-left"},[_c('atoms-title',{attrs:{"h2":true}},[_vm._v(_vm._s((_vm.target.client && _vm.target.client.name) || 'Shift Kuotasi'))])],1),_c('br'),_c('div',{staticClass:"text-left"},[_c('atoms-text',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Area : ")]),_vm._v(" "+_vm._s(_vm.target.areaTitle || '-'))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Lokasi : ")]),_vm._v(" "+_vm._s(_vm.target.location || '-'))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Shift : ")]),_vm._v(" "+_vm._s(_vm.target.title || '-'))])],1),_c('br'),_c('img',{attrs:{"src":_vm.target.qrCode,"width":"50%"}}),_c('br'),_c('br'),_c('atoms-button',{staticClass:"primary",staticStyle:{"width":"100%"},attrs:{"href":`/cetak-shift/${_vm.target._id}`,"target":"_blank"}},[_vm._v("Cetak")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }