<template>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
    <GmapMap :center="center" :zoom="15" map-type-id="terrain" style="width: 1000px; height: 600px">
      <GmapMarker
        :position="marker.position"
        :clickable="true"
        :draggable="true"
        @click="center = marker.position"
        @drag="listenDrag"
      />
      <gmap-polygon :paths="paths"></gmap-polygon>
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  props: {
    marker: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      paths: [],
    }
  },
  computed: {
    center() {
      return this.coordinate
    },
    coordinate() {
      const pos = this.$store.getters['gmap/coordinate']
      if (pos?.latitude && pos?.longitude) {
        return {
          lat: pos.latitude,
          lng: pos.longitude,
        }
      }
      // default coordinate at head office hayokerja
      return {
        lat: -6.1624923,
        lng: 106.874431,
      }
    },
  },
  model: {
    prop: 'marker',
    event: 'drag',
  },
  mounted() {
    this.addMarker()
  },
  methods: {
    listenDrag(event) {
      const pos = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      this.marker.position = pos
      this.$emit('drag', { position: pos })
    },
    async addMarker() {
      this.marker.position = { ...this.coordinate }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
