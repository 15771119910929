import router from '@/router/index'
/* eslint-disable */
const collection = 'Promotions'
const defaultPipeline = [
  {
    $lookup: {
      from: 'Users',
      localField: 'createdBy',
      foreignField: '_id',
      as: 'createdByData',
    },
  },
  {
    $unwind: {
      path: '$createdByData',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'updatedBy',
      foreignField: '_id',
      as: 'updatedByData',
    },
  },
  {
    $unwind: {
      path: '$updatedByData',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $project: {
      'createdByData.password': 0,
      'createdByData.notifTokens': 0,
      'createdByData.referralCode': 0,
      'createdByData.email': 0,

      'createdByData.password': 0,
      'updatedByData.notifTokens': 0,
      'updatedByData.referralCode': 0,
      'updatedByData.email': 0,
    },
  },
]
const initial = () => ({
  data: null,
  summary: null,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async get({ state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            limit: 1,
          },
        })
        .then(({ result, ...rest }) => {
          return { ...result?.[0], ...rest }
        })
        .catch((error) => Promise.reject(error))
    },
    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            page: payload?.page || 1,
            limit: payload?.limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          if (!payload?.returnOnly)
            commit('SET', {
              data: result,
              ...rest,
            })
          return result
        })
        .catch(async (err) => Promise.reject(err))
    },
    async create({ state }, payload) {
      return await this._vm.$api
        .post(collection + '/create', payload)
        .catch((error) => Promise.reject(error.response?.data?.message || error.response || 'Ada kesalahan'))
    },
    async update({ state }, payload) {
      return await this._vm.$api
        .patch(collection + '/update', payload)
        .catch((error) => Promise.reject(error.response?.data?.message || error.response || 'Ada kesalahan'))
    },
    async remove({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .delete(collection + '/remove', {
            params: {
              _id: payload,
            },
          })
          .then(async ({ success }) => success)
          .catch((error) => Promise.reject(error.response?.data?.message || error.response || 'Ada kesalahan'))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
