<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="close"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <molecules-wrapper>
        <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>Member Baru</atoms-title>
            </v-col>
            <!-- client -->
            <v-col cols="10">
              <atoms-text-field
                v-if="!$store.state.client.data || !client.enabled"
                label="Cari Client"
                placeholder="Kosongkan untuk mencari semua client"
                v-model="client.term"
                :rules="[]"
              />
              <atoms-text-field v-else label="Pilih Client">
                <v-combobox
                  class="rounded-lg"
                  v-model="client.value"
                  item-text="name"
                  :items="$store.state.client.data || []"
                  placeholder="Pilih Client"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
              <atoms-button
                @click="
                  () => {
                    if (!$store.state.client.data || !client.enabled) {
                      getClients()
                    } else {
                      client.term = ''
                      client.value = null
                      client.enabled = false
                    }
                  }
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="[!$store.state.client.data || !client.enabled ? 'primary' : 'red white--text', 'mb-4']"
                >{{ !$store.state.client.data || !client.enabled ? 'Cari Client' : 'Reset Pencarian' }}</atoms-button
              >
              <div v-if="client.value && client.enabled" class="text-center my-4">
                <atoms-text class="my-1 font-weight-bold">Client Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Nama:</span> {{ client.value.name }}</atoms-text
                  >
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Alamat:</span>
                    {{ client.value.address.streetAddress1 }}</atoms-text
                  >
                  <atoms-text><span class="font-weight-bold primary--text">Area Terdaftar:</span> </atoms-text>

                  <ul>
                    <li v-for="(x, i) in client.area || []" :key="i">
                      {{ x.title }} {{ x.inactive ? '(Tidak Aktif)' : '' }}
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
            <!-- area -->
            <v-col cols="10" v-if="isShowArea">
              <div v-if="!$store.state.area.data || !area.enabled">
                <atoms-text-field
                  label="Cari Area"
                  placeholder="Biarkan kosong jika ingin cari semua"
                  v-model="area.term"
                  :rules="[]"
                />
                <atoms-button
                  @click="
                    () => {
                      getAreas()
                      area.enabled = true
                    }
                  "
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  class="primary"
                  >Cari Area</atoms-button
                >
              </div>
              <div v-else>
                <atoms-text-field label="Pilih Area">
                  <v-combobox
                    multiple
                    class="rounded-lg"
                    v-model.trim="area.value"
                    :items="$store.state.area.data || []"
                    :item-text="(area) => area.title + ' - ' + (area.inactive ? 'Tidak Aktif' : 'Aktif')"
                    prepend-inner-icon="mdi-check-all"
                    @click:prepend-inner="
                      () => {
                        if ($store.state.area.data && $store.state.area.data.length > 0)
                          area.value = $store.state.area.data
                      }
                    "
                    placeholder="Pilih Area"
                    :menu-props="{ offsetY: true }"
                    :rules="[(v) => !!v || 'Harap disii!']"
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
                <atoms-button
                  @click="
                    () => {
                      getAreas()
                      area.term = ''
                      area.enabled = false
                    }
                  "
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  class="red white--text"
                  >Reset Pencarian</atoms-button
                >
              </div>
            </v-col>
            <!-- lokasi -->
            <v-col cols="10" v-if="isShowLocation">
              <div v-if="!$store.state.location.data || !location.enabled">
                <atoms-text-field
                  label="Cari Lokasi"
                  placeholder="Biarkan kosong jika ingin cari semua"
                  v-model="location.term"
                  :rules="[]"
                />
                <atoms-button
                  @click="
                    () => {
                      getLocations()
                      location.enabled = true
                    }
                  "
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  class="primary"
                  >Cari Lokasi</atoms-button
                >
              </div>
              <div v-else>
                <atoms-text-field label="Pilih Lokasi">
                  <v-combobox
                    multiple
                    class="rounded-lg"
                    v-model.trim="location.value"
                    :items="$store.state.location.data || []"
                    :item-text="(loc) => loc.name + ' - ' + (loc.areas && loc.areas.title) || loc.areaId"
                    prepend-inner-icon="mdi-check-all"
                    @click:prepend-inner="
                      () => {
                        if ($store.state.location.data && $store.state.location.data.length > 0)
                          location.value = $store.state.location.data
                      }
                    "
                    placeholder="Pilih Lokasi"
                    :menu-props="{ offsetY: true }"
                    :rules="[(v) => !!v || 'Harap disii!']"
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
                <atoms-button
                  @click="
                    () => {
                      location.term = ''
                      location.enabled = false
                    }
                  "
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  class="red white--text"
                  >Reset Pencarian</atoms-button
                >
              </div>
            </v-col>
            <!-- user -->
            <v-col v-if="isShowUser" cols="10">
              <v-container :class="['pt-0 d-flex justify-center', $vuetify.breakpoint.smAndDown && 'px-0']">
                <v-tabs
                  background-color="accent"
                  color="primary"
                  height="40"
                  v-model="tabs"
                  hide-slider
                  :show-arrows="$vuetify.breakpoint.smAndDown ? true : false"
                  :center-active="$vuetify.breakpoint.smAndDown ? true : false"
                  class="rounded-lg d-flex justify-center"
                >
                  <v-tab
                    v-for="i in ['Daftarkan', 'User Tersedia']"
                    :key="i"
                    class="text-capitalize"
                    active-class="primary lighten-5 primary--text rounded-lg ma-1"
                  >
                    <atoms-text class="font-weight-medium">{{ $toCapitalize(i) }}</atoms-text>
                  </v-tab>
                </v-tabs></v-container
              >
              <div>
                <v-fade-transition>
                  <v-container v-if="tabs === 0" class="px-0">
                    <div>
                      <v-avatar
                        color="accent"
                        size="150"
                        :style="{
                          cursor: 'pointer',
                        }"
                      >
                        <v-overlay :value="true" opacity="0.5" absolute>
                          <div @click="openUploader" class="pa-12" :style="{ width: '100%', height: '100%' }">
                            <atoms-text class="font-weight-medium"
                              ><v-icon small> mdi-pencil </v-icon>Upload Foto</atoms-text
                            >
                            <input ref="uploader" type="file" accept="image/*" class="d-none" @change="onPhotoChange" />
                          </div>
                        </v-overlay>
                        <atoms-image v-if="photo && photo.url" aspect-ratio="1" :src="photo.url || `/lazy-img.webp`" />
                        <v-icon v-else> mdi-account </v-icon>
                      </v-avatar>
                      <atoms-text class="py-4">Foto Maks. 3mb (Bila ada)</atoms-text>
                    </div>

                    <atoms-text-field
                      label="Nama Pengguna"
                      v-model="nickname"
                      :rules="[(v) => !!v || 'Harap disii!']"
                    />
                    <atoms-text-field
                      label="Email"
                      v-model="email"
                      :rules="[
                        (v) => !!v || 'Email harap diisi!',
                        (v) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email tidak valid',
                        (v) => (!!v && this.duplicate !== v) || customEmailError || `Email sudah terdaftar`,
                      ]"
                    />
                    <atoms-text-field
                      type="password"
                      label="Password"
                      v-model="password"
                      :rules="[
                        (v) => !!v || 'Harap disii!',
                        (v) => (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter',
                      ]"
                    />
                  </v-container>
                </v-fade-transition>
                <v-fade-transition>
                  <v-container v-if="tabs === 1" class="px-0">
                    <atoms-text-field
                      v-if="!$store.state.user.data || !user.enabled"
                      label="Cari User"
                      v-model="user.term"
                      :rules="[]"
                    />
                    <atoms-text-field v-else label="Pilih User">
                      <v-combobox
                        class="rounded-lg"
                        v-model="user.value"
                        :items="$store.state.user.data || []"
                        :item-text="(x) => x.nickname + ' → ' + x.email"
                        placeholder="Pilih User"
                        :menu-props="{ offsetY: true }"
                        :rules="[(v) => !!v || 'Harap disii!']"
                        dense
                        solo
                        flat
                      />
                    </atoms-text-field>
                    <atoms-button
                      :disabled="!isShowUser"
                      @click="
                        () => {
                          if (!$store.state.user.data || !user.enabled) {
                            getUsers()
                            client.term = ''

                            user.enabled = true
                          } else {
                            user.term = ''
                            user.value = null
                            user.enabled = false
                          }
                        }
                      "
                      :loading="$store.state.loading"
                      :style="{ width: '100%' }"
                      :class="[!$store.state.user.data || !user.enabled ? 'primary' : 'red white--text', 'mb-4']"
                      >{{ !$store.state.user.data || !user.enabled ? 'Cari User' : 'Reset Pencarian' }}</atoms-button
                    >
                    <div v-if="user.value && user.enabled" class="text-center my-4">
                      <atoms-text class="my-1 font-weight-bold">User Terpilih</atoms-text>
                      <div class="pa-2 accent rounded-lg text-left">
                        <atoms-text
                          ><span class="font-weight-bold primary--text">Nama:</span>
                          {{ user.value.nickname }}</atoms-text
                        >
                        <atoms-text
                          ><span class="font-weight-bold primary--text">Email:</span> {{ user.value.email }}</atoms-text
                        >
                      </div>
                    </div>
                  </v-container>
                </v-fade-transition>
              </div>
              <atoms-button
                :disabled="!isShowSubmit"
                :loading="$store.state.loading"
                type="submit"
                :style="{ width: '100%' }"
                class="primary"
                >Daftarkan Member</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </molecules-wrapper>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  data() {
    return {
      all: 'Semua',

      nickname: '',
      email: '',
      password: '',
      photo: null,
      duplicate: '',
      valid: true,
      tabs: null,
      customEmailError: '',

      client: {
        enabled: false,
        value: '',
        item: null,
        term: '',
        area: null,
      },
      area: {
        term: '',
        enabled: false,
        value: null,
        items: null,
      },
      location: {
        term: '',
        enabled: false,
        value: null,
        items: null,
      },
      user: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
    }
  },
  watch: {
    async 'client.value'() {
      if (this.client.value) {
        await this.getAreas()
        this.client.area = this.$store.state.area.data
      }
    },
  },
  computed: {
    isShowLocation() {
      return this.area.enabled && this.area.value?.length
    },
    isShowArea() {
      return this.client.enabled && this.client.value
    },
    isShowUser() {
      return this.isShowArea && this.isShowLocation && this.location.enabled && this.location.value?.length > 0
    },
    isShowSubmit() {
      return this.isShowUser && this.tabs == 0
        ? this.nickname && this.email && this.password
        : this.user.value && this.user.enabled
    },
  },
  async mounted() {
    document.documentElement.style.overflow = 'hidden'
  },
  methods: {
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.client.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
          },
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getAreas() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store.dispatch('area/getAll', {
          query: {
            clientId: this.client.value?._id,
            title: { $regex: this.area.term || '', $options: 'i' },
            // $or: [
            //   {
            //     inactive: {
            //       $exists: false,
            //     },
            //   },
            //   {
            //     inactive: false,
            //   },
            // ],
          },
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getLocations() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store.dispatch('location/getAll', {
          query: {
            areaId: { $in: this.area.value?.map((area) => area?._id) },
            name: { $regex: this.location.term || '', $options: 'i' },
            // $or: [
            //   {
            //     inactive: {
            //       $exists: false,
            //     },
            //   },
            //   {
            //     inactive: false,
            //   },
            // ],
          },
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getUsers() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.user.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('user/getAll', {
          custom: {
            $or: [
              {
                nickname: term,
              },
              {
                email: term,
              },
              {
                roles: term,
              },
            ],
          },
          page: 1,
          limit: 0,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    openUploader() {
      if (this.$refs.uploader) {
        this.$refs.uploader.click()
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (file) {
        this.photo = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.photo = null
      }
    },
    async preValidate() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.email.trim() || '', $options: 'i' }
      try {
        if (this.email && this.tabs === 0) {
          const { result: worker } = await this.$api.get(`Workers/count`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          const { result: user } = await this.$api.get(`Users/isExist`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          if (worker > 0 || user) {
            if (worker > 0) {
              this.customEmailError =
                'Email sudah terdaftar oleh pekerja. Anda bisa menambahkan di tab kedua (Pekerja Tersedia)'
            }
            this.duplicate = this.email
          } else {
            this.duplicate = ''
          }
        }

        await this.validate()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()
      try {
        if (this.tabs === 0 && this.valid && this.isShowSubmit) {
          // deprecated, since using users/register instead of users/save
          // const userId = this.$uuid.v4()
          // console.log({
          //   _id: userId,
          //   email: this.email,
          //   password: this.password,
          //   picture: this.photo,
          //   nickname: this.nickname,
          //   role: this.$roles.Client,
          // })
          const user = await this.$store.dispatch('user/register', {
            email: this.email,
            password: this.password,
            picture: this.photo?.image
              ? await this.$uploadFile(this.photo.image, `User/${userId}/Profil`, `picture`)
              : null,
            nickname: this.nickname,
            role: this.$roles.Client,
          })
          await this.registerMember({ userId: user.userId })
        } else if (this.tabs === 1 && this.isShowSubmit) {
          await this.registerMember()
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian`,
          })
        }
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: `Terdapat kesalahan, ${error.message || error}`,
        })
        this.$store.commit('SET', { loading: false })
        Promise.reject(error)
      }
    },
    async registerMember(payload) {
      const locationIds = this.location.value?.map((location) => location._id)
      const areaIds = this.area.value?.map((area) => area._id)
      try {
        await this.$store.dispatch('member/register', {
          locationIds,
          areaIds,
          userId: payload?.userId || this.user.value?._id,
          clientId: this.client.value?._id,
        })
        this.$showDialog({
          title: 'Berhasil',
          body: `Member baru berhasil ditambahkan`,
        })
        this.close()
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: `Terdapat kesalahan, ${error.message || error}`,
        })
        this.$store.commit('SET', { loading: false })
        Promise.reject(error)
      }
    },
    close() {
      document.documentElement.style.overflow = 'auto'
      // this.$refs.form.resetValidation()
      // this.$refs.form.reset()
      this.$emit('closed')
    },
  },
}
</script>
