export default [
  'Logistik / Expedisi',
  'Online Market',
  'Pabrik',
  'Makanan / Minuman',
  'Perhiasan',
  'Elektronik Retail Chain',
  'Telekomunikasi',
  'Jasa / Hiburan',
  'Lain-Lain',
]
