<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="close"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>Edit Akun Anda</atoms-title>
            </v-col>
            <v-col cols="10">
              <div>
                <v-avatar
                  color="accent"
                  size="150"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-overlay :value="true" opacity="0.5" absolute>
                    <div @click="openUploader" class="pa-12" :style="{ width: '100%', height: '100%' }">
                      <atoms-text class="font-weight-medium"><v-icon small> mdi-pencil </v-icon>Upload Foto</atoms-text>
                      <input ref="uploader" type="file" accept="image/*" class="d-none" @change="onPhotoChange" />
                    </div>
                  </v-overlay>
                  <v-img
                    v-if="photo && photo.url"
                    :lazy-src="`/lazy-img.webp`"
                    aspect-ratio="1"
                    :src="photo.url || `/lazy-img.webp`"
                  />
                  <v-icon v-else> mdi-account </v-icon>
                </v-avatar>
                <atoms-text class="py-4">Foto Maks. 3mb (Bila ada)</atoms-text>
              </div>
            </v-col>

            <v-col cols="10">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" class="py-0">
              <div class="pa-4 mb-4 rounded-lg accent text-left d-flex flex-column">
                <atoms-text
                  ><span class="font-weight-bold">Nama Akun &emsp;: </span
                  >{{ (user && user.nickname) || '-' }}</atoms-text
                >
                <atoms-text
                  ><span class="font-weight-bold">Email Anda &emsp; : </span>
                  <span class="primary--text">{{ (user && user.email) || '-' }}</span></atoms-text
                >
                <atoms-text
                  ><span class="font-weight-bold">Hak akses&emsp;&emsp;: </span
                  >{{ (user && user.roles && user.roles.toString()) || '-' }}</atoms-text
                >
              </div>
              <atoms-text-field
                type="password"
                label="Password Lama"
                color="accent"
                v-model="oldPassword"
                :rules="[
                  (v) => !!v || 'Harap disii!',
                  (v) => (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter',
                ]"
              />
              <atoms-text-field
                type="password"
                label="Password Baru"
                color="accent"
                v-model="password"
                :rules="[
                  (v) => !!v || 'Harap disii!',
                  (v) => (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter',
                ]"
              />
              <atoms-text-field
                type="password"
                label="Konfirmasi Password"
                color="accent"
                v-model="confirmPass"
                :rules="[
                  (v) => !!v || 'Harap disii!',
                  (v) => (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter',
                  (v) => (!!v && v === password) || 'Password tidak sesuai',
                ]"
              />

              <atoms-button :loading="$store.state.loading" type="submit" :style="{ width: '100%' }" class="primary"
                >Perbarui Akun</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  data() {
    return {
      user: null,
      photo: null,
      oldPassword: '',
      password: '',
      confirmPass: '',
      valid: true,
    }
  },

  async mounted() {
    document.documentElement.style.overflow = 'hidden'
    this.user = this.$store.state.user
    this.photo = {
      url: this.user.picture,
      image: null,
    }
  },
  methods: {
    close() {
      this.$emit('closed')
      document.documentElement.style.overflow = 'auto'
    },
    openUploader() {
      if (this.$refs.uploader) {
        this.$refs.uploader.click()
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (file) {
        this.photo = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.photo = null
      }
    },
    async preValidate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.validate()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()
      try {
        //&& this.password === this.confirmPass
        if (this.valid) {
          if (this.photo.url != this.user.picture) {
            const picture =
              this.photo && this.photo.image
                ? await this.$uploadFile(this.photo.image, `User/${this.user._id}/Profil`, `picture`)
                : this.user.picture
            await this.$store.dispatch('user/update', {
              _id: this.user?._id,
              picture,
            })
            this.$store.commit('user/SET', {
              picture,
            })
          }

          if (this.oldPassword && this.password === this.confirmPass) {
            await this.$store.dispatch('user/resetPassword', {
              oldPassword: this.oldPassword,
              newPassword: this.password,
            })
          }
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$showDialog({
            title: 'Berhasil',
            body: `Akun Anda berhasil diperbarui`,
          })
          this.$emit('closed')
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian`,
          })
        }
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: error?.message || error,
        })
        // return
        Promise.reject(error?.message || error)
      }
    },
  },
}
</script>
