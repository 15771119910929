var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-3 my-3",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"px-3 pt-1 pb-6",attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('h2',[_vm._v("Rangkuman Penambahan Pekerja")]),_c('p',[_vm._v("Grafik Penambahan Pekerja yang telah diapprove")])]),_c('v-col',[_c('v-select',{attrs:{"items":[
          {
            label: '1 Minggu Terakhir',
            value: 'week',
          },
          {
            label: '1 Bulan Terakhir',
            value: 'month',
          },
          {
            label: '1 Tahun Terakhir',
            value: 'year',
          },
        ],"menu-props":{ offsetY: true },"item-text":"label","item-value":"value","dense":"","solo":"","hide-details":""},model:{value:(_vm.chartFilter),callback:function ($$v) {_vm.chartFilter=$$v},expression:"chartFilter"}})],1)],1),_c('div',[_c('atoms-line-chart',{attrs:{"chart-data":_vm.chartData,"options":_vm.chartOptions}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }