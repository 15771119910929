var render = function render(){var _vm=this,_c=_vm._self._c;return _c('atoms-modal',[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":_vm.closePage}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.preValidate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v("Perbarui User")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('div',[_c('v-avatar',{style:({
                cursor: 'pointer',
              }),attrs:{"color":"accent","size":"200"}},[_c('v-overlay',{attrs:{"value":true,"opacity":"0.5","absolute":""}},[_c('div',{staticClass:"pa-12",style:({ width: '100%', height: '100%' }),on:{"click":_vm.openUploader}},[_c('atoms-text',{staticClass:"font-weight-medium"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v("Upload Foto")],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onPhotoChange}})],1)]),(_vm.photo && _vm.photo.url)?_c('atoms-image',{attrs:{"lazy-src":`/lazy-img.webp`,"aspect-ratio":"1","src":_vm.photo.url || `/lazy-img.webp`}}):_c('v-icon',[_vm._v(" mdi-account ")])],1),_c('atoms-text',{staticClass:"py-4"},[_vm._v("Foto Maks. 3mb (Bila ada)")])],1),_c('atoms-text-field',{attrs:{"label":"Nama User","rules":[(v) => !!v || 'Harap disii!']},model:{value:(_vm.nickname),callback:function ($$v) {_vm.nickname=$$v},expression:"nickname"}}),_c('atoms-text-field',{attrs:{"label":"Email","rules":[
              (v) => !!v || 'Email harap diisi!',
              (v) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email tidak valid',
              (v) => (!!v && this.duplicate !== v) || 'Email sudah terdaftar!',
            ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('atoms-text-field',{attrs:{"label":"Roles"}},[_c('v-combobox',{staticClass:"rounded-lg",attrs:{"items":_vm.$roleList,"item-text":"label","item-value":"value","placeholder":"Roles","multiple":"","dense":"","solo":"","flat":""},on:{"change":() => {
                  _vm.roles = _vm.roles && _vm.roles.map((it) => (typeof it === 'object' ? (it && it.value) || it : it))
                }},model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}})],1),_c('atoms-button',{staticClass:"primary mt-4",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v("Perbarui")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }