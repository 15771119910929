import router from '@/router/index'
/* eslint-disable */

const initial = () => ({
  coordinate: { lat: -6.1624923, lng: 106.874431 }, // default hayokerja head office
  address: '',
})
export default {
  namespaced: true,
  state: initial,
  actions: {},
  mutations: {
    setCoordinate(state, payload) {
      state.coordinate = payload
    },
    setAddress(state, payload) {
      state.address = payload
    },
  },
  getters: {
    coordinate: (state) => state.coordinate,
    address: (state) => state.address,
  },
}
