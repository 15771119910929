import axios from "axios"

const defaultPipeline = [
  {
    $lookup: {
      from: 'InvoiceItems',
      let: { invoiceId: '$_id' },
      pipeline: [
        {
          $match: {
            $expr: { $eq: ['$invoiceId', '$$invoiceId'] },
          },
        },
        {
          $sort: { order: 1 },
        },
      ],
      as: 'items',
    },
  },
  { $sort: { _createdDate: -1 } },
  {
    $lookup: {
      from: 'Clients',
      localField: 'clientId',
      foreignField: '_id',
      as: 'client',
    },
  },
  {
    $lookup: {
      from: 'Areas',
      localField: 'areaId',
      foreignField: '_id',
      as: 'area',
    },
  },

  {
    $lookup: {
      from: 'Users',
      localField: 'createdBy',
      foreignField: '_id',
      as: 'createdBy',
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'updatedBy',
      foreignField: '_id',
      as: 'updatedBy',
    },
  },
  {
    $unwind: {
      path: '$createdBy',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $unwind: {
      path: '$updatedBy',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $unwind: {
      path: '$client',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $unwind: {
      path: '$area',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $project: {
      'createdBy.password': 0,
      'updatedBy.password': 0,
    },
  },
]
const collection = 'Invoices'
const initial = () => ({
  data: [],
  total: 0,
  balanceTotal: 0,
  balanceInvoiceTotal: 0,
  length: 0,
  pagesLength: 0,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  data() {
    return {
      cancelTokenGetEndpoint: undefined,
    }
  },
  actions: {
    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: defaultPipeline,
              ...payload?.custom,
            }),
            page: payload?.page,
            limit: payload?.limit || state?.limit,
          },
        })
        .then(async ({ result, count, ...rest }) => {
          if (!payload.dont_set) {
            commit('SET', {
              data: result,
              length: count,
              ...rest,
            })
          }
          return result
        })
        .catch(async (err) => Promise.reject(err))
    },
    async get({ commit, rootState }, payload) {
      if (typeof this.cancelTokenGetEndpoint != typeof undefined) {
        this.cancelTokenGetEndpoint.cancel("Operation canceled due to new request.");
      }
      this.cancelTokenGetEndpoint = axios.CancelToken.source();
      try {
        const response = await this._vm.$api.get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              isDeleted: { $ne: true },
              ...payload?.query,
              pipeline: defaultPipeline,
              ...payload?.custom,
            }),
            page: payload?.page,
            limit: payload?.limit,
          },
          cancelToken: this.cancelTokenGetEndpoint.token,
        });
        const { result, count, ...rest } = response;        
        commit('SET', {
          data: result,
          length: count,
          ...rest,
        });
        return result;
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request invoice/get canceled:', error.message);
        } else {
          // Handle other errors
          console.log('Request invoice/get failed:', error);
          return Promise.reject(error);
        }
      }
    },
    async save({ state }, payload) {
      return await this._vm.$api
        .post(collection + '/save', payload)
        .then(({ result }) => result || {})
        .catch(async (err) => Promise.reject(err))
    },
    async update({ state }, payload) {
      return await this._vm.$api.patch(collection + '/update', payload).catch(async (err) => Promise.reject(err))
    },
    async saveItems({ state }, payload) {
      return await this._vm.$api
        .post('InvoiceItems/bulkSave', payload)
        .then((res) => {
          if (res?.message) {
            alert(res.message)
          }
          return res
        })
        .catch(async (err) => Promise.reject(err))
    },
    async updatePayment({ state }, payload) {
      return await this._vm.$api.patch('Invoices/updatePayment', payload).catch(async (err) => Promise.reject(err))
    },
    async removePayment({ state }, id) {
      return await this._vm.$api
        .delete('Invoices/removePayment', {
          params: {
            quotationId: id,
          },
        })
        .catch(async (err) => Promise.reject(err))
    },
    async updateConversation({ commit }, { payload, params, ...rest }) {
      return await this._vm.$api
        .patch(collection + '/updateConversation', { ...payload, ...rest }, { ...params })
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
