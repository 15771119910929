import './plugins'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import 'viewerjs/dist/viewer.css'
import 'vue-plyr/dist/vue-plyr.css'
import VueMask from 'v-mask'
import VueViewer from 'v-viewer'
import VuePlyr from 'vue-plyr'
// import * as VueGoogleMaps from "vue2-google-maps"
import { sync } from 'vuex-router-sync'
import GmapVue from 'gmap-vue'

Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: process.env.VUE_APP_GMAP_KEY,
    // [OPTIONAL] This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    libraries: 'places,drawing',
    region: 'ID',
    language: 'id',
  },
  installComponents: true,
})

// Vue.use(Maska)
Vue.use(VueMask)
Vue.use(VueViewer)
Vue.use(VuePlyr, { plyr: {} })
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: process.env.VUE_APP_GMAP_KEY,
//   },
// })
Vue.config.productionTip = false

sync(store, router)
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
