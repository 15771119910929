<template>
  <molecules-floating-v2>
    <div
      ref="content"
      :style="{
        margin: '10px auto',
        width: $vuetify.breakpoint.smAndDown ? '90%' : '100%',
        'min-height': '25vh',
      }"
      class="d-relative rounded-lg background pa-5"
    >
      <v-row dense>
        <v-col cols="auto" class="grow" align="start">
          <atoms-title>Stage Filter</atoms-title>
        </v-col>
        <v-col cols="auto" class="shrink">
          <v-btn depressed color="primary" icon @click="close"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>

      <v-row dense class="mt-5">
        <v-col
          v-for="(it, _iit) in items || []"
          :key="_iit"
          cols="12"
          @click.stop="updateItem(it)"
          style="cursor: pointer"
        >
          <v-hover>
            <div
              slot-scope="{ hover }"
              :class="[
                'rounded-lg pa-2',
                hover || (item && item.find((x) => x.title == it.title)) ? 'primary white--text' : '',
              ]"
              :style="{
                transform: hover ? 'scale(1.1)' : 'scale(1)',
                pointerEvents: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px !important',
              }"
            >
              <atoms-text>{{ it.title }}</atoms-text>
            </div>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </molecules-floating-v2>
</template>
<script>
export default {
  props: ['value'],
  data() {
    return {
      item: null,
      items: Object.keys(this.$salesStage)?.map((key, i) => {
        return {
          title: this.$salesStage?.[key],
          value: {
            stage: this.$salesStage?.[key],
          },
        }
      }),
    }
  },
  mounted() {
    document.documentElement.style.overflowY = 'hidden'
    if (this.$props?.value?.items?.length != this.items?.length) {
      this.item = this.$props?.value?.items
    }
  },
  methods: {
    close() {
      document.documentElement.style.overflowY = 'auto'
      this.$emit('input', {
        open: false,
        items: this.item?.length > 0 ? this.item : this.items,
      })
    },
    updateItem(payload) {
      if (this.item?.find((it) => it.title == payload.title)) {
        this.item = this.item?.filter((it) => it.title != payload.title)
      } else {
        this.item = [...(this.item || []), payload]
      }
      // console.log(this.item)
      // this.item = [payload]
    },
  },
}
</script>
