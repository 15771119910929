import router from '@/router/index'
/* eslint-disable */
const collection = 'Vacancies'
const defaultPipeline = [
  {
    $lookup: {
      from: 'Clients',
      localField: 'clientId',
      foreignField: '_id',
      as: 'client',
    },
  },
  {
    $unwind: {
      path: '$client',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'HeadHunters',
      let: { headHunterId: '$idHeadHunter' },
      pipeline: [
        {
          $match: {
            $expr: { $in: ['$_id', ['$$headHunterId']] },
          },
        },
        {
          $lookup: {
            from: 'Users',
            localField: 'userId',
            foreignField: '_id',
            as: 'user',
          },
        },
        {
          $unwind: {
            path: '$user',
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $project: {
            'user.password': 0,
          },
        },
      ],
      as: 'headHunter',
    },
  },
  {
    $unwind: {
      path: '$headHunter',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'createdBy',
      foreignField: '_id',
      as: 'createdByData',
    },
  },
  {
    $unwind: {
      path: '$createdByData',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'updatedBy',
      foreignField: '_id',
      as: 'updatedByData',
    },
  },
  {
    $unwind: {
      path: '$updatedByData',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Workers',
      localField: 'workerIds',
      foreignField: '_id',
      as: 'workers',
    },
  },
  {
    $addFields: {
      totalPelamar: {
        $size: '$workerIds',
      },
    },
  },
  {
    $project: {
      'createdByData.password': 0,
      'updatedByData.password': 0,
    },
  },
]
const initial = () => ({
  data: null,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async getSummary({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
          },
        })
        .then(({ result, ...rest }) => {
          if (!payload?.returnOnly)
            commit('SET', {
              ...rest,
              pagesLength: state.pagesLength,
            })
          return { ...rest }
        })
        .catch((error) => Promise.reject(error))
    },
    async getSummaryTags({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              pipeline: [
                {
                  $group: {
                    _id: null,
                    tags: {
                      $push: '$tags',
                    },
                  },
                },
                {
                  $project: {
                    tags: {
                      $reduce: {
                        input: '$tags',
                        initialValue: [],
                        in: {
                          $concatArrays: ['$$value', '$$this'],
                        },
                      },
                    },
                  },
                },
                {
                  $unwind: {
                    path: '$tags',
                    preserveNullAndEmptyArrays: true,
                  },
                },
                {
                  $group: {
                    _id: '$tags',
                    total: {
                      $sum: 1,
                    },
                  },
                },
                {
                  $sort: {
                    total: -1,
                  },
                },
              ],
            }),
            limit: 50,
          },
        })
        .then(({ result, ...rest }) => {
          console.log(result)
          if (!payload?.returnOnly)
            commit('SET', {
              tags: result,
            })
          return { result, ...rest }
        })
        .catch((error) => Promise.reject(error))
    },
    async get({ state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            limit: 1,
          },
        })
        .then(({ result, ...rest }) => {
          return { ...result?.[0], ...rest }
        })
        .catch((error) => Promise.reject(error))
    },
    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            page: payload?.page || 1,
            limit: payload?.limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          if (!payload?.returnOnly)
            commit('SET', {
              data: result,
              pagesLength: rest.pagesLength,
            })
          return { result, ...rest }
        })
        .catch(async (err) => Promise.reject(err))
    },
    async create({ state }, payload) {
      return await this._vm.$api
        .post(collection + '/create', payload)
        .catch((error) => Promise.reject(error || 'Ada kesalahan'))
    },
    async update({ state }, payload) {
      return await this._vm.$api
        .patch(collection + '/update', payload)
        .catch((error) => Promise.reject(error || 'Ada kesalahan'))
    },
    async remove({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .delete(collection + '/remove', {
            params: {
              _id: payload,
            },
          })
          .then(async ({ success }) => success)
          .catch((error) => Promise.reject(error || 'Ada kesalahan'))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
