<template>
  <molecules-floating>
    <v-card
      elevation="0"
      rounded="lg"
      class="mx-auto pa-4 rounded-lg"
      :min-width="350"
      :max-width="$vuetify.breakpoint.smAndDown ? 350 : 700"
    >
      <v-card-title>
        <atoms-title :h3="true" class="font-weight-bold">Edit Backdate</atoms-title>
      </v-card-title>
      <v-card-text class="text-left pb-0">
        <atoms-text
          >Anda mengedit tanggal absensi
          <span class="primary--text">{{ data.worker && data.worker.fullName }}</span></atoms-text
        >
      </v-card-text>
      <v-card-text>
        <v-dialog ref="pickerFromDialog" v-model="from.modal" :return-value.sync="from.value" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <atoms-text-field label="Dari Tanggal">
              <v-text-field
                class="rounded-lg pb-4"
                placeholder="Dari Tanggal"
                append-icon="mdi-calendar"
                background-color="background"
                v-model="formatFromDate"
                v-bind="attrs"
                hide-details
                dense
                @click="clicked = true"
                v-on="on"
                readonly
                solo
                flat
              />
            </atoms-text-field>
          </template>
          <v-date-picker
            :max="$moment(data.phlJamSelesai).format('YYYY-MM-DD')"
            v-model="from.value"
            scrollable
            class="rounded-lg"
            @click:date="$refs.pickerFromDialog.save(from.value)"
          />
        </v-dialog>

        <atoms-button
          v-if="clicked"
          @click="resetDate"
          :loading="$store.state.loading"
          :style="{ width: '100%' }"
          class="red mb-2 white--text"
          >Reset Ke Awal</atoms-button
        >

        <atoms-button @click="updateDate" :loading="$store.state.loading" :style="{ width: '100%' }" class="primary"
          >Simpan</atoms-button
        >
        <atoms-button :style="{ width: '100%' }" class="mt-2 primary" @click="close">Kembali</atoms-button>
      </v-card-text>
    </v-card>
  </molecules-floating>
</template>
<script>
export default {
  props: {
    show: Boolean,
    data: Object,
  },
  data() {
    return {
      clicked: false,
      reset: false,
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
    }
  },
  computed: {
    formatFromDate: {
      set(value) {
        this.from.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.from.value).format('DD MMMM YYYY')
      },
    },
  },
  watch: {
    'from.value': function (value) {
      if (this.clicked && value !== this.data?.phlJamMulai) {
        this.reset = true
      } else {
        this.reset = false
      }
    },
  },
  mounted() {
    if (this.data && this.data?.worker) {
      this.formatFromDate = this.data?.phlJamMulai
    } else {
      this.$showDialog({
        title: 'Kesalahan',
        body: 'Gagal memuat data absensi pekerja',
      })
      this.close()
    }
  },
  methods: {
    close(payload) {
      this.reset = false
      this.clicked = false
      this.$emit('close', payload)
    },
    async updateDate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store
          .dispatch('attendance/update', {
            ...this.data,
            phlJamMulai: this.from.value,
          })
          .then((e) => {
            if (e?.result) {
              this.$showDialog({
                title: 'Berhasil',
                body: `Absensi ${this.data?.worker?.fullName} berhasil diperbarui!`,
              })
              this.close(e?.result)
            }
          })
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: error,
        })
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async resetDate() {
      this.formatFromDate = this.data?.phlJamMulai
      this.clicked = false
    },
  },
}
</script>
