<template>
  <v-img
    v:bind="$props"
    v-on="$listeners"
    :alt="`image of ${source || Math.random() * 1000}`"
    @click.stop="
      enableZoom &&
        $viewerApi({
          options: {
            toolbar: false,
          },
          images: [source],
        })
    "
    :width="width"
    :sizes="sizes"
    :height="height"
    :contain="contain"
    :max-height="maxH"
    :max-width="maxW"
    :min-height="minH"
    :min-width="minW"
    @error="notLoaded"
    :class="[rounded && 'rounded-lg', ...($props.class || [])]"
    :lazy-src="source"
    :src="source"
    :style="{
      cursor: enableZoom ? 'pointer' : 'auto',
      'box-shadow': shadow
        ? 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
        : 'none',
    }"
  >
    <slot
  /></v-img>
</template>

<script>
export default {
  props: [
    'src',
    'shadow',
    'rounded',
    'width',
    'height',
    'classes',
    'maxH',
    'maxW',
    'minW',
    'minH',
    'contain',
    'enableZoom',
    'sizes',
  ],
  data() {
    return {
      isLoaded: false,
      source: '',
    }
  },
  mounted() {
    this.source = this.src
  },
  methods: {
    notLoaded() {
      this.source = require('../../assets/default/broken.image.png')
      this.isLoaded = false
    },
  },
}
</script>
