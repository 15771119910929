<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('close')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="validate">
          <v-row justify="center" dense>
            <v-col cols="10">
              <atoms-title>{{ !id ? 'Tambah' : 'Perbarui' }} Redirect</atoms-title>
            </v-col>

            <v-col cols="10">
              <atoms-text-field label="Url" v-model="url" :rules="rules.default" />
            </v-col>
            <v-col cols="10" align="start">
              <atoms-text-field label="Keterangan">
                <v-textarea
                  background-color="accent"
                  class="rounded-lg"
                  placeholder="Keterangan URL"
                  loader-height="100"
                  v-model="description"
                  hide-details
                  dense
                  solo
                  flat
                ></v-textarea>
              </atoms-text-field>
            </v-col>
            <v-col cols="10">
              <atoms-button
                type="submit"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="['primary', 'mt-4']"
                >Simpan</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      readOnly: false,
      url: '',
      description: '',
      payload: null,
      rules: {
        default: [(v) => !!v || 'Harap Diisi'],
      },
      valid: true,
    }
  },
  mounted() {
    this.payload = (this.id && this.$store.state.redirects.data?.find((it) => it?._id === this.id)) || null
    if (this.payload) {
      this.url = this.payload?.url
      this.description = this.payload?.description
      this.content = this.payload?.content
    } else if (this.id && !this.payload) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal memuat data!`,
      })
      this.$emit('close')
    }
  },
  methods: {
    async validate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$refs.form.validate()
        if (this.url.slice(-1) == '/') {
          this.url = this.url.substring(0, this.url.length - 1)
        }
        if (this.valid) {
          let callback
          const _id = this.$uuid.v4()
          const payload = {
            ...((this.payload && this.payload) || {}),
            _id: this.id || _id,
            url: this.url,
            description: this.description,
          }
          if (!this.id) {
            payload.createdBy = this.$store.state.user?._id
            callback = await this.$store.dispatch('redirects/create', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Pertanyaan berhasil ditambahkan!`,
            })
          } else {
            payload.updatedBy = this.$store.state.user?._id
            callback = await this.$store.dispatch('redirects/update', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Pertanyaan berhasil diperbarui!`,
            })
          }
          if (!callback?.success) {
            Promise.reject('Kesalahan')
          } else
            confirm(
              `Redirects berhasil dibuat, gunakan link berikut untuk melacak visitor pada website hayokerja. Link : ${this.url}?redirect=${callback?.result?._id}`,
            )
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$emit('close')
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
