<template>
  <v-app>
    <v-scroll-x-transition>
      <molecules-sidebar />
    </v-scroll-x-transition>
    <v-main class="background">
      <div
        class="d-flex align-center justify-left accent pa-3"
        :style="{
          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        }"
      >
        <atoms-button icon class="rounded-pill" @click="$router.go(-1) || $router.push($route.meta.parent)">
          <v-icon>mdi-chevron-left</v-icon>
        </atoms-button>
        <atoms-title h3="true" class="pl-3">
          {{ $toCapitalize($route.meta.title || $route.meta.parent || '') }}
        </atoms-title>
      </div>
      <molecules-wrapper>
        <!-- <div > -->
        <v-container
          v-if="$store.state.break"
          class="d-flex flex-column justify-center align-center"
          :style="{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }"
        >
          <v-img width="300" :src="'/maintenance.svg'" />
          <atoms-title :h2="true" class="pt-8 px-8 text-center">Halaman ini sedang kami perbaiki </atoms-title>
          <atoms-text class="pa-4">Mohon maaf atas ketidaknyamanannya</atoms-text>
        </v-container>
        <!-- </div> -->
        <!-- <div v-else>
          <v-container
            v-if="!$store.state.allowed"
            class="d-flex flex-column justify-center align-center my-16 py-md-16"
          >
            <v-img width="300" :src="'/warning.svg'" />
            <atoms-title :h2="true" class="pa-8 text-center"
              >Anda tidak memiliki akses
            </atoms-title>
          </v-container>
        </div> -->
        <slot v-else />
      </molecules-wrapper>
      <!-- </div> -->
    </v-main>
  </v-app>
</template>
<script>
export default {}
</script>
