<template>
  <molecules-floating>
    <molecules-custom-dialog
      :show="showValidator"
      title="Apakah Anda Yakin?"
      :caption="`Anda bisa cek kembali sebelum melanjutkan`"
      :items="[
        {
          title: 'Batalkan',
          override: 'gray--text',
          full: false,
          exec: () => (showValidator = false),
        },
        {
          title: 'Ya, Lanjutkan',
          full: false,
          exec: () => {
            const numSlaPercentage = datas && datas.isUseSLA ? $convertPercenttoDecimal(slaPercentage) : 1
            const loc = locations.find((x) => {
              return locationId === x._id
            })
            const numPriceBase = Number($removeSeparator(priceBase))
            const totalBase = Number(amount * numPriceBase * quantity * numSlaPercentage)
            const numFee = Number($removeSeparator(fee))
            const totalFee = Number(amount * numFee * quantity * numSlaPercentage)
            const numPriceProposed = Number($removeSeparator(priceProposed))
            const totalProposed = Number(amount * numPriceProposed * quantity * numSlaPercentage)
            close({
              title,
              amount,
              quantity,
              startDate: startDate.value,
              endDate: endDate.value,
              clientId: client._id,
              locationId: loc ? loc._id : '',
              location: loc ? loc.name : '',
              areaId: this.area._id,
              areaTitle: this.area.title,
              priceBase: numPriceBase,
              totalBase,
              fee: numFee,
              totalFee: totalFee,
              priceProposed: numPriceProposed,
              purePriceProposed: numPriceProposed,
              totalProposed,
              pureTotalProposed: totalProposed,
              slaPercentage: numSlaPercentage,
              note,
              startTime,
              workerType,
              _id: datas && datas._id ? datas._id : $uuid.v4(),
            })
          },
        },
      ]"
    />
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container class="px-md-8">
        <v-container class="px-md-8">
          <v-form ref="form" v-model="valid" @submit.prevent="validate">
            <v-row class="text-left">
              <v-col cols="12" class="text-center">
                <atoms-title>{{ head }}</atoms-title>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="pickerStartDialog"
                  v-model="startDate.modal"
                  :return-value.sync="startDate.value"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Tanggal Mulai Pekerjaan" :rules="[]">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Tanggal Mulai Pekerjaan"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatStart"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate.value"
                    scrollable
                    color="primary"
                    class="rounded-lg"
                    @click:date="$refs.pickerStartDialog.save(startDate.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="pickerEndDateDialog"
                  v-model="endDate.modal"
                  :return-value.sync="endDate.value"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Tanggal Selesai Pekerjaan" :rules="[]">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Tanggal Selesai Pekerjaan"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatEnd"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate.value"
                    scrollable
                    color="primary"
                    class="rounded-lg"
                    @click:date="$refs.pickerEndDateDialog.save(endDate.value)"
                  />
                </v-dialog>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="12" class="py-0">
                <atoms-text-field :label="type ? 'Nama Shift' : 'Nama Shift'" v-model="title" :rules="rules.default" />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <atoms-text-field label="Area">
                  <v-autocomplete
                    class="rounded-lg"
                    :items="areas"
                    placeholder="Pilih Area"
                    v-model="areaId"
                    :rules="rules.default"
                    :menu-props="{ offsetY: true }"
                    :item-text="(_val) => `${_val.title} ${(_val?.inactive && '(inactive)') || ''}`"
                    item-value="_id"
                    required
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <atoms-text-field label="Lokasi">
                  <v-autocomplete
                    class="rounded-lg"
                    :items="locationItems"
                    placeholder="Pilih Lokasi"
                    v-model="locationId"
                    :rules="rules.default"
                    :menu-props="{ offsetY: true }"
                    :item-text="(_val) => `${_val.name} ${(_val?.inactive && '(inactive)') || ''}`"
                    item-value="_id"
                    required
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <atoms-text-field
                  :label="type ? 'AWB Volume' : 'Jumlah PHL yang Dibutuhkan'"
                  v-model="amount"
                  :rules="rules.default"
                  type="number"
                />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <atoms-text-field
                  :label="type ? 'Quantity' : 'Jumlah Hari Kerja'"
                  v-model="quantity"
                  type="number"
                  :rules="rules.default"
                />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <!-- Harga Modal -->
                <atoms-text-field label="Harga Modal" v-model="priceBase" :rules="rules.default" prefix="Rp." />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <!-- Harga Fee -->
                <atoms-text-field label="Harga Fee" v-model="fee" :rules="rules.default" prefix="Rp." />
              </v-col>
              <v-col cols="12" md="12" class="py-0 mb-3">
                <atoms-text-field label="Catatan" v-model="note" />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <atoms-time-picker label="Start Time" v-model="startTime" />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <atoms-time-picker label="End Time" v-model="endTime" />
              </v-col>
              <v-col cols="12" md="6">
                <atoms-text-field label="Tipe Worker">
                  <v-autocomplete
                    class="rounded-lg"
                    :items="[
                      { label: 'PHL', value: 'Casual' },
                      { label: 'Non PHL', value: 'Fulltime' },
                    ]"
                    placeholder="Pilih Tipe Worker"
                    v-model="workerType"
                    :menu-props="{ offsetY: true }"
                    item-text="label"
                    item-value="value"
                    required
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <span v-if="withSLA">
                  <atoms-text-field
                    :disabled="!datas.isUseSLA"
                    label="Persentase SLA"
                    v-model="slaPercentage"
                    color="accent"
                    :rules="[
                      (v) => !!v || 'Harap diisi!',
                      (v) => /\d+(?:,\d+)?%/gi.test(v) || 'Harus berupa persen! (contoh: 23% atau 23,37%)',
                    ]"
                  />
                </span>
              </v-col>
              <v-col cols="12" md="6"></v-col>
              <v-col cols="12" class="py-4">
                <atoms-text
                  ><strong>Total Modal: </strong>
                  {{ $idCurrency(amount * Number($removeSeparator(priceBase)) * quantity) }}</atoms-text
                >
                <atoms-text
                  ><strong>Total Fee: </strong>
                  {{ $idCurrency(amount * Number($removeSeparator(fee)) * quantity) }}</atoms-text
                >
                <atoms-text
                  ><strong>Total Penawaran: </strong>
                  {{ $idCurrency(amount * Number($removeSeparator(priceProposed)) * quantity) }}</atoms-text
                >
              </v-col>

              <v-col cols="12">
                <atoms-button :loading="$store.state.loading" type="submit" class="primary" style="width: 100%"
                  ><v-icon left>mdi-plus-box</v-icon>Simpan Pesanan</atoms-button
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['datas', 'type', 'client', 'withSLA'],
  data() {
    return {
      valid: true,
      showValidator: false,
      head: 'Tambah Pesanan',
      title: '',
      locationId: '',
      workerType: '',
      location: '',
      locations: [], // all location within client (not filtered by area)
      area: null,
      areaId: '',
      areas: [], // all area within client
      amount: 0,
      quantity: 0,
      priceBase: '',
      fee: '',
      priceProposed: '',
      slaPercentage: '100%',
      note: '',
      startTime: '',
      endTime: '',
      rules: {
        default: [(v) => !!v || 'Harap diisi!'],
      },
      startDate: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      endDate: {
        modal: false,
        value: this.$moment().add(1, 'days').format('YYYY-MM-DD'),
      },
    }
  },
  watch: {
    areaId: {
      deep: true,
      handler(newValue) {
        this.area = this.$store.state.area.data?.find((x) => {
          return x._id === newValue
        })
        // console.log('area', this.area)
      },
    },
    amount: function (newValue) {
      this.$nextTick(() => {
        this.amount = Math.abs(Number(newValue))
        // console.log(typeof this.amount)
      })
    },
    quantity: function (newValue) {
      this.$nextTick(() => (this.quantity = Math.abs(Number(newValue))))
    },
    priceBase: function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => {
        return (this.priceBase = result.trim())
      })
      this.calcPriceProposed()
    },
    fee: function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => {
        return (this.fee = result.trim())
      })
      this.calcPriceProposed()
    },
  },
  computed: {
    locationItems() {
      // filtered locations based on selected area
      return (
        this.$store.state.area.data?.flatMap((x) => {
          if (x._id === this.area?._id) {
            // return x.locations?.filter((y) => y.inactive !== true) || []
            return x.locations || []
          }
        }) || []
      )
    },
    formatStart: {
      set(value) {
        this.startDate.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.startDate.value).format('DD MMMM YYYY')
      },
    },
    formatEnd: {
      set(value) {
        this.endDate.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.endDate.value).format('DD MMMM YYYY')
      },
    },
  },
  async mounted() {
    this.$store.commit('SET', { loading: true })
    try {
      if (!this.client) {
        this.$emit('closed')
        throw new Error('Gagal Memuat Data')
      }
      await this.$store.dispatch('area/getAll', {
        query: {
          clientId: this.client?._id,
          // inactive: { $ne: true }, issue mbak windi karna area invoicenya difilter sehingga bikin error akibat tidak ditemukan
        },
        custom: {
          pipeline: [
            {
              $lookup: {
                from: 'Locations',
                localField: '_id',
                foreignField: 'areaId',
                as: 'locations',
              },
            },
          ],
        },
      })

      this.areas = this.$store.state.area.data
      // this.locations =
      //   this.$store.state.area.data?.flatMap((x) =>
      //     x.locations.filter((y) => y.inactive !== true).map((y) => ({ _id: y._id, name: y.name, area: x.title })),
      //   ) || [] // this variable contain all location of client //! GO TO locationItems to edit input field locations

      this.locations =
        this.$store.state.area.data?.flatMap((x) =>
          x.locations.map((y) => ({ _id: y._id, name: y.name, area: x.title })),
        ) || []
      // this variable contain all location of client //! GO TO locationItems to edit input field locations
      // console.log(this.locations)
      await this.isEditable()
    } catch (err) {
      Promise.reject(error)
    } finally {
      this.$store.commit('SET', { loading: false })
    }
  },
  errorCaptured() {
    this.$emit('closed')
  },
  methods: {
    close(payload) {
      if (payload) {
        let startDate = new Date(this.startDate.value);
        let endDate = new Date(this.endDate.value);

        let startTime = this.$moment(this.startTime, "HH:mm").toDate()
        let endTime = this.$moment(this.endTime, "HH:mm").toDate();

        payload.startDate = startDate;
        payload.endDate = endDate;
        payload.startTime = startTime;
        payload.endTime = endTime;
        payload._updatedDate = new Date();
      }
      // console.log({
      //   start: payload.startDate,
      //   end: payload.endDate,
      // })
      this.$emit('closed', payload)
    },
    calcPriceProposed() {
      this.priceProposed = this.$removeSeparator(this.priceBase) + this.$removeSeparator(this.fee)
    },
    isEditable() {
      this.$store.commit('SET', { loading: true })
      if (Object.keys(this.datas || {})?.[0]) {
        this.head = 'Edit Pesanan'
        const {
          title,
          amount,
          quantity,
          priceBase,
          fee,
          purePriceProposed,
          locationId,
          areaId,
          workerType,
          startDate,
          endDate,
          note,
          startTime,
          endTime,
          ...rest
        } = this.datas
        this.title = title || ''
        this.locationId = locationId
        this.workerType = workerType
        this.areaId = areaId
        this.slaPercentage = this.$convertDecimaltoPercent(rest?.slaPercentage || 0)
        this.amount = Number(amount || 0)
        this.quantity = quantity || 0
        this.priceBase = String(priceBase) || ''
        this.fee = String(fee) || ''
        this.priceProposed = String(purePriceProposed) || ''
        this.formatStart = new Date(new Date(startDate).setHours(0, 0, 0, 0))
        this.formatEnd = new Date(new Date(endDate).setHours(0, 0, 0, 0))
        this.note = note || '';
        this.startTime = startTime ? this.$moment(startTime).format('HH:mm') : undefined;
        this.endTime = endTime ? this.$moment(endTime).format('HH:mm') : undefined;
        // console.log({
        //   start: new Date(new Date(startDate).setHours(0, 0, 0, 0)),
        //   end: new Date(new Date(endDate).setHours(0, 0, 0, 0)),
        // })
      } else {
        this.head = 'Tambah Pesanan'
      }
      this.$store.commit('SET', { loading: false })
    },
    async validate() {
      try {
        this.$store.commit('SET', { loading: true })
        this.$refs.form.validate()
        if (this.valid) {
          this.showValidator = true
        }
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
