export default [
  {
    label: 'Aceh',
    value: 'Aceh',
  },
  {
    label: 'Bali',
    value: 'Bali',
  },
  {
    label: 'Bangka Belitung',
    value: 'Bangka Belitung',
  },
  {
    label: 'Banten',
    value: 'Banten',
  },
  {
    label: 'Bengkulu',
    value: 'Bengkulu',
  },
  {
    label: 'DKI Jakarta',
    value: 'DKI Jakarta',
  },
  {
    label: 'Gorontalo',
    value: 'Gorontalo',
  },
  {
    label: 'Jambi',
    value: 'Jambi',
  },
  {
    label: 'Jawa Barat',
    value: 'Jawa Barat',
  },
  {
    label: 'Jawa Tengah',
    value: 'Jawa Tengah',
  },
  {
    label: 'Jawa Timur',
    value: 'Jawa Timur',
  },
  {
    label: 'Kalimantan Barat',
    value: 'Kalimantan Barat',
  },
  {
    label: 'Kalimantan Selatan',
    value: 'Kalimantan Selatan',
  },
  {
    label: 'Kalimantan Tengah',
    value: 'Kalimantan Tengah',
  },
  {
    label: 'Kalimantan Timur',
    value: 'Kalimantan Timur',
  },
  {
    label: 'Kalimantan Utara',
    value: 'Kalimantan Utara',
  },
  {
    label: 'Kepulauan Riau',
    value: 'Kepulauan Riau',
  },
  {
    label: 'Lampung',
    value: 'Lampung',
  },
  {
    label: 'Maluku',
    value: 'Maluku',
  },
  {
    label: 'Maluku Utara',
    value: 'Maluku Utara',
  },
  {
    label: 'Nusa Tenggara Barat',
    value: 'Nusa Tenggara Barat',
  },
  {
    label: 'Nusa Tenggara Timur',
    value: 'Nusa Tenggara Timur',
  },
  {
    label: 'Papua',
    value: 'Papua',
  },
  {
    label: 'Papua Barat',
    value: 'Papua Barat',
  },
  {
    label: 'Riau',
    value: 'Riau',
  },
  {
    label: 'Sulawasi Tenggara',
    value: 'Sulawasi Tenggara',
  },
  {
    label: 'Sulawesi Barat',
    value: 'Sulawesi Barat',
  },
  {
    label: 'Sulawesi Selatan',
    value: 'Sulawesi Selatan',
  },
  {
    label: 'Sulawesi Tengah',
    value: 'Sulawesi Tengah',
  },
  {
    label: 'Sulawesi Utara',
    value: 'Sulawesi Utara',
  },
  {
    label: 'Sumatra Barat',
    value: 'Sumatra Barat',
  },
  {
    label: 'Sumatra Selatan',
    value: 'Sumatra Selatan',
  },
  {
    label: 'Sumatra Utara',
    value: 'Sumatra Utara',
  },
  {
    label: 'Yogyakarta',
    value: 'Yogyakarta',
  },
]
