<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        'min-height': '10%',
      }"
      class="overflow-x-hidden overflow-y-auto rounded-lg text-center"
    >
      <atoms-button @click="$emit('closed')" style="position: fixed; z-index: 2; left: 0" :icon="true" class="ma-2"
        ><v-icon :class="[file.data.type.includes('video') && 'white--text']">mdi-close</v-icon></atoms-button
      >
      <div class="background rounded-lg">
        <div v-if="file && file.data" class="accent">
          <atoms-image
            v-if="file.data.type.includes('image') || file.data.type.includes('sticker')"
            :enableZoom="true"
            :src="file.url"
            style="height: 350px"
            :contain="true"
          />
          <div v-else-if="file.data.type.includes('video')" style="height: 350px">
            <vue-plyr class="rounded-lg">
              <video controls crossorigin playsinline data-poster="wa-video.jpg">
                <source size="720" :src="file.url" :type="file.data.mimetype" />
                <track default kind="captions" label="English captions" :src="file.url" srclang="en" />
              </video>
            </vue-plyr>
          </div>
          <div v-else class="d-flex justify-center align-center flex-column rounded-lg pa-8">
            <atoms-image width="150" src="/assets/no-preview.svg" class="ma-4" />
            <atoms-title>Tidak dapat menampilkan preview</atoms-title>
            <atoms-text class="my-2">
              {{ file.data.name }}
            </atoms-text>
          </div>
        </div>
        <atoms-text-field :noLabel="true">
          <v-text-field
            :disabled="!$store.state.isReady"
            background-color="background"
            class="rounded-lg"
            append-icon="mdi-send"
            placeholder="Caption"
            hide-details
            dense
            solo
            flat
          />
        </atoms-text-field>
      </div></div
  ></molecules-floating>
</template>
<script>
export default {
  props: ['file', 'chat'],
  data() {
    return { msg: '' }
  },
  mounted() {
    console.log(this.file)
  },
  methods: {
    async sendMessage() {
      this.$store.commit('SET', { loading: true })
      try {
        if (this.file && this.chat) {
          const blob = await this.$toBase64(this.file.data)
          console.log(blob)
          this.$socket.emit('send_message', {
            origin: this.chat,
            message: this.msg,
            media: {
              blob,
              data: this.file.data,
            },
          })
        }
      } finally {
        this.$store.commit('SET', { loading: false })
      }
      this.$emit('closed')
    },
  },
}
</script>
