<template>
  <span
    v-if="span"
    v:bind="$props"
    v-on="$listeners"
    @click="trigger"
    :class="[
      ...defaultClass,
      ...nonP,
      ...((classes && classes) || []),
      href ? 'primary--text text-decoration-underline' : '',
    ]"
    :style="{
      cursor: href ? 'pointer' : 'default',
    }"
    ><slot
  /></span>
  <!-- !auto && !this.$vuetify.theme.dark && 'grey--text text--darken-1', -->
  <p
    v-else
    v:bind="$props"
    v-on="$listeners"
    @click="trigger"
    :style="{
      cursor: href ? 'pointer' : 'default',
    }"
    :class="[
      ...defaultClass,
      ...itsP,
      ...((classes && classes) || []),
      href ? 'primary--text text-decoration-underline' : '',
    ]"
  >
    <slot />
  </p>
</template>
<script>
export default {
  props: ['p', 'span', 'classes', 'auto', 'href', 'target'],
  data() {
    return {
      defaultClass: ['font-weight-normal pa-0 ma-0'],
      itsP: ['body-1'],
      nonP: ['body-2'],
    }
  },
  methods: {
    trigger() {
      if (this.href) {
        window.open(this.href, this.target || '')
      }
    },
  },
}
</script>
<style lang="scss">
h1,
h2,
h3 {
  letter-spacing: 0rem;
}
</style>
