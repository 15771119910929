<template>
  <v-app class="accent">
    <molecules-wrapper v-if="$store.state.user.isLogged">
      <slot />
    </molecules-wrapper>
    <v-container
      v-else
      class="d-flex flex-column justify-center align-center"
      :style="{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      }"
    >
      <v-img width="300" :src="'/cancel.svg'" />
      <atoms-title :h2="true" class="pa-8 text-center">Anda tidak memiliki akses </atoms-title>
    </v-container>
  </v-app>
</template>
<script>
export default {
  metaInfo: {
    title: 'Blank',
    titleTemplate: `%s |  Blank`,
    htmlAttrs: { lang: 'en' },
    meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
  },
}
</script>
