var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"background overflow-x-hidden"},[_c('v-expand-transition',[(!_vm.$store.state.init)?_c('div',[_c('AppLayout',[_c('molecules-loading'),(_vm.$store.state.user.isLogged)?_c('molecules-dialog'):_vm._e(),_c('molecules-custom-dialog',{attrs:{"show":_vm.showMigrationNotification,"strict":true,"width":"350px","title":"Informasi Website","caption":"Website ini sudah tidak mendapatkan pembaruan fitur, sehingga untuk mendapatkannya Anda dapat mengakses website baru internal hayokerja pada https://hayosystems-v3.web.app/","items":[
            {
              title: 'Tutup',
              full: true,
              exec: () => (_vm.showMigrationNotification = false),
            },
            {
              title: 'Ya, buka website baru',
              override: 'red--text',
              full: true,
              exec: () => {
                _vm.$window.location.replace('https://hayosystems-v3.web.app/')
                _vm.showMigrationNotification = false
              },
            },
          ]}}),_c('router-view')],1)],1):_c('div',[_c('v-sheet',{staticClass:"transparent",style:({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        })},[_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('atoms-image',{style:({ margin: 'auto' }),attrs:{"width":"100","src":"/favicon.ico"}}),_c('atoms-text',{staticClass:"my-4 text-capitalize font-weight-medium"},[_vm._v(_vm._s(_vm.$store.state.log + '...'))])],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }