<template>
  <molecules-floating>
    <div
      ref="content"
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '70%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-container>
        <v-row>
          <v-col cols="12" align="start">
            <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-form :disabled="readOnly" v-model="valid" @submit.prevent="preValidate" ref="form">
          <v-row justify="center" class="pt-4">
            <v-col cols="10">
              <atoms-title v-if="!readOnly">Sales B2B {{ id ? 'Edit' : 'Baru' }}</atoms-title>
              <atoms-title v-else>Kasus Detail</atoms-title>
            </v-col>
            <v-col cols="10">
              <template v-if="!id && !readOnly">
                <atoms-text-field
                  v-if="!$store.state.client.data || !client.enabled"
                  label="Cari Client"
                  @keyup.enter.native="clientSearch"
                  v-model="client.term"
                  :rules="[(v) => (!!v && !!client.item) || 'Harap Pilih Klien']"
                />
                <atoms-text-field v-else label="Pilih Client">
                  <v-autocomplete
                    class="rounded-lg"
                    v-model="client.value"
                    @keyup.esc.native="clientSearch"
                    :items="$store.state.client.data.map((x) => x.name)"
                    placeholder="Pilih Client"
                    :menu-props="{ offsetY: true }"
                    :rules="[(v) => !!client.item || 'Harap Pilih Klien']"
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
                <atoms-button
                  @click="clientSearch"
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  :class="[!$store.state.client.data || !client.enabled ? 'primary' : 'red white--text', 'mb-4']"
                  >{{ !$store.state.client.data || !client.enabled ? 'Cari Client' : 'Reset Pencarian' }}</atoms-button
                >
              </template>
              <div v-if="client.item && client.enabled" class="text-start">
                <atoms-text class="my-4 font-weight-bold">Client Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Nama:</span> {{ client.item.name }}</atoms-text
                  >
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Alamat:</span>
                    {{ (client.item.address && client.item.address.streetAddress1) || '-' }}</atoms-text
                  >
                  <atoms-text><span class="font-weight-bold primary--text">Area Terdaftar:</span> </atoms-text>
                  <ul>
                    <li v-for="(x, i) in (client.item.areas && client.item.areas.map((x) => x.title)) || []" :key="i">
                      {{ x }}
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
            <v-col cols="10">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field label="Jenis Industri" v-model="jenisIndustri" :rules="[]" />
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field
                label="Kebutuhan Pekerja"
                v-model="kebutuhan"
                :rules="rules.kebutuhan"
                type="number  "
              />
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field label="Upah Ke PHL" prefix="Rp." v-model="upah" :rules="rules.upah" />
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field
                :label="`Penawaran Ke Client (margin: ${$convertDecimaltoPercent(marginPercentage)})`"
                :name="'penawaran'"
                message="Contoh: 35.000"
                prefix="Rp."
                v-model="penawaran"
                :rules="rules.penawaran"
              />
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field label="Nama PIC" v-model="namaPIC" :rules="[]" />
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field label="PIC Phone" v-model="phonePIC" :rules="rules.phonePIC" />
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field label="Mobile" v-model="mobilePIC" :rules="rules.mobilePIC" />
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field label="PIC Email" v-model="emailPIC" :rules="rules.emailPIC" />
            </v-col>
            <v-col cols="10" md="5">
              <v-dialog
                ref="pickerDialog"
                v-model="lastContacted.modal"
                :return-value.sync="lastContacted.value"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <atoms-text-field label="Last Contacted">
                    <v-text-field
                      :disabled="readOnly"
                      class="rounded-lg"
                      placeholder="Last Contacted"
                      append-icon="mdi-calendar"
                      background-color="accent"
                      v-model="formatDate"
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.default"
                      readonly
                      solo
                      flat
                      dense
                    />
                  </atoms-text-field>
                </template>
                <v-date-picker
                  :max="$moment().endOf('month').format('YYYY-MM-DD')"
                  v-model="lastContacted.value"
                  scrollable
                  class="rounded-lg"
                  @click:date="$refs.pickerDialog.save(lastContacted.value)"
                />
              </v-dialog>
            </v-col>
            <v-col cols="10" md="5" class="pb-0">
              <atoms-text-field label="Posisi Yang Dicari" v-model="posisi" :rules="[]" />
            </v-col>
            <v-col cols="10" md="5" class="pb-0 pb-md-auto">
              <atoms-text-field label="Stage">
                <v-autocomplete
                  class="rounded-lg"
                  placeholder="Stage"
                  prepend-inner-icon="mdi-filter"
                  :items="Object.values($salesStage).filter((x) => x !== $salesStage.semua)"
                  :rules="rules.default"
                  v-model="stage"
                  dense
                  solo
                  flat
                  required
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="10" md="5" class="py-0 py-md-3">
              <atoms-text-field label="Status">
                <v-autocomplete
                  class="rounded-lg"
                  placeholder="Status"
                  prepend-inner-icon="mdi-filter-variant"
                  :items="Object.values($salesStatus).filter((x) => x !== $salesStatus.semua)"
                  :rules="rules.default"
                  v-model="status"
                  dense
                  solo
                  flat
                  required
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="10">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10">
              <template v-if="!readOnly">
                <atoms-text-field
                  v-if="!$store.state.user.data || !manager.enabled"
                  label="Cari Manager"
                  v-model="manager.term"
                  :rules="[(v) => !!v || !!manager.item || 'Harap Pilih Manager']"
                  @keyup.enter.native="managerSearch"
                />
                <atoms-text-field v-else label="Pilih Manager">
                  <v-autocomplete
                    class="rounded-lg"
                    v-model="manager.value"
                    :items="$store.state.user.data.map((x) => x.nickname + '-' + x.email)"
                    @keyup.esc.native="managerSearch"
                    placeholder="Pilih Manager"
                    :menu-props="{ offsetY: true }"
                    :rules="[(v) => !!v || 'Harap disii!']"
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
                <atoms-button
                  @click="managerSearch"
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  :class="[!$store.state.user.data || !manager.enabled ? 'primary' : 'red white--text', 'mb-4']"
                  >{{ !$store.state.user.data || !manager.enabled ? 'Cari Manager' : 'Reset Pencarian' }}</atoms-button
                >
              </template>
              <div v-if="manager.item && manager.enabled" class="text-left my-4">
                <atoms-text class="my-4 font-weight-bold">Manager Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Nama:</span> {{ manager.item.nickname }}</atoms-text
                  >
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Email:</span> {{ manager.item.email }}</atoms-text
                  >
                </div>
              </div>
            </v-col>
            <v-col cols="10">
              <v-divider />
            </v-col>
            <v-col cols="10" v-if="!readOnly">
              <!-- user -->
              <atoms-text-field
                v-if="!$store.state.user.data || !salesRepData.enabled"
                label="Sales Representative"
                v-model="salesRepData.term"
                @keyup.enter.native="salesSearch"
                :rules="[
                  (v) => (!!salesRepData.items && salesRepData.items.length > 0) || !!v || 'Harap Pilih Responder',
                ]"
              />
              <div v-else>
                <v-tooltip top attach="">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Sales Representative">
                      <v-autocomplete
                        v-bind="attrs"
                        v-on="on"
                        name="salesRepData"
                        class="rounded-lg"
                        v-model="salesRepData.value"
                        @keyup.esc.native="salesSearch"
                        :items="$store.state.user.data.map((x) => x.nickname + '-' + x.email)"
                        @keyup.enter.native="
                          () => {
                            pushSalesRepData()
                            $refs.form.validate('salesRepData')
                          }
                        "
                        placeholder="Pilih Akun"
                        prepend-icon="mdi-plus"
                        @click:prepend="
                          () => {
                            pushSalesRepData()
                            $refs.form.validate('salesRepData')
                          }
                        "
                        :menu-props="{ offsetY: true }"
                        :rules="[
                          (v) => (!!salesRepData.items && salesRepData.items.length > 0) || 'Harap Pilih Responder',
                        ]"
                        dense
                        solo
                        flat
                      />
                    </atoms-text-field>
                  </template>
                  <span>Klik Icon (+) Tambah untuk menambahkan akun!</span>
                </v-tooltip>
              </div>

              <atoms-button
                @click="salesSearch"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="[!$store.state.user.data || !salesRepData.enabled ? 'primary' : 'red white--text', 'mb-4']"
                >{{ !$store.state.user.data || !salesRepData.enabled ? 'Cari Sales' : 'Reset Pencarian' }}</atoms-button
              >
            </v-col>

            <v-col cols="10" v-if="readOnly" align="start">
              <atoms-text class="font-weight-bold">Sales Representative</atoms-text>
            </v-col>
            <v-col v-if="salesRepData.items" cols="10">
              <v-data-table
                :headers="[
                  {
                    text: 'Akun',
                    sortable: false,
                    value: 'nickname',
                    class: 'primary--text text-uppercase',
                  },
                  {
                    text: 'Email',
                    sortable: false,
                    value: 'email',
                    class: 'primary--text text-uppercase',
                  },
                  {
                    text: 'Aksi',
                    sortable: false,
                    value: 'action',
                    class: 'text-uppercase text-center',
                  },
                ]"
                :items="salesRepData.items || []"
                class="accent rounded-lg"
                hide-default-footer
                hide-default-header
              >
                <template v-slot:[`header`]="{ props }">
                  <thead>
                    <tr class="d-table-row">
                      <th v-for="h in props.headers" :key="h.value" :class="h.class">
                        <span>{{ h.text }}</span>
                      </th>
                    </tr>
                  </thead></template
                >

                <template v-slot:[`item`]="{ item }">
                  <tr class="d-table-row">
                    <td class="text-left">{{ item.nickname }}</td>
                    <td class="text-left">{{ item.email }}</td>
                    <td class="text-center">
                      <v-icon :disabled="readOnly" @click="unPushSalesRepData(item._id)" small> mdi-delete </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="10">
              <v-divider />
            </v-col>

            <v-col cols="10">
              <atoms-text-field label="Remarks">
                <v-textarea
                  background-color="accent"
                  class="rounded-lg"
                  placeholder="Remarks"
                  loader-height="100"
                  v-model="remarks"
                  hide-details
                  dense
                  solo
                  flat
                ></v-textarea>
              </atoms-text-field>
            </v-col>
            <v-col cols="10" v-if="!readOnly">
              <atoms-button @click="preValidate" style="width: 100%" class="primary">
                <v-icon left>mdi-check</v-icon>Simpan Proses</atoms-button
              >
            </v-col>
            <v-col v-if="id && !readOnly" cols="10">
              <atoms-button @click="loadData" style="width: 100%" class="red white--text">
                <v-icon left>mdi-history</v-icon>Reset Proses</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id', 'readOnly'],
  data() {
    return {
      data: null,
      remarks: '',
      client: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
      manager: {
        //managerID
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
      salesRepData: {
        enabled: false,
        value: '',
        item: null,
        items: null,
        term: '',
      },
      lastContacted: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      jenisIndustri: '',
      kebutuhan: 0,
      upah: '',
      penawaran: '',
      namaPIC: '',
      phonePIC: '',
      mobilePIC: '',
      emailPIC: '',
      posisi: '',
      stage: '',
      status: '',
      valid: true,
      showEdit: null,

      rules: {
        default: [(v) => !!v || 'Harap diisi!'],
        penawaran: [
          (v) => {
            const upah = this.$removeSeparator(this.salesB2B?.upah)
            const penawaran = this.$removeSeparator(this.salesB2B?.penawaran)
            if (penawaran - upah < 0) {
              return 'Penawaran tidak boleh lebih kecil dibandingkan upah'
            }
            if (this.marginPercentage < 0.15) {
              return 'Margin tidak boleh kurang dari 15%'
            }
            return true
          },
        ],
        kebutuhan: [(v) => !v || /^\d+(\.\d+)*$/i.test(v) || 'Harus Angka'],
        phonePIC: [(v) => !v || /\d+/i.test(v) || 'Harus Angka'],
        mobilePIC: [(v) => !v || /\d+/i.test(v) || 'Harus Angka'],
        emailPIC: [(v) => !v || /.+@.+/.test(v) || 'E-mail harus valid'],
      },
    }
  },
  watch: {
    upah: function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => {
        this.upah = result
        this.$refs?.form?.validate('penawaran')
      })
    },
    penawaran: function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => (this.penawaran = result))
    },
    'salesRepData.value'() {
      if (this.salesRepData.value) {
        this.salesRepData.item =
          this.$store.state.user?.data?.find((x) => x?.email === this.salesRepData.value?.split('-')?.[1]) ||
          this.data?.personResp?.[0]
      }
    },
    'manager.value'() {
      if (this.manager.value) {
        this.manager.item =
          this.$store.state.user?.data?.find((x) => x.email === this.manager.value?.split('-')[1]?.trim()) ||
          this.data?.manager
      }
    },
    'client.value'() {
      if (this.client.value) {
        this.client.item =
          this.$store.state.client?.data?.find((x) => x.name === this.client.value) || this.data?.client
      }
    },
  },
  computed: {
    formatDate: {
      set(value) {
        if (value) {
          this.lastContacted.value = this.$moment(value).format('YYYY-MM-DD')
        }
      },
      get() {
        return this.$moment(this.lastContacted?.value || new Date()).format('DD MMMM YYYY')
      },
    },
    marginPercentage() {
      const upah = this.$removeSeparator(this.upah)
      const penawaran = this.$removeSeparator(this.penawaran)
      let marginPercentage = 0
      if (penawaran > 0) {
        marginPercentage = (penawaran - upah) / penawaran
      }
      return marginPercentage
    },
  },
  errorCaptured() {
    this.$emit('closed')
  },
  async mounted() {
    await this.loadData()
    if (this.id && !this.data) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal ketika memuat data kasus`,
      })
      this.$emit('closed')
    }
  },
  methods: {
    async getUsers(payload) {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: payload ? this.manager.term : this.salesRepData.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('user/getAll', {
          custom: {
            $or: [
              {
                nickname: term,
              },
              {
                email: term,
              },
              {
                roles: term,
              },
            ],
            pipeline: [...this.$store.state.user.defaultPipeline],
          },
          page: 0,
          limit: 100,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.client.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 0,
          limit: 100,
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    clientSearch() {
      if (!this.$store.state.client.data || !this.client.enabled) {
        this.getClients()
        this.client.term = ''
        this.client.enabled = true
      } else {
        this.client.enabled = false
      }
    },
    managerSearch() {
      if (!this.$store.state.user.data || !this.manager.enabled) {
        this.getUsers(true)
        this.manager.enabled = true
      } else {
        this.manager = {
          ...this.manager,
          item: null,
          value: null,
          enabled: false,
        }
      }
      this.manager.term = ''
    },
    salesSearch() {
      if (!this.$store.state.user.data || !this.salesRepData.enabled) {
        this.getUsers()
        this.salesRepData.term = ''
        this.salesRepData.enabled = true
      } else {
        this.salesRepData.enabled = false
      }
    },
    async loadData() {
      if (this.id) {
        this.data = await this.$store.dispatch('salesb2b/get', {
          query: {
            _id: this.id,
          },
        })
      }
      if (this.data) {
        this.client.value = this.data?.client?.name
        this.client.enabled = Boolean(this.client.value)
        this.manager.value = this.data?.manager?.nickname
        this.manager.enabled = Boolean(this.manager.value)
        this.salesRepData.items = this.data?.sales || []
        this.salesRepData.value = this.data?.sales?.[0]?.nickname
        //
        this.jenisIndustri = this.data?.jenisIndustri
        this.kebutuhan = this.data?.kebutuhan || 0
        this.upah = (this.data?.upah && String(this.data.upah)) || '0'
        this.penawaran = (this.data?.penawaran && String(this.data?.penawaran)) || '0'

        this.namaPIC = this.data?.namaPIC
        this.phonePIC = this.data?.phonePIC
        this.emailPIC = this.data?.emailPIC
        this.mobilePIC = this.data?.mobilePIC
        this.remarks = this.data?.remarks

        this.lastContacted.value = this.$moment(this.data?.lastContacted || new Date())?.format('YYYY-MM-DD')
        this.posisi = this.data?.posisi
        this.stage = this.data?.stage
        this.status = this.data?.status
        await this.$refs.form.validate()
      }
    },
    // person respon
    async pushSalesRepData() {
      if (
        !this.salesRepData?.items?.find((x) => (x?._id || x) === this.salesRepData?.item?._id) &&
        this.salesRepData?.item
      ) {
        this.salesRepData.items = [...(this.salesRepData?.items || []), this.salesRepData?.item]
      } else {
        this.$showDialog({
          title: 'Perhatian',
          body: !this.salesRepData.item
            ? 'Pilih akun terlebih dahulu!'
            : `Akun yang ada ingin tambahkan sudah masuk kedalam`,
        })
      }
    },
    async unPushSalesRepData(id) {
      if (id && this.salesRepData?.items) {
        this.salesRepData.items = this.salesRepData.items.filter((x) => x._id !== id)
        this.$refs?.form?.validate('salesRepData')
      }
    },

    async preValidate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$refs.form.validate()
        if (this.valid && !this.readOnly) {
          await this.validate()
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: 'Periksa kembali kolom pengisian',
          })
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      try {
        if (this.salesRepData.items?.length > 0) {
          const extras = await this.salesB2BPurifiedAsync(this?.data || {})
          const payload = await new Promise(async (res, rej) => {
            res({
              ...(extras || {}),
              updatedBy: this.$store.state.user?._id,
            })
          })
          // console.log(payload);
          if (this.id) {
            delete payload?.client
            await this.$store.dispatch('salesb2b/update', payload)
          } else {
            await this.$store.dispatch('salesb2b/create', payload)
          }
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$showDialog({
            title: 'Berhasil',
            body: `Proses baru berhasil di${(this.id && 'perbarui') || 'tambahkan'}!`,
          })
          this.$emit('closed')
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: 'Pastikan anda sudah menambahkan setidaknya 1 responder!',
          })
        }
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: error,
        })
        Promise.reject(error)
      }
    },
    async salesB2BPurifiedAsync(payload) {
      const _id = (this.id && this.data?._id) || this.$uuid.v4()
      const salesRep = this.salesRepData?.items?.map((it) => it?._id)
      return {
        _id,
        ...payload,
        kebutuhan: this.kebutuhan <= 0 ? this.kebutuhan : null,
        upah: this.upah ? this.$removeSeparator(this.upah) : null,
        penawaran: this.$removeSeparator(this.penawaran),
        klien: this.client.item?._id,
        jenisIndustri: this.jenisIndustri,
        stage: this.stage,
        posisi: this.posisi,
        namaPIC: this.namaPIC,
        phonePIC: this.phonePIC,
        emailPIC: this.emailPIC,
        mobilePIC: this.mobilePIC,
        lastContacted: this.lastContacted.value,
        managerId: this.manager.item?._id,
        status: this.status,
        remarks: this.remarks,
        salesRep,
      }
    },
  },
}
</script>
