var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('molecules-wrapper',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('atoms-title',[_vm._v("Tambah Pekerja Shift "+_vm._s(_vm.shift.title || '-'))])],1),_c('v-col',{staticClass:"rounded-lg my-2",attrs:{"cols":"10"}},[(!_vm.$store.state.user.data || !_vm.user.enabled)?_c('atoms-text-field',{attrs:{"label":"Cari Pekerja","rules":[]},model:{value:(_vm.user.term),callback:function ($$v) {_vm.$set(_vm.user, "term", $$v)},expression:"user.term"}}):_c('atoms-text-field',{attrs:{"label":"Pilih Pekerja"}},[_c('v-combobox',{staticClass:"rounded-lg",attrs:{"items":_vm.$store.state.user.data || [],"item-text":(x) => x.nickname + ' → ' + x.email,"placeholder":"Pilih Pekerja","menu-props":{ offsetY: true },"rules":[(v) => !!v || 'Harap disii!'],"dense":"","solo":"","flat":""},model:{value:(_vm.user.value),callback:function ($$v) {_vm.$set(_vm.user, "value", $$v)},expression:"user.value"}})],1),_c('atoms-button',{class:[!_vm.$store.state.user.data || !_vm.user.enabled ? 'primary' : 'red white--text', 'mb-4'],style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":() => {
                  if (!_vm.$store.state.user.data || !_vm.user.enabled) {
                    _vm.getUsers()
                    _vm.user.enabled = true
                  } else {
                    _vm.user.term = ''
                    _vm.user.value = null
                    _vm.user.enabled = false
                  }
                }}},[_vm._v(_vm._s(!_vm.$store.state.user.data || !_vm.user.enabled ? 'Cari Pekerja' : 'Reset Pencarian'))]),(_vm.user.value && _vm.user.enabled)?_c('div',{staticClass:"text-center my-4"},[_c('atoms-text',{staticClass:"my-1 font-weight-bold"},[_vm._v("Worker Terpilih")]),_c('div',{staticClass:"pa-2 accent rounded-lg text-left"},[_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Nama:")]),_vm._v(" "+_vm._s(_vm.user.value.nickname))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.user.value.email))])],1)],1):_vm._e(),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"disabled":!(_vm.user.value && _vm.user.enabled),"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v("Daftarkan Pekerja")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }