import router from '@/router/index'
/* eslint-disable */
const collection = 'SalesB2B'

const defaultPipeline = [
  {
    $lookup: {
      from: 'Users',
      localField: 'salesRep',
      foreignField: '_id',
      as: 'sales',
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'managerId',
      foreignField: '_id',
      as: 'manager',
    },
  },
  {
    $unwind: {
      path: '$manager',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'updatedBy',
      foreignField: '_id',
      as: 'user',
    },
  },
  {
    $unwind: {
      path: '$user',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Clients',
      localField: 'klien',
      foreignField: '_id',
      as: 'client',
    },
  },
  {
    $unwind: {
      path: '$client',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Invoices',
      localField: 'klien',
      foreignField: 'clientId',
      as: 'invoices',
    },
  },
  {
    $project: {
      'manager.password': 0,
      'user.password': 0,
      'sales.password': 0,
    },
  },
]
const initial = () => ({
  data: null,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async get({ state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            limit: 1,
          },
        })
        .then(({ result, ...rest }) => {
          return { ...result?.[0], ...rest }
        })
        .catch((error) => Promise.reject(error))
    },
    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            page: payload?.page || 1,
            limit: payload?.limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          // result = result.map((it) => {
          //   return {
          //     ...it,
          //     totalPenawaran: it.totalPenawaran,
          //     clientLogo: it.client?.logo,
          //     clientName: it.client?.name,
          //     managerPicture: it.manager?.picture,
          //     managerName: it.manager?.nickname,
          //     isInternal: it.client?.isHkReg ? 'Internal' : 'Eksternal',
          //     updatedByName: it.updatedByData?.nickname || it.updatedByData?.email,
          //   }
          // })

          if (!payload?.returnOnly)
            commit('SET', {
              data: result,
              ...rest,
            })

          // let users = []
          // const lastUsers = (await this._vm.$get_db('users'))?.map((it) => it._id)
          // result.forEach((it) => {
          //   if (it?.salesRep?.length > 0) users = users.concat(it.salesRep)
          //   if (it.managerId) users.push(it.managerId)
          //   if (it.updatedBy) users.push(it.updatedBy)
          // })

          // users = [...new Set(users)]?.filter(async (it) => lastUsers?.includes(it))

          // console.log(users)

          // console.log(result)

          return result
        })
        .catch(async (err) => Promise.reject(err))
    },
    async updateConversation({ commit }, { payload, params, ...rest }) {
      return await this._vm.$api
        .patch(collection + '/updateConversation', { ...payload, ...rest }, { ...params })
        .then(({ success, result }) => {
          return { success, result }
        })
        .catch((error) => Promise.reject(error || 'Ada kesalahan'))
    },
    async create({ state }, payload) {
      return await this._vm.$api
        .post(collection + '/create', payload)
        .catch((error) => Promise.reject(error || 'Ada kesalahan'))
    },
    async update({ state }, payload) {
      return await this._vm.$api
        .patch(collection + '/update', payload)
        .catch((error) => Promise.reject(error || 'Ada kesalahan'))
    },
    async remove({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .delete(collection + '/remove', {
            params: {
              _id: payload,
            },
          })
          .then(async ({ success }) => success)
          .catch((error) => Promise.reject(error || 'Ada kesalahan'))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
