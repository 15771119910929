var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[(_vm.vacancy)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.preValidate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v(_vm._s(_vm.vacancy.headHunter ? 'Edit' : 'Tambah')+" HeadHunter")]),_c('atoms-text',[_vm._v("Lowongan "+_vm._s(_vm.vacancy.title))]),(_vm.vacancy.headHunter)?_c('v-chip',{staticClass:"px-2 mt-2"},[(_vm.vacancy.headHunter.user)?_c('v-avatar',{staticClass:"rounded-pill",style:({
                  cursor: 'pointer',
                }),attrs:{"color":"accent"}},[(_vm.vacancy.headHunter.user.picture)?_c('atoms-image',{attrs:{"aspect-ratio":"1","src":_vm.vacancy.headHunter.user.picture || `/lazy-img.webp`}}):_c('v-icon',[_vm._v(" mdi-account ")])],1):_vm._e(),_c('atoms-text',[_vm._v(_vm._s(_vm.vacancy.headHunter.fullName))]),_c('atoms-text',[_vm._v("-(Terkini)")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_c('div',[(!_vm.$store.state.user.data || !_vm.user.enabled)?_c('atoms-text-field',{attrs:{"label":"Cari User","rules":[]},model:{value:(_vm.user.term),callback:function ($$v) {_vm.$set(_vm.user, "term", $$v)},expression:"user.term"}}):_c('atoms-text-field',{attrs:{"label":"Pilih User"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"items":(_vm.$store.state.user.data && _vm.$store.state.user.data.map((x) => x.nickname + ' → ' + x.email)) || [],"placeholder":"Pilih User","menu-props":{ offsetY: true },"rules":[(v) => !!v || 'Harap disii!'],"dense":"","solo":"","flat":""},model:{value:(_vm.user.value),callback:function ($$v) {_vm.$set(_vm.user, "value", $$v)},expression:"user.value"}})],1),_c('atoms-button',{class:[!_vm.$store.state.user.data || !_vm.user.enabled ? 'primary' : 'red white--text', 'mb-4'],style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":() => {
                    if (!_vm.$store.state.user.data || !_vm.user.enabled) {
                      _vm.getUsers()
                      _vm.user.term = ''
                      _vm.user.term = ''
                      _vm.user.enabled = true
                    } else {
                      _vm.user.enabled = false
                    }
                  }}},[_vm._v(_vm._s(!_vm.$store.state.user.data || !_vm.user.enabled ? 'Cari User' : 'Reset Pencarian'))]),(_vm.user.item && _vm.user.enabled)?_c('div',{staticClass:"text-center my-4"},[_c('atoms-text',{staticClass:"my-1 font-weight-bold"},[_vm._v("User Terpilih")]),_c('div',{staticClass:"d-flex align-center gap-5 pa-2 accent rounded-lg text-left"},[_c('v-avatar',{style:({
                      cursor: 'pointer',
                    }),attrs:{"color":"accent","size":"100"}},[(_vm.user.item.picture)?_c('atoms-image',{attrs:{"aspect-ratio":"1","src":_vm.user.item.picture || `/lazy-img.webp`}}):_c('v-icon',[_vm._v(" mdi-account ")])],1),_c('div',{staticClass:"pl-5"},[_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Nama:")]),_vm._v(" "+_vm._s(_vm.user.item.nickname))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.user.item.email))])],1)],1)],1):_vm._e()],1),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v("Tambah Head Hunter")])],1)],1)],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }