<template>
  <v-card elevation="0" rounded="lg" class="d-flex flex-column align-center text-center">
    <slot />
    <template v-if="!buttonDisabled">
      <v-card-subtitle :style="{ width: '100%' }" class="py-0 my-0">
        <v-divider></v-divider>
      </v-card-subtitle>
      <v-card-actions :style="{ width: '100%', bottom: 0, position: 'relative' }">
        <atoms-button
          :to="to"
          :type="type"
          @click="() => (buttonAction ? buttonAction() : notDefined())"
          :plain="plainDisabled ? false : true"
          :style="{ width: '100%' }"
          :class="['primary--text', buttonClass]"
        >
          {{ buttonText || 'Text Here' }}
        </atoms-button>
      </v-card-actions>
    </template>
  </v-card>
</template>
<script>
export default {
  props: ['buttonClass', 'buttonText', 'buttonAction', 'buttonDisabled', 'plainDisabled', 'to', 'type'],
  methods: {
    notDefined: () => true,
  },
}
</script>
