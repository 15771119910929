<template>
  <atoms-modal width="90%">
    <molecules-custom-dialog
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Periksa kembali sebelum menghapus`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showRemove = null),
        },
        {
          title: 'Ya, Lanjutkan',
          override: 'red--text',
          full: false,
          exec: () => {
            showRemove()
            showRemove = null
          },
        },
      ]"
    />
    <molecules-custom-dialog
      :show="showValidator"
      title="Apakah Anda Yakin?"
      :caption="`Total Pesanan anda sebesar ${$idCurrency(
        totalOffer,
        2,
        2,
      )} dengan Nilai Titipan Dana Awal sebesar ${getDownPayment} `"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showValidator = false),
        },
        {
          title: 'Ya, Lanjutkan',
          override: 'red--text',
          full: false,
          exec: () => {
            showValidator = false
            this.validate()
          },
        },
      ]"
    />

    <v-row justify="space-between" class="px-8 py-4">
      <v-col cols="2" align="start">
        <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-container class="px-md-8">
      <v-container class="px-md-8">
        <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row align="center" justify="space-between">
            <v-col cols="12" class="text-center py-0">
              <atoms-title>Edit Kuotasi</atoms-title>
            </v-col>
            <v-col cols="12" md="10" align="center">
              <atoms-text-field
                v-if="!client.enabled && !id"
                :disabled="Boolean(id)"
                label="Cari Client"
                color="accent"
                v-model="client.term"
                :appendAction="
                  () => {
                    client.term = ''
                    client.enabled = false
                  }
                "
                :rules="$vuetify.breakpoint.smAndDown ? undefined : []"
              />
              <atoms-text-field v-else label="Pilih Client">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="client.value"
                  color="accent"
                  :disabled="Boolean(id)"
                  :items="$store.state.client.data && $store.state.client.data.map((x) => x.name)"
                  placeholder="Pilih Client"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="12" md="2" align="center" class="d-flex align-center">
              <atoms-button
                :disabled="Boolean(id)"
                @click="
                  () =>
                    !client.enabled
                      ? getClients()
                      : (function () {
                          items = []
                          client.value = ''
                          client.enabled = false
                          client.item = null
                        })()
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="[!client.enabled ? 'primary' : 'red white--text']"
                >{{ !client.enabled ? 'Cari Client' : 'Reset Pencarian' }}</atoms-button
              >
            </v-col>
          </v-row>
          <v-divider v-if="!$vuetify.breakpoint.smAndDown" class="py-2" />
          <v-row class="text-left">
            <v-col cols="12" md="6">
              <v-dialog
                ref="pickerCurrentDialog"
                v-model="current.modal"
                :return-value.sync="current.value"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <atoms-text-field label="Tanggal" :rules="[]">
                    <v-text-field
                      class="rounded-lg"
                      placeholder="Tanggal"
                      append-icon="mdi-calendar"
                      color="accent"
                      v-model="formatCurrent"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      readonly
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>
                </template>
                <v-date-picker
                  v-model="current.value"
                  color="primary"
                  scrollable
                  class="rounded-lg"
                  @click:date="$refs.pickerCurrentDialog.save(current.value)"
                ></v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6">
              <v-dialog ref="pickerDueDialog" v-model="due.modal" :return-value.sync="due.value" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <atoms-text-field label="Batas Tanggal" :rules="[]">
                    <v-text-field
                      class="rounded-lg"
                      placeholder="Batas Tanggal"
                      append-icon="mdi-calendar"
                      color="accent"
                      v-model="formatDueDate"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      readonly
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>
                </template>
                <v-date-picker
                  v-model="due.value"
                  color="primary"
                  scrollable
                  class="rounded-lg"
                  @click:date="$refs.pickerDueDialog.save(due.value)"
                />
              </v-dialog>
            </v-col>
          </v-row>
          <v-row justify="center" class="my-0">
            <v-col cols="12" md="12">
              <atoms-text-field :disabled="Boolean(id)" label="Nomor Kuotasi" v-model="nomorQuo" color="accent" />
            </v-col>
            <v-col cols="12" class="d-flex flex-column align-center">
              <atoms-title :h3="true">Tipe Pesanan</atoms-title>
              <v-radio-group v-model="type" mandatory dense row>
                <v-radio label="Processing" :value="$inputType.processing" />
                <v-radio label="Sprinter" :value="$inputType.sprinter" />
              </v-radio-group>
            </v-col>
            <v-col class="d-lg-flex flex-column py-0" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'">
              <div class="d-flex align-center justify-space-between">
                <atoms-text v-if="!$vuetify.breakpoint.smAndDown" class="font-weight-bold">Pesanan Kuotasi </atoms-text>

                <atoms-button
                  :disabled="!client.item || !client.enabled"
                  @click="
                    () => {
                      if (datas.hkApproved && datas.clientApproved) {
                        $window.alert('Tidak dapat menambahkan shift baru jika kuotasi sudah approved')
                        return
                      }
                      showAddOne = true
                    }
                  "
                  :style="{
                    width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
                  }"
                  class="primary mb-2 mb-md-0"
                  ><v-icon class="mr-2">mdi-plus-box</v-icon>Tambah Pesanan
                </atoms-button>
              </div>
              <div class="d-flex flex-column flex-md-row justify-start justify-md-space-between">
                <div class="d-flex align-center">
                  <div class="d-flex align-center justify-center">
                    <v-checkbox v-model="isPpn" hide-spin-buttons />
                  </div>
                  <v-label><atoms-text class="pa-2 font-weight-bold">Aktifkan PPN : </atoms-text></v-label>
                  <atoms-text-field
                    :disabled="!isPpn"
                    label="Persentase PPN"
                    :noLabel="true"
                    v-model="ppn"
                    color="accent"
                  />
                </div>
                <div class="d-flex align-center">
                  <div class="d-flex align-center justify-center">
                    <v-checkbox v-model="isPph" hide-spin-buttons />
                  </div>
                  <v-label><atoms-text class="pa-2 font-weight-bold">Aktifkan PPH : </atoms-text></v-label>
                  <atoms-text-field
                    :disabled="!isPph"
                    label="Persentase PPH"
                    :noLabel="true"
                    v-model="pph"
                    color="accent"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="[
                  {
                    text: 'Judul',
                    sortable: false,
                    value: 'title',
                  },
                  {
                    text: 'Tanggal Mulai',
                    sortable: false,
                    value: 'startDate',
                  },
                  {
                    text: 'Tanggal Selesai',
                    sortable: false,
                    value: 'endDate',
                  },
                  {
                    text: 'Area',
                    sortable: false,
                    value: 'areaTitle',
                  },
                  {
                    text: 'Gudang / Lokasi',
                    sortable: false,
                    value: 'location',
                  },
                  {
                    text: 'Jumlah Pekerja',
                    sortable: false,
                    value: 'amount',
                  },
                  {
                    text: 'Jumlah Hari',
                    sortable: false,
                    value: 'quantity',
                  },
                  {
                    text: 'Harga Modal',
                    sortable: false,
                    value: 'priceBase',
                  },
                  {
                    text: 'Fee',
                    sortable: false,
                    value: 'fee',
                  },
                  {
                    text: 'Harga Penawaran ',
                    sortable: false,
                    value: 'priceProposed',
                  },
                  {
                    text: 'Total Modal',
                    sortable: false,
                    value: 'totalBase',
                  },
                  {
                    text: 'Total Fee',
                    sortable: false,
                    value: 'totalFee',
                  },
                  {
                    text: 'Total Penawaran ',
                    sortable: false,
                    value: 'totalProposed',
                  },
                  {
                    text: 'Edit',
                    sortable: false,
                    value: 'action',
                  },
                  {
                    text: 'Hapus',
                    sortable: false,
                    value: 'action2',
                  },
                ]"
                :items="items"
                class="rounded-lg accent"
                hide-default-footer
                hide-default-header
                disable-pagination
                mobile-breakpoint="0"
              >
                <template v-slot:[`header`]="{ props }">
                  <thead>
                    <tr class="d-table-row">
                      <th v-for="h in props.headers" :key="h.value" :class="['text-center', h.class]">
                        <span class="primary--text text-uppercase" v-html="h.text" />
                      </th>
                    </tr></thead
                ></template>

                <template v-slot:[`item`]="{ item }">
                  <tr class="d-table-row text-center">
                    <td>{{ item.title }}</td>
                    <td>
                      {{ item.startDate && $moment(item.startDate).format('DD MMMM yyyy') }}
                    </td>
                    <td>
                      {{ item.endDate && $moment(item.endDate).format('DD MMMM yyyy') }}
                    </td>
                    <td>{{ item.areaTitle }}</td>
                    <td>{{ item.location?.name ?? item.location }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>
                      {{ $idCurrency(String(item.priceBase)) }}
                    </td>
                    <td>
                      {{ $idCurrency(String(item.fee)) }}
                    </td>
                    <td>
                      {{ $idCurrency(String(item.priceProposed)) }}
                    </td>
                    <td>
                      {{ $idCurrency(String(item.totalBase)) }}
                    </td>
                    <td>
                      {{ $idCurrency(String(item.totalFee)) }}
                    </td>
                    <td>
                      {{ $idCurrency(String(item.totalProposed)) }}
                    </td>
                    <td>
                      <v-icon
                        @click="
                          () => {
                            // if ($moment().diff($moment(item.startDate), 'days') >= 0) {
                            if (item?.attendanceIds >= 0) {
                              $window.alert('Tidak dapat mengedit shift/pesanan yang sudah digunakan sebagai absensi')
                              return
                            }
                            editableId = item._id
                            showAddOne = true
                          }
                        "
                        small
                        class="mx-2"
                      >
                        mdi-pencil
                      </v-icon>
                    </td>
                    <td>
                      <v-icon
                        @click="
                          showRemove = async () => {
                            try {
                              const isShiftExist = await $api
                                .get(`Absensi/checkShift/${item._id}`)
                                .then((res) => res.result)
                              if (isShiftExist) {
                                return $store.commit('dialog/show', {
                                  title: 'Perhatian',
                                  body: 'Shift ini telah dipakai absensi, tidak bisa dihapus!',
                                })
                              } else if (isShiftExist === false) {
                                items = items.filter((x) => x._id !== item._id)
                                reCountTotalOffer()
                              }
                            } catch (err) {
                              return $store.commit('dialog/show', {
                                title: 'Perhatian',
                                body:
                                  err?.response?.data?.message ||
                                  err?.message ||
                                  'Terdapat gangguan server, coba lagi nanti',
                              })
                            }
                          }
                        "
                        small
                        class="mx-2"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <!-- exception -->
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col class="d-xl-flex align-center justify-space-between" :align="'left'">
              <div class="text-center">
                <atoms-text class="font-weight-bold mb-4 mb-md-0">Pengurangan Tagihan (Bila Ada)</atoms-text>
              </div>
              <div class="d-flex flex-column flex-xl-row justify-center align-left">
                <v-label><atoms-text class="pa-2 font-weight-bold">Keterangan : </atoms-text></v-label>
                <v-text-field
                  v-model="exception.title"
                  class="rounded-lg"
                  color="accent"
                  :style="$vuetify.breakpoint.lgAndDown && 'width:100%'"
                  placeholder="Keterangan"
                  hide-details
                  solo
                  flat
                  dense
                />
                <v-label><atoms-text class="pa-2 font-weight-bold">Nominal : </atoms-text></v-label>
                <v-text-field
                  dense
                  class="rounded-lg"
                  color="accent"
                  :style="$vuetify.breakpoint.lgAndDown && 'width:100%'"
                  placeholder="Pengurangan Nominal"
                  v-model="exception.price"
                  prefix="Rp."
                  hide-details
                  solo
                  flat
                />
                <atoms-button
                  @click="
                    () => {
                      if (exception.price && Number(exception.price.replace('.', '')) > 0 && exception.title) {
                        exceptions.push({
                          ...exception,
                          _id: $uuid.v4(),
                          price: exception.price.replace('.', ''),
                        })
                        reCountTotalOffer()
                      } else {
                        $store.commit('dialog/show', {
                          title: 'Perhatian',
                          body: 'Pastikan kolom keterangan dan nominal pengurangan terisi!',
                        })
                      }
                      exception = {}
                    }
                  "
                  :style="{
                    width: $vuetify.breakpoint.lgAndDown ? '100%' : 'auto',
                  }"
                  class="primary px-xl-8 ml-xl-4 mt-4 mt-xl-0"
                  ><v-icon left>mdi-plus-box</v-icon>Pengurangan</atoms-button
                >
              </div>
            </v-col>

            <!-- <v-col
              class="d-md-flex align-center justify-space-between"
              :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
            >
              <div>
                <atoms-text class="font-weight-bold mb-4 mb-md-0"
                  >Pengurangan Tagihan (Bila Ada)</atoms-text
                >
              </div>
              <div
                class="d-flex flex-column flex-md-row justify-center align-center"
              >
                <v-label
                  ><atoms-text class="pa-2 font-weight-bold"
                    >Keterangan :
                  </atoms-text></v-label
                >
                <v-text-field
                  v-model="exception.title"
                  class="rounded-lg"
                  color="accent"
                  :style="$vuetify.breakpoint.smAndDown && 'width:100%'"
                  placeholder="Keterangan"
                  hide-details
                  solo
                  flat
                  dense
                />
                <v-label
                  ><atoms-text class="pa-2 font-weight-bold"
                    >Nominal :
                  </atoms-text></v-label
                >
                <v-text-field
                  dense
                  class="rounded-lg"
                  background-color="accent"
                  :style="$vuetify.breakpoint.smAndDown && 'width:100%'"
                  placeholder="Pengurangan Nominal"
                  v-model="exception.price"
                  prefix="Rp."
                  hide-details
                  solo
                  flat
                />
                <atoms-button
                  @click="
                    () => {
                      if (
                        exception.price &&
                        Number(exception.price.replace('.', '')) > 0 &&
                        exception.title
                      ) {
                        exceptions.push({
                          ...exception,
                          _id: $uuid.v4(),
                          price: exception.price.replace('.', ''),
                        })
                        reCountTotalOffer()
                      } else {
                        $store.commit('dialog/show', {
                          title: 'Perhatian',
                          body: 'Pastikan kolom keterangan dan nominal pengurangan terisi!',
                        })
                      }
                      exception = {}
                    }
                  "
                  :style="{
                    width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
                  }"
                  class="primary px-md-8 ml-md-4 mt-4 mt-md-0"
                  ><v-icon left>mdi-plus-box</v-icon>Pengurangan</atoms-button
                >
              </div>
            </v-col> -->
            <v-col v-show="exceptions.length > 0" cols="12">
              <v-data-table
                :headers="[
                  {
                    text: 'Keterangan',
                    sortable: false,
                    value: 'title',
                  },
                  {
                    text: 'Nominal',
                    sortable: false,
                    value: 'price',
                  },
                  {
                    text: 'Aksi',
                    sortable: false,
                    value: 'action',
                  },
                ]"
                :items="exceptions"
                class="rounded-lg accent"
                hide-default-footer
                hide-default-header
              >
                <template v-slot:[`header`]="{ props }">
                  <thead>
                    <tr class="d-table-row">
                      <th v-for="h in props.headers" :key="h.value" :class="['text-center', h.class]">
                        <span class="primary--text text-uppercase">{{ h.text }}</span>
                      </th>
                    </tr>
                  </thead></template
                >

                <template v-slot:[`item`]="{ item }">
                  <tr class="d-table-row text-center">
                    <td>{{ item.title }}</td>
                    <td>
                      {{ $idCurrency(String(item.price)) }}
                    </td>
                    <td>
                      <!-- totalOffer += Number(item.nominal); -->
                      <v-icon
                        @click="
                          showRemove = () => {
                            exceptions = exceptions.filter((x) => x._id !== item._id)
                            reCountTotalOffer()
                          }
                        "
                        small
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="printVersion"
                :items="[
                  {
                    label: 'Versi 1 (PPN/PPH berdasarkan Platform Fee)',
                    value: 1,
                  },
                  {
                    label: 'Versi 2 (PPN/PPH berdasarkan Total Penawaran)',
                    value: 2,
                  },
                ]"
                item-text="label"
                item-value="value"
                :menu-props="{ offsetY: true }"
                label="Print Version"
                class="rounded-lg accent"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
          <!-- caps -->
          <v-row>
            <v-col align="end" class="text-right">
              <atoms-title :h2="true">{{ 'Total Modal' }}</atoms-title>
              <atoms-title :h2="true">{{ 'Platform Fee' }}</atoms-title>
              <atoms-title :h2="true">{{ 'Total Penawaran' }}</atoms-title>
              <atoms-title v-if="isPpn" :h2="true">{{ `PPN (${ppn})` }}</atoms-title>
              <atoms-title v-if="isPph" :h2="true">{{ `PPH (${pph})` }}</atoms-title>
              <atoms-title :h2="true">{{ 'Total' }}</atoms-title>
            </v-col>
            <v-col align="start" class="shrink">
              <atoms-title :h2="true">:</atoms-title>
              <atoms-title :h2="true">:</atoms-title>
              <atoms-title :h2="true">:</atoms-title>
              <atoms-title v-if="isPpn" :h2="true">:</atoms-title>
              <atoms-title v-if="isPph" :h2="true">:</atoms-title>
              <atoms-title :h2="true">:</atoms-title>
            </v-col>
            <v-col align="end" class="shrink">
              <atoms-title :h2="true">Rp.</atoms-title>
              <atoms-title :h2="true">Rp.</atoms-title>
              <atoms-title :h2="true">Rp.</atoms-title>
              <atoms-title v-if="isPpn" :h2="true">Rp.</atoms-title>
              <atoms-title v-if="isPph" :h2="true">Rp.</atoms-title>
              <atoms-title :h2="true">Rp.</atoms-title>
            </v-col>
            <v-col align="end" class="shrink text-right">
              <atoms-title :h2="true">{{ $idCurrency(totalFund, 2, 2, true).replace('Rp', '') }}</atoms-title>
              <atoms-title :h2="true">{{ $idCurrency(profit, 2, 2, true).replace('Rp', '') }}</atoms-title>
              <atoms-title :h2="true">{{ $idCurrency(pureTotalOffer, 2, 2, true).replace('Rp', '') }}</atoms-title>
              <atoms-title v-if="isPpn" :h2="true">{{
                $idCurrency(ppnValue, 2, 2, true).replace('Rp', '')
              }}</atoms-title>
              <atoms-title v-if="isPph" :h2="true">{{
                $idCurrency(pphValue, 2, 2, true).replace('Rp', '')
              }}</atoms-title>
              <atoms-title :h2="true">{{ $idCurrency(totalOffer, 2, 2, true).replace('Rp', '') }}</atoms-title>
            </v-col>
          </v-row>
          <!-- persentase -->
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12" md="6">
              <atoms-text-field label="Persentase Titipan Dana Awal" v-model="dpConvertPercentage" color="accent" />
            </v-col>
            <v-col cols="12" md="6">
              <atoms-text-field
                label="Nilai Titipan Dana Awal"
                v-model="getDownPayment"
                color="accent"
                :readonly="true"
              />
            </v-col>
            <v-col cols="12">
              <atoms-button
                :disabled="!client.enabled || !client.item"
                style="width: 100%"
                type="submit"
                class="primary mr-md-4 mb-2 mb-md-0"
                ><v-icon class="mr-2">mdi-note-check</v-icon> Simpan Kuotasi</atoms-button
              >
              <atoms-button
                link
                @click="
                  () => {
                    redirect = `/cetak-kuotasi?type=quotation&no=${datas._id}`
                    preValidate()
                  }
                "
                style="width: 100%"
                class="grey darken-3 white--text mt-3"
                title="Ingat untuk simpan kuotasi untuk mendapat printout kuotasi terbaru"
              >
                <v-icon>mdi-printer</v-icon>
                Print Kuotasi
              </atoms-button>
              <atoms-button
                v-if="$isRoles(['Super Admin'])"
                :disabled="!client.enabled || !client.item"
                style="float: right"
                type="submit"
                class="red darken-2 white--text mb-2 mb-md-0 mt-3"
                @click.prevent="deleteQuotation"
                ><v-icon class="mr-2">mdi-delete</v-icon> Hapus</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-container>
    <v-slide-x-reverse-transition>
      <organism-quotation-items
        v-if="showAddOne"
        :type="type === $inputType.sprinter ? true : false"
        :datas="editableId ? items.find((x) => x._id === editableId) : null"
        :client="client && client.item"
        @closed="
          (payload) => {
            let check = payload && items && items.find((x) => x._id === payload._id)
            if (payload && check) {
              items = [...items.filter((x) => x._id !== check._id), payload]
            } else {
              payload && items.push(payload)
            }
            showAddOne = false
            editableId = ''
          }
        "
      />
    </v-slide-x-reverse-transition>
  </atoms-modal>
</template>
<script>
import moment from 'moment'

export default {
  props: ['id'],
  data() {
    return {
      datas: {},
      valid: true,
      printVersion: 2,
      showAddOne: false,
      showHelper: true,
      showValidator: false,
      showRemove: null,
      editableId: '',
      items: [],
      exceptions: [],
      exception: {},
      isPpn: false,
      ppn: '11%',
      ppnValue: 0,
      isPph: false,
      pph: '2%',
      pphValue: 0,
      type: '',
      totalFund: 0,
      totalOffer: 0,
      pureTotalOffer: 0,
      pureTotalProposed: 0, // this.pureTotalProposed means total offer without ppn and pph
      marginPercentage: 0,
      profit: 0,
      downpayment: 0,
      invoiceDownPayment: '',
      dpPercentage: 1,
      dpConvertPercentage: '100%',
      redirect: '',
      nomorQuo: '',
      current: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      due: {
        modal: false,
        value: this.$moment().add(1, 'days').format('YYYY-MM-DD'),
      },
      client: {
        term: '',
        enabled: false,
        value: '',
        item: null,
      },
      triggerAutoQuoNum: false,
    }
  }, //this.datas.dueDate
  watch: {
    // async 'client.value'() {
    //   if (this.client.value) {
    //     this.$store.commit('SET', { loading: true })
    //     this.client.item = this.$store.state.client?.data?.find((x) => x.name === this.client.value)
    //     await this.quoNumAutoChanger()
    //     this.$store.commit('SET', { loading: false })
    //   }
    // },
    'exception.price': function (newValue) {
      const result = newValue ? newValue.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''
      this.$nextTick(() => (this.exception.price = result))
    },
    exceptions: {
      handler() {
        this.reCountTotalOffer()
      },
      deep: true,
    },
    nomorQuo: function (value) {
      this.$nextTick(() => {
        if (!value) {
          this.nomorQuo = this.datas.no
        }
      })
    },
    items: {
      handler() {
        this.reCountTotalOffer()
      },
      deep: true,
    },
    isPpn: function () {
      this.reCountTotalOffer()
    },
    ppn: function () {
      this.reCountTotalOffer()
    },
    isPph: function () {
      this.reCountTotalOffer()
    },
    pph: function () {
      this.reCountTotalOffer()
    },
    dpPercentage: function () {
      this.reCountTotalOffer()
    },
    dpConvertPercentage: function (value) {
      try {
        const converter = (value?.includes('%') && this.$convertPercenttoDecimal(value)) || Number(value) / 100
        this.$nextTick(() => {
          this.dpConvertPercentage = this.$convertDecimaltoPercent((converter > 1 && 1) || converter || 0)
          this.dpPercentage = (converter > 1 && 1) || converter || 0
        })
      } catch (error) {
        console.log(error)
      }
    },
    'current.value': function (newValue) {
      if (newValue != this.$moment(this.datas?.date).format('YYYY-MM-DD')) {
        this.quoNumAutoChanger()
      }
    },
    'due.value': function (newValue) {
      if (newValue != this.$moment(this.datas?.dueDate).format('YYYY-MM-DD')) {
        this.quoNumAutoChanger()
      }
    },
    printVersion() {
      this.reCountTotalOffer()
    },
  },
  computed: {
    getDownPayment: {
      get() {
        return this.$idCurrency(this.downpayment)
      },
      set() {
        // prevent error
      },
    },
    formatCurrent: {
      set(value) {
        this.current.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.current.value).format('DD MMMM YYYY')
      },
    },
    formatDueDate: {
      set(value) {
        this.due.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.due.value).format('DD MMMM YYYY')
      },
    },
  },
  async mounted() {
    this.$store.commit('SET', { loading: true })
    this.items = []
    const datas =
      this.id &&
      (
        await this.$store.dispatch('quotation/get', {
          query: { _id: this.id || '' },
          limit: 1,
          dont_set: true,
        })
      )?.[0]
    this.datas = datas || null

    console.log(this.datas)
    if (this.datas) {
      this.items = this.datas.items.map((x) => {
        return {
          ...x,
          totalProposed: Number(x.pureTotalProposed),
        }
      })
      this.client.item = (
        await this.$store.dispatch('client/getAll', {
          query: { _id: this.datas?.clientId },
        })
      )?.[0]
      this.client.value = this.client?.item?.name
      this.client.term = this.client?.value
      this.client.enabled = (this.client?.value && this.client?.item && true) || false
      this.type = this.datas?.type || ''
      this.exceptions = this.datas?.priceReduction || []

      this.formatCurrent = this.$moment(this.datas?.date)
      this.formatDueDate = this.$moment(this.datas?.dueDate || new Date())
      this.isPpn = this.datas?.isActivePpn
      this.ppn = (this.datas?.ppnPercentage && this.$convertDecimaltoPercent(this.datas?.ppnPercentage)) || this.ppn
      this.ppnValue = this.datas?.ppnValue
      this.isPph = this.datas?.isActivePph
      this.pph = (this.datas?.pphPercentage && this.$convertDecimaltoPercent(this.datas?.pphPercentage)) || this.pph
      this.pphValue = this.datas?.pphValue
      this.dpPercentage = this.datas?.dpPercentage || this.dpPercentage
      this.dpConvertPercentage = this.$convertDecimaltoPercent(this.datas?.dpPercentage) || this.dpConvertPercentage
      this.$nextTick(() => (this.nomorQuo = this.datas?.no || ''))
      if (!this.datas?.date || !this.datas?.dueDate) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Terdapat kesalahan pada tanggal, silahkan cek kembali apakah sudah sesuai atau belum, kemudian simpan untuk membuat perubahan.`,
        })
        this.formatCurrent = new Date()
        this.formatDueDate = new Date()
      }
    } else {
      this.$showDialog({
        title: 'Gagal',
        body: `Terdapat kesalahan ketika melakukan permintaan ${process.pid}`,
      })
      this.$emit('closed')
    }
    this.$store.commit('SET', { loading: false })
  },
  errorCaptured(err) {
    console.log(err)
    this.$emit('closed')
  },
  methods: {
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.client.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 1,
          limit: 0,
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async quoNumAutoChanger() {
      if (this.triggerAutoQuoNum) {
        // this.nomorQuo = await this.$autoFillQuoNum(
        //   this.client?.item?._id || 0,
        //   this.$moment(this.current.value),
        //   this.client?.item?.code || '',
        //   false,
        //   this.datas?.no,
        // )
        // console.log(this.nomorQuo)
      }
      this.triggerAutoQuoNum = true
    },
    reCountTotalOffer() {
      if (this.items.length > 0) {
        // calc total offer
        // this.totalOffer = this.items
        //   .map((x) => Number(x.totalProposed || 0))
        //   .reduce((past, current) => past + current, 0)

        // this.totalFund = this.items
        //   .map((x) => Number(+x?.totalBase || 0))
        //   ?.reduce((total, item) => total + item, 0)

        // const totalFee = this.items
        //   .map((x) => Number(+x?.totalFee || 0))
        //   ?.reduce((total, item) => total + item, 0)

        // // this.pureTotalOffer = this.items
        // //   .map((x) => Number(x.pureTotalProposed || 0))
        // //   .reduce((past, current) => past + current)

        // this.pureTotalOffer = (+totalFund || 0) + (+totalFee || 0)

        // this.profit =
        //   Number(this.pureTotalOffer || 0) - Number(this.totalFund || 0)
        // this.marginPercentage = this.profit / this.pureTotalOffer
        // // calc taxes
        // const valueGI = this.profit
        // if (this.isPpn) {
        //   this.ppnValue = Math.floor(
        //     this.$convertPercenttoDecimal(this.ppn) * valueGI
        //   )
        // } else {
        //   this.ppnValue = 0
        // }
        // if (this.isPph) {
        //   this.pphValue = Math.floor(
        //     this.$convertPercenttoDecimal(this.pph) * valueGI
        //   )
        // } else {
        //   this.pphValue = 0
        // }
        // // calc total offer after taxes and price reduction
        // const totalPriceReduction = this.exceptions.reduce(
        //   (total, item) => total + +(item?.price || 0),
        //   0
        // )

        this.totalOffer = this.items?.map((x) => Number(x.totalProposed || 0)).reduce((total, item) => total + item, 0)

        this.totalFund = this?.items.map((x) => Number(x.totalBase || 0)).reduce((total, item) => total + item, 0)

        const totalFee = this?.items
          .map((x) => Number(+x?.fee || 0) * (x?.amount || 0) * (x?.quantity || 0))
          ?.reduce((total, item) => total + item, 0)

        this.pureTotalOffer = (+this.totalFund || 0) + (+totalFee || 0)

        this.profit = Number(this?.pureTotalOffer || 0) - Number(this?.totalFund || 0)
        this.marginPercentage = this.profit / this.pureTotalOffer
        // calc taxes
        const valueGI = this.profit
        if (this.isPpn) {
          if (this.printVersion === 2) {
            this.ppnValue = Math.floor(this.$convertPercenttoDecimal(this.ppn) * this.pureTotalOffer)
          } else {
            this.ppnValue = Math.floor(this.$convertPercenttoDecimal(this.ppn) * valueGI)
          }
        } else {
          this.ppnValue = 0
        }
        if (this.isPph) {
          if (this.printVersion === 2) {
            this.pphValue = Math.floor(this.$convertPercenttoDecimal(this.pph) * this.pureTotalOffer)
          } else {
            this.pphValue = Math.floor(this.$convertPercenttoDecimal(this.pph) * valueGI)
          }
        } else {
          this.pphValue = 0
        }
        // calc total offer after taxes and price reduction
        const totalPriceReduction = this.datas.priceReduction.reduce((total, item) => total + +(item?.price || 0), 0)
        const dpDecimal = this.$convertPercenttoDecimal(this.dpPercentage)
        this.pureTotalProposed = this.totalOffer * dpDecimal

        this.totalOffer = this.pureTotalOffer + (+this?.ppnValue || 0) - (+this?.pphValue || 0) - totalPriceReduction
        // this.pureTotalProposed = this?.items
        //   .map(
        //     (x) =>
        //       Number(+x?.amount || 0) *
        //       (x?.quantity || 0) *
        //       (x?.purePriceProposed || 1)
        //   )
        //   ?.reduce((total, item) => total + item, 0)
      }
      this.calculateDownPayment()
    },
    calcProposedPph(proposed) {
      if (this.pph) {
        const pphPercentage = this.$convertPercenttoDecimal(this.pph)
        return (proposed / (1 - pphPercentage)).toFixed(2)
      }
      return proposed
    },
    calculateDownPayment() {
      const dpDecimal = this.$convertPercenttoDecimal(this.dpPercentage)
      this.downpayment = this.totalOffer * dpDecimal
    },
    getQuotationModel() {
      this.reCountTotalOffer()
      const userId = this.$store.state.user?._id
      const payload = {
        ...this.datas,
        no: this.nomorQuo,
        printVersion: this.printVersion,
        clientId: this.client.item?._id,
        date: this.current?.value || new Date(),
        dueDate: this.due?.value || new Date(new Date().setDate(new Date().getDate() + 1)) || new Date(),
        type: this.type,
        totalFund: this.totalFund,
        totalOffer: this.totalOffer,
        pureTotalOffer: this.pureTotalOffer,
        marginPercentage: this.marginPercentage,
        profit: this.profit,
        isActivePpn: this.isPpn,
        ppnPercentage: this.$convertPercenttoDecimal(this.ppn),
        ppnValue: this.ppnValue,
        isActivePph: this.isPph,
        pphPercentage: this.$convertPercenttoDecimal(this.pph),
        pphValue: this.pphValue,
        dpPercentage:
          (this.dpConvertPercentage && this.$convertPercenttoDecimal(this.dpConvertPercentage)) || this.dpPercentage,
        downpayment: this.downpayment,
        priceReduction: this.exceptions || [],
        updatedBy: userId,
      }

      delete payload?.client
      delete payload?.area
      delete payload?.items
      return payload
    },
    quotationItemPurified(quotationId) {
      return !quotationId
        ? Promise.reject('quotation not defined!')
        : this.items.map((x) => {
            return {
              quotationId,
              ...x,
            }
          })
    },
    async preValidate() {
      await this.$refs.form.validate()
      try {
        if (this.valid && this.datas && this.items?.length > 0 && this.client?.item) {
          this.showValidator = true
        } else if (this.items?.length <= 0) {
          this.$showDialog({
            title: 'Perhatian',
            body: `Pastikan terdapat items, setidaknya satu!`,
          })
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian!`,
          })
        }
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      this.$store.commit('SET', { loading: true })
      const quotationData = await this.getQuotationModel()
      const quotationItems = await this.quotationItemPurified(quotationData?._id)
      // console.log('edit', quotationData, quotationItems)
      try {
        await this.$store.dispatch('quotation/update', quotationData)
        await this.$store.dispatch('quotation/saveItems', quotationItems)
        this.$showDialog({
          title: 'Berhasil',
          body: `Kuotasi berhasil diperbarui!`,
        })
        if (!!this.redirect) {
          window.open(this.redirect, '_blank')
        }
        this.$emit('closed')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async deleteQuotation() {
      const isConfirmed = confirm('Yakin ingin dihapus?')
      if (!isConfirmed) return
      const validatePrompt = prompt(
        `Menghapus kuotasi akan menghapus invoice yang bersangkutan juga bila ada. Untuk konfirmasi masukkan nomor dari kuotasi yang akan dihapus ${this.nomorQuo}`,
      )
      if (!validatePrompt) return
      if (validatePrompt !== this.nomorQuo) return alert('Inputan salah')
      const quotationData = await this.getQuotationModel()
      await this.$store.commit('SET', { loading: true })
      try {
        const data = await this.$api.delete(`Quotations/removeQuotation`, {
          params: {
            _id: quotationData?._id,
          },
        })
        if (data?.success) {
          this.$showDialog({
            title: 'Hapus Berhasil',
            body: `Kuotasi nomor ${this.nomorQuo} telah berhasil dihapus`,
          })
          this.$emit('closed')
        }
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      await this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
