<template>
  <atoms-modal>
    <v-row justify="space-between" class="px-8 py-4">
      <v-col cols="2" align="start">
        <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-container>
      <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
        <v-row justify="center">
          <v-col cols="10">
            <atoms-title>User Baru</atoms-title>
          </v-col>
          <v-col cols="10">
            <div>
              <v-avatar
                color="accent"
                size="200"
                :style="{
                  cursor: 'pointer',
                }"
              >
                <v-overlay :value="true" opacity="0.5" absolute>
                  <div @click="openUploader" class="pa-12" :style="{ width: '100%', height: '100%' }">
                    <atoms-text class="font-weight-medium"><v-icon small> mdi-pencil </v-icon>Upload Foto</atoms-text>
                    <input ref="uploader" type="file" accept="image/*" class="d-none" @change="onPhotoChange" />
                  </div>
                </v-overlay>
                <atoms-image
                  v-if="photo && photo.url"
                  :lazy-src="`/lazy-img.webp`"
                  aspect-ratio="1"
                  :src="photo.url || `/lazy-img.webp`"
                />
                <v-icon v-else> mdi-account </v-icon>
              </v-avatar>
              <atoms-text class="py-4">Foto Maks. 3mb (Bila ada)</atoms-text>
            </div>

            <atoms-text-field label="Nama User" v-model="nickname" :rules="[(v) => !!v || 'Harap disii!']" />

            <atoms-text-field
              label="Email"
              v-model="email"
              :rules="[
                (v) => !!v || 'Email harap diisi!',
                (v) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email tidak valid',
                (v) => (!!v && this.duplicate !== v) || 'Email sudah terdaftar!',
              ]"
            />

            <atoms-text-field
              label="Password"
              v-model="password"
              type="password"
              :rules="[
                (v) => !!v || 'Harap disii!',
                (v) => (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter',
              ]"
            />

            <atoms-text-field label="Roles">
              <v-combobox
                v-model="roles"
                :items="$roleList"
                item-text="label"
                item-value="value"
                placeholder="Roles"
                class="rounded-lg"
                @change="
                  () => {
                    roles = roles && roles.map((it) => (typeof it === 'object' ? (it && it.value) || it : it))
                  }
                "
                multiple
                dense
                solo
                flat
                :rules="[(v) => (!!v && v.length > 0) || 'Setidaknya tambahkan satu!']"
              />
            </atoms-text-field>

            <atoms-button :loading="$store.state.loading" type="submit" :style="{ width: '100%' }" class="primary mt-4"
              >Daftarkan User</atoms-button
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </atoms-modal>
</template>
<script>
export default {
  data() {
    return {
      photo: null,
      nickname: '',
      email: '',
      password: '',
      roles: [],
      duplicate: '',
      valid: true,
    }
  },
  methods: {
    openUploader() {
      if (this.$refs.uploader) {
        this.$refs.uploader.click()
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (file) {
        this.photo = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.photo = null
      }
    },
    async preValidate() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.email || '', $options: 'i' }
      try {
        if (this.email) {
          this.email = this.email.trim()
          this.email = this.email.split('@')[0].replaceAll('.', '') + '@' + this.email.split('@')[1]
          const { result: user } = await this.$api.get(`Users/isExist`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          if (user) {
            this.duplicate = this.email
          } else {
            this.duplicate = ''
          }
        }
        await this.validate()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()

      try {
        if (this.valid && this.nickname && this.email && this.password) {
          const userId = this.$uuid.v4()
          await this.$store.dispatch('user/register', {
            _id: userId,
            email: this.email,
            roles: this.roles,
            password: this.password,
            picture:
              this.photo && this.photo.image
                ? await this.$uploadFile(this.photo.image, `User/${userId}/Profil`, `picture`)
                : '',
            nickname: this.nickname,
          })
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$showDialog({
            title: 'Berhasil',
            body: `User baru berhasil didaftarkan`,
          })
          this.$emit('closed')
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian`,
          })
        }
      } catch (error) {
        Promise.reject(error)
      }
    },
  },
}
</script>
