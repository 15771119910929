var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: '90%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[_c('atoms-title',{staticClass:"my-5"},[_vm._v("Edit Saldo Pekerja")]),_c('v-form',{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.updateBalance.apply(null, arguments)}},model:{value:(_vm.myForm),callback:function ($$v) {_vm.myForm=$$v},expression:"myForm"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.worker.data || !_vm.worker.enabled)?_c('atoms-text-field',{attrs:{"label":"Cari Pekerja","loading":_vm.$store.state.loading || _vm.worker.mainLoading,"rules":[]},model:{value:(_vm.worker.term),callback:function ($$v) {_vm.$set(_vm.worker, "term", $$v)},expression:"worker.term"}}):_c('atoms-text-field',{attrs:{"label":"Pilih Pekerja"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"loading":_vm.$store.state.loading || _vm.worker.mainLoading,"items":(_vm.worker.data && _vm.worker.data.map((x) => x.fullName + ' → ' + x.email)) || [],"placeholder":"Pilih Pekerja","menu-props":{ offsetY: true },"dense":"","solo":"","flat":""},model:{value:(_vm.worker.value),callback:function ($$v) {_vm.$set(_vm.worker, "value", $$v)},expression:"worker.value"}})],1),_c('atoms-button',{class:[!_vm.worker.data || !_vm.worker.enabled ? 'primary' : 'red white--text', 'mb-4'],style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading || _vm.worker.mainLoading},on:{"click":() => {
                  if (!_vm.worker.data || !_vm.worker.enabled) {
                    _vm.getWorkers()
                    _vm.worker.term = ''
                    _vm.worker.enabled = true
                  } else {
                    _vm.worker.data = null
                    _vm.worker.enabled = false
                  }
                }}},[_vm._v(_vm._s(!_vm.worker.data || !_vm.worker.enabled ? 'Cari Pekerja' : 'Reset Pencarian'))]),(_vm.worker.data && _vm.worker.enabled)?_c('atoms-button',{class:['mb-4 primary'],style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading || _vm.worker.mainLoading},on:{"click":() => {
                  if (_vm.worker.data) {
                    _vm.worker.item = _vm.worker.data.find((x) => x.email === _vm.worker.value.split('→')[1].trim())
                    const check = _vm.worker.item && _vm.worker.items && _vm.worker.items.find((it) => it._id === _vm.worker.item._id)
                    if (!check) {
                      _vm.worker.item.balanceReason = _vm.form.reason || ''
                      _vm.worker.items = [...(_vm.worker.items || []), _vm.worker.item]
                      _vm.worker.term = null
                      _vm.worker.value = null
                      _vm.worker.enabled = false
                    } else {
                      _vm.$showDialog({
                        title: 'Gagal menambah pekerja',
                        body: 'Maaf, pekerja yang anda maksud sudah tertambahkan',
                      })
                    }
                  }
                }}},[_vm._v("Tambah Pekerja")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('atoms-text-field',{attrs:{"label":"Saldo (Isi dengan prefix (-) untuk mengurangkan saldo pekerja)","placeholder":"Saldo","type":"number","rules":_vm.rules.default},model:{value:(_vm.form.balance),callback:function ($$v) {_vm.$set(_vm.form, "balance", $$v)},expression:"form.balance"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('atoms-text-field',{attrs:{"label":"Alasan","rules":_vm.rules.default},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"accent rounded-lg",attrs:{"items":_vm.worker.items || [],"headers":[
                {
                  text: 'Nama Pekerja',
                  sortable: false,
                  value: 'fullName',
                  width: '40%',
                  class: 'primary--text text-uppercase',
                },
                {
                  text: 'Email Pekerja',
                  sortable: false,
                  value: 'email',
                  width: '40%',
                  class: 'primary--text text-uppercase',
                },
                {
                  text: 'Alasan',
                  sortable: false,
                  value: 'alasan',
                  class: 'text-uppercase',
                },
                {
                  text: 'Aksi',
                  sortable: false,
                  value: 'action',
                  class: 'text-uppercase text-center',
                },
              ],"hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"0","disable-pagination":""},scopedSlots:_vm._u([{key:`header`,fn:function({ props }){return [_c('thead',[_c('tr',{staticClass:"d-table-row"},_vm._l((props.headers),function(h){return _c('th',{key:h.value,class:['text-left', h.class]},[_c('span',{staticClass:"primary--text text-uppercase",domProps:{"innerHTML":_vm._s(h.text)}})])}),0)])]}},{key:`item`,fn:function({ item }){return [_c('tr',{staticClass:"d-table-row"},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.fullName))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.email))]),_c('td',{staticClass:"text-left d-flex"},[_c('atoms-text-field',{staticClass:"mt-auto",staticStyle:{"width":"100%"},attrs:{"placeholder":"Alasan","color":"background","loading":_vm.worker.mainLoading,"rules":[]},model:{value:(item.balanceReason),callback:function ($$v) {_vm.$set(item, "balanceReason", $$v)},expression:"item.balanceReason"}})],1),_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"small":""},on:{"click":() => {
                          const result = _vm.worker.items && _vm.worker.items.filter((it) => it._id !== item._id)
                          if (result) {
                            _vm.worker.items = result
                          }
                        }}},[_vm._v(" mdi-delete ")])],1)])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.worker.items)?_c('atoms-button',{class:['primary'],style:({ width: '100%' }),attrs:{"type":"submit","loading":_vm.$store.state.loading || _vm.worker.mainLoading}},[_vm._v("Sesuaikan")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.worker.items)?_c('atoms-button',{class:['mb-4 red white--text'],style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading || _vm.worker.mainLoading},on:{"click":() => {
                  _vm.form = {
                    balance: 0,
                    reason: '',
                  }
                  _vm.worker = {
                    enabled: false,
                    value: '',
                    term: '',
                    item: null,
                    data: null,
                    items: null,
                    mainLoading: false,
                  }
                }}},[_vm._v("Reset")]):_vm._e()],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }