<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('close')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="validate">
          <v-row justify="center" dense>
            <v-col cols="10">
              <atoms-title>{{ !id ? 'Tambah' : 'Perbarui' }} Pertanyaan</atoms-title>
            </v-col>

            <v-col cols="10">
              <atoms-text-field label="Pertanyaan" v-model="title" :rules="rules.default" />
            </v-col>
            <v-col cols="10" align="start">
              <quill-editor
                class="accent rounded-lg mt-4"
                :content="content"
                :options="editorOpts"
                @change="onEditorChange($event)"
              >
              </quill-editor>
            </v-col>
            <v-col cols="10">
              <atoms-button
                type="submit"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="['primary', 'mt-4']"
                >Simpan</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      readOnly: false,
      title: '',
      payload: null,
      content: `<h2>Anda bisa menulis jawaban anda disini</h2><br/>...<br/><br/><br/>`,
      editorOpts: {},
      start: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      end: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      rules: {
        default: [(v) => !!v || 'Harap Diisi'],
      },
      valid: true,
    }
  },
  mounted() {
    this.payload = (this.id && this.$store.state.faq.data?.find((it) => it?._id === this.id)) || null
    if (this.payload) {
      this.title = this.payload?.title
      this.content = this.payload?.content
    } else if (this.id && !this.payload) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal memuat data!`,
      })
      this.$emit('close')
    }
  },
  methods: {
    genExcerpt(str) {
      if (!str) return ''
      if (!(typeof str === 'string')) return ''
      if (str.length > 133) {
        str = str.substring(0, 133) + '...'
      }
      return str
    },
    onEditorChange({ html, text }) {
      this.content = html
    },
    async validate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$refs.form.validate()
        if (this.valid) {
          const _id = this.$uuid.v4()
          const payload = {
            ...((this.payload && this.payload) || {}),
            _id: this.id || _id,
            title: this.title,
            content: this.content,
          }
          if (!this.id) {
            payload.createdBy = this.$store.state.user?._id
            await this.$store.dispatch('faq/create', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Pertanyaan berhasil ditambahkan!`,
            })
          } else {
            await this.$store.dispatch('faq/update', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Pertanyaan berhasil diperbarui!`,
            })
          }

          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$emit('close')
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
