var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: '90%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('closed')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[_c('v-form',{ref:"myForm",on:{"submit":function($event){$event.preventDefault();/**/}},model:{value:(_vm.myForm),callback:function ($$v) {_vm.myForm=$$v},expression:"myForm"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-md-left d-flex flex-column flex-md-row justify-space-between",attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v("Riwayat Saldo Pekerja")])],1),_c('v-col',{attrs:{"cols":"10","align":"start"}},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-center justify-space-between"},[_c('div',{staticClass:"d-flex pt-4 flex-column flex-md-row align-center"},[_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return _c('v-avatar',{attrs:{"size":"200","color":"grey"}},[(_vm.photo && _vm.photo.url)?_c('atoms-image',{staticClass:"rounded-lg",attrs:{"src":(_vm.photo && _vm.photo.url) || '/media/person-example.webp'}},[_c('v-overlay',{attrs:{"value":_vm.$vuetify.breakpoint.smAndDown ? true : hover,"opacity":"0.5","absolute":""}},[_c('div',{staticClass:"pa-12",style:({
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                            })},[(_vm.isEdit)?_c('atoms-text',{staticClass:"font-weight-medium",on:{"click":function($event){return _vm.openUploader('uploaderSelf')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-camera ")]),_vm._v("Ganti Foto")],1):_vm._e(),_c('atoms-text',{staticClass:"font-weight-medium mt-4",on:{"click":function($event){_vm.photo &&
                                  _vm.photo.url &&
                                  _vm.$viewerApi({
                                    options: {
                                      toolbar: false,
                                    },
                                    images: [_vm.photo.url],
                                  })}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-eye ")]),_vm._v("Perbesar")],1)],1)])],1):_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account")])],1)}}])}),_c('input',{ref:"uploaderSelf",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onPhotoChange}})],1),_c('div',{staticClass:"ml-md-8 d-flex py-4 align-center align-md-start flex-column justify-center text-center text-md-left"},[_c('atoms-title',{staticClass:"font-weight-bold",attrs:{"h2":true}},[_vm._v(_vm._s(_vm.data.fullName)+" "),(_vm.data.captain)?_c('v-chip',{staticClass:"primary lighten-5 primary--text rounded-lg",attrs:{"small":""}},[_vm._v("Kapten")]):_vm._e()],1),_c('atoms-text',{staticClass:"primary--text"},[_vm._v("Balance : "+_vm._s(_vm.$idCurrency(_vm.data.balance)))]),_c('atoms-text',[_vm._v(_vm._s(_vm.data.email))]),_c('atoms-text',[_vm._v("Terdaftar Pada "+_vm._s(_vm.$moment(_vm.data._createdDate).format('YYYY-MM-DD')))])],1)])])])],1)],1),_c('v-row',{staticClass:"pl-md-8 py-6"},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('atoms-button',{staticClass:"primary",staticStyle:{"width":"100%"},on:{"click":() => {
                _vm.sort = _vm.sort === _vm.constant[0] ? _vm.constant[1] : _vm.constant[0]
                _vm.getHistory()
              }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant-"+_vm._s(_vm.sort === _vm.constant[0] ? 'minus' : 'plus'))]),_vm._v("Sort Tanggal : "+_vm._s(_vm.sort === _vm.constant[0] ? 'Terbaru' : 'Terlama')+" ")],1),_c('atoms-button',{staticClass:"teal white--text mt-3",staticStyle:{"width":"100%"},on:{"click":_vm.exportXlsx}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-google-spreadsheet")]),_vm._v(" Ekspor XLSX ")],1)],1)],1),_c('div',{staticClass:"pl-md-8 py-6"},[(_vm.mainLoading)?_c('div',_vm._l((5),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"accent rounded-lg mb-2 pa-2",style:({
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }),attrs:{"type":"list-item"}})}),1):(_vm.histories && _vm.histories[0])?_c('div',[_vm._l((_vm.histories || []),function(item,i){return _c('div',{key:i,class:['accent', 'rounded-lg pa-4 mb-2', 'text-left'],style:({
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            })},[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('atoms-text',{staticClass:"font-weight-bold",attrs:{"auto":true}},[_vm._v(" "+_vm._s(_vm.changeDescription(item.desc))+" ")]),_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v(" Tanggal : "+_vm._s(_vm.$moment(item._createdDate).format('dddd, DD MMMM YYYY'))+" ")]),(item.updatedByName)?_c('atoms-text',[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-account-edit-outline")]),_vm._v(" "+_vm._s(item.updatedByName))],1):_vm._e(),_c('atoms-text',[_vm._v(" TransactionId: "+_vm._s(item._id)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('atoms-text',[(item.amount >= 0)?_c('span',{staticClass:"primary--text text-h5"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$addSeparator(Math.abs(item.amount)))+" ")],1):_c('span',{staticClass:"red--text text-h5"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus")]),_vm._v(" "+_vm._s(_vm.$addSeparator(Math.abs(item.amount)))+" ")],1)])],1)],1)],1)}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":this.pagesLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],2):_c('div',[_vm._v("Belum ada")])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }