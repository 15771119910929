<template>
  <v-btn
    v-bind="$props"
    v-on="$listeners"
    :loading="loading || $store.state.loading"
    :href="href"
    :to="to"
    :target="target"
    :disabled="disabled"
    :depressed="depressed === undefined ? true : depressed"
    :plain="plain"
    :icon="icon"
    :small="small"
    :fab="fab"
    :active-class="active"
    :class="['rounded-lg text-capitalize', classes]"
    :style="{
      'box-shadow': border ? 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px !important' : 'none',
    }"
    :outlined="outlined"
  >
    <slot v-if="$slots['custom']" name="custom" />
    <atoms-text v-else class="font-weight-medium" :auto="true"><slot /></atoms-text>
  </v-btn>
</template>
<script>
export default {
  props: {
    border: Boolean,
    classes: String,
    plain: Boolean,
    depressed: Boolean,
    disabled: Boolean,
    loading: Boolean,
    target: String,
    href: String,
    icon: Boolean,
    fab: Boolean,
    to: String,
    small: Boolean,
    active: String,
    outlined: Boolean,
  },
}
</script>
