const initial = () => ({
  title: 'perhatian',
  body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  button: 'oke',
  persistent: true,
  show: false,
})

export default {
  namespaced: true,
  state: initial,
  mutations: {
    show(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
        show: true,
      })
    },
    close(state) {
      state.show = false
      setTimeout(() => Object.assign(state, initial()), 1000)
    },
  },
}
