<template>
  <v-app>
    <div v-if="$store.state.user.isLogged">
      <v-scroll-x-transition>
        <molecules-sidebar />
      </v-scroll-x-transition>
      <v-main class="background" style="min-height: 100vh">
        <div class="primary white--text pa-2">
          Beberapa fitur baru yang sudah bisa diakses : Clients, Member, Absensi pada laman
          <a class="white" href="https://hayosystems-v3.web.app/dashboard">https://hayosystems-v3.web.app/dashboard</a>
        </div>
        <molecules-wrapper>
          <molecules-topbar />
          <div v-if="$store.state.break">
            <v-container
              class="d-flex flex-column justify-center align-center"
              :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }"
            >
              <atoms-image :width="300" src="/assets/maintenance.svg" />
              <atoms-title :h2="true" class="pt-8 px-8 text-center">Halaman ini sedang kami perbaiki </atoms-title>
              <atoms-text class="pa-4">Mohon maaf atas ketidaknyamanannya</atoms-text>
            </v-container>
          </div>
          <div v-else>
            <slot />
            <atoms-button
              icon
              :class="['rounded-lg ma-5 pa-5 background', $store.state.dark ? 'white--text' : 'black--text']"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
              style="position: fixed; right: 0; bottom: 0"
              @click="
                () => {
                  $vuetify.theme.dark = !$store.state.dark ? true : false
                  $store.commit('setTheme', !$store.state.dark ? true : false)
                }
              "
              ><v-icon dark small
                >mdi-{{ $store.state.dark ? 'white-balance-sunny ' : 'weather-night' }}</v-icon
              ></atoms-button
            >
          </div>
        </molecules-wrapper>
      </v-main>
    </div>
  </v-app>
</template>
<script>
export default {}
</script>
