const defaultPipeline = [
  {
    $lookup: {
      from: 'QuotationItems',
      let: { quotationId: '$_id' },
      pipeline: [
        {
          $match: {
            $expr: { $eq: ['$quotationId', '$$quotationId'] },
          },
        },
        {
          $sort: { order: 1 },
        },
      ],
      as: 'items',
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'createdBy',
      foreignField: '_id',
      as: 'createdBy',
    },
  },
  {
    $unwind: {
      path: '$createdBy',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'updatedBy',
      foreignField: '_id',
      as: 'updatedBy',
    },
  },
  {
    $unwind: {
      path: '$updatedBy',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Areas',
      localField: 'areaId',
      foreignField: '_id',
      as: 'area',
    },
  },
  {
    $lookup: {
      from: 'Clients',
      localField: 'clientId',
      foreignField: '_id',
      as: 'client',
    },
  },
  {
    $unwind: {
      path: '$client',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $unwind: {
      path: '$area',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $project: {
      'createdBy.password': 0,
      'updatedBy.password': 0,
    },
  },
  // {
  //     $facet: {
  //         "data": [],
  //         "info": [
  //             {
  //                 $count: "count"
  //             }
  //         ]
  //     }
  // },
  // {
  //     $unwind: {
  //         path: "$info",
  //         preserveNullAndEmptyArrays: true
  //     }
  // }
]
const collection = 'Quotations'
const initial = () => ({
  data: null,
  count: 0,
  totalQuo: 0,
  page: 1,
  pagesLength: 0,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async getSummary({ commit }, payload) {
      return await this._vm.$api
        .get(collection + '/getQuotations', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload,
            }),
          },
        })
        .then(async ({ result, pagesLength, ...rest }) => {
          const counter = {
            ...rest,
            totalQuo: result.reduce((prev, curr) => prev + curr.totalOffer, 0),
          }
          commit('SET', counter)
          return counter
        })
        .catch(async (err) => Promise.reject(err))
    },
    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/getQuotations', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: defaultPipeline,
              ...payload?.custom,
            }),
            page: payload?.page || state?.page || 1,
            limit: payload?.limit || state?.limit || 10,
          },
        })
        .then(({ result, ...rest }) => {
          if (!payload.dont_set) {
            commit('SET', {
              data: result,
              ...rest,
            })
          }
          return result
        })
        .catch(async (err) => Promise.reject(err))
    },
    async get({ commit, rootState }, payload) {
      return await this._vm.$api
        .get(collection + '/getQuotations', {
          params: {
            jsonQuery: JSON.stringify({
              isDeleted: { $ne: true },
              ...payload?.query,
              pipeline: defaultPipeline,
              ...payload?.custom,
            }),
            page: payload?.page || 1,
            limit: payload?.limit || 10,
          },
        })
        .then(({ result, count, ...rest }) => {
          commit('SET', {
            data: result,
            length: count,
            ...rest,
          })
          return result
        })
        .catch(async (err) => Promise.reject(err))
    },
    async create({ state }, payload) {
      return await this._vm.$api
        .post(collection + '/createQuotation', payload)
        .then(({ result }) => result || {})
        .catch(async (err) => Promise.reject(err))
    },
    async updateConversation({ commit }, { payload, params, ...rest }) {
      return await this._vm.$api
        .patch(collection + '/updateConversation', { ...payload, ...rest }, { ...params })
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async update({ state }, payload) {
      return await this._vm.$api
        .patch(collection + '/updateQuotation', payload)
        .catch(async (err) => Promise.reject(err))
    },
    async saveItems({ state }, payload) {
      return await this._vm.$api
        .post('QuotationItems/bulkSave', payload)
        .then((res) => {
          if (res?.message) {
            alert(res.message)
          }
          return res
        })
        .catch(async (err) => Promise.reject(err))
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
