var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '30%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('closed')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.preValidate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v("Tambah Lokasi")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-text-field',{attrs:{"label":"Nama Lokasi","rules":[
                (v) => !!v || 'Harap isi nama lokasi!',
                (v) => (!!v && _vm.duplicate !== v) || '(Nama sudah terdaftar) Gunakan nama lain!',
              ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('atoms-gmap-input',{attrs:{"address":_vm.address,"coordinate":_vm.coordinate}}),_c('atoms-text-field',[_c('v-textarea',{staticClass:"rounded-lg",attrs:{"placeholder":"Catatan","flat":"","solo":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"note"}})],1),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v("Tambah Lokasi")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }