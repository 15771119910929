<template>
  <v-overlay
    opacity="0.5"
    :value="!onLine || $store.state.loading"
    :style="{
      position: 'fixed',
    }"
    style="z-index: 90099"
  >
    <div class="d-flex flex-column align-center justify-center">
      <atoms-image width="50" :style="{ margin: 'auto' }" :src="require('@/assets/default/loading.gif')" />
      <atoms-title v-show="!$store.state.loading" class="font-weight-bold display-1">Periksa Koneksi Anda</atoms-title>
    </div>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    onLine: navigator.onLine,
  }),
  methods: {
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'
    },
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
}
</script>
