<template>
  <molecules-floating>
    <div
      ref="content"
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 pt-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row justify="space-between" class="px-8">
        <v-col cols="12" v-if="datas">
          <atoms-title :h2="true"
            >Percakapan Invoice <span class="primary--text">{{ datas.no }}</span></atoms-title
          >
        </v-col>
        <v-col cols="12">
          <v-container fluid class="pa-0">
            <div v-if="showEdit" class="d-flex accent align-center rounded-lg justify-space-between pa-4">
              <div class="d-flex flex-column flex-md-row">
                <div class="d-flex">
                  <div class="flex-shrink mr-2">
                    <v-icon small>mdi-pencil</v-icon>
                  </div>
                  <div class="flex-shrink">
                    <atoms-text>Edit Komentar : </atoms-text>
                  </div>
                </div>
                <div class="flex-grow">
                  <atoms-text>{{ $moment(showEdit._createdDate).format('dddd, DD MM YYYY | H:mm:ss') }}</atoms-text>
                </div>
              </div>
              <div>
                <v-icon @click="showEdit = null">mdi-close</v-icon>
              </div>
            </div>
            <atoms-text-field label="Tulis Komentar Anda..." :noLabel="true">
              <v-textarea
                background-color="accent"
                class="rounded-lg"
                append-icon="mdi-send"
                @click:append="assignMessage"
                placeholder="Masukkan Komentar"
                loader-height="100"
                v-model="message"
                hide-details
                dense
                solo
                flat
              ></v-textarea>
            </atoms-text-field>
          </v-container>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-container v-if="datas && datas.conversation && datas.conversation.length > 0">
            <v-row v-for="(it, i) in datas.conversation" :key="i" class="accent rounded-lg mb-2">
              <v-col class="shrink" align="center">
                <v-avatar class="background rounded-lg">
                  <atoms-image v-if="it && it.picture" :src="it.picture" />
                  <v-icon v-else color="primary">mdi-account</v-icon></v-avatar
                >
              </v-col>
              <v-col align="start" class="grow pb-0">
                <atoms-text class="font-weight-bold primary--text"
                  >{{ (it.nickname && it.nickname.trim()) || 'User' }},</atoms-text
                ><atoms-text :span="true">{{
                  $moment(it._createdDate).format('dddd, DD MM YYYY | H:mm:ss')
                }}</atoms-text>
              </v-col>
              <v-col class="shrink" v-if="it.userId === $store.state.user._id">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item
                      @click="
                        () => {
                          showEdit = it
                          message = it.message
                          $refs.content.scroll({
                            top: 0,
                            behavior: 'smooth',
                          })
                        }
                      "
                      link
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon left>mdi-pencil</v-icon>
                      <v-list-item-title class="px-2">Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="removeMessage(it._id)" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-delete</v-icon>
                      <v-list-item-title class="px-2">Hapus</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12" align="start" class="pt-0 text-left"
                ><atoms-text>{{ it.message }}</atoms-text></v-col
              >
            </v-row>
          </v-container>
          <v-container id="scroll" v-else class="d-flex justify-center align-center">
            <div class="text-center">
              <atoms-image :width="250" src="/assets/missing.svg" />
              <atoms-title :h3="true" class="mt-6">Belum Ada Percakapan!</atoms-title>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      datas: null,
      message: '',
      showEdit: null,
    }
  },
  watch: {
    showEdit: function (newValue) {
      if (!newValue) {
        this.message = ''
      }
    },
  },
  async mounted() {
    this.$store.commit('SET', { loading: true })
    await this.loadData()
    await this.readConversation()
    if (!this.datas || !this.id) {
      throw new Error('Gagal Memuat Data')
    }
    this.$store.commit('SET', { loading: false })
  },
  errorCaptured() {
    this.$emit('closed')
  },
  methods: {
    async loadData() {
      this.datas = await this.$api
        .get('Invoices/get', {
          params: {
            jsonQuery: JSON.stringify({
              pipeline: [
                {
                  $match: {
                    _id: this.id,
                  },
                },
                {
                  $lookup: {
                    from: 'Users',
                    localField: 'conversation.userId',
                    foreignField: '_id',
                    as: 'conversation2',
                  },
                },
                {
                  $addFields: {
                    conversation: {
                      $map: {
                        input: '$conversation',
                        in: {
                          $let: {
                            vars: {
                              m: {
                                $arrayElemAt: [
                                  {
                                    $filter: {
                                      input: '$conversation2',
                                      cond: {
                                        $eq: ['$$mb._id', '$$this.userId'],
                                      },
                                      as: 'mb',
                                    },
                                  },
                                  0,
                                ],
                              },
                            },
                            in: {
                              $mergeObjects: ['$$m', '$$this'],
                            },
                          },
                        },
                      },
                    },
                  },
                },
                {
                  $unset: 'conversation2',
                },
                {
                  $unset: 'conversation.password',
                },
              ],
            }),
          },
        })
        .then((data) => data?.result?.[0] || {})
      if (this.datas?.conversation) {
        this.datas.conversation = this.datas.conversation.sort((a, b) => {
          var dateA = new Date(a._createdDate).getTime()
          var dateB = new Date(b._createdDate).getTime()
          return dateA < dateB ? 1 : -1 // ? -1 : 1 for ascending/increasing order
        })
      }
    },
    async assignMessage() {
      this.$store.commit('SET', { loading: true })
      try {
        if (this.message) {
          const _id = this.$uuid.v4()
          const userId = this.$store.state.user?._id
          if (this.showEdit && this.showEdit?._id) {
            await this.$store.dispatch('invoice/updateConversation', {
              payload: {
                _id: this.id,
                'conversation.$.message': this.message,
                'conversation.$._updatedDate': new Date(),
                hadReadConversation: [userId],
              },
              params: {
                params: {
                  jsonQuery: JSON.stringify({
                    'conversation._id': this.showEdit._id,
                  }),
                },
              },
            })
            this.showEdit = null
          } else {
            const payload = {
              _id,
              userId,
              message: this.message,
              _createdDate: new Date(),
              nickname: this.$store.state.user?.nickname,
            }
            await this.$store.dispatch('invoice/updateConversation', {
              _id: this.id,
              $addToSet: { conversation: [payload] },
              hadReadConversation: [userId],
            })
          }

          await this.loadData()
          this.message = ''
          this.$vuetify.goTo(0)
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian!`,
          })
        }
      } catch (error) {
        throw error
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async readConversation() {
      try {
        const userId = this.$store.state.user?._id
        await this.$store.dispatch('invoice/updateConversation', {
          _id: this.id,
          $addToSet: { hadReadConversation: [userId] },
        })
        return true
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Gangguan',
          body: err?.response?.data?.message || err?.message,
        })
      }
    },
    async removeMessage(idConversation) {
      this.$store.commit('SET', { loading: true })
      try {
        if (idConversation) {
          await this.$store.dispatch('invoice/updateConversation', {
            _id: this.id,
            $pull: { conversation: { _id: idConversation } },
          })
          await this.loadData()
          this.message = ''
          this.$vuetify.goTo(0)
        }
      } catch (error) {
        throw error
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
