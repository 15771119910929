var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"pa-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('closed')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',{},[_c('v-row',{staticClass:"mx-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('atoms-title',[_vm._v("Bagaimana caranya approve permintaan?")])],1),_c('v-col',{attrs:{"cols":"12","align":"start"}},[_c('atoms-text',[_vm._v("Pilih pekerja mana yang ingin disetujui permintaannya, lalu klik ikon `titik tiga`, kemudian pilih `approve`")]),_c('atoms-image',{staticClass:"my-4",attrs:{"width":"100%","src":"/media/request-payment/tutorial-approve.gif"}}),_c('atoms-text',[_vm._v("Atau Anda bisa pilih pekerja dengan mencentang ikon `kotak` kemudian pilih tombol `approve` yang muncul setelahnya")]),_c('atoms-image',{staticClass:"my-4",attrs:{"width":"100%","src":"/media/request-payment/tutorial-approvebulk.gif"}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }