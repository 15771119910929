<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container v-if="target && target._id" :class="[(!$vuetify.breakpoint.smAndDown && 'px-10') || '']">
        <div class="text-left">
          <atoms-title :h2="true">{{ (target.client && target.client.name) || 'Shift Kuotasi' }}</atoms-title>
        </div>
        <br />
        <div class="text-left">
          <atoms-text><span class="font-weight-bold">Area : </span> {{ target.areaTitle || '-' }}</atoms-text>
          <atoms-text><span class="font-weight-bold">Lokasi : </span> {{ target.location || '-' }}</atoms-text>
          <atoms-text><span class="font-weight-bold">Shift : </span> {{ target.title || '-' }}</atoms-text>
        </div>
        <br />
        <img :src="target.qrCode" width="50%" />
        <br />
        <br />
        <atoms-button :href="`/cetak-shift/${target._id}`" target="_blank" style="width: 100%" class="primary"
          >Cetak</atoms-button
        >
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['target'],
  data() {
    return {
      valid: true,
    }
  },
}
</script>
