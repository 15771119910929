<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="teal" :style="style" v-bind="attrs" v-on="on">
          <v-icon>mdi-map</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="teal" style="border-radius: 0; box-shadow: none">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Map</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn style="font-size: 16px" dark text @click="onSave" :loading="loadingSave"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div>
          <atoms-google-map v-model="marker" />
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    style: String,
  },
  data() {
    return {
      dialog: false,
      marker: {
        position: {
          lat: null,
          lng: null,
        },
      },
      address: '',
      loadingSave: false,
    }
  },
  mounted() {
    const pos = this.$store.getters['gmap/coordinate']
    this.marker.position = {
      lat: pos.latitude,
      lng: pos.longitude,
    }
  },
  methods: {
    async onSave() {
      this.loadingSave = true
      const pos = this.marker.position
      const fullAddress = await this.$api
        .get(`Places/getAddress`, {
          params: {
            latitude: pos.lat,
            longitude: pos.lng,
          },
        })
        .then((data) => data.result)
      this.$store.commit('gmap/setAddress', fullAddress)
      this.$store.commit('gmap/setCoordinate', {
        latitude: pos.lat,
        longitude: pos.lng,
      })
      this.dialog = false
      this.loadingSave = false
    },
  },
}
</script>
