<template>
  <molecules-floating>
    <v-card
      elevation="0"
      rounded="lg"
      class="mx-auto pa-4 rounded-lg"
      :min-width="$vuetify.breakpoint.smAndDown ? 350 : 500"
    >
      <v-card-title>
        <atoms-title :h3="true" class="font-weight-bold">Edit Fee</atoms-title>
      </v-card-title>
      <v-card-text>
        <atoms-text-field label="Persentase Fee" :no-label="true" v-model="feePercentage" />
        <atoms-button :style="{ width: '100%' }" @click="saveFee" class="mt-5 secondary">Simpan</atoms-button>
        <atoms-button :style="{ width: '100%' }" class="mt-5 primary" @click="close">Kembali</atoms-button>
      </v-card-text>
    </v-card>
  </molecules-floating>
</template>
<script>
export default {
  props: {
    show: Boolean,
    data: Object,
  },
  data() {
    return {
      clicked: false,
      reset: false,
      feePercentage: '0.03%',
    }
  },
  watch: {
    feePercentage: function (value) {
      try {
        const converter = (String(value)?.includes('%') && this.$convertPercenttoDecimal(value)) || Number(value) / 100
        this.$nextTick(() => {
          this.feePercentage = this.$convertDecimaltoPercent((converter > 1 && 1) || converter || 0.3)
        })
      } catch (error) {
        throw error
      }
    },
  },
  async mounted() {
    this.$store.commit('SET', { loading: true })
    await this.loadData()
    if (!this.data) {
      this.close()
      throw new Error('Gagal Memuat Data')
    }
    this.$store.commit('SET', { loading: false })
  },
  methods: {
    close(payload) {
      this.reset = false
      this.clicked = false
      this.$emit('close', payload)
    },
    async loadData() {
      try {
        const result = Number(
          (
            await this.$store.dispatch('requestPayments/getAdminFee', {
              _id: "RequestPayments",
            })
          )?.result?.feePercentage || 0.3,
        )
        this.feePercentage = result
      } catch (error) {
        throw error
      }
    },
    async saveFee() {
      this.$store.commit('SET', { loading: true })
      try {
        if (this.feePercentage) {
          await this.$store.dispatch('requestPayments/saveAdminFee', {
            _id: "RequestPayments",
            feePercentage: this.$convertPercenttoDecimal(this.feePercentage),
          })
          this.close()
          this.$vuetify.goTo(0)
        }
      } catch (error) {
        throw error
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
