var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating-v2',[_c('div',{ref:"content",staticClass:"d-relative rounded-lg background pa-5",style:({
      margin: '10px auto',
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '100%',
      'min-height': '25vh',
    })},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"grow",attrs:{"cols":"auto","align":"start"}},[_c('atoms-title',[_vm._v("Status Filter")])],1),_c('v-col',{staticClass:"shrink",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"dense":""}},_vm._l((_vm.items || []),function(it,_iit){return _c('v-col',{key:_iit,staticStyle:{"cursor":"pointer"},attrs:{"cols":"12"},on:{"click":function($event){$event.stopPropagation();return _vm.updateItem(it)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return _c('div',{class:[
              'rounded-lg pa-2',
              hover || (_vm.item && _vm.item.find((x) => x.title == it.title)) ? 'primary white--text' : '',
            ],style:({
              transform: hover ? 'scale(1.1)' : 'scale(1)',
              pointerEvents: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px !important',
            })},[_c('atoms-text',[_vm._v(_vm._s(it.title))])],1)}}],null,true)})],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }