<template>
  <div>
    <v-navigation-drawer app :mini-variant="mini" style="border: none" class="accent">
      <!-- <template v-slot:prepend>
        <v-list> height="100%" :mini-variant="mini"
<v-list-item
                  v-bind="attrs"
                  v-on="on"
                  slot-scope="{ hover: nearHover }"
                  :class="[
                    'rounded-lg justify-center transparent',
                    hover && 'mx-2 pa-4',
                    nearHover && 'accent',
                  ]"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-list-item-avatar
                    color="accent"
                    :size="40"
                    class="rounded-lg"
                    :style="{
                      cursor: 'pointer',
                    }"
                  >
                    <v-img
                      v-if="$store.state.client.logo"
                      contain
                      aspect-ratio="1"
                      :src="$store.state.client.logo || '/lazy.webp'"
                    />
                    <v-icon v-else>mdi-domain</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title class="d-flex">
                    <div>
                      <atoms-text class="font-weight-bold">{{
                        `${$store.state.client.name || "Klien"}`.length >= 14
                          ? `${$store.state.client.name || "Klien"}`.substring(
                              0,
                              14
                            ) + "..."
                          : `${$store.state.client.name || "Klien"}`
                      }}</atoms-text>
                      <atoms-text class="primary--text">{{
                        $store.state.user.email
                      }}</atoms-text>
                    </div>
                    <v-icon>mdi-unfold-more-horizontal</v-icon>
                  </v-list-item-title>
                </v-list-item>
        </v-list>
      </template> -->
      <template v-slot:prepend>
        <div class="d-flex align-center justify-around flex-row my-4 mx-2">
          <img v-if="!mini" style="width: 50%" class="mr-auto" :src="require('@/assets/hayokerja.png')" />
          <v-btn class="mb-md-2" @click="mini = !mini" depressed icon elevation="0"><v-icon>mdi-menu</v-icon></v-btn>
        </div>
      </template>
      <v-list nav dense>
        <v-list-item
          v-for="({ path, icon, title }, i) in items && items"
          :key="i"
          :to="path"
          link
          active-class="primary white--text "
        >
          <v-list-item-icon :title="title">
            <v-icon>mdi-{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            ><atoms-text :auto="true">{{ $toCapitalize(title) }}</atoms-text></v-list-item-title
          >
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list nav dense :class="['rounded-xl']">
          <v-menu
            close-on-click
            v-model="menu"
            offset-y
            rounded="lg"
            :style="{
              'box-shadow': 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
            }"
            min-width="350"
            max-width="350"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :style="{
                  cursor: 'pointer',
                }"
              >
                <v-list-item-avatar
                  color="accent"
                  :size="mini ? 30 : 50"
                  class="rounded-pill"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-img v-if="$store.state.user.picture" :src="$store.state.user.picture || '/lazy.webp'" />
                  <v-icon v-else>mdi-domain</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="d-flex justify-space-between">
                  <div>
                    <atoms-text class="font-weight-bold">{{
                      `${$store.state.user.nickname || 'Pengguna'}`.length >= 14
                        ? `${$store.state.user.nickname || 'Pengguna'}`.substring(0, 14) + '...'
                        : `${$store.state.user.nickname || 'Pengguna'}`
                    }}</atoms-text>
                    <atoms-text class="primary--text">{{ $store.state.user.email }}</atoms-text>
                  </div>
                  <v-icon right>mdi-unfold-more-horizontal</v-icon>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list nav class="pa-4 rounded-lg">
              <!-- not child -->
              <v-list-item exact-active-class="primary--text" class="rounded-lg pr-4">
                <v-list-item-avatar
                  color="accent"
                  size="50"
                  class="rounded-full"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-img
                    v-if="$store.state.user.picture"
                    :src="$store.state.user.picture || '/lazy-img-webp'"
                    aspect-ratio="1"
                  />
                  <v-icon v-else> mdi-account </v-icon>
                </v-list-item-avatar>
                <v-list-item-title
                  ><atoms-text class="font-weight-bold">{{ $store.state.user.nickname || 'Pengguna' }}</atoms-text>
                  <atoms-text>{{ $store.state.user.email }}</atoms-text></v-list-item-title
                >
              </v-list-item>
              <v-divider class="my-1" />
              <v-hover>
                <v-list-item
                  @click="showReset = true"
                  slot-scope="{ hover: hoverList }"
                  :class="['mt-2 primary--text rounded-lg', hoverList && 'primary lighten-5']"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><v-icon left color="primary">mdi-lock</v-icon>Detail Akun Anda</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-hover>

              <v-list-item
                active-class="transparent"
                class="white--text mt-2 red lighten-5 red--text rounded-lg"
                :style="{
                  cursor: 'pointer',
                }"
                @click="logout"
              >
                <v-list-item-content>
                  <v-list-item-title><v-icon left color="red">mdi-logout</v-icon>Keluar</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-list-item
            link
            class="red lighten-5 red--text rounded-lg"
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon dark color="red">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              ><atoms-text :auto="true" class="red--text">{{
                $toCapitalize("Keluar")
              }}</atoms-text></v-list-item-title
            >
          </v-list-item> -->
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-slide-x-reverse-transition>
      <organism-users-reset v-if="showReset" @closed="showReset = false" />
    </v-slide-x-reverse-transition>
  </div>
</template>
<script>
import { routes } from '@/router/index'
export default {
  data() {
    return {
      items: [],
      menu: false,
      mini: true,
      showReset: false,
    }
  },
  mounted() {
    this.checker()
  },
  methods: {
    async logout() {
      this.$store.commit('SET', { loading: true })
      await this.$store.dispatch('user/logout')
      this.$store.commit('SET', { loading: false })
    },
    async checker() {
      this.items = routes
        .filter(
          (x) =>
            x.meta &&
            !x.meta?.hidden &&
            x.meta?.type?.find((x) => this.$store.state.user?.roles?.includes(x) || x == this.$roles.All),
        )
        .map((x) =>
          Object.assign({
            title: x.meta?.title || x.name,
            path: x.path,
            icon: x.meta?.icon || '',
          }),
        )
      if (!this.items) {
        Promise.reject('Kesalahan ketika memuat data')
        await this.logout()
      }
    },
  },
}
</script>

<style scoped>
::v-deep ::-webkit-scrollbar {
  width: 2px;
}
::v-deep ::-webkit-scrollbar-track {
  background-color: darkgrey;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: var(--v-primary-base);
}
</style>
