<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="onClose"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <molecules-wrapper>
        <v-row justify="center">
          <v-col cols="12">
            <atoms-title>Pekerja Shift {{ shift.title || '-' }}</atoms-title>
          </v-col>
          <v-col cols="10" class="rounded-lg my-2">
            <div v-if="mainLoading || !result">
              <v-skeleton-loader
                v-for="i in 5"
                :key="i"
                type="list-item-avatar"
                class="accent rounded-lg mb-2 pa-2"
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
              />
            </div>

            <div v-else v-for="(item, i) in result" :key="i" class="accent rounded-lg my-2 pa-5">
              <v-avatar color="grey lighten-3" size="100" class="rounded-full mr-auto">
                <atoms-image v-if="item.photo" :width="100" :enableZoom="true" :src="item.photo" />
                <v-icon v-else light>mdi-account</v-icon>
              </v-avatar>
              <atoms-text class="font-weight-bold text-left primary--text"
                ><v-icon left>mdi-account</v-icon>{{ item.fullName }}</atoms-text
              >
              <atoms-text class="text-left"
                ><span class="font-weight-bold">Target Hari Kerja :</span>
                {{ item.targetAttendances || '-' }}</atoms-text
              >
              <atoms-text class="text-left"
                ><span class="font-weight-bold">Kehadiran :</span> {{ item.totalAttendances || '-' }}</atoms-text
              >
              <atoms-text class="text-left"
                ><span class="font-weight-bold">Approved :</span> {{ item.approved || '-' }}</atoms-text
              >
              <atoms-text class="text-left"
                ><span class="font-weight-bold">Rejected :</span> {{ item.rejected || '-' }}</atoms-text
              >
              <atoms-text class="text-left"
                ><span class="font-weight-bold">Gaji yang diperoleh :</span>
                {{ $idCurrency(item.gainSalary || 0) }}</atoms-text
              >
              <atoms-button
                v-if="item.totalAttendances > 0"
                style="width: 100%"
                class="mt-2"
                @click="loadAttendance(item)"
                >Lihat detail absensi</atoms-button
              >
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination class="my-4" v-model="page" :length="pagesLength"></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </molecules-wrapper>

      <v-slide-x-reverse-transition>
        <organism-attendance-detail
          v-if="showDetail"
          :disableEdit="true"
          @closed="
            () => {
              showDetail = null
            }
          "
          :data="showDetail"
        />
      </v-slide-x-reverse-transition>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['shift'],
  data() {
    return {
      showDetail: null,
      result: null,
      page: 1,
      pagesLength: 0,
      limit: 5,
      mainLoading: false,
      //   showEditPosition: false,
      //   showEditJamMulai: false,
      //   url: "",
    }
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden'
    this.loadWorkers()
  },
  methods: {
    onClose() {
      document.documentElement.style.overflow = 'auto'
      this.$emit('close')
    },
    async loadWorkers() {
      this.mainLoading = true
      try {
        const data = await this.$api.get(`Workers/getMongo`, {
          params: {
            jsonQuery: JSON.stringify({
              _id: { $in: this.shift?.workerIds },
            }),
            page: this.page,
            limit: this.limit,
          },
        })

        this.result = data?.result
        if (this.result?.[0]) {
          this.pagesLength = data.pagesLength
          this.result = await Promise.all(
            this.result.map(async (worker) => {
              const targetAttendances = await this.$api
                .get('/QuotationItems/get', {
                  params: {
                    jsonQuery: {
                      _id: this.shift?._id,
                      pipeline: [
                        {
                          $project: {
                            quantity: 1,
                          },
                        },
                      ],
                    },
                  },
                })
                .then((data) => data?.result?.[0]?.quantity || 0)
              const totalAttendances = await this.$api
                .get('/Absensi/count', {
                  params: {
                    jsonQuery: {
                      shiftId: this.shift?._id,
                      ktpPhl: worker?._id,
                    },
                  },
                })
                .then((data) => data?.result || 0)
              const approved = await this.$api
                .get('/Absensi/count', {
                  params: {
                    jsonQuery: {
                      shiftId: this.shift?._id,
                      ktpPhl: worker?._id,
                      supervisorApproval: 'Approved',
                    },
                  },
                })
                .then((data) => data?.result || 0)
              const rejected = await this.$api
                .get('/Absensi/count', {
                  params: {
                    jsonQuery: {
                      shiftId: this.shift?._id,
                      ktpPhl: worker?._id,
                      supervisorApproval: 'Rejected',
                    },
                  },
                })
                .then((data) => data?.result || 0)
              const isPaidAttendances = await this.$api
                .get('/Absensi/count', {
                  params: {
                    jsonQuery: {
                      shiftId: this.shift?._id,
                      ktpPhl: worker?._id,
                      isPaid: true,
                    },
                  },
                })
                .then((data) => data?.result || 0)
              const gainSalary = isPaidAttendances * (this?.shift?.priceBase || 0)
              const send = {
                ...worker,
                targetAttendances,
                totalAttendances,
                approved,
                rejected,
                gainSalary,
              }
              return send
            }),
          )
          //   this.listWorkers = workers
        }
      } catch (error) {
      } finally {
        this.mainLoading = false
      }
    },

    async loadAttendance(worker) {
      this.mainLoading = true
      this.showDetail = (
        await this.$store.dispatch('attendance/getAll', {
          query: {
            ktpPhl: worker._id,
          },
          page: this.page,
        })
      )?.data?.[0]
      this.mainLoading = false
    },
  },
}
</script>
