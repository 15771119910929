/* eslint-disable */
const collection = 'Clients'
const initial = () => ({
  data: null,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async summary({ commit }, query) {
      const countPipeline = [
        {
          $group: {
            _id: {
              $dateToString: {
                format: '%Y-%m-%d',
                date: '$_createdDate',
              },
            },
            total: { $sum: 1 },
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]
      await this._vm.$api.get(collection + '/count').then(({ result }) => commit('SET', { total: result }))
      await Promise.all([
        this._vm.$api
          .get(collection + '/getClients', {
            params: {
              jsonQuery: JSON.stringify({
                ...query,
                pipeline: [
                  ...countPipeline,
                  {
                    $match: {
                      _id: this._vm.$moment().format('YYYY-MM-DD'),
                    },
                  },
                ],
              }),
            },
          })
          .then(({ result }) => result),
        this._vm.$api
          .get(collection + '/getClients', {
            params: {
              jsonQuery: JSON.stringify({
                ...query,
                pipeline: [
                  ...countPipeline,
                  {
                    $match: {
                      _id: {
                        $lte: this._vm.$moment().format('YYYY-MM-DD'),
                        $gte: this._vm.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
                      },
                    },
                  },
                ],
              }),
            },
          })
          .then(({ result }) => result),
      ]).then(([day, days]) => {
        commit('SET', {
          currentDay: day?.[0] || 0,
          within7Days: days || 0,
        })
      })
    },
    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/getClients', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              ...payload?.custom,
            }),
            page: payload?.page || 1,
            limit: payload?.limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          // const { result, ...rest } = await this._vm.$api.get(`Clients/getBalance`, {
          //     params: {
          //       // jsonQuery: JSON.stringify({
          //       _id: state,
          //       // }),
          //     },
          //   })
          if (!payload?.returnOnly)
            commit('SET', {
              data: result,
              ...rest,
            })
          return result
        })
    },
    async get({ commit }, clientId) {
      return await this._vm.$api
        .get(collection + '/getClients', {
          params: {
            jsonQuery: JSON.stringify({ _id: clientId }),
          },
        })
        .then(({ result, ...rest }) => {
          if (!result || (result && result.length < 1)) {
            return Promise.reject('Klien tidak ditemukan!')
          }
          return result?.[0]
        })
    },
    async update({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .patch(collection + '/updateClient', {
            sendMongo: payload,
          })
          .then(async ({ result }) => {
            return result
          })
          .catch((error) => Promise.reject(error))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
    async register(
      { dispatch },
      {
        code,
        name, //
        npwp, //
        email, //
        address, //
        website, //
        contactNum, //
        contactName, //
        companyType, //
        socialMedia,
        description,
        createdBy,
        logo = null,
      },
    ) {
      return await this._vm.$api
        .post(collection + '/registerClient', {
          sendMongo: {
            name,
            code,
            companyType,
            npwp,
            description,
            website,
            address: {
              streetAddress1: address,
            },
            contactPerson: {
              name: contactName,
              phone: contactNum,
              email,
            },
            socialMedia,
            createdBy,
            active: true,
            isHkReg: true,
            approved: undefined,
          },
        })
        .then(async ({ result }) => {
          if (result?._id && logo) {
            return dispatch('update', {
              _id: result?._id,
              logo: await this._vm.$uploadFile(logo, `Clients/${result._id}/Profil`, `picture`),
            })
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
