var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('closed')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),(_vm.data)?_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.preValidate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v("Edit Area")]),_c('atoms-text',[_vm._v(_vm._s(_vm.data.title))])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-text-field',{attrs:{"label":"Nama Area","mask":"AAA","rules":[
                (v) => !!v || 'Harap diisi!',
                (v) => (!!v && v.length === 3) || 'Terdiri dari 3 huruf!',
                (v) => (!!v && _vm.duplicate !== v) || '(Nama sudah terdaftar) Gunakan nama lain!',
              ]},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}}),_c('v-checkbox',{staticClass:"mb-4 text-caption",attrs:{"label":"Area Tidak Aktif","dense":"","hide-details":""},model:{value:(_vm.inactive),callback:function ($$v) {_vm.inactive=$$v},expression:"inactive"}}),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v("Perbarui Area")])],1)],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',{attrs:{"h3":true}},[_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"left":""},on:{"click":function($event){return _vm.$showDialog({
                  title: 'Informasi',
                  body: `Mengedit Lokasi dari Area akan diarahkan ke halaman Lokasi`,
                })}}},[_vm._v("mdi-information")]),_vm._v("Lokasi Area "+_vm._s(_vm.data.title)+" ")],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-data-table',{staticClass:"accent rounded-lg",attrs:{"headers":[
              {
                text: 'Lokasi',
                sortable: false,
                value: 'name',
                width: '40%',
                class: 'primary--text text-uppercase',
              },
              {
                text: '',
                sortable: false,
                value: 'action',
                class: 'text-uppercase',
              },
            ],"light":"","items":_vm.data.locations,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:`header`,fn:function({ props }){return [_c('thead',[_c('tr',{staticClass:"d-table-row"},_vm._l((props.headers),function(h){return _c('th',{key:h.value,class:h.class},[_c('span',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:`item`,fn:function({ item }){return [_c('tr',{staticClass:"d-table-row"},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.name))]),(_vm.$vuetify.breakpoint.smAndDown)?_c('td',{style:({
                    width: '100%',
                  })}):_vm._e(),_c('td',{staticClass:"text-right"},[_c('v-icon',{attrs:{"small":"","light":""},on:{"click":($event) => {
                        const routeData = _vm.$router.resolve({
                          path: '/areas/location/submit',
                          query: {
                            targetArea: _vm.$encodeObject(_vm.data),
                            targetLocation: _vm.$encodeObject(item),
                          },
                        })
                        _vm.$window.open(routeData.href, '_blank')
                      }}},[_vm._v(" mdi-pencil ")])],1)])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),on:{"click":function($event){return _vm.$router.push(`/areas/location?direct=${_vm.data._id}`)}}},[_vm._v("Kelola Lokasi")])],1)],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }