<template>
  <v-card class="pa-3 my-3" elevation="0">
    <v-row justify="center" align="center" class="px-3 pt-1 pb-6">
      <v-col>
        <h2>Rangkuman Penambahan Pekerja</h2>
        <p>Grafik Penambahan Pekerja yang telah diapprove</p>
      </v-col>
      <v-col>
        <v-select
          v-model="chartFilter"
          :items="[
            {
              label: '1 Minggu Terakhir',
              value: 'week',
            },
            {
              label: '1 Bulan Terakhir',
              value: 'month',
            },
            {
              label: '1 Tahun Terakhir',
              value: 'year',
            },
          ]"
          :menu-props="{ offsetY: true }"
          item-text="label"
          item-value="value"
          dense
          solo
          hide-details
        />
      </v-col>
    </v-row>
    <div>
      <atoms-line-chart :chart-data="chartData" :options="chartOptions" />
    </div>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      chartFilter: 'week',
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        // scales: {
        //   yAxes: [
        //     {
        //       ticks: {
        //         beginAtZero: true,
        //       },
        //     },
        //   ],
        // },
      },
      labels: [],
      dataWorkers: [],
      dataClients: [],
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            data: this.dataWorkers,
            label: 'Pekerja',
            borderColor: '#3e95cd',
            fill: false,
          },
          //   {
          //     data: this.dataClients,
          //     label: "Klien",
          //     borderColor: "#3cba9f",
          //     fill: false
          //   }
        ],
      }
    },
  },
  watch: {
    async chartFilter() {
      await this.fetchChart()
    },
  },
  async mounted() {
    await this.fetchChart()
  },
  methods: {
    async fetchChart() {
      this.labels = []
      this.dataWorkers = []
      this.dataClients = []
      let workers = []
      //   let clients = [];
      if (this.chartFilter === 'week') {
        const queryWeek = {
          _createdDate: {
            $gte: new Date(new Date().setDate(new Date().getDate() - 7)),
            $lte: new Date(new Date().setHours(-1, 59, 59, 59)),
          },
          pipeline: [
            {
              $group: {
                _id: {
                  year: { $year: '$_createdDate' },
                  month: { $month: '$_createdDate' },
                  date: { $dayOfMonth: '$_createdDate' },
                },
                total: { $sum: 1 },
              },
            },
            {
              $sort: {
                '_id.year': 1,
                '_id.month': 1,
                '_id.date': 1,
              },
            },
            {
              $project: {
                _id: 1,
                total: 1,
                tanggal: {
                  $concat: [
                    { $toString: '$_id.date' },
                    '/',
                    { $toString: '$_id.month' },
                    '/',
                    { $toString: '$_id.year' },
                  ],
                },
              },
            },
          ],
        }
        workers = await this.getWorkers(queryWeek)
        // clients = await this.getClients(queryWeek);
      } else if (this.chartFilter === 'month') {
        const queryMonth = {
          _createdDate: {
            $gte: new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0),
            $lte: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
          },
          pipeline: [
            {
              $group: {
                _id: {
                  year: { $year: '$_createdDate' },
                  month: { $month: '$_createdDate' },
                  date: { $dayOfMonth: '$_createdDate' },
                },
                total: { $sum: 1 },
              },
            },
            {
              $sort: {
                '_id.year': 1,
                '_id.month': 1,
                '_id.date': 1,
              },
            },
            {
              $project: {
                _id: 1,
                total: 1,
                tanggal: {
                  $concat: [
                    { $toString: '$_id.date' },
                    '/',
                    { $toString: '$_id.month' },
                    '/',
                    { $toString: '$_id.year' },
                  ],
                },
              },
            },
          ],
        }
        workers = await this.getWorkers(queryMonth)
        // clients = await this.getClients(queryMonth);
      } else if (this.chartFilter === 'year') {
        const queryYear = {
          _createdDate: {
            $gte: new Date(new Date().getFullYear(), 0, 1, 7, 0, 0),
            $lte: new Date(new Date().getFullYear() + 1, -1, 0),
          },
          pipeline: [
            {
              $group: {
                _id: {
                  year: { $year: '$_createdDate' },
                  month: { $month: '$_createdDate' },
                },
                total: { $sum: 1 },
              },
            },
            {
              $sort: {
                '_id.year': 1,
                '_id.month': 1,
              },
            },
            {
              $project: {
                _id: 1,
                total: 1,
                tanggal: {
                  $concat: [{ $toString: '$_id.month' }, '/', { $toString: '$_id.year' }],
                },
              },
            },
          ],
        }
        workers = await this.getWorkers(queryYear)
        // clients = await this.getClients(clients);
      }
      workers?.forEach((worker, i) => {
        this.labels.push(worker.tanggal)
        this.dataWorkers.push(worker.total)
        // this.dataClients.push(clients?.[i]?.total || 0);
      })
    },
    async getWorkers(query = {}) {
      try {
        const result = await this.$api
          .get('/Workers/getMongo', {
            params: {
              jsonQuery: JSON.stringify({
                active: true,
                approved: true,
                resigned: { $ne: true },
                blacklist: { $ne: true },
                ...query,
              }),
            },
          })
          .then((data) => data?.result || [])
        return result
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
    },
    async getClients(query = {}) {
      try {
        const result = await this.$api
          .get('/Clients/getClients', {
            params: {
              jsonQuery: JSON.stringify({
                ...query,
              }),
            },
          })
          .then((data) => data?.result || [])
        return result
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
    },
  },
}
</script>
<style scoped></style>
