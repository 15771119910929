import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/es5/util/colors'
import id from 'vuetify/lib/locale/id'
import store from '@/store/index'

Vue.use(Vuetify)

const customColors = {
  primary: '#024ed1', //colors.blue.darken4,
  secondary: '#5de478',
  info: colors.teal.lighten1,
  warning: colors.amber.base,
  error: colors.deepOrange.accent4,
  success: colors.green.accent3,
}

export default new Vuetify({
  customVariables: ['@/styles/variables.scss'],
  treeShake: true,
  theme: {
    options: { customProperties: true },
    dark: store.state.dark,
    themes: {
      light: {
        ...customColors,
        background: '#F6F6F6', //"#F7F8FB",
        accent: '#ffffff',
      },
      dark: {
        ...customColors,
        background: '#2f2f2f',
        accent: '#1a1a1a',
      },
    },
  },
  lang: {
    locales: { id },
    current: 'id',
  },
})
