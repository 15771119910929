import router from '@/router/index'
const defaultPipeline = [
  {
    $lookup: {
      from: 'Workers',
      localField: 'workerId',
      foreignField: '_id',
      as: 'worker',
    },
  },
  {
    $unwind: {
      path: '$worker',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'WorkerDetails',
      localField: 'worker.detailId',
      foreignField: '_id',
      as: 'workerDetail',
    },
  },
  {
    $unwind: {
      path: '$workerDetail',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $project: {
      password: 0,
    },
  },
]
const collection = 'Users'
/* eslint-disable */
const initial = () => ({
  data: null,
  total: 0,
  isLogged: false,
  currentDay: 0,
  within7Days: 0,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async exists({ state }, query) {
      return await this._vm.$api
        .get('Users/isExist', {
          params: {
            jsonQuery: JSON.stringify(query),
          },
        })
        .catch((error) => Promise.reject(error))
    },
    async summary({ commit }, query) {
      // get total
      const countPipeline = [
        {
          $group: {
            _id: {
              $dateToString: {
                format: '%Y-%m-%d',
                date: '$_createdDate',
              },
            },
            total: { $sum: 1 },
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]
      await this._vm.$api
        .get(collection + '/getUsers', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [
                ...defaultPipeline,
                {
                  $facet: {
                    data: [],
                    info: [
                      {
                        $count: 'count',
                      },
                    ],
                  },
                },
                {
                  $project: {
                    count: '$info.count',
                  },
                },
                {
                  $unwind: {
                    path: '$count',
                    preserveNullAndEmptyArrays: true,
                  },
                },
              ],
            }),
          },
        })
        .then(({ result, ...rest }) =>
          commit('SET', {
            total: result?.[0]?.count || 0,
          }),
        )

      Promise.all([
        this._vm.$api
          .get(collection + '/getUsers', {
            params: {
              jsonQuery: JSON.stringify({
                ...query,
                pipeline: [
                  ...defaultPipeline,
                  ...countPipeline,
                  {
                    $match: {
                      _id: this._vm.$moment().format('YYYY-MM-DD'),
                    },
                  },
                ],
              }),
            },
          })
          .then(({ result }) => result),
        this._vm.$api
          .get(collection + '/getUsers', {
            params: {
              jsonQuery: JSON.stringify({
                ...query,
                pipeline: [
                  ...defaultPipeline,
                  ...countPipeline,
                  {
                    $match: {
                      _id: {
                        $lte: this._vm.$moment().format('YYYY-MM-DD'),
                        $gte: this._vm.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
                      },
                    },
                  },
                ],
              }),
            },
          })
          .then(({ result }) => result),
      ]).then(([day, days]) => {
        commit('SET', {
          currentDay: day?.[0],
          within7Days: days,
        })
      })
    },
    async get({ state }, query) {
      return await this._vm.$api
        .get('Users/getUsers', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [
                ...defaultPipeline,
                {
                  $sort: {
                    _updatedDate: -1,
                  },
                },
              ],
            }),
            limit: 1,
          },
        })
        ?.result?.[0].catch((error) => Promise.reject(error))
    },
    async getAll({ commit, state }, { query, limit, page, custom }) {
      return await this._vm.$api
        .get(collection + '/getUsers', {
          params: {
            jsonQuery: JSON.stringify({
              // _id: { $in: Array.isArray(ids) ? ids : [ids] }
              ...query,
              pipeline: [...defaultPipeline],
              ...custom,
            }),
            page: page || 1,
            limit: limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          commit('SET', {
            data: result,
            ...rest,
          })
          return result
        })
        .catch(async (err) => Promise.reject(err))
    },
    async roles({ state: { _id } }, id) {
      return _id || id
        ? await this._vm.$api
            .get(collection + '/getRoles', {
              params: {
                _id: _id || id,
              },
            })
            .then(async ({ result }) => result)
            .catch(async (err) => Promise.reject(err))
        : null
    },
    // async register({ state }, payload) {
    //   if (payload) {
    //     return await this._vm.$api
    //       .post(collection + '/save', payload)
    //       .then(async ({ result }) => {
    //         return result
    //       })
    //       .catch((error) => Promise.reject(error))
    //   } else {
    //     throw new Error('Payload tidak terdefinisi')
    //   }
    // },
    async register({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .post(`${collection}/register`, {
            role: this._vm.$roles.Client,
            ...payload,
          })
          .then(async ({ result }) => {
            return result
          })
          .catch((error) => Promise.reject(error))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
    async update({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .patch('Users/update', payload)
          .then(async ({ result }) => {
            return result
          })
          .catch((error) => Promise.reject(error))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
    async remove({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .delete('Users/remove', {
            params: {
              _id: payload,
            },
          })
          .then(async ({ success }) => {
            return success
          })
          .catch((error) => Promise.reject(error || 'Ada kesalahan'))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
    async status({ dispatch, commit }) {
      commit('LOG', 'checking current status', { root: true })
      const token = this._vm.$storage.getItem('_credentials')
      const user = this._vm.$storage.getItem('_user')
      // console.log("here is token", token)
      if (token && user) {
        // console.log(user)
        commit('LOGIN', {
          ...user,
          roles: await dispatch('roles', user._id),
        })
        await dispatch('setLogin')
      }
    },
    async setDefault({ commit }) {
      this._vm.$storage.setItem('logged', false)
      this._vm.$storage.removeItem('logged')
      this._vm.$storage.removeItem('_user')
      this._vm.$storage.removeItem('_credentials')
      commit('RESET')
    },
    async setLogin({ dispatch, state, commit }) {
      try {
        commit('LOG', 'getting account', { root: true })
        await dispatch('client/get', process.env.VUE_APP_ID, {
          root: true,
        }).catch((err) => {
          if (this._vm.$storage.getItem('logged')) {
            dispatch('logout')
          }
          return err
        })
        // commit("LOGIN", {
        //   ...(this._vm.$decoder(
        //     this._vm.$storage.getItem("_credentials")?._at
        //   ) || {}),
        //   ...state,
        // })
        return this._vm.$storage.setItem('logged', true)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async login({ commit, dispatch }, { email, password }) {
      commit('LOG', 'logging in', { root: true })
      return await this._vm.$api
        .post('Users/login', { email, password, type: 'web-admin' })
        .then(async ({ result: { key, ...rest } }) => {
          if (!key) {
            return Promise.reject('User tidak ditemukan!')
          }
          if (!rest?.roles || (rest?.roles?.length <= 1 && rest?.roles.includes(this._vm.$roles.Worker))) {
            return Promise.reject('Akun anda tidak memiliki akses!')
          }
          this._vm.$storage.setItem('_credentials', {
            _at: key.accessToken,
            _rt: key.refreshToken,
          })
          this._vm.$storage.setItem('_user', this._vm.$decoder(key.accessToken))
          commit('LOGIN', this._vm.$decoder(key.accessToken))
          await dispatch('setLogin')
          // console.log(this._vm.$decoder(key.accessToken))
        })
        .catch((error) => {
          return Promise.reject(error || 'Ada kesalahan')
        })
    },
    async logout({ dispatch, commit }) {
      const token = this._vm.$storage.getItem('_credentials')
      if (token) {
        await this._vm.$api.post('Users/logout', {
          token: token._rt,
        })
      }
      await dispatch('setDefault')
      commit('LOG', 'logging out', { root: true })
      return router.push('/login')
    },
    async resetPassword({ dispatch, state }, { oldPassword, newPassword }) {
      const token = this._vm.$storage.getItem('_credentials')
      return await this._vm.$api
        .post('Users/resetPassword', {
          token: token._rt,
          email: state.email,
          password: newPassword,
          oldPassword,
        })
        .then(async (e) => {
          await dispatch('login', {
            email: state.email,
            password: newPassword,
          })
          return e.message
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => Promise.reject(message),
        )
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    LOGIN(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
        isLogged: true,
      })
    },
    RESET(state) {
      state = Object.create(state)
      state = Object.apply(state, initial())
    },
  },
}
