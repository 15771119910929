var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('closed')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v("Tambah Area")])],1),_c('v-col',{attrs:{"cols":"10"}},[(!_vm.$store.state.client.data || !_vm.client.enabled)?_c('atoms-text-field',{attrs:{"label":"Cari Client","rules":[]},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}}):_c('atoms-text-field',{attrs:{"label":"Pilih Client"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"items":_vm.$store.state.client.data.map((x) => x.name),"placeholder":"Pilih Client","menu-props":{ offsetY: true },"rules":[(v) => !!v || 'Harap disii!'],"dense":"","solo":"","flat":""},model:{value:(_vm.client.value),callback:function ($$v) {_vm.$set(_vm.client, "value", $$v)},expression:"client.value"}})],1),_c('atoms-button',{class:[!_vm.$store.state.client.data || !_vm.client.enabled ? 'primary' : 'red white--text', 'mb-4'],style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":() =>
                  !_vm.$store.state.client.data || !_vm.client.enabled
                    ? _vm.getClients()
                    : (function () {
                        _vm.client.value = ''
                        _vm.client.enabled = false
                        _vm.client.item = null
                      })()}},[_vm._v(_vm._s(!_vm.$store.state.client.data || !_vm.client.enabled ? 'Cari Client' : 'Reset Pencarian'))]),(_vm.client.item)?_c('div',{staticClass:"text-center my-4"},[_c('atoms-text',{staticClass:"my-1 font-weight-bold"},[_vm._v("Client Terpilih")]),_c('div',{staticClass:"pa-2 accent rounded-lg text-left"},[_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Nama:")]),_vm._v(" "+_vm._s(_vm.client.item.name))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Alamat:")]),_vm._v(" "+_vm._s(_vm.client.item.address.streetAddress1))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Area Terdaftar:")]),_vm._v(" "+_vm._s(_vm.client.item.areas && _vm.client.item.areas.map((x) => x.title)))])],1)],1):_vm._e(),_c('atoms-text-field',{attrs:{"disabled":!_vm.client.item,"label":"Nama Area","mask":"AAA","rules":[
                (v) => !!v || 'Harap diisi!',
                (v) => (!!v && v.length === 3) || 'Terdiri dari 3 huruf!',
                (v) => (!!v && _vm.duplicate !== v) || '(Nama sudah terdaftar) Gunakan nama lain!',
              ]},model:{value:(_vm.areaTitle),callback:function ($$v) {_vm.areaTitle=$$v},expression:"areaTitle"}}),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"disabled":!_vm.client.item,"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v("Tambah Area")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }