<template>
  <v-dialog ref="pickerDialog" v-model="remodal" :return-value.sync="revalue" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <atoms-text-field :label="label">
        <v-text-field
          class="rounded-lg"
          :placeholder="label"
          append-icon="mdi-calendar"
          dense
          readonly
          v-model="formatDate"
          v-bind="attrs"
          v-on="on"
          background-color="accent"
          flat
          solo
      /></atoms-text-field>
    </template>
    <v-date-picker color="primary" v-model="revalue" scrollable class="rounded-lg">
      <v-spacer />
      <v-btn text color="primary" @click="remodal = false"> Batalkan </v-btn>
      <v-btn text color="primary" @click="$refs.pickerDialog.save(revalue)"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
export default {
  props: ['modal', 'value', 'label'],
  data() {
    return {
      remodal: '',
      revalue: '',
    }
  },
  watch: {
    remodal: function () {
      this.emit(this.remodal)
    },
    revalue: function () {
      this.emit(this.revalue)
    },
  },
  mounted() {
    this.remodal = this.modal
    this.revalue = this.value
  },
  computed: {
    formatDate: {
      set(value) {
        this.remodal = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.revalue).format('DD MMMM YYYY')
      },
    },
  },
  methods: {
    emit(payload) {
      this.$emit('change', this.remodal, this.revalue)
    },
  },
}
</script>
