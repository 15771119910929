<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('close')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="validate">
          <v-row justify="center">
            <v-col cols="10" order="1">
              <atoms-title>{{ !id ? 'Tambah' : 'Perbarui' }} Testimoni</atoms-title>
            </v-col>
            <v-col cols="10" md="5" order="1" order-md="2">
              <v-hover>
                <div slot-scope="{ hover }">
                  <atoms-image
                    class="rounded-lg"
                    style="background: var(--v-accent-base)"
                    :contain="true"
                    :maxH="300"
                    :src="(picture && picture.url) || '/assets/no-preview.svg'"
                  >
                    <v-overlay
                      :value="!$vuetify.breakpoint.smAndDown && picture && picture.url ? (hover ? true : false) : true"
                      opacity="0.5"
                      absolute
                    >
                      <div
                        class="pa-12"
                        :style="{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                        }"
                      >
                        <atoms-text @click="openUploader('picture')" class="font-weight-medium"
                          ><v-icon small left> mdi-camera </v-icon>{{ !picture ? 'Upload' : 'Ganti' }} Foto</atoms-text
                        >

                        <atoms-text
                          @click="
                            picture &&
                              picture.url &&
                              $viewerApi({
                                options: {
                                  toolbar: false,
                                },
                                images: [picture.url],
                              })
                          "
                          class="font-weight-medium mt-4"
                          ><v-icon left small> mdi-eye </v-icon>Perbesar</atoms-text
                        >
                      </div>
                    </v-overlay></atoms-image
                  >
                  <input ref="picture" type="file" accept="image/*" class="d-none" @change="onPictureChange" />
                </div>
              </v-hover>

              <atoms-button @click="openUploader('picture')" class="primary mt-4" style="width: 100%"
                >Ganti Foto Cover</atoms-button
              >
            </v-col>
            <v-col cols="10" md="5" order="2" order-md="1" align="center">
              <v-row align="center" justify="center">
                <v-col cols="12" class="pb-0">
                  <atoms-text-field label="Judul Testimonial" v-model="title" :rules="rules.default" />
                </v-col>
                <v-col cols="12" class="pb-0">
                  <atoms-text-field label="Caption Atau URL Youtube" v-model="caption" :rules="rules.caption" />
                </v-col>
                <v-col cols="12">
                  <atoms-text-field label="Deskripsi">
                    <v-textarea
                      background-color="accent"
                      class="rounded-lg"
                      placeholder="Deskripsi Testimonial"
                      loader-height="100"
                      v-model="description"
                      hide-details
                      dense
                      solo
                      flat
                    ></v-textarea>
                  </atoms-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="10" order="6">
              <atoms-button
                type="submit"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="['primary', 'mb-4']"
                >Simpan</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      payload: null,
      readOnly: false,
      title: '',
      caption: '',
      description: '',
      picture: null,
      rules: {
        default: [(v) => !!v || 'Harap diisi'],
        caption: [
          (v) => !!v.match(/(www.youtube.com)|^(?!http)\w+/g) || 'Harap diisi dengan caption atau link url youtube',
        ],
        picture: [(v) => (v?.size ? v?.size < 5000000 || 'Harus kurang dari 5mb!' : true)],
      },
      valid: true,
    }
  },
  mounted() {
    this.payload = (this.id && this.$store.state.testimonial.data?.find((it) => it?._id === this.id)) || null
    if (this.payload) {
      this.title = this.payload?.title
      this.caption = this.payload?.caption
      this.description = this.payload?.description
      this.picture = {
        url: this.payload?.avatar,
      }
    } else if (this.id && !this.payload) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal memuat data!`,
      })
      this.$emit('close')
    }
  },
  methods: {
    openUploader(constant) {
      if (this.$refs[constant]) {
        this.$refs[constant]?.click()
      }
    },
    async onPictureChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 5000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 5mb!`,
        })
      } else if (file) {
        this.picture = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.picture = null
      }
    },
    async validate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$refs.form.validate()
        if (this.valid && (this.picture?.image || this.picture?.url)) {
          const _id = this.$uuid.v4()
          const payload = {
            ...((this.payload && this.payload) || {}),
            _id: this.id || _id,
            title: this.title,
            avatar: this.picture?.image
              ? await this.$uploadFile(this.picture.image, 'Testimonial', _id)
              : this.picture?.url
              ? this.picture.url
              : '',
            caption: this.caption,
            description: this.description, // show only 133 characters
          }
          if (!this.id) {
            await this.$store.dispatch('testimonial/create', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Testimonial berhasil ditambahkan!`,
            })
          } else {
            await this.$store.dispatch('testimonial/update', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Testimonial berhasil diperbarui!`,
            })
          }

          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$emit('close')
        } else {
          this.$showDialog({
            title: 'Gagal',
            body: `Pastikan foto dan kolom sudah terisi dengan benar`,
          })
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
