<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="close"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="myForm" v-model="myForm" @submit.prevent="/**/">
          <v-row justify="center">
            <v-col cols="10" class="text-md-left d-flex flex-column flex-md-row justify-space-between">
              <atoms-title>{{ pureEdit ? 'Edit' : isEdit ? 'Edit' : 'Detail' }} Saldo Pekerja</atoms-title>
              <atoms-button
                v-if="!pureEdit"
                @click="
                  () => {
                    isEdit = !isEdit
                    if (!isEdit) {
                      reset()
                    }
                    $showDialog({
                      title: 'Perhatian',
                      body: `Anda ${!isEdit ? 'mematikan' : 'menyalakan'} mode edit`,
                    })
                  }
                "
                class="primary mt-4 mt-md-0"
                >{{ isEdit ? 'Matikan Edit' : 'Aktifkan Edit' }}</atoms-button
              >
            </v-col>
            <v-col cols="10" align="start">
              <div class="d-flex flex-column flex-md-row align-center justify-space-between">
                <div class="d-flex pt-4 flex-column flex-md-row align-center">
                  <div>
                    <!-- PHOTO -->
                    <v-hover>
                      <v-avatar slot-scope="{ hover }" size="200" color="grey">
                        <atoms-image
                          v-if="photo && photo.url"
                          class="rounded-lg"
                          :src="(photo && photo.url) || '/media/person-example.webp'"
                        >
                          <v-overlay :value="$vuetify.breakpoint.smAndDown ? true : hover" opacity="0.5" absolute>
                            <div
                              class="pa-12"
                              :style="{
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                              }"
                            >
                              <atoms-text v-if="isEdit" @click="openUploader('uploaderSelf')" class="font-weight-medium"
                                ><v-icon small left> mdi-camera </v-icon>Ganti Foto</atoms-text
                              >

                              <atoms-text
                                @click="
                                  photo &&
                                    photo.url &&
                                    $viewerApi({
                                      options: {
                                        toolbar: false,
                                      },
                                      images: [photo.url],
                                    })
                                "
                                class="font-weight-medium mt-4"
                                ><v-icon left small> mdi-eye </v-icon>Perbesar</atoms-text
                              >
                            </div>
                          </v-overlay></atoms-image
                        >
                        <v-icon v-else x-large>mdi-account</v-icon>
                      </v-avatar>
                    </v-hover>
                    <input ref="uploaderSelf" type="file" accept="image/*" class="d-none" @change="onPhotoChange" />
                  </div>

                  <div
                    class="ml-md-8 d-flex py-4 align-center align-md-start flex-column justify-center text-center text-md-left"
                  >
                    <atoms-title :h2="true" class="font-weight-bold"
                      >{{ data.fullName }}
                      <v-chip v-if="data.captain" small class="primary lighten-5 primary--text rounded-lg"
                        >Kapten</v-chip
                      ></atoms-title
                    >
                    <atoms-text class="primary--text">{{ data.email }}</atoms-text>
                    <atoms-text>Terdaftar Pada {{ $moment(data._createdDate).format('YYYY-MM-DD') }}</atoms-text>
                  </div>
                </div>
              </div>
            </v-col>

            <!-- detail -->
            <v-col v-if="!pureEdit" cols="10" class="my-4">
              <v-row>
                <v-col cols="12" class="text-left">
                  <atoms-text class="mb-4 font-weight-bold">Domisili Pekerja</atoms-text>
                  <div class="pa-4 accent rounded-lg">
                    <atoms-text>Alamat : {{ data.address.streetAddress }}</atoms-text>
                    <atoms-text>Kecamatan : {{ data.address.kecamatan }}</atoms-text>
                    <atoms-text>Kota : {{ data.address.city }}</atoms-text>
                    <atoms-text>Provinsi : {{ data.address.province }}</atoms-text>
                  </div>
                </v-col>
                <v-col v-if="data.detail" cols="12" class="text-left">
                  <atoms-text class="mb-4 font-weight-bold">Detail Pekerja</atoms-text>
                  <div class="pa-4 accent rounded-lg">
                    <div>
                      <atoms-text>Keterampilan :</atoms-text>
                      <ul>
                        <li v-for="(x, i) in data.detail.skills" :key="i">
                          {{ x.name || ':' }} Selama {{ x.duration || ':' }}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <atoms-text>Pengalaman :</atoms-text>
                      <ul>
                        <li v-for="(x, i) in data.detail.experiences" :key="i">
                          {{ x.experience || ':' }} dari
                          <span class="primary--text">{{
                            $moment(x.dateStart && x.dateStart).format('DD-MM-YYYY')
                          }}</span>
                          Sampai
                          <span class="green--text">
                            {{ $moment(x.dateEnd && x.dateEnd).format('DD-MM-YYYY') }}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <atoms-text>Preferensi Pekerjaan :</atoms-text>
                      <ul>
                        <li v-for="(x, i) in data.detail.prefJobs" :key="i">
                          {{ x }}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <atoms-text>Preferensi Kota :</atoms-text>
                      <ul>
                        <li v-for="(x, i) in data.detail.prefCities" :key="i">
                          {{ x.city || ':' }}-{{ x.province || ':' }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <!-- approved feature -->
            <v-col cols="10" v-if="!newWorker">
              <v-row>
                <v-col cols="12" align="start">
                  <atoms-title>Saldo : {{ $idCurrency(balance || 0) }} </atoms-title>
                  <atoms-text class="mt-3 primary--text"
                    >Terakhir diperbarui
                    {{
                      `${$moment().format('DD/MM/YYYY, HH:mm:ss')} ${$moment().locale('en').format('A')}`
                    }}</atoms-text
                  >
                </v-col>
              </v-row>
              <v-row class="mt-5" align="center">
                <v-col cols="12"><atoms-text-field label="Catatan" v-model="form.reason" /></v-col>
                <v-col cols="12"
                  ><atoms-text-field
                    label="Jumlah Saldo"
                    v-model="form.balance"
                    prefix="Rp."
                    :rules="[(v) => !!v || 'Harap disii!']"
                /></v-col>

                <v-col cols="12">
                  <atoms-button @click="updateBalance(false)" style="width: 100%" class="black white--text"
                    >Sesuaikan Saldo</atoms-button
                  >
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" md="6">
                  <atoms-text-field
                    label="Saldo"
                    v-model="form.balance"
                    type="number"
                    :disabled="!isEdit"
                    :rules="rules.default"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <atoms-text-field
                    label="Note"
                    v-model="form.reason"
                    :disabled="!isEdit"
                    :rules="rules.default"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <atoms-button
                    :disabled="!isEdit"
                    @click="updateBalance"
                    class="primary"
                    >Sesuaikan Saldo</atoms-button
                  >
                </v-col>
              </v-row> -->
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['data', 'pureEdit', 'newWorker'],
  data() {
    return {
      isEdit: false,
      modal: null,
      photo: null,
      photoId: null,
      balance: 0,
      form: {
        balance: 0,
        reason: '',
      },
      duplicate: '',
      myForm: false,
      rules: {
        default: [(v) => !!v || 'Harap diisi!'],
      },
    }
  },
  watch: {},
  mounted() {
    if (this.pureEdit) {
      this.isEdit = true
    } else {
      this.isEdit = false
    }
    if (!this.data) {
      this.close()
    } else {
      this.reset()
      document.documentElement.style.overflowY = 'hidden'
    }
  },
  methods: {
    close() {
      document.documentElement.style.overflowY = 'auto'
      this.$emit('closed')
    },
    reset() {
      for (const [key, value] of Object.entries(this.form)) {
        this.form[key] = this.data?.[key] || this.form[key]
      }
      this.balance = this.form.balance
      this.photo = {
        url: this.data?.photo,
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (file) {
        this.photo = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.photo = null
      }
    },
    async onPhotoIdChange(payload) {
      payload = payload.target.files[0]
      if (payload && payload.size > 3000000) {
        this.$dialog.showDialog({
          title: 'Perhatian',
          content: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (payload) {
        this.photoId = {
          image: payload,
          url: URL.createObjectURL(payload),
        }
        URL.revokeObjectURL(payload) // free memory
      } else {
        this.photoId = null
      }
    },
    // async updateBalance() {
    //   this.$refs.myForm.validate()
    //   if (!this.myForm) {
    //     window.scroll({
    //       top: 0,
    //       behavior: "smooth",
    //     })
    //     return
    //   }
    //   this.$store.commit("SET", { loading: true })
    //   try {
    //     await this.$api.patch(`WorkerBalances/update`, {
    //       _id: this.data._id,
    //       balance: this.form.balance,
    //       reason: this.form.reason,
    //     })
    //     this.$refs.myForm.resetValidation()
    //     this.$refs.myForm.reset()
    //     this.$showDialog({
    //       title: "Berhasil",
    //       body: `Saldo Pekerja ${this.data?.fullName} berhasil diperbarui`,
    //     })
    //     this.close()
    //   } catch (err) {
    //     this.$showDialog({
    //       title: "Maaf, terdapat gangguan",
    //       body: err.message,
    //     })
    //   } finally {
    //     this.$store.commit("SET", { loading: false })
    //   }
    // },
    async updateBalance() {
      this.$store.commit('SET', { loading: true })
      let balance = Math.abs(this.$removeSeparator(this.form.balance))
      if (this.data._id && this.$isRoles('Super_Admin', 'Admin', 'Manager') && balance > 0) {
        // if (destruct) {
        //   balance = Number(this.currentBalance || 0) - balance
        //   if (balance < 0) {
        //     balance = 0
        //   }
        // } else {
        //   balance += Number(this.currentBalance || 0)
        // }

        if (
          window.confirm(
            'Apakah Anda yakin untuk memperbarui saldo? Saldo akhir dari ' +
              this.$idCurrency(this.balance) +
              ' akan menjadi ' +
              this.$idCurrency(this.form.balance),
          )
        ) {
          const { result, success, message } = await this.$api.patch('WorkerBalances/update', {
            _id: this.data._id,
            reason: this.form.reason,
            balance,
          })

          if (success) {
            this.$refs.myForm.resetValidation()
            this.$refs.myForm.reset()
            this.$showDialog({
              title: 'Berhasil',
              body: message,
            })
          }
        }
      } else {
        this.$showDialog({
          title: 'Perhatian',
          body: `Pastikan saldo tidak bernilai kosong`,
        })
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
