export default [
  {
    label: 'BCA',
    value: 'BCA',
  },
  {
    label: 'OVO',
    value: 'OVO',
  },
  {
    label: 'GoPay',
    value: 'GoPay',
  },
]
