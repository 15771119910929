var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: '90%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10","order":"1"}},[_c('atoms-title',[_vm._v(_vm._s(!_vm.id ? 'Tambah' : 'Perbarui')+" Promosi")])],1),_c('v-col',{attrs:{"cols":"10","md":"5","order":"1","order-md":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return _c('div',{},[_c('atoms-image',{staticClass:"rounded-lg",staticStyle:{"background":"var(--v-accent-base)"},attrs:{"contain":true,"maxH":300,"src":(_vm.picture && _vm.picture.url) || '/media/ads-example.webp'}},[_c('v-overlay',{attrs:{"value":!_vm.$vuetify.breakpoint.smAndDown && _vm.picture && _vm.picture.url ? (hover ? true : false) : true,"opacity":"0.5","absolute":""}},[_c('div',{staticClass:"pa-12",style:({
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                      })},[_c('atoms-text',{staticClass:"font-weight-medium",on:{"click":function($event){return _vm.openUploader('picture')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-camera ")]),_vm._v(_vm._s(!_vm.picture ? 'Upload' : 'Ganti')+" Foto")],1),_c('atoms-text',{staticClass:"font-weight-medium mt-4",on:{"click":function($event){_vm.picture &&
                            _vm.picture.url &&
                            _vm.$viewerApi({
                              options: {
                                toolbar: false,
                              },
                              images: [_vm.picture.url],
                            })}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-eye ")]),_vm._v("Perbesar")],1)],1)])],1),_c('input',{ref:"picture",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onPictureChange}})],1)}}])}),_c('atoms-button',{staticClass:"primary mt-4",staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.openUploader('picture')}}},[_vm._v("Ganti Foto Cover")])],1),_c('v-col',{attrs:{"cols":"10","md":"5","order":"2","order-md":"1","align":"center"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('atoms-text-field',{attrs:{"label":"Judul Promo","message":"Contoh Judul : Promo Potongan dan Angsuran","rules":_vm.rules.default},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{ref:"pickerStartDialog",attrs:{"return-value":_vm.start.value,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.start, "value", $event)},"update:return-value":function($event){return _vm.$set(_vm.start, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('atoms-text-field',{attrs:{"label":"Tanggal Mulai Promosi"}},[_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg",style:({
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }),attrs:{"placeholder":"Tanggal Mulai Promosi","append-icon":"mdi-calendar","background-color":"accent","hide-details":"","readonly":"","dense":"","solo":"","flat":""},model:{value:(_vm.formatStartDate),callback:function ($$v) {_vm.formatStartDate=$$v},expression:"formatStartDate"}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.start.modal),callback:function ($$v) {_vm.$set(_vm.start, "modal", $$v)},expression:"start.modal"}},[_c('v-date-picker',{staticClass:"rounded-lg",attrs:{"max":_vm.$moment().endOf('month').format('YYYY-MM-DD'),"scrollable":"","color":"primary"},on:{"click:date":function($event){return _vm.$refs.pickerStartDialog.save(_vm.start.value)}},model:{value:(_vm.start.value),callback:function ($$v) {_vm.$set(_vm.start, "value", $$v)},expression:"start.value"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{ref:"pickerEndDialog",attrs:{"return-value":_vm.end.value,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.end, "value", $event)},"update:return-value":function($event){return _vm.$set(_vm.end, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('atoms-text-field',{attrs:{"label":"Tanggal Selesai Promosi"}},[_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg",style:({
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }),attrs:{"placeholder":"Tanggal Selesai Promosi","append-icon":"mdi-calendar","background-color":"accent","hide-details":"","dense":"","readonly":"","solo":"","flat":""},model:{value:(_vm.formatEndDate),callback:function ($$v) {_vm.formatEndDate=$$v},expression:"formatEndDate"}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.end.modal),callback:function ($$v) {_vm.$set(_vm.end, "modal", $$v)},expression:"end.modal"}},[_c('v-date-picker',{staticClass:"rounded-lg",attrs:{"scrollable":"","color":"primary"},on:{"click:date":function($event){return _vm.$refs.pickerEndDialog.save(_vm.end.value)}},model:{value:(_vm.end.value),callback:function ($$v) {_vm.$set(_vm.end, "value", $$v)},expression:"end.value"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('atoms-text-field',{attrs:{"label":"Status"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"placeholder":"Pilih Status","items":Object.values(_vm.$postStatus),"menu-props":{ offsetY: true },"background-color":"accent","required":"","solo":"","flat":"","dense":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10","order":"3"}},[_c('atoms-text-field',{attrs:{"label":"Tambah Tags","appendIcon":"mdi-plus","appendAction":() => {
                  _vm.pushTag(_vm.tags.value)
                },"rules":[(v) => (!!_vm.tags.items && _vm.tags.items.length > 0) || 'Harap diisi!']},on:{"change":function($event){return _vm.$refs.form.validate()}},model:{value:(_vm.tags.value),callback:function ($$v) {_vm.$set(_vm.tags, "value", $$v)},expression:"tags.value"}}),_c('atoms-button',{class:['primary', 'mb-4'],style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":function($event){return _vm.pushTag(_vm.tags.value)}}},[_vm._v("Tambah")])],1),_c('v-col',{attrs:{"cols":"10","order":"4"}},[_c('v-data-table',{staticClass:"accent rounded-lg",attrs:{"headers":[
                {
                  text: 'Tag',
                  sortable: false,
                  class: 'primary--text text-uppercase text-center',
                },
                {
                  text: 'Aksi',
                  sortable: false,
                  value: 'action',
                  class: 'text-uppercase text-center',
                },
              ],"items":(_vm.tags && _vm.tags.items) || [],"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:`header`,fn:function({ props }){return [_c('thead',[_c('tr',{staticClass:"d-table-row"},_vm._l((props.headers),function(h){return _c('th',{key:h.value,class:h.class},[_c('span',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:`item`,fn:function({ item }){return [_c('tr',{staticClass:"d-table-row"},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"small":"","light":""},on:{"click":function($event){return _vm.unPushTag(item)}}},[_vm._v(" mdi-delete ")])],1)])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"10","align":"start","order":"5"}},[_c('quill-editor',{staticClass:"accent rounded-lg",attrs:{"content":_vm.content,"options":_vm.editorOpts},on:{"change":function($event){return _vm.onEditorChange($event)}}})],1),_c('v-col',{attrs:{"cols":"10","order":"6"}},[_c('atoms-button',{class:['primary', 'mb-4'],style:({ width: '100%' }),attrs:{"type":"submit","loading":_vm.$store.state.loading}},[_vm._v("Simpan")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }