export default [
  {
    label: 'Married',
    value: 'Married',
  },
  {
    label: 'Single',
    value: 'Single',
  },
]
