<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="close"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title :h2="true">Sesuaikan Area Lokasi Member</atoms-title>
            </v-col>
            <!-- user -->
            <v-col cols="10">
              <div>
                <v-fade-transition>
                  <v-container class="px-0">
                    <atoms-text-field
                      v-if="!$store.state.user.data || !user.enabled"
                      label="Cari User"
                      v-model="user.term"
                      :rules="[]"
                    />
                    <atoms-text-field v-else label="Pilih User">
                      <v-autocomplete
                        class="rounded-lg"
                        v-model="user.value"
                        :items="
                          ($store.state.user.data && $store.state.user.data.map((x) => x.nickname + ' → ' + x.email)) ||
                          []
                        "
                        placeholder="Pilih User"
                        :menu-props="{ offsetY: true }"
                        :rules="[(v) => !!v || 'Harap disii!']"
                        dense
                        solo
                        flat
                      />
                    </atoms-text-field>
                    <atoms-button
                      @click="
                        () => {
                          if (!$store.state.user.data || !user.enabled) {
                            getUsers()
                            user.term = ''
                            user.enabled = true
                          } else {
                            user.enabled = false
                          }
                        }
                      "
                      :loading="$store.state.loading"
                      :style="{ width: '100%' }"
                      :class="[!$store.state.user.data || !user.enabled ? 'primary' : 'red white--text', 'mb-4']"
                      >{{ !$store.state.user.data || !user.enabled ? 'Cari User' : 'Reset Pencarian' }}</atoms-button
                    >
                    <div v-if="user.item && user.enabled" class="text-center my-4">
                      <atoms-text class="my-1 font-weight-bold">User Terpilih</atoms-text>
                      <div class="pa-2 accent rounded-lg text-left">
                        <atoms-text
                          ><span class="font-weight-bold primary--text">Nama:</span>
                          {{ user.item.nickname }}</atoms-text
                        >
                        <atoms-text
                          ><span class="font-weight-bold primary--text">Email:</span> {{ user.item.email }}</atoms-text
                        >
                      </div>
                    </div>
                  </v-container>
                </v-fade-transition>
              </div>
            </v-col>
            <v-col class="mt-n7" v-if="user.item && user.enabled" cols="10">
              <div style="text-align: left">
                <!-- Pilih Klien -->
                <label v-if="showClient" class="d-block mb-4">
                  <p class="mb-1">Pilih Klien</p>
                  <vue-listbox-multiselect
                    v-model="selectedClients"
                    :search-function="searchClients"
                    placeholder="Pilih Klien"
                    style="width: 100%; text-align: left"
                    size="medium"
                    :hide-search="false"
                  />
                  <p v-if="selectedClients.length > 5" class="red--text">Tidak dapat menambahkan lebih dari lima. Harap gunakan menu tambah member atau edit member</p>
                </label>
                <!-- Pilih Area -->
                <label v-if="showArea" class="d-block mb-4">
                  <p class="mb-1">Pilih Area</p>
                  <vue-listbox-multiselect
                    v-model="selectedAreas"
                    :search-function="searchAreas"
                    placeholder="Pilih Area"
                    style="width: 100%; text-align: left"
                    size="medium"
                    :hide-search="false"
                  />
                </label>
                <!-- Pilih Lokasi -->
                <label v-if="showLocation" class="d-block mb-4">
                  <p class="mb-1">Pilih Lokasi</p>
                  <vue-listbox-multiselect
                    v-model="selectedLocations"
                    :search-function="searchLocations"
                    placeholder="Pilih Lokasi"
                    style="width: 100%; text-align: left"
                    size="medium"
                    :hide-search="false"
                  />
                </label>
                <!-- Pilih Role -->
                <!-- <label class="d-block mb-4">
                  <p class="mb-1">Pilih Roles Klien</p>
                  <v-select
                    v-model="selectedRoles"
                    :items="Object.values($clientRoles)"
                    :menu-props="{ offsetY: true }"
                    outlined
                    dense
                    multiple
                  />
                </label> -->
              </div>
            </v-col>
            <v-col v-if="user.item && user.enabled" cols="10">
              <atoms-button
                :loading="$store.state.loading"
                @click="registerMember"
                type="submit"
                :style="{ width: '100%', marginBottom: '5rem' }"
                class="primary"
                >Submit Member</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
import VueListboxMultiselect from '@banneredge/vue-listbox-multiselect'
export default {
  data() {
    return {
      all: 'Semua',
      nickname: '',
      email: '',
      password: '',
      photo: null,
      duplicate: '',
      valid: true,
      customEmailError: '',
      user: {
        enabled: true,
        value: '',
        item: null,
        term: '',
      },
      members: [],
      selectedClients: [],
      showClient: true,
      selectedAreas: [],
      showArea: true,
      selectedLocations: [],
      showLocation: true,
      selectedRoles: [],
    }
  },
  components: {
    VueListboxMultiselect,
  },
  watch: {
    async 'user.value'() {
      this.$store.commit('SET', { loading: true })
      if (this.user.value) {
        this.user.item = this.$store.state.user?.data?.find(
          (x) => x.email === this.user.value.split('→')[1].trim(), //→
        )
        const user = this.user.item
        const members = await this.$api
          .get('ClientMembers/get', {
            params: {
              jsonQuery: {
                userId: user?._id,
              },
            },
          })
          .then((res) => res?.result || [])
        this.members = members
        const clientIds = members.map((item) => item?.clientId || '')
        const areaIds = members.flatMap((item) => item?.areaIds || [])
        const locationIds = members.flatMap((item) => item?.locationIds || [])
        this.selectedClients = await this.$api
          .get('Clients/get', {
            params: {
              jsonQuery: JSON.stringify({
                _id: { $in: clientIds },
                pipeline: [
                  {
                    $project: {
                      _id: 0,
                      id: '$_id',
                      value: '$name',
                    },
                  },
                ],
              }),
            },
          })
          .then((res) => res?.result || [])
        this.selectedAreas = await this.$api
          .get('Areas/getAreas', {
            params: {
              jsonQuery: JSON.stringify({
                _id: { $in: areaIds },
                pipeline: [
                  {
                    $lookup: {
                      from: 'Clients',
                      localField: 'clientId',
                      foreignField: '_id',
                      as: 'client',
                    },
                  },
                  {
                    $unwind: {
                      path: '$client',
                      preserveNullAndEmptyArrays: true,
                    },
                  },
                  {
                    $project: {
                      _id: 0,
                      id: { $concat: ['$client._id', '|__|', '$_id'] },
                      value: '$title',
                      group: '$client.name',
                    },
                  },
                ],
              }),
            },
          })
          .then((res) => res?.result || [])
        this.selectedLocations = await this.$api
          .get('Locations/get', {
            params: {
              jsonQuery: JSON.stringify({
                _id: { $in: locationIds },
                pipeline: [
                  {
                    $lookup: {
                      from: 'Areas',
                      localField: 'areaId',
                      foreignField: '_id',
                      as: 'area',
                    },
                  },
                  {
                    $unwind: {
                      path: '$area',
                      preserveNullAndEmptyArrays: true,
                    },
                  },
                  {
                    $project: {
                      _id: 0,
                      id: { $concat: ['$area.clientId', '|__|', '$_id'] },
                      value: '$name',
                      group: '$area.title',
                    },
                  },
                ],
              }),
            },
          })
          .then((res) => res?.result || [])
        this.reRenderMultiSelect({})
      }
      this.$store.commit('SET', { loading: false })
    },
    selectedClients(selectedClients) {
      const clientIds = selectedClients?.map((item) => item.id) || []
      this.selectedAreas = this.selectedAreas.filter((item) => {
        return clientIds.includes(item.id.split('|__|')[0])
      })
      this.selectedLocations = this.selectedLocations.filter((item) => {
        return clientIds.includes(item.id.split('|__|')[0])
      })
      this.reRenderMultiSelect({ client: false })
    },
    selectedAreas(val) {
      this.reRenderMultiSelect({ area: false })
    },
    selectedLocations(val) {
      this.reRenderMultiSelect({ location: false })
    },
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden'
  },
  methods: {
    reRenderMultiSelect({ client = true, area = true, location = true }) {
      if (client) {
        // re-render area component
        this.showClient = false
        this.$nextTick(() => {
          this.showClient = true
        })
      }
      if (area) {
        // re-render area component
        this.showArea = false
        this.$nextTick(() => {
          this.showArea = true
        })
      }
      if (location) {
        // re-render location component
        this.showLocation = false
        this.$nextTick(() => {
          this.showLocation = true
        })
      }
    },
    async getUsers() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.user.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('user/getAll', {
          custom: {
            $or: [
              {
                nickname: term,
              },
              {
                email: term,
              },
              {
                roles: term,
              },
            ],
          },
          page: 1,
          limit: 0,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async searchClients(searchText) {
      const items = await this.$api
        .get('Clients/getClients', {
          params: {
            jsonQuery: JSON.stringify({
              _id: { $nin: this.selectedClients.map((item) => item.id) },
              name: { $regex: searchText || '', $options: 'i' },
              pipeline: [
                {
                  $sort: {
                    name: 1,
                  },
                },
                {
                  $limit: 50,
                },
                {
                  $project: {
                    _id: 0,
                    id: '$_id',
                    value: '$name',
                  },
                },
              ],
            }),
          },
        })
        .then((res) => res?.result || [])
      return items
    },
    async searchAreas(searchText) {
      const areaIds = this?.selectedAreas?.map((item) => item.id.split('|__|')[1] || '') || []
      const clientIds = this?.selectedClients?.map((item) => item?.id || '') || []
      const items = await this.$api
        .get('Areas/getAreas', {
          params: {
            jsonQuery: JSON.stringify({
              _id: { $nin: areaIds },
              title: { $regex: searchText || '', $options: 'i' },
              clientId: { $in: clientIds },
              pipeline: [
                {
                  $sort: {
                    title: 1,
                  },
                },
                {
                  $lookup: {
                    from: 'Clients',
                    localField: 'clientId',
                    foreignField: '_id',
                    as: 'client',
                  },
                },
                {
                  $unwind: {
                    path: '$client',
                    preserveNullAndEmptyArrays: true,
                  },
                },
                {
                  $project: {
                    _id: 0,
                    id: { $concat: ['$client._id', '|__|', '$_id'] },
                    title: 1,
                    inactive: 1,
                    group: '$client.name',
                  },
                },
              ],
            }),
          },
        })
        .then((res) => res?.result?.map(item => ({...item, value: item.title + ` ${item.inactive ? ' (Tidak Aktif)' : ''}`})) || [])
      return items
    },
    async searchLocations(searchText) {
      const locationIds = this?.selectedLocations?.map((item) => item.id.split('|__|')[1] || '') || []
      const areaIds = this?.selectedAreas?.map((item) => item.id.split('|__|')[1] || '') || []
      const items = await this.$api
        .get('Locations/get', {
          params: {
            jsonQuery: JSON.stringify({
              _id: { $nin: locationIds },
              name: { $regex: searchText || '', $options: 'i' },
              areaId: { $in: areaIds },
              pipeline: [
                {
                  $sort: {
                    name: 1,
                  },
                },
                {
                  $lookup: {
                    from: 'Areas',
                    localField: 'areaId',
                    foreignField: '_id',
                    as: 'area',
                  },
                },
                {
                  $unwind: {
                    path: '$area',
                    preserveNullAndEmptyArrays: true,
                  },
                },
                {
                  $project: {
                    _id: 0,
                    id: { $concat: ['$area.clientId', '|__|', '$_id'] },
                    name: 1,
                    inactive: 1,
                    group: '$area.title',
                  },
                },
              ],
            }),
          },
        })
        .then((res) => res?.result?.map(item => ({...item, value: item.name + ` ${item.inactive ? ' (Tidak Aktif)' : ''}`})) || [])
      return items
    },
    async registerMember() {
      if (this.selectedClients.length > 5) {
        return alert("Tidak dapat menambahkan lebih dari lima klien. Harap gunakan menu tambah member atau edit member")
      }
      this.$store.commit('SET', { loading: true })
      const userId = this?.user?.item?._id || ''
      const clientIds = this.selectedClients.map((item) => item.id)
      // const roles = this.selectedRoles;
      const items = clientIds.map((clientId) => {
        const memberId = this?.members?.find((member) => member.clientId === clientId)?._id
        const areaIds = this.selectedAreas
          .filter((item) => {
            return item.id.split('|__|')[0] === clientId
          })
          .map((item) => item.id.split('|__|')[1])
        const locationIds = this.selectedLocations
          .filter((item) => {
            return item.id.split('|__|')[0] === clientId
          })
          .map((item) => item.id.split('|__|')[1])
        return {
          _id: memberId,
          userId: userId,
          clientId: clientId,
          roles: Object.values(this.$clientRoles),
          areaIds: areaIds,
          locationIds: locationIds,
        }
      })
      try {
        await this.$api.post('ClientMembers/bulkSave', items)
        this.close()
        this.$showDialog({
          title: 'Berhasil',
          body: `Member berhasil ditambahkan`,
        })
      } catch (error) {
        this.$emitter.$emit('error', error)
      }
      this.$store.commit('SET', { loading: false })
    },
    close() {
      document.documentElement.style.overflow = 'auto'
      this.$refs.form.resetValidation()
      this.$refs.form.reset()

      this.$emit('closed')
    },
  },
}
</script>
