import router from '@/router/index'
/* eslint-disable */
const collection = 'Articles'
const defaultPipeline = [
  {
    $lookup: {
      from: 'Users',
      localField: 'authorId',
      foreignField: '_id',
      as: 'author',
    },
  },
  {
    $unwind: {
      path: '$author',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'updatedBy',
      foreignField: '_id',
      as: 'updatedByData',
    },
  },
  {
    $unwind: {
      path: '$updatedByData',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $project: {
      'author.password': 0,
      'updatedByData.password': 0,
    },
  },
]
const initial = () => ({
  data: null,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async getSummary({ commit, state, rootState }, payload) {
      console.log(this._vm.$areIncludes(rootState.user.roles, [this._vm.$roles.Worker, this._vm.$roles.Freelance]))
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              authorId: this._vm.$areIncludes(rootState.user.roles, [this._vm.$roles.Worker, this._vm.$roles.Freelance])
                ? rootState.user._id
                : undefined,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
          },
        })
        .then(({ result, ...rest }) => {
          commit('SET', {
            ...rest,
            pagesLength: state.pagesLength,
          })
          return { ...rest }
        })
        .catch((error) => Promise.reject(error))
    },
    async get({ rootState }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              authorId: this._vm.$areIncludes(rootState.user.roles, [this._vm.$roles.Worker, this._vm.$roles.Freelance])
                ? rootState.user._id
                : undefined,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            limit: 1,
          },
        })
        .then(({ result, ...rest }) => {
          return { ...result?.[0], ...rest }
        })
        .catch((error) => Promise.reject(error))
    },
    async getAll({ commit, state, rootState }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              authorId: this._vm.$areIncludes(rootState.user.roles, [this._vm.$roles.Worker, this._vm.$roles.Freelance])
                ? rootState.user._id
                : undefined,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            page: payload?.page || 1,
            limit: payload?.limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          if (!payload?.returnOnly)
            commit('SET', {
              data: result,
              ...rest,
            })
          return result
        })
        .catch(async (err) => Promise.reject(err))
    },
    async create({ state }, payload) {
      return await this._vm.$api
        .post(collection + '/create', payload)
        .catch((error) => Promise.reject(error || 'Ada kesalahan'))
    },
    async update({ state }, payload) {
      return await this._vm.$api
        .patch(collection + '/update', payload)
        .catch((error) => Promise.reject(error || 'Ada kesalahan'))
    },
    async remove({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .delete(collection + '/remove', {
            params: {
              _id: payload,
            },
          })
          .then(async ({ success }) => success)
          .catch((error) => Promise.reject(error || 'Ada kesalahan'))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
