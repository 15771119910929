<!-- <template>
  <div>
    <v-label v-if="!noLabel"
      ><atoms-text class="pb-1 px-2 font-weight-bold text-left" v-html="label"
    /></v-label>
    <slot v-bind="$props" v-on="$listeners" v-if="!!$slots.default" />
    <v-text-field
      v-else
      :readonly="readonly"
      :prefix="prefix"
      :loading="loading"
      :disabled="disabled || $store.state.loading"
      v-mask="mask"
      :background-color="color || 'accent'"
      class="rounded-lg my-0"
      :placeholder="message || label"
      v-model.trim="inputVal"
      :type="
        (type === constant && (sneak ? 'text' : constant)) || type || 'text'
      "
      :append-icon="appendIcon"
      :prepend-icon="prependIcon"
      @click:append="() => appendAction() || null"
      @click:prepend="() => prependAction() || null"
      :name="name"
      :rules="rules"
      :hide-details="!rules"
      :style="{
        'box-shadow':
          !rules || border ? 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px' : 'none',
      }"
      required
      dense
      solo
      flat
    >
      <v-icon
        v-if="type === constant"
        @click="sneak = !sneak"
        slot="append"
        class="lock-button"
      >
        {{ sneak ? "mdi-eye" : "mdi-eye-off" }}
      </v-icon></v-text-field
    >
  </div>
</template>
<script>
export default {
  props: [
    "mask",
    "prefix",
    "label",
    "rules",
    "color",
    "noLabel",
    "type",
    "value",
    "readonly",
    "appendAction",
    "appendIcon",
    "prependAction",
    "prependIcon",
    "border",
    "disabled",
    "message",
    "name",
    "loading",
  ],
  data() {
    return {
      sneak: false,
      constant: "password",
    }
  },
  watch: {
    value: function () {
      this.inputVal = this.value
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
}
</script> -->
<template>
  <div>
    <div class="d-flex justify-space-between">
      <v-label v-if="!noLabel"><atoms-text class="pb-1 px-2 font-weight-bold text-left" v-html="label" /></v-label>
      <atoms-text v-if="textTambahan"
        ><a :class="['px-2 text-decoration-none', classTambahan]" :href="linkTambahan">{{
          textTambahan
        }}</a></atoms-text
      >
    </div>
    <slot v-bind="$props" v-on="$listeners" v-if="!!$slots.default" />
    <v-text-field
      v-else
      :readonly="readonly"
      :prefix="prefix"
      :disabled="disabled || $store.state.loading"
      :background-color="color || 'accent'"
      class="rounded-lg my-0"
      :placeholder="placeholder || label"
      v-mask="mask"
      v-model.trim="inputVal"
      :value="value"
      :rules="rules"
      :type="type === constant ? (sneak ? 'text' : constant) : type || 'text'"
      :append-icon="appendIcon"
      :prepend-inner-icon="prependIcon"
      @click:prepend-inner="() => (typeof prependAction == 'function' && prependAction()) || null"
      @click:append="() => (typeof appendAction == 'function' && appendAction()) || null"
      @keydown.enter=";(typeof enter == 'function' && enter()) || null"
      :hide-details="!rules"
      :style="{
        'box-shadow': !rules || border ? 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px' : 'none',
      }"
      required
      dense
      solo
      flat
    >
      <v-slot />
      <v-icon v-if="type === constant" @click="sneak = !sneak" slot="append" class="lock-button">
        {{ sneak ? 'mdi-eye' : 'mdi-eye-off' }}
      </v-icon></v-text-field
    >
  </div>
</template>
<script>
export default {
  props: {
    noLabel: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    mask: String,
    prefix: String,
    label: String,
    placeholder: String,
    rules: Array,
    color: String,
    type: String,
    value: String | Number,
    appendAction: Function,
    prependAction: Function,
    enter: Function,
    appendIcon: String,
    prependIcon: String,
    classTambahan: String,
    textTambahan: String,
    linkTambahan: String,
    border: Boolean,
  },
  data() {
    return {
      sneak: false,
      constant: 'password',
    }
  },
  watch: {
    value: function () {
      this.inputVal = this.value
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
<style>
.v-input__prepend-outer {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
