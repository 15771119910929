<template>
  <molecules-floating>
    <molecules-custom-dialog
      v-if="showAreaRemove"
      :show="showAreaRemove"
      title="Apakah Anda Yakin?"
      caption="Kapten akan kehilangan akses khusus ke area tersebut"
      :items="[
        {
          title: 'Batalkan',
          override: 'transparent primary--text',
          full: false,
          exec: () => (showAreaRemove = null),
        },
        {
          title: 'Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            removeArea(showAreaRemove)
            showAreaRemove = null
          },
        },
      ]"
    />
    <molecules-custom-dialog
      v-if="showLocationRemove"
      :show="showLocationRemove"
      title="Apakah Anda Yakin?"
      caption="Kapten akan kehilangan akses khusus ke lokasi tersebut"
      :items="[
        {
          title: 'Batalkan',
          override: 'transparent primary--text',
          full: false,
          exec: () => (showLocationRemove = null),
        },
        {
          title: 'Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            removeLocation(showLocationRemove)
            showLocationRemove = null
          },
        },
      ]"
    />
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container v-if="data">
        <v-form ref="form" v-model="valid" @submit.prevent="">
          <v-row justify="center" dense>
            <v-col cols="11">
              <atoms-title>Detail Kapten {{ data.fullName.split(' ')[0] }}</atoms-title>
            </v-col>
            <v-col cols="11">
              <v-row class="pt-8" justify="center">
                <v-col cols="10" md="4">
                  <v-row>
                    <v-col cols="12">
                      <!-- PHOTO -->
                      <v-hover>
                        <v-avatar slot-scope="{ hover }" size="200" color="grey">
                          <atoms-image v-if="data.photo" class="rounded-lg" :src="data.photo">
                            <v-overlay :value="$vuetify.breakpoint.smAndDown ? true : hover" opacity="0.5" absolute>
                              <div
                                class="pa-12"
                                :style="{
                                  width: '100%',
                                  height: '100%',
                                  cursor: 'pointer',
                                }"
                              >
                                <atoms-text
                                  @click="
                                    data.photo &&
                                      $viewerApi({
                                        options: {
                                          toolbar: false,
                                        },
                                        images: [data.photo],
                                      })
                                  "
                                  class="font-weight-medium mt-4"
                                  ><v-icon left small> mdi-eye </v-icon>Perbesar</atoms-text
                                >
                              </div>
                            </v-overlay></atoms-image
                          >
                          <v-icon v-else x-large>mdi-account</v-icon>
                        </v-avatar>
                      </v-hover>
                    </v-col>
                    <v-col cols="12">
                      <atoms-title>{{
                        data.fullName ? $toCapitalize(data.fullName) : 'Tidak dicantumkan'
                      }}</atoms-title>
                      <atoms-text v-if="data.detail && data.detail.prefJobs" :span="true" class="px-4">{{
                        data.detail.prefJobs.filter((x) => x.length > 0).toString()
                      }}</atoms-text>
                      <br />
                      <v-chip
                        v-if="
                          data.blacklist ||
                          data.resigned ||
                          (data.clientSuspends && data.clientSuspends.find((x) => x._id === $store.state.client._id))
                        "
                        class="rounded-lg red lighten-5 red--text ml-2 mt-2"
                        >Status :
                        {{
                          (data.blacklist && 'Blacklisted') ||
                          (data.resigned && 'Resign') ||
                          (data.clientSuspends &&
                            data.clientSuspends.find((x) => x._id === $store.state.client._id) &&
                            'Suspend')
                        }}</v-chip
                      >
                    </v-col>
                    <v-col cols="12" class="accent rounded-lg my-2">
                      <atoms-text class="font-weight-bold text-left primary--text">Alamat</atoms-text>
                      <atoms-text class="text-left"
                        ><span class="font-weight-bold">Provinsi: </span
                        >{{
                          data.address && data.address.province ? data.address.province : 'Tidak dicantumkan'
                        }}</atoms-text
                      >
                      <atoms-text class="text-left"
                        ><span class="font-weight-bold">Kota: </span
                        >{{ data.address && data.address.city ? data.address.city : 'Tidak dicantumkan' }}</atoms-text
                      >
                    </v-col>
                    <v-col cols="12" class="accent rounded-lg my-2">
                      <atoms-text class="font-weight-bold text-left primary--text">Tentang Diri</atoms-text>
                      <atoms-text class="text-left"
                        ><span class="font-weight-bold">Deskripsi: </span
                        >{{ data.about ? data.about : 'Tidak dicantumkan' }}</atoms-text
                      >
                      <atoms-text class="text-left" v-if="data.detail && data.detail.skills"
                        ><span class="font-weight-bold">Kemampuan: </span>
                        <ul>
                          <li v-for="(x, i) in data.detail.skills" :key="i">
                            {{ x.name || '-' }} {{ x.duration || '-' }}
                          </li>
                        </ul>
                      </atoms-text>
                      <atoms-text class="text-left" v-if="data.detail && data.detail.experiences"
                        ><span class="font-weight-bold">Pengalaman: </span>
                        <ul>
                          <li v-for="(x, i) in data.detail.experiences" :key="i">
                            {{ x.experience || 'Tidak dicantumkan' }} (<span class="primary--text">{{
                              $moment(x.dateStart).format('DD-MM-YYYY')
                            }}</span>
                            Sampai
                            {{ $moment(x.dateEnd).format('DD-MM-YYYY') }})
                          </li>
                        </ul>
                      </atoms-text>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10" md="8" class="px-0">
                  <v-row dense>
                    <v-col cols="12" class="py-0">
                      <v-container :class="['py-0', $vuetify.breakpoint.smAndDown && 'px-0']">
                        <v-tabs
                          background-color="accent"
                          color="primary"
                          height="40"
                          v-model="tabs"
                          hide-slider
                          :show-arrows="$vuetify.breakpoint.smAndDown ? true : false"
                          :center-active="$vuetify.breakpoint.smAndDown ? true : false"
                          class="rounded-lg d-flex justify-center justify-md-end"
                        >
                          <v-tab
                            v-for="i in ['area', 'lokasi']"
                            :key="i"
                            class="text-capitalize"
                            active-class="primary lighten-5 primary--text rounded-lg ma-1"
                          >
                            <atoms-text class="font-weight-medium">{{ $toCapitalize(i) }}</atoms-text>
                          </v-tab>
                        </v-tabs></v-container
                      >
                    </v-col>
                    <v-col cols="12">
                      <v-fade-transition>
                        <v-container v-if="tabs === 0" :class="$vuetify.breakpoint.smAndDown && 'px-0'">
                          <div v-if="data.captainAreas && data.captainAreas.length > 0">
                            <div
                              v-for="({ ...rest }, i) in data.captainAreas.slice(
                                Math.abs((page - 1) * limit),
                                page * limit,
                              ) || []"
                              :key="i"
                              :class="['accent', 'rounded-lg pa-4 mb-2']"
                              :style="{
                                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                              }"
                            >
                              <v-row align="center">
                                <v-col class="shrink">
                                  <v-avatar color="grey lighten-4 rounded-lg">
                                    <atoms-text>{{ rest.areaName.split('')[0] }}</atoms-text>
                                  </v-avatar>
                                </v-col>
                                <v-col align="start">
                                  <atoms-text :auto="true" class="font-weight-bold"
                                    >Area {{ rest.areaName.toUpperCase() }}</atoms-text
                                  >
                                </v-col>
                                <v-col class="shrink">
                                  <v-menu offset-x class="rounded-xl">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn depressed icon v-bind="attrs" v-on="on"
                                        ><v-icon>mdi-dots-vertical</v-icon></v-btn
                                      >
                                    </template>
                                    <v-list nav dense elevation="0" class="text-center rounded-lg">
                                      <v-list-item
                                        link
                                        @click="showAreaRemove = rest.areaId"
                                        class="rounded-lg px-4 text-center"
                                      >
                                        <v-icon left>mdi-delete</v-icon>
                                        <v-list-item-title class="px-2">Hapus</v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-col>
                              </v-row>
                              <v-container
                                v-if="rest.locations && rest.locations.length > 0"
                                class="px-0 pb-0 text-start"
                              >
                                <v-chip
                                  small
                                  class="rounded-lg mr-1 my-1"
                                  v-for="(x, i) in rest.locations || []"
                                  :key="i"
                                  style="cursor: pointer"
                                >
                                  {{ x.name || '' }} {{ x.inactive ? '(Tidak Aktif)' : '' }}
                                </v-chip>
                              </v-container>
                            </div>
                            <v-row justify="center">
                              <v-col cols="8">
                                <v-container class="max-width">
                                  <v-pagination
                                    class="my-4"
                                    v-model="page"
                                    :length="Math.ceil(data.captainAreas.length / limit)"
                                  ></v-pagination>
                                </v-container>
                              </v-col>
                            </v-row>
                          </div>
                          <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
                            <div class="text-center">
                              <atoms-image :width="250" src="/assets/missing.svg" />
                              <atoms-title :h3="true" class="mt-6">Tidak ada lokasi yang ditemukan!</atoms-title>
                            </div>
                          </molecules-wrapper>
                        </v-container>
                      </v-fade-transition>
                      <v-fade-transition>
                        <v-container v-if="tabs === 1" :class="$vuetify.breakpoint.smAndDown && 'px-0'">
                          <div v-if="locations && locations.length > 0">
                            <div
                              v-for="({ ...rest }, i) in locations.slice(Math.abs((page - 1) * limit), page * limit) ||
                              []"
                              :key="i"
                              :class="['accent', 'rounded-lg pa-4 mb-2']"
                              :style="{
                                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                              }"
                            >
                              <v-row align="center">
                                <v-col class="shrink">
                                  <v-avatar color="grey lighten-4 rounded-lg">
                                    <atoms-text>{{ rest.name.split('')[0] }}</atoms-text>
                                  </v-avatar>
                                </v-col>
                                <v-col align="start">
                                  <atoms-text :auto="true" class="font-weight-bold"
                                    >Lokasi {{ rest.name }} {{ rest.inactive ? "(Tidak Aktif)" : "" }}</atoms-text
                                  >
                                  <atoms-text :auto="true">{{ rest.address }}</atoms-text>
                                </v-col>
                                <v-col align="start">
                                  <v-chip small class="rounded-lg primary">Area {{ rest.areaName }}</v-chip>
                                </v-col>
                                <v-col class="shrink">
                                  <v-menu offset-x class="rounded-xl">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn depressed icon v-bind="attrs" v-on="on"
                                        ><v-icon>mdi-dots-vertical</v-icon></v-btn
                                      >
                                    </template>
                                    <v-list nav dense elevation="0" class="text-center rounded-lg">
                                      <v-list-item
                                        link
                                        @click="showLocationRemove = rest._id"
                                        class="rounded-lg px-4 text-center"
                                      >
                                        <v-icon left>mdi-account-delete</v-icon>
                                        <v-list-item-title class="px-2">Hapus</v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-col>
                              </v-row>
                            </div>
                            <v-row justify="center">
                              <v-col cols="8">
                                <v-container class="max-width">
                                  <v-pagination
                                    class="my-4"
                                    v-model="page"
                                    :length="Math.ceil(locations.length / limit)"
                                  ></v-pagination>
                                </v-container>
                              </v-col>
                            </v-row>
                          </div>
                          <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
                            <div class="text-center">
                              <atoms-image :width="250" src="/assets/missing.svg" />
                              <atoms-title :h3="true" class="mt-6">Tidak ada lokasi yang ditemukan!</atoms-title>
                            </div>
                          </molecules-wrapper>
                        </v-container>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      page: 1,
      limit: 5,
      locations: null,
      showAreaRemove: null,
      showLocationRemove: null,
      tabs: null,
      data: null,
      valid: true,
    }
  },
  watch: {
    tabs: {
      handler() {
        this.page = 1
      },
      deep: true,
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.$store.commit('SET', { loading: true })
      const finder =
        this.id &&
        (await this.$store.dispatch('worker/get', {
          query: { _id: this.id },
        }))
      if (this.id && finder) {
        this.data = finder
        if (this.data?.captainAreas) {
          const array = this.data?.captainAreas
            ?.filter((x) => x.locations)
            .map((x) =>
              x.locations.map((y) => ({
                ...y,
                areaId: x.areaId,
                areaName: x.areaName,
              })),
            )
          let result = []
          for (let i = 0; i < array.length; i++) {
            for (let x = 0; x < array[i].length; x++) {
              result.push(array[i][x])
            }
          }
          this.locations = result || null
        }
      }
      if (!this.id || !finder | !this.data) {
        this.$showDialog({
          title: 'Kesalahan',
          body: 'Gagal memuat data kapten',
        })
        this.$emit('closed')
      }
      this.$store.commit('SET', { loading: false })
    },
    async preValidate() {
      this.$store.commit('SET', { loading: true })
      try {
        if (this.email && this.email !== this.data?.email) {
          const term = { $regex: this.email || '', $options: 'i' }
          this.email = this.email.trim()
          this.email = this.email.split('@')[0].replaceAll('.', '') + '@' + this.email.split('@')[1]
          const { result: worker } = await this.$api.get(`Workers/count`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          const { result: user } = await this.$api.get(`Users/isExist`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          if (worker > 0 || user) {
            this.duplicate = this.email
          } else {
            this.duplicate = ''
          }
        }
        await this.validate()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()
      try {
        if (this.valid) {
          await this.$store.dispatch('worker/update', {
            ...this.data,
            ...Object.assign(
              {},
              ...Object.entries(this.form).map(([key, value]) => ({
                [key]: value || this.data?.[key],
              })),
            ),
            captain: this.form.captain || false,
            blacklist: this.form.blacklist || false,
            resigned: this.form.resigned || false,
            isReadyToPayBPJS: this.form.isReadyToPayBPJS || false,
            adminPayVerified: this.form.adminPayVerified || false,
            updatedBy: this.$store.state.user?.email,
            salary: Number(this.$removeSeparator(this.form.salary)) || this.data?.salary,
            sex: this.form.sex === this.$gender.Male ? 'Male' : 'Female' || this.data?.sex,
            photo:
              this.photo && this.photo.image
                ? await this.$uploadFile(
                    this.photo.image,
                    `Worker/${this.idNumber || this.data?.idNumber}/Profil`,
                    `photo`,
                  )
                : this.data?.photo,
            photoId:
              this.photoId && this.photoId.image
                ? await this.$uploadFile(
                    this.photoId.image,
                    `Worker/${this.idNumber || this.data?.idNumber}/Profil`,
                    `photoId`,
                  )
                : this.data?.photoId,
          })
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$showDialog({
            title: 'Berhasil',
            body: `Pekerja ${this.data?.fullName} baru berhasil diperbarui`,
          })
          this.$emit('closed')
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian`,
          })
        }
      } catch (error) {
        Promise.reject(error)
      }
    },
    async removeArea(areaId) {
      this.$store.commit('SET', { loading: true })
      let data = {
        ...this.data,
        captainAreas: this.data?.captainAreas?.filter((x) => x.areaId !== areaId),
      }
      try {
        await this.$store.dispatch('worker/update', data)
        this.$showDialog({
          title: 'Berhasil',
          body: `${this.data?.captainAreas?.find((x) => x.areaID === areaId)?.areaName || 'Area'} Berhasil dihapus!`,
        })
        await this.init()
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: `Terdapat kesalahan : ${error}`,
        })
      }
      this.$store.commit('SET', { loading: false })
    },

    async removeLocation(locationId) {
      this.$store.commit('SET', { loading: true })

      let targetArea = this.data?.captainAreas?.find((x) => x.locations?.find((it) => it._id == locationId))
      if (!targetArea || !targetArea.locations) {
        return
      }
      targetArea = {
        ...targetArea,
        locations: targetArea.locations.filter((it) => it._id !== locationId),
      }
      let data = {
        ...this.data,
        captainAreas: [...(this.data?.captainAreas?.filter((x) => x.areaId !== targetArea.areaId) || []), targetArea],
      }
      try {
        await this.$store.dispatch('worker/update', data)
        this.$showDialog({
          title: 'Berhasil',
          body: `Lokasi berhasil dihapus!`,
        })
        await this.init()
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: `Terdapat kesalahan : ${error}`,
        })
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
