<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="myForm" v-model="myForm" @submit.prevent="/**/">
          <v-row justify="center">
            <v-col cols="10" class="text-md-left d-flex flex-column flex-md-row justify-space-between">
              <atoms-title>Riwayat Saldo Pekerja</atoms-title>
            </v-col>
            <v-col cols="10" align="start">
              <div class="d-flex flex-column flex-md-row align-center justify-space-between">
                <div class="d-flex pt-4 flex-column flex-md-row align-center">
                  <div>
                    <!-- PHOTO -->
                    <v-hover>
                      <v-avatar slot-scope="{ hover }" size="200" color="grey">
                        <atoms-image
                          v-if="photo && photo.url"
                          class="rounded-lg"
                          :src="(photo && photo.url) || '/media/person-example.webp'"
                        >
                          <v-overlay :value="$vuetify.breakpoint.smAndDown ? true : hover" opacity="0.5" absolute>
                            <div
                              class="pa-12"
                              :style="{
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                              }"
                            >
                              <atoms-text v-if="isEdit" @click="openUploader('uploaderSelf')" class="font-weight-medium"
                                ><v-icon small left> mdi-camera </v-icon>Ganti Foto</atoms-text
                              >

                              <atoms-text
                                @click="
                                  photo &&
                                    photo.url &&
                                    $viewerApi({
                                      options: {
                                        toolbar: false,
                                      },
                                      images: [photo.url],
                                    })
                                "
                                class="font-weight-medium mt-4"
                                ><v-icon left small> mdi-eye </v-icon>Perbesar</atoms-text
                              >
                            </div>
                          </v-overlay></atoms-image
                        >
                        <v-icon v-else x-large>mdi-account</v-icon>
                      </v-avatar>
                    </v-hover>
                    <input ref="uploaderSelf" type="file" accept="image/*" class="d-none" @change="onPhotoChange" />
                  </div>

                  <div
                    class="ml-md-8 d-flex py-4 align-center align-md-start flex-column justify-center text-center text-md-left"
                  >
                    <atoms-title :h2="true" class="font-weight-bold"
                      >{{ data.fullName }}
                      <v-chip v-if="data.captain" small class="primary lighten-5 primary--text rounded-lg"
                        >Kapten</v-chip
                      ></atoms-title
                    >
                    <atoms-text class="primary--text">Balance : {{ $idCurrency(data.balance) }}</atoms-text>
                    <atoms-text>{{ data.email }}</atoms-text>
                    <atoms-text>Terdaftar Pada {{ $moment(data._createdDate).format('YYYY-MM-DD') }}</atoms-text>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="pl-md-8 py-6">
          <v-col cols="12" md="auto">
            <atoms-button
              class="primary"
              style="width: 100%"
              @click="
                () => {
                  sort = sort === constant[0] ? constant[1] : constant[0]
                  getHistory()
                }
              "
              ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
              >Sort Tanggal : {{ sort === constant[0] ? 'Terbaru' : 'Terlama' }}
            </atoms-button>
            <atoms-button class="teal white--text mt-3" style="width: 100%" @click="exportXlsx">
              <v-icon left>mdi-google-spreadsheet</v-icon>
              Ekspor XLSX
            </atoms-button>
          </v-col>
        </v-row>
        <div class="pl-md-8 py-6">
          <div v-if="mainLoading">
            <v-skeleton-loader
              v-for="i in 5"
              :key="i"
              type="list-item"
              class="accent rounded-lg mb-2 pa-2"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </div>
          <div v-else-if="histories && histories[0]">
            <div
              v-for="(item, i) in histories || []"
              :key="i"
              :class="['accent', 'rounded-lg pa-4 mb-2', 'text-left']"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            >
              <v-row align="start" justify="start">
                <v-col cols="12" md="6">
                  <atoms-text :auto="true" class="font-weight-bold">
                    {{ changeDescription(item.desc) }}
                  </atoms-text>
                  <atoms-text class="font-weight-bold">
                    Tanggal :
                    {{ $moment(item._createdDate).format('dddd, DD MMMM YYYY') }}
                  </atoms-text>
                  <atoms-text v-if="item.updatedByName"
                    ><v-icon left small>mdi-account-edit-outline</v-icon> {{ item.updatedByName }}</atoms-text
                  >
                  <atoms-text> TransactionId: {{ item._id }} </atoms-text>
                </v-col>
                <v-col cols="12" md="6">
                  <atoms-text>
                    <span v-if="item.amount >= 0" class="primary--text text-h5">
                      <v-icon color="primary">mdi-plus</v-icon>
                      {{ $addSeparator(Math.abs(item.amount)) }}
                    </span>
                    <span v-else class="red--text text-h5">
                      <v-icon color="red">mdi-minus</v-icon>
                      {{ $addSeparator(Math.abs(item.amount)) }}
                    </span>
                  </atoms-text>
                </v-col>
              </v-row>
            </div>
            <v-row justify="center">
              <v-col cols="8">
                <v-container class="max-width">
                  <v-pagination class="my-4" v-model="page" :length="this.pagesLength"></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </div>
          <div v-else>Belum ada</div>
        </div>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['data', 'newWorker'],
  data() {
    return {
      isEdit: false,
      modal: null,
      photo: null,
      photoId: null,
      constant: ['Desc', 'Asc'],
      sort: 'Desc',
      histories: [],
      form: {
        balance: 0,
        reason: '',
      },
      duplicate: '',
      myForm: false,
      rules: {
        default: [(v) => !!v || 'Harap diisi!'],
      },
      page: 1,
      limit: 6,
      pagesLength: 0,
      mainLoading: false,
    }
  },
  // computed: {
  //   formatDate: {},
  // },
  watch: {
    page() {
      this.getHistory()
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
  mounted() {
    this.getHistory()
    if (!this.data) {
      this.$emit('closed')
    } else {
      this.reset()
    }
  },
  methods: {
    reset() {
      for (const [key, value] of Object.entries(this.form)) {
        this.form[key] = this.data?.[key] || this.form[key]
      }
      this.photo = {
        url: this.data?.photo,
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (file) {
        this.photo = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.photo = null
      }
    },
    async onPhotoIdChange(payload) {
      payload = payload.target.files[0]
      if (payload && payload.size > 3000000) {
        this.$dialog.showDialog({
          title: 'Perhatian',
          content: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (payload) {
        this.photoId = {
          image: payload,
          url: URL.createObjectURL(payload),
        }
        URL.revokeObjectURL(payload) // free memory
      } else {
        this.photoId = null
      }
    },
    async getHistory() {
      this.mainLoading = true
      try {
        this.histories = []
        const filterMongo = {
          workerId: this.data?._id,
          // workerId: "c6e0148e-c25b-48a8-ab3a-8db84248dd1e",
          pipeline: [
            {
              $match: {
                _createdDate: {
                  $gte: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000), // filter data yang diambil hanya sampai 90 hari yang lalu
                },
              },
            },
            {
              $sort: {
                _createdDate: this.sort === this.constant[0] ? -1 : 1,
              },
            },
            {
              $lookup: {
                from: 'Users',
                localField: 'updatedBy',
                foreignField: '_id',
                as: 'updatedByData',
              },
            },
            {
              $unwind: {
                path: '$updatedByData',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $project: {
                _id: 1,
                amount: 1,
                desc: 1,
                updatedByName: '$updatedByData.nickname',
                email: '$updatedByData.email',
                _createdDate: 1,
                _updatedDate: 1,
              },
            },
          ],
        }

        const data = await this.$api.get(`/WorkerBalances/getHistory`, {
          params: {
            jsonQuery: JSON.stringify(filterMongo),
            page: this.page,
            limit: this.limit,
          },
        })
        this.pagesLength = data?.pagesLength || 0
        this.histories = data?.result || []
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      this.mainLoading = false
    },
    changeDescription(desc) {
      if (desc.includes('Gaji dari absensi')) return desc.replace('Gaji dari absensi', 'Bekerja')
      else return desc.replace('Gaji dari absensi', 'Bekerja')
    },
    async exportXlsx() {
      const isConfirmed = confirm('Sudah yakin untuk ekspor datanya?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      try {
        const filter = {
          workerId: this.data?._id,
          // workerId: "c6e0148e-c25b-48a8-ab3a-8db84248dd1e",
          pipeline: [
            {
              $match: {
                _createdDate: {
                  $gte: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000), // filter data yang diambil hanya sampai 90 hari yang lalu
                },
              },
            },
            {
              $sort: {
                _createdDate: this.sort === this.constant[0] ? -1 : 1,
              },
            },
            {
              $lookup: {
                from: 'Users',
                localField: 'updatedBy',
                foreignField: '_id',
                as: 'updatedByData',
              },
            },
            {
              $unwind: {
                path: '$updatedByData',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $project: {
                _id: 1,
                amount: 1,
                desc: 1,
                updatedByName: '$updatedByData.nickname',
                email: '$updatedByData.email',
                _createdDate: 1,
                _updatedDate: 1,
              },
            },
          ],
        }

        const items = await this.$api.get(`/WorkerBalances/getHistory`, {
          params: {
            jsonQuery: JSON.stringify(filter),
          },
        })

        // const realm = await this.$realm()
        // const items = await realm.functions.getWorkerBalances(
        //   JSON.stringify(filter)
        // )
        // const items = await this.$api.get(`/WorkerBalances/getHistory`, {
        //   params: {
        //     jsonQuery: JSON.stringify(filterMongo),
        //     page: this.page,
        //     limit: this.limit,
        //   },
        // })

        const columns = [
          {
            label: '_id',
            value: '_id',
          },
          {
            label: 'Amount',
            value: 'Amount',
          },
          {
            label: 'Description',
            value: 'Description',
          },
          {
            label: 'ㅤ',
            value: 'ㅤ',
          },
        ]
        const content = items.result.map((item) => {
          // const accountNumber = item._id || ""
          return {
            _id: item._id,
            Amount: item.amount,
            Description: item.desc,
            // Email: this?.exportData?.email || "arsip.hayokerja@gmail.com",
            // ㅤ: accountNumber.length,
          }
        })
        let data = [
          {
            sheet: 'Result',
            columns,
            content,
          },
        ]
        let settings = {
          fileName: `Saldo Pekerja ${this.$moment().format('DD-MM-YYYY')}`,
        }
        this.$xlsx(data, settings)
      } catch (err) {
        if (err?.message === 'Request failed with status code 500') {
          this.$store.commit('SET', { loading: false })
          return this.$showDialog({
            title: 'Maaf',
            body: 'Penarikan data gagal',
          })
        }
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
