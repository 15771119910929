var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.span)?_c('span',_vm._g({class:[
    ..._vm.defaultClass,
    ..._vm.nonP,
    ...((_vm.classes && _vm.classes) || []),
    _vm.href ? 'primary--text text-decoration-underline' : '',
  ],style:({
    cursor: _vm.href ? 'pointer' : 'default',
  }),attrs:{"v:bind":"$props"},on:{"click":_vm.trigger}},_vm.$listeners),[_vm._t("default")],2):_c('p',_vm._g({class:[
    ..._vm.defaultClass,
    ..._vm.itsP,
    ...((_vm.classes && _vm.classes) || []),
    _vm.href ? 'primary--text text-decoration-underline' : '',
  ],style:({
    cursor: _vm.href ? 'pointer' : 'default',
  }),attrs:{"v:bind":"$props"},on:{"click":_vm.trigger}},_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }