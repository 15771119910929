<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="submitCaptain">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>Tambah Kapten</atoms-title>
            </v-col>
            <v-col cols="10">
              <atoms-text-field
                v-if="isClientsNotExist"
                label="Cari Client"
                v-model="clientTerm"
                @keyup.enter="executeGetClients"
                :rules="[]"
              />
              <atoms-text-field v-else label="Pilih Client">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="clientId"
                  :items="clients"
                  placeholder="Pilih Client"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  item-text="name"
                  item-value="_id"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
              <atoms-button
                @click="executeGetClients"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="[isClientsNotExist ? 'primary' : 'red white--text', 'mb-4']"
                >{{ isClientsNotExist ? 'Cari Client' : 'Reset Pencarian' }}</atoms-button
              >
              <div v-if="isClientSelected" class="text-center my-4">
                <atoms-text class="my-1 font-weight-bold">Client Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text><span class="font-weight-bold primary--text">Nama:</span> {{ client.name }}</atoms-text>
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Alamat:</span>
                    {{ client.address && client.address.streetAddress1 }}</atoms-text
                  >
                  <atoms-text><span class="font-weight-bold primary--text">Area Terdaftar:</span> </atoms-text>
                  <ul>
                    <li v-for="(area, i) in areas" :key="i">
                      {{ area.title }} {{ area.inactive ? '(Tidak Aktif)' : '' }}
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
            <!-- worker -->
            <v-col cols="10" v-if="isClientExistAndAreasNotExist">
              <atoms-button
                :style="{ width: '100%' }"
                class="primary"
                @click="
                  () => {
                    const routeData = $router.resolve({
                      path: '/areas/review',
                    })
                    $window.open(routeData.href, '_blank')
                  }
                "
                >Tambah Area Client !</atoms-button
              >
            </v-col>
            <v-col cols="10" v-if="isClientAndAreasExist">
              <atoms-text-field
                v-if="isWorkersNotExist"
                label="Cari Pekerja"
                :disabled="Boolean(assign._id)"
                v-model="workerTerm"
                :rules="[]"
              />
              <atoms-text-field v-else label="Pilih Pekerja">
                <v-autocomplete
                  :disabled="Boolean(assign._id)"
                  class="rounded-lg"
                  v-model="workerId"
                  :items="workers"
                  placeholder="Pilih Pekerja"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  item-text="fullName"
                  item-value="_id"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
              <atoms-button
                :disabled="Boolean(assign._id)"
                @click="executeGetWorkers"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="[isWorkersNotExist ? 'primary' : 'red white--text', 'mb-4']"
                >{{ isWorkersNotExist ? 'Cari Pekerja' : 'Reset Pencarian' }}</atoms-button
              >
              <div v-if="isWorkerSelected" class="text-center my-4">
                <atoms-text class="my-1 font-weight-bold">Pekerja Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Nama:</span> {{ worker.fullName }}</atoms-text
                  >
                  <atoms-text><span class="font-weight-bold primary--text">Email:</span> {{ worker.email }}</atoms-text>
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Alamat:</span>
                    {{ worker.address && worker.address.streetAddress }}</atoms-text
                  >
                </div>
              </div>
            </v-col>
            <!-- areaId -->
            <v-col cols="10" v-if="isClientSelected && isWorkerSelected">
              <atoms-text-field label="Pilih Area">
                <v-autocomplete
                  class="rounded-lg"
                  v-model.trim="areaId"
                  :items="areas"
                  placeholder="Pilih Area"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  item-text="title_full"
                  item-value="_id"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
              <atoms-text-field label="Pilih Lokasi">
                <v-autocomplete
                  class="rounded-lg"
                  :disabled="!areaId"
                  v-model.trim="locationId"
                  :items="locations"
                  placeholder="Pilih Lokasi"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  item-text="name_full"
                  item-value="_id"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="10" class="py-0">
              <v-divider class="mb-2" />
              <atoms-button
                :disabled="!isClientSelected || !isWorkerSelected"
                :loading="$store.state.loading"
                type="submit"
                :style="{ width: '100%' }"
                class="primary"
                >Tetapkan Kapten</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: {
    assign: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      clientTerm: '',
      clientEnabled: false,
      clientId: '',
      client: {},
      clients: [],
      workerTerm: '',
      workerEnabled: false,
      workerId: '',
      worker: {},
      workers: [],
      areaId: '',
      area: {},
      areas: [],
      locationId: '',
      location: {},
      locations: [],
      valid: false,
    }
  },
  computed: {
    isClientsNotExist() {
      return !this.clients?.[0] || !this.clientEnabled
    },
    isClientSelected() {
      return Object.keys(this.client)?.[0] && this.clientEnabled
    },
    isWorkersNotExist() {
      return !this.workers?.[0] || !this.workerEnabled
    },
    isWorkerSelected() {
      return Object.keys(this.worker)?.[0] && this.workerEnabled
    },
    isClientExistAndAreasNotExist() {
      return this.isClientSelected && !this.areas?.[0]
    },
    isClientAndAreasExist() {
      return this.isClientSelected && this.clientId && this.areas?.[0]
    },
  },
  watch: {
    async clientId(_id) {
      this.client = this.clients.find((client) => client._id === _id) || {}
      await this.getAreas()
    },
    async workerId(_id) {
      this.worker = this.workers.find((worker) => worker._id === _id) || {}
    },
    async areaId(_id) {
      this.area = this.areas.find((area) => area._id === _id) || {}
      await this.getLocations()
    },
    locationId(_id) {
      this.location = this.locations.find((location) => location._id === _id) || {}
    },
  },
  async mounted() {
    if (this.assign && this.assign?._id) {
      this.workerEnabled = true;
      this.workers = [this.assign];
      this.workerId = this.assign._id;
    }
  },
  methods: {
    async submitCaptain() {
      this.$store.commit('SET', { loading: true })
      try {
        if (
          Object.keys(this.worker)?.[0] &&
          this.areaId &&
          this.locationId &&
          !this.worker?.captainAreas?.find(
            (area) => area.areaId === this.areaId && area.locations.find((loc) => loc._id === this.location?._id),
          )
        ) {
          await this.$refs.form.validate()
          try {
            if (
              this.valid &&
              Object.keys(this.client)?.[0] &&
              Object.keys(this.worker)?.[0] &&
              this.areaId &&
              Object.keys(this.area)?.[0] &&
              this.locationId &&
              Object.keys(this.location)?.[0]
            ) {
              //code here
              const area = this.area;
              const locations =
                this?.worker?.captainAreas
                  ?.find((area) => area.areaId === this.areaId)
                  ?.locations?.filter((loc) => loc._id !== this.location?._id) || []
              let data = {
                ...(this?.worker || []),
                captain: true,
                captainAreas: [
                  ...(this?.worker?.captainAreas?.filter((x) => x.areaId !== this.areaId) || [] ),
                  {
                    areaId: area._id,
                    areaName: area.title,
                    locations: [...locations, this.location],
                  },
                ],
              }
              await this.$store.dispatch('worker/update', data)
              this.$showDialog({
                title: 'Berhasil',
                body: 'Kapten berhasil ditambahkan ke lokasi ' + this.location?.name,
              })
              this.$emit('closed')
            } else {
              this.$showDialog({
                title: 'Maaf',
                body: 'Periksa kembali kolom pengisian!',
              })
            }
          } catch (error) {
            throw error
          }
        } else {
          this.$showDialog({
            title: 'Maaf',
            body: 'Pekerja tersebut sudah terdaftar sebagai kapten di lokasi ' + this.location.name ,
          })
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.clientTerm || '', $options: 'i' }
      try {
        this.clients = await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 1,
        })
        this.clientEnabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    executeGetClients() {
      if (this.isClientsNotExist) {
        this.getClients()
        this.clientTerm = ''
        this.workerTerm = ''
        this.areaId = ''
        this.locationId = ''
      } else {
        this.clientEnabled = false
      }
    },
    async getWorkers() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.workerTerm || '', $options: 'i' }
      try {
        this.workers = await this.$store.dispatch('worker/getAll', {
          query: {
            active: true,
            approved: true,
            blacklist: { $ne: true },
            $or: [
              {
                idNumber: term,
              },
              {
                phone: term,
              },
              {
                fullName: term,
              },
              {
                email: term,
              },
            ],
          },
          page: 1,
        }).then((res) => res.result)
        this.workerEnabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    executeGetWorkers() {
      if (this.isWorkersNotExist) {
        this.getWorkers()
        this.clientTerm = ''
        this.workerTerm = ''
        this.areaId = ''
        this.locationId = ''
      } else {
        this.workerEnabled = false
      }
    },
    async getAreas() {
      this.$store.commit('SET', { loading: true })
      try {
        this.areas = await this.$store
          .dispatch('area/getAll', {
            custom: {
              clientId: this.clientId,
            },
            page: 1,
          })
          .then(
            (res) =>
              res?.data?.map((area) => ({
                ...area,
                title_full: `${area.title} ${area.inactive ? '(Tidak Aktif)' : ''}`,
              })) || [],
          )
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getLocations() {
      this.$store.commit('SET', { loading: true })
      try {
        this.locations = await this.$store
          .dispatch('location/getAll', {
            custom: {
              areaId: this.areaId,
            },
            page: 1,
          })
          .then(
            (res) =>
              {
                return res?.data?.map((location) => ({
                ...location,
                name_full: `${location.name} ${location.inactive ? '(Tidak Aktif)' : ''}`,
              })) || []
              },
          )
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
