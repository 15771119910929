<template>
  <molecules-floating>
    <molecules-custom-dialog
      :show="buktiBayar.modal"
      title="Apakah Anda Yakin?"
      caption="Anda akan menghapus bukti pembayaran!"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => {
            buktiBayar.modal = null
          },
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            unPushBuktiBayar(buktiBayar.modal)
            buktiBayar.modal = null
          },
        },
      ]"
    />
    <molecules-custom-dialog
      :show="buktiBayarKeKlien.modal"
      title="Apakah Anda Yakin?"
      caption="Anda akan menghapus bukti pembayaran ke klien!"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => {
            buktiBayarKeKlien.modal = null
          },
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            unPushBuktiBayarKeKlien(buktiBayarKeKlien.modal)
            buktiBayarKeKlien.modal = null
          },
        },
      ]"
    />
    <div
      ref="content"
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '70%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-container>
        <v-row>
          <v-col cols="12" align="start">
            <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-form v-model="valid" @submit.prevent="preValidate" ref="cases">
          <v-row justify="center" class="pt-4">
            <v-col cols="10">
              <atoms-title v-if="!readOnly">Kasus {{ id ? 'Edit' : 'Baru' }}</atoms-title>
              <atoms-title v-else>Kasus Detail</atoms-title>
            </v-col>
            <v-col cols="10">
              <template v-if="!id && !readOnly">
                <atoms-text-field
                  v-if="!$store.state.worker.data || !worker.enabled"
                  label="Cari Pekerja"
                  v-model="worker.term"
                  :rules="[(v) => !!v || !!worker.item || 'Harap Pilih Pekerja']"
                />
                <atoms-text-field v-else label="Pilih Pekerja">
                  <v-autocomplete
                    class="rounded-lg"
                    v-model="worker.value"
                    :items="$store.state.worker.data.map((x) => x.fullName + '-' + x.email)"
                    placeholder="Pilih Pekerja"
                    :menu-props="{ offsetY: true }"
                    :rules="[(v) => !!worker.item || 'Harap Pilih Pekerja']"
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
                <atoms-button
                  @click="
                    () => {
                      if (!$store.state.worker.data || !worker.enabled) {
                        getWorkers()
                        worker.enabled = true
                      } else {
                        worker.item = null
                        worker.enabled = false
                      }
                      worker.value = ''
                      worker.term = ''
                    }
                  "
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  :class="[!$store.state.worker.data || !worker.enabled ? 'primary' : 'red white--text', 'mb-4']"
                  >{{ !$store.state.worker.data || !worker.enabled ? 'Cari Pekerja' : 'Reset Pencarian' }}</atoms-button
                >
              </template>
              <div v-if="worker.item && worker.enabled" class="text-start">
                <atoms-text class="my-2 font-weight-bold">Pekerja Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Nama:</span> {{ worker.item.fullName }}</atoms-text
                  >
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Email:</span> {{ worker.item.email }}</atoms-text
                  >
                </div>
              </div>
            </v-col>
            <v-col cols="10">
              <template v-if="!id && !readOnly">
                <atoms-text-field
                  v-if="!$store.state.client.data || !client.enabled"
                  label="Cari Client"
                  v-model="client.term"
                  :rules="[(v) => !!v || !!client.item || 'Harap Pilih Klien']"
                />
                <atoms-text-field v-else label="Pilih Client">
                  <v-autocomplete
                    class="rounded-lg"
                    v-model="client.value"
                    :items="$store.state.client.data.map((x) => x.name)"
                    placeholder="Pilih Client"
                    :menu-props="{ offsetY: true }"
                    :rules="[(v) => !!client.item || 'Harap Pilih Klien']"
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
                <atoms-button
                  @click="
                    () => {
                      if (!$store.state.client.data || !client.enabled) {
                        getClients()
                        client.term = ''
                        client.enabled = true
                      } else {
                        client.enabled = false
                      }
                    }
                  "
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  :class="[!$store.state.client.data || !client.enabled ? 'primary' : 'red white--text', 'mb-4']"
                  >{{ !$store.state.client.data || !client.enabled ? 'Cari Client' : 'Reset Pencarian' }}</atoms-button
                >
              </template>
              <div v-if="client.item && client.enabled" class="text-start">
                <atoms-text class="my-1 font-weight-bold">Client Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Nama:</span> {{ client.item.name }}</atoms-text
                  >
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Alamat:</span>
                    {{ client.item.address.streetAddress1 }}</atoms-text
                  >
                  <atoms-text><span class="font-weight-bold primary--text">Area Terdaftar:</span> </atoms-text>
                  <ul>
                    <li v-for="(x, i) in (client.item.areas && client.item.areas.map((x) => x.title)) || []" :key="i">
                      {{ x }}
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
            <v-col cols="10">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" v-if="!readOnly">
              <!-- user -->
              <atoms-text-field
                v-if="!$store.state.user.data || !personRespData.enabled"
                label="Cari Pihak Yang Bertanggung Jawab"
                v-model="personRespData.term"
                :rules="[
                  (v) => (!!personRespData.items && personRespData.items.length > 0) || !!v || 'Harap Pilih Responder',
                ]"
              />
              <div v-else>
                <v-tooltip top attach="">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Pilih Pihak Yang Bertanggung Jawab">
                      <v-autocomplete
                        v-bind="attrs"
                        v-on="on"
                        name="personRespData"
                        class="rounded-lg"
                        v-model="personRespData.value"
                        :items="$store.state.user.data.map((x) => x.nickname + '-' + x.email)"
                        placeholder="Pilih Akun"
                        prepend-icon="mdi-plus"
                        @click:prepend="
                          () => {
                            pushAccount()
                            $refs.cases.validate('personRespData')
                          }
                        "
                        :menu-props="{ offsetY: true }"
                        :rules="[
                          (v) => (!!personRespData.items && personRespData.items.length > 0) || 'Harap Pilih Responder',
                        ]"
                        dense
                        solo
                        flat
                      />
                    </atoms-text-field>
                  </template>
                  <span>Klik Icon (+) Tambah untuk menambahkan akun!</span>
                </v-tooltip>
              </div>

              <atoms-button
                @click="
                  () => {
                    if (!$store.state.user.data || !personRespData.enabled) {
                      getUsers()
                      personRespData.term = ''
                      personRespData.enabled = true
                    } else {
                      personRespData.enabled = false
                    }
                  }
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="[!$store.state.user.data || !personRespData.enabled ? 'primary' : 'red white--text', 'mb-4']"
                >{{
                  !$store.state.user.data || !personRespData.enabled ? 'Cari Pihak' : 'Reset Pencarian'
                }}</atoms-button
              >
            </v-col>
            <v-col cols="10" v-if="readOnly" align="start">
              <atoms-text class="font-weight-bold">Pihak Yang Bertanggung Jawab</atoms-text>
            </v-col>
            <v-col v-if="personRespData.items" cols="10">
              <v-data-table
                :headers="[
                  {
                    text: 'Akun',
                    sortable: false,
                    value: 'nickname',
                    class: 'primary--text text-uppercase',
                  },
                  {
                    text: 'Email',
                    sortable: false,
                    value: 'email',
                    class: 'primary--text text-uppercase',
                  },
                  {
                    text: 'Aksi',
                    sortable: false,
                    value: 'action',
                    class: 'text-uppercase text-center',
                  },
                ]"
                :items="personRespData.items || []"
                class="accent rounded-lg"
                hide-default-footer
                hide-default-header
              >
                <template v-slot:[`header`]="{ props }">
                  <thead>
                    <tr class="d-table-row">
                      <th v-for="h in props.headers" :key="h.value" :class="h.class">
                        <span>{{ h.text }}</span>
                      </th>
                    </tr>
                  </thead></template
                >

                <template v-slot:[`item`]="{ item }">
                  <tr class="d-table-row">
                    <td class="text-left">{{ item.nickname }}</td>
                    <td class="text-left">{{ item.email }}</td>
                    <td class="text-center">
                      <v-icon :disabled="readOnly" @click="unPushAccount(item._id)" small light> mdi-delete </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="10">
              <v-divider />
            </v-col>
            <v-col cols="10" md="5" class="pb-0">
              <atoms-text-field label="Status">
                <v-autocomplete
                  class="rounded-lg"
                  placeholder="Pilih Status"
                  :items="Object.values($cases)"
                  :menu-props="{ offsetY: true }"
                  background-color="accent"
                  v-model="status"
                  :disabled="readOnly"
                  required
                  :rules="rules"
                  solo
                  flat
                  dense
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="10" md="5" class="pt-0 pt-md-3">
              <v-dialog ref="pickerDialog" v-model="date.modal" :return-value.sync="date.value" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <atoms-text-field label="Tanggal Kejadian">
                    <v-text-field
                      :disabled="readOnly"
                      class="rounded-lg"
                      placeholder="Tanggal Kejadian"
                      append-icon="mdi-calendar"
                      background-color="accent"
                      v-model="formatDate"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      :rules="rules"
                      readonly
                      solo
                      flat
                      dense
                      :style="{
                        'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                      }"
                    />
                  </atoms-text-field>
                </template>
                <v-date-picker
                  :max="$moment().endOf('month').format('YYYY-MM-DD')"
                  v-model="date.value"
                  scrollable
                  class="rounded-lg"
                  @click:date="$refs.pickerDialog.save(date.value)"
                />
              </v-dialog>
            </v-col>
            <v-col cols="10" class="pt-md-0 pb-0">
              <atoms-text-field label="Kerugian" :disabled="readOnly" prefix="Rp." v-model="kerugian" :rules="rules" />
            </v-col>
            <v-col cols="10" class="py-0">
              <div class="d-flex align-center justify-center justify-md-start">
                <div class="d-flex align-center justify-center">
                  <v-checkbox :disabled="readOnly" v-model="blacklist" hide-spin-buttons />
                </div>
                <v-label><atoms-text class="pa-2 font-weight-bold">BLACKLIST PEKERJA! </atoms-text></v-label>
              </div>
            </v-col>
            <v-col cols="10" class="py-0">
              <atoms-title :h3="true"
                ><span class="primary--text">Total Pengembalian :</span> {{ $idCurrency(pengembalian) }}</atoms-title
              >

              <atoms-title :h3="true"
                ><span class="primary--text">Hutang :</span> {{ $idCurrency(hutang) }}</atoms-title
              >
            </v-col>
            <v-col cols="10">
              <v-divider />
            </v-col>
            <div class="buktiBayarKeHayokerja">
              <v-col cols="10">
                <atoms-title :h3="true">Bukti Pembayaran ke Hayokerja</atoms-title>
              </v-col>
              <v-col cols="10" v-if="!readOnly">
                <v-form v-model="buktiBayar.valid" @submit.prevent="pushBuktiBayar" ref="buktiBayar">
                  <atoms-text-field
                    label="Jumlah Pembayaran"
                    prefix="Rp."
                    v-model="buktiBayar.payAmount"
                    :rules="rules"
                  />
                  <div>
                    <atoms-image
                      class="rounded-lg"
                      :contain="true"
                      :maxH="300"
                      :src="buktiBayar.picture && buktiBayar.picture.url"
                    >
                      <v-overlay :value="true" opacity="0.5" absolute>
                        <div
                          class="pa-12"
                          :style="{
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                          }"
                        >
                          <atoms-text
                            :v-if="!buktiBayar.picture"
                            @click="openUploader('uploaderBukti')"
                            class="font-weight-medium"
                            ><v-icon small left> mdi-camera </v-icon>Unggah Foto</atoms-text
                          >

                          <atoms-text
                            v-if="!!buktiBayar.picture"
                            @click="
                              buktiBayar && buktiBayar.picture
                              buktiBayar.picture.url &&
                                $viewerApi({
                                  options: {
                                    toolbar: false,
                                  },
                                  images: [buktiBayar.picture.url],
                                })
                            "
                            class="font-weight-medium mt-4"
                            ><v-icon left small> mdi-eye </v-icon>Perbesar</atoms-text
                          >
                        </div>
                      </v-overlay></atoms-image
                    >
                    <input ref="uploaderBukti" type="file" accept="image/*" class="d-none" @change="onBuktiChange" />
                  </div>
                  <atoms-button
                    :disabled="!!buktiBayar.picture"
                    @click="openUploader('uploaderBukti')"
                    class="primary mt-4"
                    style="width: 100%"
                    >Unggah Bukti Pembayaran</atoms-button
                  >
                  <atoms-button @click="pushBuktiBayar" class="green white--text mt-4" style="width: 100%">
                    Tambah Bukti</atoms-button
                  >
                </v-form>
              </v-col>
              <v-col v-if="buktiBayar.items && (readOnly || buktiBayar.items.length > 0)" cols="10">
                <v-data-table
                  :headers="[
                    {
                      text: 'Bukti (Gambar)',
                      value: 'value',
                      width: '10%',
                    },
                    {
                      text: 'Jumlah Bayar',
                      value: 'payAmount',
                      width: '40%',
                    },
                    {
                      text: 'Tanggal',
                      value: 'label',
                      width: '40%',
                    },
                    {
                      text: 'Aksi',
                      width: '20%',
                    },
                  ]"
                  disable-sort
                  :items="buktiBayar.items || []"
                  class="accent rounded-lg"
                  hide-default-footer
                  hide-default-header
                  mobile-breakpoint="0"
                  dense
                >
                  <template v-slot:[`header`]="{ props }">
                    <thead>
                      <tr class="d-table-row">
                        <th v-for="h in props.headers" :key="h.value" :class="['left-center', h.class]">
                          <span class="primary--text text-uppercase">{{ h.text }}</span>
                        </th>
                      </tr>
                    </thead></template
                  >

                  <template v-slot:[`item`]="{ item }">
                    <tr class="d-table-row">
                      <td>
                        <atoms-image
                          :src="(item.value && item.value.url) || item.value || './lazy-img.webp'"
                          :enableZoom="true"
                          :contain="true"
                          width="150px"
                          height="150px"
                          class="my-2"
                        />
                      </td>
                      <td>
                        {{ $idCurrency(item.payAmount) }}
                      </td>
                      <td>
                        {{ $moment(item._createdDate).format('DD MMMM YYYY, H:mm:ss') }}
                      </td>
                      <td>
                        <atoms-button
                          :disabled="readOnly"
                          @click="
                            () => {
                              buktiBayar.modal = item._id
                            }
                          "
                          :icon="true"
                        >
                          <v-icon> mdi-delete </v-icon>
                        </atoms-button>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </div>
            <div class="buktiBayarKeKlien">
              <v-col cols="10">
                <atoms-title :h3="true">Bukti Pembayaran ke Klien</atoms-title>
              </v-col>
              <v-col cols="10" v-if="!readOnly">
                <v-form
                  v-model="buktiBayarKeKlien.valid"
                  @submit.prevent="pushBuktiBayarKeKlien"
                  ref="buktiBayarKeKlien"
                >
                  <atoms-text-field
                    label="Jumlah Pembayaran"
                    prefix="Rp."
                    v-model="buktiBayarKeKlien.payAmount"
                    :rules="rules"
                  />
                  <div>
                    <atoms-image
                      class="rounded-lg"
                      :contain="true"
                      :maxH="300"
                      :src="buktiBayarKeKlien.picture && buktiBayarKeKlien.picture.url"
                    >
                      <v-overlay :value="true" opacity="0.5" absolute>
                        <div
                          class="pa-12"
                          :style="{
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                          }"
                        >
                          <atoms-text
                            :v-if="!buktiBayarKeKlien.picture"
                            @click="openUploader('uploaderBuktiBayarKeKlien')"
                            class="font-weight-medium"
                            ><v-icon small left> mdi-camera </v-icon>Unggah Foto</atoms-text
                          >

                          <atoms-text
                            v-if="!!buktiBayarKeKlien.picture"
                            @click="
                              buktiBayarKeKlien && buktiBayarKeKlien.picture
                              buktiBayarKeKlien.picture.url &&
                                $viewerApi({
                                  options: {
                                    toolbar: false,
                                  },
                                  images: [buktiBayarKeKlien.picture.url],
                                })
                            "
                            class="font-weight-medium mt-4"
                            ><v-icon left small> mdi-eye </v-icon>Perbesar</atoms-text
                          >
                        </div>
                      </v-overlay></atoms-image
                    >
                    <input
                      ref="uploaderBuktiBayarKeKlien"
                      type="file"
                      accept="image/*"
                      class="d-none"
                      @change="onBuktiKeKlienChange"
                    />
                  </div>
                  <atoms-button
                    :disabled="!!buktiBayarKeKlien.picture"
                    @click="openUploader('uploaderBuktiBayarKeKlien')"
                    class="primary mt-4"
                    style="width: 100%"
                    >Unggah Bukti Pembayaran</atoms-button
                  >
                  <atoms-button @click="pushBuktiBayarKeKlien" class="green white--text mt-4" style="width: 100%">
                    Tambah Bukti</atoms-button
                  >
                </v-form>
              </v-col>
              <v-col v-if="buktiBayarKeKlien.items && (readOnly || buktiBayarKeKlien.items.length > 0)" cols="10">
                <v-data-table
                  :headers="[
                    {
                      text: 'Bukti (Gambar)',
                      value: 'value',
                      width: '10%',
                    },
                    {
                      text: 'Jumlah Bayar',
                      value: 'payAmount',
                      width: '40%',
                    },
                    {
                      text: 'Tanggal',
                      value: 'label',
                      width: '40%',
                    },
                    {
                      text: 'Aksi',
                      width: '20%',
                    },
                  ]"
                  disable-sort
                  :items="buktiBayarKeKlien.items || []"
                  class="accent rounded-lg"
                  hide-default-footer
                  hide-default-header
                  mobile-breakpoint="0"
                  dense
                >
                  <template v-slot:[`header`]="{ props }">
                    <thead>
                      <tr class="d-table-row">
                        <th v-for="h in props.headers" :key="h.value" :class="['left-center', h.class]">
                          <span class="primary--text text-uppercase">{{ h.text }}</span>
                        </th>
                      </tr>
                    </thead></template
                  >

                  <template v-slot:[`item`]="{ item }">
                    <tr class="d-table-row">
                      <td>
                        <atoms-image
                          :src="(item.value && item.value.url) || item.value || './lazy-img.webp'"
                          :enableZoom="true"
                          :contain="true"
                          width="150px"
                          height="150px"
                          class="my-2"
                        />
                      </td>
                      <td>
                        {{ $idCurrency(item.payAmount) }}
                      </td>
                      <td>
                        {{ $moment(item._createdDate).format('DD MMMM YYYY, H:mm:ss') }}
                      </td>
                      <td>
                        <atoms-button
                          :disabled="readOnly"
                          @click="
                            () => {
                              buktiBayarKeKlien.modal = item._id
                            }
                          "
                          :icon="true"
                        >
                          <v-icon> mdi-delete </v-icon>
                        </atoms-button>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </div>
            <v-col cols="10">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" v-if="!readOnly">
              <atoms-button @click="preValidate" style="width: 100%" class="primary">
                <v-icon left>mdi-check</v-icon>Simpan Kasus</atoms-button
              >
            </v-col>
            <v-col v-if="id && !readOnly" cols="10">
              <atoms-button @click="loadData" style="width: 100%" class="red white--text">
                <v-icon left>mdi-history</v-icon>Reset Kasus</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id', 'readOnly'],
  data() {
    return {
      data: null,
      worker: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
      client: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
      buktiBayar: {
        modal: false,
        valid: true,
        payAmount: '',
        picture: null,
        items: null,
      },
      buktiBayarKeKlien: {
        modal: false,
        valid: true,
        payAmount: '',
        picture: null,
        items: null,
      },
      personRespData: {
        enabled: false,
        value: '',
        item: null,
        items: null,
        term: '',
      },
      date: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      rules: {
        default: [(v) => !!v || 'Harap diisi!'],
      },
      status: '',
      showEdit: null,
      valid: true,
      kerugian: '',
      blacklist: false,
      pengembalian: 0,
      hutang: 0,
      rules: [(v) => !!v || 'Harap Diisi!'],
    }
  },
  watch: {
    kerugian: function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => {
        this.kerugian = result
        this.calculateHutang()
      })
    },
    'buktiBayar.items': {
      handler(items) {
        const totalPengembalian = items.reduce((total, item) => total + +(item?.payAmount || 0), 0)
        this.$nextTick(() => {
          this.pengembalian = totalPengembalian
          this.calculateHutang()
        })
      },
      deep: true,
    },
    // "pengembalian": function(val) {
    //   const newNum = typeof(val) == "string" ? this.$removeSeparator(val) : val
    //   this.pengembalian = +(newNum);
    //   this.calculateHutang();
    // },
    'buktiBayar.payAmount': function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => (this.buktiBayar.payAmount = result))
    },
    'buktiBayarKeKlien.items': {
      handler(items) {
        const totalPengembalian = items.reduce((total, item) => total + +(item?.payAmount || 0), 0)
        this.$nextTick(() => {
          this.pengembalian = totalPengembalian
          this.calculateHutang()
        })
      },
      deep: true,
    },
    'buktiBayarKeKlien.payAmount': function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => (this.buktiBayarKeKlien.payAmount = result))
    },

    'personRespData.value'() {
      if (this.personRespData.value) {
        this.personRespData.item =
          this.$store.state.user?.data?.find((x) => x?.email === this.personRespData.value?.split('-')?.[1]) ||
          this.data?.personResp?.[0]
      }
    },
    'client.value'() {
      if (this.client.value) {
        this.client.item =
          this.$store.state.client?.data?.find((x) => x.name === this.client.value) || this.data?.client
      }
    },
    'worker.value'() {
      if (this.worker.value) {
        this.worker.item =
          this.$store.state.worker?.data?.find((x) => x.email === this.worker.value.split('-')[1]) || this.data?.worker
      }
      if (this.worker.item) {
        this.blacklist = this.worker.item?.blacklist
      } else {
        this.blacklist = false
      }
    },
  },
  computed: {
    formatDate: {
      set(value) {
        this.date.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.date?.value || new Date()).format('DD MMMM YYYY')
      },
    },
  },
  errorCaptured() {
    this.$emit('closed')
  },
  async mounted() {
    await this.loadData()
    if (this.id && !this.data) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal ketika memuat data kasus`,
      })
      this.$emit('closed')
    }
    // console.log(this.data);
  },
  methods: {
    async loadData() {
      if (this.id) {
        this.data = await this.$store.dispatch('cases/get', {
          query: {
            _id: this.id,
          },
        })
      }
      if (this.data) {
        this.client.value = this.data?.client?.name
        this.client.enabled = Boolean(this.data?.client?.name)
        this.worker.value = this.data?.worker?.fullName
        this.worker.enabled = Boolean(this.data?.worker?.fullName)
        this.personRespData.items = this.data?.personResp || []
        this.personRespData.value = this.data?.personResp?.[0]?.nickname
        this.status = this.data?.status
        this.kerugian = String(this.data?.kerugian)
        this.pengembalian = this.data?.pengembalian
        this.date.value = this.$moment(this.data?.tanggalKejadian).format('YYYY-MM-DD')
        this.buktiBayar.items = this.data?.buktiBayar || []
        this.buktiBayarKeKlien.items = this.data?.buktiBayarKeKlien || []
      }
    },
    async calculateHutang() {
      const kerugian = this.$removeSeparator(this.kerugian)
      const pengembalian =
        typeof this.pengembalian == 'string' ? this.$removeSeparator(this.pengembalian) : this.pengembalian
      this.hutang = +kerugian - pengembalian
    },
    async getWorkers() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.worker.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('worker/getAll', {
          custom: {
            pipeline: [
              ...this.$store.state.worker.defaultPipeline,
              {
                $match: {
                  approved: true,
                  $or: [
                    {
                      idNumber: term,
                    },
                    {
                      fullName: term,
                    },
                    {
                      email: term,
                    },
                    {
                      phone: term,
                    },
                    {
                      birthplace: term,
                    },
                    {
                      'address.city': term,
                    },
                    {
                      'address.province': term,
                    },
                    {
                      'address.kecamatan': term,
                    },
                  ],
                },
              },
            ],
          },
          page: 0,
          limit: 100,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.client.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 0,
          limit: 100,
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getUsers() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.personRespData.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('user/getAll', {
          custom: {
            $or: [
              {
                nickname: term,
              },
              {
                email: term,
              },
              {
                roles: term,
              },
            ],
            pipeline: [...this.$store.state.user.defaultPipeline],
          },
          page: 0,
          limit: 100,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    // person respon
    async pushAccount() {
      if (
        !this.personRespData?.items?.find((x) => (x?._id || x) === this.personRespData?.item?._id) &&
        this.personRespData?.item
      ) {
        this.personRespData.items = [...(this.personRespData?.items || []), this.personRespData?.item]
      } else {
        this.$showDialog({
          title: 'Perhatian',
          body: !this.personRespData.item
            ? 'Pilih akun terlebih dahulu!'
            : `Akun yang ada ingin tambahkan sudah masuk kedalam`,
        })
      }
    },
    async unPushAccount(id) {
      if (id && this.personRespData?.items) {
        this.personRespData.items = this.personRespData.items.filter((x) => x._id !== id)
        this.$refs?.cases?.validate('personRespData')
      }
    },
    // bukti
    openUploader(constant) {
      if (this.$refs[constant]) {
        this.$refs[constant]?.click()
      }
    },
    // bukti bayar
    async onBuktiChange(payload) {
      payload = payload.target.files[0]
      if (payload && payload.size > 3000000) {
        this.$dialog.showDialog({
          title: 'Perhatian',
          content: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (payload) {
        this.buktiBayar.picture = {
          raw: payload,
          url: URL.createObjectURL(payload),
        }
        URL.revokeObjectURL(payload) // free memory
      } else {
        this.buktiBayar.picture = null
      }
    },
    async pushBuktiBayar() {
      await this.$refs.buktiBayar.validate()
      if (this.buktiBayar.valid && this.buktiBayar.payAmount && this.buktiBayar.picture) {
        const date = new Date()
        this.buktiBayar.items = [
          ...(this.buktiBayar.items || []),
          {
            _id: this.$uuid.v4(),
            payAmount: this.$removeSeparator(this.buktiBayar.payAmount),
            value: this.buktiBayar.picture,
            label: this.$moment(date).format('DD MMMM YYYY H:mm:ss'),
            _createdDate: date,
          },
        ]
        this.buktiBayar = {
          modal: this.buktiBayar.modal,
          picture: null,
          payAmount: '',
          items: this.buktiBayar.items || null,
        }
        this.$refs.uploaderBukti.value = null
        this.$refs.buktiBayar.resetValidation()
        this.$refs.buktiBayar.reset()
      } else {
        this.$showDialog({
          title: 'Perhatian',
          body: !this.buktiBayar.picture
            ? 'Pastikan anda telah mengunggah bukti bayar sebelum menambahkan bukti'
            : `Periksa kembali jumlah dan unggahan bukti bayar`,
        })
      }
    },
    async unPushBuktiBayar(_id) {
      console.log('test')
      if (_id && this.buktiBayar?.items) {
        this.buktiBayar.items = this.buktiBayar.items.filter((x) => x._id !== _id)
      }
    },
    // bukti bayar ke klien
    async onBuktiKeKlienChange(payload) {
      payload = payload.target.files[0]
      if (payload && payload.size > 3000000) {
        this.$dialog.showDialog({
          title: 'Perhatian',
          content: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (payload) {
        this.buktiBayarKeKlien.picture = {
          raw: payload,
          url: URL.createObjectURL(payload),
        }
        URL.revokeObjectURL(payload) // free memory
      } else {
        this.buktiBayarKeKlien.picture = null
      }
    },
    async pushBuktiBayarKeKlien() {
      await this.$refs.buktiBayarKeKlien.validate()
      if (this.buktiBayarKeKlien.valid && this.buktiBayarKeKlien.payAmount && this.buktiBayarKeKlien.picture) {
        const date = new Date()
        this.buktiBayarKeKlien.items = [
          ...(this.buktiBayarKeKlien.items || []),
          {
            _id: this.$uuid.v4(),
            payAmount: this.$removeSeparator(this.buktiBayarKeKlien.payAmount),
            value: this.buktiBayarKeKlien.picture,
            label: this.$moment(date).format('DD MMMM YYYY H:mm:ss'),
            _createdDate: date,
          },
        ]
        this.buktiBayarKeKlien = {
          modal: this.buktiBayarKeKlien.modal,
          picture: null,
          payAmount: '',
          items: this.buktiBayarKeKlien.items || null,
        }
        this.$refs.uploaderBuktiBayarKeKlien.value = null
        this.$refs.buktiBayarKeKlien.resetValidation()
        this.$refs.buktiBayarKeKlien.reset()
      } else {
        this.$showDialog({
          title: 'Perhatian',
          body: !this.buktiBayarKeKlien.picture
            ? 'Pastikan anda telah mengunggah bukti bayar sebelum menambahkan bukti'
            : `Periksa kembali jumlah dan unggahan bukti bayar`,
        })
      }
    },
    async unPushBuktiBayarKeKlien(_id) {
      if (_id && this.buktiBayarKeKlien?.items) {
        this.buktiBayarKeKlien.items = this.buktiBayarKeKlien.items.filter((x) => x._id !== _id)
      }
    },

    async preValidate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$refs.cases.validate()
        if (
          this.valid &&
          !this.readOnly
          // this.worker.item &&
          // this.client.item &&

          // this.status &&
          // this.kerugian &&
          // this.date.value
        ) {
          await this.validate()
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: 'Periksa kembali kolom pengisian',
          })
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      try {
        if (this.personRespData.items?.length > 0) {
          const extras = await this.casePurifiedAsync()
          const payload = await new Promise(async (res, rej) => {
            res({
              ...(extras || {}),
              pekerja: this.worker.item?._id,
              klien: this.client.item?._id,
              status: this.status,
              tanggalKejadian: this.date.value,
              updatedBy: this.$store.state.user?._id,
              worker: {
                ...this.worker.item,
                blacklist: this.blacklist,
              },
            })
          })
          if (this.id) {
            delete payload?.client
            await this.$store.dispatch('cases/update', payload)
          } else {
            await this.$store.dispatch('cases/create', payload)
          }
          // console.log(payload);
          this.$refs.cases.resetValidation()
          this.$refs.cases.reset()
          this.$showDialog({
            title: 'Berhasil',
            body: `Kasus baru berhasil di${(this.id && 'perbarui') || 'tambahkan'}!`,
          })
          this.$emit('closed')
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: 'Pastikan anda sudah menambahkan setidaknya 1 responder!',
          })
        }
      } catch (error) {
        Promise.reject(error)
      }
    },
    async casePurifiedAsync() {
      const _id = (this.id && this.data?._id) || this.$uuid.v4()
      const personResp = this.personRespData?.items?.map((it) => it?._id)
      const buktiBayar =
        (this.buktiBayar.items?.length > 0 &&
          (await Promise.all(
            this.buktiBayar.items?.map(async (it) => {
              let value = it?.value
              if (it?.value?.url && it?.value?.raw) {
                value = await this.$uploadFile(it?.value?.raw, `Cases/${_id}`, it?.value?.url) //it?.value?.url;
              }
              return {
                ...it,
                value,
              }
            }),
          ))) ||
        []
      const buktiBayarKeKlien =
        (this.buktiBayarKeKlien.items?.length > 0 &&
          (await Promise.all(
            this.buktiBayarKeKlien.items?.map(async (it) => {
              let value = it?.value
              if (it?.value?.url && it?.value?.raw) {
                value = await this.$uploadFile(it?.value?.raw, `Cases/${_id}`, it?.value?.url) //it?.value?.url;
              }
              return {
                ...it,
                value,
              }
            }),
          ))) ||
        []
      return {
        _id,
        ...(this?.data || {}),
        kerugian: this.$removeSeparator(this.kerugian),
        pengembalian: this.$removeSeparator(this.pengembalian),
        hutang: this.$removeSeparator(this.hutang),
        personResp,
        buktiBayar,
        buktiBayarKeKlien,
      }
    },
  },
}
</script>
