var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-img',_vm._g({class:[_vm.rounded && 'rounded-lg', ...(_vm.$props.class || [])],style:({
    cursor: _vm.enableZoom ? 'pointer' : 'auto',
    'box-shadow': _vm.shadow
      ? 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
      : 'none',
  }),attrs:{"v:bind":"$props","alt":`image of ${_vm.source || Math.random() * 1000}`,"width":_vm.width,"sizes":_vm.sizes,"height":_vm.height,"contain":_vm.contain,"max-height":_vm.maxH,"max-width":_vm.maxW,"min-height":_vm.minH,"min-width":_vm.minW,"lazy-src":_vm.source,"src":_vm.source},on:{"click":function($event){$event.stopPropagation();_vm.enableZoom &&
      _vm.$viewerApi({
        options: {
          toolbar: false,
        },
        images: [_vm.source],
      })},"error":_vm.notLoaded}},_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }