<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="pa-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container class="">
        <v-row justify="center" class="mx-4">
          <v-col cols="12" class="text-center">
            <atoms-title>Bagaimana caranya approve permintaan?</atoms-title>
          </v-col>
          <v-col cols="12" align="start">
            <atoms-text
              >Pilih pekerja mana yang ingin disetujui permintaannya, lalu klik ikon `titik tiga`, kemudian pilih
              `approve`</atoms-text
            >
            <atoms-image width="100%" class="my-4" src="/media/request-payment/tutorial-approve.gif" />
            <atoms-text
              >Atau Anda bisa pilih pekerja dengan mencentang ikon `kotak` kemudian pilih tombol `approve` yang muncul
              setelahnya</atoms-text
            >
            <atoms-image width="100%" class="my-4" src="/media/request-payment/tutorial-approvebulk.gif" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
