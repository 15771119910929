var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.preValidate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v("Edit Akun Anda")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('div',[_c('v-avatar',{style:({
                  cursor: 'pointer',
                }),attrs:{"color":"accent","size":"150"}},[_c('v-overlay',{attrs:{"value":true,"opacity":"0.5","absolute":""}},[_c('div',{staticClass:"pa-12",style:({ width: '100%', height: '100%' }),on:{"click":_vm.openUploader}},[_c('atoms-text',{staticClass:"font-weight-medium"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v("Upload Foto")],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onPhotoChange}})],1)]),(_vm.photo && _vm.photo.url)?_c('v-img',{attrs:{"lazy-src":`/lazy-img.webp`,"aspect-ratio":"1","src":_vm.photo.url || `/lazy-img.webp`}}):_c('v-icon',[_vm._v(" mdi-account ")])],1),_c('atoms-text',{staticClass:"py-4"},[_vm._v("Foto Maks. 3mb (Bila ada)")])],1)]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-divider')],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('div',{staticClass:"pa-4 mb-4 rounded-lg accent text-left d-flex flex-column"},[_c('atoms-text',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nama Akun  : ")]),_vm._v(_vm._s((_vm.user && _vm.user.nickname) || '-'))]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Email Anda   : ")]),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s((_vm.user && _vm.user.email) || '-'))])]),_c('atoms-text',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Hak akses  : ")]),_vm._v(_vm._s((_vm.user && _vm.user.roles && _vm.user.roles.toString()) || '-'))])],1),_c('atoms-text-field',{attrs:{"type":"password","label":"Password Lama","color":"accent","rules":[
                (v) => !!v || 'Harap disii!',
                (v) => (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter',
              ]},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('atoms-text-field',{attrs:{"type":"password","label":"Password Baru","color":"accent","rules":[
                (v) => !!v || 'Harap disii!',
                (v) => (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter',
              ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('atoms-text-field',{attrs:{"type":"password","label":"Konfirmasi Password","color":"accent","rules":[
                (v) => !!v || 'Harap disii!',
                (v) => (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter',
                (v) => (!!v && v === _vm.password) || 'Password tidak sesuai',
              ]},model:{value:(_vm.confirmPass),callback:function ($$v) {_vm.confirmPass=$$v},expression:"confirmPass"}}),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v("Perbarui Akun")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }