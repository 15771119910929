var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{staticClass:"accent",staticStyle:{"border":"none"},attrs:{"app":"","mini-variant":_vm.mini},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-around flex-row my-4 mx-2"},[(!_vm.mini)?_c('img',{staticClass:"mr-auto",staticStyle:{"width":"50%"},attrs:{"src":require('@/assets/hayokerja.png')}}):_vm._e(),_c('v-btn',{staticClass:"mb-md-2",attrs:{"depressed":"","icon":"","elevation":"0"},on:{"click":function($event){_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-list',{class:['rounded-xl'],attrs:{"nav":"","dense":""}},[_c('v-menu',{style:({
              'box-shadow': 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
            }),attrs:{"close-on-click":"","offset-y":"","rounded":"lg","min-width":"350","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({style:({
                  cursor: 'pointer',
                })},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{staticClass:"rounded-pill",style:({
                    cursor: 'pointer',
                  }),attrs:{"color":"accent","size":_vm.mini ? 30 : 50}},[(_vm.$store.state.user.picture)?_c('v-img',{attrs:{"src":_vm.$store.state.user.picture || '/lazy.webp'}}):_c('v-icon',[_vm._v("mdi-domain")])],1),_c('v-list-item-title',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(`${_vm.$store.state.user.nickname || 'Pengguna'}`.length >= 14 ? `${_vm.$store.state.user.nickname || 'Pengguna'}`.substring(0, 14) + '...' : `${_vm.$store.state.user.nickname || 'Pengguna'}`))]),_c('atoms-text',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$store.state.user.email))])],1),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-unfold-more-horizontal")])],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticClass:"pa-4 rounded-lg",attrs:{"nav":""}},[_c('v-list-item',{staticClass:"rounded-lg pr-4",attrs:{"exact-active-class":"primary--text"}},[_c('v-list-item-avatar',{staticClass:"rounded-full",style:({
                    cursor: 'pointer',
                  }),attrs:{"color":"accent","size":"50"}},[(_vm.$store.state.user.picture)?_c('v-img',{attrs:{"src":_vm.$store.state.user.picture || '/lazy-img-webp',"aspect-ratio":"1"}}):_c('v-icon',[_vm._v(" mdi-account ")])],1),_c('v-list-item-title',[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$store.state.user.nickname || 'Pengguna'))]),_c('atoms-text',[_vm._v(_vm._s(_vm.$store.state.user.email))])],1)],1),_c('v-divider',{staticClass:"my-1"}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover: hoverList }){return _c('v-list-item',{class:['mt-2 primary--text rounded-lg', hoverList && 'primary lighten-5'],style:({
                    cursor: 'pointer',
                  }),on:{"click":function($event){_vm.showReset = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-lock")]),_vm._v("Detail Akun Anda")],1)],1)],1)}}])}),_c('v-list-item',{staticClass:"white--text mt-2 red lighten-5 red--text rounded-lg",style:({
                  cursor: 'pointer',
                }),attrs:{"active-class":"transparent"},on:{"click":_vm.logout}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":"","color":"red"}},[_vm._v("mdi-logout")]),_vm._v("Keluar")],1)],1)],1)],1)],1)],1)]},proxy:true}])},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.items && _vm.items),function({ path, icon, title },i){return _c('v-list-item',{key:i,attrs:{"to":path,"link":"","active-class":"primary white--text "}},[_c('v-list-item-icon',{attrs:{"title":title}},[_c('v-icon',[_vm._v("mdi-"+_vm._s(icon))])],1),_c('v-list-item-title',[_c('atoms-text',{attrs:{"auto":true}},[_vm._v(_vm._s(_vm.$toCapitalize(title)))])],1)],1)}),1)],1),_c('v-slide-x-reverse-transition',[(_vm.showReset)?_c('organism-users-reset',{on:{"closed":function($event){_vm.showReset = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }