const collection = 'Areas'
const initial = () => ({
  data: null,
  total: 0,
  defaultPipeline: [
    {
      $lookup: {
        from: 'Clients',
        localField: 'clientId',
        foreignField: '_id',
        as: 'client',
      },
    },
    {
      $unwind: {
        path: '$client',
        preserveNullAndEmptyArrays: true,
      },
    },

    {
      $lookup: {
        from: 'Locations',
        localField: '_id',
        foreignField: 'areaId',
        as: 'locations',
      },
    },
    {
      $addFields: {
        areas: '$$ROOT',
      },
    },
  ],
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async summary({ commit }, query) {
      await this._vm.$api
        .get(collection + '/getAreas', {
          params: {
            jsonQuery: JSON.stringify({
              pipeline: [
                {
                  $count: 'title',
                },
              ],
            }),
          },
        })
        .then(({ result }) => commit('SET', { total: result?.[0]?.title }))
    },
    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/getAreas', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [
                {
                  $lookup: {
                    from: 'Clients',
                    localField: 'clientId',
                    foreignField: '_id',
                    as: 'client',
                  },
                },
                {
                  $unwind: {
                    path: '$client',
                    preserveNullAndEmptyArrays: true,
                  },
                },
              ],
              ...payload?.custom,
            }),
            page: payload?.page || 1,
            limit: payload?.limit || state.limit,
          },
        })
        .then(({ result, ...rest }) => {
          if (!payload?.returnOnly) {
            commit('SET', {
              data: result,
              ...rest,
            })
          }
          return {
            data: result,
            ...rest,
          }
        })
    },
    async register({ commit }, payload) {
      // {title, clientID}
      return await this._vm.$api
        .post(collection + '/createArea', payload)
        .then(({ success, ...rest }) => {
          return success
        })
        .catch((err) => Promise.reject(err))
    },
    async edit({ commit }, payload) {
      // {_id, title}
      return await this._vm.$api
        .put(collection + '/setAreaTitle', payload)
        .then(({ success }) => success)
        .catch((err) => Promise.reject(err))
    },
    async remove({ commit }, _id) {
      return await this._vm.$api
        .delete(collection + '/removeArea', {
          params: { _id },
        })
        .catch((err) => Promise.reject(err))
    },
    async checkArea({ state }, payload) {
      return await this._vm.$api
        .get(collection + '/getAreas', {
          params: {
            jsonQuery: JSON.stringify({
              //   areaId,
              title: { $regex: payload.areaTitle || '', $options: 'i' },
              clientId: payload.clientId,
            }),
          },
        })
        .then(({ result }) => (result.length > 0 ? true : false))
        .catch((err) => Promise.reject(err))
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
