<template>
  <molecules-floating>
    <molecules-custom-dialog
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Periksa kembali sebelum menghapus`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showRemove = null),
        },
        {
          title: 'Ya, Lanjutkan',
          override: 'red--text',
          full: false,
          exec: () => {
            removeItems(showRemove)
          },
        },
      ]"
    />
    <molecules-custom-dialog
      :show="showRemovePayment"
      title="Apakah Anda Yakin?"
      :caption="`Untuk konfirmasi, masukkan nominal harga dari pembayaran yang akan dihapus (${
        (showRemovePayment && $idCurrency(showRemovePayment.payAmount)) || 0
      })`"
      :width="500"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showRemovePayment = null),
        },
        {
          title: 'Iya, hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            if (showRemovePayment) {
              const amount = Number(showRemovePayment.payAmount)
              if (showRemovePayment.payAmount && amount && amount === $removeSeparator(payValidator)) {
                removePayment(showRemovePayment)
                showRemovePayment = null
              } else {
                $showDialog({
                  title: 'Kesalahan',
                  body: `Masukkan nominal dengan benar`,
                })
              }
            }
            payValidator = ''
          },
        },
      ]"
    >
      <v-text-field
        prefix="Rp."
        background-color="accent"
        class="rounded-lg px-0"
        placeholder="Harga Pembayaran..."
        v-model="payValidator"
        hide-details
        solo
        flat
    /></molecules-custom-dialog>

    <molecules-custom-dialog
      :show="showRemovePriceReduction"
      title="Apakah Anda Yakin?"
      :caption="`Anda akan menghapus item pengurangan pada invoice. (Submit untuk menyimpan perubahan!)`"
      :width="500"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showRemovePriceReduction = null),
        },
        {
          title: 'Iya, hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            if (showRemovePriceReduction) {
              datas.priceReduction = datas.priceReduction.filter((x) => x._id !== showRemovePriceReduction._id)
              reCountTotalOffer()
              showRemovePriceReduction = null
            }
          },
        },
      ]"
    />
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container class="px-md-8">
        <v-container class="px-md-8">
          <v-form ref="formDP" v-model="dpValid" @submit.prevent="submitDpPayment">
            <v-row class="text-left">
              <v-col cols="12" class="text-center py-0">
                <atoms-title :h2="$vuetify.breakpoint.smAndDown ? true : false">Pembayaran {{ invoiceNo }}</atoms-title>
                <atoms-text>Tipe Pesanan: {{ datas ? datas.type : '-' }}</atoms-text>
              </v-col>
              <v-col cols="12">
                <atoms-title :h3="true">Pembayaran DP</atoms-title>
                <atoms-text>Nominal : {{ $idCurrency(datas && datas.downpayment ? datas.downpayment : 0) }}</atoms-text>
              </v-col>
              <v-col cols="12">
                <atoms-text-field
                  :label="`DP : ${$idCurrency(datas && datas.downpayment ? datas.downpayment : 0)}`"
                  :noLabel="true"
                  v-model="downpayment"
                  prefix="Rp.
            "
                />
              </v-col>
              <v-col cols="12">
                <v-dialog
                  ref="pickerPaymentDialog"
                  v-model="dpPaymentDate.modal"
                  :return-value.sync="dpPaymentDate.value"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Tanggal Pembayaran DP" :rules="[]">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Tanggal Pembayaran DP"
                        append-icon="mdi-calendar"
                        color="accent"
                        v-model="formatPaymentDate"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        dense
                        solo
                        flat
                        :rules="[(v) => !!v || 'Harap Diisi!']"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    v-model="dpPaymentDate.value"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerPaymentDialog.save(dpPaymentDate.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col cols="12" align="center" class="pt-0">
                <atoms-image
                  v-if="photo && photo.url"
                  class="rounded-lg"
                  :contain="true"
                  :enableZoom="true"
                  :height="250"
                  :src="photo.url"
                />
                <input ref="photo" type="file" accept="image/*" class="d-none" @change="onPhotoChange" />
              </v-col>
              <v-col cols="12" align="center">
                <atoms-button
                  @click="$refs.photo.click()"
                  style="width: 100%"
                  class="rounded-lg primary--text primary lighten-5"
                  >Upload Bukti Pembayaran (Max.3Mb) <v-icon right>mdi-camera</v-icon></atoms-button
                >
                <atoms-button
                  v-if="photo && photo.url"
                  @click="
                    () => {
                      photo = null
                      submitDpPayment()
                    }
                  "
                  style="width: 100%"
                  class="rounded-lg red--text red lighten-5 mt-2"
                  >Hapus Bukti Pembayaran</atoms-button
                >
                <div class="pa-2" />
                <atoms-button type="submit" style="width: 100%" class="primary"> Submit Pembayaran</atoms-button>
              </v-col>
            </v-row>
          </v-form>

          <div v-if="invoiceData && datas">
            <v-row justify="center">
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="pickerCurrentDialog"
                  v-model="current.modal"
                  :return-value.sync="current.value"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Tanggal" :rules="[]">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Tanggal"
                        append-icon="mdi-calendar"
                        color="accent"
                        v-model="formatCurrent"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    v-model="current.value"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerCurrentDialog.save(current.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog ref="pickerDueDialog" v-model="due.modal" :return-value.sync="due.value" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Batas Tanggal" :rules="[]">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Batas Tanggal"
                        append-icon="mdi-calendar"
                        color="accent"
                        v-model="formatDueDate"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    v-model="due.value"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerDueDialog.save(due.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col
                class="d-md-flex align-center justify-space-between py-0"
                :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
              >
                <div class="d-flex align-md-center flex-column flex-md-row">
                  <div class="my-4 mx-0 my-md-0 mr-md-4">
                    <atoms-button
                      @click="showAddOne = true"
                      :style="{
                        width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
                      }"
                      class="primary mb-2 mb-md-0"
                      ><v-icon class="mr-2">mdi-plus-box</v-icon>Tambah Pesanan
                    </atoms-button>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div class="d-flex align-center justify-center">
                    <v-checkbox v-model="datas.isActivePpn" hide-spin-buttons />
                  </div>
                  <v-label><atoms-text class="pa-2 font-weight-bold">Aktifkan PPN : </atoms-text></v-label>
                  <atoms-text-field
                    :disabled="!datas.isActivePpn || !datas.ppnPercentage"
                    label="Persentase PPN"
                    :noLabel="true"
                    v-model="datas.ppnPercentage"
                    color="accent"
                  />
                </div>
                <div class="d-flex align-center">
                  <div class="d-flex align-center justify-center">
                    <v-checkbox v-model="datas.isActivePph" hide-spin-buttons />
                  </div>
                  <v-label><atoms-text class="pa-2 font-weight-bold">Aktifkan PPH : </atoms-text></v-label>
                  <atoms-text-field
                    :disabled="!datas.isActivePph || !datas.pphPercentage"
                    label="Persentase PPH"
                    :noLabel="true"
                    v-model="datas.pphPercentage"
                    color="accent"
                  />
                </div>

                <div class="d-flex">
                  <div class="d-flex align-center">
                    <div class="d-flex align-center justify-center">
                      <v-checkbox v-model="datas.isUseSLA" hide-spin-buttons />
                    </div>
                    <v-label><atoms-text class="pa-2 font-weight-bold">Aktifkan SLA </atoms-text></v-label>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="d-flex align-center">
                    <div class="d-flex align-center justify-center">
                      <v-checkbox v-model="datas.pphDitanggungHK" hide-spin-buttons />
                    </div>
                    <v-label><atoms-text class="pa-2 font-weight-bold">PPH ditanggung HK </atoms-text></v-label>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="[
                    {
                      text: 'Judul',
                      sortable: false,
                      value: 'title',
                    },
                    {
                      text: 'Tanggal Mulai',
                      sortable: false,
                      value: 'startDate',
                    },
                    {
                      text: 'Tanggal Selesai',
                      sortable: false,
                      value: 'endDate',
                    },
                    {
                      text: 'Area',
                      sortable: false,
                      value: 'areaTitle',
                    },
                    {
                      text: 'Gudang / Lokasi',
                      sortable: false,
                      value: 'location',
                    },
                    {
                      text: 'Jumlah Pekerja',
                      sortable: false,
                      value: 'amount',
                    },
                    {
                      text: 'Jumlah Hari',
                      sortable: false,
                      value: 'quantity',
                    },
                    {
                      text: 'Harga Modal',
                      sortable: false,
                      value: 'priceBase',
                    },
                    {
                      text: 'Fee',
                      sortable: false,
                      value: 'fee',
                    },
                    {
                      text: 'Harga Penawaran ',
                      sortable: false,
                      value: 'priceProposed',
                    },
                    {
                      text: 'Total Modal',
                      sortable: false,
                      value: 'totalBase',
                    },
                    {
                      text: 'Total Fee',
                      sortable: false,
                      value: 'totalFee',
                    },
                    {
                      text: 'Total Penawaran ',
                      sortable: false,
                      value: 'totalProposed',
                    },
                    {
                      text: 'SLA(%)',
                      sortable: false,
                      value: 'slaPercentage',
                    },
                    {
                      text: 'Edit',
                      sortable: false,
                      value: 'action',
                    },
                    {
                      text: 'Hapus',
                      sortable: false,
                      value: 'action2',
                    },
                  ]"
                  :items="datas.items || []"
                  class="rounded-lg accent"
                  hide-default-footer
                  hide-default-header
                  disable-pagination
                  mobile-breakpoint="0"
                >
                  <template v-slot:[`header`]="{ props }">
                    <thead>
                      <tr class="d-table-row">
                        <th v-for="h in props.headers" :key="h.value" :class="['text-center', h.class]">
                          <span class="primary--text text-uppercase" v-html="h.text" />
                        </th>
                      </tr></thead
                  ></template>

                  <template v-slot:[`item`]="{ item }">
                    <tr class="d-table-row text-center">
                      <td>{{ item.title }}</td>
                      <td>
                        {{ item.startDate && $moment(item.startDate).format('DD MMMM yyyy') }}
                      </td>
                      <td>
                        {{ item.endDate && $moment(item.endDate).format('DD MMMM yyyy') }}
                      </td>
                      <td>{{ item.areaTitle }}</td>
                      <td>{{ item.location?.name || item.location }}</td>
                      <td>{{ item.amount }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>
                        {{ $idCurrency(String(item.priceBase)) }}
                      </td>
                      <td>
                        {{ $idCurrency(String(item.fee)) }}
                      </td>
                      <td>
                        {{ $idCurrency(String(item.priceProposed)) }}
                      </td>
                      <td>
                        {{ $idCurrency(String(item.totalBase)) }}
                      </td>
                      <td>
                        {{ $idCurrency(String(item.totalFee)) }}
                      </td>
                      <td>
                        {{ $idCurrency(String(item.totalProposed)) }}
                      </td>
                      <td>
                        {{ Boolean(datas.isUseSLA) ? $convertDecimaltoPercent(item.slaPercentage) : '-' }}
                      </td>
                      <td>
                        <v-icon
                          @click="
                            () => {
                              editableId = item._id
                              showAddOne = true
                            }
                          "
                          small
                          class="mx-2"
                        >
                          mdi-pencil
                        </v-icon>
                      </td>
                      <td>
                        <v-icon @click="showRemove = item" small class="mx-2"> mdi-delete </v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col cols="12" :align="'left'">
                <atoms-text-field
                  v-model="fakturPajak"
                  class="rounded-lg"
                  color="accent"
                  mask="###.###-##.########"
                  :style="$vuetify.breakpoint.lgAndDown && 'width:100%'"
                  label="Faktur Pajak"
                  hide-details
                  solo
                  flat
                  dense
                />
              </v-col>
              <v-col cols="12" :align="'left'">
                <input
                  ref="proofOfFaktur"
                  type="file"
                  accept="image/*, application/pdf"
                  class="d-none"
                  @change="onFakturChange"
                />
                <atoms-text
                  v-if="fakturProof"
                  :href="typeof fakturProof == 'object' ? fakturProof.url : fakturProof"
                  class="mb-2 primary--text text-decoration-underline"
                  >Lihat Bukti
                </atoms-text>
                <atoms-button @click="$refs.proofOfFaktur.click()" style="width: 100%" class="rounded-lg orange"
                  >Upload Faktur Pajak (Max.3Mb) <v-icon right>mdi-camera</v-icon></atoms-button
                >
              </v-col>

              <!-- exception -->
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col class="d-xl-flex align-center justify-space-between" :align="'left'">
                <div class="text-center">
                  <atoms-text class="font-weight-bold mb-4 mb-md-0">Pengurangan Tagihan (Bila Ada)</atoms-text>
                </div>
                <div class="d-flex flex-column flex-xl-row justify-center align-left">
                  <v-label><atoms-text class="pa-2 font-weight-bold">Keterangan : </atoms-text></v-label>
                  <v-text-field
                    v-model="exception.title"
                    class="rounded-lg"
                    color="accent"
                    :style="$vuetify.breakpoint.lgAndDown && 'width:100%'"
                    placeholder="Keterangan"
                    hide-details
                    solo
                    flat
                    dense
                  />
                  <v-label><atoms-text class="pa-2 font-weight-bold">Nominal : </atoms-text></v-label>
                  <v-text-field
                    dense
                    class="rounded-lg"
                    color="accent"
                    :style="$vuetify.breakpoint.lgAndDown && 'width:100%'"
                    placeholder="Pengurangan Nominal"
                    v-model="exception.price"
                    prefix="Rp."
                    hide-details
                    solo
                    flat
                  />
                  <atoms-button
                    @click="
                      () => {
                        if (exception.price && Number(exception.price.replace('.', '')) > 0 && exception.title) {
                          datas.priceReduction.push({
                            ...exception,
                            _id: $uuid.v4(),
                            price: exception.price.replace('.', ''),
                          })
                          reCountTotalOffer()
                        } else {
                          $store.commit('dialog/show', {
                            title: 'Perhatian',
                            body: 'Pastikan kolom keterangan dan nominal pengurangan terisi!',
                          })
                        }
                        exception = {}
                      }
                    "
                    :style="{
                      width: $vuetify.breakpoint.lgAndDown ? '100%' : 'auto',
                    }"
                    class="primary px-xl-8 ml-xl-4 mt-4 mt-xl-0"
                    ><v-icon left>mdi-plus-box</v-icon>Pengurangan</atoms-button
                  >
                </div>
              </v-col>
              <v-col v-show="datas.priceReduction && datas.priceReduction.length > 0" cols="12">
                <v-data-table
                  :headers="[
                    {
                      text: 'Keterangan',
                      sortable: false,
                      value: 'title',
                    },
                    {
                      text: 'Nominal',
                      sortable: false,
                      value: 'price',
                    },
                    {
                      text: 'Aksi',
                      sortable: false,
                      value: 'action',
                    },
                  ]"
                  :items="datas.priceReduction"
                  class="rounded-lg accent"
                  hide-default-footer
                  hide-default-header
                >
                  <template v-slot:[`header`]="{ props }">
                    <thead>
                      <tr class="d-table-row">
                        <th v-for="h in props.headers" :key="h.value" :class="['text-center', h.class]">
                          <span class="primary--text text-uppercase">{{ h.text }}</span>
                        </th>
                      </tr>
                    </thead></template
                  >

                  <template v-slot:[`item`]="{ item }">
                    <tr class="d-table-row text-center">
                      <td>{{ item.title }}</td>
                      <td>
                        {{ $idCurrency(String(item.price)) }}
                      </td>
                      <td>
                        <!-- totalOffer += Number(item.nominal); -->
                        <v-icon @click="showRemovePriceReduction = item" light small> mdi-delete </v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="printVersion"
                  :items="[
                    {
                      label: 'Versi 1 (PPN/PPH berdasarkan Platform Fee)',
                      value: 1,
                    },
                    {
                      label: 'Versi 2 (PPN/PPH berdasarkan Total Realisasi)',
                      value: 2,
                    },
                  ]"
                  item-text="label"
                  item-value="value"
                  :menu-props="{ offsetY: true }"
                  label="Print Version"
                  class="rounded-lg accent"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
            </v-row>

            <!-- caps -->
            <v-row>
              <v-col align="end" class="text-right">
                <atoms-title
                  :h2="true"
                  v-for="i in ['Total Modal', 'Platform Fee', 'Total Realisasi', 'PPN', 'PPH']"
                  :key="i"
                  >{{ i }}</atoms-title
                >
              </v-col>

              <v-col align="start" class="shrink">
                <atoms-title :h2="true" v-for="i in 5" :key="i">:</atoms-title>
              </v-col>
              <v-col align="end" class="shrink">
                <atoms-title :h2="true" v-for="i in 5" :key="i">Rp.</atoms-title>
              </v-col>
              <v-col align="end" class="shrink text-right">
                <atoms-title
                  :h2="true"
                  v-for="(x, i) in [
                    $idCurrency(datas.totalFund, 2, 2, true),
                    $idCurrency(datas.profit, 2, 2, true),
                    $idCurrency(datas.pureTotalOffer, 2, 2, true),
                    $idCurrency(datas.ppnValue, 2, 2, true),
                    $idCurrency(datas.pphValue, 2, 2, true),
                  ]"
                  :key="i"
                  >{{ x.replace('Rp', '') }}</atoms-title
                >
              </v-col>
              <v-col cols="12">
                <atoms-button
                  @click="submitInvoice(`/cetak-invoice?type=invoice&no=${datas._id}`)"
                  style="width: 100%"
                  class="primary"
                >
                  Submit Invoice
                </atoms-button>
                <atoms-button
                  v-if="$isNumber(datas.balanceInvoice)"
                  link
                  @click="submitInvoice(`/cetak-invoice?type=invoice&no=${datas._id}`)"
                  :title="
                    !datas.balanceInvoice
                      ? 'Submit Invoice terlebih dahulu di halaman edit invoice agar bisa printout invoice'
                      : 'Ingat untuk submit Invoice untuk mendapat printout terbaru dari invoice'
                  "
                  style="width: 100%"
                  class="grey darken-3 white--text mt-3"
                >
                  <v-icon>mdi-printer</v-icon>
                  Print Invoice
                </atoms-button>
                <atoms-button
                  v-if="$isRoles(['Super Admin'])"
                  style="float: right"
                  type="submit"
                  class="red darken-2 white--text mb-2 mb-md-0 mt-3"
                  @click.prevent="deleteInvoice"
                  ><v-icon class="mr-2">mdi-delete</v-icon> Hapus Invoice</atoms-button
                >
              </v-col>
            </v-row>

            <v-slide-x-reverse-transition>
              <organism-quotation-items
                v-if="showAddOne"
                :type="datas.type === $inputType.sprinter ? true : false"
                :withSLA="true"
                :datas="
                  editableId
                    ? datas.items.find((x) => x._id === editableId) && {
                        ...datas.items.find((x) => x._id === editableId),
                        isUseSLA: datas.isUseSLA || false,
                      }
                    : {}
                "
                :client="datas.client"
                @closed="onQuoItemsClosed"
              />
            </v-slide-x-reverse-transition>
          </div>

          <div v-if="datas && datas.balanceInvoice != undefined">
            <!-- validator -->

            <v-divider class="my-8" />
            <v-form ref="formPayment" v-model="valid" @submit.prevent="preSubmitPayment">
              <v-row>
                <v-col
                  cols="12"
                  class="d-md-flex align-center justify-space-between"
                  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
                >
                  <div>
                    <atoms-title :h3="true" class="font-weight-bold mb-4 mb-md-0">Pembayaran Invoice</atoms-title>
                    <atoms-text
                      >Sisa Pembayaran
                      {{ $idCurrency(datas.balance ? datas.balance : 0) }}
                      dari Total
                      {{ $idCurrency(datas.balanceInvoice ? datas.balanceInvoice : 0) }}
                    </atoms-text>
                  </div>
                  <div>
                    <v-icon
                      left
                      style="cursor: pointer"
                      @click="
                        $showDialog({
                          title: 'Informasi',
                          body: `Pastikan  telah mengunggah bukti pembayaran dan terdapat sisa pembayaran (sisa pembayaran tidak minus dan nol) untuk mengaktifkan tombol \'Bayar Lunas\'`,
                        })
                      "
                      >mdi-information</v-icon
                    >
                    <atoms-button
                      @click="
                        () => {
                          switchPaidOff()
                        }
                      "
                      class="primary mt-4 mt-md-0"
                      >{{ !datas.paidOff ? 'Jadikan Lunas' : 'Batalkan Lunas' }}</atoms-button
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <v-dialog
                    ref="pickerPayDialog"
                    v-model="payDate.modal"
                    :return-value.sync="payDate.value"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <atoms-text-field label="Tanggal">
                        <v-text-field
                          background-color="accent"
                          class="rounded-lg"
                          v-model="formatPayDate"
                          v-bind="attrs"
                          v-on="on"
                          dense
                          hide-details
                          readonly
                          solo
                          flat
                      /></atoms-text-field>
                    </template>
                    <v-date-picker
                      v-model="payDate.value"
                      scrollable
                      class="rounded-lg"
                      @click:date="$refs.pickerPayDialog.save(payDate.value)"
                    />
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6">
                  <atoms-text-field label="Jumlah Bayar" prefix="Rp." v-model="payAmount" :rules="[]" />
                </v-col>
                <v-col cols="12" align="center" class="pt-0">
                  <atoms-image
                    v-if="proofOfPayment && proofOfPayment.url"
                    class="rounded-lg"
                    :enableZoom="true"
                    :contain="true"
                    height="250"
                    :src="proofOfPayment.url"
                  />
                  <input
                    ref="proofOfPayment"
                    type="file"
                    accept="image/*"
                    class="d-none"
                    @change="onProofOfPaymentChange"
                  />
                </v-col>
                <v-col cols="12" align="center">
                  <atoms-button
                    @click="$refs.proofOfPayment.click()"
                    style="width: 100%"
                    class="rounded-lg green--text green lighten-5"
                    >Upload Bukti Pembayaran (Max.3Mb) <v-icon right>mdi-camera</v-icon></atoms-button
                  >

                  <div class="pa-2" />
                  <atoms-button
                    :disabled="Boolean(datas.balance === 0)"
                    type="submit"
                    style="width: 100%"
                    class="green darken-2 white--text mr-md-4 mb-2 mb-md-0"
                  >
                    Proses Pembayaran</atoms-button
                  >
                </v-col>
                <v-col v-show="datas.paymentProcess && datas.paymentProcess.length > 0" cols="12">
                  <v-data-table
                    :headers="[
                      {
                        text: 'Bukti Pembayaran',
                        width: '1%',
                      },
                      {
                        text: 'Pembayaran',
                      },
                      {
                        text: 'Tanggal Pembayaran',
                      },
                      {
                        text: 'Aksi',
                      },
                    ]"
                    disable-sort
                    :items="datas.paymentProcess || []"
                    class="accent rounded-lg"
                    hide-default-footer
                    hide-default-header
                    mobile-breakpoint="0"
                  >
                    <template v-slot:[`header`]="{ props }">
                      <thead>
                        <tr class="d-table-row">
                          <th v-for="h in props.headers" :key="h.value" :class="['text-center', h.class]">
                            <span class="primary--text text-uppercase">{{ h.text }}</span>
                          </th>
                        </tr>
                      </thead></template
                    >

                    <template v-slot:[`item`]="{ item }">
                      <tr class="d-table-row text-center">
                        <td>
                          <atoms-image
                            :src="item.proofOfPayment || './lazy-img.webp'"
                            :enableZoom="true"
                            :contain="true"
                            height="150"
                            class="my-2"
                          />
                        </td>
                        <td>
                          {{ $idCurrency(String(item.payAmount)) }}
                        </td>
                        <td>
                          {{ $moment(item.date).format('dddd, DD MMMM YYYY') }}
                        </td>
                        <td>
                          <!-- totalOffer += Number(item.nominal); -->
                          <v-icon
                            v-if="datas.paymentProcess && datas.paymentProcess.indexOf(item) === 0"
                            @click="showRemovePayment = item"
                            light
                            small
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-container>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      valid: true,
      dpValid: true,
      showHelper: true,
      showRemove: null,
      showRemovePayment: null,
      quotationData: null,
      datas: {},
      photo: null,
      downpayment: '',
      dpPercentage: 1,
      nomorQuo: '',
      fakturPajak: null,
      fakturProof: null,
      dpPaymentDate: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      printVersion: 2,
      // items
      showAddOne: false,
      editableId: '',
      exception: {},
      current: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      due: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      // payment
      showRemovePriceReduction: null,
      invoiceData: null,
      proofOfPayment: null,
      payAmount: '',
      payAmountDummy: '',
      payValidator: '',
      payments: [],
      payDate: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
    }
  },
  watch: {
    'datas.isActivePph': {
      handler: function () {
        this.$nextTick(() => this.reCountTotalOffer())
      },
      deep: true,
    },
    'datas.pphPercentage': {
      handler: function () {
        this.$nextTick(() => this.reCountTotalOffer())
      },
      deep: true,
    },
    'datas.isActivePpn': {
      handler: function () {
        this.$nextTick(() => this.reCountTotalOffer())
      },
      deep: true,
    },
    'datas.ppnPercentage': {
      handler: function () {
        this.$nextTick(() => this.reCountTotalOffer())
      },
      deep: true,
    },
    'datas.priceReduction': {
      handler() {
        this.reCountTotalOffer()
      },
      deep: true,
    },
    'datas.items': {
      handler() {
        this.reCountTotalOffer()
      },
      deep: true,
    },
    printVersion: {
      handler() {
        this.reCountTotalOffer()
      },
      deep: true,
    },
    downpayment: function (newValue) {
      const result = this.$separator(newValue) || '0'
      this.$nextTick(() => (this.downpayment = result))
    },
    payAmount: function (newValue) {
      let result = this.$separator(newValue) || '0'
      if (String(newValue)?.includes('-')) {
        result = '-' + result
      }
      this.$nextTick(() => (this.payAmount = result))
    },
    // payAmount: function (newValue) {
    //   console.log(newValue)
    //   const result = this.$separator(newValue) || "0"
    //   this.$nextTick(() => (this.payAmount = result))
    // },
    payValidator: function (newValue) {
      const result = this.$separator(newValue) || '0'
      this.$nextTick(() => (this.payValidator = result))
    },
    'datas.isUseSLA': {
      handler: function () {
        if (this.datas?.isUseSLA) {
          this.datas.items = this.datas?.items?.map((x) => ({
            ...x,
            slaPercentage: x?.slaPercentage || 1,
            totalBase: x.amount * x.quantity * x.priceBase * x.slaPercentage,
            pureTotalProposed: x.amount * x.quantity * x.purePriceProposed * x.slaPercentage,
            totalProposed: x.amount * x.quantity * x.priceProposed * x.slaPercentage,
          }))
        } else {
          this.datas.items = this.datas?.items?.map((x) => ({
            ...x,
            totalBase: x.amount * x.quantity * x.priceBase,
            pureTotalProposed: x.amount * x.quantity * x.purePriceProposed,
            totalProposed: x.amount * x.quantity * x.priceProposed,
          }))
        }
        this.$nextTick(() => this.reCountTotalOffer())
      },
      deep: true,
    },
  },
  computed: {
    invoiceNo() {
      return this.datas?.no?.replace('/QUO/', '/INV/').replace('/QUOT/', '/INV/')
    },
    formatPayDate: {
      set(value) {
        this.payDate.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.payDate.value).format('DD MMMM YYYY')
      },
    },
    formatPaymentDate: {
      set(value) {
        this.dpPaymentDate.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.dpPaymentDate.value).format('DD MMMM YYYY')
      },
    },
    formatCurrent: {
      set(value) {
        this.current.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.current.value).format('DD MMMM YYYY')
      },
    },
    formatDueDate: {
      set(value) {
        this.due.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.due.value).format('DD MMMM YYYY')
      },
    },
  },
  async mounted() {
    await this.refreshData()
  },
  errorCaptured() {
    //this.$emit("closed");
  },
  methods: {
    async removeItems(_item) {
      this.$store.commit('SET', { loading: true })
      try {
        const isShiftExist = await this.$api.get(`Absensi/checkShift/${_item._id}`).then((res) => res.result)

        if (isShiftExist === false) {
          this.datas.items = this.datas.items?.filter((x) => x._id !== _item._id)
          this.reCountTotalOffer()
        } else {
          throw new Error('Shift ini telah dipakai absensi, tidak bisa dihapus!')
        }
      } catch (err) {
        this.$store.commit('dialog/show', {
          title: 'Perhatian',
          body:
            err?.response?.data?.message ||
            err?.data?.message ||
            err?.message ||
            'Terdapat gangguan server, coba lagi nanti',
        })
        Promise.reject(err)
      } finally {
        this.showRemove = null
      }
      this.$store.commit('SET', { loading: false })
    },
    async onFakturChange(e) {
      const file = e.target.files[0]
      if (file?.size > 3000000) {
        return this.$showDialog({
          title: 'Gagal',
          body: `Bukti faktur maksimal berukuran 3Mb`,
        })
      } else if (!file.type?.includes('pdf') && !file.type?.includes('image')) {
        return this.$showDialog({
          title: 'Gagal',
          body: `Bukti faktur setidaknya dalam format gambar atau file pdf`,
        })
      } else if (file) {
        this.fakturProof = {
          url: URL.createObjectURL(file),
          img: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.fakturProof = null
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file?.size > 3000000) {
        return this.$showDialog({
          title: 'Gagal',
          body: `Bukti faktur maksimal berukuran 3Mb`,
        })
      } else if (file) {
        this.photo = {
          url: URL.createObjectURL(file),
          img: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.photo = null
      }
    },
    async onProofOfPaymentChange(e) {
      const file = e.target.files[0]
      if (file.size > 3000000) {
        return this.$showDialog({
          title: 'Gagal',
          body: `Bukti pembayaran maksimal berukuran 3Mb`,
        })
      }
      if (file) {
        this.proofOfPayment = {
          url: URL.createObjectURL(file),
          img: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.proofOfPayment = null
      }
    },
    async onQuoItemsClosed(payload) {
      let check = payload && this.datas.items.find((x) => x._id === payload._id)
      if (payload && check) {
        this.datas.items = [...this.datas.items.filter((x) => x._id !== check._id), payload]
      } else {
        payload && this.datas.items.push(payload)
      }
      this.showAddOne = false
      this.editableId = ''
    },
    async switchPaidOff() {
      this.$store.commit('SET', { loading: true })
      try {
        const send = {
          _id: this.datas?._id,
          quotationId: this.quotationData?._id,
          paidOff: !this.datas?.paidOff || false,
        }
        await this.$store.dispatch('invoice/save', send)
        this.refreshData()
      } catch (err) {
        this.$swal(err?.response?.data?.message || err?.message, '', 'error')
      }
      this.$store.commit('SET', { loading: false })
    },
    reCountTotalOffer() {
      if (this.datas?.items?.length > 0) {
        // calc total offer
        this.datas.totalOffer = this.datas?.items
          .map((x) => Number(x.totalProposed || 0))
          .reduce((total, item) => total + item, 0)

        this.datas.totalFund = this.datas?.items
          .map((x) => Number(x.totalBase || 0))
          .reduce((total, item) => total + item, 0)

        const totalFee = this.datas?.items
          .map((x) => Number((+x?.fee || 0) * (x?.amount || 0) * (x?.quantity || 0)))
          ?.reduce((total, item) => total + item, 0)

        this.datas.pureTotalOffer = Number((+this.datas.totalFund || 0) + (+totalFee || 0))

        this.datas.profit = Number(this.datas?.pureTotalOffer || 0) - Number(this.datas?.totalFund || 0)
        this.datas.marginPercentage = Number(this.datas.profit / this.datas.pureTotalOffer)
        // calc taxes
        const valueGI = Number(this.datas.profit)
        if (this.datas.isActivePpn) {
          if (this.printVersion === 2) {
            this.datas.ppnValue = Number(
              Math.floor(this.$convertPercenttoDecimal(this.datas.ppnPercentage) * this.datas.pureTotalOffer),
            )
          } else {
            this.datas.ppnValue = Number(Math.floor(this.$convertPercenttoDecimal(this.datas.ppnPercentage) * valueGI))
          }
        } else {
          this.datas.ppnValue = 0
        }
        if (this.datas.isActivePph) {
          if (this.printVersion === 2) {
            this.datas.pphValue = Number(
              Math.floor(this.$convertPercenttoDecimal(this.datas.pphPercentage) * this.datas.pureTotalOffer),
            )
          } else {
            this.datas.pphValue = Number(Math.floor(this.$convertPercenttoDecimal(this.datas.pphPercentage) * valueGI))
          }
        } else {
          this.datas.pphValue = 0
        }
        // calc total offer after taxes and price reduction
        const totalPriceReduction = this.datas.priceReduction.reduce(
          (total, item) => Number(total + (+item?.price || 0)),
          0,
        )
        // this.datas.totalOffer = this.datas?.items
        //   .map((x) => Number(x.totalProposed || 0))
        //   .reduce((past, current) => past + current)
        // this.datas.totalFund = this.datas?.items
        //   .map((x) => Number(x.totalBase || 0))
        //   .reduce((past, current) => past + current)
        // this.datas.pureTotalOffer = this.datas?.items
        //   .map((x) => Number(x.pureTotalProposed || 0))
        //   .reduce((past, current) => past + current)
        // this.datas.profit =
        //   Number(this.datas?.pureTotalOffer || 0) -
        //   Number(this.datas?.totalFund || 0)
        // this.datas.marginPercentage =
        //   this.datas.profit / this.datas.pureTotalOffer
        // // calc taxes
        // const valueGI = this.datas.profit
        // if (this.datas.isActivePpn) {
        //   this.datas.ppnValue = Math.floor(
        //     this.$convertPercenttoDecimal(this.datas.ppnPercentage) * valueGI
        //   )
        // } else {
        //   this.datas.ppnValue = 0
        // }
        // if (this.datas.isActivePph) {
        //   this.datas.pphValue = Math.floor(
        //     this.$convertPercenttoDecimal(this.datas.pphPercentage) * valueGI
        //   )
        // } else {
        //   this.datas.pphValue = 0
        // }
        // // calc total offer after taxes and price reduction
        // const totalPriceReduction = this.datas.priceReduction.reduce(
        //   (total, item) => total + +(item?.price || 0),
        //   0
        // )
        this.datas.totalOffer =
          Number(this.datas.pureTotalOffer) +
          (+this.datas?.ppnValue || 0) -
          (+this.datas?.pphValue || 0) -
          totalPriceReduction
      }
    },
    async refreshData() {
      this.$store.commit('SET', { loading: true })
      try {
        this.quotationData =
          (this.id &&
            (
              await this.$store.dispatch('quotation/get', {
                query: { _id: this.id },
                limit: 1,
                dont_set: true,
              })
            )?.[0]) ||
          {}
        this.invoiceData =
          this.id &&
          (
            await this.$store.dispatch('invoice/get', {
              query: { _id: this.id },
              limit: 1,
              dont_set: true,
            })
          )?.[0]
        if (this.id && this.quotationData) {
          this.datas = { ...this.quotationData, ...this.invoiceData }
          this.datas.items = this.quotationData?.items?.map((x) => ({
            ...x,
            ...(this.datas?.items?.find((it) => it?.quoItemId === x?._id) || {}),
          }))
          this.datas.ppnPercentage = this.$convertDecimaltoPercent(this.datas.ppnPercentage)
          this.datas.pphPercentage = this.$convertDecimaltoPercent(this.datas.pphPercentage)
          this.datas.isUseSLA = this.datas?.isUseSLA || false
          this.fakturPajak = this.datas?.fakturPajak
          this.fakturProof = this.datas?.fakturProof

          // console.log(this.fakturPajak, this.fakturProof)
          this.downpayment = String(this.$convertRupiahtoNumber(this.$idCurrency(this.datas?.downpayment)))
          this.photo = { url: this.datas?.dpProof }
          this.formatCurrent = this.datas?.date || new Date()
          this.formatDueDate = this.datas?.dueDate || this.datas?.date || new Date()
          if (!this.datas?._id) {
            this.$emit('closed')
          }
          if (!this.datas?.date || !this.datas?.dueDate) {
            this.$showDialog({
              title: 'Perhatian',
              body: `Terdapat kesalahan pada tanggal, silahkan cek kembali apakah sudah sesuai atau belum, kemudian simpan untuk membuat perubahan.`,
            })
          }
        }
      } catch (err) {
        this.$showDialog({
          title: 'Gagal',
          body: `Terdapat kesalahan ketika melakukan permintaan ${err}`,
        })
      }
      this.$store.commit('SET', { loading: false })
    },
    async submitDpPayment() {
      this.$store.commit('SET', { loading: true })
      await this.$refs.formDP.validate()
      const downpayment = Number(this.$removeSeparator(this.downpayment))
      try {
        // remove dp image this.photo.img && this.photo.url
        if (Boolean(this.dpValid)) {
          const user = this.$store.state.user._id
          const quotationId = this.quotationData?._id
          const sendData = {
            _id: quotationId,
            quotationId,
            downpayment,
            isDeleted: false,
            no: this.invoiceNo || '',
            dpPaymentDate: this.dpPaymentDate?.value,
            date: new Date(this.formatCurrent || this.datas?.date || this.quotationData?.date || new Date()),
            dueDate: new Date(this.formatDueDate || this.datas?.dueDate || this.quotationData?.dueDate || new Date()),
            dpSubmitBy: user,
            updatedBy: user,
          }

          if (this.photo?.img) {
            const dpProof = await this.$uploadFile(this.photo.img, `Quotation/${quotationId}`, `dpProof`)
            sendData.dpProof = dpProof
          } else if (this.photo?.url) {
            sendData.dpProof = this.photo.url
          } else {
            sendData.dpProof = null
          }

          // console.log('dpsubmit', sendData)

          await this.$store.dispatch('invoice/save', sendData)
          await Promise.all([this.refreshData()]).then(async () => {
            // console.log('on-refresh', this.datas)
            if (this.datas?.balanceInvoice) {
              await this.submitInvoice()
              this.$store.commit('SET', { loading: true })
            }
            this.$showDialog({
              title: 'Berhasil',
              body: `Pembayaran DP Berhasil `,
            })
          })
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body:
              !this.photo.img || !this.photo.url
                ? `Harap upload bukti pembayaran!`
                : `Periksa kembali kolom pengisian!`,
          })
        }
      } catch (err) {
        Promise.reject(err)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async submitInvoice(redirect) {
      this.$store.commit('SET', { loading: true })
      try {
        const userId = this.$store.state.user?._id
        let ppnPercentage = 0
        let ppnValue = 0
        if (this.datas?.isActivePpn) {
          ppnPercentage = this.$convertPercenttoDecimal(this.datas.ppnPercentage)
          ppnValue = this.datas.ppnValue
        }
        let pphPercentage = 0
        let pphValue = 0
        if (this.datas?.isActivePph) {
          pphPercentage = this.$convertPercenttoDecimal(this.datas.pphPercentage)
          pphValue = this.datas.pphValue
        }
        const downpayment = Number(this.$removeSeparator(this.datas.downpayment))
        // handle balance
        const totalPriceReduction = this.datas.priceReduction.reduce((total, item) => total + +(item?.price || 0), 0)
        const pureTotalOffer = this.datas.pureTotalOffer
        let balanceInvoice = pureTotalOffer + ppnValue - downpayment - totalPriceReduction
        if (!this.datas?.pphDitanggungHK) {
          balanceInvoice -= pphValue
        }
        this.datas.balanceInvoice = balanceInvoice
        // save invoice
        const invoice = {
          ...this.datas,
          no: this.invoiceNo,
          quotationId: this.quotationData._id,
          printVersion: this.printVersion,
          ppnPercentage,
          ppnValue,
          pphPercentage,
          pphValue,
          totalPR: totalPriceReduction,
          priceReduction: this.datas?.priceReduction || [],
          updatedBy: userId,
          date: this.current.value,
          dueDate: this.due.value,
          fakturPajak: this.fakturPajak,
          fakturProof: this.fakturProof?.img
            ? await this.$uploadFile(
                this.fakturProof.img,
                `Invoice/${this.invoiceNo}/Faktur`,
                `pajak|${this.$moment().format('DD-MMMM-YYYY')}|${+new Date()}`,
              )
            : this.fakturProof,
        }
        const savedInvoice = await this.$store.dispatch('invoice/save', invoice)
        const invoiceItems = this.datas?.items?.map((item) => ({
          ...item,
          invoiceId: savedInvoice?._id,
          quotationId: savedInvoice?.quotationId,
          quoItemId: item?._id,
          // startDate: this.$moment(
          //   new Date(item.startDate).setHours(0, 0, 0, 0)
          // ),
          // endDate: this.$moment(new Date(item.endDate).setHours(0, 0, 0, 0)),
        }))
        // console.log('submit-inv', savedInvoice, invoiceItems)
        if (invoiceItems) {
          await this.$store.dispatch('invoice/saveItems', invoiceItems).then((_items) => {
            // console.log('hasil', _items)
          })
        }
        if (redirect) {
          window.open(redirect, '_blank')
        }
        await this.refreshData()
        this.$emit('closed')
      } catch (err) {
        Promise.reject(err)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async preSubmitPayment() {
      this.$store.commit('SET', { loading: true })
      this.$refs.formPayment.validate()
      const amount = Number(this.$removeSeparator(this.payAmount))
      try {
        if (this.valid && this.proofOfPayment && ((this.datas.balance > 0 && amount > 0) || this.datas.balance < 0)) {
          await this.submitPayment()
        } else {
          if (!this.proofOfPayment?.img || !this.proofOfPayment?.url) {
            return this.$showDialog({
              title: 'Perhatian',
              body: `Harap upload bukti pembayaran!`,
            })
          }
          return this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian!`,
          })
        }
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async submitPayment() {
      try {
        const user = this.$store.state.user?._id
        const paymentDate = this.$moment(this.payDate.value).format('YYYY-MM-DD')
        const payObj = {
          _id: this.$uuid.v4(),
          payAmount: this.$removeSeparator(this.payAmount),
          date: paymentDate,
          proofOfPayment: undefined,
          submitBy: user,
        }
        if (this.proofOfPayment) {
          const proofOfPayment = await this.$uploadFile(
            this.proofOfPayment.img,
            `Quotation/${this.quotationData?._id}/Invoice`,
            `paymentProcess|${this.$moment(paymentDate).format('DD-MMMM-YYYY')}|${+new Date()}`,
          )
          payObj.proofOfPayment = proofOfPayment
        }
        const send = {
          quotationId: this.quotationData?._id,
          payObj,
        }
        const data = await this.$store.dispatch('invoice/updatePayment', send)
        if (data?.success) {
          //! inget bila nambah field handle juga yang di bawah ini
          // ["datas", "invoiceData"].forEach((x) => {
          //   this[x] = {
          //     ...this[x],
          //     balance: data.result?.balance,
          //     lastPaymentDate: data.result?.lastPaymentDate,
          //     lastPaymentBy: data.result?.lastPaymentBy,
          //     paymentProcess: data.result?.paymentProcess,
          //   };
          // });
          this.refreshData()
          this.payAmount = ''
          this.proofOfPayment = null
        }
      } catch (err) {
        Promise.reject(err)
      }
    },
    async removePayment(item) {
      this.$store.commit('SET', { loading: true })
      if (!item) return
      try {
        const data = await this.$store.dispatch('invoice/removePayment', this.quotationData?._id)
        if (data?.success) {
          ;['datas', 'invoiceData'].forEach((x) => {
            this[x] = {
              ...this[x],
              balance: data.result?.balance,
              paymentProcess: data.result?.paymentProcess,
            }
          })
        } else {
          this.$showDialog({
            title: 'Kesalahan',
            body: `Gagal menghapus pembayaran sejumlah ${this.$idCurrency(item.payAmount)}, coba lagi nanti.`,
          })
        }
      } catch (err) {
        Promise.reject(err)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async deleteInvoice() {
      const isConfirmed = confirm('Yakin ingin dihapus?')
      if (!isConfirmed) return
      const validatePrompt = prompt(
        `Menghapus invoice akan menghapus kuotasi yang bersangkutan juga. Untuk konfirmasi masukkan nomor dari invoice yang akan dihapus ${this.datas?.no}`,
      )
      if (!validatePrompt) return
      if (validatePrompt !== this.datas?.no) return alert('Inputan salah')
      await this.$store.commit('SET', { loading: true })
      try {
        const data = await this.$api.delete(`Invoices/removeInvoice`, {
          params: {
            _id: this.datas?._id,
          },
        })
        if (data?.success) {
          this.$showDialog({
            title: 'Hapus Berhasil',
            body: `Invoice nomor ${this.datas?.no} telah berhasil dihapus`,
          })
          this.$emit('closed')
        }
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      await this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
