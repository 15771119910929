<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('close')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="validate">
          <v-row justify="center" dense>
            <v-col cols="10">
              <atoms-title>{{ !id ? 'Tambah' : 'Perbarui' }} Lowongan</atoms-title>
            </v-col>

            <v-col cols="10">
              <atoms-text-field
                label="Judul Lowongan"
                message="Contoh Lowongan : Accounting Cempaka Mas"
                v-model="title"
                :rules="rules.default"
              />
            </v-col>
            <v-col cols="10">
              <template v-if="!id && !readOnly">
                <atoms-text-field
                  v-if="!$store.state.client.data || !client.enabled"
                  label="Cari Perusahaan"
                  v-model="client.term"
                  :rules="[(v) => !!v || !!client.item || 'Harap Pilih Perusahaan']"
                />
                <atoms-text-field v-else label="Pilih Perusahaan">
                  <v-autocomplete
                    class="rounded-lg"
                    v-model="client.value"
                    :items="$store.state.client.data.map((x) => x.name)"
                    placeholder="Pilih Perusahaan"
                    :menu-props="{ offsetY: true }"
                    :rules="[(v) => !!client.item || 'Harap Pilih Perusahaan']"
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
                <atoms-button
                  @click="
                    () => {
                      if (!$store.state.client.data || !client.enabled) {
                        getClients()
                        client.term = ''
                        client.enabled = true
                      } else {
                        client.enabled = false
                        client.item = null
                      }
                    }
                  "
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  :class="[!$store.state.client.data || !client.enabled ? 'primary' : 'red white--text', 'mb-4']"
                  >{{
                    !$store.state.client.data || !client.enabled ? 'Cari Perusahaan' : 'Reset Pencarian'
                  }}</atoms-button
                >
              </template>
              <div v-if="client.item && client.enabled" class="text-start">
                <atoms-text class="my-1 font-weight-bold">Perusahaan Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Nama:</span> {{ client.item.name }}</atoms-text
                  >
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Alamat:</span>
                    {{ client.item.address && client.item.address.streetAddress1 }}</atoms-text
                  >
                  <atoms-text><span class="font-weight-bold primary--text">Area Terdaftar:</span> </atoms-text>
                  <ul>
                    <li v-for="(x, i) in (client.item.areas && client.item.areas.map((x) => x.title)) || []" :key="i">
                      {{ x }}
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>

            <v-col cols="10">
              <atoms-text-field label="Status">
                <v-autocomplete
                  class="rounded-lg"
                  placeholder="Pilih Status"
                  :items="Object.values($postStatus)"
                  :menu-props="{ offsetY: true }"
                  background-color="accent"
                  v-model="status"
                  required
                  solo
                  flat
                  dense
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field label="Provinsi">
                <v-autocomplete
                  class="rounded-lg"
                  placeholder="Pilih Provinsi"
                  :items="$provinces"
                  v-model="province"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap diisi!']"
                  @change="city = ''"
                  background-color="accent"
                  required
                  solo
                  flat
                  dense
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="10" md="5">
              <atoms-text-field label="Kota/Daerah">
                <v-autocomplete
                  class="rounded-lg"
                  placeholder="Pilih Kota/Daerah"
                  :items="(province && $cities[province]) || []"
                  :menu-props="{ offsetY: true }"
                  v-model="city"
                  background-color="accent"
                  no-data-text=" Pilih provinsi terlebih dahulu"
                  :rules="[(v) => (province && !!v) || 'Harap diisi!']"
                  required
                  solo
                  flat
                  dense
                />
              </atoms-text-field>
            </v-col>

            <!-- <v-col cols="10">
              <atoms-text-field label="Kategori">
                <v-autocomplete
                  class="rounded-lg"
                  placeholder="Pilih Kategori"
                  :items="Object.values($postCategory)"
                  :menu-props="{ offsetY: true }"
                  background-color="accent"
                  :rules="rules.default"
                  v-model="category"
                  required
                  solo
                  flat
                  dense
                />
              </atoms-text-field>
            </v-col> -->
            <v-col cols="10" md="6">
              <atoms-text-field label="Salary" v-model="salary.value" prefix="Rp." :rules="[]" />
            </v-col>
            <v-col cols="10" md="4">
              <atoms-text-field label="Salary Status" :rules="[]">
                <v-combobox
                  class="rounded-lg"
                  placeholder="Salary Status"
                  :items="$salaryStatus"
                  v-model="salary.status"
                  dense
                  solo
                  flat
                  required /></atoms-text-field
            ></v-col>
            <v-col cols="10">
              <atoms-text-field
                label="Tambah Tags"
                v-model="tags.value"
                appendIcon="mdi-plus"
                :appendAction="
                  () => {
                    pushTag(tags.value)
                  }
                "
                @change="$refs.form.validate()"
                :rules="[(v) => (!!tags.items && tags.items.length > 0) || 'Harap diisi!']"
              />

              <atoms-button
                @click="pushTag(tags.value)"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="['primary', 'mb-4']"
                >Tambah</atoms-button
              >
            </v-col>
            <v-col cols="10">
              <v-data-table
                :headers="[
                  {
                    text: 'Tag',
                    sortable: false,
                    class: 'primary--text text-uppercase text-center',
                  },
                  {
                    text: 'Aksi',
                    sortable: false,
                    value: 'action',
                    class: 'text-uppercase text-center',
                  },
                ]"
                :items="(tags && tags.items) || []"
                class="accent rounded-lg"
                hide-default-footer
                hide-default-header
              >
                <template v-slot:[`header`]="{ props }">
                  <thead>
                    <tr class="d-table-row">
                      <th v-for="h in props.headers" :key="h.value" :class="h.class">
                        <span>{{ h.text }}</span>
                      </th>
                    </tr>
                  </thead></template
                >

                <template v-slot:[`item`]="{ item }">
                  <tr class="d-table-row">
                    <td class="text-center">{{ item }}</td>
                    <td class="text-center">
                      <v-icon small @click="unPushTag(item)"> mdi-delete </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="10" align="start">
              <quill-editor
                class="accent rounded-lg mt-4"
                :content="content"
                :options="editorOpts"
                @change="onEditorChange($event)"
              >
              </quill-editor>
            </v-col>
            <v-col cols="10">
              <atoms-button
                type="submit"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="['primary', 'mt-4']"
                >Simpan</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      payload: null,
      readOnly: false,
      title: '',
      status: '',
      excerpt: '',
      picture: null,
      salary: {
        value: '',
        status: '',
      },
      client: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
      province: '',
      city: '',
      content: `<h2>Anda bisa menulis lowongan anda disini</h2><br/><p>Tulis apapun yang bisa anda pikirkan...</p><br/><br/><br/><br/><br/><br/><br/><br/><br/>`,
      tags: {
        value: '',
        items: null,
      },
      editorOpts: {},
      start: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      end: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      rules: {
        default: [(v) => !!v || 'Harap Diisi'],
      },
      valid: true,
    }
  },
  watch: {
    'salary.value': function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => {
        this.salary.value = result
      })
    },
    'client.value'() {
      if (this.client.value) {
        this.client.item = this.$store.state.client?.data?.find((x) => x.name === this.client.value)
        // || this.data?.client;z
      }
    },
  },
  mounted() {
    this.payload = (this.id && this.$store.state.vacancy.data?.find((it) => it?._id === this.id)) || null
    if (this.payload) {
      this.title = this.payload?.title
      this.content = this.payload?.content
      this.excerpt = this.payload?.excerpt
      this.status = this.payload?.status || this.$postStatus[1] // Published and Drafted
      this.client.value = this.payload?.client?.name
      this.client.item = this.payload?.client
      this.client.enabled = true
      this.province = this.payload?.province
      this.city = this.payload?.city
      this.salary.value = String(this.payload?.salary || 0)
      this.salary.status = this.payload?.salaryStatus || this.$salaryStatus[0] // per month, per day
      this.tags.items = this.payload?.tags
    } else if (this.id && !this.payload) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal memuat data!`,
      })
      this.$emit('close')
    }
  },
  methods: {
    pushTag(payload) {
      if (!payload) {
        return alert('Harap tag diisi!')
      }
      this.tags.items = [...new Set([payload, ...(this.tags.items || [])])]
      this.tags.value = ''
    },
    unPushTag(payload) {
      if (this.tags.items) {
        this.tags.items = this.tags.items?.filter((it) => it !== payload)
      }
    },
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.client.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 0,
          limit: 100,
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    genExcerpt(str) {
      if (!str) return ''
      if (!(typeof str === 'string')) return ''
      if (str.length > 133) {
        str = str.substring(0, 133) + '...'
      }
      return str
    },
    onEditorChange({ html, text }) {
      this.content = html
      this.excerpt = this.genExcerpt(text)
    },
    async validate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$refs.form.validate()
        if (this.valid) {
          const _id = this.$uuid.v4()
          const payload = {
            ...((this.payload && this.payload) || {}),
            _id: this.id || _id,
            title: this.title,
            content: this.content,
            excerpt: this.excerpt,
            status: this.status || this.$postStatus[1], // Published and Drafted
            clientId: this.client.item?._id || this.client?._id || this.payload?.client?._id,
            province: this.province,
            city: this.city,
            salary: this.$removeSeparator(this.salary.value || 0),
            salaryStatus: this.salary.status || this.$salaryStatus[0], // per month, per day
            tags: this.tags.items || [],
          }
          // console.log('payload', payload)
          if (!this.id) {
            await this.$store.dispatch('vacancy/create', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Lowongan berhasil ditambahkan!`,
            })
          } else {
            await this.$store.dispatch('vacancy/update', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Lowongan berhasil diperbarui!`,
            })
          }

          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$emit('close')
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
