<template>
  <h3
    v-if="h3"
    v:bind="$props"
    v-on="$listeners"
    :class="[...defaultClass, capital && 'text-capitalize', !override && hThree, classes]"
  >
    <slot />
  </h3>
  <h2
    v-else-if="h2"
    v:bind="$props"
    v-on="$listeners"
    :class="[...defaultClass, capital && 'text-capitalize', !override && hTwo, classes]"
  >
    <slot />
  </h2>
  <h1
    v-else
    v:bind="$props"
    v-on="$listeners"
    :class="[...defaultClass, capital && 'text-capitalize', !override && hOne, classes]"
  >
    <slot />
  </h1>
</template>
<script>
export default {
  props: ['h1', 'h2', 'h3', 'classes', 'override', 'capital'],
  data() {
    return {
      defaultClass: ['font-weight-bold'],
      hOne: 'text-h5 text-md-h4',
      hTwo: 'text-h6 text-md-h5',
      hThree: 'headline text-md-h6',
    }
  },
}
</script>
<style lang="scss">
h1,
h2,
h3 {
  letter-spacing: 0rem;
}
</style>
