<template>
  <div
    :class="['d-flex justify-start align-center rounded-lg pa-4', background ? background : 'accent']"
    :style="{
      height: '100%',
      'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
    }"
  >
    <div>
      <v-avatar v-if="loading" class="mr-4 rounded-lg background"></v-avatar>
      <v-avatar v-else :color="color || 'primary'" class="rounded-lg mr-4">
        <v-icon dark>mdi-{{ icon }}</v-icon>
      </v-avatar>
    </div>
    <div style="width: 100%">
      <v-skeleton-loader v-if="loading" type="list-item-two-line" />
      <div v-else class="d-flex flex-column justify-center">
        <atoms-title :h2="true" v-html="title" />
        <atoms-text class="text-left" v-html="cap" />
        <atoms-text :span="true" :auto="true" class="text-left green--text" v-html="desc" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['icon', 'title', 'cap', 'desc', 'color', 'loading', 'background'],
}
</script>
