<template>
  <molecules-floating>
    <div
      ref="content"
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 pt-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row justify="space-between" class="px-8">
        <v-col cols="12" v-if="datas">
          <atoms-title :h2="true"
            >Komplain
            <span class="primary--text"
              >{{ (request && request.worker && request.worker.fullName) || '-' }}
              -
              {{
                (request &&
                  request._createdDate &&
                  $moment(request._createdDate).locale('en').format('DD/MM/YYYY : H:mm:ss A')) ||
                ''
              }}</span
            ></atoms-title
          >
        </v-col>
        <v-col v-if="$roles.Admin" cols="12">
          <atoms-text-field
            v-if="!user.enabled"
            label="Cari Assignee"
            :noLabel="true"
            v-model="user.term"
            :rules="[]"
          />
          <atoms-text-field v-else label="Assignee">
            <v-autocomplete
              class="rounded-lg"
              v-model="user.value"
              :items="
                ($store.state.user.data && $store.state.user.data.map((x) => x.nickname + ' → ' + x.email)) ||
                (request.complainAssignee && [user.value]) ||
                []
              "
              placeholder="Pilih Assignee"
              :menu-props="{ offsetY: true }"
              :rules="[(v) => !!v || 'Harap disii!']"
              dense
              solo
              flat
            />
          </atoms-text-field>
          <atoms-button
            @click="
              () => {
                if (!user.enabled) {
                  getUsers()
                  user.item = null
                  user.term = ''
                  user.value = ''
                  user.enabled = true
                } else {
                  user.enabled = false
                }
              }
            "
            :loading="$store.state.loading"
            :style="{ width: '100%' }"
            :class="[!user.enabled ? 'primary' : 'red white--text', 'mb-4']"
            >{{ !user.enabled ? 'Cari User' : 'Reset Pencarian' }}</atoms-button
          >
          <atoms-text
            >Di assign oleh: {{ (request.complainAssignBy && request.complainAssignBy.email) || '-' }}</atoms-text
          >
        </v-col>
        <v-col cols="12">
          <v-container fluid class="pa-0">
            <atoms-text-field label="Tulis Pesan Anda..." :noLabel="true">
              <v-textarea
                background-color="accent"
                class="rounded-lg"
                prepend-icon="mdi-image-multiple"
                append-icon="mdi-send"
                @click:append="assignMessage"
                @click:prepend="$refs.proof.click()"
                placeholder="Masukkan Pesan"
                loader-height="100"
                v-model="message"
                hide-details
                dense
                solo
                flat
              ></v-textarea>
            </atoms-text-field>
          </v-container>
          <div v-if="proofPhoto" class="text-left mt-5">
            <a :href="proofPhoto.url" target="_blank"
              ><atoms-text
                >Lihat Unggahan : {{ (proofPhoto && proofPhoto.img && proofPhoto.img.name) || '' }}</atoms-text
              ></a
            >
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-container v-if="datas && datas.length > 0">
            <v-row v-for="(it, i) in datas" :key="i" class="accent rounded-lg mb-2">
              <v-col class="shrink" align="center">
                <v-avatar class="background rounded-lg">
                  <atoms-image v-if="it.user && it.user.picture" :src="it.user.picture" />
                  <v-icon v-else color="primary">mdi-account</v-icon></v-avatar
                >
              </v-col>
              <v-col align="start" class="grow pb-0">
                <atoms-text class="font-weight-bold primary--text"
                  >{{ (it.user && it.user.nickname.trim()) || 'User' }},</atoms-text
                ><atoms-text :span="true"
                  >{{ $moment(it._createdDate).format('dddd') }},
                  {{ $moment(it._createdDate).locale('en').format('DD/MM/YYYY | H:mm:ss A') }}</atoms-text
                >
              </v-col>
              <v-col class="shrink" v-if="it.userId === $store.state.user._id">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item link @click="removeMessage(it._id)" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-delete</v-icon>
                      <v-list-item-title class="px-2">Hapus</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12" align="start" class="pt-0 text-left"
                ><atoms-text>{{ it.message }}</atoms-text></v-col
              >
              <v-col v-if="it.proofPhoto" cols="12" align="start" class="pt-0 text-left"
                ><a :href="it.proofPhoto" target="_blank"><atoms-text>Lihat Bukti</atoms-text></a></v-col
              >
            </v-row>
          </v-container>
          <v-container id="scroll" v-else class="d-flex justify-center align-center">
            <div class="text-center">
              <atoms-image :width="250" src="/assets/missing.svg" />
              <atoms-title :h3="true" class="mt-6">Belum Ada Percakapan!</atoms-title>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </div>
    <input ref="proof" type="file" accept="image/*" class="d-none" @change="onProofChange" />
  </molecules-floating>
</template>
<script>
export default {
  props: ['id', 'request'],
  data() {
    return {
      proof: null,
      proofPhoto: null,
      openSendProof: null,
      datas: null,
      message: '',
      user: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
    }
  },
  watch: {
    'user.value'() {
      if (this.user.value) {
        this.user.item = this.$store.state.user?.data?.find(
          (x) => x.email === this.user.value.split('→')[1].trim(), //→
        )
      }
      if (this.user.item) {
        this.assignComplain()
      }
    },
  },
  async mounted() {
    this.$store.commit('SET', { loading: true })
    await this.loadData()
    if (!this.datas || !this.id) {
      this.$emit('closed')
      throw new Error('Gagal Memuat Data')
    }
    if (this.request && this.request.complainAssignee?._id) {
      this.user = {
        item: this.request.complainAssignee,
        value: this.request.complainAssignee.nickname + ' → ' + this.request.complainAssignee.email,
        enabled: true,
        term: '',
      }
    }
    this.$store.commit('SET', { loading: false })
  },
  methods: {
    async loadData() {
      this.datas =
        this.id &&
        (
          await this.$store.dispatch('requestPayments/getComplainMessages', {
            refId: this.id,
            limit: 0,
          })
        )?.result
    },
    async getUsers() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.user.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('user/getAll', {
          query: {
            roles: {
              $in: [this.$roles.Admin],
            },
          },
          custom: {
            $or: [
              {
                nickname: term,
              },
              {
                email: term,
              },
              {
                roles: term,
              },
            ],
          },
          page: 1,
          limit: 0,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async onProofChange(e) {
      const file = e.target.files[0]
      if (file?.size > 3000000) {
        return this.$showDialog({
          title: 'Gagal',
          body: `Bukti maksimal berukuran 3Mb`,
        })
      } else if (file) {
        this.proofPhoto = {
          url: (window.URL || window.webkitURL).createObjectURL(file),
          img: file,
        }
        const url = window.URL || window.webkitURL
        url.revokeObjectURL(file) // free memory
      } else {
        this.proofPhoto = null
      }
    },
    async assignMessage() {
      this.$store.commit('SET', { loading: true })
      try {
        if (this.message) {
          const payload = {
            refId: this.id,
            message: this.message,
            proofPhoto: this.proofPhoto?.img
              ? await this.$uploadFile(
                  this.proofPhoto?.img,
                  `RequestPayments/${this.id}/${new Date().toISOString()}`,
                  `proofPhoto`,
                )
              : undefined,
          }
          await this.$store.dispatch('requestPayments/saveComplainMessage', payload)
          await this.loadData()
          this.message = ''
          this.proofPhoto = null
          this.$vuetify.goTo(0)
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Pastikan kolom pesan terisi!`,
          })
        }
      } catch (error) {
        throw error
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async removeMessage(idComplain) {
      this.$store.commit('SET', { loading: true })
      try {
        if (idComplain) {
          await this.$store.dispatch('requestPayments/removeComplainMessage', {
            _id: idComplain,
          })
          await this.loadData()
          this.message = ''
          this.$vuetify.goTo(0)
        }
      } catch (error) {
        throw error
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async assignComplain() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store.dispatch('requestPayments/assignComplain', {
          refId: this.id,
          userId: this.user.item?._id || this.request.complainAssignee?._id || undefined,

          //  complainAssignBy: this.user.item?._id ?  this.$store.user._id : undefined
        })
        await this.loadData()
        this.message = ''
        this.$vuetify.goTo(0)
      } catch (error) {
        throw error
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
