export default {
  'Sulawasi Tenggara': [
    {
      label: 'Bau-Bau',
      value: 'Bau-Bau',
    },
    {
      label: 'Bombana',
      value: 'Bombana',
    },
    {
      label: 'Buton',
      value: 'Buton',
    },
    {
      label: 'Buton Selatan',
      value: 'Buton Selatan',
    },
    {
      label: 'Buton Tengah',
      value: 'Buton Tengah',
    },
    {
      label: 'Buton Utara',
      value: 'Buton Utara',
    },
    {
      label: 'Kendari',
      value: 'Kendari',
    },
    {
      label: 'Kolaka',
      value: 'Kolaka',
    },
    {
      label: 'Kolaka Timur',
      value: 'Kolaka Timur',
    },
    {
      label: 'Kolaka Utara',
      value: 'Kolaka Utara',
    },
    {
      label: 'Konawe',
      value: 'Konawe',
    },
    {
      label: 'Konawe Kepulauan',
      value: 'Konawe Kepulauan',
    },
    {
      label: 'Konawe Selatan',
      value: 'Konawe Selatan',
    },
    {
      label: 'Konawe Utara',
      value: 'Konawe Utara',
    },
    {
      label: 'Muna',
      value: 'Muna',
    },
    {
      label: 'Muna Barat',
      value: 'Muna Barat',
    },
    {
      label: 'Wakatobi',
      value: 'Wakatobi',
    },
  ],
  Gorontalo: [
    {
      label: 'Boalemo',
      value: 'Boalemo',
    },
    {
      label: 'Bone Bolango',
      value: 'Bone Bolango',
    },
    {
      label: 'Gorontalo',
      value: 'Gorontalo',
    },
    {
      label: 'Gorontalo Utara',
      value: 'Gorontalo Utara',
    },
    {
      label: 'Pohuwato',
      value: 'Pohuwato',
    },
  ],
  Maluku: [
    {
      label: 'Ambon',
      value: 'Ambon',
    },
    {
      label: 'Buru',
      value: 'Buru',
    },
    {
      label: 'Buru Selatan',
      value: 'Buru Selatan',
    },
    {
      label: 'Kepulauan Aru',
      value: 'Kepulauan Aru',
    },
    {
      label: 'Kepulauan Tanimbar',
      value: 'Kepulauan Tanimbar',
    },
    {
      label: 'Maluku Barat Daya',
      value: 'Maluku Barat Daya',
    },
    {
      label: 'Maluku Tengah',
      value: 'Maluku Tengah',
    },
    {
      label: 'Maluku Tenggara',
      value: 'Maluku Tenggara',
    },
    {
      label: 'Seram Bagian Barat',
      value: 'Seram Bagian Barat',
    },
    {
      label: 'Seram Bagian Timur',
      value: 'Seram Bagian Timur',
    },
    {
      label: 'Tual',
      value: 'Tual',
    },
  ],
  'Kalimantan Utara': [
    {
      label: 'Bulungan',
      value: 'Bulungan',
    },
    {
      label: 'Malinau',
      value: 'Malinau',
    },
    {
      label: 'Nunukan',
      value: 'Nunukan',
    },
    {
      label: 'Tana Tidung',
      value: 'Tana Tidung',
    },
    {
      label: 'Tarakan',
      value: 'Tarakan',
    },
  ],
  'Nusa Tenggara Timur': [
    {
      label: 'Alor',
      value: 'Alor',
    },
    {
      label: 'Belu',
      value: 'Belu',
    },
    {
      label: 'Ende',
      value: 'Ende',
    },
    {
      label: 'Flores Timur',
      value: 'Flores Timur',
    },
    {
      label: 'Kupang',
      value: 'Kupang',
    },
    {
      label: 'Lembata',
      value: 'Lembata',
    },
    {
      label: 'Malaka',
      value: 'Malaka',
    },
    {
      label: 'Manggarai',
      value: 'Manggarai',
    },
    {
      label: 'Manggarai Barat',
      value: 'Manggarai Barat',
    },
    {
      label: 'Manggarai Timur',
      value: 'Manggarai Timur',
    },
    {
      label: 'Nagekeo',
      value: 'Nagekeo',
    },
    {
      label: 'Ngada',
      value: 'Ngada',
    },
    {
      label: 'Rote Ndao',
      value: 'Rote Ndao',
    },
    {
      label: 'Sabu Raijua',
      value: 'Sabu Raijua',
    },
    {
      label: 'Sikka',
      value: 'Sikka',
    },
    {
      label: 'Sumba Barat',
      value: 'Sumba Barat',
    },
    {
      label: 'Sumba Barat Daya',
      value: 'Sumba Barat Daya',
    },
    {
      label: 'Sumba Tengah',
      value: 'Sumba Tengah',
    },
    {
      label: 'Sumba Timur',
      value: 'Sumba Timur',
    },
    {
      label: 'Timor Tengah Selatan',
      value: 'Timor Tengah Selatan',
    },
    {
      label: 'Timor Tengah Utara',
      value: 'Timor Tengah Utara',
    },
  ],
  'Jawa Timur': [
    {
      label: 'Bangkalan',
      value: 'Bangkalan',
    },
    {
      label: 'Banyuwangi',
      value: 'Banyuwangi',
    },
    {
      label: 'Batu',
      value: 'Batu',
    },
    {
      label: 'Blitar',
      value: 'Blitar',
    },
    {
      label: 'Bojonegoro',
      value: 'Bojonegoro',
    },
    {
      label: 'Bondowoso',
      value: 'Bondowoso',
    },
    {
      label: 'Gresik',
      value: 'Gresik',
    },
    {
      label: 'Jember',
      value: 'Jember',
    },
    {
      label: 'Jombang',
      value: 'Jombang',
    },
    {
      label: 'Kediri',
      value: 'Kediri',
    },
    {
      label: 'Lamongan',
      value: 'Lamongan',
    },
    {
      label: 'Lumajang',
      value: 'Lumajang',
    },
    {
      label: 'Madiun',
      value: 'Madiun',
    },
    {
      label: 'Magetan',
      value: 'Magetan',
    },
    {
      label: 'Malang',
      value: 'Malang',
    },
    {
      label: 'Mojokerto',
      value: 'Mojokerto',
    },
    {
      label: 'Nganjuk',
      value: 'Nganjuk',
    },
    {
      label: 'Ngawi',
      value: 'Ngawi',
    },
    {
      label: 'Pacitan',
      value: 'Pacitan',
    },
    {
      label: 'Pamekasan',
      value: 'Pamekasan',
    },
    {
      label: 'Pasuruan',
      value: 'Pasuruan',
    },
    {
      label: 'Ponorogo',
      value: 'Ponorogo',
    },
    {
      label: 'Probolinggo',
      value: 'Probolinggo',
    },
    {
      label: 'Sampang',
      value: 'Sampang',
    },
    {
      label: 'Sidoarjo',
      value: 'Sidoarjo',
    },
    {
      label: 'Situbondo',
      value: 'Situbondo',
    },
    {
      label: 'Sumenep',
      value: 'Sumenep',
    },
    {
      label: 'Surabaya',
      value: 'Surabaya',
    },
    {
      label: 'Trenggalek',
      value: 'Trenggalek',
    },
    {
      label: 'Tuban',
      value: 'Tuban',
    },
    {
      label: 'Tulungagung',
      value: 'Tulungagung',
    },
  ],
  'Kepulauan Riau': [
    {
      label: 'Batam',
      value: 'Batam',
    },
    {
      label: 'Bintan',
      value: 'Bintan',
    },
    {
      label: 'Karimun',
      value: 'Karimun',
    },
    {
      label: 'Kepulauan Anambas',
      value: 'Kepulauan Anambas',
    },
    {
      label: 'Lingga',
      value: 'Lingga',
    },
    {
      label: 'Natuna',
      value: 'Natuna',
    },
    {
      label: 'Tanjung Pinang',
      value: 'Tanjung Pinang',
    },
  ],
  Jambi: [
    {
      label: 'Batanghari',
      value: 'Batanghari',
    },
    {
      label: 'Bungo',
      value: 'Bungo',
    },
    {
      label: 'Jambi',
      value: 'Jambi',
    },
    {
      label: 'Kerinci',
      value: 'Kerinci',
    },
    {
      label: 'Merangin',
      value: 'Merangin',
    },
    {
      label: 'Muaro Jambi',
      value: 'Muaro Jambi',
    },
    {
      label: 'Sarolangun',
      value: 'Sarolangun',
    },
    {
      label: 'Sungaipenuh',
      value: 'Sungaipenuh',
    },
    {
      label: 'Tanjung Jabung Barat',
      value: 'Tanjung Jabung Barat',
    },
    {
      label: 'Tanjung Jabung Timur',
      value: 'Tanjung Jabung Timur',
    },
    {
      label: 'Tebo',
      value: 'Tebo',
    },
  ],
  'Sulawesi Tengah': [
    {
      label: 'Banggai',
      value: 'Banggai',
    },
    {
      label: 'Banggai Kepulauan',
      value: 'Banggai Kepulauan',
    },
    {
      label: 'Banggai Laut',
      value: 'Banggai Laut',
    },
    {
      label: 'Buol',
      value: 'Buol',
    },
    {
      label: 'Donggala',
      value: 'Donggala',
    },
    {
      label: 'Morowali',
      value: 'Morowali',
    },
    {
      label: 'Morowali Utara',
      value: 'Morowali Utara',
    },
    {
      label: 'Palu',
      value: 'Palu',
    },
    {
      label: 'Parigi Moutong',
      value: 'Parigi Moutong',
    },
    {
      label: 'Poso',
      value: 'Poso',
    },
    {
      label: 'Sigi',
      value: 'Sigi',
    },
    {
      label: 'Tojo Una-Una',
      value: 'Tojo Una-Una',
    },
    {
      label: 'Tolitoli',
      value: 'Tolitoli',
    },
  ],
  Bengkulu: [
    {
      label: 'Bengkulu',
      value: 'Bengkulu',
    },
    {
      label: 'Bengkulu Selatan',
      value: 'Bengkulu Selatan',
    },
    {
      label: 'Bengkulu Tengah',
      value: 'Bengkulu Tengah',
    },
    {
      label: 'Bengkulu Utara',
      value: 'Bengkulu Utara',
    },
    {
      label: 'Kaur',
      value: 'Kaur',
    },
    {
      label: 'Kepahiang',
      value: 'Kepahiang',
    },
    {
      label: 'Lebong',
      value: 'Lebong',
    },
    {
      label: 'Mukomuko',
      value: 'Mukomuko',
    },
    {
      label: 'Rejang Lebong',
      value: 'Rejang Lebong',
    },
    {
      label: 'Seluma',
      value: 'Seluma',
    },
  ],
  Riau: [
    {
      label: 'Batam',
      value: 'Batam',
    },
    {
      label: 'Bintan',
      value: 'Bintan',
    },
    {
      label: 'Karimun',
      value: 'Karimun',
    },
    {
      label: 'Kepulauan Anambas',
      value: 'Kepulauan Anambas',
    },
    {
      label: 'Lingga',
      value: 'Lingga',
    },
    {
      label: 'Natuna',
      value: 'Natuna',
    },
    {
      label: 'Tanjung Pinang',
      value: 'Tanjung Pinang',
    },
  ],
  'Kalimantan Timur': [
    {
      label: 'Balikpapan',
      value: 'Balikpapan',
    },
    {
      label: 'Berau',
      value: 'Berau',
    },
    {
      label: 'Bontang',
      value: 'Bontang',
    },
    {
      label: 'Kutai Barat',
      value: 'Kutai Barat',
    },
    {
      label: 'Kutai Kartanegara',
      value: 'Kutai Kartanegara',
    },
    {
      label: 'Kutai Timur',
      value: 'Kutai Timur',
    },
    {
      label: 'Mahakam Ulu',
      value: 'Mahakam Ulu',
    },
    {
      label: 'Paser',
      value: 'Paser',
    },
    {
      label: 'Penajam Paser Utara',
      value: 'Penajam Paser Utara',
    },
    {
      label: 'Samarinda',
      value: 'Samarinda',
    },
  ],
  'Sumatra Selatan': [
    {
      label: 'Banyuasin',
      value: 'Banyuasin',
    },
    {
      label: 'Empat Lawang',
      value: 'Empat Lawang',
    },
    {
      label: 'Lahat',
      value: 'Lahat',
    },
    {
      label: 'Lubuklinggau',
      value: 'Lubuklinggau',
    },
    {
      label: 'Muara Enim',
      value: 'Muara Enim',
    },
    {
      label: 'Musi Banyuasin',
      value: 'Musi Banyuasin',
    },
    {
      label: 'Musi Rawas',
      value: 'Musi Rawas',
    },
    {
      label: 'Musi Rawas Utara',
      value: 'Musi Rawas Utara',
    },
    {
      label: 'Ogan Ilir',
      value: 'Ogan Ilir',
    },
    {
      label: 'Ogan Komering Ilir',
      value: 'Ogan Komering Ilir',
    },
    {
      label: 'Ogan Komering Ulu',
      value: 'Ogan Komering Ulu',
    },
    {
      label: 'Ogan Komering Ulu Selatan',
      value: 'Ogan Komering Ulu Selatan',
    },
    {
      label: 'Ogan Komering Ulu Timur',
      value: 'Ogan Komering Ulu Timur',
    },
    {
      label: 'Pagar Alam',
      value: 'Pagar Alam',
    },
    {
      label: 'Palembang',
      value: 'Palembang',
    },
    {
      label: 'Penukal Abab Lematang Ilir',
      value: 'Penukal Abab Lematang Ilir',
    },
    {
      label: 'Prabumulih',
      value: 'Prabumulih',
    },
  ],
  Papua: [
    {
      label: 'Asmat',
      value: 'Asmat',
    },
    {
      label: 'Biak Numfor',
      value: 'Biak Numfor',
    },
    {
      label: 'Boven Digoel',
      value: 'Boven Digoel',
    },
    {
      label: 'Deiyai',
      value: 'Deiyai',
    },
    {
      label: 'Dogiyai',
      value: 'Dogiyai',
    },
    {
      label: 'Intan Jaya',
      value: 'Intan Jaya',
    },
    {
      label: 'Jayapura',
      value: 'Jayapura',
    },
    {
      label: 'Jayawijaya',
      value: 'Jayawijaya',
    },
    {
      label: 'Keerom',
      value: 'Keerom',
    },
    {
      label: 'Kepulauan Yapen',
      value: 'Kepulauan Yapen',
    },
    {
      label: 'Lanny Jaya',
      value: 'Lanny Jaya',
    },
    {
      label: 'Mamberamo Raya',
      value: 'Mamberamo Raya',
    },
    {
      label: 'Mamberamo Tengah',
      value: 'Mamberamo Tengah',
    },
    {
      label: 'Mappi',
      value: 'Mappi',
    },
    {
      label: 'Merauke',
      value: 'Merauke',
    },
    {
      label: 'Mimika',
      value: 'Mimika',
    },
    {
      label: 'Nabire',
      value: 'Nabire',
    },
    {
      label: 'Nduga',
      value: 'Nduga',
    },
    {
      label: 'Paniai',
      value: 'Paniai',
    },
    {
      label: 'Pegunungan Bintang',
      value: 'Pegunungan Bintang',
    },
    {
      label: 'Puncak',
      value: 'Puncak',
    },
    {
      label: 'Puncak Jaya',
      value: 'Puncak Jaya',
    },
    {
      label: 'Sarmi',
      value: 'Sarmi',
    },
    {
      label: 'Supiori',
      value: 'Supiori',
    },
    {
      label: 'Tolikara',
      value: 'Tolikara',
    },
    {
      label: 'Waropen',
      value: 'Waropen',
    },
    {
      label: 'Yahukimo',
      value: 'Yahukimo',
    },
    {
      label: 'Yalimo',
      value: 'Yalimo',
    },
  ],
  'Sumatra Utara': [
    {
      label: 'Asahan',
      value: 'Asahan',
    },
    {
      label: 'Batu Bara',
      value: 'Batu Bara',
    },
    {
      label: 'Dairi',
      value: 'Dairi',
    },
    {
      label: 'Deli Serdang',
      value: 'Deli Serdang',
    },
    {
      label: 'Humbang Hasundutan',
      value: 'Humbang Hasundutan',
    },
    {
      label: 'Karo',
      value: 'Karo',
    },
    {
      label: 'Labuhanbatu',
      value: 'Labuhanbatu',
    },
    {
      label: 'Labuhanbatu Selatan',
      value: 'Labuhanbatu Selatan',
    },
    {
      label: 'Labuhanbatu Utara',
      value: 'Labuhanbatu Utara',
    },
    {
      label: 'Langkat',
      value: 'Langkat',
    },
    {
      label: 'Mandailing Natal',
      value: 'Mandailing Natal',
    },
    {
      label: 'Nias',
      value: 'Nias',
    },
    {
      label: 'Nias Barat',
      value: 'Nias Barat',
    },
    {
      label: 'Nias Selatan',
      value: 'Nias Selatan',
    },
    {
      label: 'Nias Utara',
      value: 'Nias Utara',
    },
    {
      label: 'Padang Lawas',
      value: 'Padang Lawas',
    },
    {
      label: 'Padang Lawas Utara',
      value: 'Padang Lawas Utara',
    },
    {
      label: 'Pakpak Bharat',
      value: 'Pakpak Bharat',
    },
    {
      label: 'Samosir',
      value: 'Samosir',
    },
    {
      label: 'Serdang Bedagai',
      value: 'Serdang Bedagai',
    },
    {
      label: 'Simalungun',
      value: 'Simalungun',
    },
    {
      label: 'Tapanuli Selatan',
      value: 'Tapanuli Selatan',
    },
    {
      label: 'Tapanuli Tengah',
      value: 'Tapanuli Tengah',
    },
    {
      label: 'Tapanuli Utara',
      value: 'Tapanuli Utara',
    },
    {
      label: 'Toba',
      value: 'Toba',
    },
    {
      label: 'Binjai',
      value: 'Binjai',
    },
    {
      label: 'Gunungsitoli',
      value: 'Gunungsitoli',
    },
    {
      label: 'Medan',
      value: 'Medan',
    },
    {
      label: 'Padangsidempuan',
      value: 'Padangsidempuan',
    },
    {
      label: 'Pematangsiantar',
      value: 'Pematangsiantar',
    },
    {
      label: 'Sibolga',
      value: 'Sibolga',
    },
    {
      label: 'Tanjungbalai',
      value: 'Tanjungbalai',
    },
    {
      label: 'Tebing Tinggi',
      value: 'Tebing Tinggi',
    },
  ],
  Banten: [
    {
      label: 'Cilegon',
      value: 'Cilegon',
    },
    {
      label: 'Lebak',
      value: 'Lebak',
    },
    {
      label: 'Pandeglang',
      value: 'Pandeglang',
    },
    {
      label: 'Serang',
      value: 'Serang',
    },
    {
      label: 'Tangerang',
      value: 'Tangerang',
    },
    {
      label: 'Tangerang Selatan',
      value: 'Tangerang Selatan',
    },
  ],
  'Sumatra Barat': [
    {
      label: 'Agam',
      value: 'Agam',
    },
    {
      label: 'Bukittinggi',
      value: 'Bukittinggi',
    },
    {
      label: 'Dharmasraya',
      value: 'Dharmasraya',
    },
    {
      label: 'Kepulauan Mentawai',
      value: 'Kepulauan Mentawai',
    },
    {
      label: 'Lima Puluh',
      value: 'Lima Puluh',
    },
    {
      label: 'Padang',
      value: 'Padang',
    },
    {
      label: 'Padang Pariaman',
      value: 'Padang Pariaman',
    },
    {
      label: 'Padangpanjang',
      value: 'Padangpanjang',
    },
    {
      label: 'Pariaman',
      value: 'Pariaman',
    },
    {
      label: 'Pasaman',
      value: 'Pasaman',
    },
    {
      label: 'Pasaman Barat',
      value: 'Pasaman Barat',
    },
    {
      label: 'Payakumbuh',
      value: 'Payakumbuh',
    },
    {
      label: 'Pesisir Selatan',
      value: 'Pesisir Selatan',
    },
    {
      label: 'Sawahlunto',
      value: 'Sawahlunto',
    },
    {
      label: 'Sijunjung',
      value: 'Sijunjung',
    },
    {
      label: 'Solok',
      value: 'Solok',
    },
    {
      label: 'Solok Selatan',
      value: 'Solok Selatan',
    },
    {
      label: 'Tanah Datar',
      value: 'Tanah Datar',
    },
  ],
  Yogyakarta: [
    {
      label: 'Bantul',
      value: 'Bantul',
    },
    {
      label: 'Gunung Kidul',
      value: 'Gunung Kidul',
    },
    {
      label: 'Kulon Progo',
      value: 'Kulon Progo',
    },
    {
      label: 'Sleman',
      value: 'Sleman',
    },
    {
      label: 'Yogyakarta',
      value: 'Yogyakarta',
    },
  ],
  'Jawa Tengah': [
    {
      label: 'Banjarnegara',
      value: 'Banjarnegara',
    },
    {
      label: 'Banyumas',
      value: 'Banyumas',
    },
    {
      label: 'Batang',
      value: 'Batang',
    },
    {
      label: 'Blora',
      value: 'Blora',
    },
    {
      label: 'Boyolali',
      value: 'Boyolali',
    },
    {
      label: 'Brebes',
      value: 'Brebes',
    },
    {
      label: 'Cilacap',
      value: 'Cilacap',
    },
    {
      label: 'Demak',
      value: 'Demak',
    },
    {
      label: 'Grobogan',
      value: 'Grobogan',
    },
    {
      label: 'Jepara',
      value: 'Jepara',
    },
    {
      label: 'Karanganyar',
      value: 'Karanganyar',
    },
    {
      label: 'Kebumen',
      value: 'Kebumen',
    },
    {
      label: 'Kendal',
      value: 'Kendal',
    },
    {
      label: 'Klaten',
      value: 'Klaten',
    },
    {
      label: 'Kudus',
      value: 'Kudus',
    },
    {
      label: 'Magelang',
      value: 'Magelang',
    },
    {
      label: 'Pati',
      value: 'Pati',
    },
    {
      label: 'Pekalongan',
      value: 'Pekalongan',
    },
    {
      label: 'Pemalang',
      value: 'Pemalang',
    },
    {
      label: 'Purbalingga',
      value: 'Purbalingga',
    },
    {
      label: 'Purworejo',
      value: 'Purworejo',
    },
    {
      label: 'Rembang',
      value: 'Rembang',
    },
    {
      label: 'Salatiga',
      value: 'Salatiga',
    },
    {
      label: 'Semarang',
      value: 'Semarang',
    },
    {
      label: 'Sragen',
      value: 'Sragen',
    },
    {
      label: 'Sukoharjo',
      value: 'Sukoharjo',
    },
    {
      label: 'Surakarta',
      value: 'Surakarta',
    },
    {
      label: 'Tegal',
      value: 'Tegal',
    },
    {
      label: 'Temanggung',
      value: 'Temanggung',
    },
    {
      label: 'Wonogiri',
      value: 'Wonogiri',
    },
    {
      label: 'Wonosobo',
      value: 'Wonosobo',
    },
  ],
  'Sulawesi Barat': [
    {
      label: 'Majene',
      value: 'Majene',
    },
    {
      label: 'Mamasa',
      value: 'Mamasa',
    },
    {
      label: 'Mamuju',
      value: 'Mamuju',
    },
    {
      label: 'Mamuju Tengah',
      value: 'Mamuju Tengah',
    },
    {
      label: 'Pasangkayu',
      value: 'Pasangkayu',
    },
    {
      label: 'Polewali Mandar',
      value: 'Polewali Mandar',
    },
  ],
  'Jawa Barat': [
    {
      label: 'Bandung',
      value: 'Bandung',
    },
    {
      label: 'Bandung Barat',
      value: 'Bandung Barat',
    },
    {
      label: 'Banjar',
      value: 'Banjar',
    },
    {
      label: 'Bekasi',
      value: 'Bekasi',
    },
    {
      label: 'Bogor',
      value: 'Bogor',
    },
    {
      label: 'Ciamis',
      value: 'Ciamis',
    },
    {
      label: 'Cianjur',
      value: 'Cianjur',
    },
    {
      label: 'Cimahi',
      value: 'Cimahi',
    },
    {
      label: 'Cirebon',
      value: 'Cirebon',
    },
    {
      label: 'Depok',
      value: 'Depok',
    },
    {
      label: 'Garut',
      value: 'Garut',
    },
    {
      label: 'Indramayu',
      value: 'Indramayu',
    },
    {
      label: 'Karawang',
      value: 'Karawang',
    },
    {
      label: 'Kuningan',
      value: 'Kuningan',
    },
    {
      label: 'Majalengka',
      value: 'Majalengka',
    },
    {
      label: 'Pangandaran',
      value: 'Pangandaran',
    },
    {
      label: 'Purwakarta',
      value: 'Purwakarta',
    },
    {
      label: 'Subang',
      value: 'Subang',
    },
    {
      label: 'Sukabumi',
      value: 'Sukabumi',
    },
    {
      label: 'Sumedang',
      value: 'Sumedang',
    },
    {
      label: 'Tasikmalaya',
      value: 'Tasikmalaya',
    },
  ],
  'Kalimantan Tengah': [
    {
      label: 'Barito Selatan',
      value: 'Barito Selatan',
    },
    {
      label: 'Barito Timur',
      value: 'Barito Timur',
    },
    {
      label: 'Barito Utara',
      value: 'Barito Utara',
    },
    {
      label: 'Gunung Mas',
      value: 'Gunung Mas',
    },
    {
      label: 'Kapuas',
      value: 'Kapuas',
    },
    {
      label: 'Katingan',
      value: 'Katingan',
    },
    {
      label: 'Lamandau',
      value: 'Lamandau',
    },
    {
      label: 'Murung Raya',
      value: 'Murung Raya',
    },
    {
      label: 'Palangka Raya',
      value: 'Palangka Raya',
    },
    {
      label: 'Pulang Pisau',
      value: 'Pulang Pisau',
    },
    {
      label: 'Seruyan',
      value: 'Seruyan',
    },
    {
      label: 'Sukamara',
      value: 'Sukamara',
    },
    {
      label: 'Waringin Barat',
      value: 'Waringin Barat',
    },
    {
      label: 'Waringin Timur',
      value: 'Waringin Timur',
    },
  ],
  'Papua Barat': [
    {
      label: 'Fakfak',
      value: 'Fakfak',
    },
    {
      label: 'Kaimana',
      value: 'Kaimana',
    },
    {
      label: 'Manokwari',
      value: 'Manokwari',
    },
    {
      label: 'Manokwari Selatan',
      value: 'Manokwari Selatan',
    },
    {
      label: 'Maybrat',
      value: 'Maybrat',
    },
    {
      label: 'Pegunungan Arfak',
      value: 'Pegunungan Arfak',
    },
    {
      label: 'Raja Ampat',
      value: 'Raja Ampat',
    },
    {
      label: 'Sorong',
      value: 'Sorong',
    },
    {
      label: 'Sorong Selatan',
      value: 'Sorong Selatan',
    },
    {
      label: 'Tambrauw',
      value: 'Tambrauw',
    },
    {
      label: 'Teluk Bintuni',
      value: 'Teluk Bintuni',
    },
    {
      label: 'Teluk Wondama',
      value: 'Teluk Wondama',
    },
  ],
  'Bangka Belitung': [
    {
      label: 'Bangka',
      value: 'Bangka',
    },
    {
      label: 'Bangka Barat',
      value: 'Bangka Barat',
    },
    {
      label: 'Bangka Selatan',
      value: 'Bangka Selatan',
    },
    {
      label: 'Bangka Tengah',
      value: 'Bangka Tengah',
    },
    {
      label: 'Belitung',
      value: 'Belitung',
    },
    {
      label: 'Belitung Timur',
      value: 'Belitung Timur',
    },
    {
      label: 'Pangkal Pinang',
      value: 'Pangkal Pinang',
    },
  ],
  Aceh: [
    {
      label: 'Aceh Barat',
      value: 'Aceh Barat',
    },
    {
      label: 'Aceh Barat Daya',
      value: 'Aceh Barat Daya',
    },
    {
      label: 'Aceh Besar',
      value: 'Aceh Besar',
    },
    {
      label: 'Aceh Jaya',
      value: 'Aceh Jaya',
    },
    {
      label: 'Aceh Selatan',
      value: 'Aceh Selatan',
    },
    {
      label: 'Aceh Singkil',
      value: 'Aceh Singkil',
    },
    {
      label: 'Aceh Tamiang',
      value: 'Aceh Tamiang',
    },
    {
      label: 'Aceh Tengah',
      value: 'Aceh Tengah',
    },
    {
      label: 'Aceh Tenggara',
      value: 'Aceh Tenggara',
    },
    {
      label: 'Aceh Timur',
      value: 'Aceh Timur',
    },
    {
      label: 'Aceh Utara',
      value: 'Aceh Utara',
    },
    {
      label: 'Banda Aceh',
      value: 'Banda Aceh',
    },
    {
      label: 'Bener Meriah',
      value: 'Bener Meriah',
    },
    {
      label: 'Bireuen',
      value: 'Bireuen',
    },
    {
      label: 'Gayo Lues',
      value: 'Gayo Lues',
    },
    {
      label: 'Langsa',
      value: 'Langsa',
    },
    {
      label: 'Lhokseumawe',
      value: 'Lhokseumawe',
    },
    {
      label: 'Nagan Raya',
      value: 'Nagan Raya',
    },
    {
      label: 'Pidie',
      value: 'Pidie',
    },
    {
      label: 'Pidie Jaya',
      value: 'Pidie Jaya',
    },
    {
      label: 'Simeulue',
      value: 'Simeulue',
    },
    {
      label: 'Sinabang',
      value: 'Sinabang',
    },
    {
      label: 'Subulussalam',
      value: 'Subulussalam',
    },
  ],
  'Sulawesi Selatan': [
    {
      label: 'Bantaeng',
      value: 'Bantaeng',
    },
    {
      label: 'Barru',
      value: 'Barru',
    },
    {
      label: 'Bone',
      value: 'Bone',
    },
    {
      label: 'Bulukumba',
      value: 'Bulukumba',
    },
    {
      label: 'Enrekang',
      value: 'Enrekang',
    },
    {
      label: 'Gowa',
      value: 'Gowa',
    },
    {
      label: 'Jeneponto',
      value: 'Jeneponto',
    },
    {
      label: 'Kepulauan Selayar',
      value: 'Kepulauan Selayar',
    },
    {
      label: 'Luwu',
      value: 'Luwu',
    },
    {
      label: 'Luwu Timur',
      value: 'Luwu Timur',
    },
    {
      label: 'Luwu Utara',
      value: 'Luwu Utara',
    },
    {
      label: 'Makassar',
      value: 'Makassar',
    },
    {
      label: 'Maros',
      value: 'Maros',
    },
    {
      label: 'Palopo',
      value: 'Palopo',
    },
    {
      label: 'Pangkajene dan Kepulauan',
      value: 'Pangkajene dan Kepulauan',
    },
    {
      label: 'Parepare',
      value: 'Parepare',
    },
    {
      label: 'Pinrang',
      value: 'Pinrang',
    },
    {
      label: 'Sidenreng Rappang',
      value: 'Sidenreng Rappang',
    },
    {
      label: 'Sinjai',
      value: 'Sinjai',
    },
    {
      label: 'Soppeng',
      value: 'Soppeng',
    },
    {
      label: 'Takalar',
      value: 'Takalar',
    },
    {
      label: 'Tana Toraja',
      value: 'Tana Toraja',
    },
    {
      label: 'Toraja Utara',
      value: 'Toraja Utara',
    },
    {
      label: 'Wajo',
      value: 'Wajo',
    },
  ],
  Bali: [
    {
      label: 'Badung',
      value: 'Badung',
    },
    {
      label: 'Bangli',
      value: 'Bangli',
    },
    {
      label: 'Buleleng',
      value: 'Buleleng',
    },
    {
      label: 'Denpasar',
      value: 'Denpasar',
    },
    {
      label: 'Gianyar',
      value: 'Gianyar',
    },
    {
      label: 'Jembrana',
      value: 'Jembrana',
    },
    {
      label: 'Karangasem',
      value: 'Karangasem',
    },
    {
      label: 'Klungkung',
      value: 'Klungkung',
    },
    {
      label: 'Tabanan',
      value: 'Tabanan',
    },
  ],
  'Kalimantan Selatan': [
    {
      label: 'Balangan',
      value: 'Balangan',
    },
    {
      label: 'Banjarbaru',
      value: 'Banjarbaru',
    },
    {
      label: 'Banjarmasin',
      value: 'Banjarmasin',
    },
    {
      label: 'Barito Kuala',
      value: 'Barito Kuala',
    },
    {
      label: 'baru',
      value: 'baru',
    },
    {
      label: 'Hulu Sungai Selatan',
      value: 'Hulu Sungai Selatan',
    },
    {
      label: 'Hulu Sungai Tengah',
      value: 'Hulu Sungai Tengah',
    },
    {
      label: 'Hulu Sungai Utara',
      value: 'Hulu Sungai Utara',
    },
    {
      label: 'Tabalong',
      value: 'Tabalong',
    },
    {
      label: 'Tanah Bumbu',
      value: 'Tanah Bumbu',
    },
    {
      label: 'Tanah Laut',
      value: 'Tanah Laut',
    },
    {
      label: 'Tapin',
      value: 'Tapin',
    },
  ],
  'Maluku Utara': [
    {
      label: 'Halmahera Barat',
      value: 'Halmahera Barat',
    },
    {
      label: 'Halmahera Selatan',
      value: 'Halmahera Selatan',
    },
    {
      label: 'Halmahera Tengah',
      value: 'Halmahera Tengah',
    },
    {
      label: 'Halmahera Timur',
      value: 'Halmahera Timur',
    },
    {
      label: 'Halmahera Utara',
      value: 'Halmahera Utara',
    },
    {
      label: 'Kepulauan Sula',
      value: 'Kepulauan Sula',
    },
    {
      label: 'Pulau Morotai',
      value: 'Pulau Morotai',
    },
    {
      label: 'Pulau Taliabu',
      value: 'Pulau Taliabu',
    },
    {
      label: 'Ternate',
      value: 'Ternate',
    },
    {
      label: 'Tidore Kepulauan',
      value: 'Tidore Kepulauan',
    },
  ],
  'Kalimantan Barat': [
    {
      label: 'Bengkayang',
      value: 'Bengkayang',
    },
    {
      label: 'Kapuas Hulu',
      value: 'Kapuas Hulu',
    },
    {
      label: 'Kayong Utara',
      value: 'Kayong Utara',
    },
    {
      label: 'Ketapang',
      value: 'Ketapang',
    },
    {
      label: 'Kubu Raya',
      value: 'Kubu Raya',
    },
    {
      label: 'Landak',
      value: 'Landak',
    },
    {
      label: 'Melawi',
      value: 'Melawi',
    },
    {
      label: 'Mempawah',
      value: 'Mempawah',
    },
    {
      label: 'Pontianak',
      value: 'Pontianak',
    },
    {
      label: 'Sambas',
      value: 'Sambas',
    },
    {
      label: 'Sanggau',
      value: 'Sanggau',
    },
    {
      label: 'Sekadau',
      value: 'Sekadau',
    },
    {
      label: 'Singkawang',
      value: 'Singkawang',
    },
    {
      label: 'Sintang',
      value: 'Sintang',
    },
  ],
  'Sulawesi Utara': [
    {
      label: 'Bitung',
      value: 'Bitung',
    },
    {
      label: 'Bolaang Mongondow',
      value: 'Bolaang Mongondow',
    },
    {
      label: 'Bolaang Mongondow Selatan',
      value: 'Bolaang Mongondow Selatan',
    },
    {
      label: 'Bolaang Mongondow Timur',
      value: 'Bolaang Mongondow Timur',
    },
    {
      label: 'Bolaang Mongondow Utara',
      value: 'Bolaang Mongondow Utara',
    },
    {
      label: 'Kepulauan Sangihe',
      value: 'Kepulauan Sangihe',
    },
    {
      label: 'Kepulauan Siau Tagulandang Biaro',
      value: 'Kepulauan Siau Tagulandang Biaro',
    },
    {
      label: 'Kepulauan Talaud',
      value: 'Kepulauan Talaud',
    },
    {
      label: 'Manado',
      value: 'Manado',
    },
    {
      label: 'Minahasa',
      value: 'Minahasa',
    },
    {
      label: 'Minahasa Selatan',
      value: 'Minahasa Selatan',
    },
    {
      label: 'Minahasa Tenggara',
      value: 'Minahasa Tenggara',
    },
    {
      label: 'Minahasa Utara',
      value: 'Minahasa Utara',
    },
    {
      label: 'mobagu',
      value: 'mobagu',
    },
    {
      label: 'Tomohon',
      value: 'Tomohon',
    },
  ],
  'Nusa Tenggara Barat': [
    {
      label: 'Bima',
      value: 'Bima',
    },
    {
      label: 'Dompu',
      value: 'Dompu',
    },
    {
      label: 'Lombok Barat',
      value: 'Lombok Barat',
    },
    {
      label: 'Lombok Tengah',
      value: 'Lombok Tengah',
    },
    {
      label: 'Lombok Timur',
      value: 'Lombok Timur',
    },
    {
      label: 'Lombok Utara',
      value: 'Lombok Utara',
    },
    {
      label: 'Mataram',
      value: 'Mataram',
    },
    {
      label: 'Sumbawa',
      value: 'Sumbawa',
    },
    {
      label: 'Sumbawa Barat',
      value: 'Sumbawa Barat',
    },
  ],
  'DKI Jakarta': [
    {
      label: 'Administrasi Kepulauan Seribu',
      value: 'Administrasi Kepulauan Seribu',
    },
    {
      label: 'Jakarta Barat',
      value: 'Jakarta Barat',
    },
    {
      label: 'Jakarta Pusat',
      value: 'Jakarta Pusat',
    },
    {
      label: 'Jakarta Selatan',
      value: 'Jakarta Selatan',
    },
    {
      label: 'Jakarta Timur',
      value: 'Jakarta Timur',
    },
    {
      label: 'Jakarta Utara',
      value: 'Jakarta Utara',
    },
  ],
  Lampung: [
    {
      label: 'Bandar Lampung',
      value: 'Bandar Lampung',
    },
    {
      label: 'Lampung Barat',
      value: 'Lampung Barat',
    },
    {
      label: 'Lampung Selatan',
      value: 'Lampung Selatan',
    },
    {
      label: 'Lampung Tengah',
      value: 'Lampung Tengah',
    },
    {
      label: 'Lampung Timur',
      value: 'Lampung Timur',
    },
    {
      label: 'Lampung Utara',
      value: 'Lampung Utara',
    },
    {
      label: 'Mesuji',
      value: 'Mesuji',
    },
    {
      label: 'Metro',
      value: 'Metro',
    },
    {
      label: 'Pesawaran',
      value: 'Pesawaran',
    },
    {
      label: 'Pesisir Barat',
      value: 'Pesisir Barat',
    },
    {
      label: 'Pringsewu',
      value: 'Pringsewu',
    },
    {
      label: 'Tanggamus',
      value: 'Tanggamus',
    },
    {
      label: 'Tulang Bawang',
      value: 'Tulang Bawang',
    },
    {
      label: 'Tulang Bawang Barat',
      value: 'Tulang Bawang Barat',
    },
    {
      label: 'Way Kanan',
      value: 'Way Kanan',
    },
  ],
}
