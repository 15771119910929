import axios from 'axios'
import Vue from 'vue'
import store from '../store'
import { socket } from '../plugins/helpers'

const instance = axios.create({
  baseURL: process.env.VUE_APP_DB_URL,
})
const instance2 = axios.create({
  baseURL: process.env.VUE_APP_DB_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
  },
})

instance.interceptors.request.use((config) => {
  // get token, it's automatically parse a json doc
  const token = Vue.prototype.$storage.getItem('_credentials')
  const user = Vue.prototype.$storage.getItem('_user')
  socket.emit('connectUser', user?._id)
  if (token && user) {
    config.headers['Authorization'] = `Bearer ${token?._at}`
  }
  process.env.NODE_ENV === 'development' && console.log('Making request to ' + config.url)
  return config
})

instance.interceptors.response.use(
  (result) => {
    return result.data || result
  },
  async (error) => {
    const originalRequest = error.config
    // console.log(error?.response)
    error?.response && console.warn(error?.response)

    if (error.response && Boolean(error.response.status === 401)) {
      if (!store.state.route.fullPath.includes('/login')) {
        store.commit('SET', { loading: false })
        await store.dispatch('user/logout')
      }
      return Promise.reject(error.response)
    }

    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true
      const token = async () => {
        let token = Vue.prototype.$storage.getItem('_credentials')
        console.log(token)
        if (token) {
          token = token._rt
          const { data } = await instance2
            .post(`Users/token`, {
              token,
            })
            .catch(async () => await store.dispatch('user/logout'))

          if (data && data.accessToken) {
            Vue.prototype.$storage.setItem('_credentials', {
              _at: data.accessToken,
              _rt: token,
            })
            // Vue.prototype.$storage.setItem('_user', Vue.prototype.$decoder(data.accessToken))
            // store.commit('user/LOGIN', Vue.prototype.$decoder(data.accessToken))
            token = data.accessToken
          }
        }
        return token
      }
      await token()
      return await instance(originalRequest)
    }

    return Promise.reject(error?.response?.data?.message || error?.response?.data || error?.response || error)
  },
)

/**
 * error get =>
 * - response which contains (data, status, statusText, headers ),
 */

Vue.use({
  install() {
    Vue.prototype.$api = instance
  },
})
