import router from '@/router/index'
/* eslint-disable */
const collection = 'Cases'
const defaultPipeline = [
  {
    $lookup: {
      from: 'Users',
      localField: 'personResp',
      foreignField: '_id',
      as: 'personResp',
    },
  },
  {
    $lookup: {
      from: 'Workers',
      localField: 'per',
      foreignField: '_id',
      as: 'worker',
    },
  },
  {
    $lookup: {
      from: 'Workers',
      localField: 'pekerja',
      foreignField: '_id',
      as: 'worker',
    },
  },
  {
    $unwind: {
      path: '$worker',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Clients',
      localField: 'klien',
      foreignField: '_id',
      as: 'client',
    },
  },
  {
    $unwind: {
      path: '$client',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $project: {
      'personResp.password': 0,
    },
  },
]
const initial = () => ({
  data: null,
  total: 0,
  limit: 10,
  kerugian: 0,
  pengembalian: 0,
  hutang: 0,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async summary({ commit }, payload) {
      await this._vm.$api
        .get(collection + '/getSum', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
          },
        })
        .then(({ result, ...rest }) => {
          // console.log(result)
          commit('SET', {
            kerugian: result?.sumKerugian || 0,
            pengembalian: result?.sumPengembalian || 0,
            hutang: result?.sumHutang || 0,
          })
        })
    },
    async get({ state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            limit: 1,
          },
        })
        .then(({ result, ...rest }) => {
          return { ...result?.[0], ...rest }
        })
        .catch((error) => Promise.reject(error))
    },
    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            page: payload?.page || 1,
            limit: payload?.limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          if (!payload?.returnOnly)
            commit('SET', {
              data: result,
              ...rest,
            })
          return result
        })
        .catch(async (err) => Promise.reject(err))
    },
    async updateConversation({ commit }, { payload, params, ...rest }) {
      return await this._vm.$api
        .patch(collection + '/updateConversation', { ...payload, ...rest }, { ...params })
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async remove({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .delete(collection + '/remove', {
            params: {
              _id: payload,
            },
          })
          .then(async ({ success }) => success)
          .catch((error) => Promise.reject(error.response?.data?.message || error.response || 'Ada kesalahan'))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
    async create({ state }, payload) {
      return await this._vm.$api.post(collection + '/create', payload).catch(async (err) => Promise.reject(err))
    },
    async update({ state }, payload) {
      return await this._vm.$api.patch(collection + '/update', payload).catch(async (err) => Promise.reject(err))
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
