<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="close"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <atoms-title class="my-5">Edit Saldo Pekerja</atoms-title>
        <v-form ref="myForm" v-model="myForm" @submit.prevent="updateBalance">
          <v-row justify="center">
            <v-col cols="12">
              <atoms-text-field
                v-if="!worker.data || !worker.enabled"
                label="Cari Pekerja"
                :loading="$store.state.loading || worker.mainLoading"
                v-model="worker.term"
                :rules="[]"
              />
              <atoms-text-field v-else label="Pilih Pekerja">
                <v-autocomplete
                  class="rounded-lg"
                  :loading="$store.state.loading || worker.mainLoading"
                  v-model="worker.value"
                  :items="(worker.data && worker.data.map((x) => x.fullName + ' → ' + x.email)) || []"
                  placeholder="Pilih Pekerja"
                  :menu-props="{ offsetY: true }"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
              <atoms-button
                @click="
                  () => {
                    if (!worker.data || !worker.enabled) {
                      getWorkers()
                      worker.term = ''
                      worker.enabled = true
                    } else {
                      worker.data = null
                      worker.enabled = false
                    }
                  }
                "
                :loading="$store.state.loading || worker.mainLoading"
                :style="{ width: '100%' }"
                :class="[!worker.data || !worker.enabled ? 'primary' : 'red white--text', 'mb-4']"
                >{{ !worker.data || !worker.enabled ? 'Cari Pekerja' : 'Reset Pencarian' }}</atoms-button
              >
              <atoms-button
                v-if="worker.data && worker.enabled"
                :loading="$store.state.loading || worker.mainLoading"
                @click="
                  () => {
                    if (worker.data) {
                      worker.item = worker.data.find((x) => x.email === worker.value.split('→')[1].trim())
                      const check = worker.item && worker.items && worker.items.find((it) => it._id === worker.item._id)
                      if (!check) {
                        worker.item.balanceReason = form.reason || ''
                        worker.items = [...(worker.items || []), worker.item]
                        worker.term = null
                        worker.value = null
                        worker.enabled = false
                      } else {
                        $showDialog({
                          title: 'Gagal menambah pekerja',
                          body: 'Maaf, pekerja yang anda maksud sudah tertambahkan',
                        })
                      }
                    }
                  }
                "
                :style="{ width: '100%' }"
                :class="['mb-4 primary']"
                >Tambah Pekerja</atoms-button
              >
            </v-col>
            <v-col cols="12" md="6">
              <atoms-text-field
                label="Saldo (Isi dengan prefix (-) untuk mengurangkan saldo pekerja)"
                placeholder="Saldo"
                v-model="form.balance"
                type="number"
                :rules="rules.default"
              />
            </v-col>
            <v-col cols="12" md="6">
              <atoms-text-field label="Alasan" v-model="form.reason" :rules="rules.default" />
            </v-col>
            <v-col cols="12">
              <v-data-table
                :items="worker.items || []"
                :headers="[
                  {
                    text: 'Nama Pekerja',
                    sortable: false,
                    value: 'fullName',
                    width: '40%',
                    class: 'primary--text text-uppercase',
                  },
                  {
                    text: 'Email Pekerja',
                    sortable: false,
                    value: 'email',
                    width: '40%',
                    class: 'primary--text text-uppercase',
                  },
                  {
                    text: 'Alasan',
                    sortable: false,
                    value: 'alasan',
                    class: 'text-uppercase',
                  },
                  {
                    text: 'Aksi',
                    sortable: false,
                    value: 'action',
                    class: 'text-uppercase text-center',
                  },
                ]"
                class="accent rounded-lg"
                hide-default-footer
                hide-default-header
                mobile-breakpoint="0"
                disable-pagination
              >
                <template v-slot:[`header`]="{ props }">
                  <thead>
                    <tr class="d-table-row">
                      <th v-for="h in props.headers" :key="h.value" :class="['text-left', h.class]">
                        <span class="primary--text text-uppercase" v-html="h.text" />
                      </th>
                    </tr></thead
                ></template>
                <template v-slot:[`item`]="{ item }">
                  <tr class="d-table-row">
                    <td class="text-left">{{ item.fullName }}</td>
                    <td class="text-left">{{ item.email }}</td>
                    <td class="text-left d-flex">
                      <atoms-text-field
                        placeholder="Alasan"
                        color="background"
                        :loading="worker.mainLoading"
                        v-model="item.balanceReason"
                        :rules="[]"
                        style="width: 100%"
                        class="mt-auto"
                      />
                    </td>
                    <td class="text-center">
                      <v-icon
                        small
                        @click="
                          () => {
                            const result = worker.items && worker.items.filter((it) => it._id !== item._id)
                            if (result) {
                              worker.items = result
                            }
                          }
                        "
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <atoms-button
                v-if="worker.items"
                type="submit"
                :loading="$store.state.loading || worker.mainLoading"
                :style="{ width: '100%' }"
                :class="['primary']"
                >Sesuaikan</atoms-button
              >
            </v-col>
            <v-col cols="12">
              <atoms-button
                v-if="worker.items"
                @click="
                  () => {
                    form = {
                      balance: 0,
                      reason: '',
                    }
                    worker = {
                      enabled: false,
                      value: '',
                      term: '',
                      item: null,
                      data: null,
                      items: null,
                      mainLoading: false,
                    }
                  }
                "
                :loading="$store.state.loading || worker.mainLoading"
                :style="{ width: '100%' }"
                :class="['mb-4 red white--text']"
                >Reset</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  data() {
    return {
      form: {
        balance: 0,
        reason: '',
      },
      worker: {
        enabled: false,
        value: '',
        term: '',
        item: null,
        data: null,
        items: null,
        mainLoading: false,
      },
      duplicate: '',
      myForm: null,
      rules: {
        default: [(v) => !!v || 'Harap diisi!'],
      },
    }
  },
  watch: {},
  mounted() {
    this.reset()
  },
  methods: {
    close() {
      this.reset()
      this.$emit('closed')
    },
    searchWorker() {
      if (!this.worker.data || !this.worker.enabled) {
        this.getWorkers()
        this.worker.term = ''
        this.worker.enabled = true
      } else {
        this.worker.data = null
        this.worker.enabled = false
      }
    },
    reset() {
      // for (const [key, value] of Object.entries(this.form)) {
      //   this.form[key] = this.data?.[key] || this.form[key]
      // }
      this.form = {
        balance: 0,
        reason: '',
      }
      this.worker = {
        enabled: false,
        value: '',
        term: '',
        item: null,
        data: null,
        items: null,
        mainLoading: false,
      }
    },
    async getWorkers() {
      this.worker.mainLoading = true
      try {
        const term = { $regex: this.worker.term || '', $options: 'i' }
        this.worker.data = await this.$store.dispatch('worker/getAll', {
          query: {
            approved: true,
            active: true,
            email: { $exists: true, $ne: '' },
            $or: [
              {
                fullName: term,
              },
              {
                email: term,
              },
            ],
          },
          returnOnly: true,
          limit: 50,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.worker.mainLoading = false
      }
    },
    async updateBalance() {
      this.$refs.myForm.validate()
      if (!this.myForm) {
        window.scroll({
          top: 0,
          behavior: 'smooth',
        })
        this.$showDialog({
          title: 'Perhatian',
          body: `Harap diisi kolom pengisian`,
        })
        return
      }
      this.$store.commit('SET', { loading: true })
      try {
        // @fetch worker's balances
        for (let i = 0; i < this.worker.items?.length; i++) {
          const result = await this.$store.dispatch('worker/getWorkerBalances', {
            query: {
              _id: this.worker.items[i]?._id,
            },
            page: this.page,
            limit: this.limit,
          })
          this.worker.items[i].balance = Number(result?.[0]?.balance || 0)
        }
        // console.log(this.worker.items)
        if (confirm('Apakah anda yakin akan melakukan perubahan saldo terhadap data pekerja dibawah?')) {
          for (let i = 0; i < this.worker.items?.length; i++) {
            const balance = Number(Number(this.worker.items[i].balance || 0) + Number(this.form.balance))
            await this.$api.patch(`WorkerBalances/update`, {
              _id: this.worker.items[i]._id,
              reason: this.worker.items[i].balanceReason || this.form.reason,
              balance,
            })
          }
          this.$refs.myForm?.resetValidation()
          this.$refs.myForm?.reset()
          this.$showDialog({
            title: 'Berhasil',
            body: `Saldo Pekerja berhasil diperbarui`,
          })
          this.reset()
        }
      } catch (err) {
        this.$showDialog({
          title: 'Maaf, terdapat gangguan',
          body: err.message,
        })
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
