<template>
  <v-menu
    ref="menu"
    v-model="timeMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <v-label v-if="!noLabel"><atoms-text class="pb-1 px-2 font-weight-bold text-left" v-html="label" /></v-label>
        <v-text-field
          :value="value"
          :placeholder="placeholder"
          :rules="rules"
          class="rounded-lg my-0"
          :style="{
            'box-shadow': !rules || border ? 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px' : 'none',
          }"
          append-icon="mdi-clock-time-four-outline"
          :background-color="color || 'accent'"
          :hide-details="!rules"
          readonly
          required
          dense
          solo
          flat
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </div>
    </template>
    <v-time-picker v-if="timeMenu" v-model="model" full-width></v-time-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: String,
    color: String,
    noLabel: Boolean,
    label: {
      type: String,
      default: 'Time',
    },
    placeholder: {
      type: String,
      default: 'Time',
    },
    rules: Array,
  },
  data() {
    return {
      timeMenu: false,
    }
  },
  computed: {
    model: {
      get() {
        // this.value contain String "##:##"
        const value = this?.value || ''
        return value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
