const collection = 'Locations'
const initial = () => ({
  data: null,
  defaultPipeline: [
    {
      $lookup: {
        from: 'Areas',
        let: { aid: ['$areaId'] },
        pipeline: [
          {
            $match: {
              $expr: {
                $in: ['$_id', '$$aid'],
              },
            },
          },
          {
            $lookup: {
              from: 'Clients',
              localField: 'clientId',
              foreignField: '_id',
              as: 'client',
            },
          },
          {
            $unwind: {
              path: '$client',
              preserveNullAndEmptyArrays: true,
            },
          },
        ],
        as: 'areas',
      },
    },
    {
      $unwind: {
        path: '$areas',
        preserveNullAndEmptyArrays: true,
      },
    },
    {
      $addFields: {
        client: '$areas.client',
        locations: '$$ROOT',
      },
    },
  ],
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async getAll({ commit, state }, payload) {
      console.log({
        ...payload?.query,
        pipeline: [...state.defaultPipeline],
        ...payload?.custom,
      })
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...state.defaultPipeline],
              ...payload?.custom,
            }),
            page: payload?.page || 1,
            limit: payload?.limit || state.limit,
          },
        })
        .then(({ result, ...rest }) => {
          const resp = {
            data: result,
            ...rest,
          }
          console.log(resp)
          if (!payload?.returnOnly) {
            commit('SET', resp)
          }
          return resp
        })
    },
    async create({ state }, payload) {
      return await this._vm.$api
        .post(collection + '/create', payload)
        .then(({ success }) => success)
        .catch((err) => Promise.reject(err))
    },
    async update({ state }, payload) {
      return await this._vm.$api
        .patch(collection + '/update', payload)
        .then(({ success }) => success)
        .catch((err) => Promise.reject(err))
    },
    async remove({ state }, locationId) {
      return await this._vm.$api
        .put(collection + '/remove', {
          _id: locationId,
        })
        .then(({ success }) => ({ ...area, locations }))
        .catch((err) => Promise.reject(err))
    },
    async checkLocation({ state, rootState }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify(payload),
          },
        })
        .then(({ result }) => (result.length > 0 ? true : false))
        .catch((err) => Promise.reject(err))
    },
    // async checkLocation({ state }, [areaId, term]) {
    //   return await this._vm.$api
    //     .get("Areas/getAreas", {
    //       params: {
    //         jsonQuery: JSON.stringify({
    //           _id: areaId,
    //           locations: {
    //             $elemMatch: {
    //               name: { $regex: term || "", $options: "i" },
    //             },
    //           },
    //         }),
    //       },
    //     })
    //     .then(({ result }) => (result.length > 0 ? true : false))
    //     .catch((err) => {
    //       // console.log(err)
    //       return Promise.reject(err)
    //     })
    // },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
