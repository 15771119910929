var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('molecules-wrapper',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('atoms-title',[_vm._v("Pekerja Yang Ditambahkan ke Shift "+_vm._s(_vm.shift.title || '-'))])],1),_c('v-col',{staticClass:"rounded-lg my-2",attrs:{"cols":"10"}},[(_vm.mainLoading || !_vm.result)?_c('div',_vm._l((5),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"accent rounded-lg mb-2 pa-2",style:({
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }),attrs:{"type":"list-item-avatar"}})}),1):_vm._l((_vm.result),function(item,i){return _c('div',{key:i,staticClass:"accent rounded-lg my-2 flex pa-5"},[_c('v-avatar',{staticClass:"rounded-full mr-auto",attrs:{"color":"grey lighten-3","size":"50"}},[(item.photo)?_c('atoms-image',{attrs:{"width":100,"enableZoom":true,"src":item.photo}}):_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-account")])],1),_c('atoms-text',{staticClass:"font-weight-bold text-left primary--text"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(_vm._s(item.fullName))],1),_c('atoms-text',{staticClass:"text-left"},[_vm._v("Email "+_vm._s(item.email))]),_c('atoms-button',{staticClass:"mt-2 error",staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.deleteWorkerFromShift(item._id)}}},[_vm._v("Hapus pekerja dari shift")])],1)})],2)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.pagesLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c('v-slide-x-reverse-transition',[(_vm.showDetail)?_c('organism-attendance-detail',{attrs:{"disableEdit":true,"data":_vm.showDetail},on:{"closed":() => {
            _vm.showDetail = null
          }}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }