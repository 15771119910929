<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="close"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <molecules-wrapper>
        <v-row v-if="item" justify="center">
          <v-col cols="10">
            <atoms-title>Edit Member {{ (item.client && item.client.name) || '-' }}</atoms-title>
          </v-col>
          <!-- client -->
          <v-col cols="10">
            <div v-if="item.client" class="mt-4">
              <atoms-text class="my-1 font-weight-bold">Client </atoms-text>
              <div class="pa-2 accent rounded-lg text-left mb-4">
                <atoms-text
                  ><span class="font-weight-bold primary--text">Nama:</span> {{ item.client.name }}</atoms-text
                >
                <atoms-text
                  ><span class="font-weight-bold primary--text">Alamat:</span>
                  {{ item.client.address.streetAddress1 }}</atoms-text
                >
                <atoms-text><span class="font-weight-bold primary--text">Area Terdaftar:</span> </atoms-text>

                <ul>
                  <li v-for="(x, i) in item.client.area || []" :key="i">
                    {{ x.title }} {{ x.inactive ? '(Tidak Aktif)' : '' }}
                  </li>
                </ul>
              </div>
              <atoms-text-field
                v-if="item.user"
                disabled
                label="Nickname"
                v-model="item.user.nickname"
                color="accent"
                :rules="[]"
              />
              <atoms-text-field v-if="item.user" disabled label="Email" v-model="item.user.email" color="accent" />
            </div>
          </v-col>
          <v-col cols="10">
            <v-divider></v-divider>
          </v-col>
          <!-- user -->
          <v-col cols="10">
            <v-form ref="formRole" v-model="validRole" @submit.prevent="preValidateRole">
              <atoms-text-field label="Role">
                <v-autocomplete
                  background-color="accent"
                  class="rounded-lg"
                  placeholder="Pilih Role"
                  :items="[
                    $clientRoles.Admin + ', akses keseluruhan halaman',
                    $clientRoles.Supervisor + ', akses halaman pekerja dan absensi saja',
                  ]"
                  :menu-props="{ offsetY: true }"
                  v-model="getSelectedRole"
                  :rules="[(v) => !!v || 'Harap Pilih Role']"
                  solo
                  dense
                  flat
                  required
                />
              </atoms-text-field>

              <atoms-button :loading="$store.state.loading" type="submit" :style="{ width: '100%' }" class="primary">{{
                item.user && $store.state.user.roles.find((x) => x == $roles.Admin || x == $roles.Super_Admin)
                  ? 'Tambah Role dan Simpan Email'
                  : 'Tambah Role'
              }}</atoms-button>
            </v-form>
          </v-col>
          <v-col cols="10">
            <v-data-table
              :headers="[
                {
                  text: 'Role',
                  sortable: false,
                  value: 'name',
                  width: '40%',
                  class: 'primary--text text-uppercase',
                },
                {
                  text: '',
                  sortable: false,
                  value: 'action',
                  class: 'text-uppercase',
                },
              ]"
              :items="(item && item.roles) || []"
              class="accent rounded-lg"
              hide-default-footer
              hide-default-header
            >
              <template v-slot:[`header`]="{ props }">
                <thead>
                  <tr class="d-table-row">
                    <th v-for="h in props.headers" :key="h.value" :class="h.class">
                      <span>{{ h.text }}</span>
                    </th>
                  </tr>
                </thead></template
              >

              <template v-slot:[`item`]="{ item: roleItem }">
                <tr class="d-table-row">
                  <td class="text-left">{{ roleItem }}</td>
                  <td
                    v-if="$vuetify.breakpoint.smAndDown"
                    :style="{
                      width: '100%',
                    }"
                  />

                  <td class="text-right">
                    <v-icon small @click="removeRoles(roleItem)"> mdi-delete </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="10">
            <v-divider></v-divider>
          </v-col>
          <!-- area -->
          <v-col cols="10" v-if="isShowArea">
            <div v-if="!$store.state.area.data || !area.enabled">
              <atoms-text-field
                label="Cari Area"
                placeholder="Biarkan kosong jika ingin cari semua"
                v-model="area.term"
                :rules="[]"
              />
              <atoms-button
                @click="
                  () => {
                    getAreas()
                    area.enabled = true
                  }
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                class="primary"
                >Cari Area</atoms-button
              >
            </div>
            <div v-else>
              <atoms-text-field label="Pilih Area">
                <v-combobox
                  multiple
                  class="rounded-lg"
                  v-model.trim="area.value"
                  :items="$store.state.area.data || []"
                  :item-text="(area) => area.title + ' - ' + (area.inactive ? 'Tidak Aktif' : 'Aktif')"
                  prepend-inner-icon="mdi-check-all"
                  @click:prepend-inner="
                    () => {
                      if ($store.state.area.data && $store.state.area.data.length > 0)
                        area.value = $store.state.area.data
                    }
                  "
                  placeholder="Pilih Area"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
              <atoms-button
                @click="
                  () => {
                    getAreas()
                    area.term = ''
                    area.enabled = false
                  }
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                class="red white--text"
                >Reset Pencarian</atoms-button
              >
            </div>
          </v-col>
          <!-- lokasi -->
          <v-col cols="10" v-if="isShowLocation">
            <div v-if="!$store.state.location.data || !location.enabled">
              <atoms-text-field
                label="Cari Lokasi"
                placeholder="Biarkan kosong jika ingin cari semua"
                v-model="location.term"
                :rules="[]"
              />
              <atoms-button
                @click="
                  () => {
                    getLocations()
                    location.enabled = true
                  }
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                class="primary"
                >Cari Lokasi</atoms-button
              >
            </div>
            <div v-else>
              <atoms-text-field label="Pilih Lokasi">
                <v-combobox
                  multiple
                  class="rounded-lg"
                  v-model.trim="location.value"
                  :items="$store.state.location.data || []"
                  :item-text="(loc) => loc.name + ' - ' + (loc.areas && loc.areas.title) || loc.areaId"
                  prepend-inner-icon="mdi-check-all"
                  @click:prepend-inner="
                    () => {
                      if ($store.state.location.data && $store.state.location.data.length > 0)
                        location.value = $store.state.location.data
                    }
                  "
                  placeholder="Pilih Lokasi"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
              <atoms-button
                @click="
                  () => {
                    location.term = ''
                    location.enabled = false
                  }
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                class="red white--text"
                >Reset Pencarian</atoms-button
              >
            </div>
          </v-col>

          <v-col cols="10">
            <atoms-button
              @click="validateRole"
              :disabled="!isShowLocation"
              :loading="$store.state.loading"
              :style="{ width: '100%' }"
              type="submit"
              class="primary"
              >Tambah Area Lokasi
              {{ (!isShowLocation && '(Cari area dan lokasi sampai tombol aktif)') || '' }}</atoms-button
            >
          </v-col>
          <v-col cols="10">
            <v-data-table
              :items="(item && getAreasLocations) || []"
              :headers="[
                {
                  text: 'Area',
                  sortable: false,
                  value: 'title',
                  width: '40%',
                  class: 'primary--text text-uppercase',
                },
                {
                  text: 'Lokasi',
                  sortable: false,
                  value: 'location.name',
                  width: '40%',
                  class: 'primary--text text-uppercase',
                },
                {
                  text: '',
                  sortable: false,
                  value: 'action',
                  class: 'text-uppercase',
                },
              ]"
              class="accent rounded-lg mt-2"
              hide-default-footer
              hide-default-header
              mobile-breakpoint="0"
              disable-pagination
            >
              <template v-slot:[`header`]="{ props }">
                <thead>
                  <tr class="d-table-row">
                    <th v-for="h in props.headers" :key="h.value" :class="['text-left', h.class]">
                      <span class="primary--text text-uppercase" v-html="h.text" />
                    </th>
                  </tr></thead
              ></template>
              <template v-slot:[`item`]="{ item: itemLoc }">
                <tr class="d-table-row">
                  <td class="text-left">{{ itemLoc.title }}</td>
                  <td class="text-left">{{ itemLoc.location.name }}</td>
                  <td class="text-right">
                    <v-icon small @click="removeAreaLocation(itemLoc, itemLoc.location)"> mdi-delete </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <atoms-button
              @click="
                () => {
                  item.locationIds = null
                  item.areaIds = null
                  removeAreaLocation()
                }
              "
              :loading="$store.state.loading"
              :style="{ width: '100%' }"
              type="submit"
              class="red white--text mt-4"
              >Hapus Semua Area</atoms-button
            >
          </v-col>
        </v-row>
      </molecules-wrapper>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      all: 'Semua',
      validRole: true,

      nickname: '',
      email: '',
      item: null,
      photo: null,
      tabs: null,
      role: '',

      client: {
        enabled: false,
        value: '',
        item: null,
        term: '',
        area: null,
      },
      area: {
        term: '',
        enabled: false,
        value: null,
        items: null,
      },
      location: {
        term: '',
        enabled: false,
        value: null,
        items: null,
      },
      user: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
    }
  },
  computed: {
    getSelectedRole: {
      get() {
        return ''
      },
      set(value) {
        if (value?.includes(',')) {
          this.role = value?.split(',')[0]
        }
      },
    },
    getAreasLocations() {
      return this.item?.locations
        .filter((x) => this.item?.areaIds && this.item?.areaIds?.includes(x?.areaId))
        ?.map((x) => ({
          ...(this.item?.areas?.find((it) => it?._id == x?.areaId) || {}),
          location: x,
        }))
    },

    isShowArea() {
      return this.item?.client?._id
    },
    isShowLocation() {
      return this.area.enabled && this.area.value?.length
    },
    isShowUser() {
      return this.isShowArea && this.isShowLocation && this.location.enabled && this.location.value?.length > 0
    },
    isShowSubmit() {
      return this.isShowUser && this.tabs == 0
        ? this.nickname && this.email && this.password
        : this.user.value && this.user.enabled
    },
  },
  async mounted() {
    await this.loadData()
    if (!this.item) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal Memuat Data Member`,
      })
      this.close()
      return
    }

    if (this.data?.areas) {
      this.area.value = this.data?.areas
    }
    this.role = this.data?.roles[0]
    if (
      this.data?.user &&
      this.$store.state.user?.roles?.find((x) => x == this.$roles.Admin || x == this.$roles.Super_Admin)
    ) {
      this.email = this.data?.user.email
    }

    console.log(this.item)

    document.documentElement.style.overflow = 'hidden'
  },
  methods: {
    async loadData() {
      if (this.data?.userId && this.data?.client?._id)
        this.item = await this.$store.dispatch('member/get', {
          userId: this.data?.userId,
          clientId: this.data?.client?._id,
          returnOnly: true,
          pipeline: this.$store.state.member.defaultPipeline,
        })
    },
    async getAreas() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store.dispatch('area/getAll', {
          query: {
            clientId: this.item?.client?._id,
            title: { $regex: this.area.term || '', $options: 'i' },
            // $or: [
            //   {
            //     inactive: {
            //       $exists: false,
            //     },
            //   },
            //   {
            //     inactive: false,
            //   },
            // ],
          },
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getLocations() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store.dispatch('location/getAll', {
          query: {
            areaId: { $in: this.area.value?.map((area) => area?._id) },
            name: { $regex: this.location.term || '', $options: 'i' },
            // $or: [
            //   {
            //     inactive: {
            //       $exists: false,
            //     },
            //   },
            //   {
            //     inactive: false,
            //   },
            // ],
          },
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    openUploader() {
      if (this.$refs.uploader) {
        this.$refs.uploader.click()
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (file) {
        this.photo = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.photo = null
      }
    },
    async registerMember(payload) {
      const locationIds = this.location.value?.map((location) => location._id)
      const areaIds = this.area.value?.map((area) => area._id)
      try {
        await this.$store.dispatch('member/register', {
          locationIds,
          areaIds,
          userId: payload?.userId || this.user.value?._id,
          clientId: this.item?.client?._id,
        })
        this.$showDialog({
          title: 'Berhasil',
          body: `Member baru berhasil ditambahkan`,
        })
        this.close()
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: `Terdapat kesalahan, ${error.message || error}`,
        })
        this.$store.commit('SET', { loading: false })
        Promise.reject(error)
      }
    },
    async removeAreaLocation(area, location) {
      this.$store.commit('SET', { loading: true })
      try {
        this.item.locationIds = this.item?.locationIds?.filter((it) => it !== location?._id) || []
        this.item.locations = this.item.locations.filter((it) => this.item.locationIds.includes(it._id))
        this.item.areaIds = this.item?.areaIds?.filter((x) => this.item.locations?.find((it) => it.areaId === x)) || []
        await this.$store.dispatch('member/update', {
          _id: this.item?._id,
          locationIds: this.item?.locationIds || [],
          areaIds: this.item?.areaIds || [],
        })
        await this.loadData()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async removeRoles(role) {
      this.$store.commit('SET', { loading: true })
      this.item.roles = this.item?.roles?.filter((x) => x !== role)
      await this.$store.dispatch('member/updateRoles', {
        _id: this.item?._id,
        roles: this.item?.roles,
      })
      this.$store.commit('SET', { loading: false })
    },
    async preValidateRole() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.email.trim() || '', $options: 'i' }
      try {
        await this.$refs.formRole.validate()
        if (this.email) {
          const { result: worker } = await this.$api.get(`Workers/count`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          const { result: user } = await this.$api.get(`Users/isExist`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          if (worker > 0 || user) {
            this.duplicate = this.email
          } else {
            this.duplicate = ''
          }
        }
        if (!this.validRole) {
          this.$showDialog({
            title: 'Perhatian',
            body: `Lihat Kembali Kolom Pengisisan`,
          })
        } else {
          await this.validateRole()
        }
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validateRole() {
      this.$store.commit('SET', { loading: true })
      try {
        if (this.validRole && this.role) {
          this.item.roles = [...new Set([this.role, ...this.item?.roles])]
        }
        if (this.isShowLocation && this.area.value) {
          this.item.areaIds = [
            ...new Set([...(this.area.value?.map((areaItem) => areaItem._id) || []), ...(this.item?.areaIds || [])]),
          ]
          // this.area.value = null
        }
        if (this.isShowLocation && this.location.value) {
          this.item.locationIds = [
            ...new Set([
              ...(this.location.value?.map((locationItem) => locationItem?._id) || []),
              ...(this.item?.locationIds || []),
            ]),
          ]
          // this.location.item = null
        }

        if (this.isShowLocation || this.validRole) {
          console.log({
            _id: this.item?._id,
            roles: this.item?.roles || [],
            locationIds: this.item?.locationIds || [],
            areaIds: this.item?.areaIds || [],
          })
          await this.$store.dispatch('member/update', {
            _id: this.item?._id,
            roles: this.item?.roles || [],
            locationIds: this.item?.locationIds || [],
            areaIds: this.item?.areaIds || [],
          })

          // ganti email
          if (
            this.item?.user &&
            this.$store.state.user?.roles?.find((x) => x == this.$roles.Admin || x == this.$roles.Super_Admin)
          )
            await this.$store.dispatch('user/update', {
              _id: this.item?.user?._id,
              email: this.email,
            })
          await this.loadData()
          this.$showDialog({
            title: 'Berhasil',
            body: `Member ${this.item?.user?.nickname || this.item?.userId} berhasil diperbarui`,
          })
          this.$refs.formRole?.resetValidation()
          this.$refs.formRole?.inputs?.forEach((input) => {
            if (!input?.type == 'email') {
              input.reset()
            }
          })

          this.$refs.formAreas?.resetValidation()
          this.$refs.formAreas?.reset()
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian`,
          })
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    close() {
      document.documentElement.style.overflow = 'auto'
      // this.$refs.form.resetValidation()
      // this.$refs.form.reset()
      this.$emit('closed')
    },
  },
}
</script>
