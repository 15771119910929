<template>
  <v-app class="background overflow-x-hidden">
    <v-expand-transition>
      <div v-if="!$store.state.init">
        <AppLayout>
          <molecules-loading />
          <molecules-dialog v-if="$store.state.user.isLogged" />
          <molecules-custom-dialog
            :show="showMigrationNotification"
            :strict="true"
            width="350px"
            title="Informasi Website"
            caption="Website ini sudah tidak mendapatkan pembaruan fitur, sehingga untuk mendapatkannya Anda dapat mengakses website baru internal hayokerja pada https://hayosystems-v3.web.app/"
            :items="[
              {
                title: 'Tutup',
                full: true,
                exec: () => (showMigrationNotification = false),
              },
              {
                title: 'Ya, buka website baru',
                override: 'red--text',
                full: true,
                exec: () => {
                  $window.location.replace('https://hayosystems-v3.web.app/')
                  showMigrationNotification = false
                },
              },
            ]"
          />
          <router-view />
        </AppLayout>
      </div>
      <div v-else>
        <v-sheet
          class="transparent"
          :style="{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }"
        >
          <div class="d-flex flex-column align-center justify-center">
            <atoms-image width="100" :style="{ margin: 'auto' }" src="/favicon.ico" />
            <atoms-text class="my-4 text-capitalize font-weight-medium">{{ $store.state.log + '...' }}</atoms-text>
          </div>
        </v-sheet>
      </div>
    </v-expand-transition>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'Beranda',
      titleTemplate: `%s |  Dashboard `,
      htmlAttrs: { lang: 'en' },
      meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
    }
  },
  async mounted() {
    // console.log(await this.$get_all_db())
    // this.$emitter.$on("error", (err) => this.errorDisplay(err))
    // setInterval(() => {
    //   console.clear()
    // }, 60 * 10 * 1000)
    // await this.$set_db('test', 'halo')
    // await this.$set_db('test2', true)
    // await this.$set_db('test2', {
    //   ok: 'ok',
    // })
    // console.log(await this.$get_all_db())
  },
  data() {
    return {
      logger: 0,
      showMigrationNotification: true,
    }
  },
  errorCaptured(err) {
    console.log('error nih', err)
    if (
      !err?.message?.includes('mapDiv') &&
      !err?.message?.includes('addEventListener') &&
      !err?.message?.includes('getDiv') &&
      !err?.message?.includes('Navigation cancelled')
    )
      this.errorDisplay(err)
  },
  methods: {
    errorDisplay(err) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Terdapat kesalahan, ${err.message || err}`,
      })
      this.$store.commit('SET', { loading: false })
    },
  },
  // sockets: {
  //   connect: function () {
  //     this.logger = 0
  //     console.log("connected : blast")
  //   },
  //   disconnect: function () {
  //     console.log("disconnect : blast")
  //   },
  //   connect_failed: function (err) {
  //     this.logger += 1
  //     if (this.logger > 25) {
  //       this.$socket.off()
  //     }
  //   },
  // },
  // created(){
  //   this.$wa.on()
  // }
  // beforeMount() {
  //   this.checkTheme();
  // },
  // watch: {
  //   $route: function () {
  //     this.checkTheme();
  //   },
  // },
  // methods: {
  //   checkTheme() {
  //     let isDark = localStorage.getItem("dark");
  //     this.$vuetify.theme.dark = Boolean(
  //       String(isDark).toLowerCase() === "true" ? true : false || false
  //     );
  //   },
  // },
}
</script>
