<template>
  <span>
    <span v-if="isEdit">
      <v-text-field v-model="value" class="m-0 p-0" dense outlined hide-details @keypress.enter="isEdit = false" />
    </span>
    <span v-else @dblclick="isEdit = !isEdit">
      {{ value }}
    </span>
  </span>
</template>
<script>
export default {
  name: 'DynamicText',
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      isEdit: false,
    }
  },
}
</script>
