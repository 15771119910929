<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="12" align="start">
          <v-btn depressed color="primary" icon @click="onClose"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container class="px-10">
        <v-row v-if="payload && client" justify="space-between" class="py-4">
          <v-col cols="12" class="mb-10">
            <atoms-title
              >Lowongan <span class="primary--text">{{ payload.title }}</span></atoms-title
            >
          </v-col>
          <v-col cols="12" md="2" class="rounded-lg accent">
            <v-avatar size="150">
              <atoms-image contain :enableZoom="true" v-if="client.logo" :src="client.logo" />
              <v-icon light v-else>mdi-domain</v-icon></v-avatar
            >
          </v-col>
          <v-col
            cols="10"
            :class="[
              'd-flex flex-column ',
              $vuetify.breakpoint.smAndDown ? 'text-left mt-10' : 'pa-10',
              'align-start rounded-lg ',
            ]"
          >
            <atoms-title :h2="true">{{ client.name }}</atoms-title>
            <atoms-text>{{ client.address.streetAddress1 }}</atoms-text>

            <atoms-text class="mt-3">
              <v-icon small>mdi-map-marker</v-icon>
              {{ payload.city }} - {{ payload.province }}</atoms-text
            >
          </v-col>
          <v-col cols="12" class="px-0">
            <v-tabs
              background-color="accent"
              color="primary"
              height="40"
              v-model="tabs"
              hide-slider
              :show-arrows="$vuetify.breakpoint.smAndDown ? true : false"
              :center-active="$vuetify.breakpoint.smAndDown ? true : false"
              class="rounded-lg d-flex justify-start align-center px-0 mt-5"
            >
              <v-tab
                v-for="i in ['Konten', 'Daftar Peminat', 'Rincian']"
                :key="i"
                class="text-capitalize"
                active-class="primary lighten-5 primary--text rounded-lg ma-1"
              >
                <atoms-text class="font-weight-medium">{{ $toCapitalize(i) }}</atoms-text>
              </v-tab>
            </v-tabs>
            <div>
              <v-fade-transition>
                <v-container v-if="tabs === 0" class="pa-10 my-5 rounded-lg text-left accent">
                  <div v-html="payload.content"></div>
                </v-container>
              </v-fade-transition>
              <v-fade-transition>
                <v-container v-if="tabs === 1" class="px-0">
                  <div
                    v-for="(worker, i) in payload.workers || []"
                    :key="i"
                    :class="['accent', 'rounded-lg pa-4 mb-2']"
                    :style="{
                      'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 1px 4px',
                    }"
                  >
                    <v-row align="center">
                      <v-col class="shrink">
                        <v-avatar class="rounded-lg">
                          <atoms-image
                            v-if="worker && worker.photo"
                            class="rounded-lg"
                            :enableZoom="true"
                            :src="worker.photo"
                          />
                          <v-icon v-else>mdi-account</v-icon></v-avatar
                        >
                      </v-col>
                      <v-col cols="8" md="4" align="start">
                        <atoms-text class="font-weight-bold">{{ worker.fullName }}</atoms-text>

                        <atoms-text class="primary--text"
                          >{{ (worker.address && worker.address.city) || '' }} -
                          {{ (worker.address && worker.address.province) || '' }}</atoms-text
                        >
                      </v-col>
                      <v-col align="start" cols="12" md="auto">
                        <atoms-text class="font-weight-bold">Email: {{ worker.email || '-' }}</atoms-text>
                        <atoms-text class="primary--text">Telp : {{ worker.phone || '-' }}</atoms-text>
                      </v-col>
                      <v-col :align="$vuetify.breakpoint.smAndDown ? 'start' : 'end'">
                        <!-- <atoms-text
                            ><strong>Masuk:</strong> Pukul
                            {{
                              $moment(phlJamMulai).format("H:mm:ss")
                            }}</atoms-text
                          > -->
                      </v-col>
                      <v-col class="shrink">
                        <atoms-button @click="showDetail = worker" class="primary">Detail</atoms-button>
                      </v-col>
                    </v-row>
                  </div></v-container
                >
              </v-fade-transition>
              <v-fade-transition>
                <v-container v-if="tabs === 2" class="text-start pa-10 my-5 accent">
                  <atoms-text class="orange--text">- Salary : {{ $idCurrency(payload.salary || 0) || '-' }}</atoms-text>
                  <atoms-text
                    >- Penulis : {{ (payload.createdByData && payload.createdByData.nickname) || '-' }}</atoms-text
                  >
                  <atoms-text
                    >- Disunting Oleh :
                    {{ (payload.updatedByData && payload.updatedByData.nickname) || '-' }}</atoms-text
                  ></v-container
                >
              </v-fade-transition>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-slide-x-reverse-transition>
        <organism-workers-edit v-if="showDetail" @closed="detailClose" :data="showDetail" />
      </v-slide-x-reverse-transition>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      tabs: null,
      payload: null,
      showDetail: null,
      valid: true,
      client: null,
    }
  },
  async mounted() {
    document.documentElement.style.overflow = 'hidden'
    this.payload = (this.id && this.$store.state.vacancy.data?.find((it) => it?._id === this.id)) || null
    if (this.id && !this.payload) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal memuat data!`,
      })
      this.onClose()
    } else {
      this.client = await this.getClients(this.payload?.clientId)
    }
  },
  methods: {
    detailClose() {
      document.documentElement.style.overflow = 'hidden'
      this.showDetail = null
    },
    async getClients(_id) {
      this.$store.commit('SET', { loading: true })
      try {
        return (
          (
            await this.$store.dispatch('client/getAll', {
              custom: {
                approved: true, //{ $exists: true }
                _id,
                pipeline: [
                  {
                    $lookup: {
                      from: 'Areas',
                      localField: '_id',
                      foreignField: 'clientId',
                      as: 'areas',
                    },
                  },
                ],
              },
              page: 0,
              limit: 1,
            })
          )?.[0] || null
        )
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    onClose() {
      document.documentElement.style.overflow = 'auto'
      this.$emit('close')
    },
  },
}
</script>
