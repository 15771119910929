var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('v-card',{staticClass:"mx-auto pa-4 rounded-lg",attrs:{"elevation":"0","rounded":"lg","min-width":350,"max-width":_vm.$vuetify.breakpoint.smAndDown ? 350 : 700}},[_c('v-card-title',[_c('atoms-title',{staticClass:"font-weight-bold",attrs:{"h3":true}},[_vm._v("Edit Posisi")])],1),_c('v-card-text',{staticClass:"text-left pb-0"},[_c('atoms-text',[_vm._v("Anda mengedit posisi absensi "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.data.worker && _vm.data.worker.fullName))])])],1),_c('v-card-text',[_c('v-autocomplete',{staticClass:"rounded-lg mb-4",style:({
          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        }),attrs:{"placeholder":"Pilih Area","items":_vm.areas,"menu-props":{ offsetY: true },"background-color":"background","item-text":"title","item-value":"_id","hide-details":"","solo":"","flat":"","dense":"","required":"","disabled":_vm.areaLoading,"loading":_vm.areaLoading},model:{value:(_vm.areaId),callback:function ($$v) {_vm.areaId=$$v},expression:"areaId"}}),_c('v-autocomplete',{staticClass:"rounded-lg mb-4",style:({
          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        }),attrs:{"placeholder":"Pilih Lokasi","items":_vm.locations,"menu-props":{ offsetY: true },"background-color":"background","item-text":"name","item-value":"_id","hide-details":"","solo":"","flat":"","dense":"","required":"","disabled":_vm.locationLoading,"loading":_vm.locationLoading},model:{value:(_vm.locationId),callback:function ($$v) {_vm.locationId=$$v},expression:"locationId"}}),_c('v-autocomplete',{staticClass:"rounded-lg mb-4",style:({
          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        }),attrs:{"placeholder":"Pilih Shift","items":_vm.shifts,"menu-props":{ offsetY: true },"background-color":"background","item-text":"title","item-value":"_id","hide-details":"","solo":"","flat":"","dense":"","required":"","disabled":_vm.shiftLoading,"loading":_vm.shiftLoading},model:{value:(_vm.shiftId),callback:function ($$v) {_vm.shiftId=$$v},expression:"shiftId"}}),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":_vm.updateData}},[_vm._v("Simpan")]),_c('atoms-button',{staticClass:"mt-2 primary",style:({ width: '100%' }),on:{"click":_vm.close}},[_vm._v("Kembali")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }