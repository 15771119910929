import router from '@/router/index'
/* eslint-disable */
const collection = 'RequestPayments'
const defaultPipeline = [
  {
    $lookup: {
      from: 'Workers',
      localField: 'workerId',
      foreignField: '_id',
      as: 'worker',
    },
  },
  {
    $unwind: {
      path: '$worker',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'approvedBy',
      foreignField: '_id',
      as: 'approvedBy',
    },
  },
  {
    $unwind: {
      path: '$approvedBy',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'updatedBy',
      foreignField: '_id',
      as: 'updatedBy',
    },
  },
  {
    $unwind: {
      path: '$updatedBy',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'complainAssignee',
      foreignField: '_id',
      as: 'complainAssignee',
    },
  },
  {
    $unwind: {
      path: '$complainAssignee',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'complainAssignBy',
      foreignField: '_id',
      as: 'complainAssignBy',
    },
  },
  {
    $unwind: {
      path: '$complainAssignBy',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'ComplainMessages',
      localField: '_id',
      foreignField: 'refId',
      as: 'complainMessages',
    },
  },
  { $addFields: { totalComplainMessages: { $size: '$complainMessages' } } },
  {
    $project: {
      'updatedBy.password': 0,
      'updatedBy.notifTokens': 0,
      'updatedBy.roles': 0,
      'approvedBy.password': 0,
      'approvedBy.notifTokens': 0,
      'approvedBy.roles': 0,
      'complainAssignBy.password': 0,
      'complainAssignBy.notifTokens': 0,
      'complainAssignBy.roles': 0,
      'complainAssignee.password': 0,
      'complainAssignee.notifTokens': 0,
      'complainAssignee.roles': 0,
    },
  },
]
const initial = () => ({
  data: null,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async getAll({ commit, state, dispatch }, { query, limit, page, custom, pipeline }) {
      await this._vm.$api
        .get('RequestPayments/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [
                ...state.defaultPipeline,
                ...(query?.pipeline || []),
                ...(custom?.pipeline || []),
                ...(pipeline || []),
              ],
              ...custom,
            }),
            page: page || 1,
            limit: limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          let data = result,
            balance = null
          if (data?.length > 0)
            balance = await dispatch(
              'worker/getWorkerBalances',
              {
                query: {
                  ...query,
                  _id: {
                    $in: data.map((it) => it?.worker?._id)?.filter((it) => !!it),
                  },
                },
                limit,
                page,
                custom,
                pipeline,
                returnOnly: true,
              },
              { root: true },
            )
          if (balance?.length > 0) {
            data = data.map((it) => ({
              ...it,
              balance: balance?.find((n) => n?._id === it?.worker?._id)?.balance || 0,
            }))
          }
          commit('SET', {
            data,
            ...rest,
          })
          return result
        })
        .catch(async (err) => Promise.reject(err))
    },

    async getEditWorkerBalances({ commit, state, dispatch }, { query, limit, page, custom, pipeline }) {
      return await this._vm.$api
        .get('EditWorkerBalances/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [
                ...state.defaultPipeline,
                ...(query?.pipeline || []),
                ...(custom?.pipeline || []),
                ...(pipeline || []),
              ],
              ...custom,
            }),
            page: page || 1,
            limit: limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          let data = result,
            balance = null
          if (data?.length > 0)
            balance = await dispatch(
              'worker/getWorkerBalances',
              {
                query: {
                  ...query,
                  _id: {
                    $in: data.map((it) => it?.worker?._id)?.filter((it) => !!it),
                  },
                },
                returnOnly: true,
              },
              { root: true },
            )
          if (balance?.length > 0) {
            data = data.map((it) => ({
              ...it,
              balance: balance?.find((n) => n?._id === it?.worker?._id)?.balance || 0,
            }))
          }
          return { result: data || result, ...rest }
        })
        .catch(async (err) => Promise.reject(err))
    },

    async approve({ commit }, payload) {
      return await this._vm.$api
        .post(collection + '/approve', payload)
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async updateEditWorkerBalances({ commit }, payload) {
      return await this._vm.$api
        .post('EditWorkerBalances/approve', payload)
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async bulkApprove({ commit }, payload) {
      return await this._vm.$api
        .post(collection + '/bulkApprove', payload)
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async assignComplain({ commit }, payload) {
      return await this._vm.$api
        .post(collection + '/assignComplain', payload)
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async bulkApproveEditWorkerBalances({ commit }, payload) {
      return await this._vm.$api
        .post('EditWorkerBalances/bulkApprove', payload)
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async getComplainMessages({ commit }, payload) {
      return await this._vm.$api
        .get(collection + '/getMessages', {
          params: {
            refId: payload.refId,
            page: payload.page || 1,
            limit: payload.limit || 0,
          },
        })
        .then(({ result, ...rest }) => {
          return { result, ...rest }
        })
        .catch(async (err) => Promise.reject(err))
    },
    async saveComplainMessage({ commit }, payload) {
      return await this._vm.$api
        .post(collection + '/saveMessage', {
          ...payload,
          refId: payload.refId,
          message: payload.message,
          proofPhoto: payload.proofPhoto,
        })
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async getAdminFee({ commit }, payload) {
      return await this._vm.$api
        .get('AdminSetting/get/' + payload._id)
        .then(({ result, ...rest }) => {
          return { result, ...rest }
        })
        .catch(async (err) => Promise.reject(err))
    },
    async saveAdminFee({ commit }, payload) {
      return await this._vm.$api
        .patch('AdminSetting/update', {
          _id: payload._id,
          feePercentage: payload.feePercentage,
        })
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async removeComplainMessage({ commit }, payload) {
      return await this._vm.$api
        .delete(collection + '/removeMessage', {
          params: {
            ...payload,
            _id: payload._id,
          },
        })
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
  },

  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
