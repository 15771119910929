<template>
  <div id="gmap-input" :style="style">
    <v-label>
      <atoms-text class="pb-1 px-2 font-weight-bold text-left" v-html="'Alamat'" />
    </v-label>
    <div class="mb-5" style="position: relative">
      <vue-google-autocomplete
        id="map"
        v-model="address"
        placeholder="Alamat"
        :country="['ID']"
        class="v-input__slot v-text-field__slot accent px-3 py-2"
        style="border-radius: 5px"
        v-on:placechanged="getAddressData"
      />
      <v-fade-transition mode="in-out">
        <div
          v-if="!coordinate.latitude || !coordinate.longitude"
          class="v-messages theme--light error--text ml-3"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">Harap masukkan alamat dengan benar</div>
          </div>
        </div>
      </v-fade-transition>
      <v-fade-transition mode="in-out">
        <div
          v-if="coordinate.latitude && coordinate.longitude"
          class="v-messages theme--light black--text ml-3 mt-3"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              <p class="mb-3">Latitude : {{ coordinate.latitude }}</p>
              <p class="mb-3">Longitude : {{ coordinate.longitude }}</p>
            </div>
          </div>
        </div>
      </v-fade-transition>
      <atoms-gmap-modal v-if="showGmap" style="position: absolute; top: -19px; right: 5px" />
    </div>
  </div>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default {
  props: {
    style: String,
  },
  data() {
    return {
      showGmap: true,
    }
  },
  methods: {
    getAddressData(addressData, placeResultData) {
      this.$store.commit('gmap/setAddress', placeResultData.formatted_address)
      this.$store.commit('gmap/setCoordinate', {
        latitude: addressData.latitude,
        longitude: addressData.longitude,
      })
      this.reRenderGmap()
    },
    reRenderGmap() {
      this.showGmap = false
      this.$nextTick(() => {
        this.showGmap = true
      })
    },
  },
  computed: {
    address() {
      return this.$store.getters['gmap/address']
    },
    coordinate() {
      return this.$store.getters['gmap/coordinate']
    },
  },
  components: {
    VueGoogleAutocomplete,
  },
}
</script>
