<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '80%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>Perusahaan Baru</atoms-title>
            </v-col>
            <v-col cols="10">
              <div>
                <v-avatar
                  color="accent"
                  size="100"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-overlay :value="true" opacity="0.5" absolute>
                    <div @click="openUploader" class="pa-12" :style="{ width: '100%', height: '100%' }">
                      <atoms-text class="font-weight-medium"><v-icon small> mdi-pencil </v-icon>Upload Logo</atoms-text>
                      <input ref="uploader" type="file" accept="image/*" class="d-none" @change="onPhotoChange" />
                    </div>
                  </v-overlay>
                  <atoms-image
                    v-if="logo && logo.url"
                    :lazy-src="`/lazy-img.webp`"
                    aspect-ratio="1"
                    :src="logo.url || `/lazy-img.webp`"
                  />
                  <v-icon v-else> mdi-account </v-icon>
                </v-avatar>
                <atoms-text class="py-4">Logo Maks. 3mb (Bila ada)</atoms-text>
              </div>

              <v-row class="mt-4">
                <!-- row 1 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Nama Perusahaan"
                    v-model="name"
                    :rules="[
                      (v) => !!v || 'Nama harap diisi!',
                      (v) => (!!v && duplicate.name !== v) || 'Nama sudah terdaftar!',
                    ]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Kode Perusahaan"
                    v-model="code"
                    v-mask="'AAAAAA'"
                    :rules="[
                      (v) => !!v || 'Harap diisi!',
                      (v) => (v && v.length >= 4) || 'Minimal 4 Huruf!',
                      (v) => (v && v.length <= 6) || 'Maksimal 6 Huruf!',
                      (v) => /^[A-Z]{1,6}/.test(v) || 'Huruf Kapital!',
                    ]"
                  />
                </v-col>

                <!-- row 2 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Nama Kontak"
                    v-model="contactPerson.name"
                    :rules="[(v) => !!v || 'Harap disii!']"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Email"
                    v-model="contactPerson.email"
                    :rules="[
                      (v) => !!v || 'Email harap diisi!',
                      (v) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email tidak valid',
                      (v) => (!!v && duplicate.email !== v) || 'Email sudah terdaftar!',
                    ]"
                  />
                </v-col>

                <!-- row 3 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field
                    label="Nomor Telepon"
                    :mask="'############'"
                    v-model="contactPerson.phone"
                    type="number"
                    :rules="[
                      (v) => !!v || 'Nomor harap diisi!',
                      (
                        v, ///^(62|0)8[1-9][0-9]{9,12}$/
                      ) => /^\d{9,12}/.test(v) || 'Nomor tidak valid (Contoh: 6287765347111/089666347816)',
                    ]"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field label="Alamat " v-model="address" :rules="[(v) => !!v || 'Harap disii!']" />
                </v-col>

                <!-- row 4 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field label="NPWP (Bila Ada)" v-model="npwp" :rules="[]" />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field label="Tipe Perusahaan">
                    <v-autocomplete
                      class="rounded-lg"
                      placeholder="Tipe Perusahaan"
                      :items="$companyType"
                      v-model="companyType"
                      :menu-props="{ offsetY: true }"
                      :rules="[(v) => !!v || 'Harap disii!']"
                      dense
                      solo
                      flat
                    />
                  </atoms-text-field>
                </v-col>
                <!-- row 4 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field label="URL Youtube Client (Bila Ada)" v-model="socials.yt.url" :rules="[]" />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field label="URL Instagram Client (Bila Ada)" v-model="socials.ig.url" :rules="[]" />
                </v-col>
                <!-- row 5 -->
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field label="URL Website Client (Bila Ada)" v-model="website" :rules="[]" />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <atoms-text-field label="URL Facebook Client (Bila Ada)" v-model="socials.fb.url" :rules="[]" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <atoms-text-field>
                    <v-textarea
                      class="rounded-lg"
                      v-model.trim="description"
                      placeholder="Contoh: Perusahaan bergerak dibidang apa dan kapan terbentuknya, dsb."
                      flat
                      solo
                    />
                  </atoms-text-field>
                </v-col>
              </v-row>
              <atoms-button
                :loading="$store.state.loading"
                type="submit"
                :style="{ width: '100%' }"
                class="primary mt-4"
                >Daftarkan Client</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  data() {
    return {
      logo: null,
      code: '',
      name: '',
      npwp: '',
      website: '',
      address: '',
      companyType: '',
      description: '',
      contactPerson: {
        name: '',
        phone: '',
        email: '',
      },
      socials: {
        ig: {
          mediaName: 'instagram',
          url: '',
        },
        fb: {
          mediaName: 'facebook',
          url: '',
        },
        yt: {
          mediaName: 'youtube',
          url: '',
        },
      },
      duplicate: {
        name: '',
        email: '',
      },
      createdBy: '',
      valid: true,
    }
  },
  watch: {
    code: function (val) {
      if (val) {
        this.$nextTick(() => (this.code = String(val).toUpperCase()))
      }
    },
  },
  methods: {
    openUploader() {
      if (this.$refs.uploader) {
        this.$refs.uploader.click()
      }
    },
    async onPhotoChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 3000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 3mb!`,
        })
      } else if (file) {
        this.logo = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.logo = null
      }
    },
    async preValidate() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.contactPerson.email || '', $options: 'i' }
      try {
        if (this.contactPerson.email) {
          this.contactPerson.email = this.contactPerson.email.trim()
          this.contactPerson.email =
            this.contactPerson.email.split('@')[0].replaceAll('.', '') + '@' + this.contactPerson.email.split('@')[1]
          const { result: client } = await this.$api.get(`Clients/count`, {
            params: {
              jsonQuery: JSON.stringify({
                'contactPerson.email': term,
              }),
            },
          })
          const { result: user } = await this.$api.get(`Users/isExist`, {
            params: {
              jsonQuery: JSON.stringify({
                email: term,
              }),
            },
          })
          if (client > 0 || user) {
            this.duplicate.email = this.contactPerson.email
          } else {
            this.duplicate.email = ''
          }
        }
        if (this.name) {
          const check = await this.$api.get(`Clients/count`, {
            params: {
              jsonQuery: JSON.stringify({
                name: { $regex: this.name || '', $options: 'i' },
              }),
            },
          })
          if (check.result > 0) {
            this.duplicate.name = this.name
          } else {
            this.duplicate.name = ''
          }
        }
        await this.validate()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()

      try {
        if (this.valid) {
          await this.$store.dispatch('client/register', {
            code: this.code,
            name: this.name,
            npwp: this.npwp,
            email: this.contactPerson.email,
            website: this.web,
            address: this.address,
            contactNum: this.contactPerson.phone,
            contactName: this.contactPerson.name,
            companyType: this.companyType,
            description: this.description,
            logo: this.logo?.image,
            socialMedia: [this.socials.ig, this.socials.fb, this.socials.yt],
            createdBy: this.$store?.state?.user?._id,
            // auto approved
            approved: true,
            active: true,
          })
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$showDialog({
            title: 'Berhasil',
            body: `Client baru berhasil didaftarkan`,
          })
          this.$emit('closed')
        } else {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian`,
          })
        }
      } catch (error) {
        Promise.reject(error)
      }
    },
  },
}
</script>
