import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import { roles } from '@/plugins/helpers'

// export const roles = {
//   All: "All",
//   Super_Admin: "Super Admin",
//   Manager: "Manager",
//   Admin: "Admin",
//   Staff: "Staff",
//   Sales: "Sales",
//   Area_Coordinator: "Area Coordinator",
//   Freelance: "Freelance",
//   Client: "Client",
//   Worker: "Worker",
// }

const layouts = {
  authLayout: 'authLayout',
  blankLayout: 'blankLayout',
  singleLayout: 'singleLayout',
}

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      title: 'Login',
      desc: 'Masuk Ke Aplikasi',
      layout: layouts.authLayout,
      hidden: true,
      validate: (state, to, from, next) => {
        if (state.user?.logged || router.app.$storage.getItem('logged')) {
          next({ path: '/beranda' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/beranda',
    name: 'Beranda',
    component: () => import('../views/Home'),
    meta: {
      title: 'Beranda',
      icon: 'view-dashboard',
      desc: 'Halaman pintas untuk anda',
      type: [
        roles.All,
        // break: true
      ],
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users'),
    meta: {
      title: 'Users',
      icon: 'account-multiple',
      desc: 'Kelola Semua Users Aplikasi',
      type: [roles.Super_Admin, roles.Admin, roles.Manager],
      // break: true
    },
  },
  {
    path: '/clients',
    name: 'clients',
    redirect: '/clients/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/clients/review',
        component: () => import('@/views/Clients'),
        meta: {
          title: 'Review Client',
          parent: 'clients',
          desc: 'Cek dan kelola clients Hayokerja',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
        },
      },
      {
        path: '/clients/members',
        component: () => import('@/views/Clients/Members'),
        meta: {
          title: 'Members',
          parent: 'clients',
          desc: 'Cek dan kelola member clients Hayokerja',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
          // break: true
        },
      },
    ],
    meta: {
      title: 'Clients',
      icon: 'domain',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
      // break: true
    },
  },
  {
    path: '/workers',
    name: 'workers',
    redirect: '/workers/approve',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/workers/approve',
        component: () => import('@/views/Workers/Approve'),
        meta: {
          title: 'Approve Pekerja',
          parent: 'workers',
          desc: 'Cek dan kelola pekerja baru',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
        },
      },
      {
        path: '/workers/review',
        component: () => import('@/views/Workers'),
        meta: {
          title: 'Pekerja',
          parent: 'workers',
          desc: 'Cek dan kelola pekerja',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
        },
      },
      {
        path: '/workers/captain',
        component: () => import('@/views/Workers/Captain'),
        meta: {
          title: 'Kapten',
          parent: 'workers',
          desc: 'Cek dan kelola kapten dari Area',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
        },
      },
      {
        path: '/workers/balances',
        component: () => import('@/views/Workers/Balances'),
        meta: {
          title: 'Saldo (BETA)',
          parent: 'workers',
          desc: 'Cek Saldo Pekerja',
          type: [roles.Super_Admin, roles.Finance],
        },
      },
      {
        path: '/workers/balances-approval',
        component: () => import('@/views/Workers/BalanceApproval'),
        meta: {
          title: 'Saldo Approval',
          parent: 'workers',
          desc: 'Approval Saldo Pekerja',
          type: [roles.Super_Admin],
        },
      },
      {
        path: '/workers/request-payment',
        component: () => import('@/views/Workers/RequestPayment'),
        meta: {
          title: 'Request Payment',
          parent: 'workers',
          desc: 'Approve Saldo Pekerja',
          type: [roles.Super_Admin, roles.Finance],
        },
      },
    ],
    meta: {
      title: 'Pekerja',
      icon: 'account-group',
      desc: 'Kelola Semua Pekerja',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
      // break: true
    },
  },
  {
    path: '/areas',
    name: 'Areas',
    redirect: '/areas/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/areas/review',
        component: () => import('@/views/Areas/Test'),
        meta: {
          title: 'Review Area',
          parent: 'areas',
          desc: 'Cek dan kelola Areas',
          type: [roles.Super_Admin, roles.Admin, roles.Manager],
        },
      },
      // {
      //   path: "/areas/test",
      //   component: () => import("@/views/Areas/Test"),
      //   meta: {
      //     title: "Beta Area",
      //     parent: "areas",
      //     desc: "Cek dan kelola Areas",
      //     type: [roles.Super_Admin, roles.Admin, roles.Manager],
      //   },
      // },
      {
        path: '/areas/location',
        component: () => import('@/views/Areas/Locations'),
        meta: {
          title: 'Lokasi',
          parent: 'areas',
          desc: 'Cek dan kelola Lokasi dari Area',
          type: [roles.Super_Admin, roles.Admin, roles.Manager],
        },
      },
      {
        path: '/areas/location/submit',
        props: true,
        component: () => import('../views/Areas/LocationSubmit'),
        meta: {
          hidden: true,
          title: 'Submit Lokasi',
          layout: layouts.singleLayout,
          desc: 'Submit lokasi area Anda',
          type: [roles.Super_Admin, roles.Admin, roles.Manager],
        },
      },
    ],
    meta: {
      title: 'Areas',
      icon: 'map-marker',
      desc: 'Kelola Semua Area',
      type: [roles.Super_Admin, roles.Admin, roles.Manager],
      // break: true
    },
  },
  {
    path: '/attendance',
    name: 'Attendance',
    redirect: '/attendance/terbaru',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/attendance/terbaru',
        component: () => import('@/views/Attendance'),
        meta: {
          title: 'Review Absensi',
          parent: 'Absensi',
          desc: 'Cek dan kelola Absensi terbaru',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
        },
      },
      {
        path: '/attendance/semua',
        component: () => import('@/views/Attendance/Semua'),
        meta: {
          title: 'Semua',
          parent: 'Absensi',
          desc: 'Cek dan kelola seluruh Absensi',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
        },
      },
      {
        path: '/attendance/riwayat',
        component: () => import('@/views/Attendance/Riwayat'),
        meta: {
          title: 'Riwayat',
          parent: 'Absensi',
          desc: 'Cek detail seluruh absensi pekerja',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
        },
      },
    ],
    meta: {
      title: 'Absensi',
      icon: 'format-list-text',
      desc: 'Kelola Semua Absensi',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
      // break: true
    },
  },
  {
    path: '/shifts',
    name: 'shifts',
    redirect: '/shifts/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/shifts/review',
        component: () => import('@/views/Shifts'),
        meta: {
          title: 'Review Shifts',
          parent: 'Shifts',
          desc: 'Cek Shifts',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
        },
      },
    ],
    meta: {
      title: 'Shifts',
      icon: 'account-child-circle',
      desc: 'Kelola semua Shift anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
      // break: true
    },
  },
  {
    path: '/quotations',
    name: 'Quotations',
    redirect: '/quotations/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/quotations/review',
        component: () => import('@/views/Quotations'),
        meta: {
          title: 'Review Kuotasi',
          parent: 'Kuotasi',
          desc: 'Cek dan kelola Kuotasi',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
        },
      },
      {
        path: '/quotations/generate',
        component: () => import('@/views/Quotations/Generate'),
        meta: {
          title: 'Generate Kuotasi',
          parent: 'Kuotasi',
          // break: true,
          desc: 'Generate Kuotasi',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
        },
      },
      {
        path: '/quotations/generate/:_id',
        component: () => import('@/views/Quotations/Generate'),
        meta: {
          title: 'Renewal Kuotasi',
          parent: 'Kuotasi',
          hidden: true,
          desc: 'Renewal Kuotasi',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
        },
      },
      {
        path: '/quotations/purge-delete',
        component: () => import('@/views/Quotations/PurgeDelete'),
        meta: {
          title: 'Hapus Permanen Kuotasi',
          parent: 'Kuotasi',
          // break: true,
          desc: 'Hapus Permanen Kuotasi',
          type: [roles.Super_Admin],
        },
      },
    ],
    meta: {
      title: 'Kuotasi',
      icon: 'sticker-text',
      desc: 'Kelola Semua Kuotasi',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
      // break: true
    },
  },
  {
    path: '/cetak-kuotasi',
    name: 'cetak-kuotasi',
    component: () => import(/* webpackChunkName: "about" */ '../views/Quotations/Print'),
    meta: {
      title: 'cetak',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
      layout: layouts.blankLayout,
      hidden: true,
    },
  },
  {
    path: '/cetak-shift/:_id',
    name: 'cetak-shift',
    component: () => import(/* webpackChunkName: "about" */ '../views/Shifts/Print'),
    meta: {
      title: 'cetak',
      type: [roles.Supervisor, roles.Admin],
      layout: layouts.blankLayout,
      manualAuth: true,
      hidden: true,
    },
  },
  {
    path: '/cetak-invoice',
    name: 'cetak-invoice',
    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices/Print'),
    meta: {
      title: 'cetak',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
      layout: layouts.blankLayout,
      hidden: true,
    },
  },
  {
    path: '/invoices',
    name: 'Invoices',
    redirect: '/invoices/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/invoices/review',
        component: () => import('@/views/Invoices'),
        meta: {
          title: 'Review Invoice',
          parent: 'Invoice',
          desc: 'Cek dan kelola Invoice',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
        },
      },
      {
        path: '/invoices/generate',
        component: () => import('@/views/Invoices/Generate'),
        meta: {
          title: 'Generate Invoice',
          parent: 'Invoice',
          desc: 'Generate Invoice Non PI',
          // break: true,
          type: [roles.Super_Admin, roles.Admin], //, roles.Manager, roles.Sales],
        },
      },
      {
        path: '/invoices/purge-delete',
        component: () => import('@/views/Invoices/PurgeDelete'),
        meta: {
          title: 'Hapus Permanen Invoice',
          parent: 'Invoice',
          // break: true,
          desc: 'Hapus Permanen Invoice',
          type: [roles.Super_Admin],
        },
      },
    ],
    meta: {
      title: 'Invoice',
      icon: 'receipt',
      desc: 'Kelola Semua Invoice',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Sales],
    },
  },
  {
    path: '/salary_weeks',
    name: 'Upah Mingguan',
    redirect: '/salary_weeks/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/salary_weeks/review',
        component: () => import('@/views/SalaryWeeks/review'),
        meta: {
          title: 'Review Upah Mingguan',
          parent: 'Upah Mingguan',
          desc: 'Cek dan kelola Upah Mingguan',
          type: [roles.Super_Admin, roles.Finance],
        },
      },
    ],
    meta: {
      title: 'Upah Mingguan',
      icon: 'calendar-week-begin',
      desc: 'Kelola Upah Mingguan',
      // break: true,
      type: [roles.Super_Admin, roles.Finance],
    },
  },
  {
    path: '/cases',
    name: 'Kasus',
    redirect: '/cases/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/cases/review',
        component: () => import('@/views/Cases'),
        meta: {
          title: 'Review Kasus',
          parent: 'Kasus',
          desc: 'Cek dan kelola Kasus',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
        },
      },
    ],
    meta: {
      title: 'Kasus',
      icon: 'briefcase-search',
      desc: 'Kelola semua kasus anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Area_Coordinator],
      // break: true
    },
  },
  // {
  //   path: "/blast",
  //   name: "Blast",
  //   redirect: '/blast/whatsapp',
  //   component: () => import("@/components/Molecules/NestedWrapper"),
  //   children: [
  //     {
  //       path: '/blast/whatsapp',
  //       component: () => import('@/views/Blast'),
  //       meta: {
  //         title: 'Blast Whatsapp',
  //         parent: 'Blast',
  //         desc: 'Lakukan Blast Whatsapp',
  //         type: [roles.All]
  //       }
  //     },
  //     {
  //       path: '/blast/configuration',
  //       component: () => import('@/views/Blast/Configuration'),
  //       meta: {
  //         title: 'Blast Configuration',
  //         parent: 'Blast',
  //         desc: 'Konfigurasi Fitur Blast',
  //         type: [roles.All]
  //       }
  //     },
  //   ],
  //   meta: {
  //     title: 'Blast',
  //     icon: 'forum-outline',
  //     desc: 'Blast pesan melalui whatsapp',
  //     type: [roles.All],
  //     break: true
  //   }
  // },
  {
    path: '/sales',
    name: 'Sales',
    redirect: '/sales/b2b',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/sales/b2b',
        component: () => import('@/views/Sales/Test'),
        meta: {
          title: 'Review Bussines to Bussines',
          parent: 'Sales',
          desc: 'Cek dan kelola bussines to bussines',
          type: [roles.Super_Admin, roles.Admin, roles.Manager],
        },
      },

      // {
      //   path: '/sales/lookup',
      //   component: () => import('@/views/Sales/Lookup'),
      //   meta: {
      //     title: 'Review Lookup Pipeline',
      //     parent: 'Sales',
      //     desc: 'Cek dan kelola bussines to bussines',
      //     type: [roles.Super_Admin, roles.Admin, roles.Manager],
      //   },
      // },
      // {
      //   path: '/sales/split',
      //   component: () => import('@/views/Sales/Split'),
      //   meta: {
      //     title: 'Review Split Query',
      //     parent: 'Sales',
      //     desc: 'Cek dan kelola bussines to bussines',
      //     type: [roles.Super_Admin, roles.Admin, roles.Manager],
      //   },
      // },
      {
        path: '/sales/summary',
        component: () => import('@/views/Sales/Summary'),
        meta: {
          title: 'Summary',
          parent: 'Sales',
          desc: 'Cek summary sales',
          type: [roles.Super_Admin, roles.Admin, roles.Manager],
        },
      },
    ],
    meta: {
      title: 'Sales',
      icon: 'handshake',
      desc: 'Kelola semua data sales',
      type: [roles.Super_Admin, roles.Admin, roles.Manager],
      // break: true
    },
  },

  {
    path: '/promotion',
    name: 'Promosi',
    redirect: '/promotion/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/promotion/review',
        component: () => import('@/views/Promotion'),
        meta: {
          title: 'Review Promosi',
          parent: 'Promosi',
          desc: 'Cek dan kelola Promosi',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
        },
      },
    ],
    meta: {
      title: 'Promosi',
      icon: 'bullhorn',
      desc: 'Kelola semua promosi anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
      // break: true
    },
  },
  {
    path: '/testimonial',
    name: 'Testimonial',
    redirect: '/testimonial/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/testimonial/review',
        component: () => import('@/views/Testimonial'),
        meta: {
          title: 'Review Testimonial',
          parent: 'Testimonial',
          desc: 'Cek dan kelola Testimonial',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
        },
      },
    ],
    meta: {
      title: 'Testimonial',
      icon: 'star',
      desc: 'Kelola semua testimonial anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
      // break: true
    },
  },
  {
    path: '/blog',
    name: 'Blog',
    redirect: '/blog/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/blog/review',
        component: () => import('@/views/Blog'),
        meta: {
          title: 'Review Artikel',
          parent: 'Blog',
          desc: 'Cek dan kelola Artikel',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff, roles.Freelance],
        },
      },
    ],
    meta: {
      title: 'Blog',
      icon: 'newspaper-variant',
      desc: 'Kelola semua artikel anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff, roles.Freelance],
    },
  },
  {
    path: '/headhunters',
    name: 'HeadHunters',
    redirect: '/headhunters/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/headhunters/review',
        component: () => import('@/views/HeadHunters'),
        meta: {
          title: 'Review HeadHunters',
          parent: 'HeadHunters',
          desc: 'Cek dan kelola HeadHunters',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
        },
      },
    ],
    meta: {
      // navigations
      title: 'HeadHunters',
      icon: 'account-tie-voice',
      desc: 'Kelola semua HeadHunters anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
      // break: true
    },
  },
  {
    path: '/vacancy',
    name: 'Lowongan',
    redirect: '/vacancy/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/vacancy/review',
        component: () => import('@/views/Vacancy'),
        meta: {
          title: 'Review Lowongan',
          parent: 'Lowongan',
          desc: 'Cek dan kelola Lowongan',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
        },
      },
      {
        path: '/lowongan/detail/:_id',
        component: () => import('../views/Vacancy/Detail'),
        meta: {
          hidden: true,
          title: 'Detail Lowongan',
          layout: layouts.singleLayout,
          parent: '/lowongan',
          desc: 'Lihat detail lowongan Anda',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
        },
      },
      {
        path: '/lowongan/pelamar',
        props: true,
        component: () => import('../views/Vacancy/Applicant'),
        meta: {
          hidden: true,
          title: 'Detail Pelamar',
          layout: layouts.singleLayout,
          parent: '/lowongan',
          desc: 'Lihat detail pelamar Anda',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
        },
      },
    ],
    meta: {
      title: 'Lowongan',
      icon: 'text-box',
      desc: 'Kelola semua Lowongan anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
      // break: true
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    redirect: '/faq/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/faq/review',
        component: () => import('@/views/Faq'),
        meta: {
          title: 'Review Faq',
          parent: 'FAQ',
          desc: 'Cek dan kelola Faq',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
        },
      },
    ],
    meta: {
      title: 'Kelola FAQ',
      icon: 'frequently-asked-questions',
      desc: 'Kelola semua Faq anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
      // break: true
    },
  },
  {
    path: '/redirects',
    name: 'Redirects',
    redirect: '/redirects/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/redirects/review',
        component: () => import('@/views/Redirects'),
        meta: {
          title: 'Review Redirects',
          parent: 'Redirects',
          desc: 'Cek dan kelola Redirects',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
        },
      },
    ],
    meta: {
      title: 'Redirects',
      icon: 'link-variant',
      desc: 'Kelola semua redirects anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
      // break: true
    },
  },
  {
    path: '/referrals',
    name: 'Referrals',
    redirect: '/referrals/review',
    component: () => import('@/components/Molecules/NestedWrapper'),
    children: [
      {
        path: '/referrals/review',
        component: () => import('@/views/Referrals'),
        meta: {
          title: 'Review Referrals',
          parent: 'Referrals',
          desc: 'Cek dan kelola Referral',
          type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
        },
      },
    ],
    meta: {
      title: 'Kelola Referral',
      icon: 'clipboard-list-outline',
      desc: 'Kelola semua Referral anda',
      type: [roles.Super_Admin, roles.Admin, roles.Manager, roles.Staff],
      // break: true
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      type: [roles.All],
      hidden: true,
      layout: layouts.blankLayout,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // renew strategy unstable:!commit
  store.commit('LOG', 'validating')
  if (document) {
    document.documentElement.style.overflow = 'auto'
  }
  if (router.app.$storage.getItem('logged')) {
    // console.log('isBreak', to, to.meta?.break || false)
    store.commit('SET', {
      break: to.meta?.break || false,
      allow: to.meta?.type?.find((x) => store.state.user?.roles?.includes(x) || x === roles.All) ? true : false,
    })
    if (to?.name == '404') {
      to.meta.layout = 'default'
    }
    return (typeof to.meta?.validate == 'function' && to.meta?.validate(store.state, to, from, next)) || next()
  }
  return (!to.fullPath.includes('login') && next({ path: '/login' })) || next()
})

router.afterEach(async (to, from) => {
  const token = router.app.$storage.getItem('_credentials'),
    logged = router.app.$storage.getItem('logged'),
    user = router.app.$storage.getItem('_user')
  store.commit('SET', {
    loading: true,
  })
  try {
    if (token && user) {
      await router.app.$api
        .post('Users/isLogin', {
          token: token._rt,
        })
        .then(async ({ result }) => {
          if (result === false) {
            await store.dispatch('user/logout')
          } else {
            await store.dispatch('user/status')
          }
        })
        .catch((err) => {
          Promise.reject(err)
        })
    } else if (logged) {
      await store.dispatch('user/logout')
    }
  } catch (err) {
    return Promise.reject(err)
  } finally {
    store.commit('SET', {
      init: false,
      loading: false,
    })
  }
})

export default router
