var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.$store.state.user.isLogged)?_c('div',[_c('v-scroll-x-transition',[_c('molecules-sidebar')],1),_c('v-main',{staticClass:"background",staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"primary white--text pa-2"},[_vm._v(" Beberapa fitur baru yang sudah bisa diakses : Clients, Member, Absensi pada laman "),_c('a',{staticClass:"white",attrs:{"href":"https://hayosystems-v3.web.app/dashboard"}},[_vm._v("https://hayosystems-v3.web.app/dashboard")])]),_c('molecules-wrapper',[_c('molecules-topbar'),(_vm.$store.state.break)?_c('div',[_c('v-container',{staticClass:"d-flex flex-column justify-center align-center",style:({
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            })},[_c('atoms-image',{attrs:{"width":300,"src":"/assets/maintenance.svg"}}),_c('atoms-title',{staticClass:"pt-8 px-8 text-center",attrs:{"h2":true}},[_vm._v("Halaman ini sedang kami perbaiki ")]),_c('atoms-text',{staticClass:"pa-4"},[_vm._v("Mohon maaf atas ketidaknyamanannya")])],1)],1):_c('div',[_vm._t("default"),_c('atoms-button',{class:['rounded-lg ma-5 pa-5 background', _vm.$store.state.dark ? 'white--text' : 'black--text'],staticStyle:{"position":"fixed","right":"0","bottom":"0"},style:({
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }),attrs:{"icon":""},on:{"click":() => {
                _vm.$vuetify.theme.dark = !_vm.$store.state.dark ? true : false
                _vm.$store.commit('setTheme', !_vm.$store.state.dark ? true : false)
              }}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("mdi-"+_vm._s(_vm.$store.state.dark ? 'white-balance-sunny ' : 'weather-night'))])],1)],2)],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }