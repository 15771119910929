import router from '@/router/index'
/* eslint-disable */
const collection = 'Workers'
const defaultPipeline = [
  {
    $lookup: {
      from: 'Clients',
      localField: 'clientIds',
      foreignField: '_id',
      as: 'clients',
    },
  },
  {
    $lookup: {
      from: 'WorkerDetails',
      localField: 'detailId',
      foreignField: '_id',
      as: 'detail',
    },
  },
  {
    $unwind: {
      path: '$detail',
      preserveNullAndEmptyArrays: true,
    },
  },
]
const initial = () => ({
  data: null,
  total: 0,
  currentDay: 0,
  within7Days: 0,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async exists({ state }, query) {
      return await this._vm.$api
        .get('Users/isExist', {
          params: {
            jsonQuery: JSON.stringify(query),
          },
        })
        .catch((error) => Promise.reject(error))
    },
    async summary({ commit }, query) {
      // get total
      const countPipeline = [
        {
          $group: {
            _id: {
              $dateToString: {
                format: '%Y-%m-%d',
                date: '$_createdDate',
              },
            },
            total: { $sum: 1 },
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]
      await this._vm.$api
        .get(collection + '/getMongo', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [
                {
                  $facet: {
                    data: [],
                    info: [
                      {
                        $count: 'count',
                      },
                    ],
                  },
                },
                {
                  $project: {
                    count: '$info.count',
                  },
                },
                {
                  $unwind: {
                    path: '$count',
                    preserveNullAndEmptyArrays: true,
                  },
                },
              ],
            }),
          },
        })
        .then(({ result, ...rest }) =>
          commit('SET', {
            total: result?.[0]?.count || 0,
          }),
        )

      Promise.all([
        this._vm.$api
          .get(collection + '/getMongo', {
            params: {
              jsonQuery: JSON.stringify({
                ...query,
                pipeline: [
                  ...countPipeline,
                  {
                    $match: {
                      _id: this._vm.$moment().format('YYYY-MM-DD'),
                    },
                  },
                ],
              }),
            },
          })
          .then(({ result }) => result),
        this._vm.$api
          .get(collection + '/getMongo', {
            params: {
              jsonQuery: JSON.stringify({
                ...query,
                pipeline: [
                  ...countPipeline,
                  {
                    $match: {
                      _id: {
                        $lte: this._vm.$moment().format('YYYY-MM-DD'),
                        $gte: this._vm.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
                      },
                    },
                  },
                ],
              }),
            },
          })
          .then(({ result }) => result),
      ]).then(([day, days]) => {
        commit('SET', {
          currentDay: day?.[0],
          within7Days: days,
        })
      })
    },
    async get({ state }, { query, custom }) {
      return await this._vm.$api
        .get(collection + '/getMongo', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [...defaultPipeline],
              ...custom,
            }),
            limit: 1,
          },
        })
        .then(({ result, ...rest }) => {
          return result?.[0]
        })
        .catch((error) => Promise.reject(error))
    },
    async getAll({ commit, state }, { query, limit, page, custom, returnOnly }) {
      return await this._vm.$api
        .get(collection + '/getMongo', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [...defaultPipeline],
              ...custom,
            }),
            page: page || 1,
            limit: limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          if (!returnOnly) {
            commit('SET', {
              data: result,
              ...rest,
            })
          }
          return { result, ...rest }
        })
        .catch(async (err) => Promise.reject(err))
    },
    async getWorkerBalances({ commit, state }, { query, limit, page, custom, returnOnly }) {
      return await this._vm.$api
        .get('WorkerBalances/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [...defaultPipeline],
              ...custom,
            }),
            page: page || 1,
            limit: limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          const data =
            result?.map(({ worker, ...item }) => {
              delete worker._createdDate
              delete worker._updatedDate
              delete worker.balance
              delete worker._id
              return {
                ...worker,
                ...item,
              }
            }) || []
          if (!returnOnly)
            commit('SET', {
              data,
              ...rest,
            })
          return data
        })
        .catch(async (err) => Promise.reject(err))
    },

    async update({ commit }, payload) {
      return await this._vm.$api
        .put(collection + '/setWorkerData', {
          sendMongo: {
            ...payload,
          },
        })
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
    async remove({ state }, payload) {
      if (payload) {
        return await this._vm.$api
          .delete(collection + '/removeWorker', {
            params: {
              _id: payload,
            },
          })
          .then(async ({ success }) => success)
          .catch((error) => Promise.reject(error || 'Ada kesalahan'))
      } else {
        throw new Error('Payload tidak terdefinisi')
      }
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
