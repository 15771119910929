var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-scroll-x-transition',[_c('molecules-sidebar')],1),_c('v-main',{staticClass:"background"},[_c('div',{staticClass:"d-flex align-center justify-left accent pa-3",style:({
        'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
      })},[_c('atoms-button',{staticClass:"rounded-pill",attrs:{"icon":""},on:{"click":function($event){_vm.$router.go(-1) || _vm.$router.push(_vm.$route.meta.parent)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('atoms-title',{staticClass:"pl-3",attrs:{"h3":"true"}},[_vm._v(" "+_vm._s(_vm.$toCapitalize(_vm.$route.meta.title || _vm.$route.meta.parent || ''))+" ")])],1),_c('molecules-wrapper',[(_vm.$store.state.break)?_c('v-container',{staticClass:"d-flex flex-column justify-center align-center",style:({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        })},[_c('v-img',{attrs:{"width":"300","src":'/maintenance.svg'}}),_c('atoms-title',{staticClass:"pt-8 px-8 text-center",attrs:{"h2":true}},[_vm._v("Halaman ini sedang kami perbaiki ")]),_c('atoms-text',{staticClass:"pa-4"},[_vm._v("Mohon maaf atas ketidaknyamanannya")])],1):_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }