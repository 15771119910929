var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: '90%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v(_vm._s(!_vm.id ? 'Tambah' : 'Perbarui')+" Redirect")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-text-field',{attrs:{"label":"Url","rules":_vm.rules.default},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c('v-col',{attrs:{"cols":"10","align":"start"}},[_c('atoms-text-field',{attrs:{"label":"Keterangan"}},[_c('v-textarea',{staticClass:"rounded-lg",attrs:{"background-color":"accent","placeholder":"Keterangan URL","loader-height":"100","hide-details":"","dense":"","solo":"","flat":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-button',{class:['primary', 'mt-4'],style:({ width: '100%' }),attrs:{"type":"submit","loading":_vm.$store.state.loading}},[_vm._v("Simpan")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }