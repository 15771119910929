<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container v-if="data">
        <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>Edit Area</atoms-title>
              <atoms-text>{{ data.title }}</atoms-text>
            </v-col>
            <v-col cols="10">
              <atoms-text-field
                label="Nama Area"
                mask="AAA"
                v-model="area"
                :rules="[
                  (v) => !!v || 'Harap diisi!',
                  (v) => (!!v && v.length === 3) || 'Terdiri dari 3 huruf!',
                  (v) => (!!v && duplicate !== v) || '(Nama sudah terdaftar) Gunakan nama lain!',
                ]"
              />
              <v-checkbox
                v-model="inactive"
                class="mb-4 text-caption"
                label="Area Tidak Aktif"
                dense
                hide-details
              ></v-checkbox>
              <atoms-button :loading="$store.state.loading" type="submit" :style="{ width: '100%' }" class="primary"
                >Perbarui Area</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>

        <!-- <v-row justify="center">
          <v-col cols="12">
            <atoms-text >
              <v-icon
                left
                style="cursor: pointer"
                @click="
                  $showDialog({
                    title: 'Informasi',
                    body: `Klik link di-samping untuk mengelola lokasi di area ${data.title}`,
                  })
                "
                >mdi-information</v-icon
              ><a @click="$router.push(`/areas/location?direct=${data._id}`)"
                >Kelola Lokasi Area {{ data.title }}</a
              >
            </atoms-title>
          </v-col>
        </v-row> -->
        <v-row justify="center" class="mt-5">
          <v-col cols="10">
            <atoms-title :h3="true">
              <v-icon
                left
                style="cursor: pointer"
                @click="
                  $showDialog({
                    title: 'Informasi',
                    body: `Mengedit Lokasi dari Area akan diarahkan ke halaman Lokasi`,
                  })
                "
                >mdi-information</v-icon
              >Lokasi Area {{ data.title }}
            </atoms-title>
          </v-col>
          <v-col cols="10">
            <v-data-table
              :headers="[
                {
                  text: 'Lokasi',
                  sortable: false,
                  value: 'name',
                  width: '40%',
                  class: 'primary--text text-uppercase',
                },
                {
                  text: '',
                  sortable: false,
                  value: 'action',
                  class: 'text-uppercase',
                },
              ]"
              light
              :items="data.locations"
              class="accent rounded-lg"
              hide-default-footer
              hide-default-header
            >
              <template v-slot:[`header`]="{ props }">
                <thead>
                  <tr class="d-table-row">
                    <th v-for="h in props.headers" :key="h.value" :class="h.class">
                      <span>{{ h.text }}</span>
                    </th>
                  </tr>
                </thead></template
              >

              <template v-slot:[`item`]="{ item }">
                <tr class="d-table-row">
                  <td class="text-left">{{ item.name }}</td>
                  <td
                    v-if="$vuetify.breakpoint.smAndDown"
                    :style="{
                      width: '100%',
                    }"
                  />

                  <td class="text-right">
                    <v-icon
                      small
                      light
                      @click="
                        ($event) => {
                          const routeData = $router.resolve({
                            path: '/areas/location/submit',
                            query: {
                              targetArea: $encodeObject(data),
                              targetLocation: $encodeObject(item),
                            },
                          })
                          $window.open(routeData.href, '_blank')
                        }
                      "
                    >
                      mdi-pencil
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="10">
            <atoms-button
              @click="$router.push(`/areas/location?direct=${data._id}`)"
              :style="{ width: '100%' }"
              class="primary"
              >Kelola Lokasi</atoms-button
            >
          </v-col>
        </v-row>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      area: '',
      name: '',
      inactive: false,
      duplicate: '',
      duplicateLocation: '',
      address: '',
      valid: true,
    }
  },
  watch: {
    area() {
      this.area = this.area.toUpperCase()
    },
  },
  mounted() {
    if (!this.data || !this.data?._id) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal ketika memuat data`,
      })
      this.$emit('closed')
    } else {
      this.area = this.data?.title
      this.inactive = this.data?.inactive
    }
  },
  methods: {
    async preValidate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$refs.form.validate()
        if (this.valid && this.area) {
          await this.$store
            .dispatch('area/checkArea', {
              areaId: this.data._id,
              areaTitle: this.area,
              clientId: this.data.clientId,
            })
            .then(async (e) => {
              if (e) {
                if (this.area !== this.data.title) {
                  this.duplicate = this.area
                }
              } else {
                this.duplicate = ''
              }
            })
          await this.validate()
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()
      if (this.valid) {
        await this.$store
          .dispatch('area/edit', {
            _id: this.data?._id,
            title: this.area,
            inactive: this.inactive,
          })
          .then((e) => {
            if (e) {
              this.$showDialog({
                title: 'Berhasil',
                body: `Area ${this.data?.title} berhasil diperbarui!`,
              })
              this.data.title = this.area
              this.data.inactive = this.inactive
              this.$refs.form.reset()
              this.$refs.form.resetValidation()
              this.$emit('closed')
            }
          })
          .catch((err) => {
            throw new Error(err)
          })
      } else {
        this.$showDialog({
          title: 'Gagal',
          body: `Periksa kembali kolom pengisian`,
        })
      }
    },
  },
}
</script>
