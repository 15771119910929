<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: '90%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="onClose"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="validate">
          <v-row justify="center">
            <v-col cols="10" order="1">
              <atoms-title>{{ !id ? 'Tambah' : 'Perbarui' }} Artikel</atoms-title>
            </v-col>
            <v-col cols="10" md="5" order="1" order-md="2">
              <v-hover>
                <div slot-scope="{ hover }">
                  <atoms-image
                    class="rounded-lg"
                    style="background: var(--v-accent-base)"
                    :contain="true"
                    :maxH="200"
                    :src="
                      (picture && picture.url) ||
                      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDa0FuU-fmH_q3jM0H0nMImMVWRaQZL9TUOA&usqp=CAU' ||
                      '/media/ads-example.webp'
                    "
                  >
                    <v-overlay
                      :value="!$vuetify.breakpoint.smAndDown && picture && picture.url ? (hover ? true : false) : true"
                      opacity="0.5"
                      absolute
                    >
                      <div
                        class="pa-12"
                        :style="{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                        }"
                      >
                        <atoms-text @click="openUploader('picture')" class="font-weight-medium"
                          ><v-icon small left> mdi-camera </v-icon>{{ !picture ? 'Upload' : 'Ganti' }} Foto</atoms-text
                        >

                        <atoms-text
                          @click="
                            picture &&
                              picture.url &&
                              $viewerApi({
                                options: {
                                  toolbar: false,
                                },
                                images: [picture.url],
                              })
                          "
                          class="font-weight-medium mt-4"
                          ><v-icon left small> mdi-eye </v-icon>Perbesar</atoms-text
                        >
                      </div>
                    </v-overlay></atoms-image
                  >
                  <input ref="picture" type="file" accept="image/*" class="d-none" @change="onPictureChange" />
                </div>
              </v-hover>

              <atoms-button @click="openUploader('picture')" class="primary mt-4" style="width: 100%"
                >Ganti Foto Cover</atoms-button
              >
            </v-col>
            <v-col cols="10" md="5" order="2" order-md="1" align="center">
              <v-row align="center" justify="center">
                <v-col cols="12" class="py-0">
                  <atoms-text-field
                    label="Judul Artikel"
                    message="Contoh Artikel : Tips dan Trik Melamar Pekerjaan"
                    v-model="title"
                    :rules="rules.default"
                  />
                </v-col>
                <!-- v-if="!$isRoles(['Freelance'])" -->
                <v-col cols="12" class="py-0">
                  <atoms-text-field label="Status">
                    <v-autocomplete
                      class="rounded-lg"
                      placeholder="Pilih Status"
                      :items="Object.values($postStatus)"
                      :menu-props="{ offsetY: true }"
                      background-color="accent"
                      v-model="status"
                      required
                      solo
                      flat
                      dense
                    />
                  </atoms-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <atoms-text-field label="Kategori">
                    <v-autocomplete
                      class="rounded-lg"
                      placeholder="Pilih Kategori"
                      :items="Object.values($postCategory)"
                      :menu-props="{ offsetY: true }"
                      background-color="accent"
                      :rules="rules.default"
                      v-model="category"
                      required
                      solo
                      flat
                      dense
                    />
                  </atoms-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="10" order="3">
              <atoms-text-field
                label="Tambah Tags"
                v-model="tags.value"
                appendIcon="mdi-plus"
                :appendAction="
                  () => {
                    pushTag(tags.value)
                  }
                "
                @change="$refs.form.validate()"
                :rules="[(v) => (!!tags.items && tags.items.length > 0) || 'Harap diisi!']"
              />

              <atoms-button
                @click="pushTag(tags.value)"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="['primary', 'mb-4']"
                >Tambah</atoms-button
              >
            </v-col>
            <v-col cols="10" order="4">
              <v-data-table
                :headers="[
                  {
                    text: 'Tag',
                    sortable: false,
                    class: 'primary--text text-uppercase text-center',
                  },
                  {
                    text: 'Aksi',
                    sortable: false,
                    value: 'action',
                    class: 'text-uppercase text-center',
                  },
                ]"
                :items="(tags && tags.items) || []"
                class="accent rounded-lg"
                hide-default-footer
                hide-default-header
              >
                <template v-slot:[`header`]="{ props }">
                  <thead>
                    <tr class="d-table-row">
                      <th v-for="h in props.headers" :key="h.value" :class="h.class">
                        <span>{{ h.text }}</span>
                      </th>
                    </tr>
                  </thead></template
                >

                <template v-slot:[`item`]="{ item }">
                  <tr class="d-table-row">
                    <td class="text-center">{{ item }}</td>
                    <td class="text-center">
                      <v-icon small light @click="unPushTag(item)"> mdi-delete </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="10" align="start" order="5">
              <quill-editor
                class="accent rounded-lg"
                :content="content"
                :options="editorOpts"
                @change="onEditorChange($event)"
              >
              </quill-editor>
            </v-col>
            <v-col cols="10" order="6">
              <atoms-button
                type="submit"
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="['primary', 'mb-4']"
                >Simpan</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      payload: null,
      readOnly: false,
      title: '',
      status: '',
      excerpt: '',
      category: '',
      picture: null,
      content: `<h2>Anda bisa menulis konten anda disini</h2><br/><p>Tulis apapun yang bisa anda pikirkan...</p><br/><br/><br/><br/><br/><br/><br/><br/><br/>`,
      tags: {
        value: '',
        items: null,
      },
      editorOpts: {},
      start: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      end: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      rules: {
        default: [(v) => !!v || 'Harap Diisi'],
        picture: [(v) => (v?.size ? v?.size < 5000000 || 'Harus kurang dari 5mb!' : true)],
      },
      valid: true,
    }
  },
  computed: {
    formatStartDate: {
      set(value) {
        this.start.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.start.value).format('DD MMMM YYYY')
      },
    },
    formatEndDate: {
      set(value) {
        this.end.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.end.value).format('DD MMMM YYYY')
      },
    },
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden'
    this.payload = (this.id && this.$store.state.article.data?.find((it) => it?._id === this.id)) || null
    if (this.payload) {
      this.title = this.payload?.title
      this.status = this.payload?.status
      this.excerpt = this.payload?.excerpt
      this.content = this.payload?.content
      this.tags.items = this.payload?.tags
      this.category = this.payload?.category
      this.picture = {
        url: this.payload?.picture,
      }
    } else if (this.id && !this.payload) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal memuat data!`,
      })
      this.onClose()
    }
  },
  methods: {
    onClose() {
      document.documentElement.style.overflow = 'auto'
      this.$emit('close')
      this.$emit('closed')
    },
    openUploader(constant) {
      if (this.$refs[constant]) {
        this.$refs[constant]?.click()
      }
    },
    async onPictureChange(e) {
      const file = e.target.files[0]
      if (file && file.size > 5000000) {
        this.$showDialog({
          title: 'Perhatian',
          body: `Maksimal ukuran foto adalah 5mb!`,
        })
      } else if (file) {
        this.picture = {
          url: URL.createObjectURL(file),
          image: file,
        }
        URL.revokeObjectURL(file) // free memory
      } else {
        this.picture = null
      }
    },
    pushTag(payload) {
      if (!payload) {
        return alert('Harap tag diisi!')
      }
      this.tags.items = [...new Set([payload, ...(this.tags.items || [])])]
      this.tags.value = ''
    },
    unPushTag(payload) {
      if (this.tags.items) {
        this.tags.items = this.tags.items?.filter((it) => it !== payload)
      }
    },
    genExcerpt(str) {
      if (!str) return ''
      if (!(typeof str === 'string')) return ''
      if (str.length > 133) {
        str = str.substring(0, 133) + '...'
      }
      return str
    },
    onEditorChange({ html, text }) {
      this.content = html
      this.excerpt = this.genExcerpt(text)
    },
    async validate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$refs.form.validate()
        if (this.valid) {
          const _id = this.$uuid.v4()
          const payload = {
            ...((this.payload && this.payload) || {}),
            _id: this.id || _id,
            title: this.title,
            picture: this.picture?.image
              ? await this.$uploadFile(this.picture.image, 'Article', _id)
              : this.picture?.url
              ? this.picture.url
              : '',
            content: this.content,
            excerpt: this.excerpt, // show only 133 characters
            status: this.status || this.$postStatus[1], // Published and Drafted
            tags: this.tags.items || [],
            category: this.category,
          }
          if (!this.id) {
            await this.$store.dispatch('article/create', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Artikel berhasil ditambahkan!`,
            })
          } else {
            await this.$store.dispatch('article/update', payload)
            this.$showDialog({
              title: 'Berhasil',
              body: `Artikel berhasil diperbarui!`,
            })
          }

          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.$emit('close')
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
