var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"overflow-x-hidden overflow-y-auto rounded-lg text-center",style:({
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      'min-height': '10%',
    })},[_c('atoms-button',{staticClass:"ma-2",staticStyle:{"position":"fixed","z-index":"2","left":"0"},attrs:{"icon":true},on:{"click":function($event){return _vm.$emit('closed')}}},[_c('v-icon',{class:[_vm.file.data.type.includes('video') && 'white--text']},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"background rounded-lg"},[(_vm.file && _vm.file.data)?_c('div',{staticClass:"accent"},[(_vm.file.data.type.includes('image') || _vm.file.data.type.includes('sticker'))?_c('atoms-image',{staticStyle:{"height":"350px"},attrs:{"enableZoom":true,"src":_vm.file.url,"contain":true}}):(_vm.file.data.type.includes('video'))?_c('div',{staticStyle:{"height":"350px"}},[_c('vue-plyr',{staticClass:"rounded-lg"},[_c('video',{attrs:{"controls":"","crossorigin":"","playsinline":"","data-poster":"wa-video.jpg"}},[_c('source',{attrs:{"size":"720","src":_vm.file.url,"type":_vm.file.data.mimetype}}),_c('track',{attrs:{"default":"","kind":"captions","label":"English captions","src":_vm.file.url,"srclang":"en"}})])])],1):_c('div',{staticClass:"d-flex justify-center align-center flex-column rounded-lg pa-8"},[_c('atoms-image',{staticClass:"ma-4",attrs:{"width":"150","src":"/assets/no-preview.svg"}}),_c('atoms-title',[_vm._v("Tidak dapat menampilkan preview")]),_c('atoms-text',{staticClass:"my-2"},[_vm._v(" "+_vm._s(_vm.file.data.name)+" ")])],1)],1):_vm._e(),_c('atoms-text-field',{attrs:{"noLabel":true}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"disabled":!_vm.$store.state.isReady,"background-color":"background","append-icon":"mdi-send","placeholder":"Caption","hide-details":"","dense":"","solo":"","flat":""}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }