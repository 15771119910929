<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="validate">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>Tambah Area</atoms-title>
            </v-col>
            <v-col cols="10">
              <atoms-text-field
                v-if="!$store.state.client.data || !client.enabled"
                label="Cari Client"
                v-model="term"
                :rules="[]"
              />
              <atoms-text-field v-else label="Pilih Client">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="client.value"
                  :items="$store.state.client.data.map((x) => x.name)"
                  placeholder="Pilih Client"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
              <atoms-button
                @click="
                  () =>
                    !$store.state.client.data || !client.enabled
                      ? getClients()
                      : (function () {
                          client.value = ''
                          client.enabled = false
                          client.item = null
                        })()
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="[!$store.state.client.data || !client.enabled ? 'primary' : 'red white--text', 'mb-4']"
                >{{ !$store.state.client.data || !client.enabled ? 'Cari Client' : 'Reset Pencarian' }}</atoms-button
              >
              <div v-if="client.item" class="text-center my-4">
                <atoms-text class="my-1 font-weight-bold">Client Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Nama:</span> {{ client.item.name }}</atoms-text
                  >
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Alamat:</span>
                    {{ client.item.address.streetAddress1 }}</atoms-text
                  >
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Area Terdaftar:</span>
                    {{ client.item.areas && client.item.areas.map((x) => x.title) }}</atoms-text
                  >
                </div>
              </div>
              <atoms-text-field
                :disabled="!client.item"
                label="Nama Area"
                mask="AAA"
                v-model="areaTitle"
                :rules="[
                  (v) => !!v || 'Harap diisi!',
                  (v) => (!!v && v.length === 3) || 'Terdiri dari 3 huruf!',
                  (v) => (!!v && duplicate !== v) || '(Nama sudah terdaftar) Gunakan nama lain!',
                ]"
              />
              <atoms-button
                :disabled="!client.item"
                :loading="$store.state.loading"
                type="submit"
                :style="{ width: '100%' }"
                class="primary"
                >Tambah Area</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  data() {
    return {
      term: '',
      areaTitle: '',
      client: {
        enabled: false,
        value: '',
        item: null,
      },
      duplicate: '',
      address: '',
      valid: true,
    }
  },
  watch: {
    areaTitle() {
      this.areaTitle = this.areaTitle.toUpperCase()
    },
    'client.value'() {
      if (this.client.value) {
        this.client.item = this.$store.state.client?.data?.find((x) => x.name === this.client.value)
      }
    },
  },
  methods: {
    async getClients() {
      const term = { $regex: this.term || '', $options: 'i' }
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 1,
          limit: 0,
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.$refs.form.validate()
        if (this.valid && this.areaTitle && this.client?.item) {
          await this.$store
            .dispatch('area/checkArea', {
              areaTitle: this.areaTitle,
              clientId: this.client.item?._id,
            })
            .then(async (e) => {
              if (e) {
                this.duplicate = this.areaTitle
              } else {
                this.duplicate = ''
              }
            })
          await this.$refs.form.validate()
          await this.register()
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async register() {
      if (this.valid) {
        await this.$store
          .dispatch('area/register', {
            clientId: this.client.item?._id,
            title: this.areaTitle,
          })
          .then((e) => {
            if (e) {
              this.$showDialog({
                title: 'Berhasil',
                body: `Area ${this.areaTitle} berhasil ditambahkan!`,
              })
              this.$refs.form.reset()
              this.$refs.form.resetValidation()
              this.$emit('closed')
            }
          })
      } else {
        this.$showDialog({
          title: 'Gagal',
          body: `Periksa kembali kolom pengisian`,
        })
      }
    },
  },
}
</script>
