/* eslint-disable */
const collection = 'HeadHunters'
const defaultPipeline = [
  {
    $sort: {
      _createdDate: -1,
    },
  },
  {
    $lookup: {
      from: 'Users',
      localField: 'userId',
      foreignField: '_id',
      as: 'user',
    },
  },
  {
    $unwind: {
      path: '$user',
      preserveNullAndEmptyArrays: true,
    },
  },

  {
    $project: {
      'user.password': 0,
    },
  },
  {
    $lookup: {
      from: 'Vacancies',
      localField: '_id',
      foreignField: 'idHeadHunter',
      as: 'vacancies',
    },
  },
]
const initial = () => ({
  data: null,
  page: 1,
  total: 0,
  length: 0,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            limit: payload?.limit || state.limit,
            page: payload?.page || state.page,
          },
        })
        .then(async ({ result, ...rest }) => {
          const resp = {
            data: result,
            ...rest,
          }
          if (!payload?.returnOnly) {
            commit('SET', resp)
          }
          return resp
        })
        .catch((err) => Promise.reject(err))
    },
    async register({ rootState }, payload) {
      return await this._vm.$api.post(collection + '/register', payload).catch((error) => Promise.reject(error))
    },
    async remove({ state }, memberId) {
      return await this._vm.$api
        .delete(collection + '/remove', {
          params: {
            _id: memberId,
          },
        })
        .catch((error) => Promise.reject(error || 'Ada kesalahan'))
    },
    async update({ state }, payload) {
      return await this._vm.$api.patch(collection + '/update', payload).catch((error) => Promise.reject(error))
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
