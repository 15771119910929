var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    position: 'absolute',
    top: 0,
    left: 0,
  }),attrs:{"id":"dragdrop"},on:{"dragenter":function($event){$event.preventDefault();return _vm.dragenter.apply(null, arguments)},"dragover":function($event){$event.preventDefault();return _vm.dragOver.apply(null, arguments)},"dragleave":_vm.dragleave,"drop":function($event){$event.stopPropagation();$event.preventDefault();return _vm.drop.apply(null, arguments)}}},[_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"multiple":"","type":"file"},on:{"change":_vm.onChange}}),_c('v-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],attrs:{"opacity":"0.5","dark":_vm.$vuetify.theme.dark},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('closed')}}},[_c('div',{staticClass:"background d-flex justify-center align-center flex-column pa-12 rounded-lg",style:({
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
        'min-height': '10%',
      })},[_c('atoms-title',[_vm._v("Lepaskan File Anda Disini")]),_c('atoms-image',{staticClass:"ma-12",attrs:{"width":"250","src":"/assets/add-files.svg"}}),(_vm.file)?_c('atoms-text',{staticClass:"my-2"},[_vm._v(" "+_vm._s(_vm.file.name)+" "),_c('atoms-button',{attrs:{"icon":true},on:{"click":_vm.removeFile}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('atoms-button',{staticClass:"primary",on:{"click":function($event){return _vm.$refs.uploader.click()}}},[_vm._v("Unggah File")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }