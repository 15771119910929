var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: '90%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-container',{staticClass:"px-10"},[(_vm.payload && _vm.client)?_c('v-row',{staticClass:"py-4",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"mb-10",attrs:{"cols":"12"}},[_c('atoms-title',[_vm._v("Lowongan "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.payload.title))])])],1),_c('v-col',{staticClass:"rounded-lg accent",attrs:{"cols":"12","md":"2"}},[_c('v-avatar',{attrs:{"size":"150"}},[(_vm.client.logo)?_c('atoms-image',{attrs:{"contain":"","enableZoom":true,"src":_vm.client.logo}}):_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-domain")])],1)],1),_c('v-col',{class:[
            'd-flex flex-column ',
            _vm.$vuetify.breakpoint.smAndDown ? 'text-left mt-10' : 'pa-10',
            'align-start rounded-lg ',
          ],attrs:{"cols":"10"}},[_c('atoms-title',{attrs:{"h2":true}},[_vm._v(_vm._s(_vm.client.name))]),_c('atoms-text',[_vm._v(_vm._s(_vm.client.address.streetAddress1))]),_c('atoms-text',{staticClass:"mt-3"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.payload.city)+" - "+_vm._s(_vm.payload.province))],1)],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-tabs',{staticClass:"rounded-lg d-flex justify-start align-center px-0 mt-5",attrs:{"background-color":"accent","color":"primary","height":"40","hide-slider":"","show-arrows":_vm.$vuetify.breakpoint.smAndDown ? true : false,"center-active":_vm.$vuetify.breakpoint.smAndDown ? true : false},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((['Konten', 'Daftar Peminat', 'Rincian']),function(i){return _c('v-tab',{key:i,staticClass:"text-capitalize",attrs:{"active-class":"primary lighten-5 primary--text rounded-lg ma-1"}},[_c('atoms-text',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$toCapitalize(i)))])],1)}),1),_c('div',[_c('v-fade-transition',[(_vm.tabs === 0)?_c('v-container',{staticClass:"pa-10 my-5 rounded-lg text-left accent"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.payload.content)}})]):_vm._e()],1),_c('v-fade-transition',[(_vm.tabs === 1)?_c('v-container',{staticClass:"px-0"},_vm._l((_vm.payload.workers || []),function(worker,i){return _c('div',{key:i,class:['accent', 'rounded-lg pa-4 mb-2'],style:({
                    'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 1px 4px',
                  })},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('v-avatar',{staticClass:"rounded-lg"},[(worker && worker.photo)?_c('atoms-image',{staticClass:"rounded-lg",attrs:{"enableZoom":true,"src":worker.photo}}):_c('v-icon',[_vm._v("mdi-account")])],1)],1),_c('v-col',{attrs:{"cols":"8","md":"4","align":"start"}},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(worker.fullName))]),_c('atoms-text',{staticClass:"primary--text"},[_vm._v(_vm._s((worker.address && worker.address.city) || '')+" - "+_vm._s((worker.address && worker.address.province) || ''))])],1),_c('v-col',{attrs:{"align":"start","cols":"12","md":"auto"}},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v("Email: "+_vm._s(worker.email || '-'))]),_c('atoms-text',{staticClass:"primary--text"},[_vm._v("Telp : "+_vm._s(worker.phone || '-'))])],1),_c('v-col',{attrs:{"align":_vm.$vuetify.breakpoint.smAndDown ? 'start' : 'end'}}),_c('v-col',{staticClass:"shrink"},[_c('atoms-button',{staticClass:"primary",on:{"click":function($event){_vm.showDetail = worker}}},[_vm._v("Detail")])],1)],1)],1)}),0):_vm._e()],1),_c('v-fade-transition',[(_vm.tabs === 2)?_c('v-container',{staticClass:"text-start pa-10 my-5 accent"},[_c('atoms-text',{staticClass:"orange--text"},[_vm._v("- Salary : "+_vm._s(_vm.$idCurrency(_vm.payload.salary || 0) || '-'))]),_c('atoms-text',[_vm._v("- Penulis : "+_vm._s((_vm.payload.createdByData && _vm.payload.createdByData.nickname) || '-'))]),_c('atoms-text',[_vm._v("- Disunting Oleh : "+_vm._s((_vm.payload.updatedByData && _vm.payload.updatedByData.nickname) || '-'))])],1):_vm._e()],1)],1)],1)],1):_vm._e()],1),_c('v-slide-x-reverse-transition',[(_vm.showDetail)?_c('organism-workers-edit',{attrs:{"data":_vm.showDetail},on:{"closed":_vm.detailClose}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }