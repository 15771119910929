<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '30%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container v-if="data">
        <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>Edit Lokasi</atoms-title>
              <atoms-text>{{ data.name }}</atoms-text>
            </v-col>
            <v-col cols="10">
              <atoms-text-field
                label="Nama Lokasi"
                v-model="name"
                :rules="[
                  (v) => !!v || 'Harap isi nama lokasi!',
                  (v) => (!!v && duplicate !== v) || '(Nama sudah terdaftar) Gunakan nama lain!',
                ]"
              />
              <atoms-gmap-input />
              <atoms-text-field>
                <v-textarea class="rounded-lg" v-model.trim="note" placeholder="Catatan" flat solo />
              </atoms-text-field>
              <v-checkbox v-model="inactive" class="mb-4" label="Lokasi Tidak Aktif" dense hide-details></v-checkbox>
              <v-checkbox
                v-model="forceGeotagging"
                class="mb-4"
                label="Pekerja Wajib Absensi di Lokasi"
                dense
                hide-details
              ></v-checkbox>
              <v-select
                class="rounded-lg"
                v-model.trim="maxDistance"
                :items="listMaxDistances"
                :menu-props="{ offsetY: true }"
                item-text="label"
                item-value="value"
                hide-details
                dense
                solo
                flat
              />
              <atoms-button :loading="$store.state.loading" type="submit" class="primary" :style="{ width: '100%' }"
                >Perbarui {{ data.name }}</atoms-button
              >
            </v-col>
            <!-- <v-col cols="10">
              <v-data-table
                :headers="[
                  {
                    text: 'Lokasi',
                    sortable: false,
                    value: 'name',
                    width: '40%',
                    class: 'primary--text text-uppercase',
                  },
                  {
                    text: '',
                    sortable: false,
                    value: 'action',
                    class: 'text-uppercase',
                  },
                ]"
                light
                :items="data.locations"
                class="accent rounded-lg"
                hide-default-footer
                hide-default-header
              >
                <template v-slot:[`header`]="{ props }">
                  <thead>
                    <tr class="d-table-row">
                      <th
                        v-for="h in props.headers"
                        :key="h.value"
                        :class="h.class"
                      >
                        <span>{{ h.text }}</span>
                      </th>
                    </tr>
                  </thead></template
                >

                <template v-slot:[`item`]="{ item }">
                  <tr class="d-table-row">
                    <td class="text-left">{{ item.name }}</td>
                    <td
                      v-if="$vuetify.breakpoint.smAndDown"
                      :style="{
                        width: '100%',
                      }"
                    />

                    <td class="text-right">
                      <v-icon
                        small
                        light
                        @click="$router.push(`/lokasi?type=${data.title}`)"
                      >
                        mdi-pencil
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col> -->
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['data', 'area'],
  data() {
    return {
      name: '',
      note: '',
      inactive: false,
      forceGeotagging: false,
      maxDistance: 200,
      listMaxDistances: [],
      duplicate: '',
      valid: true,
    }
  },
  computed: {
    address() {
      return this.$store.getters['gmap/address']
    },
    coordinate() {
      return this.$store.getters['gmap/coordinate']
    },
  },
  async mounted() {
    if (!this.data || !this.area) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal ketika memuat data`,
      })
      this.$emit('closed')
    } else {
      this.name = this.data?.name
      this.$store.commit('gmap/setAddress', this.data?.address)
      this.$store.commit('gmap/setCoordinate', this.data?.coordinate || {})
      this.listMaxDistances = await this.$api.get('Locations/getListMaxDistances').then((res) => res.result)
      this.inactive = this.data?.inactive
      this.forceGeotagging = this.data?.forceGeotagging
      this.maxDistance = this.data?.maxDistance
    }
  },
  methods: {
    async preValidate() {
      // if (!this.coordinate?.latitude || !this.coordinate?.longitude) {
      //   return this.$showDialog({
      //     title: "Gagal",
      //     body: `Harap masukkan alamat dengan benar`,
      //   });
      // }
      this.$store.commit('SET', { loading: true })
      try {
        if (this.name && this.address) {
          await this.$store.dispatch('location/checkLocation', [this.area?._id, this.name]).then(async (e) => {
            if (e && this.data.name !== this.name) {
              this.duplicate = this.name
            } else {
              this.duplicate = ''
            }
          })
          await this.validate()
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()
      if (this.valid) {
        await this.$store
          .dispatch('location/update', {
            _id: this.data?._id,
            name: this.name,
            note: this.note,
            address: this.address,
            coordinate: this.coordinate,
            inactive: this.inactive,
            forceGeotagging: this.forceGeotagging,
            areaId: this.area._id,
          })
          .then((e) => {
            if (e) {
              this.$showDialog({
                title: 'Berhasil',
                body: `Lokasi ${this.data.name} berhasil diperbarui!`,
              })
              this.$refs.form.reset()
              this.$refs.form.resetValidation()
              this.$emit('closed', e)
            }
          })
      } else {
        this.$showDialog({
          title: 'Gagal',
          body: `Periksa kembali kolom pengisian`,
        })
      }
    },
  },
}
</script>
