<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="accent overflow-x-hidden overflow-y-auto rounded-lg text-center pb-8"
    >
      <v-row justify="start" class="pa-4 pb-0">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" class="pa-4 pt-0">
        <v-col cols="10" align="center">
          <atoms-title :h2="true">Percakapan Baru</atoms-title>
        </v-col>
        <v-col cols="10">
          <v-row dense align="end">
            <v-col cols="9">
              <atoms-text-field label="Pilih Kontak">
                <v-autocomplete
                  class="rounded-lg"
                  v-model.trim="contact.term"
                  :items="(contacts && contacts.map((x) => `${x.name} | +${x.number}`)) || []"
                  placeholder="Pilih Kontak"
                  background-color="background"
                  :menu-props="{ offsetY: true }"
                  hide-details=""
                  append-icon="mdi-chevron-down"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="3">
              <atoms-button
                @click="
                  () => {
                    contact.value = contact.term.split('|')[0]
                    contact.number = contact.term.split('|')[1]
                  }
                "
                class="primary"
                :style="{ width: '100%' }"
                >Tambah</atoms-button
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10" class="pb-0">
          <!-- <atoms-text>Atau</atoms-text> -->
          <v-divider></v-divider>
        </v-col>
        <v-col cols="10">
          <v-row dense align="end" class="py-0">
            <v-col cols="9">
              <atoms-text-field
                v-if="!$store.state.worker.data || !worker.enabled"
                label="Cari Pekerja"
                color="background"
                v-model="worker.term"
              />
              <atoms-text-field v-else label="Pilih Kontak Dari Pekerja">
                <v-autocomplete
                  class="rounded-lg"
                  v-model.trim="worker.term"
                  placeholder="Pilih Kontak Dari Pekerja"
                  :items="
                    ($store.state.worker.data &&
                      $store.state.worker.data.map(
                        (x) => `${x.fullName} | ${(x.phone.startsWith('62') && '+') || ''}${x.phone}`,
                      )) ||
                    []
                  "
                  background-color="background"
                  :menu-props="{ offsetY: true }"
                  append-icon="mdi-close"
                  @click:append="
                    () => {
                      worker.enabled = false
                    }
                  "
                  hide-details
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="3" align="end">
              <atoms-button
                @click="
                  () => {
                    if (!$store.state.worker.data || !worker.enabled) {
                      getWorkers()
                      worker.term = ''
                    } else {
                      contact.value = worker.term.split('|')[0]
                      contact.number = worker.term.split('|')[1]
                    }
                  }
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                class="primary"
                >{{ !$store.state.worker.data || !worker.enabled ? 'Cari' : 'Tambah' }}</atoms-button
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10" class="pb-0">
          <!-- <atoms-text>Atau</atoms-text> -->
          <v-divider></v-divider>
        </v-col>
        <v-col cols="10">
          <v-row dense align="end" class="py-0">
            <v-col cols="9">
              <atoms-text-field label="Nomor Baru" color="background" v-model="anon" />
            </v-col>
            <v-col cols="3">
              <atoms-button
                @click="
                  () => {
                    contact.value = 'Nomor Baru'
                    contact.number = anon
                  }
                "
                class="primary"
                :style="{ width: '100%' }"
                >Tambah</atoms-button
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10" v-if="contact.value && contact.number">
          <atoms-text class="my-1 font-weight-bold">Terpilih</atoms-text>
          <div class="pa-2 background rounded-lg text-left">
            <atoms-text><span class="font-weight-bold primary--text">Nama:</span> {{ contact.value }}</atoms-text>
            <atoms-text><span class="font-weight-bold primary--text">Nomor:</span> {{ contact.number }}</atoms-text>
          </div>
        </v-col>
        <v-col cols="10" v-if="contact.value && contact.number">
          <atoms-button @click="createChat" class="primary" style="width: 100%">Mulai Percakapan</atoms-button>
        </v-col>
      </v-row>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  data() {
    return {
      contacts: null,
      anon: '',
      contact: {
        term: '',
        value: '',
        number: '',
      },
      worker: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
    }
  },
  watch: {
    'worker.value'() {
      if (this.worker.value) {
        this.worker.item = this.$store.state.worker?.data?.find((x) => x.fullName === this.worker.value)
        this.worker.enabled = true
      }
    },
  },
  sockets: {
    get_contacts(contacts) {
      if (contacts) {
        this.contacts = contacts
        this.$storage.setItem(this.$store.state.constant.contacts, contacts)
      }
      this.$store.commit('SET', { loading: false })
    },
    create_chat(data) {
      if (data) {
        this.$emit('create', data)
      }
      this.$store.commit('SET', { loading: false })
    },
  },
  mounted() {
    this.$store.commit('SET', { loading: true })
    const hasContacts = this.$storage.getItem(this.$store.state.constant.contacts)
    if (hasContacts) {
      this.contacts = hasContacts
    }
    this.$socket.emit('get_contact', { isMyContact: true })
  },
  methods: {
    async getWorkers() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.worker.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('worker/getAll', {
          query: {
            active: true,
            approved: true,
            blacklist: false,
            $or: [
              {
                idNumber: term,
              },
              {
                phone: term,
              },
              {
                fullName: term,
              },
              {
                email: term,
              },
              {
                'address.city': term,
              },
              {
                'address.province': term,
              },
              {
                'address.kecamatan': term,
              },
            ],
          },
          page: 1,
          limit: 50,
        })
        this.worker.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async createChat() {
      if (this.contact?.number?.startsWith('0')) {
        this.contact.number = `62${this.contact.number.substring(1)}`
      }
      if (this.contact.number) {
        this.$store.commit('SET', { loading: true })
        this.$socket.emit('create_chat', {
          number: this.contact.number
            .toString()
            .trim()
            .replace(/[- )(+]/g, ''),
        })
      }
    },
  },
}
</script>
