<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : width || '50%',
        height: '100%',
      }"
      :class="['overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8', background]"
    >
      <slot />
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: {
    background: {
      type: String,
      default: 'background',
    },
    width: String,
  },
}
</script>
