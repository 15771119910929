import Vue from 'vue'
//import { storage } from "@/firebase.js";
import firebase from 'firebase/app'
import 'firebase/storage'

Vue.use({
  install() {
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_APIKEY,
      authDomain: process.env.VUE_APP_AUTHDOMAIN,
      projectId: process.env.VUE_APP_PROJECTID,
      storageBucket: process.env.VUE_APP_STORAGEBUCKET,
      messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
      appId: process.env.VUE_APP_IDAPP,
      measurementId: process.env.VUE_APP_MEASUREMENTID,
    }
    firebase.initializeApp(firebaseConfig)
    Vue.prototype.$firebase = firebase
    Vue.prototype.$uploadFile = async (blob, urlPath, filename) => {
      const ref = firebase.storage().ref(urlPath).child(filename)
      const snapshot = await ref.put(blob)
      return await snapshot.ref.getDownloadURL()
    }
  },
})

// {
//     apiKey: "AIzaSyDMcQRXF23PAms7A-H7w1lAR4iCV8zgrok",
//     authDomain: "hayo-kerja.firebaseapp.com",
//     projectId: "hayo-kerja",
//     storageBucket: "hayo-kerja.appspot.com",
//     messagingSenderId: "53756477327",
//     appId: "1:53756477327:web:d1220396f3232445a7d5fa",
//     measurementId: "G-RXLMXLKX32"
// }
