import CryptoJS from 'crypto-js'
import SecureStorage from 'secure-web-storage'
import { openDB } from 'idb'

import Vue from 'vue'
const instance = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, process.env.VUE_APP_SECRET)
    return key.toString()
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, process.env.VUE_APP_SECRET)
    data = data.toString()
    return data
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, process.env.VUE_APP_SECRET)
    data = data.toString(CryptoJS.enc.Utf8)
    return data
  },
})

const connecter = process.env.VUE_APP_CONNECTER || 'HAYOGAN'

const dbname = 'hk'
// const dbPromise = openDB('translations', 1, {
//   upgrade(db) {
//     db.createObjectStore(dbname)
//   },
// })

Vue.use({
  install() {
    Vue.prototype.$storage = instance
    Vue.prototype.$hash = (key, secret = connecter) => {
      key = CryptoJS.SHA256(key, secret)
      return key.toString()
    }
    Vue.prototype.$encrypt = (data, secret = connecter) => {
      data = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), secret)
      return data.toString()
    }
    Vue.prototype.$decrypt = (data, secret = connecter) => {
      data = CryptoJS.AES.decrypt(data, secret)
      return data.toString(CryptoJS.enc.Utf8)
    }
    Vue.prototype.$get_db = async (key) => {
      return (await dbPromise).get(dbname, key)
    }
    Vue.prototype.$get_all_db = async () => {
      return (await dbPromise).getAll(dbname)
    }
    Vue.prototype.$keys_db = async () => {
      return (await dbPromise).getAllKeys(dbname)
    }
    Vue.prototype.$set_db = async (key, val) => {
      return (await dbPromise).put(dbname, val, key)
    }
  },
})
