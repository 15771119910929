var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '30%',
      height: '100%',
    })},[_c('v-row',{staticClass:"px-8 py-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('closed')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),(_vm.data)?_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.preValidate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-title',[_vm._v("Edit Lokasi")]),_c('atoms-text',[_vm._v(_vm._s(_vm.data.name))])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-text-field',{attrs:{"label":"Nama Lokasi","rules":[
                (v) => !!v || 'Harap isi nama lokasi!',
                (v) => (!!v && _vm.duplicate !== v) || '(Nama sudah terdaftar) Gunakan nama lain!',
              ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('atoms-gmap-input'),_c('atoms-text-field',[_c('v-textarea',{staticClass:"rounded-lg",attrs:{"placeholder":"Catatan","flat":"","solo":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"note"}})],1),_c('v-checkbox',{staticClass:"mb-4",attrs:{"label":"Lokasi Tidak Aktif","dense":"","hide-details":""},model:{value:(_vm.inactive),callback:function ($$v) {_vm.inactive=$$v},expression:"inactive"}}),_c('v-checkbox',{staticClass:"mb-4",attrs:{"label":"Pekerja Wajib Absensi di Lokasi","dense":"","hide-details":""},model:{value:(_vm.forceGeotagging),callback:function ($$v) {_vm.forceGeotagging=$$v},expression:"forceGeotagging"}}),_c('v-select',{staticClass:"rounded-lg",attrs:{"items":_vm.listMaxDistances,"menu-props":{ offsetY: true },"item-text":"label","item-value":"value","hide-details":"","dense":"","solo":"","flat":""},model:{value:(_vm.maxDistance),callback:function ($$v) {_vm.maxDistance=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"maxDistance"}}),_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v("Perbarui "+_vm._s(_vm.data.name))])],1)],1)],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }