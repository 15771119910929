const collection = 'SalaryWeeks'
const initial = () => ({
  data: null,
  detailSalaryWeek: null,
})

export default {
  namespaced: true,
  state: initial,
  actions: {
    async createSalaryWeek({ commit, state }, payload) {
      return await this._vm.$api.post(collection + '/create', payload)
    },

    async getSalaryWeeks({ commit, state }, payload) {
      const response = await this._vm.$api.get(collection + '/get', {
        params: {
          jsonQuery: JSON.stringify({
            pipeline: [
              {
                $lookup: {
                  from: 'Clients',
                  localField: 'clientId',
                  foreignField: '_id',
                  as: 'clientData',
                },
              },
              {
                $project: {
                  _id: 1,
                  title: 1,
                  clientId: 1,
                  clientName: { $arrayElemAt: ['$clientData.name', 0] },
                  startDate: 1,
                  endDate: 1,
                  label_remark_1: 1,
                  remark_1: 1,
                  isCompleted: 1,
                  attendances: 1,
                  workers: 1,
                  shifts: 1,
                  _createdDate: 1,
                },
              },
              {
                $sort: {
                  _createdDate: -1,
                },
              },
            ],
          }),
        },
      })
      commit('SET_DATA_SALARY_WEEK', response?.result)
    },

    // async getSalaryWeek({ commit, state }, payload) {
    //   // const response = await this._vm.$api.get(collection + `/getOne/${payload._id}/${payload.labelRemark1}`)
    //   return await this._vm.$api.get(collection + `/getOne/${payload._id}`)
    // },

    async getOneSalaryWeek({ commit, state }, payload) {
      return await this._vm.$api.get(collection + `/getOne/${payload._id}`)
    },

    async refreshSalaryWeek({ commit, state }, payload) {
      return await this._vm.$api.get(collection + `/refresh/${payload._id}`)
    },

    async updateSalaryWeek({ commit, state }, payload) {
      return await this._vm.$api.patch(collection + `/update/${payload._id}`, payload)
    },

    async deleteSalaryWeek({ commit, state }, payload) {
      return await this._vm.$api.delete(collection + `/remove/${payload._id}`)
    },

    async getSumSalaryWeek({ commit, state }, payload) {
      return await this._vm.$api.get(collection + `/getSum/${payload._id}`)
    },
  },

  mutations: {
    SET_DATA_SALARY_WEEK(state, payload) {
      state.data = payload
    },
  },

  getters: {
    getDataSalaryWeek(state) {
      return state.data
    },
  },
}
