var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{class:['overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8', _vm.background],style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : _vm.width || '50%',
      height: '100%',
    })},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }