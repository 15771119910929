<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '30%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="$emit('closed')"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>Tambah Lokasi</atoms-title>
            </v-col>
            <v-col cols="10">
              <atoms-text-field
                label="Nama Lokasi"
                v-model="name"
                :rules="[
                  (v) => !!v || 'Harap isi nama lokasi!',
                  (v) => (!!v && duplicate !== v) || '(Nama sudah terdaftar) Gunakan nama lain!',
                ]"
              />
              <atoms-gmap-input :address="address" :coordinate="coordinate" />
              <atoms-text-field>
                <v-textarea class="rounded-lg" v-model.trim="note" placeholder="Catatan" flat solo />
              </atoms-text-field>
              <atoms-button :loading="$store.state.loading" type="submit" class="primary" :style="{ width: '100%' }"
                >Tambah Lokasi</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['area'],
  data() {
    return {
      name: '',
      note: '',
      duplicate: '',
      valid: true,
    }
  },
  computed: {
    address() {
      return this.$store.getters['gmap/address']
    },
    coordinate() {
      return this.$store.getters['gmap/coordinate']
    },
  },
  mounted() {
    if (!this.area) {
      this.$showDialog({
        title: 'Kesalahan',
        body: `Gagal ketika memuat data`,
      })
      this.$emit('closed')
    }
    this.$store.commit('gmap/setAddress', '')
    this.$store.commit('gmap/setCoordinate', {})
  },
  methods: {
    async preValidate() {
      if (!this.coordinate?.latitude || !this.coordinate?.longitude) {
        return this.$showDialog({
          title: 'Gagal',
          body: `Harap masukkan alamat dengan benar`,
        })
      }
      this.$store.commit('SET', { loading: true })
      try {
        if (this.name && this.address) {
          await this.$store.dispatch('location/checkLocation', [this.area?._id, this.name]).then(async (e) => {
            if (e) {
              this.duplicate = this.name
            } else {
              this.duplicate = ''
            }
          })
          await this.validate()
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()
      if (this.valid) {
        const loc = {
          _id: this.$uuid.v4(),
          name: this.name,
          note: this.note,
          address: this.address,
          coordinate: this.coordinate,
          areaId: this.area._id,
        }
        await this.$store.dispatch('location/create', loc).then((e) => {
          if (e) {
            this.$showDialog({
              title: 'Berhasil',
              body: `Lokasi ${this.name} berhasil ditambahkan!`,
            })
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
            this.$emit('closed', {
              ...this.area,
              locations: [...this.area?.locations, loc],
            })
          }
        })
      } else {
        this.$showDialog({
          title: 'Gagal',
          body: `Periksa kembali kolom pengisian`,
        })
      }
    },
  },
}
</script>
