var render = function render(){var _vm=this,_c=_vm._self._c;return _c('molecules-floating',[_c('div',{staticClass:"accent overflow-x-hidden overflow-y-auto rounded-lg text-center pb-8",style:({
      position: 'fixed',
      top: 0,
      right: 0,
      width: _vm.$vuetify.breakpoint.smAndDown ? '90%' : '50%',
      height: '100%',
    })},[_c('v-row',{staticClass:"pa-4",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('closed')}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1)],1),_c('v-row',{staticClass:"pa-4 pt-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10","align":"center"}},[_c('atoms-title',{attrs:{"h2":true}},[_vm._v("Broadcast List Baru")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{attrs:{"dense":"","align":"end"}},[_c('v-col',{attrs:{"cols":"9"}},[_c('atoms-text-field',{attrs:{"label":"Pilih Kontak"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"items":(_vm.contacts && _vm.contacts.map((x) => `${x.name} | +${x.number}`)) || [],"placeholder":"Pilih Kontak","background-color":"background","menu-props":{ offsetY: true },"hide-details":"","append-icon":"mdi-chevron-down","dense":"","solo":"","flat":""},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contact"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),on:{"click":() => {
                  _vm.pushItem(_vm.contact.split('|')[0], _vm.contact.split('|')[1])
                }}},[_vm._v("Tambah")])],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{staticClass:"py-0",attrs:{"dense":"","align":"end"}},[_c('v-col',{attrs:{"cols":"9"}},[(!_vm.$store.state.worker.data || !_vm.worker.enabled)?_c('atoms-text-field',{attrs:{"label":"Cari Pekerja","color":"background"},model:{value:(_vm.worker.term),callback:function ($$v) {_vm.$set(_vm.worker, "term", $$v)},expression:"worker.term"}}):_c('atoms-text-field',{attrs:{"label":"Pilih Kontak Dari Pekerja"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"placeholder":"Pilih Kontak Dari Pekerja","items":(_vm.$store.state.worker.data &&
                    _vm.$store.state.worker.data.map(
                      (x) => `${x.fullName} | ${(x.phone.startsWith('62') && '+') || ''}${x.phone}`,
                    )) ||
                  [],"background-color":"background","menu-props":{ offsetY: true },"append-icon":"mdi-close","hide-details":"","dense":"","solo":"","flat":""},on:{"click:append":() => {
                    _vm.worker.enabled = false
                  }},model:{value:(_vm.worker.term),callback:function ($$v) {_vm.$set(_vm.worker, "term", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"worker.term"}})],1)],1),_c('v-col',{attrs:{"cols":"3","align":"end"}},[_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),attrs:{"loading":_vm.$store.state.loading},on:{"click":() => {
                  if (!_vm.$store.state.worker.data || !_vm.worker.enabled) {
                    _vm.getWorkers()
                    _vm.worker.term = ''
                  } else {
                    _vm.pushItem(_vm.worker.term.split('|')[0], _vm.worker.term.split('|')[1])
                  }
                }}},[_vm._v(_vm._s(!_vm.$store.state.worker.data || !_vm.worker.enabled ? 'Cari' : 'Tambah'))])],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{staticClass:"py-0",attrs:{"dense":"","align":"end"}},[_c('v-col',{attrs:{"cols":"9"}},[_c('atoms-text-field',{attrs:{"label":"Nomor Baru","color":"background"},model:{value:(_vm.anon),callback:function ($$v) {_vm.anon=$$v},expression:"anon"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('atoms-button',{staticClass:"primary",style:({ width: '100%' }),on:{"click":() => {
                  _vm.pushItem(`Nomor Baru ${_vm.items.length + 1}`, _vm.anon)
                }}},[_vm._v("Tambah")])],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v("List Nomor "),_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"right":""},on:{"click":() => {
                _vm.$showDialog({
                  title: 'Informasi',
                  body: 'Untuk menghapus nomor dari list, cukup diklik saja nomornya pada list.',
                })
              }}},[_vm._v("mdi-information")])],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-list',{staticClass:"overflow-y-auto background rounded-lg",staticStyle:{"height":"150px"},attrs:{"nav":"","two-line":"","color":"rgba(0,0,0,0)"}},[_c('v-list-item-group',{attrs:{"active-class":"primary white--text"}},[_vm._l(((_vm.items && _vm.items.length > 0 && _vm.items) || []),function(x,i){return [_c('v-list-item',{key:i,staticClass:"rounded-lg",attrs:{"dense":""},on:{"click":() => {
                    _vm.items = _vm.items.filter((it) => it.number != x.number)
                  }}},[_c('v-list-item-content',{staticClass:"text-left"},[_c('v-list-item-title',[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(x.value)+" ")])],1),_c('v-list-item-subtitle',[_c('atoms-text',[_vm._v(_vm._s(x.number))])],1)],1)],1),(i < i - 1)?_c('v-divider',{key:i}):_vm._e()]})],2)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('atoms-button',{staticClass:"primary",staticStyle:{"width":"100%"},on:{"click":_vm.createChat}},[_vm._v("Mulai Percakapan")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }