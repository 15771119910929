<template>
  <v-overlay
    :value="show || false"
    fixed
    :dark="$store.state.dark"
    opacity="0.7"
    class="d-flex align-center justify-center text-center"
    height="100%"
    style="z-index: 2001"
  >
    <v-card rounded="lg" class="mx-auto pa-4" :min-width="width || 300" :max-width="width || 344" elevation="0">
      <v-card-title>
        <atoms-title :h3="true" class="font-weight-bold">{{ title || 'Title' }}</atoms-title>
      </v-card-title>
      <v-card-text v-if="caption" class="text-left">
        <atoms-text>{{ caption }}</atoms-text>
      </v-card-text>
      <v-card-text v-if="!strict" :class="['text-left px-2 pt-0', override && override]">
        <slot />
      </v-card-text>
      <v-card-actions
        v-if="items"
        :class="[
          'd-flex px-2',
          strict
            ? 'flex-column py-4'
            : !items.find((e) => e.disable) && items.length > 1
            ? 'justify-space-between'
            : 'justify-end',
        ]"
      >
        <template v-for="({ title, override, exec, full, disable = false }, i) in items">
          <!-- <v-spacer v-if="!full && items.length < 2" :key="title" /> -->
          <atoms-button
            v-if="!disable"
            :key="i"
            @click="exec"
            :class="[override ? override : 'primary--text', 'rounded-lg ', strict && 'my-2 mx-0']"
            :style="{
              width: full ? '100%' : strict ? '90%' : 99 / items.length + '%',
            }"
            >{{ title }}</atoms-button
          >
        </template>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
export default {
  props: ['title', 'items', 'show', 'width', 'strict', 'persistent', 'override', 'caption'],
}
</script>
