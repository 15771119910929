/* eslint-disable */
const collection = 'ClientMembers'
const defaultPipeline = [
  {
    $lookup: {
      from: 'Users',
      localField: 'userId',
      foreignField: '_id',
      as: 'user',
    },
  },
  {
    $unwind: {
      path: '$user',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Clients',
      localField: 'clientId',
      foreignField: '_id',
      as: 'client',
    },
  },
  {
    $unwind: {
      path: '$client',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Areas',
      localField: 'areaIds',
      foreignField: '_id',
      as: 'areas',
    },
  },
  {
    $lookup: {
      from: 'Locations',
      localField: 'locationIds',
      foreignField: '_id',
      as: 'locations',
    },
  },
  {
    $project: {
      'user.password': 0,
    },
  },
]
const initial = () => ({
  data: null,
  page: 1,
  total: 0,
  length: 0,
  defaultPipeline,
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async getSummary({ commit, rootState }, page) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              clientId: rootState?.client?._id,
            }),
          },
        })
        .then(async ({ result, pagesLength, ...rest }) => {
          commit('SET', {
            total: result.length || 0,
          })
        })
        .catch(() => Promise.reject(`Akun anda tidak terkait dengan klien`))
    },

    async getAll({ commit, state }, payload) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [...defaultPipeline],
              ...payload?.custom,
            }),
            limit: payload?.limit || state.limit,
            page: payload?.page || state.page,
          },
        })
        .then(async ({ result, ...rest }) => {
          commit('SET', {
            data: result,
            ...rest,
          })
        })
        .catch((err) => Promise.reject(err))
    },
    async get({ commit, rootState }, { userId, clientId, returnOnly = false, ...payload }) {
      return await this._vm.$api
        .get(collection + '/get', {
          params: {
            jsonQuery: JSON.stringify({ userId, clientId, ...payload }),
          },
        })
        .then(({ result }) => {
          if ((!returnOnly && !result) || (result && result.length < 1)) {
            commit('RESET')
            return Promise.reject('User tidak ditemukan!')
          }
          return returnOnly ? result[0] : commit('SET', result[0])
        })
        .catch(() => Promise.reject(`Akun anda tidak terkait dengan klien`))
    },
    async register({ rootState }, payload) {
      return await this._vm.$api.post(collection + '/registerMember', payload).catch((error) => Promise.reject(error))
    },
    async remove({ state }, memberId) {
      return await this._vm.$api
        .delete(collection + '/remove', {
          params: {
            _id: memberId,
          },
        })
        .catch((error) => Promise.reject(error || 'Ada kesalahan'))
    },
    async updateRoles({ state }, payload) {
      return await this._vm.$api.patch(collection + '/updateRoles', payload).catch((error) => Promise.reject(error))
    },
    async update({ state }, payload) {
      return await this._vm.$api.patch(collection + '/update', payload).catch((error) => Promise.reject(error))
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    SET_MEMBERS(state, payload) {
      state.members = payload
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
