import router from '@/router/index'
/* eslint-disable */
const collection = 'Workers'
const initial = () => ({
  data: null,
  total: 0,
  currentDay: 0,
  within7Days: 0,
})
const defaultPipeline = [
  {
    $match: {
      captain: true,
    },
  },
]
export default {
  namespaced: true,
  state: initial,
  actions: {
    async summary({ commit }, query) {
      // get total
      const countPipeline = [
        ...defaultPipeline,
        {
          $group: {
            _id: {
              $dateToString: {
                format: '%Y-%m-%d',
                date: '$_createdDate',
              },
            },
            total: { $sum: 1 },
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]
      await this._vm.$api
        .get(collection + '/getMongo', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [
                ...defaultPipeline,
                {
                  $facet: {
                    data: [],
                    info: [
                      {
                        $count: 'count',
                      },
                    ],
                  },
                },
                {
                  $project: {
                    count: '$info.count',
                  },
                },
                {
                  $unwind: {
                    path: '$count',
                    preserveNullAndEmptyArrays: true,
                  },
                },
              ],
            }),
          },
        })
        .then(({ result, ...rest }) =>
          commit('SET', {
            total: result?.[0]?.count || 0,
          }),
        )

      Promise.all([
        this._vm.$api
          .get(collection + '/getMongo', {
            params: {
              jsonQuery: JSON.stringify({
                ...query,
                pipeline: [
                  ...countPipeline,
                  {
                    $match: {
                      _id: this._vm.$moment().format('YYYY-MM-DD'),
                    },
                  },
                ],
              }),
            },
          })
          .then(({ result }) => result),
        this._vm.$api
          .get(collection + '/getMongo', {
            params: {
              jsonQuery: JSON.stringify({
                ...query,
                pipeline: [
                  ...countPipeline,
                  {
                    $match: {
                      _id: {
                        $lte: this._vm.$moment().format('YYYY-MM-DD'),
                        $gte: this._vm.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
                      },
                    },
                  },
                ],
              }),
            },
          })
          .then(({ result }) => result),
      ]).then(([day, days]) => {
        commit('SET', {
          currentDay: day?.[0],
          within7Days: days,
        })
      })
    },
    async get({ state }, query) {
      return await this._vm.$api
        .get(collection + '/getUsers', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [
                ...defaultPipeline,
                {
                  $sort: {
                    _updatedDate: -1,
                  },
                },
              ],
            }),
            limit: 1,
          },
        })
        ?.result?.[0].catch((error) => Promise.reject(error))
    },
    async getAll({ commit, state }, { query, limit, page, custom }) {
      return await this._vm.$api
        .get(collection + '/getMongo', {
          params: {
            jsonQuery: JSON.stringify({
              ...query,
              pipeline: [
                ...defaultPipeline,
                {
                  $lookup: {
                    from: 'WorkerDetails',
                    localField: '_id',
                    foreignField: 'workerId',
                    as: 'detail',
                  },
                },
                {
                  $unwind: {
                    path: '$detail',
                    preserveNullAndEmptyArrays: true,
                  },
                },
              ],
              ...custom,
            }),
            page: page || 1,
            limit: limit || state.limit,
          },
        })
        .then(async ({ result, ...rest }) => {
          commit('SET', {
            data: result,
            ...rest,
          })
          return result
        })
        .catch(async (err) => Promise.reject(err))
    },
    async update({ commit }, payload) {
      return await this._vm.$api
        .put(collection + '/setWorkerData', {
          sendMongo: {
            ...(payload || {}),
          },
        })
        .then(({ success, result }) => {
          return success
        })
        .catch(async (err) => Promise.reject(err))
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
