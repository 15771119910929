<template>
  <v-menu bottom left offset-y origin="top right" transition="scale-transition">
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="notifButton rounded-pill pa-2" text fab v-bind="attrs" v-on="on" @click="readNotif">
        <div class="">
          <v-badge v-if="unReadNotifications.length > 0" color="red" bordered overlap>
            <template v-slot:badge>
              <span>{{ unReadNotifications.length }}</span>
            </template>
            <v-icon :color="$store.state.dark ? `white` : '#757575'">mdi-bell</v-icon>
          </v-badge>
          <v-icon :color="$store.state.dark ? `white` : '#757575'" v-else>mdi-bell</v-icon>
        </div>
      </v-btn>
    </template>

    <v-list style="max-width: 640px; min-width: 150px; max-height: 500px; padding-top: 25px; overflow: scroll" nav>
      <v-list-item two-line v-for="(notif, index) in notifications" :key="index">
        <div class="pa-1" style="word-wrap: break-word">
          <h1 class="text-h6">{{ notif.title }}</h1>
          <p class="text-caption">{{ notif.message }}</p>
          <v-divider v-if="index < notifications.length - 1"></v-divider>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DefaultNotifications',

  data: () => ({
    notifications: [],
  }),

  async mounted() {
    this.getNotifs()
  },

  computed: {
    unReadNotifications() {
      return this?.notifications?.filter((notif) => notif?.isRead !== true) || []
    },
  },

  methods: {
    async getNotifs() {
      const notifs = await this.$api
        .get('Notifications/get', {
          params: {
            limit: 50,
            jsonQuery: JSON.stringify({
              userId: this.$store?.state?.user?._id,
              pipeline: [
                {
                  $sort: {
                    _createdDate: -1,
                  },
                },
              ],
            }),
          },
        })
        .then((data) => data?.result || [])
      // notif structure
      // {
      //   userId,
      //   title,
      //   message,
      //   isRead,
      //   code,
      // }
      this.notifications = notifs
      // listen socket
      this.$socket.on('getNotification', (...args) => {
        const notif = args?.[0] || {}
        this.notifications.unshift(notif)
        if (this.notifications?.length > 7) {
          this.notifications.pop()
        }
      })
    },
    async readNotif() {
      const userId = this.$store?.state?.user?._id
      if (this.unReadNotifications.length > 0) {
        await this.$api.patch('Notifications/readNotifs', {
          userId: userId,
        })
        this.notifications = this.notifications.map((notif) => ({
          ...notif,
          isRead: true,
        }))
      }
    },
  },
}
</script>

<style scoped>
.notifButton:hover {
  transition: 0.4s;
  filter: brightness(90%);
}
</style>
