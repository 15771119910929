<template>
  <v-app class="white">
    <slot />
  </v-app>
</template>
<script>
export default {
  metaInfo: {
    title: 'Masuk Ke Aplikasi',
    titleTemplate: `%s |  Autentikasi`,
    htmlAttrs: { lang: 'en' },
    meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
  },
}
</script>
