const defaultPipeline = [
  {
    $lookup: {
      from: 'Clients',
      localField: 'clientId',
      foreignField: '_id',
      as: 'client',
    },
  },
  {
    $unwind: {
      path: '$client',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Workers',
      localField: 'ktpPhl',
      foreignField: '_id',
      as: 'worker',
    },
  },

  {
    $unwind: {
      path: '$worker',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'QuotationItems',
      localField: 'shiftId',
      foreignField: '_id',
      as: 'shift',
    },
  },
  {
    $unwind: {
      path: '$shift',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $unwind: {
      path: '$detail',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $lookup: {
      from: 'Areas',
      localField: 'areaId',
      foreignField: '_id',
      as: 'area',
    },
  },
  {
    $unwind: {
      path: '$area',
      preserveNullAndEmptyArrays: true,
    },
  },
]
const collection = 'Absensi'
const initial = () => ({
  data: null,
  total: 0, // uniq
  length: 0, // total absensi
  approved: 0,
  complete: 0,
  page: 1,
  from: new Date(),
  till: new Date(),
})
export default {
  namespaced: true,
  state: initial,
  actions: {
    async getSummary({ commit, state }, payload = {}) {
      this._vm.$api
        .get(collection + '/getMongo', {
          params: {
            jsonQuery: JSON.stringify({
              pipeline: [
                {
                  $group: {
                    _id: null,
                    total: { $addToSet: '$ktpPhl' },
                    from: { $first: '$_createdDate' },
                    till: { $last: '$_updatedDate' },
                  },
                },
              ],
            }),
          },
        })
        .then(({ result }) => {
          commit('SET', {
            total: result?.length > 0 ? result[0]?.total?.length : 0,
            from: result[0]?.from || new Date(),
            till: result[0]?.till || new Date(),
          })
        })
        .catch((err) => Promise.reject(err))
      // count length
      // await this._vm.$api
      //   .get(collection + '/getMongo', {
      //     params: {
      //       jsonQuery: JSON.stringify({
      //         ...payload?.query,
      //       }),
      //     },
      //   })
      //   .then(async ({ result, count }) => {
      //     console.log({ result, count })
      //     commit('SET', {
      //       approved: result.filter((x) => x.supervisorApproval)?.length || 0,
      //       complete: result.filter((x) => x.absensiCompleted)?.length || 0,
      //       length: count || 0,
      //     })
      //   })
      return state
    },
    async getAll({ state, commit }, payload = {}) {
      // console.log(payload)
      return await this._vm.$api
        .get(collection + '/getMongo', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload?.query,
              pipeline: [{ $sort: { _createdDate: -1 } }, ...defaultPipeline],
              ...payload?.custom,
            }),
            page: payload?.page || state?.page || 1,
            limit: payload?.limit || state?.limit || 10,
          },
        })
        .then(async ({ result, ...rest }) => {
          if (payload?.day && payload?.day !== this._vm.$days[0]) {
            result = result.filter((x) => {
              return this._vm.$moment(x._createdDate).format('dddd').toLowerCase() === payload?.day.toLowerCase()
            })
          }
          const data = {
            data: result,
            approved: result.filter((x) => x.supervisorApproval)?.length || 0,
            complete: result.filter((x) => x.absensiCompleted)?.length || 0,
            length: rest?.count || 0,
            ...rest,
          }
          if (!payload?.returnOnly) {
            commit('SET', {
              ...data,
            })
          }
          return data
        })
        .catch((err) => Promise.reject(err))
    },
    async getUniq({ rootState }, payload = {}) {
      const term = { $regex: payload?.term || '', $options: 'i' }
      const { result, pagesLength } = await this._vm.$api
        .get('Absensi/getMongo', {
          params: {
            jsonQuery: JSON.stringify({
              ...payload.query,
              pipeline: [
                {
                  $group: {
                    _id: null,
                    uniq: { $addToSet: '$ktpPhl' },
                  },
                },
                {
                  $lookup: {
                    from: 'Workers',
                    let: { pid: '$uniq' },
                    pipeline: [{ $match: { $expr: { $in: ['$_id', '$$pid'] } } }],
                    as: 'workers',
                  },
                },
                {
                  $unwind: {
                    path: '$workers',
                    preserveNullAndEmptyArrays: true,
                  },
                },
                ...(payload?.term
                  ? [
                      {
                        $match: payload?.term?.id
                          ? {
                              'workers._id': payload?.term?.id,
                            }
                          : {
                              $or: [
                                {
                                  'workers.fullName': term,
                                },
                                {
                                  'workers.address.city': term,
                                },
                                {
                                  'workers.address.province': term,
                                },
                                {
                                  'workers.salary': term,
                                },
                              ],
                            },
                      },
                    ]
                  : []),
                {
                  $replaceRoot: {
                    newRoot: '$workers',
                  },
                },
                {
                  $lookup: {
                    from: 'WorkerDetails',
                    localField: 'detailId',
                    foreignField: '_id',
                    as: 'detail',
                  },
                },
                {
                  $unwind: {
                    path: '$detail',
                    preserveNullAndEmptyArrays: true,
                  },
                },
                {
                  $lookup: {
                    from: 'Absensi',
                    localField: '_id',
                    foreignField: 'ktpPhl',
                    as: 'absensi',
                  },
                },
                {
                  $replaceRoot: {
                    newRoot: {
                      $mergeObjects: [
                        '$$ROOT',
                        {
                          absensi: {
                            $size: '$absensi',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            }),
          },
        })
        .catch(async (err) => {
          return Promise.reject(err)
        })
      return {
        result:
          payload?.page || payload?.limit
            ? result.slice(((payload?.page || 1) - 1) * payload?.limit, (payload?.page || 1) * payload?.limit)
            : result,
        count: result.length,
        pagesLength,
        payload,
      }
    },
    async update({ commit }, payload) {
      return await this._vm.$api.patch(collection + '/update', payload)
    },
    async setStatus({ commit }, payload) {
      return await this._vm.$api.put(collection + '/setStatus', payload)
    },
    async approveBulk({ commit }, payload) {
      return await this._vm.$api.put(collection + '/setBulkStatus', payload)
    },
  },
  mutations: {
    SET(state, payload) {
      state = Object.assign(state, {
        ...state,
        ...payload,
      })
    },
    SETBYFIELD(state, payload) {
      state[payload[0]] = payload[1]
    },
    RESET(state) {
      Object.assign(state, initial())
    },
  },
}
