import Vue from 'vue'

const requireComponent = require.context('@/components', true, /\.vue$/)
//['./Atoms/Button.vue', './Atoms/Text.vue', './Atoms/Title.vue', './Molecules/BetaDialog.vue']
for (const file of requireComponent.keys()) {
  const componentConfig = requireComponent(file)
  Vue.component(file.replace(/(.vue|\/|\.)/g, ''), componentConfig.default || componentConfig)
}

const requireLayout = require.context('@/layouts', true, /\.vue$/)
for (const file of requireLayout.keys()) {
  const layoutConfig = requireLayout(file)
  Vue.component(layoutConfig.default.name || file.replace(/(.vue|\/|\.)/g, ''), layoutConfig.default || layoutConfig)
}
