<template>
  <molecules-wrapper>
    <v-navigation-drawer app v-model="drawer" height="100%" v-if="$vuetify.breakpoint.mdAndDown" class="rounded-r-lg">
      <template v-slot:prepend>
        <atoms-image width="50%" class="mx-auto mt-4" :src="require('@/assets/hayokerja.png')" />
      </template>
      <v-list nav dense color="primary--text" class="rounded-r-lg">
        <v-list-item v-for="({ path, icon, title }, i) in items || []" :key="i" :to="path">
          <v-list-item-icon>
            <v-icon>mdi-{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            ><atoms-text :auto="true">{{ $toCapitalize(title) }}</atoms-text></v-list-item-title
          >
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list nav dense :class="['rounded-lg']">
          <v-menu
            close-on-click
            v-model="menu"
            offset-y
            rounded="lg"
            :style="{
              'box-shadow': 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
            }"
            min-width="350"
            max-width="350"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :style="{
                  cursor: 'pointer',
                }"
              >
                <v-list-item-avatar
                  color="accent"
                  class="rounded-lg"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-img v-if="$store.state.user.picture" :src="$store.state.user.picture || '/lazy.webp'" />
                  <v-icon v-else>mdi-domain</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="d-flex justify-space-between">
                  <div>
                    <atoms-text class="font-weight-bold">{{
                      `${$store.state.user.nickname || 'Pengguna'}`.length >= 14
                        ? `${$store.state.user.nickname || 'Pengguna'}`.substring(0, 14) + '...'
                        : `${$store.state.user.nickname || 'Pengguna'}`
                    }}</atoms-text>
                    <atoms-text class="primary--text">{{ $store.state.user.email }}</atoms-text>
                  </div>
                  <v-icon right>mdi-unfold-more-horizontal</v-icon>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list nav class="pa-4 rounded-lg">
              <!-- not child -->
              <v-list-item exact-active-class="primary--text" class="rounded-lg pr-4">
                <v-list-item-avatar
                  color="accent"
                  size="50"
                  class="rounded-lg"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-img
                    v-if="$store.state.user.picture"
                    :src="$store.state.user.picture || '/lazy-img-webp'"
                    aspect-ratio="1"
                  />
                  <v-icon v-else> mdi-account </v-icon>
                </v-list-item-avatar>
                <v-list-item-title
                  ><atoms-text class="font-weight-bold">{{ $store.state.user.nickname || 'Pengguna' }}</atoms-text>
                  <atoms-text>{{ $store.state.user.email }}</atoms-text></v-list-item-title
                >
              </v-list-item>
              <v-divider class="my-1" />
              <v-hover>
                <v-list-item
                  @click="showReset = true"
                  slot-scope="{ hover: hoverList }"
                  :class="['mt-2 primary--text rounded-lg', hoverList && 'primary lighten-5']"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title><v-icon left color="primary">mdi-lock</v-icon>Ganti Password</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-hover>

              <v-list-item
                active-class="transparent"
                class="white--text mt-2 red lighten-5 red--text rounded-lg"
                :style="{
                  cursor: 'pointer',
                }"
                @click="logout"
              >
                <v-list-item-content>
                  <v-list-item-title><v-icon left color="red">mdi-logout</v-icon>Keluar</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- 
          <v-list-item
            link
            class="red lighten-5 red--text rounded-lg"
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon dark color="red">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              ><atoms-text :auto="true" class="red--text">{{
                $toCapitalize("Keluar")
              }}</atoms-text></v-list-item-title
            >
          </v-list-item> -->
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-row align="center">
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col cols="12" md="6" order="2" order-md="1" class="d-flex grow align-center flex-column flex-md-row">
            <atoms-title class="mb-1 mr-2">
              {{ $toCapitalize($route.meta.parent || $route.meta.title || '') }}
            </atoms-title>
            <v-icon v-if="$route.meta.desc && !$vuetify.breakpoint.smAndDown">mdi-chevron-right</v-icon>
            <atoms-text v-if="$route.meta.desc" class="ml-2">
              {{ $toCapitalize($route.meta.desc) }}
            </atoms-text>
          </v-col>
          <v-col cols="12" lg="auto" order="1" order-lg="2" class="d-flex align-center justify-space-between">
            <v-row justify="end" align="center">
              <v-col v-if="$vuetify.breakpoint.mdAndDown" class="order-first">
                <v-btn @click="drawer = !drawer" depressed icon>
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </v-col>
              <v-col class="shrink d-flex align-center items-center">
                <atoms-notifications></atoms-notifications>

                <!-- <v-tabs
                  v-model="tabTheme"
                  background-color="background"
                  color="primary"
                  height="40"
                  hide-slider
                  :center-active="$vuetify.breakpoint.smAndDown ? true : false"
                  class="rounded-lg"
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                >
                  <v-tab
                    v-for="({ title, icon }, i) in [
                      {
                        icon: 'white-balance-sunny',
                        title: 'light',
                      },
                      {
                        icon: 'weather-night',
                        title: 'dark',
                      },
                    ]"
                    :key="i"
                    @click="setTheme(title)"
                    class="text-capitalize ma-1"
                    active-class="primary lighten-5 primary--text rounded-lg"
                  >
                    <atoms-text :auto="true" class="font-weight-medium"
                      ><v-icon>mdi-{{ icon }}</v-icon> {{ title }}</atoms-text
                    >
                  </v-tab>
                </v-tabs> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="getChildRoutes && getChildRoutes">
        <v-tabs
          background-color="accent"
          color="primary"
          height="40"
          hide-slider
          :show-arrows="$vuetify.breakpoint.smAndDown ? true : false"
          :center-active="$vuetify.breakpoint.smAndDown ? true : false"
          class="rounded-lg"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        >
          <v-tab
            v-for="({ path, meta }, i) in getChildRoutes"
            :key="i"
            :to="path"
            class="text-capitalize ma-1"
            active-class="primary lighten-5 primary--text rounded-lg"
          >
            <atoms-text class="font-weight-medium">{{ meta.title }}</atoms-text>
          </v-tab>
        </v-tabs></v-col
      >
    </v-row>
    <v-slide-x-reverse-transition>
      <organism-users-reset v-if="showReset" @closed="showReset = false" />
    </v-slide-x-reverse-transition>
  </molecules-wrapper>
</template>
<script>
import { routes } from '../../router/index'
export default {
  data() {
    return {
      items: [],
      drawer: null,
      menu: false,
      tabTheme: null,
      anotherMenu: false,
      showReset: false,
    }
  },
  computed: {
    getChildRoutes() {
      const filteredRoute = this.$route.fullPath.split('?')?.[0]
      let route = this.$router.options.routes.find((x) => {
        return x.children ? x.children.find((y) => y?.path === filteredRoute) : x.path && x.path === filteredRoute
      })
      let filteredChildren = route?.children?.filter((item) => {
        return item?.meta?.type?.some(
          (role) => this.$store.state.user?.roles?.includes(role) || role === this.$roles.All,
        )
      })
      // route.children = filteredChildren
      return filteredChildren?.filter((it) => !it?.meta?.hidden)
    },
  },
  mounted() {
    this.tabTheme = this.$store.state.dark ? 1 : 0 || 0
    this.checker()
  },
  methods: {
    setTheme(title) {
      title = String(title).toLowerCase().trim()
      this.$vuetify.theme.dark = title === 'dark' ? true : false
      this.$store.commit('setTheme', title === 'dark' ? true : false)
    },
    async logout() {
      this.$store.commit('SET', { loading: true })
      await this.$store.dispatch('user/logout')
      this.$store.commit('SET', { loading: false })
    },
    async checker() {
      // console.log(this.$store.state.user.roles)
      this.items = routes
        .filter(
          (x) =>
            x.meta &&
            !x.meta?.hidden &&
            x.meta?.type?.find((x) => this.$store.state.user?.roles?.includes(x) || x === this.$roles.All),
        )
        .map((x) =>
          Object.assign({
            title: x.meta?.title || x.name,
            path: x.path,
            icon: x.meta?.icon || '',
          }),
        )
      // console.log(this.items)
      // console.log(this.$router)
      if (!this.items) {
        Promise.reject('Kesalahan ketika memuat data')
        await this.logout()
      }
    },
  },
}
</script>
