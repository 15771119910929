<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="close"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <molecules-wrapper>
        <v-form ref="form" @submit.prevent="validate">
          <v-row justify="center">
            <v-col cols="12">
              <atoms-title>Tambah Pekerja Shift {{ shift.title || '-' }}</atoms-title>
            </v-col>
            <v-col cols="10" class="rounded-lg my-2">
              <atoms-text-field
                v-if="!$store.state.user.data || !user.enabled"
                label="Cari Pekerja"
                v-model="user.term"
                :rules="[]"
              />
              <atoms-text-field v-else label="Pilih Pekerja">
                <v-combobox
                  class="rounded-lg"
                  v-model="user.value"
                  :items="$store.state.user.data || []"
                  :item-text="(x) => x.nickname + ' → ' + x.email"
                  placeholder="Pilih Pekerja"
                  :menu-props="{ offsetY: true }"
                  :rules="[(v) => !!v || 'Harap disii!']"
                  dense
                  solo
                  flat
                />
              </atoms-text-field>
              <atoms-button
                @click="
                  () => {
                    if (!$store.state.user.data || !user.enabled) {
                      getUsers()
                      user.enabled = true
                    } else {
                      user.term = ''
                      user.value = null
                      user.enabled = false
                    }
                  }
                "
                :loading="$store.state.loading"
                :style="{ width: '100%' }"
                :class="[!$store.state.user.data || !user.enabled ? 'primary' : 'red white--text', 'mb-4']"
                >{{ !$store.state.user.data || !user.enabled ? 'Cari Pekerja' : 'Reset Pencarian' }}</atoms-button
              >
              <div v-if="user.value && user.enabled" class="text-center my-4">
                <atoms-text class="my-1 font-weight-bold">Worker Terpilih</atoms-text>
                <div class="pa-2 accent rounded-lg text-left">
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Nama:</span> {{ user.value.nickname }}</atoms-text
                  >
                  <atoms-text
                    ><span class="font-weight-bold primary--text">Email:</span> {{ user.value.email }}</atoms-text
                  >
                </div>
              </div>
              <atoms-button
                :disabled="!(user.value && user.enabled)"
                :loading="$store.state.loading"
                type="submit"
                :style="{ width: '100%' }"
                class="primary"
                >Daftarkan Pekerja</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </molecules-wrapper>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['shift'],
  data() {
    return {
      isShowWorkerSelected: null,
      result: null,
      mainLoading: false,
      user: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
    }
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden'
  },
  methods: {
    close() {
      document.documentElement.style.overflow = 'auto'
      this.$emit('close')
    },
    async getUsers() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.user.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('user/getAll', {
          custom: {
            workerId: {
              $exists: true,
            },
            $or: [
              {
                nickname: term,
              },
              {
                email: term,
              },
              {
                roles: term,
              },
            ],
          },
          page: 1,
          limit: 0,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      this.$store.commit('SET', { loading: true })
      try {
        console.log(this.user.value)
        const _payload = {
          ...(this.shift || {}),
          assignedWorkerIds: [...new Set([...(this.shift?.assignedWorkerIds || []), this.user?.value?.workerId])],
        }
        await this.$api.patch(`QuotationItems/update`, _payload)
        this.$showDialog({
          title: 'Berhasil',
          body: `Pekerja berhasil ditambahkan`,
        })
        this.close()
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: `Terdapat kesalahan, ${error.message || error}`,
        })
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
  },
}
</script>
