<template>
  <molecules-floating>
    <div
      :style="{
        position: 'fixed',
        top: 0,
        right: 0,
        width: $vuetify.breakpoint.smAndDown ? '90%' : '50%',
        height: '100%',
      }"
      class="background overflow-x-hidden overflow-y-auto rounded-l-lg text-center pb-8"
    >
      <v-row justify="space-between" class="px-8 py-4">
        <v-col cols="2" align="start">
          <v-btn depressed color="primary" icon @click="close"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-form v-if="vacancy" ref="form" v-model="valid" @submit.prevent="preValidate">
          <v-row justify="center">
            <v-col cols="10">
              <atoms-title>{{ vacancy.headHunter ? 'Edit' : 'Tambah' }} HeadHunter</atoms-title>
              <atoms-text>Lowongan {{ vacancy.title }}</atoms-text>
              <v-chip v-if="vacancy.headHunter" class="px-2 mt-2">
                <v-avatar
                  v-if="vacancy.headHunter.user"
                  color="accent"
                  class="rounded-pill"
                  :style="{
                    cursor: 'pointer',
                  }"
                >
                  <atoms-image
                    v-if="vacancy.headHunter.user.picture"
                    aspect-ratio="1"
                    :src="vacancy.headHunter.user.picture || `/lazy-img.webp`"
                  />
                  <v-icon v-else> mdi-account </v-icon>
                </v-avatar>
                <atoms-text>{{ vacancy.headHunter.fullName }}</atoms-text>
                <atoms-text>-(Terkini)</atoms-text>
              </v-chip>
            </v-col>
            <v-col cols="10">
              <div>
                <atoms-text-field
                  v-if="!$store.state.user.data || !user.enabled"
                  label="Cari User"
                  v-model="user.term"
                  :rules="[]"
                />
                <atoms-text-field v-else label="Pilih User">
                  <v-autocomplete
                    class="rounded-lg"
                    v-model="user.value"
                    :items="
                      ($store.state.user.data && $store.state.user.data.map((x) => x.nickname + ' → ' + x.email)) || []
                    "
                    placeholder="Pilih User"
                    :menu-props="{ offsetY: true }"
                    :rules="[(v) => !!v || 'Harap disii!']"
                    dense
                    solo
                    flat
                  />
                </atoms-text-field>
                <atoms-button
                  @click="
                    () => {
                      if (!$store.state.user.data || !user.enabled) {
                        getUsers()
                        user.term = ''
                        user.term = ''
                        user.enabled = true
                      } else {
                        user.enabled = false
                      }
                    }
                  "
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  :class="[!$store.state.user.data || !user.enabled ? 'primary' : 'red white--text', 'mb-4']"
                  >{{ !$store.state.user.data || !user.enabled ? 'Cari User' : 'Reset Pencarian' }}</atoms-button
                >
                <div v-if="user.item && user.enabled" class="text-center my-4">
                  <atoms-text class="my-1 font-weight-bold">User Terpilih</atoms-text>
                  <div class="d-flex align-center gap-5 pa-2 accent rounded-lg text-left">
                    <v-avatar
                      color="accent"
                      size="100"
                      :style="{
                        cursor: 'pointer',
                      }"
                    >
                      <atoms-image
                        v-if="user.item.picture"
                        aspect-ratio="1"
                        :src="user.item.picture || `/lazy-img.webp`"
                      />
                      <v-icon v-else> mdi-account </v-icon>
                    </v-avatar>
                    <div class="pl-5">
                      <atoms-text
                        ><span class="font-weight-bold primary--text">Nama:</span> {{ user.item.nickname }}</atoms-text
                      >
                      <atoms-text
                        ><span class="font-weight-bold primary--text">Email:</span> {{ user.item.email }}</atoms-text
                      >
                    </div>
                  </div>
                </div>
              </div>
              <atoms-button :loading="$store.state.loading" type="submit" :style="{ width: '100%' }" class="primary"
                >Tambah Head Hunter</atoms-button
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </molecules-floating>
</template>
<script>
export default {
  props: ['vacancy'],
  data() {
    return {
      valid: true,
      tabs: null,
      user: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
    }
  },
  watch: {
    'user.value'() {
      if (this.user.value) {
        this.user.item = this.$store.state.user?.data?.find(
          (x) => x.email === this.user.value.split('→')[1].trim(), //→
        )
      }
    },
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden'
    console.log(this.vacancy)
    if (!this.vacancy?._id) {
      this.close()
    }
  },
  methods: {
    async getUsers() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.user.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('user/getAll', {
          custom: {
            $or: [
              {
                nickname: term,
              },
              {
                email: term,
              },
              {
                roles: term,
              },
            ],
          },
          page: 1,
          limit: 0,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async preValidate() {
      this.$store.commit('SET', { loading: true })
      try {
        await this.validate()
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async validate() {
      await this.$refs.form.validate()
      const headHunterId = this.user.item?._id

      try {
        if (!this.valid || !headHunterId || !this.vacancy?._id) {
          this.$showDialog({
            title: 'Perhatian',
            body: `Periksa kembali kolom pengisian`,
          })
          return
        }
        const headHunterName = this.user.item?.worker?.fullName || this.user.item?.nickname

        let respHeadHunter = (
          await this.$store.dispatch('headhunters/getAll', {
            query: { userId: headHunterId },
            returnOnly: true,
          })
        )?.data?.[0]
        if (!respHeadHunter?._id) {
          respHeadHunter = (
            await this.$store.dispatch('headhunters/register', {
              userId: headHunterId,
              fullName: headHunterName,
              phoneNumber: this.user.item?.worker?.phone,
              // experience: this.user.item?.workerDetail?.experiences, // need to change from String into Array
              createdBy: this.$store.state.user._id,
              updatedBy: this.$store.state.user._id,
            })
          )?.result
        }

        if (!respHeadHunter?._id) {
          throw new Error('Kesalahan ketika menambahkan Head Hunter')
        }

        await this.$store.dispatch('vacancy/update', {
          _id: this.vacancy._id,
          idHeadHunter: respHeadHunter?._id,
          useHeadHunter: true,
        })

        this.$showDialog({
          title: 'Berhasil',
          body: `${headHunterName} berhasil menjadi Head Hunter ${this.vacancy?.title}`,
        })
        this.close()
      } catch (error) {
        Promise.reject(error)
      }
    },
    close() {
      document.documentElement.style.overflow = 'auto'
      this.$emit('close')
    },
  },
}
</script>
