export default {
  Gianyar: [
    { label: 'Ubud', value: 'Ubud' },
    { label: 'Tegallalang', value: 'Tegallalang' },
    { label: 'Tampak Siring', value: 'Tampak Siring' },
    { label: 'Sukawati', value: 'Sukawati' },
    { label: 'Payangan', value: 'Payangan' },
    { label: 'Gianyar', value: 'Gianyar' },
    { label: 'Belah Batuh (Blahbatuh)', value: 'Belah Batuh (Blahbatuh)' }
  ],
  Bangli: [
    { label: 'Tembuku', value: 'Tembuku' },
    { label: 'Susut', value: 'Susut' },
    { label: 'Kintamani', value: 'Kintamani' },
    { label: 'Bangli', value: 'Bangli' }
  ],
  Buleleng: [
    { label: 'Tejakula', value: 'Tejakula' },
    { label: 'Sukasada', value: 'Sukasada' },
    { label: 'Seririt', value: 'Seririt' },
    { label: 'Sawan', value: 'Sawan' },
    { label: 'Kubutambahan', value: 'Kubutambahan' },
    { label: 'Gerokgak', value: 'Gerokgak' },
    { label: 'Busungbiu', value: 'Busungbiu' },
    { label: 'Buleleng', value: 'Buleleng' },
    { label: 'Banjar', value: 'Banjar' }
  ],
  Tabanan: [
    { label: 'Tabanan', value: 'Tabanan' },
    { label: 'Selemadeg', value: 'Selemadeg' },
    { label: 'Salemadeg Timur', value: 'Salemadeg Timur' },
    { label: 'Salemadeg Barat', value: 'Salemadeg Barat' },
    { label: 'Pupuan', value: 'Pupuan' },
    { label: 'Penebel', value: 'Penebel' },
    { label: 'Marga', value: 'Marga' },
    { label: 'Kerambitan', value: 'Kerambitan' },
    { label: 'Kediri', value: 'Kediri' },
    { label: 'Baturiti', value: 'Baturiti' }
  ],
  Karangasem: [
    { label: 'Sidemen', value: 'Sidemen' },
    { label: 'Selat', value: 'Selat' },
    { label: 'Rendang', value: 'Rendang' },
    { label: 'Manggis', value: 'Manggis' },
    { label: 'Kubu', value: 'Kubu' },
    { label: 'Karang Asem', value: 'Karang Asem' },
    { label: 'Bebandem', value: 'Bebandem' },
    { label: 'Abang', value: 'Abang' }
  ],
  Badung: [
    { label: 'Petang', value: 'Petang' },
    { label: 'Mengwi', value: 'Mengwi' },
    { label: 'Kuta Utara', value: 'Kuta Utara' },
    { label: 'Kuta Selatan', value: 'Kuta Selatan' },
    { label: 'Kuta', value: 'Kuta' },
    { label: 'Abiansemal', value: 'Abiansemal' }
  ],
  Jembrana: [
    { label: 'Pekutatan', value: 'Pekutatan' },
    { label: 'Negara', value: 'Negara' },
    { label: 'Mendoyo', value: 'Mendoyo' },
    { label: 'Melaya', value: 'Melaya' },
    { label: 'Jembrana', value: 'Jembrana' }
  ],
  Klungkung: [
    { label: 'Nusapenida', value: 'Nusapenida' },
    { label: 'Klungkung', value: 'Klungkung' },
    { label: 'Dawan', value: 'Dawan' },
    { label: 'Banjarangkan', value: 'Banjarangkan' }
  ],
  Denpasar: [
    { label: 'Denpasar Utara', value: 'Denpasar Utara' },
    { label: 'Denpasar Timur', value: 'Denpasar Timur' },
    { label: 'Denpasar Selatan', value: 'Denpasar Selatan' },
    { label: 'Denpasar Barat', value: 'Denpasar Barat' }
  ],
  'Bangka Selatan': [
    { label: 'Tukak Sadai', value: 'Tukak Sadai' },
    { label: 'Toboali', value: 'Toboali' },
    { label: 'Simpang Rimba', value: 'Simpang Rimba' },
    { label: 'Pulau Besar', value: 'Pulau Besar' },
    { label: 'Payung', value: 'Payung' },
    { label: 'Lepar Pongok', value: 'Lepar Pongok' },
    { label: 'Kepulauan Pongok', value: 'Kepulauan Pongok' },
    { label: 'Air Gegas', value: 'Air Gegas' }
  ],
  'Bangka Barat': [
    { label: 'Tempilang', value: 'Tempilang' },
    { label: 'Simpang Teritip', value: 'Simpang Teritip' },
    { label: 'Parittiga', value: 'Parittiga' },
    { label: 'Mentok (Muntok)', value: 'Mentok (Muntok)' },
    { label: 'Kelapa', value: 'Kelapa' },
    { label: 'Jebus', value: 'Jebus' }
  ],
  Belitung: [
    { label: 'Tanjung Pandan', value: 'Tanjung Pandan' },
    { label: 'Sijuk', value: 'Sijuk' },
    { label: 'Selat Nasik', value: 'Selat Nasik' },
    { label: 'Membalong', value: 'Membalong' },
    { label: 'Badau', value: 'Badau' }
  ],
  'Pangkal Pinang': [
    { label: 'Taman Sari', value: 'Taman Sari' },
    { label: 'Rangkui', value: 'Rangkui' },
    { label: 'Pangkal Balam', value: 'Pangkal Balam' },
    { label: 'Girimaya', value: 'Girimaya' },
    { label: 'Gerunggang', value: 'Gerunggang' },
    { label: 'Gabek', value: 'Gabek' },
    { label: 'Bukit Intan', value: 'Bukit Intan' }
  ],
  'Bangka Tengah': [
    { label: 'Sungai Selan', value: 'Sungai Selan' },
    { label: 'Simpang Katis', value: 'Simpang Katis' },
    { label: 'Pangkalan Baru', value: 'Pangkalan Baru' },
    { label: 'Namang', value: 'Namang' },
    { label: 'Lubuk Besar', value: 'Lubuk Besar' },
    { label: 'Koba', value: 'Koba' }
  ],
  Bangka: [
    { label: 'Sungai Liat', value: 'Sungai Liat' },
    { label: 'Riau Silip', value: 'Riau Silip' },
    { label: 'Puding Besar', value: 'Puding Besar' },
    { label: 'Pemali', value: 'Pemali' },
    { label: 'Merawang', value: 'Merawang' },
    { label: 'Mendo Barat', value: 'Mendo Barat' },
    { label: 'Belinyu', value: 'Belinyu' },
    { label: 'Bakam', value: 'Bakam' }
  ],
  'Belitung Timur': [
    { label: 'Simpang Renggiang', value: 'Simpang Renggiang' },
    { label: 'Simpang Pesak', value: 'Simpang Pesak' },
    { label: 'Manggar', value: 'Manggar' },
    { label: 'Kelapa Kampit', value: 'Kelapa Kampit' },
    { label: 'Gantung', value: 'Gantung' },
    { label: 'Dendang', value: 'Dendang' },
    { label: 'Damar', value: 'Damar' }
  ],
  Lebak: [
    { label: 'Warunggunung', value: 'Warunggunung' },
    { label: 'Wanasalam', value: 'Wanasalam' },
    { label: 'Sobang', value: 'Sobang' },
    { label: 'Sajira', value: 'Sajira' },
    { label: 'Rangkasbitung', value: 'Rangkasbitung' },
    { label: 'Panggarangan', value: 'Panggarangan' },
    { label: 'Muncang', value: 'Muncang' },
    { label: 'Malingping', value: 'Malingping' },
    { label: 'Maja', value: 'Maja' },
    { label: 'Leuwidamar', value: 'Leuwidamar' },
    { label: 'Lebakgedong', value: 'Lebakgedong' },
    { label: 'Kalanganyar', value: 'Kalanganyar' },
    { label: 'Gunung Kencana', value: 'Gunung Kencana' },
    { label: 'Curugbitung', value: 'Curugbitung' },
    { label: 'Cirinten', value: 'Cirinten' },
    { label: 'Cipanas', value: 'Cipanas' },
    { label: 'Cimarga', value: 'Cimarga' },
    { label: 'Cilograng', value: 'Cilograng' },
    { label: 'Cileles', value: 'Cileles' },
    { label: 'Cikulur', value: 'Cikulur' },
    { label: 'Cijaku', value: 'Cijaku' },
    { label: 'Cihara', value: 'Cihara' },
    { label: 'Cigemblong', value: 'Cigemblong' },
    { label: 'Cibeber', value: 'Cibeber' },
    { label: 'Cibadak', value: 'Cibadak' },
    { label: 'Bojongmanik', value: 'Bojongmanik' },
    { label: 'Bayah', value: 'Bayah' },
    { label: 'Banjarsari', value: 'Banjarsari' }
  ],
  Serang: [
    { label: 'Waringin Kurung', value: 'Waringin Kurung' },
    { label: 'Walantaka', value: 'Walantaka' },
    { label: 'Tunjung Teja', value: 'Tunjung Teja' },
    { label: 'Tirtayasa', value: 'Tirtayasa' },
    { label: 'Tanara', value: 'Tanara' },
    { label: 'Taktakan', value: 'Taktakan' },
    { label: 'Serang', value: 'Serang' },
    { label: 'Pulo Ampel', value: 'Pulo Ampel' },
    { label: 'Pontang', value: 'Pontang' },
    { label: 'Petir', value: 'Petir' },
    { label: 'Pamarayan', value: 'Pamarayan' },
    { label: 'Padarincang', value: 'Padarincang' },
    { label: 'Pabuaran', value: 'Pabuaran' },
    { label: 'Mancak', value: 'Mancak' },
    { label: 'Lebak Wangi', value: 'Lebak Wangi' },
    { label: 'Kramatwatu', value: 'Kramatwatu' },
    { label: 'Kragilan', value: 'Kragilan' },
    { label: 'Kopo', value: 'Kopo' },
    { label: 'Kibin', value: 'Kibin' },
    { label: 'Kasemen', value: 'Kasemen' },
    { label: 'Jawilan', value: 'Jawilan' },
    { label: 'Gunungsari', value: 'Gunungsari' },
    { label: 'Curug', value: 'Curug' },
    { label: 'Ciruas', value: 'Ciruas' },
    { label: 'Cipocok Jaya', value: 'Cipocok Jaya' },
    { label: 'Ciomas', value: 'Ciomas' },
    { label: 'Cinangka', value: 'Cinangka' },
    { label: 'Cikeusal', value: 'Cikeusal' },
    { label: 'Cikande', value: 'Cikande' },
    { label: 'Carenang (Cerenang)', value: 'Carenang (Cerenang)' },
    { label: 'Bojonegara', value: 'Bojonegara' },
    { label: 'Binuang', value: 'Binuang' },
    { label: 'Baros', value: 'Baros' },
    { label: 'Bandung', value: 'Bandung' },
    { label: 'Anyar', value: 'Anyar' }
  ],
  Tangerang: [
    { label: 'Tigaraksa', value: 'Tigaraksa' },
    { label: 'Teluknaga', value: 'Teluknaga' },
    { label: 'Tangerang', value: 'Tangerang' },
    { label: 'Sukamulya', value: 'Sukamulya' },
    { label: 'Sukadiri', value: 'Sukadiri' },
    { label: 'Solear', value: 'Solear' },
    { label: 'Sindang Jaya', value: 'Sindang Jaya' },
    { label: 'Sepatan Timur', value: 'Sepatan Timur' },
    { label: 'Sepatan', value: 'Sepatan' },
    { label: 'Rajeg', value: 'Rajeg' },
    { label: 'Pinang (Penang)', value: 'Pinang (Penang)' },
    { label: 'Periuk', value: 'Periuk' },
    { label: 'Pasar Kemis', value: 'Pasar Kemis' },
    { label: 'Panongan', value: 'Panongan' },
    { label: 'Pakuhaji', value: 'Pakuhaji' },
    { label: 'Pagedangan', value: 'Pagedangan' },
    { label: 'Neglasari', value: 'Neglasari' },
    { label: 'Mekar Baru', value: 'Mekar Baru' },
    { label: 'Mauk', value: 'Mauk' },
    { label: 'Legok', value: 'Legok' },
    { label: 'Larangan', value: 'Larangan' },
    { label: 'Kronjo', value: 'Kronjo' },
    { label: 'Kresek', value: 'Kresek' },
    { label: 'Kosambi', value: 'Kosambi' },
    { label: 'Kemiri', value: 'Kemiri' },
    { label: 'Kelapa Dua', value: 'Kelapa Dua' },
    { label: 'Karawaci', value: 'Karawaci' },
    { label: 'Karang Tengah', value: 'Karang Tengah' },
    { label: 'Jayanti', value: 'Jayanti' },
    { label: 'Jatiuwung', value: 'Jatiuwung' },
    { label: 'Jambe', value: 'Jambe' },
    { label: 'Gunung Kaler', value: 'Gunung Kaler' },
    { label: 'Curug', value: 'Curug' },
    { label: 'Cisoka', value: 'Cisoka' },
    { label: 'Cisauk', value: 'Cisauk' },
    { label: 'Cipondoh', value: 'Cipondoh' },
    { label: 'Ciledug', value: 'Ciledug' },
    { label: 'Cikupa', value: 'Cikupa' },
    { label: 'Cibodas', value: 'Cibodas' },
    { label: 'Benda', value: 'Benda' },
    { label: 'Batuceper', value: 'Batuceper' },
    { label: 'Balaraja', value: 'Balaraja' }
  ],
  Pandeglang: [
    { label: 'Sumur', value: 'Sumur' },
    { label: 'Sukaresmi', value: 'Sukaresmi' },
    { label: 'Sobang', value: 'Sobang' },
    { label: 'Sindangresmi', value: 'Sindangresmi' },
    { label: 'Saketi', value: 'Saketi' },
    { label: 'Pulosari', value: 'Pulosari' },
    { label: 'Picung', value: 'Picung' },
    { label: 'Patia', value: 'Patia' },
    { label: 'Panimbang', value: 'Panimbang' },
    { label: 'Pandeglang', value: 'Pandeglang' },
    { label: 'Pagelaran', value: 'Pagelaran' },
    { label: 'Munjul', value: 'Munjul' },
    { label: 'Menes', value: 'Menes' },
    { label: 'Mekarjaya', value: 'Mekarjaya' },
    { label: 'Mandalawangi', value: 'Mandalawangi' },
    { label: 'Majasari', value: 'Majasari' },
    { label: 'Labuan', value: 'Labuan' },
    { label: 'Koroncong', value: 'Koroncong' },
    { label: 'Karang Tanjung', value: 'Karang Tanjung' },
    { label: 'Kaduhejo', value: 'Kaduhejo' },
    { label: 'Jiput', value: 'Jiput' },
    { label: 'Cisata', value: 'Cisata' },
    { label: 'Cipeucang', value: 'Cipeucang' },
    { label: 'Cimanuk', value: 'Cimanuk' },
    { label: 'Cimanggu', value: 'Cimanggu' },
    { label: 'Cikeusik', value: 'Cikeusik' },
    { label: 'Cikeudal (Cikedal)', value: 'Cikeudal (Cikedal)' },
    { label: 'Cigeulis', value: 'Cigeulis' },
    { label: 'Cibitung', value: 'Cibitung' },
    { label: 'Cibaliung', value: 'Cibaliung' },
    { label: 'Carita', value: 'Carita' },
    { label: 'Cadasari', value: 'Cadasari' },
    { label: 'Bojong', value: 'Bojong' },
    { label: 'Banjar', value: 'Banjar' },
    { label: 'Angsana', value: 'Angsana' }
  ],
  'Tangerang Selatan': [
    { label: 'Setu', value: 'Setu' },
    { label: 'Serpong Utara', value: 'Serpong Utara' },
    { label: 'Serpong', value: 'Serpong' },
    { label: 'Pondok Aren', value: 'Pondok Aren' },
    { label: 'Pamulang', value: 'Pamulang' },
    { label: 'Ciputat Timur', value: 'Ciputat Timur' },
    { label: 'Ciputat', value: 'Ciputat' }
  ],
  Cilegon: [
    { label: 'Purwakarta', value: 'Purwakarta' },
    { label: 'Pulomerak', value: 'Pulomerak' },
    { label: 'Jombang', value: 'Jombang' },
    { label: 'Gerogol', value: 'Gerogol' },
    { label: 'Ciwandan', value: 'Ciwandan' },
    { label: 'Citangkil', value: 'Citangkil' },
    { label: 'Cilegon', value: 'Cilegon' },
    { label: 'Cibeber', value: 'Cibeber' }
  ],
  Seluma: [
    { label: 'Talo', value: 'Talo' },
    { label: 'Sukaraja', value: 'Sukaraja' },
    { label: 'Semidang Alas Maras', value: 'Semidang Alas Maras' },
    { label: 'Semidang Alas', value: 'Semidang Alas' },
    { label: 'Seluma Utara', value: 'Seluma Utara' },
    { label: 'Seluma Timur', value: 'Seluma Timur' },
    { label: 'Seluma Selatan', value: 'Seluma Selatan' },
    { label: 'Seluma Barat', value: 'Seluma Barat' },
    { label: 'Seluma', value: 'Seluma' },
    { label: 'Lubuk Sandi', value: 'Lubuk Sandi' },
    { label: 'Ilir Talo', value: 'Ilir Talo' },
    { label: 'Air Periukan', value: 'Air Periukan' }
  ],
  'Bengkulu Tengah': [
    { label: 'Talang Empat', value: 'Talang Empat' },
    { label: 'Taba Penanjung', value: 'Taba Penanjung' },
    { label: 'Pondok Kubang', value: 'Pondok Kubang' },
    { label: 'Pondok Kelapa', value: 'Pondok Kelapa' },
    { label: 'Pematang Tiga', value: 'Pematang Tiga' },
    { label: 'Pagar Jati', value: 'Pagar Jati' },
    { label: 'Merigi Sakti', value: 'Merigi Sakti' },
    { label: 'Merigi Kelindang', value: 'Merigi Kelindang' },
    { label: 'Karang Tinggi', value: 'Karang Tinggi' },
    { label: 'Bang Haji', value: 'Bang Haji' }
  ],
  Bengkulu: [
    { label: 'Sungai Serut', value: 'Sungai Serut' },
    { label: 'Singaran Pati', value: 'Singaran Pati' },
    { label: 'Selebar', value: 'Selebar' },
    { label: 'Ratu Samban', value: 'Ratu Samban' },
    { label: 'Ratu Agung', value: 'Ratu Agung' },
    { label: 'Muara Bangka Hulu', value: 'Muara Bangka Hulu' },
    { label: 'Kampung Melayu', value: 'Kampung Melayu' },
    { label: 'Gading Cempaka', value: 'Gading Cempaka' }
  ],
  'Muko Muko': [
    { label: 'Sungai Rumbai', value: 'Sungai Rumbai' },
    { label: 'Selagan Raya', value: 'Selagan Raya' },
    { label: 'Pondok Suguh', value: 'Pondok Suguh' },
    { label: 'Penarik', value: 'Penarik' },
    { label: 'Malin Deman', value: 'Malin Deman' },
    { label: 'Lubuk Pinang', value: 'Lubuk Pinang' },
    {
      label: 'Kota Mukomuko (Mukomuko Utara)',
      value: 'Kota Mukomuko (Mukomuko Utara)'
    },
    {
      label: 'Ipuh (Muko-Muko Selatan)',
      value: 'Ipuh (Muko-Muko Selatan)'
    },
    { label: 'Air Rami', value: 'Air Rami' },
    { label: 'Air Majunto', value: 'Air Majunto' },
    { label: 'Air Dikit', value: 'Air Dikit' }
  ],
  'Rejang Lebong': [
    { label: 'Sindang Kelingi', value: 'Sindang Kelingi' },
    { label: 'Sindang Daratan', value: 'Sindang Daratan' },
    { label: 'Sindang Beliti Ulu', value: 'Sindang Beliti Ulu' },
    { label: 'Sindang Beliti Ilir', value: 'Sindang Beliti Ilir' },
    { label: 'Selupu Rejang', value: 'Selupu Rejang' },
    { label: 'Padang Ulak Tanding', value: 'Padang Ulak Tanding' },
    { label: 'Kota Padang', value: 'Kota Padang' },
    { label: 'Curup Utara', value: 'Curup Utara' },
    { label: 'Curup Timur', value: 'Curup Timur' },
    { label: 'Curup Tengah', value: 'Curup Tengah' },
    { label: 'Curup Selatan', value: 'Curup Selatan' },
    { label: 'Curup', value: 'Curup' },
    { label: 'Binduriang', value: 'Binduriang' },
    { label: 'Bermani Ulu Raya', value: 'Bermani Ulu Raya' },
    { label: 'Bermani Ulu', value: 'Bermani Ulu' }
  ],
  Kaur: [
    { label: 'Semidang Gumai (Gumay)', value: 'Semidang Gumai (Gumay)' },
    { label: 'Padang Guci Hulu', value: 'Padang Guci Hulu' },
    { label: 'Padang Guci Hilir', value: 'Padang Guci Hilir' },
    { label: 'Nasal', value: 'Nasal' },
    { label: 'Muara Sahung', value: 'Muara Sahung' },
    { label: 'Maje', value: 'Maje' },
    { label: 'Lungkang Kule', value: 'Lungkang Kule' },
    { label: 'Luas', value: 'Luas' },
    { label: 'Kinal', value: 'Kinal' },
    { label: 'Kelam Tengah', value: 'Kelam Tengah' },
    { label: 'Kaur Utara', value: 'Kaur Utara' },
    { label: 'Kaur Tengah', value: 'Kaur Tengah' },
    { label: 'Kaur Selatan', value: 'Kaur Selatan' }
  ],
  'Bengkulu Selatan': [
    { label: 'Seginim', value: 'Seginim' },
    { label: 'Pinoraya', value: 'Pinoraya' },
    { label: 'Pino', value: 'Pino' },
    { label: 'Pasar Manna', value: 'Pasar Manna' },
    { label: 'Manna', value: 'Manna' },
    { label: 'Kota Manna', value: 'Kota Manna' },
    { label: 'Kedurang Ilir', value: 'Kedurang Ilir' },
    { label: 'Kedurang', value: 'Kedurang' },
    { label: 'Bunga Mas', value: 'Bunga Mas' },
    { label: 'Air Nipis', value: 'Air Nipis' }
  ],
  Kepahiang: [
    { label: 'Seberang Musi', value: 'Seberang Musi' },
    { label: 'Muara Kemumu', value: 'Muara Kemumu' },
    { label: 'Merigi', value: 'Merigi' },
    { label: 'Kepahiang', value: 'Kepahiang' },
    { label: 'Kebawetan', value: 'Kebawetan' },
    { label: 'Bermani Ilir', value: 'Bermani Ilir' }
  ],
  Lebong: [
    { label: 'Rimbo Pengadang', value: 'Rimbo Pengadang' },
    { label: 'Pinang Belapis', value: 'Pinang Belapis' },
    { label: 'Pelabai', value: 'Pelabai' },
    { label: 'Lebong Utara', value: 'Lebong Utara' },
    { label: 'Lebong Tengah', value: 'Lebong Tengah' },
    { label: 'Lebong Selatan', value: 'Lebong Selatan' },
    { label: 'Lebong Sakti', value: 'Lebong Sakti' },
    { label: 'Lebong Atas', value: 'Lebong Atas' },
    { label: 'Bingin Kuning', value: 'Bingin Kuning' },
    { label: 'Amen', value: 'Amen' }
  ],
  'Bengkulu Utara': [
    { label: 'Putri Hijau', value: 'Putri Hijau' },
    { label: 'Padang Jaya', value: 'Padang Jaya' },
    { label: 'Napal Putih', value: 'Napal Putih' },
    { label: 'Lais', value: 'Lais' },
    { label: 'Ketahun', value: 'Ketahun' },
    { label: 'Kerkap', value: 'Kerkap' },
    { label: 'Hulu Palik', value: 'Hulu Palik' },
    { label: 'Giri Mulia', value: 'Giri Mulia' },
    { label: 'Enggano', value: 'Enggano' },
    { label: 'Batik Nau', value: 'Batik Nau' },
    { label: 'Arma Jaya', value: 'Arma Jaya' },
    { label: 'Arga Makmur', value: 'Arga Makmur' },
    { label: 'Air Padang', value: 'Air Padang' },
    { label: 'Air Napal', value: 'Air Napal' },
    { label: 'Air Besi', value: 'Air Besi' }
  ],
  'Gunung Kidul': [
    { label: 'Wonosari', value: 'Wonosari' },
    { label: 'Tepus', value: 'Tepus' },
    { label: 'Tanjungsari', value: 'Tanjungsari' },
    { label: 'Semin', value: 'Semin' },
    { label: 'Semanu', value: 'Semanu' },
    { label: 'Sapto Sari', value: 'Sapto Sari' },
    { label: 'Rongkop', value: 'Rongkop' },
    { label: 'Purwosari', value: 'Purwosari' },
    { label: 'Ponjong', value: 'Ponjong' },
    { label: 'Playen', value: 'Playen' },
    { label: 'Patuk', value: 'Patuk' },
    { label: 'Panggang', value: 'Panggang' },
    { label: 'Paliyan', value: 'Paliyan' },
    { label: 'Nglipar', value: 'Nglipar' },
    { label: 'Ngawen', value: 'Ngawen' },
    { label: 'Karangmojo', value: 'Karangmojo' },
    { label: 'Girisubo', value: 'Girisubo' },
    { label: 'Gedang Sari', value: 'Gedang Sari' }
  ],
  Yogyakarta: [
    { label: 'Wirobrajan', value: 'Wirobrajan' },
    { label: 'Umbulharjo', value: 'Umbulharjo' },
    { label: 'Tegalrejo', value: 'Tegalrejo' },
    { label: 'Pakualaman', value: 'Pakualaman' },
    { label: 'Ngampilan', value: 'Ngampilan' },
    { label: 'Mergangsan', value: 'Mergangsan' },
    { label: 'Mantrijeron', value: 'Mantrijeron' },
    { label: 'Kraton', value: 'Kraton' },
    { label: 'Kotagede', value: 'Kotagede' },
    { label: 'Jetis', value: 'Jetis' },
    { label: 'Gondomanan', value: 'Gondomanan' },
    { label: 'Gondokusuman', value: 'Gondokusuman' },
    { label: 'Gedong Tengen', value: 'Gedong Tengen' },
    { label: 'Danurejan', value: 'Danurejan' }
  ],
  'Kulon Progo': [
    { label: 'Wates', value: 'Wates' },
    { label: 'Temon', value: 'Temon' },
    { label: 'Sentolo', value: 'Sentolo' },
    { label: 'Samigaluh', value: 'Samigaluh' },
    { label: 'Pengasih', value: 'Pengasih' },
    { label: 'Panjatan', value: 'Panjatan' },
    { label: 'Nanggulan', value: 'Nanggulan' },
    { label: 'Lendah', value: 'Lendah' },
    { label: 'Kokap', value: 'Kokap' },
    { label: 'Kalibawang', value: 'Kalibawang' },
    { label: 'Girimulyo', value: 'Girimulyo' },
    { label: 'Galur', value: 'Galur' }
  ],
  Sleman: [
    { label: 'Turi', value: 'Turi' },
    { label: 'Tempel', value: 'Tempel' },
    { label: 'Sleman', value: 'Sleman' },
    { label: 'Seyegan', value: 'Seyegan' },
    { label: 'Prambanan', value: 'Prambanan' },
    { label: 'Pakem', value: 'Pakem' },
    { label: 'Ngemplak', value: 'Ngemplak' },
    { label: 'Ngaglik', value: 'Ngaglik' },
    { label: 'Moyudan', value: 'Moyudan' },
    { label: 'Mlati', value: 'Mlati' },
    { label: 'Minggir', value: 'Minggir' },
    { label: 'Kalasan', value: 'Kalasan' },
    { label: 'Godean', value: 'Godean' },
    { label: 'Gamping', value: 'Gamping' },
    { label: 'Depok', value: 'Depok' },
    { label: 'Cangkringan', value: 'Cangkringan' },
    { label: 'Berbah', value: 'Berbah' }
  ],
  Bantul: [
    { label: 'Srandakan', value: 'Srandakan' },
    { label: 'Sewon', value: 'Sewon' },
    { label: 'Sedayu', value: 'Sedayu' },
    { label: 'Sanden', value: 'Sanden' },
    { label: 'Pundong', value: 'Pundong' },
    { label: 'Pleret', value: 'Pleret' },
    { label: 'Piyungan', value: 'Piyungan' },
    { label: 'Pandak', value: 'Pandak' },
    { label: 'Pajangan', value: 'Pajangan' },
    { label: 'Kretek', value: 'Kretek' },
    { label: 'Kasihan', value: 'Kasihan' },
    { label: 'Jetis', value: 'Jetis' },
    { label: 'Imogiri', value: 'Imogiri' },
    { label: 'Dlingo', value: 'Dlingo' },
    { label: 'Bantul', value: 'Bantul' },
    { label: 'Banguntapan', value: 'Banguntapan' },
    { label: 'Bambang Lipuro', value: 'Bambang Lipuro' }
  ],
  'Jakarta Selatan': [
    { label: 'Tebet', value: 'Tebet' },
    { label: 'Setia Budi', value: 'Setia Budi' },
    { label: 'Pesanggrahan', value: 'Pesanggrahan' },
    { label: 'Pasar Minggu', value: 'Pasar Minggu' },
    { label: 'Pancoran', value: 'Pancoran' },
    { label: 'Mampang Prapatan', value: 'Mampang Prapatan' },
    { label: 'Kebayoran Lama', value: 'Kebayoran Lama' },
    { label: 'Kebayoran Baru', value: 'Kebayoran Baru' },
    { label: 'Jagakarsa', value: 'Jagakarsa' },
    { label: 'Cilandak', value: 'Cilandak' }
  ],
  'Jakarta Utara': [
    { label: 'Tanjung Priok', value: 'Tanjung Priok' },
    { label: 'Penjaringan', value: 'Penjaringan' },
    { label: 'Pademangan', value: 'Pademangan' },
    { label: 'Koja', value: 'Koja' },
    { label: 'Kelapa Gading', value: 'Kelapa Gading' },
    { label: 'Cilincing', value: 'Cilincing' }
  ],
  'Jakarta Pusat': [
    { label: 'Tanah Abang', value: 'Tanah Abang' },
    { label: 'Senen', value: 'Senen' },
    { label: 'Sawah Besar', value: 'Sawah Besar' },
    { label: 'Menteng', value: 'Menteng' },
    { label: 'Kemayoran', value: 'Kemayoran' },
    { label: 'Johar Baru', value: 'Johar Baru' },
    { label: 'Gambir', value: 'Gambir' },
    { label: 'Cempaka Putih', value: 'Cempaka Putih' }
  ],
  'Jakarta Barat': [
    { label: 'Tambora', value: 'Tambora' },
    { label: 'Taman Sari', value: 'Taman Sari' },
    { label: 'Palmerah', value: 'Palmerah' },
    { label: 'Kembangan', value: 'Kembangan' },
    { label: 'Kebon Jeruk', value: 'Kebon Jeruk' },
    { label: 'Kalideres', value: 'Kalideres' },
    { label: 'Grogol Petamburan', value: 'Grogol Petamburan' },
    { label: 'Cengkareng', value: 'Cengkareng' }
  ],
  'Jakarta Timur': [
    { label: 'Pulo Gadung', value: 'Pulo Gadung' },
    { label: 'Pasar Rebo', value: 'Pasar Rebo' },
    { label: 'Matraman', value: 'Matraman' },
    { label: 'Makasar', value: 'Makasar' },
    { label: 'Kramat Jati', value: 'Kramat Jati' },
    { label: 'Jatinegara', value: 'Jatinegara' },
    { label: 'Duren Sawit', value: 'Duren Sawit' },
    { label: 'Ciracas', value: 'Ciracas' },
    { label: 'Cipayung', value: 'Cipayung' },
    { label: 'Cakung', value: 'Cakung' }
  ],
  'Kepulauan Seribu': [
    { label: 'Kepulauan Seribu Utara', value: 'Kepulauan Seribu Utara' },
    { label: 'Kepulauan Seribu Selatan', value: 'Kepulauan Seribu Selatan' }
  ],
  Boalemo: [
    { label: 'Wonosari', value: 'Wonosari' },
    { label: 'Tilamuta', value: 'Tilamuta' },
    { label: 'Paguyaman Pantai', value: 'Paguyaman Pantai' },
    { label: 'Paguyaman', value: 'Paguyaman' },
    { label: 'Mananggu', value: 'Mananggu' },
    { label: 'Dulupi', value: 'Dulupi' },
    { label: 'Botumoita', value: 'Botumoita' }
  ],
  Pohuwato: [
    { label: 'Wanggarasi', value: 'Wanggarasi' },
    { label: 'Taluditi (Taluduti)', value: 'Taluditi (Taluduti)' },
    { label: 'Randangan', value: 'Randangan' },
    { label: 'Popayato Timur', value: 'Popayato Timur' },
    { label: 'Popayato Barat', value: 'Popayato Barat' },
    { label: 'Popayato', value: 'Popayato' },
    { label: 'Patilanggio', value: 'Patilanggio' },
    { label: 'Paguat', value: 'Paguat' },
    { label: 'Marisa', value: 'Marisa' },
    { label: 'Lemito', value: 'Lemito' },
    { label: 'Duhiadaa', value: 'Duhiadaa' },
    { label: 'Dengilo', value: 'Dengilo' },
    { label: 'Buntulia', value: 'Buntulia' }
  ],
  'Gorontalo Utara': [
    { label: 'Tomolito', value: 'Tomolito' },
    { label: 'Tolinggula', value: 'Tolinggula' },
    { label: 'Sumalata Timur', value: 'Sumalata Timur' },
    { label: 'Sumalata', value: 'Sumalata' },
    { label: 'Ponelo Kepulauan', value: 'Ponelo Kepulauan' },
    { label: 'Monano', value: 'Monano' },
    { label: 'Kwandang', value: 'Kwandang' },
    { label: 'Gentuma Raya', value: 'Gentuma Raya' },
    { label: 'Biau', value: 'Biau' },
    { label: 'Atinggola', value: 'Atinggola' },
    { label: 'Anggrek', value: 'Anggrek' }
  ],
  Gorontalo: [
    { label: 'Tolangohula', value: 'Tolangohula' },
    { label: 'Tilango', value: 'Tilango' },
    { label: 'Tibawa', value: 'Tibawa' },
    { label: 'Telaga Jaya', value: 'Telaga Jaya' },
    { label: 'Telaga Biru', value: 'Telaga Biru' },
    { label: 'Telaga', value: 'Telaga' },
    { label: 'Tabongo', value: 'Tabongo' },
    { label: 'Sipatana', value: 'Sipatana' },
    { label: 'Pulubala', value: 'Pulubala' },
    { label: 'Mootilango', value: 'Mootilango' },
    { label: 'Limboto Barat', value: 'Limboto Barat' },
    { label: 'Limboto', value: 'Limboto' },
    { label: 'Kota Utara', value: 'Kota Utara' },
    { label: 'Kota Timur', value: 'Kota Timur' },
    { label: 'Kota Tengah', value: 'Kota Tengah' },
    { label: 'Kota Selatan', value: 'Kota Selatan' },
    { label: 'Kota Barat', value: 'Kota Barat' },
    { label: 'Hulonthalangi', value: 'Hulonthalangi' },
    { label: 'Dungingi', value: 'Dungingi' },
    { label: 'Dungaliyo', value: 'Dungaliyo' },
    { label: 'Dumbo Raya', value: 'Dumbo Raya' },
    { label: 'Bongomeme', value: 'Bongomeme' },
    { label: 'Boliohuto (Boliyohuto)', value: 'Boliohuto (Boliyohuto)' },
    { label: 'Biluhu', value: 'Biluhu' },
    { label: 'Bilato', value: 'Bilato' },
    { label: 'Batudaa Pantai', value: 'Batudaa Pantai' },
    { label: 'Batudaa', value: 'Batudaa' },
    { label: 'Asparaga', value: 'Asparaga' }
  ],
  'Bone Bolango': [
    { label: 'Tilongkabila', value: 'Tilongkabila' },
    { label: 'Tapa', value: 'Tapa' },
    { label: 'Suwawa Timur', value: 'Suwawa Timur' },
    { label: 'Suwawa Tengah', value: 'Suwawa Tengah' },
    { label: 'Suwawa Selatan', value: 'Suwawa Selatan' },
    { label: 'Suwawa', value: 'Suwawa' },
    { label: 'Pinogu', value: 'Pinogu' },
    { label: 'Kabila Bone', value: 'Kabila Bone' },
    { label: 'Kabila', value: 'Kabila' },
    { label: 'Bulawa', value: 'Bulawa' },
    { label: 'Bulango Utara', value: 'Bulango Utara' },
    { label: 'Bulango Ulu', value: 'Bulango Ulu' },
    { label: 'Bulango Timur', value: 'Bulango Timur' },
    { label: 'Bulango Selatan', value: 'Bulango Selatan' },
    { label: 'Botu Pingge', value: 'Botu Pingge' },
    { label: 'Bonepantai', value: 'Bonepantai' },
    { label: 'Bone Raya', value: 'Bone Raya' },
    { label: 'Bone', value: 'Bone' }
  ],
  Tebo: [
    { label: 'VII Koto Ilir', value: 'VII Koto Ilir' },
    { label: 'VII Koto', value: 'VII Koto' },
    { label: 'Tengah Ilir', value: 'Tengah Ilir' },
    { label: 'Tebo Ulu', value: 'Tebo Ulu' },
    { label: 'Tebo Tengah', value: 'Tebo Tengah' },
    { label: 'Tebo Ilir', value: 'Tebo Ilir' },
    { label: 'Sumay', value: 'Sumay' },
    { label: 'Serai Serumpun', value: 'Serai Serumpun' },
    { label: 'Rimbo Ulu', value: 'Rimbo Ulu' },
    { label: 'Rimbo Ilir', value: 'Rimbo Ilir' },
    { label: 'Rimbo Bujang', value: 'Rimbo Bujang' },
    { label: 'Muara Tabir', value: 'Muara Tabir' }
  ],
  'Tanjung Jabung Barat': [
    { label: 'Tungkal Ulu', value: 'Tungkal Ulu' },
    { label: 'Tungkal Ilir', value: 'Tungkal Ilir' },
    { label: 'Tebing Tinggi', value: 'Tebing Tinggi' },
    { label: 'Senyerang', value: 'Senyerang' },
    { label: 'Seberang Kota', value: 'Seberang Kota' },
    { label: 'Renah Mendaluh', value: 'Renah Mendaluh' },
    { label: 'Pengabuan', value: 'Pengabuan' },
    { label: 'Muara Papalik', value: 'Muara Papalik' },
    { label: 'Merlung', value: 'Merlung' },
    { label: 'Kuala Betara', value: 'Kuala Betara' },
    { label: 'Bram Itam', value: 'Bram Itam' },
    { label: 'Betara', value: 'Betara' },
    { label: 'Batang Asam', value: 'Batang Asam' }
  ],
  Merangin: [
    { label: 'Tiang Pumpung', value: 'Tiang Pumpung' },
    { label: 'Tabir Ulu', value: 'Tabir Ulu' },
    { label: 'Tabir Timur', value: 'Tabir Timur' },
    { label: 'Tabir Selatan', value: 'Tabir Selatan' },
    { label: 'Tabir Lintas', value: 'Tabir Lintas' },
    { label: 'Tabir Ilir', value: 'Tabir Ilir' },
    { label: 'Tabir Barat', value: 'Tabir Barat' },
    { label: 'Tabir', value: 'Tabir' },
    { label: 'Sungai Tenang', value: 'Sungai Tenang' },
    { label: 'Sungai Manau', value: 'Sungai Manau' },
    { label: 'Renah Pemenang', value: 'Renah Pemenang' },
    { label: 'Renah Pembarap', value: 'Renah Pembarap' },
    { label: 'Pangkalan Jambu', value: 'Pangkalan Jambu' },
    { label: 'Pamenang Selatan', value: 'Pamenang Selatan' },
    { label: 'Pamenang Barat', value: 'Pamenang Barat' },
    { label: 'Pamenang', value: 'Pamenang' },
    { label: 'Nalo Tantan', value: 'Nalo Tantan' },
    { label: 'Muara Siau', value: 'Muara Siau' },
    { label: 'Margo Tabir', value: 'Margo Tabir' },
    { label: 'Lembah Masurai', value: 'Lembah Masurai' },
    { label: 'Jangkat', value: 'Jangkat' },
    { label: 'Batang Masumai', value: 'Batang Masumai' },
    { label: 'Bangko Barat', value: 'Bangko Barat' },
    { label: 'Bangko', value: 'Bangko' }
  ],
  Jambi: [
    { label: 'Telanaipura', value: 'Telanaipura' },
    { label: 'Pelayangan', value: 'Pelayangan' },
    { label: 'Pasar Jambi', value: 'Pasar Jambi' },
    { label: 'Kota Baru', value: 'Kota Baru' },
    { label: 'Jelutung', value: 'Jelutung' },
    { label: 'Jambi Timur', value: 'Jambi Timur' },
    { label: 'Jambi Selatan', value: 'Jambi Selatan' },
    { label: 'Danau Teluk', value: 'Danau Teluk' }
  ],
  Bungo: [
    { label: 'Tanah Tumbuh', value: 'Tanah Tumbuh' },
    { label: 'Tanah Sepenggal Lintas', value: 'Tanah Sepenggal Lintas' },
    { label: 'Tanah Sepenggal', value: 'Tanah Sepenggal' },
    { label: 'Rimbo Tengah', value: 'Rimbo Tengah' },
    { label: 'Rantau Pandan', value: 'Rantau Pandan' },
    { label: 'Pelepat Ilir', value: 'Pelepat Ilir' },
    { label: 'Pelepat', value: 'Pelepat' },
    { label: 'Pasar Muara Bungo', value: 'Pasar Muara Bungo' },
    { label: 'Muko-Muko Batin VII', value: 'Muko-Muko Batin VII' },
    { label: 'Limbur Lubuk Mengkuang', value: 'Limbur Lubuk Mengkuang' },
    { label: 'Jujuhan Ilir', value: 'Jujuhan Ilir' },
    { label: 'Jujuhan', value: 'Jujuhan' },
    { label: 'Bungo Dani', value: 'Bungo Dani' },
    { label: 'Bathin III Ulu', value: 'Bathin III Ulu' },
    { label: 'Bathin III', value: 'Bathin III' },
    { label: 'Bathin II Pelayang', value: 'Bathin II Pelayang' },
    { label: 'Bathin II Babeko', value: 'Bathin II Babeko' }
  ],
  Sungaipenuh: [
    { label: 'Tanah Kampung', value: 'Tanah Kampung' },
    { label: 'Sungai Penuh', value: 'Sungai Penuh' },
    { label: 'Sungai Bungkal', value: 'Sungai Bungkal' },
    { label: 'Pondok Tinggi', value: 'Pondok Tinggi' },
    { label: 'Pesisir Bukit', value: 'Pesisir Bukit' },
    { label: 'Kumun Debai', value: 'Kumun Debai' },
    { label: 'Koto Baru', value: 'Koto Baru' },
    { label: 'Hamparan Rawang', value: 'Hamparan Rawang' }
  ],
  'Muaro Jambi': [
    { label: 'Taman Rajo', value: 'Taman Rajo' },
    { label: 'Sungai Gelam', value: 'Sungai Gelam' },
    { label: 'Sungai Bahar', value: 'Sungai Bahar' },
    { label: 'Sekernan', value: 'Sekernan' },
    { label: 'Mestong', value: 'Mestong' },
    { label: 'Maro Sebo', value: 'Maro Sebo' },
    { label: 'Kumpeh Ulu', value: 'Kumpeh Ulu' },
    { label: 'Kumpeh', value: 'Kumpeh' },
    { label: 'Jambi Luar Kota', value: 'Jambi Luar Kota' },
    { label: 'Bahar Utara', value: 'Bahar Utara' },
    { label: 'Bahar Selatan', value: 'Bahar Selatan' }
  ],
  Kerinci: [
    { label: 'Siulak Mukai', value: 'Siulak Mukai' },
    { label: 'Siulak', value: 'Siulak' },
    { label: 'Sitinjau Laut', value: 'Sitinjau Laut' },
    { label: 'Keliling Danau', value: 'Keliling Danau' },
    { label: 'Kayu Aro Barat', value: 'Kayu Aro Barat' },
    { label: 'Kayu Aro', value: 'Kayu Aro' },
    { label: 'Gunung Tujuh', value: 'Gunung Tujuh' },
    { label: 'Gunung Raya', value: 'Gunung Raya' },
    { label: 'Gunung Kerinci', value: 'Gunung Kerinci' },
    { label: 'Depati Tujuh', value: 'Depati Tujuh' },
    { label: 'Danau Kerinci', value: 'Danau Kerinci' },
    { label: 'Bukitkerman', value: 'Bukitkerman' },
    { label: 'Batang Merangin', value: 'Batang Merangin' },
    { label: 'Air Hangat Timur', value: 'Air Hangat Timur' },
    { label: 'Air Hangat Barat', value: 'Air Hangat Barat' },
    { label: 'Air Hangat', value: 'Air Hangat' }
  ],
  Sarolangun: [
    { label: 'Singkut', value: 'Singkut' },
    { label: 'Sarolangun', value: 'Sarolangun' },
    { label: 'Pelawan', value: 'Pelawan' },
    { label: 'Pauh', value: 'Pauh' },
    { label: 'Mandiangin', value: 'Mandiangin' },
    { label: 'Limun', value: 'Limun' },
    { label: 'Cermin Nan Gadang', value: 'Cermin Nan Gadang' },
    {
      label: 'Bathin VIII (Batin VIII)',
      value: 'Bathin VIII (Batin VIII)'
    },
    { label: 'Batang Asai', value: 'Batang Asai' },
    { label: 'Air Hitam', value: 'Air Hitam' }
  ],
  'Tanjung Jabung Timur': [
    { label: 'Sadu', value: 'Sadu' },
    { label: 'Rantau Rasau', value: 'Rantau Rasau' },
    { label: 'Nipah Panjang', value: 'Nipah Panjang' },
    { label: 'Muara Sabak Timur', value: 'Muara Sabak Timur' },
    { label: 'Muara Sabak Barat', value: 'Muara Sabak Barat' },
    { label: 'Mendahara Ulu', value: 'Mendahara Ulu' },
    { label: 'Mendahara', value: 'Mendahara' },
    { label: 'Kuala Jambi', value: 'Kuala Jambi' },
    { label: 'Geragai', value: 'Geragai' },
    { label: 'Dendang', value: 'Dendang' },
    { label: 'Berbak', value: 'Berbak' }
  ],
  'Batang Hari': [
    { label: 'Pemayung', value: 'Pemayung' },
    { label: 'Muara Tembesi', value: 'Muara Tembesi' },
    { label: 'Muara Bulian', value: 'Muara Bulian' },
    { label: 'Mersam', value: 'Mersam' },
    { label: 'Maro Sebo Ulu', value: 'Maro Sebo Ulu' },
    { label: 'Maro Sebo Ilir', value: 'Maro Sebo Ilir' },
    { label: 'Batin XXIV', value: 'Batin XXIV' },
    { label: 'Bajubang', value: 'Bajubang' }
  ],
  Indramayu: [
    { label: 'Widasari', value: 'Widasari' },
    { label: 'Tukdana', value: 'Tukdana' },
    { label: 'Trisi/Terisi', value: 'Trisi/Terisi' },
    { label: 'Sukra', value: 'Sukra' },
    { label: 'Sukagumiwang', value: 'Sukagumiwang' },
    { label: 'Sliyeg', value: 'Sliyeg' },
    { label: 'Sindang', value: 'Sindang' },
    { label: 'Patrol', value: 'Patrol' },
    { label: 'Pasekan', value: 'Pasekan' },
    { label: 'Losarang', value: 'Losarang' },
    { label: 'Lohbener', value: 'Lohbener' },
    { label: 'Lelea', value: 'Lelea' },
    { label: 'Kroya', value: 'Kroya' },
    { label: 'Krangkeng', value: 'Krangkeng' },
    { label: 'Kertasemaya', value: 'Kertasemaya' },
    { label: 'Kedokan Bunder', value: 'Kedokan Bunder' },
    { label: 'Karangampel', value: 'Karangampel' },
    { label: 'Kandanghaur', value: 'Kandanghaur' },
    { label: 'Juntinyuat', value: 'Juntinyuat' },
    { label: 'Jatibarang', value: 'Jatibarang' },
    { label: 'Indramayu', value: 'Indramayu' },
    { label: 'Haurgeulis', value: 'Haurgeulis' },
    { label: 'Gantar', value: 'Gantar' },
    { label: 'Gabuswetan', value: 'Gabuswetan' },
    { label: 'Cikedung', value: 'Cikedung' },
    { label: 'Cantigi', value: 'Cantigi' },
    { label: 'Bongas', value: 'Bongas' },
    { label: 'Bangodua', value: 'Bangodua' },
    { label: 'Balongan', value: 'Balongan' },
    { label: 'Arahan', value: 'Arahan' },
    { label: 'Anjatan', value: 'Anjatan' }
  ],
  Cirebon: [
    { label: 'Weru', value: 'Weru' },
    { label: 'Waled', value: 'Waled' },
    { label: 'Tengah Tani', value: 'Tengah Tani' },
    { label: 'Talun (Cirebon Selatan)', value: 'Talun (Cirebon Selatan)' },
    { label: 'Susukan Lebak', value: 'Susukan Lebak' },
    { label: 'Susukan', value: 'Susukan' },
    { label: 'Suranenggala', value: 'Suranenggala' },
    { label: 'Sumber', value: 'Sumber' },
    { label: 'Sedong', value: 'Sedong' },
    { label: 'Plumbon', value: 'Plumbon' },
    { label: 'Plered', value: 'Plered' },
    { label: 'Pekalipan', value: 'Pekalipan' },
    { label: 'Pasaleman', value: 'Pasaleman' },
    { label: 'Panguragan', value: 'Panguragan' },
    { label: 'Pangenan', value: 'Pangenan' },
    { label: 'Palimanan', value: 'Palimanan' },
    { label: 'Pabuaran', value: 'Pabuaran' },
    { label: 'Pabedilan', value: 'Pabedilan' },
    { label: 'Mundu', value: 'Mundu' },
    { label: 'Losari', value: 'Losari' },
    { label: 'Lemahwungkuk', value: 'Lemahwungkuk' },
    { label: 'Lemahabang', value: 'Lemahabang' },
    { label: 'Klangenan', value: 'Klangenan' },
    { label: 'Kesambi', value: 'Kesambi' },
    { label: 'Kejaksan', value: 'Kejaksan' },
    { label: 'Kedawung', value: 'Kedawung' },
    { label: 'Karangwareng', value: 'Karangwareng' },
    { label: 'Karangsembung', value: 'Karangsembung' },
    { label: 'Kapetakan', value: 'Kapetakan' },
    { label: 'Kaliwedi', value: 'Kaliwedi' },
    { label: 'Jamblang', value: 'Jamblang' },
    { label: 'Harjamukti', value: 'Harjamukti' },
    {
      label: 'Gunung Jati (Cirebon Utara)',
      value: 'Gunung Jati (Cirebon Utara)'
    },
    { label: 'Greged (Greget)', value: 'Greged (Greget)' },
    { label: 'Gempol', value: 'Gempol' },
    { label: 'Gegesik', value: 'Gegesik' },
    { label: 'Gebang', value: 'Gebang' },
    { label: 'Dukupuntang', value: 'Dukupuntang' },
    { label: 'Depok', value: 'Depok' },
    { label: 'Ciwaringin', value: 'Ciwaringin' },
    { label: 'Ciledug', value: 'Ciledug' },
    { label: 'Beber', value: 'Beber' },
    { label: 'Babakan', value: 'Babakan' },
    { label: 'Astanajapura', value: 'Astanajapura' },
    { label: 'Arjawinangun', value: 'Arjawinangun' }
  ],
  Cianjur: [
    { label: 'Warungkondang', value: 'Warungkondang' },
    { label: 'Tanggeung', value: 'Tanggeung' },
    { label: 'Takokak', value: 'Takokak' },
    { label: 'Sukaresmi', value: 'Sukaresmi' },
    { label: 'Sukanagara', value: 'Sukanagara' },
    { label: 'Sukaluyu', value: 'Sukaluyu' },
    { label: 'Sindangbarang', value: 'Sindangbarang' },
    { label: 'Pasirkuda', value: 'Pasirkuda' },
    { label: 'Pagelaran', value: 'Pagelaran' },
    { label: 'Pacet', value: 'Pacet' },
    { label: 'Naringgul', value: 'Naringgul' },
    { label: 'Mande', value: 'Mande' },
    { label: 'Leles', value: 'Leles' },
    { label: 'Karangtengah', value: 'Karangtengah' },
    { label: 'Kadupandak', value: 'Kadupandak' },
    { label: 'Haurwangi', value: 'Haurwangi' },
    { label: 'Gekbrong', value: 'Gekbrong' },
    { label: 'Cugenang', value: 'Cugenang' },
    { label: 'Ciranjang', value: 'Ciranjang' },
    { label: 'Cipanas', value: 'Cipanas' },
    { label: 'Cilaku', value: 'Cilaku' },
    { label: 'Cikalongkulon', value: 'Cikalongkulon' },
    { label: 'Cikadu', value: 'Cikadu' },
    { label: 'Cijati', value: 'Cijati' },
    { label: 'Cidaun', value: 'Cidaun' },
    { label: 'Cibinong', value: 'Cibinong' },
    { label: 'Cibeber', value: 'Cibeber' },
    { label: 'Cianjur', value: 'Cianjur' },
    { label: 'Campaka Mulya', value: 'Campaka Mulya' },
    { label: 'Campaka', value: 'Campaka' },
    { label: 'Bojongpicung', value: 'Bojongpicung' },
    { label: 'Agrabinta', value: 'Agrabinta' }
  ],
  Sukabumi: [
    { label: 'Warungkiara', value: 'Warungkiara' },
    { label: 'Warudoyong', value: 'Warudoyong' },
    { label: 'Waluran', value: 'Waluran' },
    { label: 'Tegalbuleud', value: 'Tegalbuleud' },
    { label: 'Surade', value: 'Surade' },
    { label: 'Sukaraja', value: 'Sukaraja' },
    { label: 'Sukalarang', value: 'Sukalarang' },
    { label: 'Sukabumi', value: 'Sukabumi' },
    { label: 'Simpenan', value: 'Simpenan' },
    { label: 'Sagaranten', value: 'Sagaranten' },
    { label: 'Purabaya', value: 'Purabaya' },
    { label: 'Pelabuhanratu', value: 'Pelabuhanratu' },
    { label: 'Parung Kuda', value: 'Parung Kuda' },
    { label: 'Parakan Salak', value: 'Parakan Salak' },
    { label: 'Pabuaran', value: 'Pabuaran' },
    { label: 'Nyalindung', value: 'Nyalindung' },
    { label: 'Nagrak', value: 'Nagrak' },
    { label: 'Lengkong', value: 'Lengkong' },
    { label: 'Lembursitu', value: 'Lembursitu' },
    { label: 'Kebonpedes', value: 'Kebonpedes' },
    { label: 'Kalibunder', value: 'Kalibunder' },
    { label: 'Kalapa Nunggal', value: 'Kalapa Nunggal' },
    { label: 'Kadudampit', value: 'Kadudampit' },
    { label: 'Kabandungan', value: 'Kabandungan' },
    { label: 'Jampang Tengah', value: 'Jampang Tengah' },
    { label: 'Jampang Kulon', value: 'Jampang Kulon' },
    { label: 'Gunungguruh', value: 'Gunungguruh' },
    { label: 'Gunung Puyuh', value: 'Gunung Puyuh' },
    { label: 'Geger Bitung', value: 'Geger Bitung' },
    { label: 'Curugkembar', value: 'Curugkembar' },
    { label: 'Citamiang', value: 'Citamiang' },
    { label: 'Cisolok', value: 'Cisolok' },
    { label: 'Cisaat', value: 'Cisaat' },
    { label: 'Cireunghas', value: 'Cireunghas' },
    { label: 'Ciracap', value: 'Ciracap' },
    { label: 'Cimanggu', value: 'Cimanggu' },
    { label: 'Cikole', value: 'Cikole' },
    { label: 'Cikidang', value: 'Cikidang' },
    { label: 'Cikembar', value: 'Cikembar' },
    { label: 'Cikakak', value: 'Cikakak' },
    { label: 'Ciemas', value: 'Ciemas' },
    { label: 'Cidolog', value: 'Cidolog' },
    { label: 'Cidahu', value: 'Cidahu' },
    { label: 'Cidadap', value: 'Cidadap' },
    { label: 'Cicurug', value: 'Cicurug' },
    { label: 'Cicantayan', value: 'Cicantayan' },
    { label: 'Cibitung', value: 'Cibitung' },
    { label: 'Cibeureum', value: 'Cibeureum' },
    { label: 'Cibadak', value: 'Cibadak' },
    { label: 'Ciambar', value: 'Ciambar' },
    { label: 'Caringin', value: 'Caringin' },
    { label: 'Bojong Genteng', value: 'Bojong Genteng' },
    { label: 'Baros', value: 'Baros' },
    { label: 'Bantargadung', value: 'Bantargadung' }
  ],
  Purwakarta: [
    { label: 'Wanayasa', value: 'Wanayasa' },
    { label: 'Tegal Waru', value: 'Tegal Waru' },
    { label: 'Sukatani', value: 'Sukatani' },
    { label: 'Sukasari', value: 'Sukasari' },
    { label: 'Purwakarta', value: 'Purwakarta' },
    { label: 'Pondoksalam', value: 'Pondoksalam' },
    { label: 'Plered', value: 'Plered' },
    { label: 'Pasawahan', value: 'Pasawahan' },
    { label: 'Maniis', value: 'Maniis' },
    { label: 'Kiarapedes', value: 'Kiarapedes' },
    { label: 'Jatiluhur', value: 'Jatiluhur' },
    { label: 'Darangdan', value: 'Darangdan' },
    { label: 'Cibatu', value: 'Cibatu' },
    { label: 'Campaka', value: 'Campaka' },
    { label: 'Bungursari', value: 'Bungursari' },
    { label: 'Bojong', value: 'Bojong' },
    { label: 'Babakancikao', value: 'Babakancikao' }
  ],
  Garut: [
    { label: 'Wanaraja', value: 'Wanaraja' },
    { label: 'Tarogong Kidul', value: 'Tarogong Kidul' },
    { label: 'Tarogong Kaler', value: 'Tarogong Kaler' },
    { label: 'Talegong', value: 'Talegong' },
    { label: 'Sukawening', value: 'Sukawening' },
    { label: 'Sukaresmi', value: 'Sukaresmi' },
    { label: 'Sucinaraja', value: 'Sucinaraja' },
    { label: 'Singajaya', value: 'Singajaya' },
    { label: 'Selaawi', value: 'Selaawi' },
    { label: 'Samarang', value: 'Samarang' },
    { label: 'Peundeuy', value: 'Peundeuy' },
    { label: 'Pasirwangi', value: 'Pasirwangi' },
    { label: 'Pangatikan', value: 'Pangatikan' },
    { label: 'Pamulihan', value: 'Pamulihan' },
    { label: 'Pameungpeuk', value: 'Pameungpeuk' },
    { label: 'Pakenjeng', value: 'Pakenjeng' },
    { label: 'Mekarmukti', value: 'Mekarmukti' },
    { label: 'Malangbong', value: 'Malangbong' },
    { label: 'Leuwigoong', value: 'Leuwigoong' },
    { label: 'Leles', value: 'Leles' },
    { label: 'Kersamanah', value: 'Kersamanah' },
    { label: 'Karangtengah', value: 'Karangtengah' },
    { label: 'Karangpawitan', value: 'Karangpawitan' },
    { label: 'Kadungora', value: 'Kadungora' },
    { label: 'Garut Kota', value: 'Garut Kota' },
    { label: 'Cisurupan', value: 'Cisurupan' },
    { label: 'Cisompet', value: 'Cisompet' },
    { label: 'Cisewu', value: 'Cisewu' },
    { label: 'Cilawu', value: 'Cilawu' },
    { label: 'Cikelet', value: 'Cikelet' },
    { label: 'Cikajang', value: 'Cikajang' },
    { label: 'Cihurip', value: 'Cihurip' },
    { label: 'Cigedug', value: 'Cigedug' },
    { label: 'Cibiuk', value: 'Cibiuk' },
    { label: 'Cibatu', value: 'Cibatu' },
    { label: 'Cibalong', value: 'Cibalong' },
    { label: 'Caringin', value: 'Caringin' },
    { label: 'Bungbulang', value: 'Bungbulang' },
    { label: 'Blubur Limbangan', value: 'Blubur Limbangan' },
    { label: 'Bayongbong', value: 'Bayongbong' },
    { label: 'Banyuresmi', value: 'Banyuresmi' },
    { label: 'Banjarwangi', value: 'Banjarwangi' }
  ],
  Sumedang: [
    { label: 'Wado', value: 'Wado' },
    { label: 'Ujungjaya', value: 'Ujungjaya' },
    { label: 'Tomo', value: 'Tomo' },
    { label: 'Tanjungsari', value: 'Tanjungsari' },
    { label: 'Tanjungmedar', value: 'Tanjungmedar' },
    { label: 'Tanjungkerta', value: 'Tanjungkerta' },
    { label: 'Surian', value: 'Surian' },
    { label: 'Sumedang Utara', value: 'Sumedang Utara' },
    { label: 'Sumedang Selatan', value: 'Sumedang Selatan' },
    { label: 'Sukasari', value: 'Sukasari' },
    { label: 'Situraja', value: 'Situraja' },
    { label: 'Rancakalong', value: 'Rancakalong' },
    { label: 'Paseh', value: 'Paseh' },
    { label: 'Pamulihan', value: 'Pamulihan' },
    { label: 'Jatinunggal', value: 'Jatinunggal' },
    { label: 'Jatinangor', value: 'Jatinangor' },
    { label: 'Jatigede', value: 'Jatigede' },
    { label: 'Ganeas', value: 'Ganeas' },
    { label: 'Darmaraja', value: 'Darmaraja' },
    { label: 'Conggeang', value: 'Conggeang' },
    { label: 'Cisitu', value: 'Cisitu' },
    { label: 'Cisarua', value: 'Cisarua' },
    { label: 'Cimanggung', value: 'Cimanggung' },
    { label: 'Cimalaka', value: 'Cimalaka' },
    { label: 'Cibugel', value: 'Cibugel' },
    { label: 'Buahdua', value: 'Buahdua' }
  ],
  Bandung: [
    { label: 'Ujung Berung', value: 'Ujung Berung' },
    { label: 'Sumur Bandung', value: 'Sumur Bandung' },
    { label: 'Sukasari', value: 'Sukasari' },
    { label: 'Sukajadi', value: 'Sukajadi' },
    { label: 'Soreang', value: 'Soreang' },
    { label: 'Solokan Jeruk', value: 'Solokan Jeruk' },
    { label: 'Regol', value: 'Regol' },
    { label: 'Rancasari', value: 'Rancasari' },
    { label: 'Rancaekek', value: 'Rancaekek' },
    { label: 'Ranca Bali', value: 'Ranca Bali' },
    { label: 'Pasirjambu', value: 'Pasirjambu' },
    { label: 'Paseh', value: 'Paseh' },
    { label: 'Panyileukan', value: 'Panyileukan' },
    { label: 'Pangalengan', value: 'Pangalengan' },
    { label: 'Pameungpeuk', value: 'Pameungpeuk' },
    { label: 'Pacet', value: 'Pacet' },
    { label: 'Nagreg', value: 'Nagreg' },
    { label: 'Margahayu', value: 'Margahayu' },
    { label: 'Margaasih', value: 'Margaasih' },
    { label: 'Mandalajati', value: 'Mandalajati' },
    { label: 'Majalaya', value: 'Majalaya' },
    { label: 'Lengkong', value: 'Lengkong' },
    { label: 'Kutawaringin', value: 'Kutawaringin' },
    { label: 'Kiaracondong', value: 'Kiaracondong' },
    { label: 'Kertasari', value: 'Kertasari' },
    { label: 'Katapang', value: 'Katapang' },
    { label: 'Ibun', value: 'Ibun' },
    { label: 'Gedebage', value: 'Gedebage' },
    { label: 'Dayeuhkolot', value: 'Dayeuhkolot' },
    { label: 'Coblong', value: 'Coblong' },
    { label: 'Ciwidey', value: 'Ciwidey' },
    { label: 'Ciparay', value: 'Ciparay' },
    { label: 'Cinambo', value: 'Cinambo' },
    { label: 'Cimeunyan', value: 'Cimeunyan' },
    { label: 'Cimaung', value: 'Cimaung' },
    { label: 'Cileunyi', value: 'Cileunyi' },
    { label: 'Cilengkrang', value: 'Cilengkrang' },
    { label: 'Cikancung', value: 'Cikancung' },
    { label: 'Cidadap', value: 'Cidadap' },
    { label: 'Cicendo', value: 'Cicendo' },
    { label: 'Cicalengka', value: 'Cicalengka' },
    { label: 'Cibiru', value: 'Cibiru' },
    { label: 'Cibeunying Kidul', value: 'Cibeunying Kidul' },
    { label: 'Cibeunying Kaler', value: 'Cibeunying Kaler' },
    { label: 'Cangkuang', value: 'Cangkuang' },
    { label: 'Buahbatu (Margacinta)', value: 'Buahbatu (Margacinta)' },
    { label: 'Bojongsoang', value: 'Bojongsoang' },
    { label: 'Bojongloa Kidul', value: 'Bojongloa Kidul' },
    { label: 'Bojongloa Kaler', value: 'Bojongloa Kaler' },
    { label: 'Batununggal', value: 'Batununggal' },
    { label: 'Banjaran', value: 'Banjaran' },
    { label: 'Bandung Wetan', value: 'Bandung Wetan' },
    { label: 'Bandung Kulon', value: 'Bandung Kulon' },
    { label: 'Bandung Kidul', value: 'Bandung Kidul' },
    { label: 'Baleendah', value: 'Baleendah' },
    { label: 'Babakan Ciparay', value: 'Babakan Ciparay' },
    { label: 'Astana Anyar', value: 'Astana Anyar' },
    { label: 'Arjasari', value: 'Arjasari' },
    { label: 'Arcamanik', value: 'Arcamanik' },
    { label: 'Antapani (Cicadas)', value: 'Antapani (Cicadas)' },
    { label: 'Andir', value: 'Andir' }
  ],
  Karawang: [
    { label: 'Tirtamulya', value: 'Tirtamulya' },
    { label: 'Tirtajaya', value: 'Tirtajaya' },
    { label: 'Tempuran', value: 'Tempuran' },
    { label: 'Telukjambe Timur', value: 'Telukjambe Timur' },
    { label: 'Telukjambe Barat', value: 'Telukjambe Barat' },
    { label: 'Tegalwaru', value: 'Tegalwaru' },
    { label: 'Talagasari', value: 'Talagasari' },
    { label: 'Rengasdengklok', value: 'Rengasdengklok' },
    { label: 'Rawamerta', value: 'Rawamerta' },
    { label: 'Purwasari', value: 'Purwasari' },
    { label: 'Pedes', value: 'Pedes' },
    { label: 'Pangkalan', value: 'Pangkalan' },
    { label: 'Pakisjaya', value: 'Pakisjaya' },
    { label: 'Majalaya', value: 'Majalaya' },
    { label: 'Lemahabang', value: 'Lemahabang' },
    { label: 'Kutawaluya', value: 'Kutawaluya' },
    { label: 'Kotabaru', value: 'Kotabaru' },
    { label: 'Klari', value: 'Klari' },
    { label: 'Karawang Timur', value: 'Karawang Timur' },
    { label: 'Karawang Barat', value: 'Karawang Barat' },
    { label: 'Jayakerta', value: 'Jayakerta' },
    { label: 'Jatisari', value: 'Jatisari' },
    { label: 'Cilebar', value: 'Cilebar' },
    { label: 'Cilamaya Wetan', value: 'Cilamaya Wetan' },
    { label: 'Cilamaya Kulon', value: 'Cilamaya Kulon' },
    { label: 'Cikampek', value: 'Cikampek' },
    { label: 'Cibuaya', value: 'Cibuaya' },
    { label: 'Ciampel', value: 'Ciampel' },
    { label: 'Batujaya', value: 'Batujaya' },
    { label: 'Banyusari', value: 'Banyusari' }
  ],
  Bogor: [
    { label: 'Tenjolaya', value: 'Tenjolaya' },
    { label: 'Tenjo', value: 'Tenjo' },
    { label: 'Tanjungsari', value: 'Tanjungsari' },
    { label: 'Tanah Sereal', value: 'Tanah Sereal' },
    { label: 'Tamansari', value: 'Tamansari' },
    { label: 'Tajurhalang', value: 'Tajurhalang' },
    { label: 'Sukaraja', value: 'Sukaraja' },
    { label: 'Sukamakmur', value: 'Sukamakmur' },
    { label: 'Sukajaya', value: 'Sukajaya' },
    { label: 'Rumpin', value: 'Rumpin' },
    { label: 'Ranca Bungur', value: 'Ranca Bungur' },
    { label: 'Parung Panjang', value: 'Parung Panjang' },
    { label: 'Parung', value: 'Parung' },
    { label: 'Pamijahan', value: 'Pamijahan' },
    { label: 'Nanggung', value: 'Nanggung' },
    { label: 'Megamendung', value: 'Megamendung' },
    { label: 'Leuwisadeng', value: 'Leuwisadeng' },
    { label: 'Leuwiliang', value: 'Leuwiliang' },
    { label: 'Klapanunggal', value: 'Klapanunggal' },
    { label: 'Kemang', value: 'Kemang' },
    { label: 'Jonggol', value: 'Jonggol' },
    { label: 'Jasinga', value: 'Jasinga' },
    { label: 'Gunung Sindur', value: 'Gunung Sindur' },
    { label: 'Gunung Putri', value: 'Gunung Putri' },
    { label: 'Dramaga', value: 'Dramaga' },
    { label: 'Citeureup', value: 'Citeureup' },
    { label: 'Ciseeng', value: 'Ciseeng' },
    { label: 'Cisarua', value: 'Cisarua' },
    { label: 'Ciomas', value: 'Ciomas' },
    { label: 'Cileungsi', value: 'Cileungsi' },
    { label: 'Cijeruk', value: 'Cijeruk' },
    { label: 'Cigudeg', value: 'Cigudeg' },
    { label: 'Cigombong', value: 'Cigombong' },
    { label: 'Cibungbulang', value: 'Cibungbulang' },
    { label: 'Cibinong', value: 'Cibinong' },
    { label: 'Ciawi', value: 'Ciawi' },
    { label: 'Ciampea', value: 'Ciampea' },
    { label: 'Cariu', value: 'Cariu' },
    { label: 'Caringin', value: 'Caringin' },
    { label: 'Bojonggede', value: 'Bojonggede' },
    { label: 'Bogor Utara - Kota', value: 'Bogor Utara - Kota' },
    { label: 'Bogor Timur - Kota', value: 'Bogor Timur - Kota' },
    { label: 'Bogor Tengah - Kota', value: 'Bogor Tengah - Kota' },
    { label: 'Bogor Selatan - Kota', value: 'Bogor Selatan - Kota' },
    { label: 'Bogor Barat - Kota', value: 'Bogor Barat - Kota' },
    { label: 'Babakan Madang', value: 'Babakan Madang' }
  ],
  Tasikmalaya: [
    { label: 'Tawang', value: 'Tawang' },
    { label: 'Taraju', value: 'Taraju' },
    { label: 'Tanjungjaya', value: 'Tanjungjaya' },
    { label: 'Tamansari', value: 'Tamansari' },
    { label: 'Sukaresik', value: 'Sukaresik' },
    { label: 'Sukaratu', value: 'Sukaratu' },
    { label: 'Sukarame', value: 'Sukarame' },
    { label: 'Sukaraja', value: 'Sukaraja' },
    { label: 'Sukahening', value: 'Sukahening' },
    { label: 'Sodonghilir', value: 'Sodonghilir' },
    { label: 'Singaparna', value: 'Singaparna' },
    { label: 'Sariwangi', value: 'Sariwangi' },
    { label: 'Salopa', value: 'Salopa' },
    { label: 'Salawu', value: 'Salawu' },
    { label: 'Rajapolah', value: 'Rajapolah' },
    { label: 'Puspahiang', value: 'Puspahiang' },
    { label: 'Purbaratu', value: 'Purbaratu' },
    { label: 'Parungponteng', value: 'Parungponteng' },
    { label: 'Pancatengah', value: 'Pancatengah' },
    { label: 'Pagerageung', value: 'Pagerageung' },
    { label: 'Padakembang', value: 'Padakembang' },
    { label: 'Manonjaya', value: 'Manonjaya' },
    { label: 'Mangunreja', value: 'Mangunreja' },
    { label: 'Mangkubumi', value: 'Mangkubumi' },
    { label: 'Leuwisari', value: 'Leuwisari' },
    { label: 'Kawalu', value: 'Kawalu' },
    { label: 'Karangnunggal', value: 'Karangnunggal' },
    { label: 'Karang Jaya', value: 'Karang Jaya' },
    { label: 'Kadipaten', value: 'Kadipaten' },
    { label: 'Jatiwaras', value: 'Jatiwaras' },
    { label: 'Jamanis', value: 'Jamanis' },
    { label: 'Indihiang', value: 'Indihiang' },
    { label: 'Gunung Tanjung', value: 'Gunung Tanjung' },
    { label: 'Culamega', value: 'Culamega' },
    { label: 'Cisayong', value: 'Cisayong' },
    { label: 'Cipedes', value: 'Cipedes' },
    { label: 'Cipatujah', value: 'Cipatujah' },
    { label: 'Cineam', value: 'Cineam' },
    { label: 'Cikatomas', value: 'Cikatomas' },
    { label: 'Cikalong', value: 'Cikalong' },
    { label: 'Cihideung', value: 'Cihideung' },
    { label: 'Cigalontang', value: 'Cigalontang' },
    { label: 'Cibeureum', value: 'Cibeureum' },
    { label: 'Cibalong', value: 'Cibalong' },
    { label: 'Ciawi', value: 'Ciawi' },
    { label: 'Bungursari', value: 'Bungursari' },
    { label: 'Bojonggambir', value: 'Bojonggambir' },
    { label: 'Bojongasih', value: 'Bojongasih' },
    { label: 'Bantarkalong', value: 'Bantarkalong' }
  ],
  Bekasi: [
    { label: 'Tarumajaya', value: 'Tarumajaya' },
    { label: 'Tambun Utara', value: 'Tambun Utara' },
    { label: 'Tambun Selatan', value: 'Tambun Selatan' },
    { label: 'Tambelang', value: 'Tambelang' },
    { label: 'Sukawangi', value: 'Sukawangi' },
    { label: 'Sukatani', value: 'Sukatani' },
    { label: 'Sukakarya', value: 'Sukakarya' },
    { label: 'Setu', value: 'Setu' },
    { label: 'Serang Baru', value: 'Serang Baru' },
    { label: 'Rawalumbu', value: 'Rawalumbu' },
    { label: 'Pondok Melati', value: 'Pondok Melati' },
    { label: 'Pondok Gede', value: 'Pondok Gede' },
    { label: 'Pebayuran', value: 'Pebayuran' },
    { label: 'Mustika Jaya', value: 'Mustika Jaya' },
    { label: 'Muara Gembong', value: 'Muara Gembong' },
    { label: 'Medan Satria', value: 'Medan Satria' },
    { label: 'Kedung Waringin', value: 'Kedung Waringin' },
    { label: 'Karangbahagia', value: 'Karangbahagia' },
    { label: 'Jatiasih', value: 'Jatiasih' },
    { label: 'Jati Sampurna', value: 'Jati Sampurna' },
    { label: 'Cikarang Utara', value: 'Cikarang Utara' },
    { label: 'Cikarang Timur', value: 'Cikarang Timur' },
    { label: 'Cikarang Selatan', value: 'Cikarang Selatan' },
    { label: 'Cikarang Pusat', value: 'Cikarang Pusat' },
    { label: 'Cikarang Barat', value: 'Cikarang Barat' },
    { label: 'Cibitung', value: 'Cibitung' },
    { label: 'Cibarusah', value: 'Cibarusah' },
    { label: 'Cabangbungin', value: 'Cabangbungin' },
    { label: 'Bojongmangu', value: 'Bojongmangu' },
    { label: 'Bekasi Utara', value: 'Bekasi Utara' },
    { label: 'Bekasi Timur', value: 'Bekasi Timur' },
    { label: 'Bekasi Selatan', value: 'Bekasi Selatan' },
    { label: 'Bekasi Barat', value: 'Bekasi Barat' },
    { label: 'Bantar Gebang', value: 'Bantar Gebang' },
    { label: 'Babelan', value: 'Babelan' }
  ],
  Depok: [
    { label: 'Tapos', value: 'Tapos' },
    { label: 'Sukmajaya', value: 'Sukmajaya' },
    { label: 'Sawangan', value: 'Sawangan' },
    { label: 'Pancoran Mas', value: 'Pancoran Mas' },
    { label: 'Limo', value: 'Limo' },
    { label: 'Cipayung', value: 'Cipayung' },
    { label: 'Cinere', value: 'Cinere' },
    { label: 'Cimanggis', value: 'Cimanggis' },
    { label: 'Cilodong', value: 'Cilodong' },
    { label: 'Bojongsari', value: 'Bojongsari' },
    { label: 'Beji', value: 'Beji' }
  ],
  Subang: [
    { label: 'Tanjungsiang', value: 'Tanjungsiang' },
    { label: 'Tambakdahan', value: 'Tambakdahan' },
    { label: 'Sukasari', value: 'Sukasari' },
    { label: 'Subang', value: 'Subang' },
    { label: 'Serangpanjang', value: 'Serangpanjang' },
    { label: 'Sagalaherang', value: 'Sagalaherang' },
    { label: 'Pusakanagara', value: 'Pusakanagara' },
    { label: 'Pusakajaya', value: 'Pusakajaya' },
    { label: 'Purwadadi', value: 'Purwadadi' },
    { label: 'Patokbeusi', value: 'Patokbeusi' },
    { label: 'Pamanukan', value: 'Pamanukan' },
    { label: 'Pagaden Barat', value: 'Pagaden Barat' },
    { label: 'Pagaden', value: 'Pagaden' },
    { label: 'Pabuaran', value: 'Pabuaran' },
    { label: 'Legonkulon', value: 'Legonkulon' },
    { label: 'Kasomalang', value: 'Kasomalang' },
    { label: 'Kalijati', value: 'Kalijati' },
    { label: 'Jalancagak', value: 'Jalancagak' },
    { label: 'Dawuan', value: 'Dawuan' },
    { label: 'Compreng', value: 'Compreng' },
    { label: 'Cisalak', value: 'Cisalak' },
    { label: 'Cipunagara', value: 'Cipunagara' },
    { label: 'Cipeundeuy', value: 'Cipeundeuy' },
    { label: 'Cikaum', value: 'Cikaum' },
    { label: 'Cijambe', value: 'Cijambe' },
    { label: 'Cibogo', value: 'Cibogo' },
    { label: 'Ciater', value: 'Ciater' },
    { label: 'Ciasem', value: 'Ciasem' },
    { label: 'Blanakan', value: 'Blanakan' },
    { label: 'Binong', value: 'Binong' }
  ],
  Ciamis: [
    { label: 'Tambaksari', value: 'Tambaksari' },
    { label: 'Sukamantri', value: 'Sukamantri' },
    { label: 'Sukadana', value: 'Sukadana' },
    { label: 'Sindangkasih', value: 'Sindangkasih' },
    { label: 'Sadananya', value: 'Sadananya' },
    { label: 'Rancah', value: 'Rancah' },
    { label: 'Rajadesa', value: 'Rajadesa' },
    { label: 'Purwadadi', value: 'Purwadadi' },
    { label: 'Panumbangan', value: 'Panumbangan' },
    { label: 'Panjalu', value: 'Panjalu' },
    { label: 'Panawangan', value: 'Panawangan' },
    { label: 'Pamarican', value: 'Pamarican' },
    { label: 'Lumbung', value: 'Lumbung' },
    { label: 'Lakbok', value: 'Lakbok' },
    { label: 'Kawali', value: 'Kawali' },
    { label: 'Jatinagara', value: 'Jatinagara' },
    { label: 'Cisaga', value: 'Cisaga' },
    { label: 'Cipaku', value: 'Cipaku' },
    { label: 'Cimaragas', value: 'Cimaragas' },
    { label: 'Cikoneng', value: 'Cikoneng' },
    { label: 'Cijeungjing', value: 'Cijeungjing' },
    { label: 'Cihaurbeuti', value: 'Cihaurbeuti' },
    { label: 'Cidolog', value: 'Cidolog' },
    { label: 'Ciamis', value: 'Ciamis' },
    { label: 'Baregbeg', value: 'Baregbeg' },
    { label: 'Banjarsari', value: 'Banjarsari' }
  ],
  Majalengka: [
    { label: 'Talaga', value: 'Talaga' },
    { label: 'Sumberjaya', value: 'Sumberjaya' },
    { label: 'Sukahaji', value: 'Sukahaji' },
    { label: 'Sindangwangi', value: 'Sindangwangi' },
    { label: 'Sindang', value: 'Sindang' },
    { label: 'Rajagaluh', value: 'Rajagaluh' },
    { label: 'Panyingkiran', value: 'Panyingkiran' },
    { label: 'Palasah', value: 'Palasah' },
    { label: 'Malausma', value: 'Malausma' },
    { label: 'Majalengka', value: 'Majalengka' },
    { label: 'Maja', value: 'Maja' },
    { label: 'Ligung', value: 'Ligung' },
    { label: 'Leuwimunding', value: 'Leuwimunding' },
    { label: 'Lemahsugih', value: 'Lemahsugih' },
    { label: 'Kertajati', value: 'Kertajati' },
    { label: 'Kasokandel', value: 'Kasokandel' },
    { label: 'Kadipaten', value: 'Kadipaten' },
    { label: 'Jatiwangi', value: 'Jatiwangi' },
    { label: 'Jatitujuh', value: 'Jatitujuh' },
    { label: 'Dawuan', value: 'Dawuan' },
    { label: 'Cingambul', value: 'Cingambul' },
    { label: 'Cikijing', value: 'Cikijing' },
    { label: 'Cigasong', value: 'Cigasong' },
    { label: 'Bantarujeg', value: 'Bantarujeg' },
    { label: 'Banjaran', value: 'Banjaran' },
    { label: 'Argapura', value: 'Argapura' }
  ],
  Kuningan: [
    { label: 'Subang', value: 'Subang' },
    { label: 'Sindangagung', value: 'Sindangagung' },
    { label: 'Selajambe', value: 'Selajambe' },
    { label: 'Pasawahan', value: 'Pasawahan' },
    { label: 'Pancalang', value: 'Pancalang' },
    { label: 'Nusaherang', value: 'Nusaherang' },
    { label: 'Mandirancan', value: 'Mandirancan' },
    { label: 'Maleber', value: 'Maleber' },
    { label: 'Luragung', value: 'Luragung' },
    { label: 'Lebakwangi', value: 'Lebakwangi' },
    { label: 'Kuningan', value: 'Kuningan' },
    { label: 'Kramat Mulya', value: 'Kramat Mulya' },
    { label: 'Karangkancana', value: 'Karangkancana' },
    { label: 'Kalimanggis', value: 'Kalimanggis' },
    { label: 'Kadugede', value: 'Kadugede' },
    { label: 'Japara', value: 'Japara' },
    { label: 'Jalaksana', value: 'Jalaksana' },
    { label: 'Hantara', value: 'Hantara' },
    { label: 'Garawangi', value: 'Garawangi' },
    { label: 'Darma', value: 'Darma' },
    { label: 'Ciwaru', value: 'Ciwaru' },
    { label: 'Cipicung', value: 'Cipicung' },
    { label: 'Ciniru', value: 'Ciniru' },
    { label: 'Cimahi', value: 'Cimahi' },
    { label: 'Cilimus', value: 'Cilimus' },
    { label: 'Cilebak', value: 'Cilebak' },
    { label: 'Cigugur', value: 'Cigugur' },
    { label: 'Cigandamekar', value: 'Cigandamekar' },
    { label: 'Cidahu', value: 'Cidahu' },
    { label: 'Cibingbin', value: 'Cibingbin' },
    { label: 'Cibeureum', value: 'Cibeureum' },
    { label: 'Ciawigebang', value: 'Ciawigebang' }
  ],
  'Bandung Barat': [
    { label: 'Sindangkerta', value: 'Sindangkerta' },
    { label: 'Saguling', value: 'Saguling' },
    { label: 'Rongga', value: 'Rongga' },
    { label: 'Parongpong', value: 'Parongpong' },
    { label: 'Padalarang', value: 'Padalarang' },
    { label: 'Ngamprah', value: 'Ngamprah' },
    { label: 'Lembang', value: 'Lembang' },
    { label: 'Gununghalu', value: 'Gununghalu' },
    { label: 'Cisarua', value: 'Cisarua' },
    { label: 'Cipongkor', value: 'Cipongkor' },
    { label: 'Cipeundeuy', value: 'Cipeundeuy' },
    { label: 'Cipatat', value: 'Cipatat' },
    { label: 'Cililin', value: 'Cililin' },
    { label: 'Cikalong Wetan', value: 'Cikalong Wetan' },
    { label: 'Cihampelas', value: 'Cihampelas' },
    { label: 'Batujajar', value: 'Batujajar' }
  ],
  Pangandaran: [
    { label: 'Sidamulih', value: 'Sidamulih' },
    { label: 'Parigi', value: 'Parigi' },
    { label: 'Pangandaran', value: 'Pangandaran' },
    { label: 'Padaherang', value: 'Padaherang' },
    { label: 'Mangunjaya', value: 'Mangunjaya' },
    { label: 'Langkaplancar', value: 'Langkaplancar' },
    { label: 'Kalipucang', value: 'Kalipucang' },
    { label: 'Cimerak', value: 'Cimerak' },
    { label: 'Cijulang', value: 'Cijulang' },
    { label: 'Cigugur', value: 'Cigugur' }
  ],
  Banjar: [
    { label: 'Purwaharja', value: 'Purwaharja' },
    { label: 'Pataruman', value: 'Pataruman' },
    { label: 'Langensari', value: 'Langensari' },
    { label: 'Banjar', value: 'Banjar' },
    { label: 'Aluh-Aluh', value: 'Aluh-Aluh' },
    { label: 'Aranio', value: 'Aranio' },
    { label: 'Astambul', value: 'Astambul' },
    { label: 'Beruntung Baru', value: 'Beruntung Baru' },
    { label: 'Gambut', value: 'Gambut' },
    { label: 'Karang Intan', value: 'Karang Intan' },
    { label: 'Kertak Hanyar', value: 'Kertak Hanyar' },
    { label: 'Martapura Barat', value: 'Martapura Barat' },
    { label: 'Martapura Kota', value: 'Martapura Kota' },
    { label: 'Martapura Timur', value: 'Martapura Timur' },
    { label: 'Mataraman', value: 'Mataraman' },
    { label: 'Pengaron', value: 'Pengaron' },
    { label: 'Peramasan', value: 'Peramasan' },
    { label: 'Sambung Makmur', value: 'Sambung Makmur' },
    { label: 'Sei/Sungai Pinang', value: 'Sei/Sungai Pinang' },
    { label: 'Sei/Sungai Tabuk', value: 'Sei/Sungai Tabuk' },
    { label: 'Simpang Empat', value: 'Simpang Empat' },
    { label: 'Tatah Makmur', value: 'Tatah Makmur' },
    { label: 'Telaga Bauntung', value: 'Telaga Bauntung' }
  ],
  Cimahi: [
    { label: 'Cimahi Utara', value: 'Cimahi Utara' },
    { label: 'Cimahi Tengah', value: 'Cimahi Tengah' },
    { label: 'Cimahi Selatan', value: 'Cimahi Selatan' }
  ],
  Wonogiri: [
    { label: 'Wuryantoro', value: 'Wuryantoro' },
    { label: 'Wonogiri', value: 'Wonogiri' },
    { label: 'Tirtomoyo', value: 'Tirtomoyo' },
    { label: 'Slogohimo', value: 'Slogohimo' },
    { label: 'Sidoharjo', value: 'Sidoharjo' },
    { label: 'Selogiri', value: 'Selogiri' },
    { label: 'Purwantoro', value: 'Purwantoro' },
    { label: 'Puhpelem', value: 'Puhpelem' },
    { label: 'Pracimantoro', value: 'Pracimantoro' },
    { label: 'Paranggupito', value: 'Paranggupito' },
    { label: 'Nguntoronadi', value: 'Nguntoronadi' },
    { label: 'Ngadirojo', value: 'Ngadirojo' },
    { label: 'Manyaran', value: 'Manyaran' },
    { label: 'Kismantoro', value: 'Kismantoro' },
    { label: 'Karangtengah', value: 'Karangtengah' },
    { label: 'Jatisrono', value: 'Jatisrono' },
    { label: 'Jatiroto', value: 'Jatiroto' },
    { label: 'Jatipurno', value: 'Jatipurno' },
    { label: 'Giriwoyo', value: 'Giriwoyo' },
    { label: 'Giritontro', value: 'Giritontro' },
    { label: 'Girimarto', value: 'Girimarto' },
    { label: 'Eromoko', value: 'Eromoko' },
    { label: 'Bulukerto', value: 'Bulukerto' },
    { label: 'Batuwarno', value: 'Batuwarno' },
    { label: 'Baturetno', value: 'Baturetno' }
  ],
  Batang: [
    { label: 'Wonotunggal', value: 'Wonotunggal' },
    { label: 'Warungasem', value: 'Warungasem' },
    { label: 'Tulis', value: 'Tulis' },
    { label: 'Tersono', value: 'Tersono' },
    { label: 'Subah', value: 'Subah' },
    { label: 'Reban', value: 'Reban' },
    { label: 'Pecalungan', value: 'Pecalungan' },
    { label: 'Limpung', value: 'Limpung' },
    { label: 'Kandeman', value: 'Kandeman' },
    { label: 'Gringsing', value: 'Gringsing' },
    { label: 'Blado', value: 'Blado' },
    { label: 'Bawang', value: 'Bawang' },
    { label: 'Batang', value: 'Batang' },
    { label: 'Banyuputih', value: 'Banyuputih' },
    { label: 'Bandar', value: 'Bandar' }
  ],
  Wonosobo: [
    { label: 'Wonosobo', value: 'Wonosobo' },
    { label: 'Watumalang', value: 'Watumalang' },
    { label: 'Wadaslintang', value: 'Wadaslintang' },
    { label: 'Sukoharjo', value: 'Sukoharjo' },
    { label: 'Selomerto', value: 'Selomerto' },
    { label: 'Sapuran', value: 'Sapuran' },
    { label: 'Mojotengah', value: 'Mojotengah' },
    { label: 'Leksono', value: 'Leksono' },
    { label: 'Kertek', value: 'Kertek' },
    { label: 'Kepil', value: 'Kepil' },
    { label: 'Kejajar', value: 'Kejajar' },
    { label: 'Kaliwiro', value: 'Kaliwiro' },
    { label: 'Kalikajar', value: 'Kalikajar' },
    { label: 'Kalibawang', value: 'Kalibawang' },
    { label: 'Garung', value: 'Garung' }
  ],
  Boyolali: [
    { label: 'Wonosegoro', value: 'Wonosegoro' },
    { label: 'Teras', value: 'Teras' },
    { label: 'Simo', value: 'Simo' },
    { label: 'Selo', value: 'Selo' },
    { label: 'Sawit', value: 'Sawit' },
    { label: 'Sambi', value: 'Sambi' },
    { label: 'Nogosari', value: 'Nogosari' },
    { label: 'Ngemplak', value: 'Ngemplak' },
    { label: 'Musuk', value: 'Musuk' },
    { label: 'Mojosongo', value: 'Mojosongo' },
    { label: 'Klego', value: 'Klego' },
    { label: 'Kemusu', value: 'Kemusu' },
    { label: 'Karanggede', value: 'Karanggede' },
    { label: 'Juwangi', value: 'Juwangi' },
    { label: 'Cepogo', value: 'Cepogo' },
    { label: 'Boyolali', value: 'Boyolali' },
    { label: 'Banyudono', value: 'Banyudono' },
    { label: 'Andong', value: 'Andong' },
    { label: 'Ampel', value: 'Ampel' }
  ],
  Klaten: [
    { label: 'Wonosari', value: 'Wonosari' },
    { label: 'Wedi', value: 'Wedi' },
    { label: 'Tulung', value: 'Tulung' },
    { label: 'Trucuk', value: 'Trucuk' },
    { label: 'Prambanan', value: 'Prambanan' },
    { label: 'Polanharjo', value: 'Polanharjo' },
    { label: 'Pedan', value: 'Pedan' },
    { label: 'Ngawen', value: 'Ngawen' },
    { label: 'Manisrenggo', value: 'Manisrenggo' },
    { label: 'Klaten Utara', value: 'Klaten Utara' },
    { label: 'Klaten Tengah', value: 'Klaten Tengah' },
    { label: 'Klaten Selatan', value: 'Klaten Selatan' },
    { label: 'Kemalang', value: 'Kemalang' },
    { label: 'Kebonarum', value: 'Kebonarum' },
    { label: 'Karangnongko', value: 'Karangnongko' },
    { label: 'Karangdowo', value: 'Karangdowo' },
    { label: 'Karanganom', value: 'Karanganom' },
    { label: 'Kalikotes', value: 'Kalikotes' },
    { label: 'Juwiring', value: 'Juwiring' },
    { label: 'Jogonalan', value: 'Jogonalan' },
    { label: 'Jatinom', value: 'Jatinom' },
    { label: 'Gantiwarno', value: 'Gantiwarno' },
    { label: 'Delanggu', value: 'Delanggu' },
    { label: 'Ceper', value: 'Ceper' },
    { label: 'Cawas', value: 'Cawas' },
    { label: 'Bayat', value: 'Bayat' }
  ],
  Demak: [
    { label: 'Wonosalam', value: 'Wonosalam' },
    { label: 'Wedung', value: 'Wedung' },
    { label: 'Sayung', value: 'Sayung' },
    { label: 'Mranggen', value: 'Mranggen' },
    { label: 'Mijen', value: 'Mijen' },
    { label: 'Kebonagung', value: 'Kebonagung' },
    { label: 'Karangawen', value: 'Karangawen' },
    { label: 'Karanganyar', value: 'Karanganyar' },
    { label: 'Karang Tengah', value: 'Karang Tengah' },
    { label: 'Guntur', value: 'Guntur' },
    { label: 'Gajah', value: 'Gajah' },
    { label: 'Dempet', value: 'Dempet' },
    { label: 'Demak', value: 'Demak' },
    { label: 'Bonang', value: 'Bonang' }
  ],
  Pekalongan: [
    { label: 'Wonopringgo', value: 'Wonopringgo' },
    { label: 'Wonokerto', value: 'Wonokerto' },
    { label: 'Wiradesa', value: 'Wiradesa' },
    { label: 'Tirto', value: 'Tirto' },
    { label: 'Talun', value: 'Talun' },
    { label: 'Sragi', value: 'Sragi' },
    { label: 'Siwalan', value: 'Siwalan' },
    {
      label: 'Petungkriono/Petungkriyono',
      value: 'Petungkriono/Petungkriyono'
    },
    { label: 'Pekalongan Utara', value: 'Pekalongan Utara' },
    { label: 'Pekalongan Timur', value: 'Pekalongan Timur' },
    { label: 'Pekalongan Selatan', value: 'Pekalongan Selatan' },
    { label: 'Pekalongan Barat', value: 'Pekalongan Barat' },
    { label: 'Paninggaran', value: 'Paninggaran' },
    { label: 'Lebakbarang', value: 'Lebakbarang' },
    { label: 'Kesesi', value: 'Kesesi' },
    { label: 'Kedungwuni', value: 'Kedungwuni' },
    { label: 'Karangdadap', value: 'Karangdadap' },
    { label: 'Karanganyar', value: 'Karanganyar' },
    { label: 'Kandangserang', value: 'Kandangserang' },
    { label: 'Kajen', value: 'Kajen' },
    { label: 'Doro', value: 'Doro' },
    { label: 'Buaran', value: 'Buaran' },
    { label: 'Bojong', value: 'Bojong' }
  ],
  Temanggung: [
    { label: 'Wonoboyo', value: 'Wonoboyo' },
    { label: 'Tretep', value: 'Tretep' },
    { label: 'Tlogomulyo', value: 'Tlogomulyo' },
    { label: 'Tembarak', value: 'Tembarak' },
    { label: 'Temanggung', value: 'Temanggung' },
    { label: 'Selopampang', value: 'Selopampang' },
    { label: 'Pringsurat', value: 'Pringsurat' },
    { label: 'Parakan', value: 'Parakan' },
    { label: 'Ngadirejo', value: 'Ngadirejo' },
    { label: 'Kranggan', value: 'Kranggan' },
    { label: 'Kledung', value: 'Kledung' },
    { label: 'Kedu', value: 'Kedu' },
    { label: 'Kandangan', value: 'Kandangan' },
    { label: 'Kaloran', value: 'Kaloran' },
    { label: 'Jumo', value: 'Jumo' },
    { label: 'Gemawang', value: 'Gemawang' },
    { label: 'Candiroto', value: 'Candiroto' },
    { label: 'Bulu', value: 'Bulu' },
    { label: 'Bejen', value: 'Bejen' },
    { label: 'Bansari', value: 'Bansari' }
  ],
  Grobogan: [
    { label: 'Wirosari', value: 'Wirosari' },
    { label: 'Toroh', value: 'Toroh' },
    { label: 'Tegowanu', value: 'Tegowanu' },
    { label: 'Tawangharjo', value: 'Tawangharjo' },
    { label: 'Tanggungharjo', value: 'Tanggungharjo' },
    { label: 'Purwodadi', value: 'Purwodadi' },
    { label: 'Pulokulon', value: 'Pulokulon' },
    { label: 'Penawangan', value: 'Penawangan' },
    { label: 'Ngaringan', value: 'Ngaringan' },
    { label: 'Kradenan', value: 'Kradenan' },
    { label: 'Klambu', value: 'Klambu' },
    { label: 'Kedungjati', value: 'Kedungjati' },
    { label: 'Karangrayung', value: 'Karangrayung' },
    { label: 'Gubug', value: 'Gubug' },
    { label: 'Grobogan', value: 'Grobogan' },
    { label: 'Godong', value: 'Godong' },
    { label: 'Geyer', value: 'Geyer' },
    { label: 'Gabus', value: 'Gabus' },
    { label: 'Brati', value: 'Brati' }
  ],
  Pati: [
    { label: 'Winong', value: 'Winong' },
    { label: 'Wedarijaksa', value: 'Wedarijaksa' },
    { label: 'Trangkil', value: 'Trangkil' },
    { label: 'Tlogowungu', value: 'Tlogowungu' },
    { label: 'Tayu', value: 'Tayu' },
    { label: 'Tambakromo', value: 'Tambakromo' },
    { label: 'Sukolilo', value: 'Sukolilo' },
    { label: 'Pucakwangi', value: 'Pucakwangi' },
    { label: 'Pati', value: 'Pati' },
    { label: 'Margoyoso', value: 'Margoyoso' },
    { label: 'Margorejo', value: 'Margorejo' },
    { label: 'Kayen', value: 'Kayen' },
    { label: 'Juwana', value: 'Juwana' },
    { label: 'Jakenan', value: 'Jakenan' },
    { label: 'Jaken', value: 'Jaken' },
    { label: 'Gunungwungkal', value: 'Gunungwungkal' },
    { label: 'Gembong', value: 'Gembong' },
    { label: 'Gabus', value: 'Gabus' },
    { label: 'Dukuhseti', value: 'Dukuhseti' },
    { label: 'Cluwak', value: 'Cluwak' },
    { label: 'Batangan', value: 'Batangan' }
  ],
  Magelang: [
    { label: 'Windusari', value: 'Windusari' },
    { label: 'Tempuran', value: 'Tempuran' },
    { label: 'Tegalrejo', value: 'Tegalrejo' },
    { label: 'Srumbung', value: 'Srumbung' },
    { label: 'Secang', value: 'Secang' },
    { label: 'Sawangan', value: 'Sawangan' },
    { label: 'Salaman', value: 'Salaman' },
    { label: 'Salam', value: 'Salam' },
    { label: 'Pakis', value: 'Pakis' },
    { label: 'Ngluwar', value: 'Ngluwar' },
    { label: 'Ngablak', value: 'Ngablak' },
    { label: 'Muntilan', value: 'Muntilan' },
    { label: 'Mungkid', value: 'Mungkid' },
    { label: 'Mertoyudan', value: 'Mertoyudan' },
    { label: 'Magelang Utara', value: 'Magelang Utara' },
    { label: 'Magelang Tengah', value: 'Magelang Tengah' },
    { label: 'Magelang Selatan', value: 'Magelang Selatan' },
    { label: 'Kaliangkrik', value: 'Kaliangkrik' },
    { label: 'Kajoran', value: 'Kajoran' },
    { label: 'Grabag', value: 'Grabag' },
    { label: 'Dukun', value: 'Dukun' },
    { label: 'Candimulyo', value: 'Candimulyo' },
    { label: 'Borobudur', value: 'Borobudur' },
    { label: 'Bandongan', value: 'Bandongan' }
  ],
  Sukoharjo: [
    { label: 'Weru', value: 'Weru' },
    { label: 'Tawangsari', value: 'Tawangsari' },
    { label: 'Sukoharjo', value: 'Sukoharjo' },
    { label: 'Polokarto', value: 'Polokarto' },
    { label: 'Nguter', value: 'Nguter' },
    { label: 'Mojolaban', value: 'Mojolaban' },
    { label: 'Kartasura', value: 'Kartasura' },
    { label: 'Grogol', value: 'Grogol' },
    { label: 'Gatak', value: 'Gatak' },
    { label: 'Bulu', value: 'Bulu' },
    { label: 'Bendosari', value: 'Bendosari' },
    { label: 'Baki', value: 'Baki' }
  ],
  Kendal: [
    { label: 'Weleri', value: 'Weleri' },
    { label: 'Sukorejo', value: 'Sukorejo' },
    { label: 'Singorojo', value: 'Singorojo' },
    { label: 'Rowosari', value: 'Rowosari' },
    { label: 'Ringinarum', value: 'Ringinarum' },
    { label: 'Plantungan', value: 'Plantungan' },
    { label: 'Pegandon', value: 'Pegandon' },
    { label: 'Patebon', value: 'Patebon' },
    { label: 'Patean', value: 'Patean' },
    { label: 'Pagerruyung', value: 'Pagerruyung' },
    { label: 'Ngampel', value: 'Ngampel' },
    { label: 'Limbangan', value: 'Limbangan' },
    { label: 'Kendal', value: 'Kendal' },
    { label: 'Kangkung', value: 'Kangkung' },
    { label: 'Kaliwungu Selatan', value: 'Kaliwungu Selatan' },
    { label: 'Kaliwungu', value: 'Kaliwungu' },
    { label: 'Gemuh', value: 'Gemuh' },
    { label: 'Cepiring', value: 'Cepiring' },
    { label: 'Brangsong', value: 'Brangsong' },
    { label: 'Boja', value: 'Boja' }
  ],
  Jepara: [
    { label: 'Welahan', value: 'Welahan' },
    { label: 'Tahunan', value: 'Tahunan' },
    { label: 'Pecangaan', value: 'Pecangaan' },
    { label: 'Pakis Aji', value: 'Pakis Aji' },
    { label: 'Nalumsari', value: 'Nalumsari' },
    { label: 'Mlonggo', value: 'Mlonggo' },
    { label: 'Mayong', value: 'Mayong' },
    { label: 'Kembang', value: 'Kembang' },
    { label: 'Keling', value: 'Keling' },
    { label: 'Kedung', value: 'Kedung' },
    { label: 'Karimunjawa', value: 'Karimunjawa' },
    { label: 'Kalinyamatan', value: 'Kalinyamatan' },
    { label: 'Jepara', value: 'Jepara' },
    { label: 'Donorojo', value: 'Donorojo' },
    { label: 'Batealit', value: 'Batealit' },
    { label: 'Bangsri', value: 'Bangsri' }
  ],
  Pemalang: [
    { label: 'Watukumpul', value: 'Watukumpul' },
    { label: 'Warungpring', value: 'Warungpring' },
    { label: 'Ulujami', value: 'Ulujami' },
    { label: 'Taman', value: 'Taman' },
    { label: 'Randudongkal', value: 'Randudongkal' },
    { label: 'Pulosari', value: 'Pulosari' },
    { label: 'Petarukan', value: 'Petarukan' },
    { label: 'Pemalang', value: 'Pemalang' },
    { label: 'Moga', value: 'Moga' },
    { label: 'Comal', value: 'Comal' },
    { label: 'Bodeh', value: 'Bodeh' },
    { label: 'Belik', value: 'Belik' },
    { label: 'Bantarbolang', value: 'Bantarbolang' },
    { label: 'Ampelgading', value: 'Ampelgading' }
  ],
  Tegal: [
    { label: 'Warurejo', value: 'Warurejo' },
    { label: 'Tegal Timur', value: 'Tegal Timur' },
    { label: 'Tegal Selatan', value: 'Tegal Selatan' },
    { label: 'Tegal Barat', value: 'Tegal Barat' },
    { label: 'Tarub', value: 'Tarub' },
    { label: 'Talang', value: 'Talang' },
    { label: 'Surodadi', value: 'Surodadi' },
    { label: 'Slawi', value: 'Slawi' },
    { label: 'Pangkah', value: 'Pangkah' },
    { label: 'Pagerbarang', value: 'Pagerbarang' },
    { label: 'Margasari', value: 'Margasari' },
    { label: 'Margadana', value: 'Margadana' },
    { label: 'Lebaksiu', value: 'Lebaksiu' },
    { label: 'Kramat', value: 'Kramat' },
    { label: 'Kedung Banteng', value: 'Kedung Banteng' },
    { label: 'Jatinegara', value: 'Jatinegara' },
    { label: 'Dukuhwaru', value: 'Dukuhwaru' },
    { label: 'Dukuhturi', value: 'Dukuhturi' },
    { label: 'Bumijawa', value: 'Bumijawa' },
    { label: 'Bojong', value: 'Bojong' },
    { label: 'Balapulang', value: 'Balapulang' },
    { label: 'Adiwerna', value: 'Adiwerna' }
  ],
  Banyumas: [
    { label: 'Wangon', value: 'Wangon' },
    { label: 'Tambak', value: 'Tambak' },
    { label: 'Sumpiuh', value: 'Sumpiuh' },
    { label: 'Sumbang', value: 'Sumbang' },
    { label: 'Somagede', value: 'Somagede' },
    { label: 'Sokaraja', value: 'Sokaraja' },
    { label: 'Rawalo', value: 'Rawalo' },
    { label: 'Purwokerto Utara', value: 'Purwokerto Utara' },
    { label: 'Purwokerto Timur', value: 'Purwokerto Timur' },
    { label: 'Purwokerto Selatan', value: 'Purwokerto Selatan' },
    { label: 'Purwokerto Barat', value: 'Purwokerto Barat' },
    { label: 'Purwojati', value: 'Purwojati' },
    { label: 'Pekuncen', value: 'Pekuncen' },
    { label: 'Patikraja', value: 'Patikraja' },
    { label: 'Lumbir', value: 'Lumbir' },
    { label: 'Kemranjen', value: 'Kemranjen' },
    { label: 'Kembaran', value: 'Kembaran' },
    { label: 'Kedung Banteng', value: 'Kedung Banteng' },
    { label: 'Kebasen', value: 'Kebasen' },
    { label: 'Karanglewas', value: 'Karanglewas' },
    { label: 'Kalibagor', value: 'Kalibagor' },
    { label: 'Jatilawang', value: 'Jatilawang' },
    { label: 'Gumelar', value: 'Gumelar' },
    { label: 'Cilongok', value: 'Cilongok' },
    { label: 'Baturaden', value: 'Baturaden' },
    { label: 'Banyumas', value: 'Banyumas' },
    { label: 'Ajibarang', value: 'Ajibarang' }
  ],
  Banjarnegara: [
    { label: 'Wanayasa', value: 'Wanayasa' },
    { label: 'Wanadadi (Wonodadi)', value: 'Wanadadi (Wonodadi)' },
    { label: 'Susukan', value: 'Susukan' },
    { label: 'Sigaluh', value: 'Sigaluh' },
    { label: 'Rakit', value: 'Rakit' },
    { label: 'Purworejo Klampok', value: 'Purworejo Klampok' },
    { label: 'Purwonegoro', value: 'Purwonegoro' },
    { label: 'Punggelan', value: 'Punggelan' },
    { label: 'Pejawaran', value: 'Pejawaran' },
    { label: 'Pandanarum', value: 'Pandanarum' },
    { label: 'Pagentan', value: 'Pagentan' },
    { label: 'Pagedongan', value: 'Pagedongan' },
    { label: 'Mandiraja', value: 'Mandiraja' },
    { label: 'Madukara', value: 'Madukara' },
    { label: 'Karangkobar', value: 'Karangkobar' },
    { label: 'Kalibening', value: 'Kalibening' },
    { label: 'Bawang', value: 'Bawang' },
    { label: 'Batur', value: 'Batur' },
    { label: 'Banjarnegara', value: 'Banjarnegara' },
    { label: 'Banjarmangu', value: 'Banjarmangu' }
  ],
  Brebes: [
    { label: 'Wanasari', value: 'Wanasari' },
    { label: 'Tonjong', value: 'Tonjong' },
    { label: 'Tanjung', value: 'Tanjung' },
    { label: 'Songgom', value: 'Songgom' },
    { label: 'Sirampog', value: 'Sirampog' },
    { label: 'Salem', value: 'Salem' },
    { label: 'Paguyangan', value: 'Paguyangan' },
    { label: 'Losari', value: 'Losari' },
    { label: 'Larangan', value: 'Larangan' },
    { label: 'Ketanggungan', value: 'Ketanggungan' },
    { label: 'Kersana', value: 'Kersana' },
    { label: 'Jatibarang', value: 'Jatibarang' },
    { label: 'Bumiayu', value: 'Bumiayu' },
    { label: 'Bulakamba', value: 'Bulakamba' },
    { label: 'Brebes', value: 'Brebes' },
    { label: 'Bantarkawung', value: 'Bantarkawung' },
    { label: 'Banjarharjo', value: 'Banjarharjo' }
  ],
  Cilacap: [
    { label: 'Wanareja', value: 'Wanareja' },
    { label: 'Sidareja', value: 'Sidareja' },
    { label: 'Sampang', value: 'Sampang' },
    { label: 'Patimuan', value: 'Patimuan' },
    { label: 'Nusawungu', value: 'Nusawungu' },
    { label: 'Maos', value: 'Maos' },
    { label: 'Majenang', value: 'Majenang' },
    { label: 'Kroya', value: 'Kroya' },
    { label: 'Kesugihan', value: 'Kesugihan' },
    { label: 'Kedungreja', value: 'Kedungreja' },
    { label: 'Kawunganten', value: 'Kawunganten' },
    { label: 'Karangpucung', value: 'Karangpucung' },
    { label: 'Kampung Laut', value: 'Kampung Laut' },
    { label: 'Jeruklegi', value: 'Jeruklegi' },
    { label: 'Gandrungmangu', value: 'Gandrungmangu' },
    { label: 'Dayeuhluhur', value: 'Dayeuhluhur' },
    { label: 'Cipari', value: 'Cipari' },
    { label: 'Cimanggu', value: 'Cimanggu' },
    { label: 'Cilacap Utara', value: 'Cilacap Utara' },
    { label: 'Cilacap Tengah', value: 'Cilacap Tengah' },
    { label: 'Cilacap Selatan', value: 'Cilacap Selatan' },
    { label: 'Binangun', value: 'Binangun' },
    { label: 'Bantarsari', value: 'Bantarsari' },
    { label: 'Adipala', value: 'Adipala' }
  ],
  Semarang: [
    { label: 'Ungaran Timur', value: 'Ungaran Timur' },
    { label: 'Ungaran Barat', value: 'Ungaran Barat' },
    { label: 'Tuntang', value: 'Tuntang' },
    { label: 'Tugu', value: 'Tugu' },
    { label: 'Tengaran', value: 'Tengaran' },
    { label: 'Tembalang', value: 'Tembalang' },
    { label: 'Susukan', value: 'Susukan' },
    { label: 'Suruh', value: 'Suruh' },
    { label: 'Sumowono', value: 'Sumowono' },
    { label: 'Semarang Utara', value: 'Semarang Utara' },
    { label: 'Semarang Timur', value: 'Semarang Timur' },
    { label: 'Semarang Tengah', value: 'Semarang Tengah' },
    { label: 'Semarang Selatan', value: 'Semarang Selatan' },
    { label: 'Semarang Barat', value: 'Semarang Barat' },
    { label: 'Pringapus', value: 'Pringapus' },
    { label: 'Pedurungan', value: 'Pedurungan' },
    { label: 'Pabelan', value: 'Pabelan' },
    { label: 'Ngaliyan', value: 'Ngaliyan' },
    { label: 'Mijen', value: 'Mijen' },
    { label: 'Kaliwungu', value: 'Kaliwungu' },
    { label: 'Jambu', value: 'Jambu' },
    { label: 'Gunungpati', value: 'Gunungpati' },
    { label: 'Getasan', value: 'Getasan' },
    { label: 'Genuk', value: 'Genuk' },
    { label: 'Gayamsari', value: 'Gayamsari' },
    { label: 'Gajah Mungkur', value: 'Gajah Mungkur' },
    { label: 'Candisari', value: 'Candisari' },
    { label: 'Bringin', value: 'Bringin' },
    { label: 'Bergas', value: 'Bergas' },
    { label: 'Bawen', value: 'Bawen' },
    { label: 'Banyumanik', value: 'Banyumanik' },
    { label: 'Banyubiru', value: 'Banyubiru' },
    { label: 'Bandungan', value: 'Bandungan' },
    { label: 'Bancak', value: 'Bancak' },
    { label: 'Ambarawa', value: 'Ambarawa' }
  ],
  Kudus: [
    { label: 'Undaan', value: 'Undaan' },
    { label: 'Mejobo', value: 'Mejobo' },
    { label: 'Kudus Kota', value: 'Kudus Kota' },
    { label: 'Kaliwungu', value: 'Kaliwungu' },
    { label: 'Jekulo', value: 'Jekulo' },
    { label: 'Jati', value: 'Jati' },
    { label: 'Gebog', value: 'Gebog' },
    { label: 'Dawe', value: 'Dawe' },
    { label: 'Bae', value: 'Bae' }
  ],
  Blora: [
    { label: 'Tunjungan', value: 'Tunjungan' },
    { label: 'Todanan', value: 'Todanan' },
    { label: 'Sambong', value: 'Sambong' },
    { label: 'Randublatung', value: 'Randublatung' },
    { label: 'Ngawen', value: 'Ngawen' },
    { label: 'Kunduran', value: 'Kunduran' },
    { label: 'Kradenan', value: 'Kradenan' },
    { label: 'Kedungtuban', value: 'Kedungtuban' },
    { label: 'Jiken', value: 'Jiken' },
    { label: 'Jepon', value: 'Jepon' },
    { label: 'Jati', value: 'Jati' },
    { label: 'Japah', value: 'Japah' },
    { label: 'Cepu', value: 'Cepu' },
    { label: 'Bogorejo', value: 'Bogorejo' },
    { label: 'Blora kota', value: 'Blora kota' },
    { label: 'Banjarejo', value: 'Banjarejo' }
  ],
  Salatiga: [
    { label: 'Tingkir', value: 'Tingkir' },
    { label: 'Sidorejo', value: 'Sidorejo' },
    { label: 'Sidomukti', value: 'Sidomukti' },
    { label: 'Argomulyo', value: 'Argomulyo' }
  ],
  Karanganyar: [
    { label: 'Tawangmangu', value: 'Tawangmangu' },
    { label: 'Tasikmadu', value: 'Tasikmadu' },
    { label: 'Ngargoyoso', value: 'Ngargoyoso' },
    { label: 'Mojogedang', value: 'Mojogedang' },
    { label: 'Matesih', value: 'Matesih' },
    { label: 'Kerjo', value: 'Kerjo' },
    { label: 'Kebakkramat', value: 'Kebakkramat' },
    { label: 'Karangpandan', value: 'Karangpandan' },
    { label: 'Karanganyar', value: 'Karanganyar' },
    { label: 'Jumapolo', value: 'Jumapolo' },
    { label: 'Jumantono', value: 'Jumantono' },
    { label: 'Jenawi', value: 'Jenawi' },
    { label: 'Jatiyoso', value: 'Jatiyoso' },
    { label: 'Jatipuro', value: 'Jatipuro' },
    { label: 'Jaten', value: 'Jaten' },
    { label: 'Gondangrejo', value: 'Gondangrejo' },
    { label: 'Colomadu', value: 'Colomadu' }
  ],
  Sragen: [
    { label: 'Tanon', value: 'Tanon' },
    { label: 'Tangen', value: 'Tangen' },
    { label: 'Sumberlawang', value: 'Sumberlawang' },
    { label: 'Sukodono', value: 'Sukodono' },
    { label: 'Sragen', value: 'Sragen' },
    { label: 'Sidoharjo', value: 'Sidoharjo' },
    { label: 'Sambung Macan', value: 'Sambung Macan' },
    { label: 'Sambirejo', value: 'Sambirejo' },
    { label: 'Plupuh', value: 'Plupuh' },
    { label: 'Ngrampal', value: 'Ngrampal' },
    { label: 'Mondokan', value: 'Mondokan' },
    { label: 'Miri', value: 'Miri' },
    { label: 'Masaran', value: 'Masaran' },
    { label: 'Kedawung', value: 'Kedawung' },
    { label: 'Karangmalang', value: 'Karangmalang' },
    { label: 'Kalijambe', value: 'Kalijambe' },
    { label: 'Jenar', value: 'Jenar' },
    { label: 'Gondang', value: 'Gondang' },
    { label: 'Gesi', value: 'Gesi' },
    { label: 'Gemolong', value: 'Gemolong' }
  ],
  Rembang: [
    { label: 'Sumber', value: 'Sumber' },
    { label: 'Sulang', value: 'Sulang' },
    { label: 'Sluke', value: 'Sluke' },
    { label: 'Sedan', value: 'Sedan' },
    { label: 'Sarang', value: 'Sarang' },
    { label: 'Sale', value: 'Sale' },
    { label: 'Rembang', value: 'Rembang' },
    { label: 'Pancur', value: 'Pancur' },
    { label: 'Pamotan', value: 'Pamotan' },
    { label: 'Lasem', value: 'Lasem' },
    { label: 'Kragan', value: 'Kragan' },
    { label: 'Kaliori', value: 'Kaliori' },
    { label: 'Gunem', value: 'Gunem' },
    { label: 'Bulu', value: 'Bulu' }
  ],
  Kebumen: [
    { label: 'Sruweng', value: 'Sruweng' },
    { label: 'Sempor', value: 'Sempor' },
    { label: 'Sadang', value: 'Sadang' },
    { label: 'Rowokele', value: 'Rowokele' },
    { label: 'Puring', value: 'Puring' },
    { label: 'Prembun', value: 'Prembun' },
    { label: 'Poncowarno', value: 'Poncowarno' },
    { label: 'Petanahan', value: 'Petanahan' },
    { label: 'Pejagoan', value: 'Pejagoan' },
    { label: 'Padureso', value: 'Padureso' },
    { label: 'Mirit', value: 'Mirit' },
    { label: 'Kuwarasan', value: 'Kuwarasan' },
    { label: 'Kutowinangun', value: 'Kutowinangun' },
    { label: 'Klirong', value: 'Klirong' },
    { label: 'Kebumen', value: 'Kebumen' },
    { label: 'Karangsambung', value: 'Karangsambung' },
    { label: 'Karanggayam', value: 'Karanggayam' },
    { label: 'Karanganyar', value: 'Karanganyar' },
    { label: 'Gombong', value: 'Gombong' },
    { label: 'Buluspesantren', value: 'Buluspesantren' },
    { label: 'Buayan', value: 'Buayan' },
    { label: 'Bonorowo', value: 'Bonorowo' },
    { label: 'Ayah', value: 'Ayah' },
    { label: 'Ambal', value: 'Ambal' },
    { label: 'Alian/Aliyan', value: 'Alian/Aliyan' },
    { label: 'Adimulyo', value: 'Adimulyo' }
  ],
  Surakarta: [
    { label: 'Serengan', value: 'Serengan' },
    { label: 'Pasar Kliwon', value: 'Pasar Kliwon' },
    { label: 'Laweyan', value: 'Laweyan' },
    { label: 'Jebres', value: 'Jebres' },
    { label: 'Banjarsari', value: 'Banjarsari' }
  ],
  Purbalingga: [
    { label: 'Rembang', value: 'Rembang' },
    { label: 'Purbalingga', value: 'Purbalingga' },
    { label: 'Pengadegan', value: 'Pengadegan' },
    { label: 'Padamara', value: 'Padamara' },
    { label: 'Mrebet', value: 'Mrebet' },
    { label: 'Kutasari', value: 'Kutasari' },
    { label: 'Kertanegara', value: 'Kertanegara' },
    { label: 'Kemangkon', value: 'Kemangkon' },
    { label: 'Kejobong', value: 'Kejobong' },
    { label: 'Karangreja', value: 'Karangreja' },
    { label: 'Karangmoncol', value: 'Karangmoncol' },
    { label: 'Karangjambu', value: 'Karangjambu' },
    { label: 'Karanganyar', value: 'Karanganyar' },
    { label: 'Kalimanah', value: 'Kalimanah' },
    { label: 'Kaligondang', value: 'Kaligondang' },
    { label: 'Bukateja', value: 'Bukateja' },
    { label: 'Bojongsari', value: 'Bojongsari' },
    { label: 'Bobotsari', value: 'Bobotsari' }
  ],
  Purworejo: [
    { label: 'Purworejo', value: 'Purworejo' },
    { label: 'Purwodadi', value: 'Purwodadi' },
    { label: 'Pituruh', value: 'Pituruh' },
    { label: 'Ngombol', value: 'Ngombol' },
    { label: 'Loano', value: 'Loano' },
    { label: 'Kutoarjo', value: 'Kutoarjo' },
    { label: 'Kemiri', value: 'Kemiri' },
    { label: 'Kaligesing', value: 'Kaligesing' },
    { label: 'Grabag', value: 'Grabag' },
    { label: 'Gebang', value: 'Gebang' },
    { label: 'Butuh', value: 'Butuh' },
    { label: 'Bruno', value: 'Bruno' },
    { label: 'Bener', value: 'Bener' },
    { label: 'Bayan', value: 'Bayan' },
    { label: 'Banyuurip', value: 'Banyuurip' },
    { label: 'Bagelen', value: 'Bagelen' }
  ],
  Bangkalan: [
    { label: 'Arosbaya', value: 'Arosbaya' },
    { label: 'Bangkalan', value: 'Bangkalan' },
    { label: 'Blega', value: 'Blega' },
    { label: 'Burneh', value: 'Burneh' },
    { label: 'Galis', value: 'Galis' },
    { label: 'Geger', value: 'Geger' },
    { label: 'Kamal', value: 'Kamal' },
    { label: 'Klampis', value: 'Klampis' },
    { label: 'Kokop', value: 'Kokop' },
    { label: 'Konang', value: 'Konang' },
    { label: 'Kwanyar', value: 'Kwanyar' },
    { label: 'Labang', value: 'Labang' },
    { label: 'Modung', value: 'Modung' },
    { label: 'Sepulu', value: 'Sepulu' },
    { label: 'Socah', value: 'Socah' },
    { label: 'Tanah Merah', value: 'Tanah Merah' },
    { label: 'Tanjungbumi', value: 'Tanjungbumi' },
    { label: 'Tragah', value: 'Tragah' }
  ],
  Banyuwangi: [
    { label: 'Bangorejo', value: 'Bangorejo' },
    { label: 'Banyuwangi', value: 'Banyuwangi' },
    { label: 'Cluring', value: 'Cluring' },
    { label: 'Gambiran', value: 'Gambiran' },
    { label: 'Genteng', value: 'Genteng' },
    { label: 'Giri', value: 'Giri' },
    { label: 'Glagah', value: 'Glagah' },
    { label: 'Glenmore', value: 'Glenmore' },
    { label: 'Kabat', value: 'Kabat' },
    { label: 'Kalibaru', value: 'Kalibaru' },
    { label: 'Kalipuro', value: 'Kalipuro' },
    { label: 'Licin', value: 'Licin' },
    { label: 'Muncar', value: 'Muncar' },
    { label: 'Pesanggaran', value: 'Pesanggaran' },
    { label: 'Purwoharjo', value: 'Purwoharjo' },
    { label: 'Rogojampi', value: 'Rogojampi' },
    { label: 'Sempu', value: 'Sempu' },
    { label: 'Siliragung', value: 'Siliragung' },
    { label: 'Singojuruh', value: 'Singojuruh' },
    { label: 'Songgon', value: 'Songgon' },
    { label: 'Srono', value: 'Srono' },
    { label: 'Tegaldlimo', value: 'Tegaldlimo' },
    { label: 'Tegalsari', value: 'Tegalsari' },
    { label: 'Wongsorejo', value: 'Wongsorejo' }
  ],
  Batu: [
    { label: 'Batu', value: 'Batu' },
    { label: 'Bumiaji', value: 'Bumiaji' },
    { label: 'Junrejo', value: 'Junrejo' }
  ],
  Blitar: [
    { label: 'Kepanjen Kidul', value: 'Kepanjen Kidul' },
    { label: 'Sanan Wetan', value: 'Sanan Wetan' },
    { label: 'Sukorejo', value: 'Sukorejo' },
    { label: 'Bakung', value: 'Bakung' },
    { label: 'Binangun', value: 'Binangun' },
    { label: 'Doko', value: 'Doko' },
    { label: 'Gandusari', value: 'Gandusari' },
    { label: 'Garum', value: 'Garum' },
    { label: 'Kademangan', value: 'Kademangan' },
    { label: 'Kanigoro', value: 'Kanigoro' },
    { label: 'Kesamben', value: 'Kesamben' },
    { label: 'Nglegok', value: 'Nglegok' },
    { label: 'Panggungrejo', value: 'Panggungrejo' },
    { label: 'Ponggok', value: 'Ponggok' },
    { label: 'Sanan Kulon', value: 'Sanan Kulon' },
    { label: 'Selopuro', value: 'Selopuro' },
    { label: 'Selorejo', value: 'Selorejo' },
    { label: 'Srengat', value: 'Srengat' },
    { label: 'Sutojayan', value: 'Sutojayan' },
    { label: 'Talun', value: 'Talun' },
    { label: 'Udanawu', value: 'Udanawu' },
    { label: 'Wates', value: 'Wates' },
    { label: 'Wlingi', value: 'Wlingi' },
    { label: 'Wonodadi', value: 'Wonodadi' },
    { label: 'Wonotirto', value: 'Wonotirto' }
  ],
  Bojonegoro: [
    { label: 'Balen', value: 'Balen' },
    { label: 'Baureno', value: 'Baureno' },
    { label: 'Bojonegoro', value: 'Bojonegoro' },
    { label: 'Bubulan', value: 'Bubulan' },
    { label: 'Dander', value: 'Dander' },
    { label: 'Gayam', value: 'Gayam' },
    { label: 'Gondang', value: 'Gondang' },
    { label: 'Kalitidu', value: 'Kalitidu' },
    { label: 'Kanor', value: 'Kanor' },
    { label: 'Kapas', value: 'Kapas' },
    { label: 'Kasiman', value: 'Kasiman' },
    { label: 'Kedewan', value: 'Kedewan' },
    { label: 'Kedungadem', value: 'Kedungadem' },
    { label: 'Kepoh Baru', value: 'Kepoh Baru' },
    { label: 'Malo', value: 'Malo' },
    { label: 'Margomulyo', value: 'Margomulyo' },
    { label: 'Ngambon', value: 'Ngambon' },
    { label: 'Ngasem', value: 'Ngasem' },
    { label: 'Ngraho', value: 'Ngraho' },
    { label: 'Padangan', value: 'Padangan' },
    { label: 'Purwosari', value: 'Purwosari' },
    { label: 'Sekar', value: 'Sekar' },
    { label: 'Sugihwaras', value: 'Sugihwaras' },
    { label: 'Sukosewu', value: 'Sukosewu' },
    { label: 'Sumberrejo', value: 'Sumberrejo' },
    { label: 'Tambakrejo', value: 'Tambakrejo' },
    { label: 'Temayang', value: 'Temayang' },
    { label: 'Trucuk', value: 'Trucuk' }
  ],
  Bondowoso: [
    { label: 'Binakal', value: 'Binakal' },
    { label: 'Bondowoso', value: 'Bondowoso' },
    { label: 'Botolinggo', value: 'Botolinggo' },
    { label: 'Cermee', value: 'Cermee' },
    { label: 'Curahdami', value: 'Curahdami' },
    { label: 'Grujugan', value: 'Grujugan' },
    { label: 'Jambe Sari Darus Sholah', value: 'Jambe Sari Darus Sholah' },
    { label: 'Klabang', value: 'Klabang' },
    { label: 'Maesan', value: 'Maesan' },
    { label: 'Pakem', value: 'Pakem' },
    { label: 'Prajekan', value: 'Prajekan' },
    { label: 'Pujer', value: 'Pujer' },
    { label: 'Sempol', value: 'Sempol' },
    { label: 'Sukosari', value: 'Sukosari' },
    { label: 'Sumber Wringin', value: 'Sumber Wringin' },
    { label: 'Taman Krocok', value: 'Taman Krocok' },
    { label: 'Tamanan', value: 'Tamanan' },
    { label: 'Tapen', value: 'Tapen' },
    { label: 'Tegalampel', value: 'Tegalampel' },
    { label: 'Tenggarang', value: 'Tenggarang' },
    { label: 'Tlogosari', value: 'Tlogosari' },
    { label: 'Wonosari', value: 'Wonosari' },
    { label: 'Wringin', value: 'Wringin' }
  ],
  Gresik: [
    { label: 'Balong Panggang', value: 'Balong Panggang' },
    { label: 'Benjeng', value: 'Benjeng' },
    { label: 'Bungah', value: 'Bungah' },
    { label: 'Cerme', value: 'Cerme' },
    { label: 'Driyorejo', value: 'Driyorejo' },
    { label: 'Duduk Sampeyan', value: 'Duduk Sampeyan' },
    { label: 'Dukun', value: 'Dukun' },
    { label: 'Gresik', value: 'Gresik' },
    { label: 'Kebomas', value: 'Kebomas' },
    { label: 'Kedamean', value: 'Kedamean' },
    { label: 'Manyar', value: 'Manyar' },
    { label: 'Menganti', value: 'Menganti' },
    { label: 'Panceng', value: 'Panceng' },
    { label: 'Sangkapura', value: 'Sangkapura' },
    { label: 'Sidayu', value: 'Sidayu' },
    { label: 'Tambak', value: 'Tambak' },
    { label: 'Ujung Pangkah', value: 'Ujung Pangkah' },
    { label: 'Wringin Anom', value: 'Wringin Anom' }
  ],
  Jember: [
    { label: 'Ajung', value: 'Ajung' },
    { label: 'Ambulu', value: 'Ambulu' },
    { label: 'Arjasa', value: 'Arjasa' },
    { label: 'Balung', value: 'Balung' },
    { label: 'Bangsalsari', value: 'Bangsalsari' },
    { label: 'Gumuk Mas', value: 'Gumuk Mas' },
    { label: 'Jelbuk', value: 'Jelbuk' },
    { label: 'Jenggawah', value: 'Jenggawah' },
    { label: 'Jombang', value: 'Jombang' },
    { label: 'Kalisat', value: 'Kalisat' },
    { label: 'Kaliwates', value: 'Kaliwates' },
    { label: 'Kencong', value: 'Kencong' },
    { label: 'Ledokombo', value: 'Ledokombo' },
    { label: 'Mayang', value: 'Mayang' },
    { label: 'Mumbulsari', value: 'Mumbulsari' },
    { label: 'Pakusari', value: 'Pakusari' },
    { label: 'Panti', value: 'Panti' },
    { label: 'Patrang', value: 'Patrang' },
    { label: 'Puger', value: 'Puger' },
    { label: 'Rambipuji', value: 'Rambipuji' },
    { label: 'Semboro', value: 'Semboro' },
    { label: 'Silo', value: 'Silo' },
    { label: 'Sukorambi', value: 'Sukorambi' },
    { label: 'Sukowono', value: 'Sukowono' },
    { label: 'Sumber Baru', value: 'Sumber Baru' },
    { label: 'Sumber Jambe', value: 'Sumber Jambe' },
    { label: 'Sumber Sari', value: 'Sumber Sari' },
    { label: 'Tanggul', value: 'Tanggul' },
    { label: 'Tempurejo', value: 'Tempurejo' },
    { label: 'Umbulsari', value: 'Umbulsari' },
    { label: 'Wuluhan', value: 'Wuluhan' }
  ],
  Jombang: [
    { label: 'Bandar Kedung Mulyo', value: 'Bandar Kedung Mulyo' },
    { label: 'Bareng', value: 'Bareng' },
    { label: 'Diwek', value: 'Diwek' },
    { label: 'Gudo', value: 'Gudo' },
    { label: 'Jogoroto', value: 'Jogoroto' },
    { label: 'Jombang', value: 'Jombang' },
    { label: 'Kabuh', value: 'Kabuh' },
    { label: 'Kesamben', value: 'Kesamben' },
    { label: 'Kudu', value: 'Kudu' },
    { label: 'Megaluh', value: 'Megaluh' },
    { label: 'Mojoagung', value: 'Mojoagung' },
    { label: 'Mojowarno', value: 'Mojowarno' },
    { label: 'Ngoro', value: 'Ngoro' },
    { label: 'Ngusikan', value: 'Ngusikan' },
    { label: 'Perak', value: 'Perak' },
    { label: 'Peterongan', value: 'Peterongan' },
    { label: 'Plandaan', value: 'Plandaan' },
    { label: 'Ploso', value: 'Ploso' },
    { label: 'Sumobito', value: 'Sumobito' },
    { label: 'Tembelang', value: 'Tembelang' },
    { label: 'Wonosalam', value: 'Wonosalam' }
  ],
  Kediri: [
    { label: 'Kediri Kota', value: 'Kediri Kota' },
    { label: 'Mojoroto', value: 'Mojoroto' },
    { label: 'Pesantren', value: 'Pesantren' },
    { label: 'Badas', value: 'Badas' },
    { label: 'Banyakan', value: 'Banyakan' },
    { label: 'Gampengrejo', value: 'Gampengrejo' },
    { label: 'Grogol', value: 'Grogol' },
    { label: 'Gurah', value: 'Gurah' },
    { label: 'Kandangan', value: 'Kandangan' },
    { label: 'Kandat', value: 'Kandat' },
    { label: 'Kayen Kidul', value: 'Kayen Kidul' },
    { label: 'Kepung', value: 'Kepung' },
    { label: 'Kras', value: 'Kras' },
    { label: 'Kunjang', value: 'Kunjang' },
    { label: 'Mojo', value: 'Mojo' },
    { label: 'Ngadiluwih', value: 'Ngadiluwih' },
    { label: 'Ngancar', value: 'Ngancar' },
    { label: 'Ngasem', value: 'Ngasem' },
    { label: 'Pagu', value: 'Pagu' },
    { label: 'Papar', value: 'Papar' },
    { label: 'Pare', value: 'Pare' },
    { label: 'Plemahan', value: 'Plemahan' },
    { label: 'Plosoklaten', value: 'Plosoklaten' },
    { label: 'Puncu', value: 'Puncu' },
    { label: 'Purwoasri', value: 'Purwoasri' },
    { label: 'Ringinrejo', value: 'Ringinrejo' },
    { label: 'Semen', value: 'Semen' },
    { label: 'Tarokan', value: 'Tarokan' },
    { label: 'Wates', value: 'Wates' }
  ],
  Lamongan: [
    { label: 'Babat', value: 'Babat' },
    { label: 'Bluluk', value: 'Bluluk' },
    { label: 'Brondong', value: 'Brondong' },
    { label: 'Deket', value: 'Deket' },
    { label: 'Glagah', value: 'Glagah' },
    { label: 'Kalitengah', value: 'Kalitengah' },
    { label: 'Karang Geneng', value: 'Karang Geneng' },
    { label: 'Karangbinangun', value: 'Karangbinangun' },
    { label: 'Kedungpring', value: 'Kedungpring' },
    { label: 'Kembangbahu', value: 'Kembangbahu' },
    { label: 'Lamongan', value: 'Lamongan' },
    { label: 'Laren', value: 'Laren' },
    { label: 'Maduran', value: 'Maduran' },
    { label: 'Mantup', value: 'Mantup' },
    { label: 'Modo', value: 'Modo' },
    { label: 'Ngimbang', value: 'Ngimbang' },
    { label: 'Paciran', value: 'Paciran' },
    { label: 'Pucuk', value: 'Pucuk' },
    { label: 'Sambeng', value: 'Sambeng' },
    { label: 'Sarirejo', value: 'Sarirejo' },
    { label: 'Sekaran', value: 'Sekaran' },
    { label: 'Solokuro', value: 'Solokuro' },
    { label: 'Sugio', value: 'Sugio' },
    { label: 'Sukodadi', value: 'Sukodadi' },
    { label: 'Sukorame', value: 'Sukorame' },
    { label: 'Tikung', value: 'Tikung' },
    { label: 'Turi', value: 'Turi' }
  ],
  Lumajang: [
    { label: 'Candipuro', value: 'Candipuro' },
    { label: 'Gucialit', value: 'Gucialit' },
    { label: 'Jatiroto', value: 'Jatiroto' },
    { label: 'Kedungjajang', value: 'Kedungjajang' },
    { label: 'Klakah', value: 'Klakah' },
    { label: 'Kunir', value: 'Kunir' },
    { label: 'Lumajang', value: 'Lumajang' },
    { label: 'Padang', value: 'Padang' },
    { label: 'Pasirian', value: 'Pasirian' },
    { label: 'Pasrujambe/Pasujambe', value: 'Pasrujambe/Pasujambe' },
    { label: 'Pronojiwo', value: 'Pronojiwo' },
    { label: 'Randuagung', value: 'Randuagung' },
    { label: 'Ranuyoso', value: 'Ranuyoso' },
    { label: 'Rowokangkung', value: 'Rowokangkung' },
    { label: 'Senduro', value: 'Senduro' },
    { label: 'Sukodono', value: 'Sukodono' },
    { label: 'Sumbersuko', value: 'Sumbersuko' },
    { label: 'Tekung', value: 'Tekung' },
    { label: 'Tempeh', value: 'Tempeh' },
    { label: 'Tempursari', value: 'Tempursari' },
    { label: 'Yosowilangun', value: 'Yosowilangun' }
  ],
  Madiun: [
    { label: 'Kartoharjo', value: 'Kartoharjo' },
    { label: 'Manguharjo', value: 'Manguharjo' },
    { label: 'Taman', value: 'Taman' },
    { label: 'Balerejo', value: 'Balerejo' },
    { label: 'Dagangan', value: 'Dagangan' },
    { label: 'Dolopo', value: 'Dolopo' },
    { label: 'Geger', value: 'Geger' },
    { label: 'Gemarang', value: 'Gemarang' },
    { label: 'Jiwan', value: 'Jiwan' },
    { label: 'Kare', value: 'Kare' },
    { label: 'Kebonsari', value: 'Kebonsari' },
    { label: 'Madiun', value: 'Madiun' },
    { label: 'Mejayan', value: 'Mejayan' },
    { label: 'Pilangkenceng', value: 'Pilangkenceng' },
    { label: 'Saradan', value: 'Saradan' },
    { label: 'Sawahan', value: 'Sawahan' },
    { label: 'Wonoasri', value: 'Wonoasri' },
    { label: 'Wungu', value: 'Wungu' }
  ],
  Magetan: [
    { label: 'Barat', value: 'Barat' },
    { label: 'Bendo', value: 'Bendo' },
    { label: 'Karangrejo', value: 'Karangrejo' },
    { label: 'Karas', value: 'Karas' },
    { label: 'Kartoharjo (Kertoharjo)', value: 'Kartoharjo (Kertoharjo)' },
    { label: 'Kawedanan', value: 'Kawedanan' },
    { label: 'Lembeyan', value: 'Lembeyan' },
    { label: 'Magetan', value: 'Magetan' },
    { label: 'Maospati', value: 'Maospati' },
    { label: 'Ngariboyo', value: 'Ngariboyo' },
    { label: 'Nguntoronadi', value: 'Nguntoronadi' },
    { label: 'Panekan', value: 'Panekan' },
    { label: 'Parang', value: 'Parang' },
    { label: 'Plaosan', value: 'Plaosan' },
    { label: 'Poncol', value: 'Poncol' },
    { label: 'Sidorejo', value: 'Sidorejo' },
    { label: 'Sukomoro', value: 'Sukomoro' },
    { label: 'Takeran', value: 'Takeran' }
  ],
  Malang: [
    { label: 'Blimbing', value: 'Blimbing' },
    { label: 'Kedungkandang', value: 'Kedungkandang' },
    { label: 'Klojen', value: 'Klojen' },
    { label: 'Lowokwaru', value: 'Lowokwaru' },
    { label: 'Sukun', value: 'Sukun' },
    { label: 'Ampelgading', value: 'Ampelgading' },
    { label: 'Bantur', value: 'Bantur' },
    { label: 'Bululawang', value: 'Bululawang' },
    { label: 'Dampit', value: 'Dampit' },
    { label: 'Dau', value: 'Dau' },
    { label: 'Donomulyo', value: 'Donomulyo' },
    { label: 'Gedangan', value: 'Gedangan' },
    { label: 'Gondanglegi', value: 'Gondanglegi' },
    { label: 'Jabung', value: 'Jabung' },
    { label: 'Kalipare', value: 'Kalipare' },
    { label: 'Karangploso', value: 'Karangploso' },
    { label: 'Kasembon', value: 'Kasembon' },
    { label: 'Kepanjen', value: 'Kepanjen' },
    { label: 'Kromengan', value: 'Kromengan' },
    { label: 'Lawang', value: 'Lawang' },
    { label: 'Ngajung (Ngajum)', value: 'Ngajung (Ngajum)' },
    { label: 'Ngantang', value: 'Ngantang' },
    { label: 'Pagak', value: 'Pagak' },
    { label: 'Pagelaran', value: 'Pagelaran' },
    { label: 'Pakis', value: 'Pakis' },
    { label: 'Pakisaji', value: 'Pakisaji' },
    { label: 'Poncokusumo', value: 'Poncokusumo' },
    { label: 'Pujon', value: 'Pujon' },
    { label: 'Singosari', value: 'Singosari' },
    { label: 'Sumbermanjing Wetan', value: 'Sumbermanjing Wetan' },
    { label: 'Sumberpucung', value: 'Sumberpucung' },
    { label: 'Tajinan', value: 'Tajinan' },
    { label: 'Tirtoyudo', value: 'Tirtoyudo' },
    { label: 'Tumpang', value: 'Tumpang' },
    { label: 'Turen', value: 'Turen' },
    { label: 'Wagir', value: 'Wagir' },
    { label: 'Wajak', value: 'Wajak' },
    { label: 'Wonosari', value: 'Wonosari' }
  ],
  Mojokerto: [
    { label: 'Magersari', value: 'Magersari' },
    { label: 'Prajurit Kulon', value: 'Prajurit Kulon' },
    { label: 'Bangsal', value: 'Bangsal' },
    { label: 'Dawar Blandong', value: 'Dawar Blandong' },
    { label: 'Dlanggu', value: 'Dlanggu' },
    { label: 'Gedeg', value: 'Gedeg' },
    { label: 'Gondang', value: 'Gondang' },
    { label: 'Jatirejo', value: 'Jatirejo' },
    { label: 'Jetis', value: 'Jetis' },
    { label: 'Kemlagi', value: 'Kemlagi' },
    { label: 'Kutorejo', value: 'Kutorejo' },
    { label: 'Mojoanyar', value: 'Mojoanyar' },
    { label: 'Mojosari', value: 'Mojosari' },
    { label: 'Ngoro', value: 'Ngoro' },
    { label: 'Pacet', value: 'Pacet' },
    { label: 'Pungging', value: 'Pungging' },
    { label: 'Puri', value: 'Puri' },
    { label: 'Sooko', value: 'Sooko' },
    { label: 'Trawas', value: 'Trawas' },
    { label: 'Trowulan', value: 'Trowulan' }
  ],
  Nganjuk: [
    { label: 'Bagor', value: 'Bagor' },
    { label: 'Baron', value: 'Baron' },
    { label: 'Berbek', value: 'Berbek' },
    { label: 'Gondang', value: 'Gondang' },
    { label: 'Jatikalen', value: 'Jatikalen' },
    { label: 'Kertosono', value: 'Kertosono' },
    { label: 'Lengkong', value: 'Lengkong' },
    { label: 'Loceret', value: 'Loceret' },
    { label: 'Nganjuk', value: 'Nganjuk' },
    { label: 'Ngetos', value: 'Ngetos' },
    { label: 'Ngluyu', value: 'Ngluyu' },
    { label: 'Ngronggot', value: 'Ngronggot' },
    { label: 'Pace', value: 'Pace' },
    { label: 'Patianrowo', value: 'Patianrowo' },
    { label: 'Prambon', value: 'Prambon' },
    { label: 'Rejoso', value: 'Rejoso' },
    { label: 'Sawahan', value: 'Sawahan' },
    { label: 'Sukomoro', value: 'Sukomoro' },
    { label: 'Tanjunganom', value: 'Tanjunganom' },
    { label: 'Wilangan', value: 'Wilangan' }
  ],
  Ngawi: [
    { label: 'Bringin', value: 'Bringin' },
    { label: 'Geneng', value: 'Geneng' },
    { label: 'Gerih', value: 'Gerih' },
    { label: 'Jogorogo', value: 'Jogorogo' },
    { label: 'Karanganyar', value: 'Karanganyar' },
    { label: 'Karangjati', value: 'Karangjati' },
    { label: 'Kasreman', value: 'Kasreman' },
    { label: 'Kedunggalar', value: 'Kedunggalar' },
    { label: 'Kendal', value: 'Kendal' },
    { label: 'Kwadungan', value: 'Kwadungan' },
    { label: 'Mantingan', value: 'Mantingan' },
    { label: 'Ngawi', value: 'Ngawi' },
    { label: 'Ngrambe', value: 'Ngrambe' },
    { label: 'Padas', value: 'Padas' },
    { label: 'Pangkur', value: 'Pangkur' },
    { label: 'Paron', value: 'Paron' },
    { label: 'Pitu', value: 'Pitu' },
    { label: 'Sine', value: 'Sine' },
    { label: 'Widodaren', value: 'Widodaren' }
  ],
  Pacitan: [
    { label: 'Arjosari', value: 'Arjosari' },
    { label: 'Bandar', value: 'Bandar' },
    { label: 'Donorojo', value: 'Donorojo' },
    { label: 'Kebon Agung', value: 'Kebon Agung' },
    { label: 'Nawangan', value: 'Nawangan' },
    { label: 'Ngadirojo', value: 'Ngadirojo' },
    { label: 'Pacitan', value: 'Pacitan' },
    { label: 'Pringkuku', value: 'Pringkuku' },
    { label: 'Punung', value: 'Punung' },
    { label: 'Sudimoro', value: 'Sudimoro' },
    { label: 'Tegalombo', value: 'Tegalombo' },
    { label: 'Tulakan', value: 'Tulakan' }
  ],
  Pamekasan: [
    { label: 'Batumarmar', value: 'Batumarmar' },
    { label: 'Galis', value: 'Galis' },
    { label: 'Kadur', value: 'Kadur' },
    { label: 'Larangan', value: 'Larangan' },
    { label: 'Pademawu', value: 'Pademawu' },
    { label: 'Pakong', value: 'Pakong' },
    { label: "Palenga'an", value: "Palenga'an" },
    { label: 'Pamekasan', value: 'Pamekasan' },
    { label: 'Pasean', value: 'Pasean' },
    { label: 'Pegantenan', value: 'Pegantenan' },
    { label: 'Proppo', value: 'Proppo' },
    { label: 'Tlanakan', value: 'Tlanakan' },
    { label: 'Waru', value: 'Waru' }
  ],
  Pasuruan: [
    { label: 'Bugul Kidul', value: 'Bugul Kidul' },
    { label: 'Gadingrejo', value: 'Gadingrejo' },
    { label: 'Panggungrejo', value: 'Panggungrejo' },
    { label: 'Purworejo', value: 'Purworejo' },
    { label: 'Bangil', value: 'Bangil' },
    { label: 'Beji', value: 'Beji' },
    { label: 'Gempol', value: 'Gempol' },
    { label: 'Gondang Wetan', value: 'Gondang Wetan' },
    { label: 'Grati', value: 'Grati' },
    { label: 'Kejayan', value: 'Kejayan' },
    { label: 'Kraton', value: 'Kraton' },
    { label: 'Lekok', value: 'Lekok' },
    { label: 'Lumbang', value: 'Lumbang' },
    { label: 'Nguling', value: 'Nguling' },
    { label: 'Pandaan', value: 'Pandaan' },
    { label: 'Pasrepan', value: 'Pasrepan' },
    { label: 'Pohjentrek', value: 'Pohjentrek' },
    { label: 'Prigen', value: 'Prigen' },
    { label: 'Purwodadi', value: 'Purwodadi' },
    { label: 'Purwosari', value: 'Purwosari' },
    { label: 'Puspo', value: 'Puspo' },
    { label: 'Rejoso', value: 'Rejoso' },
    { label: 'Rembang', value: 'Rembang' },
    { label: 'Sukorejo', value: 'Sukorejo' },
    { label: 'Tosari', value: 'Tosari' },
    { label: 'Tutur', value: 'Tutur' },
    { label: 'Winongan', value: 'Winongan' },
    { label: 'Wonorejo', value: 'Wonorejo' }
  ],
  Ponorogo: [
    { label: 'Babadan', value: 'Babadan' },
    { label: 'Badegan', value: 'Badegan' },
    { label: 'Balong', value: 'Balong' },
    { label: 'Bungkal', value: 'Bungkal' },
    { label: 'Jambon', value: 'Jambon' },
    { label: 'Jenangan', value: 'Jenangan' },
    { label: 'Jetis', value: 'Jetis' },
    { label: 'Kauman', value: 'Kauman' },
    { label: 'Mlarak', value: 'Mlarak' },
    { label: 'Ngebel', value: 'Ngebel' },
    { label: 'Ngrayun', value: 'Ngrayun' },
    { label: 'Ponorogo', value: 'Ponorogo' },
    { label: 'Pudak', value: 'Pudak' },
    { label: 'Pulung', value: 'Pulung' },
    { label: 'Sambit', value: 'Sambit' },
    { label: 'Sampung', value: 'Sampung' },
    { label: 'Sawoo', value: 'Sawoo' },
    { label: 'Siman', value: 'Siman' },
    { label: 'Slahung', value: 'Slahung' },
    { label: 'Sooko', value: 'Sooko' },
    { label: 'Sukorejo', value: 'Sukorejo' }
  ],
  Probolinggo: [
    { label: 'Kademangan', value: 'Kademangan' },
    { label: 'Kanigaran', value: 'Kanigaran' },
    { label: 'Kedopok (Kedopak)', value: 'Kedopok (Kedopak)' },
    { label: 'Mayangan', value: 'Mayangan' },
    { label: 'Wonoasih', value: 'Wonoasih' },
    { label: 'Bantaran', value: 'Bantaran' },
    { label: 'Banyu Anyar', value: 'Banyu Anyar' },
    { label: 'Besuk', value: 'Besuk' },
    { label: 'Dringu', value: 'Dringu' },
    { label: 'Gading', value: 'Gading' },
    { label: 'Gending', value: 'Gending' },
    { label: 'Kota Anyar', value: 'Kota Anyar' },
    { label: 'Kraksaan', value: 'Kraksaan' },
    { label: 'Krejengan', value: 'Krejengan' },
    { label: 'Krucil', value: 'Krucil' },
    { label: 'Kuripan', value: 'Kuripan' },
    { label: 'Leces', value: 'Leces' },
    { label: 'Lumbang', value: 'Lumbang' },
    { label: 'Maron', value: 'Maron' },
    { label: 'Paiton', value: 'Paiton' },
    { label: 'Pajarakan', value: 'Pajarakan' },
    { label: 'Pakuniran', value: 'Pakuniran' },
    { label: 'Sukapura', value: 'Sukapura' },
    { label: 'Sumber', value: 'Sumber' },
    { label: 'Sumberasih', value: 'Sumberasih' },
    { label: 'Tegal Siwalan', value: 'Tegal Siwalan' },
    { label: 'Tiris', value: 'Tiris' },
    { label: 'Tongas', value: 'Tongas' },
    { label: 'Wonomerto', value: 'Wonomerto' }
  ],
  Sampang: [
    { label: 'Banyuates', value: 'Banyuates' },
    { label: 'Camplong', value: 'Camplong' },
    { label: 'Jrengik', value: 'Jrengik' },
    { label: 'Karang Penang', value: 'Karang Penang' },
    { label: 'Kedungdung', value: 'Kedungdung' },
    { label: 'Ketapang', value: 'Ketapang' },
    { label: 'Omben', value: 'Omben' },
    { label: 'Pangarengan', value: 'Pangarengan' },
    { label: 'Robatal', value: 'Robatal' },
    { label: 'Sampang', value: 'Sampang' },
    { label: 'Sokobanah', value: 'Sokobanah' },
    { label: 'Sreseh', value: 'Sreseh' },
    { label: 'Tambelangan', value: 'Tambelangan' },
    { label: 'Torjun', value: 'Torjun' }
  ],
  Sidoarjo: [
    { label: 'Balongbendo', value: 'Balongbendo' },
    { label: 'Buduran', value: 'Buduran' },
    { label: 'Candi', value: 'Candi' },
    { label: 'Gedangan', value: 'Gedangan' },
    { label: 'Jabon', value: 'Jabon' },
    { label: 'Krembung', value: 'Krembung' },
    { label: 'Krian', value: 'Krian' },
    { label: 'Porong', value: 'Porong' },
    { label: 'Prambon', value: 'Prambon' },
    { label: 'Sedati', value: 'Sedati' },
    { label: 'Sidoarjo', value: 'Sidoarjo' },
    { label: 'Sukodono', value: 'Sukodono' },
    { label: 'Taman', value: 'Taman' },
    { label: 'Tanggulangin', value: 'Tanggulangin' },
    { label: 'Tarik', value: 'Tarik' },
    { label: 'Tulangan', value: 'Tulangan' },
    { label: 'Waru', value: 'Waru' },
    { label: 'Wonoayu', value: 'Wonoayu' }
  ],
  Situbondo: [
    { label: 'Arjasa', value: 'Arjasa' },
    { label: 'Asembagus', value: 'Asembagus' },
    { label: 'Banyuglugur', value: 'Banyuglugur' },
    { label: 'Banyuputih', value: 'Banyuputih' },
    { label: 'Besuki', value: 'Besuki' },
    { label: 'Bungatan', value: 'Bungatan' },
    { label: 'Jangkar', value: 'Jangkar' },
    { label: 'Jatibanteng', value: 'Jatibanteng' },
    { label: 'Kapongan', value: 'Kapongan' },
    { label: 'Kendit', value: 'Kendit' },
    { label: 'Mangaran', value: 'Mangaran' },
    { label: 'Mlandingan', value: 'Mlandingan' },
    { label: 'Panarukan', value: 'Panarukan' },
    { label: 'Panji', value: 'Panji' },
    { label: 'Situbondo', value: 'Situbondo' },
    { label: 'Suboh', value: 'Suboh' },
    { label: 'Sumbermalang', value: 'Sumbermalang' }
  ],
  Sumenep: [
    { label: 'Ambunten', value: 'Ambunten' },
    { label: 'Arjasa', value: 'Arjasa' },
    { label: 'Batang Batang', value: 'Batang Batang' },
    { label: 'Batuan', value: 'Batuan' },
    { label: 'Batuputih', value: 'Batuputih' },
    { label: 'Bluto', value: 'Bluto' },
    { label: 'Dasuk', value: 'Dasuk' },
    { label: 'Dungkek', value: 'Dungkek' },
    { label: 'Ganding', value: 'Ganding' },
    { label: 'Gapura', value: 'Gapura' },
    { label: 'Gayam', value: 'Gayam' },
    { label: 'Gili Ginting', value: 'Gili Ginting' },
    { label: 'Guluk Guluk', value: 'Guluk Guluk' },
    { label: 'Kalianget', value: 'Kalianget' },
    { label: 'Kangayan', value: 'Kangayan' },
    { label: 'Kota Sumenep', value: 'Kota Sumenep' },
    { label: 'Lenteng', value: 'Lenteng' },
    { label: 'Manding', value: 'Manding' },
    { label: 'Masalembu', value: 'Masalembu' },
    { label: 'Nonggunong', value: 'Nonggunong' },
    { label: 'Pasongsongan', value: 'Pasongsongan' },
    { label: 'Pragaan', value: 'Pragaan' },
    { label: "Ra'as", value: "Ra'as" },
    { label: 'Rubaru', value: 'Rubaru' },
    { label: 'Sapeken', value: 'Sapeken' },
    { label: 'Saronggi', value: 'Saronggi' },
    { label: 'Talango', value: 'Talango' }
  ],
  Surabaya: [
    { label: 'Asemrowo', value: 'Asemrowo' },
    { label: 'Benowo', value: 'Benowo' },
    { label: 'Bubutan', value: 'Bubutan' },
    { label: 'Bulak', value: 'Bulak' },
    { label: 'Dukuh Pakis', value: 'Dukuh Pakis' },
    { label: 'Gayungan', value: 'Gayungan' },
    { label: 'Genteng', value: 'Genteng' },
    { label: 'Gubeng', value: 'Gubeng' },
    { label: 'Gununganyar', value: 'Gununganyar' },
    { label: 'Jambangan', value: 'Jambangan' },
    { label: 'Karangpilang', value: 'Karangpilang' },
    { label: 'Kenjeran', value: 'Kenjeran' },
    { label: 'Krembangan', value: 'Krembangan' },
    { label: 'Lakarsantri', value: 'Lakarsantri' },
    { label: 'Mulyorejo', value: 'Mulyorejo' },
    { label: 'Pabean Cantikan', value: 'Pabean Cantikan' },
    { label: 'Pakal', value: 'Pakal' },
    { label: 'Rungkut', value: 'Rungkut' },
    { label: 'Sambikerep', value: 'Sambikerep' },
    { label: 'Sawahan', value: 'Sawahan' },
    { label: 'Semampir', value: 'Semampir' },
    { label: 'Simokerto', value: 'Simokerto' },
    { label: 'Sukolilo', value: 'Sukolilo' },
    { label: 'Sukomanunggal', value: 'Sukomanunggal' },
    { label: 'Tambaksari', value: 'Tambaksari' },
    { label: 'Tandes', value: 'Tandes' },
    { label: 'Tegalsari', value: 'Tegalsari' },
    { label: 'Tenggilis Mejoyo', value: 'Tenggilis Mejoyo' },
    { label: 'Wiyung', value: 'Wiyung' },
    { label: 'Wonocolo', value: 'Wonocolo' },
    { label: 'Wonokromo', value: 'Wonokromo' }
  ],
  Trenggalek: [
    { label: 'Bendungan', value: 'Bendungan' },
    { label: 'Dongko', value: 'Dongko' },
    { label: 'Durenan', value: 'Durenan' },
    { label: 'Gandusari', value: 'Gandusari' },
    { label: 'Kampak', value: 'Kampak' },
    { label: 'Karangan', value: 'Karangan' },
    { label: 'Munjungan', value: 'Munjungan' },
    { label: 'Panggul', value: 'Panggul' },
    { label: 'Pogalan', value: 'Pogalan' },
    { label: 'Pule', value: 'Pule' },
    { label: 'Suruh', value: 'Suruh' },
    { label: 'Trenggalek', value: 'Trenggalek' },
    { label: 'Tugu', value: 'Tugu' },
    { label: 'Watulimo', value: 'Watulimo' }
  ],
  Tuban: [
    { label: 'Bancar', value: 'Bancar' },
    { label: 'Bangilan', value: 'Bangilan' },
    { label: 'Grabagan', value: 'Grabagan' },
    { label: 'Jatirogo', value: 'Jatirogo' },
    { label: 'Jenu', value: 'Jenu' },
    { label: 'Kenduruan', value: 'Kenduruan' },
    { label: 'Kerek', value: 'Kerek' },
    { label: 'Merakurak', value: 'Merakurak' },
    { label: 'Montong', value: 'Montong' },
    { label: 'Palang', value: 'Palang' },
    { label: 'Parengan', value: 'Parengan' },
    { label: 'Plumpang', value: 'Plumpang' },
    { label: 'Rengel', value: 'Rengel' },
    { label: 'Semanding', value: 'Semanding' },
    { label: 'Senori', value: 'Senori' },
    { label: 'Singgahan', value: 'Singgahan' },
    { label: 'Soko', value: 'Soko' },
    { label: 'Tambakboyo', value: 'Tambakboyo' },
    { label: 'Tuban', value: 'Tuban' },
    { label: 'Widang', value: 'Widang' }
  ],
  Tulungagung: [
    { label: 'Bandung', value: 'Bandung' },
    { label: 'Besuki', value: 'Besuki' },
    { label: 'Boyolangu', value: 'Boyolangu' },
    { label: 'Campur Darat', value: 'Campur Darat' },
    { label: 'Gondang', value: 'Gondang' },
    { label: 'Kalidawir', value: 'Kalidawir' },
    { label: 'Karang Rejo', value: 'Karang Rejo' },
    { label: 'Kauman', value: 'Kauman' },
    { label: 'Kedungwaru', value: 'Kedungwaru' },
    { label: 'Ngantru', value: 'Ngantru' },
    { label: 'Ngunut', value: 'Ngunut' },
    { label: 'Pagerwojo', value: 'Pagerwojo' },
    { label: 'Pakel', value: 'Pakel' },
    { label: 'Pucanglaban', value: 'Pucanglaban' },
    { label: 'Rejotangan', value: 'Rejotangan' },
    { label: 'Sendang', value: 'Sendang' },
    { label: 'Sumbergempol', value: 'Sumbergempol' },
    { label: 'Tanggung Gunung', value: 'Tanggung Gunung' },
    { label: 'Tulungagung', value: 'Tulungagung' }
  ],
  Bengkayang: [
    { label: 'Bengkayang', value: 'Bengkayang' },
    { label: 'Capkala', value: 'Capkala' },
    { label: 'Jagoi Babang', value: 'Jagoi Babang' },
    { label: 'Ledo', value: 'Ledo' },
    { label: 'Lembah Bawang', value: 'Lembah Bawang' },
    { label: 'Lumar', value: 'Lumar' },
    { label: 'Monterado', value: 'Monterado' },
    { label: 'Samalantan', value: 'Samalantan' },
    { label: 'Sanggau Ledo', value: 'Sanggau Ledo' },
    { label: 'Seluas', value: 'Seluas' },
    { label: 'Siding', value: 'Siding' },
    { label: 'Sungai Betung', value: 'Sungai Betung' },
    { label: 'Sungai Raya', value: 'Sungai Raya' },
    { label: 'Sungai Raya Kepulauan', value: 'Sungai Raya Kepulauan' },
    { label: 'Suti Semarang', value: 'Suti Semarang' },
    { label: 'Teriak', value: 'Teriak' },
    { label: 'Tujuh Belas', value: 'Tujuh Belas' }
  ],
  'Kapuas Hulu': [
    { label: 'Badau', value: 'Badau' },
    { label: 'Batang Lupar', value: 'Batang Lupar' },
    { label: 'Bika', value: 'Bika' },
    { label: 'Boyan Tanjung', value: 'Boyan Tanjung' },
    { label: 'Bunut Hilir', value: 'Bunut Hilir' },
    { label: 'Bunut Hulu', value: 'Bunut Hulu' },
    { label: 'Embaloh Hilir', value: 'Embaloh Hilir' },
    { label: 'Embaloh Hulu', value: 'Embaloh Hulu' },
    { label: 'Empanang', value: 'Empanang' },
    { label: 'Hulu Gurung', value: 'Hulu Gurung' },
    { label: 'Jongkong (Jengkong)', value: 'Jongkong (Jengkong)' },
    { label: 'Kalis', value: 'Kalis' },
    { label: 'Mentebah', value: 'Mentebah' },
    { label: 'Pengkadan (Batu Datu)', value: 'Pengkadan (Batu Datu)' },
    { label: 'Puring Kencana', value: 'Puring Kencana' },
    { label: 'Putussibau Selatan', value: 'Putussibau Selatan' },
    { label: 'Putussibau Utara', value: 'Putussibau Utara' },
    { label: 'Seberuang', value: 'Seberuang' },
    { label: 'Selimbau', value: 'Selimbau' },
    { label: 'Semitau', value: 'Semitau' },
    { label: 'Silat Hilir', value: 'Silat Hilir' },
    { label: 'Silat Hulu', value: 'Silat Hulu' },
    { label: 'Suhaid', value: 'Suhaid' }
  ],
  'Kayong Utara': [
    { label: 'Kepulauan Karimata', value: 'Kepulauan Karimata' },
    {
      label: 'Pulau Maya (Pulau Maya Karimata)',
      value: 'Pulau Maya (Pulau Maya Karimata)'
    },
    { label: 'Seponti', value: 'Seponti' },
    { label: 'Simpang Hilir', value: 'Simpang Hilir' },
    { label: 'Sukadana', value: 'Sukadana' },
    { label: 'Teluk Batang', value: 'Teluk Batang' }
  ],
  Ketapang: [
    { label: 'Air Upas', value: 'Air Upas' },
    { label: 'Benua Kayong', value: 'Benua Kayong' },
    { label: 'Delta Pawan', value: 'Delta Pawan' },
    { label: 'Hulu Sungai', value: 'Hulu Sungai' },
    { label: 'Jelai Hulu', value: 'Jelai Hulu' },
    { label: 'Kendawangan', value: 'Kendawangan' },
    { label: 'Manis Mata', value: 'Manis Mata' },
    { label: 'Marau', value: 'Marau' },
    { label: 'Matan Hilir Selatan', value: 'Matan Hilir Selatan' },
    { label: 'Matan Hilir Utara', value: 'Matan Hilir Utara' },
    { label: 'Muara Pawan', value: 'Muara Pawan' },
    { label: 'Nanga Tayap', value: 'Nanga Tayap' },
    { label: 'Pemahan', value: 'Pemahan' },
    { label: 'Sandai', value: 'Sandai' },
    { label: 'Simpang Dua', value: 'Simpang Dua' },
    { label: 'Simpang Hulu', value: 'Simpang Hulu' },
    { label: 'Singkup', value: 'Singkup' },
    { label: 'Sungai Laur', value: 'Sungai Laur' },
    { label: 'Sungai Melayu Rayak', value: 'Sungai Melayu Rayak' },
    { label: 'Tumbang Titi', value: 'Tumbang Titi' }
  ],
  'Kubu Raya': [
    { label: 'Batu Ampar', value: 'Batu Ampar' },
    { label: 'Kuala Mandor B', value: 'Kuala Mandor B' },
    { label: 'Kubu', value: 'Kubu' },
    { label: 'Rasau Jaya', value: 'Rasau Jaya' },
    { label: 'Sungai Ambawang', value: 'Sungai Ambawang' },
    { label: 'Sungai Kakap', value: 'Sungai Kakap' },
    { label: 'Sungai Raya', value: 'Sungai Raya' },
    { label: 'Teluk Pakedai', value: 'Teluk Pakedai' },
    { label: 'Terentang', value: 'Terentang' }
  ],
  Landak: [
    { label: 'Air Besar', value: 'Air Besar' },
    { label: 'Banyuke Hulu', value: 'Banyuke Hulu' },
    { label: 'Jelimpo', value: 'Jelimpo' },
    { label: 'Kuala Behe', value: 'Kuala Behe' },
    { label: 'Mandor', value: 'Mandor' },
    { label: 'Mempawah Hulu', value: 'Mempawah Hulu' },
    { label: 'Menjalin', value: 'Menjalin' },
    { label: 'Menyuke', value: 'Menyuke' },
    { label: 'Meranti', value: 'Meranti' },
    { label: 'Ngabang', value: 'Ngabang' },
    { label: 'Sebangki', value: 'Sebangki' },
    { label: 'Sengah Temila', value: 'Sengah Temila' },
    { label: 'Sompak', value: 'Sompak' }
  ],
  Melawi: [
    { label: 'Belimbing', value: 'Belimbing' },
    { label: 'Belimbing Hulu', value: 'Belimbing Hulu' },
    { label: 'Ella Hilir', value: 'Ella Hilir' },
    { label: 'Menukung', value: 'Menukung' },
    { label: 'Nanga Pinoh', value: 'Nanga Pinoh' },
    { label: 'Pinoh Selatan', value: 'Pinoh Selatan' },
    { label: 'Pinoh Utara', value: 'Pinoh Utara' },
    { label: 'Sayan', value: 'Sayan' },
    { label: 'Sokan', value: 'Sokan' },
    { label: 'Tanah Pinoh', value: 'Tanah Pinoh' },
    { label: 'Tanah Pinoh Barat', value: 'Tanah Pinoh Barat' }
  ],
  Mempawah: [
    { label: 'Anjongan', value: 'Anjongan' },
    { label: 'Mempawah Hilir', value: 'Mempawah Hilir' },
    { label: 'Mempawah Timur', value: 'Mempawah Timur' },
    { label: 'Sadaniang', value: 'Sadaniang' },
    { label: 'Segedong', value: 'Segedong' },
    { label: 'Sei/Sungai Kunyit', value: 'Sei/Sungai Kunyit' },
    { label: 'Sei/Sungai Pinyuh', value: 'Sei/Sungai Pinyuh' },
    { label: 'Siantan', value: 'Siantan' },
    { label: 'Toho', value: 'Toho' }
  ],
  Pontianak: [
    { label: 'Pontianak Barat', value: 'Pontianak Barat' },
    { label: 'Pontianak Kota', value: 'Pontianak Kota' },
    { label: 'Pontianak Selatan', value: 'Pontianak Selatan' },
    { label: 'Pontianak Tenggara', value: 'Pontianak Tenggara' },
    { label: 'Pontianak Timur', value: 'Pontianak Timur' },
    { label: 'Pontianak Utara', value: 'Pontianak Utara' }
  ],
  Sambas: [
    { label: 'Galing', value: 'Galing' },
    { label: 'Jawai', value: 'Jawai' },
    { label: 'Jawai Selatan', value: 'Jawai Selatan' },
    { label: 'Paloh', value: 'Paloh' },
    { label: 'Pemangkat', value: 'Pemangkat' },
    { label: 'Sajad', value: 'Sajad' },
    { label: 'Sajingan Besar', value: 'Sajingan Besar' },
    { label: 'Salatiga', value: 'Salatiga' },
    { label: 'Sambas', value: 'Sambas' },
    { label: 'Sebawi', value: 'Sebawi' },
    { label: 'Sejangkung', value: 'Sejangkung' },
    { label: 'Selakau', value: 'Selakau' },
    { label: 'Selakau Timur', value: 'Selakau Timur' },
    { label: 'Semparuk', value: 'Semparuk' },
    { label: 'Subah', value: 'Subah' },
    { label: 'Tangaran', value: 'Tangaran' },
    { label: 'Tebas', value: 'Tebas' },
    { label: 'Tekarang', value: 'Tekarang' },
    { label: 'Teluk Keramat', value: 'Teluk Keramat' }
  ],
  Sanggau: [
    { label: 'Balai', value: 'Balai' },
    { label: 'Beduai (Beduwai)', value: 'Beduai (Beduwai)' },
    { label: 'Bonti', value: 'Bonti' },
    { label: 'Entikong', value: 'Entikong' },
    { label: 'Jangkang', value: 'Jangkang' },
    { label: 'Kapuas (Sanggau Kapuas)', value: 'Kapuas (Sanggau Kapuas)' },
    { label: 'Kembayan', value: 'Kembayan' },
    { label: 'Meliau', value: 'Meliau' },
    { label: 'Mukok', value: 'Mukok' },
    { label: 'Noyan', value: 'Noyan' },
    { label: 'Parindu', value: 'Parindu' },
    { label: 'Sekayam', value: 'Sekayam' },
    { label: 'Tayan Hilir', value: 'Tayan Hilir' },
    { label: 'Tayan Hulu', value: 'Tayan Hulu' },
    { label: 'Toba', value: 'Toba' }
  ],
  Sekadau: [
    { label: 'Belitang', value: 'Belitang' },
    { label: 'Belitang Hilir', value: 'Belitang Hilir' },
    { label: 'Belitang Hulu', value: 'Belitang Hulu' },
    { label: 'Nanga Mahap', value: 'Nanga Mahap' },
    { label: 'Nanga Taman', value: 'Nanga Taman' },
    { label: 'Sekadau Hilir', value: 'Sekadau Hilir' },
    { label: 'Sekadau Hulu', value: 'Sekadau Hulu' }
  ],
  Singkawang: [
    { label: 'Singkawang Barat', value: 'Singkawang Barat' },
    { label: 'Singkawang Selatan', value: 'Singkawang Selatan' },
    { label: 'Singkawang Tengah', value: 'Singkawang Tengah' },
    { label: 'Singkawang Timur', value: 'Singkawang Timur' },
    { label: 'Singkawang Utara', value: 'Singkawang Utara' }
  ],
  Sintang: [
    { label: 'Ambalau', value: 'Ambalau' },
    { label: 'Binjai Hulu', value: 'Binjai Hulu' },
    { label: 'Dedai', value: 'Dedai' },
    { label: 'Kayan Hilir', value: 'Kayan Hilir' },
    { label: 'Kayan Hulu', value: 'Kayan Hulu' },
    { label: 'Kelam Permai', value: 'Kelam Permai' },
    { label: 'Ketungau Hilir', value: 'Ketungau Hilir' },
    { label: 'Ketungau Hulu', value: 'Ketungau Hulu' },
    { label: 'Ketungau Tengah', value: 'Ketungau Tengah' },
    { label: 'Sepauk', value: 'Sepauk' },
    { label: 'Serawai (Nanga Serawai)', value: 'Serawai (Nanga Serawai)' },
    { label: 'Sintang', value: 'Sintang' },
    { label: 'Sungai Tebelian', value: 'Sungai Tebelian' },
    { label: 'Tempunak', value: 'Tempunak' }
  ],
  Balangan: [
    { label: 'Awayan', value: 'Awayan' },
    { label: 'Batu Mandi', value: 'Batu Mandi' },
    { label: 'Halong', value: 'Halong' },
    { label: 'Juai', value: 'Juai' },
    { label: 'Lampihong', value: 'Lampihong' },
    { label: 'Paringin', value: 'Paringin' },
    { label: 'Paringin Selatan', value: 'Paringin Selatan' },
    { label: 'Tebing Tinggi', value: 'Tebing Tinggi' }
  ],
  Banjarbaru: [
    { label: 'Banjar Baru Selatan', value: 'Banjar Baru Selatan' },
    { label: 'Banjar Baru Utara', value: 'Banjar Baru Utara' },
    { label: 'Cempaka', value: 'Cempaka' },
    { label: 'Landasan Ulin', value: 'Landasan Ulin' },
    { label: 'Liang Anggang', value: 'Liang Anggang' }
  ],
  Banjarmasin: [
    { label: 'Banjarmasin Barat', value: 'Banjarmasin Barat' },
    { label: 'Banjarmasin Selatan', value: 'Banjarmasin Selatan' },
    { label: 'Banjarmasin Tengah', value: 'Banjarmasin Tengah' },
    { label: 'Banjarmasin Timur', value: 'Banjarmasin Timur' },
    { label: 'Banjarmasin Utara', value: 'Banjarmasin Utara' }
  ],
  'Barito Kuala': [
    { label: 'Alalak', value: 'Alalak' },
    { label: 'Anjir Muara', value: 'Anjir Muara' },
    { label: 'Anjir Pasar', value: 'Anjir Pasar' },
    { label: 'Bakumpai', value: 'Bakumpai' },
    { label: 'Barambai', value: 'Barambai' },
    { label: 'Belawang', value: 'Belawang' },
    { label: 'Cerbon', value: 'Cerbon' },
    { label: 'Jejangkit', value: 'Jejangkit' },
    { label: 'Kuripan', value: 'Kuripan' },
    { label: 'Mandastana', value: 'Mandastana' },
    { label: 'Marabahan', value: 'Marabahan' },
    { label: 'Mekar Sari', value: 'Mekar Sari' },
    { label: 'Rantau Badauh', value: 'Rantau Badauh' },
    { label: 'Tabukan', value: 'Tabukan' },
    { label: 'Tabunganen', value: 'Tabunganen' },
    { label: 'Tamban', value: 'Tamban' },
    { label: 'Wanaraya', value: 'Wanaraya' }
  ],
  'Hulu Sungai Selatan': [
    { label: 'Angkinang', value: 'Angkinang' },
    { label: 'Daha Barat', value: 'Daha Barat' },
    { label: 'Daha Selatan', value: 'Daha Selatan' },
    { label: 'Daha Utara', value: 'Daha Utara' },
    { label: 'Kalumpang (Kelumpang)', value: 'Kalumpang (Kelumpang)' },
    { label: 'Kandangan', value: 'Kandangan' },
    { label: 'Loksado', value: 'Loksado' },
    { label: 'Padang Batung', value: 'Padang Batung' },
    { label: 'Simpur', value: 'Simpur' },
    { label: 'Sungai Raya', value: 'Sungai Raya' },
    { label: 'Telaga Langsat', value: 'Telaga Langsat' }
  ],
  'Hulu Sungai Tengah': [
    { label: 'Barabai', value: 'Barabai' },
    { label: 'Batang Alai Selatan', value: 'Batang Alai Selatan' },
    { label: 'Batang Alai Timur', value: 'Batang Alai Timur' },
    { label: 'Batang Alai Utara', value: 'Batang Alai Utara' },
    { label: 'Batu Benawa', value: 'Batu Benawa' },
    { label: 'Hantakan', value: 'Hantakan' },
    { label: 'Haruyan', value: 'Haruyan' },
    { label: 'Labuan Amas Selatan', value: 'Labuan Amas Selatan' },
    { label: 'Labuan Amas Utara', value: 'Labuan Amas Utara' },
    { label: 'Limpasu', value: 'Limpasu' },
    { label: 'Pandawan', value: 'Pandawan' }
  ],
  'Hulu Sungai Utara': [
    { label: 'Amuntai Selatan', value: 'Amuntai Selatan' },
    { label: 'Amuntai Tengah', value: 'Amuntai Tengah' },
    { label: 'Amuntai Utara', value: 'Amuntai Utara' },
    { label: 'Babirik', value: 'Babirik' },
    { label: 'Banjang', value: 'Banjang' },
    { label: 'Danau Panggang', value: 'Danau Panggang' },
    { label: 'Haur Gading', value: 'Haur Gading' },
    { label: 'Paminggir', value: 'Paminggir' },
    { label: 'Sungai Pandan', value: 'Sungai Pandan' },
    { label: 'Sungai Tabukan', value: 'Sungai Tabukan' }
  ],
  Kotabaru: [
    { label: 'Hampang', value: 'Hampang' },
    { label: 'Kelumpang Barat', value: 'Kelumpang Barat' },
    { label: 'Kelumpang Hilir', value: 'Kelumpang Hilir' },
    { label: 'Kelumpang Hulu', value: 'Kelumpang Hulu' },
    { label: 'Kelumpang Selatan', value: 'Kelumpang Selatan' },
    { label: 'Kelumpang Tengah', value: 'Kelumpang Tengah' },
    { label: 'Kelumpang Utara', value: 'Kelumpang Utara' },
    { label: 'Pamukan Barat', value: 'Pamukan Barat' },
    { label: 'Pamukan Selatan', value: 'Pamukan Selatan' },
    { label: 'Pamukan Utara', value: 'Pamukan Utara' },
    { label: 'Pulau Laut Barat', value: 'Pulau Laut Barat' },
    { label: 'Pulau Laut Kepulauan', value: 'Pulau Laut Kepulauan' },
    { label: 'Pulau Laut Selatan', value: 'Pulau Laut Selatan' },
    {
      label: 'Pulau Laut Tanjung Selayar',
      value: 'Pulau Laut Tanjung Selayar'
    },
    { label: 'Pulau Laut Tengah', value: 'Pulau Laut Tengah' },
    { label: 'Pulau Laut Timur', value: 'Pulau Laut Timur' },
    { label: 'Pulau Laut Utara', value: 'Pulau Laut Utara' },
    { label: 'Pulau Sebuku', value: 'Pulau Sebuku' },
    { label: 'Pulau Sembilan', value: 'Pulau Sembilan' },
    { label: 'Sampanahan', value: 'Sampanahan' },
    { label: 'Sungai Durian', value: 'Sungai Durian' }
  ],
  Tabalong: [
    { label: 'Banua Lawas', value: 'Banua Lawas' },
    { label: 'Bintang Ara', value: 'Bintang Ara' },
    { label: 'Haruai', value: 'Haruai' },
    { label: 'Jaro', value: 'Jaro' },
    { label: 'Kelua (Klua)', value: 'Kelua (Klua)' },
    { label: 'Muara Harus', value: 'Muara Harus' },
    { label: 'Muara Uya', value: 'Muara Uya' },
    { label: 'Murung Pudak', value: 'Murung Pudak' },
    { label: 'Pugaan', value: 'Pugaan' },
    { label: 'Tanjung', value: 'Tanjung' },
    { label: 'Tanta', value: 'Tanta' },
    { label: 'Upau', value: 'Upau' }
  ],
  'Tanah Bumbu': [
    { label: 'Angsana', value: 'Angsana' },
    { label: 'Batulicin', value: 'Batulicin' },
    { label: 'Karang Bintang', value: 'Karang Bintang' },
    { label: 'Kuranji', value: 'Kuranji' },
    { label: 'Kusan Hilir', value: 'Kusan Hilir' },
    { label: 'Kusan Hulu', value: 'Kusan Hulu' },
    { label: 'Mantewe', value: 'Mantewe' },
    { label: 'Satui', value: 'Satui' },
    { label: 'Simpang Empat', value: 'Simpang Empat' },
    { label: 'Sungai Loban', value: 'Sungai Loban' }
  ],
  'Tanah Laut': [
    { label: 'Bajuin', value: 'Bajuin' },
    { label: 'Bati-Bati', value: 'Bati-Bati' },
    { label: 'Batu Ampar', value: 'Batu Ampar' },
    { label: 'Bumi Makmur', value: 'Bumi Makmur' },
    { label: 'Jorong', value: 'Jorong' },
    { label: 'Kintap', value: 'Kintap' },
    { label: 'Kurau', value: 'Kurau' },
    { label: 'Panyipatan', value: 'Panyipatan' },
    { label: 'Pelaihari', value: 'Pelaihari' },
    { label: 'Takisung', value: 'Takisung' },
    { label: 'Tambang Ulang', value: 'Tambang Ulang' }
  ],
  Tapin: [
    { label: 'Bakarangan', value: 'Bakarangan' },
    { label: 'Binuang', value: 'Binuang' },
    { label: 'Bungur', value: 'Bungur' },
    { label: 'Candi Laras Selatan', value: 'Candi Laras Selatan' },
    { label: 'Candi Laras Utara', value: 'Candi Laras Utara' },
    { label: 'Hatungun', value: 'Hatungun' },
    { label: 'Lokpaikat', value: 'Lokpaikat' },
    { label: 'Piani', value: 'Piani' },
    { label: 'Salam Babaris', value: 'Salam Babaris' },
    { label: 'Tapin Selatan', value: 'Tapin Selatan' },
    { label: 'Tapin Tengah', value: 'Tapin Tengah' },
    { label: 'Tapin Utara', value: 'Tapin Utara' }
  ],
  'Barito Selatan': [
    { label: 'Dusun Hilir', value: 'Dusun Hilir' },
    { label: 'Dusun Selatan', value: 'Dusun Selatan' },
    { label: 'Dusun Utara', value: 'Dusun Utara' },
    { label: 'Gunung Bintang Awai', value: 'Gunung Bintang Awai' },
    { label: 'Jenamas', value: 'Jenamas' },
    { label: 'Karau Kuala', value: 'Karau Kuala' }
  ],
  'Barito Timur': [
    { label: 'Awang', value: 'Awang' },
    { label: 'Benua Lima', value: 'Benua Lima' },
    { label: 'Dusun Tengah', value: 'Dusun Tengah' },
    { label: 'Dusun Timur', value: 'Dusun Timur' },
    { label: 'Karusen Janang', value: 'Karusen Janang' },
    { label: 'Paju Epat', value: 'Paju Epat' },
    { label: 'Paku', value: 'Paku' },
    { label: 'Patangkep Tutui', value: 'Patangkep Tutui' },
    { label: 'Pematang Karau', value: 'Pematang Karau' },
    { label: 'Raren Batuah', value: 'Raren Batuah' }
  ],
  'Barito Utara': [
    { label: 'Gunung Purei', value: 'Gunung Purei' },
    { label: 'Gunung Timang', value: 'Gunung Timang' },
    { label: 'Lahei', value: 'Lahei' },
    { label: 'Lahei Barat', value: 'Lahei Barat' },
    { label: 'Montallat (Montalat)', value: 'Montallat (Montalat)' },
    { label: 'Teweh Baru', value: 'Teweh Baru' },
    { label: 'Teweh Selatan', value: 'Teweh Selatan' },
    { label: 'Teweh Tengah', value: 'Teweh Tengah' },
    { label: 'Teweh Timur', value: 'Teweh Timur' }
  ],
  'Gunung Mas': [
    { label: 'Damang Batu', value: 'Damang Batu' },
    { label: 'Kahayan Hulu Utara', value: 'Kahayan Hulu Utara' },
    { label: 'Kurun', value: 'Kurun' },
    { label: 'Manuhing', value: 'Manuhing' },
    { label: 'Manuhing Raya', value: 'Manuhing Raya' },
    { label: 'Mihing Raya', value: 'Mihing Raya' },
    { label: 'Miri Manasa', value: 'Miri Manasa' },
    { label: 'Rungan', value: 'Rungan' },
    { label: 'Rungan Barat', value: 'Rungan Barat' },
    { label: 'Rungan Hulu', value: 'Rungan Hulu' },
    { label: 'Sepang (Sepang Simin)', value: 'Sepang (Sepang Simin)' },
    { label: 'Tewah', value: 'Tewah' }
  ],
  Kapuas: [
    { label: 'Basarang', value: 'Basarang' },
    { label: 'Bataguh', value: 'Bataguh' },
    { label: 'Dadahup', value: 'Dadahup' },
    { label: 'Kapuas Barat', value: 'Kapuas Barat' },
    { label: 'Kapuas Hilir', value: 'Kapuas Hilir' },
    { label: 'Kapuas Hulu', value: 'Kapuas Hulu' },
    { label: 'Kapuas Kuala', value: 'Kapuas Kuala' },
    { label: 'Kapuas Murung', value: 'Kapuas Murung' },
    { label: 'Kapuas Tengah', value: 'Kapuas Tengah' },
    { label: 'Kapuas Timur', value: 'Kapuas Timur' },
    { label: 'Mandau Talawang', value: 'Mandau Talawang' },
    { label: 'Mantangai', value: 'Mantangai' },
    { label: 'Pasak Talawang', value: 'Pasak Talawang' },
    { label: 'Pulau Petak', value: 'Pulau Petak' },
    { label: 'Selat', value: 'Selat' },
    { label: 'Tamban Catur', value: 'Tamban Catur' },
    { label: 'Timpah', value: 'Timpah' }
  ],
  Katingan: [
    { label: 'Bukit Raya', value: 'Bukit Raya' },
    { label: 'Kamipang', value: 'Kamipang' },
    { label: 'Katingan Hilir', value: 'Katingan Hilir' },
    { label: 'Katingan Hulu', value: 'Katingan Hulu' },
    { label: 'Katingan Kuala', value: 'Katingan Kuala' },
    { label: 'Katingan Tengah', value: 'Katingan Tengah' },
    { label: 'Marikit', value: 'Marikit' },
    { label: 'Mendawai', value: 'Mendawai' },
    { label: 'Petak Malai', value: 'Petak Malai' },
    { label: 'Pulau Malan', value: 'Pulau Malan' },
    {
      label: 'Sanaman Mantikei (Senamang Mantikei)',
      value: 'Sanaman Mantikei (Senamang Mantikei)'
    },
    { label: 'Tasik Payawan', value: 'Tasik Payawan' },
    {
      label: 'Tewang Sanggalang Garing (Sangalang)',
      value: 'Tewang Sanggalang Garing (Sangalang)'
    }
  ],
  'Kotawaringin Barat': [
    { label: 'Arut Selatan', value: 'Arut Selatan' },
    { label: 'Arut Utara', value: 'Arut Utara' },
    { label: 'Kotawaringin Lama', value: 'Kotawaringin Lama' },
    { label: 'Kumai', value: 'Kumai' },
    { label: 'Pangkalan Banteng', value: 'Pangkalan Banteng' },
    { label: 'Pangkalan Lada', value: 'Pangkalan Lada' }
  ],
  'Kotawaringin Timur': [
    { label: 'Antang Kalang', value: 'Antang Kalang' },
    { label: 'Baamang', value: 'Baamang' },
    { label: 'Bukit Santuei', value: 'Bukit Santuei' },
    { label: 'Cempaga', value: 'Cempaga' },
    { label: 'Cempaga Hulu', value: 'Cempaga Hulu' },
    { label: 'Kota Besi', value: 'Kota Besi' },
    { label: 'Mentawa Baru Ketapang', value: 'Mentawa Baru Ketapang' },
    { label: 'Mentaya Hilir Selatan', value: 'Mentaya Hilir Selatan' },
    { label: 'Mentaya Hilir Utara', value: 'Mentaya Hilir Utara' },
    { label: 'Mentaya Hulu', value: 'Mentaya Hulu' },
    { label: 'Parenggean', value: 'Parenggean' },
    { label: 'Pulau Hanaut', value: 'Pulau Hanaut' },
    { label: 'Seranau', value: 'Seranau' },
    { label: 'Telaga Antang', value: 'Telaga Antang' },
    { label: 'Telawang', value: 'Telawang' },
    { label: 'Teluk Sampit', value: 'Teluk Sampit' },
    { label: 'Tualan Hulu', value: 'Tualan Hulu' }
  ],
  Lamandau: [
    { label: 'Batang Kawa', value: 'Batang Kawa' },
    { label: 'Belantikan Raya', value: 'Belantikan Raya' },
    { label: 'Bulik', value: 'Bulik' },
    { label: 'Bulik Timur', value: 'Bulik Timur' },
    { label: 'Delang', value: 'Delang' },
    { label: 'Lamandau', value: 'Lamandau' },
    { label: 'Menthobi Raya', value: 'Menthobi Raya' },
    { label: 'Sematu Jaya', value: 'Sematu Jaya' }
  ],
  'Murung Raya': [
    { label: 'Barito Tuhup Raya', value: 'Barito Tuhup Raya' },
    { label: 'Laung Tuhup', value: 'Laung Tuhup' },
    { label: 'Murung', value: 'Murung' },
    { label: 'Permata Intan', value: 'Permata Intan' },
    { label: 'Seribu Riam', value: 'Seribu Riam' },
    { label: 'Sumber Barito', value: 'Sumber Barito' },
    { label: 'Sungai Babuat', value: 'Sungai Babuat' },
    { label: 'Tanah Siang', value: 'Tanah Siang' },
    { label: 'Tanah Siang Selatan', value: 'Tanah Siang Selatan' },
    { label: 'Uut Murung', value: 'Uut Murung' }
  ],
  'Palangka Raya': [
    { label: 'Bukit Batu', value: 'Bukit Batu' },
    { label: 'Jekan Raya', value: 'Jekan Raya' },
    { label: 'Pahandut', value: 'Pahandut' },
    { label: 'Rakumpit', value: 'Rakumpit' },
    { label: 'Sebangau', value: 'Sebangau' }
  ],
  'Pulang Pisau': [
    { label: 'Banama Tingang', value: 'Banama Tingang' },
    { label: 'Jabiren Raya', value: 'Jabiren Raya' },
    { label: 'Kahayan Hilir', value: 'Kahayan Hilir' },
    { label: 'Kahayan Kuala', value: 'Kahayan Kuala' },
    { label: 'Kahayan Tengah', value: 'Kahayan Tengah' },
    { label: 'Maliku', value: 'Maliku' },
    { label: 'Pandih Batu', value: 'Pandih Batu' },
    { label: 'Sebangau Kuala', value: 'Sebangau Kuala' }
  ],
  Seruyan: [
    { label: 'Batu Ampar', value: 'Batu Ampar' },
    { label: 'Danau Seluluk', value: 'Danau Seluluk' },
    { label: 'Danau Sembuluh', value: 'Danau Sembuluh' },
    { label: 'Hanau', value: 'Hanau' },
    { label: 'Seruyan Hilir', value: 'Seruyan Hilir' },
    { label: 'Seruyan Hilir Timur', value: 'Seruyan Hilir Timur' },
    { label: 'Seruyan Hulu', value: 'Seruyan Hulu' },
    { label: 'Seruyan Raya', value: 'Seruyan Raya' },
    { label: 'Seruyan Tengah', value: 'Seruyan Tengah' },
    { label: 'Suling Tambun', value: 'Suling Tambun' }
  ],
  Sukamara: [
    { label: 'Balai Riam', value: 'Balai Riam' },
    { label: 'Jelai', value: 'Jelai' },
    { label: 'Pantai Lunci', value: 'Pantai Lunci' },
    { label: 'Permata Kecubung', value: 'Permata Kecubung' },
    { label: 'Sukamara', value: 'Sukamara' }
  ],
  Balikpapan: [
    { label: 'Balikpapan Barat', value: 'Balikpapan Barat' },
    { label: 'Balikpapan Kota', value: 'Balikpapan Kota' },
    { label: 'Balikpapan Selatan', value: 'Balikpapan Selatan' },
    { label: 'Balikpapan Tengah', value: 'Balikpapan Tengah' },
    { label: 'Balikpapan Timur', value: 'Balikpapan Timur' },
    { label: 'Balikpapan Utara', value: 'Balikpapan Utara' }
  ],
  Berau: [
    { label: 'Batu Putih', value: 'Batu Putih' },
    { label: 'Biatan', value: 'Biatan' },
    { label: 'Biduk-Biduk', value: 'Biduk-Biduk' },
    { label: 'Derawan (Pulau Derawan)', value: 'Derawan (Pulau Derawan)' },
    { label: 'Gunung Tabur', value: 'Gunung Tabur' },
    { label: 'Kelay', value: 'Kelay' },
    { label: 'Maratua', value: 'Maratua' },
    { label: 'Sambaliung', value: 'Sambaliung' },
    { label: 'Segah', value: 'Segah' },
    { label: 'Tabalar', value: 'Tabalar' },
    { label: 'Talisayan', value: 'Talisayan' },
    { label: 'Tanjung Redeb', value: 'Tanjung Redeb' },
    { label: 'Teluk Bayur', value: 'Teluk Bayur' }
  ],
  Bontang: [
    { label: 'Bontang Barat', value: 'Bontang Barat' },
    { label: 'Bontang Selatan', value: 'Bontang Selatan' },
    { label: 'Bontang Utara', value: 'Bontang Utara' }
  ],
  'Kutai Barat': [
    { label: 'Barong Tongkok', value: 'Barong Tongkok' },
    { label: 'Bentian Besar', value: 'Bentian Besar' },
    { label: 'Bongan', value: 'Bongan' },
    { label: 'Damai', value: 'Damai' },
    { label: 'Jempang', value: 'Jempang' },
    { label: 'Linggang Bigung', value: 'Linggang Bigung' },
    { label: 'Long Iram', value: 'Long Iram' },
    { label: 'Melak', value: 'Melak' },
    { label: 'Mook Manaar Bulatn', value: 'Mook Manaar Bulatn' },
    { label: 'Muara Lawa', value: 'Muara Lawa' },
    { label: 'Muara Pahu', value: 'Muara Pahu' },
    { label: 'Nyuatan', value: 'Nyuatan' },
    { label: 'Penyinggahan', value: 'Penyinggahan' },
    { label: 'Sekolaq Darat', value: 'Sekolaq Darat' },
    { label: 'Siluq Ngurai', value: 'Siluq Ngurai' },
    { label: 'Tering', value: 'Tering' }
  ],
  'Kutai Kartanegara': [
    { label: 'Anggana', value: 'Anggana' },
    { label: 'Kembang Janggut', value: 'Kembang Janggut' },
    { label: 'Kenohan', value: 'Kenohan' },
    { label: 'Kota Bangun', value: 'Kota Bangun' },
    { label: 'Loa Janan', value: 'Loa Janan' },
    { label: 'Loa Kulu', value: 'Loa Kulu' },
    { label: 'Marang Kayu', value: 'Marang Kayu' },
    { label: 'Muara Badak', value: 'Muara Badak' },
    { label: 'Muara Jawa', value: 'Muara Jawa' },
    { label: 'Muara Kaman', value: 'Muara Kaman' },
    { label: 'Muara Muntai', value: 'Muara Muntai' },
    { label: 'Muara Wis', value: 'Muara Wis' },
    { label: 'Samboja (Semboja)', value: 'Samboja (Semboja)' },
    { label: 'Sanga-Sanga', value: 'Sanga-Sanga' },
    { label: 'Sebulu', value: 'Sebulu' },
    { label: 'Tabang', value: 'Tabang' },
    { label: 'Tenggarong', value: 'Tenggarong' },
    { label: 'Tenggarong Seberang', value: 'Tenggarong Seberang' }
  ],
  'Kutai Timur': [
    { label: 'Batu Ampar', value: 'Batu Ampar' },
    { label: 'Bengalon', value: 'Bengalon' },
    { label: 'Busang', value: 'Busang' },
    { label: 'Kaliorang', value: 'Kaliorang' },
    { label: 'Karangan', value: 'Karangan' },
    { label: 'Kaubun', value: 'Kaubun' },
    { label: 'Kongbeng', value: 'Kongbeng' },
    {
      label: 'Long Mesangat (Mesengat)',
      value: 'Long Mesangat (Mesengat)'
    },
    { label: 'Muara Ancalong', value: 'Muara Ancalong' },
    { label: 'Muara Bengkal', value: 'Muara Bengkal' },
    { label: 'Muara Wahau', value: 'Muara Wahau' },
    { label: 'Rantau Pulung', value: 'Rantau Pulung' },
    { label: 'Sandaran', value: 'Sandaran' },
    { label: 'Sangatta Selatan', value: 'Sangatta Selatan' },
    { label: 'Sangatta Utara', value: 'Sangatta Utara' },
    { label: 'Sangkulirang', value: 'Sangkulirang' },
    { label: 'Telen', value: 'Telen' },
    { label: 'Teluk Pandan', value: 'Teluk Pandan' }
  ],
  'Mahakam Ulu': [
    { label: 'Laham', value: 'Laham' },
    { label: 'Long Apari', value: 'Long Apari' },
    { label: 'Long Bagun', value: 'Long Bagun' },
    { label: 'Long Hubung', value: 'Long Hubung' },
    { label: 'Long Pahangai', value: 'Long Pahangai' }
  ],
  Paser: [
    { label: 'Batu Engau', value: 'Batu Engau' },
    { label: 'Batu Sopang', value: 'Batu Sopang' },
    { label: 'Kuaro', value: 'Kuaro' },
    { label: 'Long Ikis', value: 'Long Ikis' },
    { label: 'Long Kali', value: 'Long Kali' },
    { label: 'Muara Komam', value: 'Muara Komam' },
    { label: 'Muara Samu', value: 'Muara Samu' },
    { label: 'Pasir Belengkong', value: 'Pasir Belengkong' },
    { label: 'Tanah Grogot', value: 'Tanah Grogot' },
    { label: 'Tanjung Harapan', value: 'Tanjung Harapan' }
  ],
  'Penajam Paser Utara': [
    { label: 'Babulu', value: 'Babulu' },
    { label: 'Penajam', value: 'Penajam' },
    { label: 'Sepaku', value: 'Sepaku' },
    { label: 'Waru', value: 'Waru' }
  ],
  Samarinda: [
    { label: 'Loa Janan Ilir', value: 'Loa Janan Ilir' },
    { label: 'Palaran', value: 'Palaran' },
    { label: 'Samarinda Ilir', value: 'Samarinda Ilir' },
    { label: 'Samarinda Kota', value: 'Samarinda Kota' },
    { label: 'Samarinda Seberang', value: 'Samarinda Seberang' },
    { label: 'Samarinda Ulu', value: 'Samarinda Ulu' },
    { label: 'Samarinda Utara', value: 'Samarinda Utara' },
    { label: 'Sambutan', value: 'Sambutan' },
    { label: 'Sungai Kunjang', value: 'Sungai Kunjang' },
    { label: 'Sungai Pinang', value: 'Sungai Pinang' }
  ],
  Bulungan: [
    { label: 'Peso', value: 'Peso' },
    { label: 'Peso Hilir/Ilir', value: 'Peso Hilir/Ilir' },
    { label: 'Pulau Bunyu', value: 'Pulau Bunyu' },
    { label: 'Sekatak', value: 'Sekatak' },
    { label: 'Tanjung Palas', value: 'Tanjung Palas' },
    { label: 'Tanjung Palas Barat', value: 'Tanjung Palas Barat' },
    { label: 'Tanjung Palas Tengah', value: 'Tanjung Palas Tengah' },
    { label: 'Tanjung Palas Timur', value: 'Tanjung Palas Timur' },
    { label: 'Tanjung Palas Utara', value: 'Tanjung Palas Utara' },
    { label: 'Tanjung Selor', value: 'Tanjung Selor' }
  ],
  Malinau: [
    { label: 'Bahau Hulu', value: 'Bahau Hulu' },
    { label: 'Kayan Hilir', value: 'Kayan Hilir' },
    { label: 'Kayan Hulu', value: 'Kayan Hulu' },
    { label: 'Kayan Selatan', value: 'Kayan Selatan' },
    { label: 'Malinau Barat', value: 'Malinau Barat' },
    { label: 'Malinau Kota', value: 'Malinau Kota' },
    { label: 'Malinau Selatan', value: 'Malinau Selatan' },
    { label: 'Malinau Selatan Hilir', value: 'Malinau Selatan Hilir' },
    { label: 'Malinau Selatan Hulu', value: 'Malinau Selatan Hulu' },
    { label: 'Malinau Utara', value: 'Malinau Utara' },
    { label: 'Mentarang', value: 'Mentarang' },
    { label: 'Mentarang Hulu', value: 'Mentarang Hulu' },
    { label: 'Pujungan', value: 'Pujungan' },
    { label: 'Sungai Boh', value: 'Sungai Boh' },
    { label: 'Sungai Tubu', value: 'Sungai Tubu' }
  ],
  Nunukan: [
    { label: 'Krayan', value: 'Krayan' },
    { label: 'Krayan Selatan', value: 'Krayan Selatan' },
    { label: 'Lumbis', value: 'Lumbis' },
    { label: 'Lumbis Ogong', value: 'Lumbis Ogong' },
    { label: 'Nunukan', value: 'Nunukan' },
    { label: 'Nunukan Selatan', value: 'Nunukan Selatan' },
    { label: 'Sebatik', value: 'Sebatik' },
    { label: 'Sebatik Barat', value: 'Sebatik Barat' },
    { label: 'Sebatik Tengah', value: 'Sebatik Tengah' },
    { label: 'Sebatik Timur', value: 'Sebatik Timur' },
    { label: 'Sebatik Utara', value: 'Sebatik Utara' },
    { label: 'Sebuku', value: 'Sebuku' },
    { label: 'Sei Menggaris', value: 'Sei Menggaris' },
    { label: 'Sembakung', value: 'Sembakung' },
    { label: 'Sembakung Atulai', value: 'Sembakung Atulai' },
    { label: 'Tulin Onsoi', value: 'Tulin Onsoi' }
  ],
  'Tana Tidung': [
    { label: 'Betayau', value: 'Betayau' },
    { label: 'Muruk Rian', value: 'Muruk Rian' },
    { label: 'Sesayap', value: 'Sesayap' },
    { label: 'Sesayap Hilir', value: 'Sesayap Hilir' },
    { label: 'Tana Lia', value: 'Tana Lia' }
  ],
  Tarakan: [
    { label: 'Tarakan Barat', value: 'Tarakan Barat' },
    { label: 'Tarakan Tengah', value: 'Tarakan Tengah' },
    { label: 'Tarakan Timur', value: 'Tarakan Timur' },
    { label: 'Tarakan Utara', value: 'Tarakan Utara' }
  ],
  Batam: [
    { label: 'Batam Kota', value: 'Batam Kota' },
    { label: 'Batu Aji', value: 'Batu Aji' },
    { label: 'Batu Ampar', value: 'Batu Ampar' },
    { label: 'Belakang Padang', value: 'Belakang Padang' },
    { label: 'Bengkong', value: 'Bengkong' },
    { label: 'Bulang', value: 'Bulang' },
    { label: 'Galang', value: 'Galang' },
    { label: 'Lubuk Baja', value: 'Lubuk Baja' },
    { label: 'Nongsa', value: 'Nongsa' },
    { label: 'Sagulung', value: 'Sagulung' },
    { label: 'Sei/Sungai Beduk', value: 'Sei/Sungai Beduk' },
    { label: 'Sekupang', value: 'Sekupang' }
  ],
  Bintan: [
    { label: 'Bintan Pesisir', value: 'Bintan Pesisir' },
    { label: 'Bintan Timur', value: 'Bintan Timur' },
    { label: 'Bintan Utara', value: 'Bintan Utara' },
    { label: 'Gunung Kijang', value: 'Gunung Kijang' },
    { label: 'Mantang', value: 'Mantang' },
    { label: 'Seri/Sri Kuala Lobam', value: 'Seri/Sri Kuala Lobam' },
    { label: 'Tambelan', value: 'Tambelan' },
    { label: 'Teluk Bintan', value: 'Teluk Bintan' },
    { label: 'Teluk Sebong', value: 'Teluk Sebong' },
    { label: 'Toapaya', value: 'Toapaya' }
  ],
  Karimun: [
    { label: 'Belat', value: 'Belat' },
    { label: 'Buru', value: 'Buru' },
    { label: 'Durai', value: 'Durai' },
    { label: 'Karimun', value: 'Karimun' },
    { label: 'Kundur', value: 'Kundur' },
    { label: 'Kundur Barat', value: 'Kundur Barat' },
    { label: 'Kundur Utara', value: 'Kundur Utara' },
    { label: 'Meral', value: 'Meral' },
    { label: 'Meral Barat', value: 'Meral Barat' },
    { label: 'Moro', value: 'Moro' },
    { label: 'Tebing', value: 'Tebing' },
    { label: 'Ungar', value: 'Ungar' }
  ],
  'Kepulauan Anambas': [
    { label: 'Jemaja', value: 'Jemaja' },
    { label: 'Jemaja Timur', value: 'Jemaja Timur' },
    { label: 'Palmatak', value: 'Palmatak' },
    { label: 'Siantan', value: 'Siantan' },
    { label: 'Siantan Selatan', value: 'Siantan Selatan' },
    { label: 'Siantan Tengah', value: 'Siantan Tengah' },
    { label: 'Siantan Timur', value: 'Siantan Timur' }
  ],
  Lingga: [
    { label: 'Lingga', value: 'Lingga' },
    { label: 'Lingga Timur', value: 'Lingga Timur' },
    { label: 'Lingga Utara', value: 'Lingga Utara' },
    { label: 'Selayar', value: 'Selayar' },
    { label: 'Senayang', value: 'Senayang' },
    { label: 'Singkep', value: 'Singkep' },
    { label: 'Singkep Barat', value: 'Singkep Barat' },
    { label: 'Singkep Pesisir', value: 'Singkep Pesisir' },
    { label: 'Singkep Selatan', value: 'Singkep Selatan' }
  ],
  Natuna: [
    { label: 'Bunguran Barat', value: 'Bunguran Barat' },
    { label: 'Bunguran Selatan', value: 'Bunguran Selatan' },
    { label: 'Bunguran Tengah', value: 'Bunguran Tengah' },
    { label: 'Bunguran Timur', value: 'Bunguran Timur' },
    { label: 'Bunguran Timur Laut', value: 'Bunguran Timur Laut' },
    { label: 'Bunguran Utara', value: 'Bunguran Utara' },
    { label: 'Midai', value: 'Midai' },
    { label: 'Pulau Laut', value: 'Pulau Laut' },
    { label: 'Pulau Tiga', value: 'Pulau Tiga' },
    { label: 'Serasan', value: 'Serasan' },
    { label: 'Serasan Timur', value: 'Serasan Timur' },
    { label: 'Subi', value: 'Subi' }
  ],
  'Tanjung Pinang': [
    { label: 'Bukit Bestari', value: 'Bukit Bestari' },
    { label: 'Tanjung Pinang Barat', value: 'Tanjung Pinang Barat' },
    { label: 'Tanjung Pinang Kota', value: 'Tanjung Pinang Kota' },
    { label: 'Tanjung Pinang Timur', value: 'Tanjung Pinang Timur' }
  ],
  'Bandar Lampung': [
    { label: 'Bumi Waras', value: 'Bumi Waras' },
    { label: 'Enggal', value: 'Enggal' },
    { label: 'Kedamaian', value: 'Kedamaian' },
    { label: 'Kedaton', value: 'Kedaton' },
    { label: 'Kemiling', value: 'Kemiling' },
    { label: 'Labuhan Ratu', value: 'Labuhan Ratu' },
    { label: 'Langkapura', value: 'Langkapura' },
    { label: 'Panjang', value: 'Panjang' },
    { label: 'Rajabasa', value: 'Rajabasa' },
    { label: 'Sukabumi', value: 'Sukabumi' },
    { label: 'Sukarame', value: 'Sukarame' },
    { label: 'Tanjung Karang Barat', value: 'Tanjung Karang Barat' },
    { label: 'Tanjung Karang Pusat', value: 'Tanjung Karang Pusat' },
    { label: 'Tanjung Karang Timur', value: 'Tanjung Karang Timur' },
    { label: 'Tanjung Senang', value: 'Tanjung Senang' },
    { label: 'Telukbetung Barat', value: 'Telukbetung Barat' },
    { label: 'Telukbetung Selatan', value: 'Telukbetung Selatan' },
    { label: 'Telukbetung Timur', value: 'Telukbetung Timur' },
    { label: 'Telukbetung Utara', value: 'Telukbetung Utara' },
    { label: 'Way Halim', value: 'Way Halim' }
  ],
  'Lampung Barat': [
    { label: 'Air Hitam', value: 'Air Hitam' },
    { label: 'Balik Bukit', value: 'Balik Bukit' },
    { label: 'Bandar Negeri Suoh', value: 'Bandar Negeri Suoh' },
    { label: 'Batu Brak', value: 'Batu Brak' },
    { label: 'Batu Ketulis', value: 'Batu Ketulis' },
    { label: 'Belalau', value: 'Belalau' },
    { label: 'Gedung Surian', value: 'Gedung Surian' },
    { label: 'Kebun Tebu', value: 'Kebun Tebu' },
    { label: 'Lumbok Seminung', value: 'Lumbok Seminung' },
    { label: 'Pagar Dewa', value: 'Pagar Dewa' },
    { label: 'Sekincau', value: 'Sekincau' },
    { label: 'Sukau', value: 'Sukau' },
    { label: 'Sumber Jaya', value: 'Sumber Jaya' },
    { label: 'Suoh', value: 'Suoh' },
    { label: 'Way Tenong', value: 'Way Tenong' }
  ],
  'Lampung Selatan': [
    { label: 'Bakauheni', value: 'Bakauheni' },
    { label: 'Candipuro', value: 'Candipuro' },
    { label: 'Jati Agung', value: 'Jati Agung' },
    { label: 'Kalianda', value: 'Kalianda' },
    { label: 'Katibung', value: 'Katibung' },
    { label: 'Ketapang', value: 'Ketapang' },
    { label: 'Merbau Mataram', value: 'Merbau Mataram' },
    { label: 'Natar', value: 'Natar' },
    { label: 'Palas', value: 'Palas' },
    { label: 'Penengahan', value: 'Penengahan' },
    { label: 'Rajabasa', value: 'Rajabasa' },
    { label: 'Sidomulyo', value: 'Sidomulyo' },
    { label: 'Sragi', value: 'Sragi' },
    { label: 'Tanjung Bintang', value: 'Tanjung Bintang' },
    { label: 'Tanjung Sari', value: 'Tanjung Sari' },
    { label: 'Way Panji', value: 'Way Panji' },
    { label: 'Way Sulan', value: 'Way Sulan' }
  ],
  'Lampung Tengah': [
    { label: 'Anak Ratu Aji', value: 'Anak Ratu Aji' },
    { label: 'Anak Tuha', value: 'Anak Tuha' },
    { label: 'Bandar Mataram', value: 'Bandar Mataram' },
    { label: 'Bandar Surabaya', value: 'Bandar Surabaya' },
    { label: 'Bangun Rejo', value: 'Bangun Rejo' },
    { label: 'Bekri', value: 'Bekri' },
    { label: 'Bumi Nabung', value: 'Bumi Nabung' },
    { label: 'Bumi Ratu Nuban', value: 'Bumi Ratu Nuban' },
    { label: 'Gunung Sugih', value: 'Gunung Sugih' },
    { label: 'Kalirejo', value: 'Kalirejo' },
    { label: 'Kota Gajah', value: 'Kota Gajah' },
    { label: 'Padang Ratu', value: 'Padang Ratu' },
    { label: 'Pubian', value: 'Pubian' },
    { label: 'Punggur', value: 'Punggur' },
    { label: 'Putra Rumbia', value: 'Putra Rumbia' },
    { label: 'Rumbia', value: 'Rumbia' },
    { label: 'Selagai Lingga', value: 'Selagai Lingga' },
    { label: 'Sendang Agung', value: 'Sendang Agung' },
    { label: 'Seputih Agung', value: 'Seputih Agung' },
    { label: 'Seputih Banyak', value: 'Seputih Banyak' },
    { label: 'Seputih Mataram', value: 'Seputih Mataram' },
    { label: 'Seputih Raman', value: 'Seputih Raman' },
    { label: 'Seputih Surabaya', value: 'Seputih Surabaya' },
    { label: 'Terbanggi Besar', value: 'Terbanggi Besar' },
    { label: 'Terusan Nunyai', value: 'Terusan Nunyai' },
    { label: 'Trimurjo', value: 'Trimurjo' },
    { label: 'Way Pangubuan', value: 'Way Pangubuan' },
    { label: 'Way Seputih', value: 'Way Seputih' }
  ],
  'Lampung Timur': [
    { label: 'Bandar Sribawono', value: 'Bandar Sribawono' },
    { label: 'Batanghari', value: 'Batanghari' },
    { label: 'Batanghari Nuban', value: 'Batanghari Nuban' },
    { label: 'Braja Slebah', value: 'Braja Slebah' },
    { label: 'Bumi Agung', value: 'Bumi Agung' },
    { label: 'Gunung Pelindung', value: 'Gunung Pelindung' },
    { label: 'Jabung', value: 'Jabung' },
    { label: 'Labuhan Maringgai', value: 'Labuhan Maringgai' },
    { label: 'Labuhan Ratu', value: 'Labuhan Ratu' },
    { label: 'Marga Sekampung', value: 'Marga Sekampung' },
    { label: 'Margatiga', value: 'Margatiga' },
    { label: 'Mataram Baru', value: 'Mataram Baru' },
    { label: 'Melinting', value: 'Melinting' },
    { label: 'Metro Kibang', value: 'Metro Kibang' },
    { label: 'Pasir Sakti', value: 'Pasir Sakti' },
    { label: 'Pekalongan', value: 'Pekalongan' },
    { label: 'Purbolinggo', value: 'Purbolinggo' },
    { label: 'Raman Utara', value: 'Raman Utara' },
    { label: 'Sekampung', value: 'Sekampung' },
    { label: 'Sekampung Udik', value: 'Sekampung Udik' },
    { label: 'Sukadana', value: 'Sukadana' },
    { label: 'Waway Karya', value: 'Waway Karya' },
    {
      label: 'Way Bungur (Purbolinggo Utara)',
      value: 'Way Bungur (Purbolinggo Utara)'
    },
    { label: 'Way Jepara', value: 'Way Jepara' }
  ],
  'Lampung Utara': [
    { label: 'Abung Barat', value: 'Abung Barat' },
    { label: 'Abung Kunang', value: 'Abung Kunang' },
    { label: 'Abung Pekurun', value: 'Abung Pekurun' },
    { label: 'Abung Selatan', value: 'Abung Selatan' },
    { label: 'Abung Semuli', value: 'Abung Semuli' },
    { label: 'Abung Surakarta', value: 'Abung Surakarta' },
    { label: 'Abung Tengah', value: 'Abung Tengah' },
    { label: 'Abung Timur', value: 'Abung Timur' },
    { label: 'Abung Tinggi', value: 'Abung Tinggi' },
    { label: 'Blambangan Pagar', value: 'Blambangan Pagar' },
    { label: 'Bukit Kemuning', value: 'Bukit Kemuning' },
    { label: 'Bunga Mayang', value: 'Bunga Mayang' },
    { label: 'Hulu Sungkai', value: 'Hulu Sungkai' },
    { label: 'Kotabumi', value: 'Kotabumi' },
    { label: 'Kotabumi Selatan', value: 'Kotabumi Selatan' },
    { label: 'Kotabumi Utara', value: 'Kotabumi Utara' },
    { label: 'Muara Sungkai', value: 'Muara Sungkai' },
    { label: 'Sungkai Barat', value: 'Sungkai Barat' },
    { label: 'Sungkai Jaya', value: 'Sungkai Jaya' },
    { label: 'Sungkai Selatan', value: 'Sungkai Selatan' },
    { label: 'Sungkai Tengah', value: 'Sungkai Tengah' },
    { label: 'Sungkai Utara', value: 'Sungkai Utara' },
    { label: 'Tanjung Raja', value: 'Tanjung Raja' }
  ],
  Mesuji: [
    { label: 'Mesuji', value: 'Mesuji' },
    { label: 'Mesuji Timur', value: 'Mesuji Timur' },
    { label: 'Panca Jaya', value: 'Panca Jaya' },
    { label: 'Rawa Jitu Utara', value: 'Rawa Jitu Utara' },
    { label: 'Simpang Pematang', value: 'Simpang Pematang' },
    { label: 'Tanjung Raya', value: 'Tanjung Raya' },
    { label: 'Way Serdang', value: 'Way Serdang' }
  ],
  Metro: [
    { label: 'Metro Barat', value: 'Metro Barat' },
    { label: 'Metro Pusat', value: 'Metro Pusat' },
    { label: 'Metro Selatan', value: 'Metro Selatan' },
    { label: 'Metro Timur', value: 'Metro Timur' },
    { label: 'Metro Utara', value: 'Metro Utara' }
  ],
  Pesawaran: [
    { label: 'Gedong Tataan', value: 'Gedong Tataan' },
    { label: 'Kedondong', value: 'Kedondong' },
    { label: 'Marga Punduh', value: 'Marga Punduh' },
    { label: 'Negeri Katon', value: 'Negeri Katon' },
    { label: 'Padang Cermin', value: 'Padang Cermin' },
    { label: 'Punduh Pidada', value: 'Punduh Pidada' },
    { label: 'Tegineneng', value: 'Tegineneng' },
    { label: 'Way Khilau', value: 'Way Khilau' },
    { label: 'Way Lima', value: 'Way Lima' }
  ],
  'Pesisir Barat': [
    { label: 'Bengkunat', value: 'Bengkunat' },
    { label: 'Bengkunat Belimbing', value: 'Bengkunat Belimbing' },
    { label: 'Karya Penggawa', value: 'Karya Penggawa' },
    { label: 'Krui Selatan', value: 'Krui Selatan' },
    { label: 'Lemong', value: 'Lemong' },
    { label: 'Ngambur', value: 'Ngambur' },
    { label: 'Pesisir Selatan', value: 'Pesisir Selatan' },
    { label: 'Pesisir Tengah', value: 'Pesisir Tengah' },
    { label: 'Pesisir Utara', value: 'Pesisir Utara' },
    { label: 'Pulau Pisang', value: 'Pulau Pisang' },
    { label: 'Way Krui', value: 'Way Krui' }
  ],
  Pringsewu: [
    { label: 'Adi Luwih', value: 'Adi Luwih' },
    { label: 'Ambarawa', value: 'Ambarawa' },
    { label: 'Banyumas', value: 'Banyumas' },
    { label: 'Gading Rejo', value: 'Gading Rejo' },
    { label: 'Pagelaran', value: 'Pagelaran' },
    { label: 'Pagelaran Utara', value: 'Pagelaran Utara' },
    { label: 'Pardasuka', value: 'Pardasuka' },
    { label: 'Pringsewu', value: 'Pringsewu' },
    { label: 'Sukoharjo', value: 'Sukoharjo' }
  ],
  Tanggamus: [
    { label: 'Air Naningan', value: 'Air Naningan' },
    { label: 'Bandar Negeri Semuong', value: 'Bandar Negeri Semuong' },
    { label: 'Bulok', value: 'Bulok' },
    { label: 'Cukuh Balak', value: 'Cukuh Balak' },
    { label: 'Gisting', value: 'Gisting' },
    { label: 'Gunung Alip', value: 'Gunung Alip' },
    { label: 'Kelumbayan', value: 'Kelumbayan' },
    { label: 'Kelumbayan Barat', value: 'Kelumbayan Barat' },
    {
      label: 'Kota Agung (Kota Agung Pusat)',
      value: 'Kota Agung (Kota Agung Pusat)'
    },
    { label: 'Kota Agung Barat', value: 'Kota Agung Barat' },
    { label: 'Kota Agung Timur', value: 'Kota Agung Timur' },
    { label: 'Limau', value: 'Limau' },
    { label: 'Pematang Sawa', value: 'Pematang Sawa' },
    { label: 'Pugung', value: 'Pugung' },
    { label: 'Pulau Panggung', value: 'Pulau Panggung' },
    { label: 'Semaka', value: 'Semaka' },
    { label: 'Sumberejo', value: 'Sumberejo' },
    { label: 'Talang Padang', value: 'Talang Padang' },
    { label: 'Ulubelu', value: 'Ulubelu' },
    { label: 'Wonosobo', value: 'Wonosobo' }
  ],
  'Tulang Bawang': [
    { label: 'Banjar Agung', value: 'Banjar Agung' },
    { label: 'Banjar Baru', value: 'Banjar Baru' },
    { label: 'Banjar Margo', value: 'Banjar Margo' },
    { label: 'Dente Teladas', value: 'Dente Teladas' },
    { label: 'Gedung Aji', value: 'Gedung Aji' },
    { label: 'Gedung Aji Baru', value: 'Gedung Aji Baru' },
    { label: 'Gedung Meneng', value: 'Gedung Meneng' },
    { label: 'Menggala', value: 'Menggala' },
    { label: 'Menggala Timur', value: 'Menggala Timur' },
    { label: 'Meraksa Aji', value: 'Meraksa Aji' },
    { label: 'Penawar Aji', value: 'Penawar Aji' },
    { label: 'Penawar Tama', value: 'Penawar Tama' },
    { label: 'Rawa Pitu', value: 'Rawa Pitu' },
    { label: 'Rawajitu Selatan', value: 'Rawajitu Selatan' },
    { label: 'Rawajitu Timur', value: 'Rawajitu Timur' }
  ],
  'Tulang Bawang Barat': [
    { label: 'Gunung Agung', value: 'Gunung Agung' },
    { label: 'Gunung Terang', value: 'Gunung Terang' },
    { label: 'Lambu Kibang', value: 'Lambu Kibang' },
    { label: 'Pagar Dewa', value: 'Pagar Dewa' },
    { label: 'Tulang Bawang Tengah', value: 'Tulang Bawang Tengah' },
    { label: 'Tulang Bawang Udik', value: 'Tulang Bawang Udik' },
    { label: 'Tumijajar', value: 'Tumijajar' },
    { label: 'Way Kenanga', value: 'Way Kenanga' }
  ],
  'Way Kanan': [
    { label: 'Bahuga', value: 'Bahuga' },
    { label: 'Banjit', value: 'Banjit' },
    { label: 'Baradatu', value: 'Baradatu' },
    { label: 'Blambangan Umpu', value: 'Blambangan Umpu' },
    { label: 'Buay Bahuga', value: 'Buay Bahuga' },
    { label: 'Bumi Agung', value: 'Bumi Agung' },
    { label: 'Gunung Labuhan', value: 'Gunung Labuhan' },
    { label: 'Kasui', value: 'Kasui' },
    { label: 'Negara Batin', value: 'Negara Batin' },
    { label: 'Negeri Agung', value: 'Negeri Agung' },
    { label: 'Negeri Besar', value: 'Negeri Besar' },
    { label: 'Pakuan Ratu', value: 'Pakuan Ratu' },
    { label: 'Rebang Tangkas', value: 'Rebang Tangkas' },
    { label: 'Way Tuba', value: 'Way Tuba' }
  ],
  Ambon: [
    { label: 'Baguala', value: 'Baguala' },
    { label: 'Leitimur Selatan', value: 'Leitimur Selatan' },
    { label: 'Nusaniwe (Nusanive)', value: 'Nusaniwe (Nusanive)' },
    { label: 'Sirimau', value: 'Sirimau' },
    { label: 'Teluk Ambon', value: 'Teluk Ambon' }
  ],
  Buru: [
    { label: 'Airbuaya', value: 'Airbuaya' },
    { label: 'Batabual', value: 'Batabual' },
    { label: 'Fena Leisela', value: 'Fena Leisela' },
    { label: 'Lilialy', value: 'Lilialy' },
    { label: 'Lolong Guba', value: 'Lolong Guba' },
    { label: 'Namlea', value: 'Namlea' },
    { label: 'Teluk Kaiely', value: 'Teluk Kaiely' },
    { label: 'Waeapo', value: 'Waeapo' },
    { label: 'Waelata', value: 'Waelata' },
    { label: 'Waplau', value: 'Waplau' }
  ],
  'Buru Selatan': [
    { label: 'Ambalau', value: 'Ambalau' },
    { label: 'Fena Fafan', value: 'Fena Fafan' },
    { label: 'Kepala Madan', value: 'Kepala Madan' },
    { label: 'Leksula', value: 'Leksula' },
    { label: 'Namrole', value: 'Namrole' },
    { label: 'Waesama', value: 'Waesama' }
  ],
  'Kepulauan Aru': [
    { label: 'Aru Selatan', value: 'Aru Selatan' },
    { label: 'Aru Selatan Timur', value: 'Aru Selatan Timur' },
    { label: 'Aru Selatan Utara', value: 'Aru Selatan Utara' },
    { label: 'Aru Tengah', value: 'Aru Tengah' },
    { label: 'Aru Tengah Selatan', value: 'Aru Tengah Selatan' },
    { label: 'Aru Tengah Timur', value: 'Aru Tengah Timur' },
    { label: 'Aru Utara', value: 'Aru Utara' },
    { label: 'Aru Utara Timur Batuley', value: 'Aru Utara Timur Batuley' },
    { label: 'Pulau-Pulau Aru', value: 'Pulau-Pulau Aru' },
    { label: 'Sir-Sir', value: 'Sir-Sir' }
  ],
  'Maluku Barat Daya': [
    { label: 'Damer', value: 'Damer' },
    { label: 'Dawelor Dawera', value: 'Dawelor Dawera' },
    { label: 'Kepulauan Romang', value: 'Kepulauan Romang' },
    { label: 'Kisar Utara', value: 'Kisar Utara' },
    { label: 'Mdona Hyera/Hiera', value: 'Mdona Hyera/Hiera' },
    { label: 'Moa Lakor', value: 'Moa Lakor' },
    { label: 'Pulau Lakor', value: 'Pulau Lakor' },
    {
      label: 'Pulau Letti (Leti Moa Lakor)',
      value: 'Pulau Letti (Leti Moa Lakor)'
    },
    { label: 'Pulau Masela', value: 'Pulau Masela' },
    { label: 'Pulau Pulau Babar', value: 'Pulau Pulau Babar' },
    { label: 'Pulau Pulau Terselatan', value: 'Pulau Pulau Terselatan' },
    { label: 'Pulau Wetang', value: 'Pulau Wetang' },
    { label: 'Pulau-Pulau Babar Timur', value: 'Pulau-Pulau Babar Timur' },
    { label: 'Wetar', value: 'Wetar' },
    { label: 'Wetar Barat', value: 'Wetar Barat' },
    { label: 'Wetar Timur', value: 'Wetar Timur' },
    { label: 'Wetar Utara', value: 'Wetar Utara' }
  ],
  'Maluku Tengah': [
    { label: 'Amahai', value: 'Amahai' },
    { label: 'Banda', value: 'Banda' },
    { label: 'Kota Masohi', value: 'Kota Masohi' },
    { label: 'Leihitu', value: 'Leihitu' },
    { label: 'Leihitu Barat', value: 'Leihitu Barat' },
    { label: 'Nusa Laut', value: 'Nusa Laut' },
    { label: 'Pulau Haruku', value: 'Pulau Haruku' },
    { label: 'Salahutu', value: 'Salahutu' },
    { label: 'Saparua', value: 'Saparua' },
    { label: 'Saparua Timur', value: 'Saparua Timur' },
    { label: 'Seram Utara', value: 'Seram Utara' },
    { label: 'Seram Utara Barat', value: 'Seram Utara Barat' },
    { label: 'Seram Utara Timur Kobi', value: 'Seram Utara Timur Kobi' },
    { label: 'Seram Utara Timur Seti', value: 'Seram Utara Timur Seti' },
    { label: 'Tehoru', value: 'Tehoru' },
    { label: 'Teluk Elpaputih', value: 'Teluk Elpaputih' },
    { label: 'Telutih', value: 'Telutih' },
    { label: 'Teon Nila Serua', value: 'Teon Nila Serua' }
  ],
  'Maluku Tenggara': [
    { label: 'Hoat Sorbay', value: 'Hoat Sorbay' },
    { label: 'Kei Besar', value: 'Kei Besar' },
    { label: 'Kei Besar Selatan', value: 'Kei Besar Selatan' },
    { label: 'Kei Besar Selatan Barat', value: 'Kei Besar Selatan Barat' },
    { label: 'Kei Besar Utara Barat', value: 'Kei Besar Utara Barat' },
    { label: 'Kei Besar Utara Timur', value: 'Kei Besar Utara Timur' },
    { label: 'Kei Kecil', value: 'Kei Kecil' },
    { label: 'Kei Kecil Barat', value: 'Kei Kecil Barat' },
    { label: 'Kei Kecil Timur', value: 'Kei Kecil Timur' },
    { label: 'Kei Kecil Timur Selatan', value: 'Kei Kecil Timur Selatan' },
    { label: 'Manyeuw', value: 'Manyeuw' }
  ],
  'Maluku Tenggara Barat': [
    { label: 'Kormomolin', value: 'Kormomolin' },
    { label: 'Molu Maru', value: 'Molu Maru' },
    { label: 'Nirunmas', value: 'Nirunmas' },
    { label: 'Selaru', value: 'Selaru' },
    { label: 'Tanimbar Selatan', value: 'Tanimbar Selatan' },
    { label: 'Tanimbar Utara', value: 'Tanimbar Utara' },
    { label: 'Wer Maktian', value: 'Wer Maktian' },
    { label: 'Wer Tamrian', value: 'Wer Tamrian' },
    { label: 'Wuar Labobar', value: 'Wuar Labobar' },
    { label: 'Yaru', value: 'Yaru' }
  ],
  'Seram Bagian Barat': [
    { label: 'Amalatu', value: 'Amalatu' },
    { label: 'Elpaputih', value: 'Elpaputih' },
    { label: 'Huamual', value: 'Huamual' },
    { label: 'Huamual Belakang', value: 'Huamual Belakang' },
    { label: 'Inamosol', value: 'Inamosol' },
    { label: 'Kairatu', value: 'Kairatu' },
    { label: 'Kairatu Barat', value: 'Kairatu Barat' },
    { label: 'Kepulauan Manipa', value: 'Kepulauan Manipa' },
    { label: 'Seram Barat', value: 'Seram Barat' },
    { label: 'Taniwel', value: 'Taniwel' },
    { label: 'Taniwel Timur', value: 'Taniwel Timur' }
  ],
  'Seram Bagian Timur': [
    { label: 'Bula', value: 'Bula' },
    { label: 'Bula Barat', value: 'Bula Barat' },
    { label: 'Gorom Timur', value: 'Gorom Timur' },
    { label: 'Kian Darat', value: 'Kian Darat' },
    { label: 'Kilmury', value: 'Kilmury' },
    { label: 'Pulau Gorom', value: 'Pulau Gorom' },
    { label: 'Pulau Panjang', value: 'Pulau Panjang' },
    { label: 'Seram Timur', value: 'Seram Timur' },
    { label: 'Siritaun Wida Timur', value: 'Siritaun Wida Timur' },
    { label: 'Siwalalat', value: 'Siwalalat' },
    { label: 'Teluk Waru', value: 'Teluk Waru' },
    { label: 'Teor', value: 'Teor' },
    { label: 'Tutuk Tolu', value: 'Tutuk Tolu' },
    { label: 'Wakate', value: 'Wakate' },
    { label: 'Werinama', value: 'Werinama' }
  ],
  Tual: [
    { label: 'Kur Selatan', value: 'Kur Selatan' },
    { label: 'Pulau Dullah Selatan', value: 'Pulau Dullah Selatan' },
    { label: 'Pulau Dullah Utara', value: 'Pulau Dullah Utara' },
    { label: 'Pulau Tayando Tam', value: 'Pulau Tayando Tam' },
    { label: 'Pulau-Pulau Kur', value: 'Pulau-Pulau Kur' }
  ],
  'Halmahera Barat': [
    { label: 'Ibu', value: 'Ibu' },
    { label: 'Ibu Selatan', value: 'Ibu Selatan' },
    { label: 'Ibu Utara', value: 'Ibu Utara' },
    { label: 'Jailolo', value: 'Jailolo' },
    { label: 'Jailolo Selatan', value: 'Jailolo Selatan' },
    { label: 'Loloda', value: 'Loloda' },
    { label: 'Sahu', value: 'Sahu' },
    { label: 'Sahu Timur', value: 'Sahu Timur' }
  ],
  'Halmahera Selatan': [
    { label: 'Bacan', value: 'Bacan' },
    { label: 'Bacan Barat', value: 'Bacan Barat' },
    { label: 'Bacan Barat Utara', value: 'Bacan Barat Utara' },
    { label: 'Bacan Selatan', value: 'Bacan Selatan' },
    { label: 'Bacan Timur', value: 'Bacan Timur' },
    { label: 'Bacan Timur Selatan', value: 'Bacan Timur Selatan' },
    { label: 'Bacan Timur Tengah', value: 'Bacan Timur Tengah' },
    { label: 'Gane Barat', value: 'Gane Barat' },
    { label: 'Gane Barat Selatan', value: 'Gane Barat Selatan' },
    { label: 'Gane Barat Utara', value: 'Gane Barat Utara' },
    { label: 'Gane Timur', value: 'Gane Timur' },
    { label: 'Gane Timur Selatan', value: 'Gane Timur Selatan' },
    { label: 'Gane Timur Tengah', value: 'Gane Timur Tengah' },
    { label: 'Kasiruta Barat', value: 'Kasiruta Barat' },
    { label: 'Kasiruta Timur', value: 'Kasiruta Timur' },
    { label: 'Kayoa', value: 'Kayoa' },
    { label: 'Kayoa Barat', value: 'Kayoa Barat' },
    { label: 'Kayoa Selatan', value: 'Kayoa Selatan' },
    { label: 'Kayoa Utara', value: 'Kayoa Utara' },
    { label: 'Kepulauan Botanglomang', value: 'Kepulauan Botanglomang' },
    { label: 'Kepulauan Joronga', value: 'Kepulauan Joronga' },
    {
      label: 'Makian Barat (Pulau Makian)',
      value: 'Makian Barat (Pulau Makian)'
    },
    { label: 'Mandioli Selatan', value: 'Mandioli Selatan' },
    { label: 'Mandioli Utara', value: 'Mandioli Utara' },
    { label: 'Obi', value: 'Obi' },
    { label: 'Obi Barat', value: 'Obi Barat' },
    { label: 'Obi Selatan', value: 'Obi Selatan' },
    { label: 'Obi Timur', value: 'Obi Timur' },
    { label: 'Obi Utara', value: 'Obi Utara' },
    { label: 'Pulau Makian', value: 'Pulau Makian' }
  ],
  'Halmahera Tengah': [
    { label: 'Patani', value: 'Patani' },
    { label: 'Patani Barat', value: 'Patani Barat' },
    { label: 'Patani Utara', value: 'Patani Utara' },
    { label: 'Pulau Gebe', value: 'Pulau Gebe' },
    { label: 'Weda', value: 'Weda' },
    { label: 'Weda Selatan', value: 'Weda Selatan' },
    { label: 'Weda Tengah', value: 'Weda Tengah' },
    { label: 'Weda Utara', value: 'Weda Utara' }
  ],
  'Halmahera Timur': [
    { label: 'Kota Maba', value: 'Kota Maba' },
    { label: 'Maba', value: 'Maba' },
    { label: 'Maba Selatan', value: 'Maba Selatan' },
    { label: 'Maba Tengah', value: 'Maba Tengah' },
    { label: 'Maba Utara', value: 'Maba Utara' },
    { label: 'Wasile', value: 'Wasile' },
    { label: 'Wasile Selatan', value: 'Wasile Selatan' },
    { label: 'Wasile Tengah', value: 'Wasile Tengah' },
    { label: 'Wasile Timur', value: 'Wasile Timur' },
    { label: 'Wasile Utara', value: 'Wasile Utara' }
  ],
  'Halmahera Utara': [
    { label: 'Galela', value: 'Galela' },
    { label: 'Galela Barat', value: 'Galela Barat' },
    { label: 'Galela Selatan', value: 'Galela Selatan' },
    { label: 'Galela Utara', value: 'Galela Utara' },
    { label: 'Kao', value: 'Kao' },
    { label: 'Kao Barat', value: 'Kao Barat' },
    { label: 'Kao Teluk', value: 'Kao Teluk' },
    { label: 'Kao Utara', value: 'Kao Utara' },
    { label: 'Loloda Kepulauan', value: 'Loloda Kepulauan' },
    { label: 'Loloda Utara', value: 'Loloda Utara' },
    { label: 'Malifut', value: 'Malifut' },
    { label: 'Tobelo', value: 'Tobelo' },
    { label: 'Tobelo Barat', value: 'Tobelo Barat' },
    { label: 'Tobelo Selatan', value: 'Tobelo Selatan' },
    { label: 'Tobelo Tengah', value: 'Tobelo Tengah' },
    { label: 'Tobelo Timur', value: 'Tobelo Timur' },
    { label: 'Tobelo Utara', value: 'Tobelo Utara' }
  ],
  'Kepulauan Sula': [
    { label: 'Mangoli Barat', value: 'Mangoli Barat' },
    { label: 'Mangoli Selatan', value: 'Mangoli Selatan' },
    { label: 'Mangoli Tengah', value: 'Mangoli Tengah' },
    { label: 'Mangoli Timur', value: 'Mangoli Timur' },
    { label: 'Mangoli Utara', value: 'Mangoli Utara' },
    { label: 'Mangoli Utara Timur', value: 'Mangoli Utara Timur' },
    { label: 'Sanana', value: 'Sanana' },
    { label: 'Sanana Utara', value: 'Sanana Utara' },
    { label: 'Sulabesi Barat', value: 'Sulabesi Barat' },
    { label: 'Sulabesi Selatan', value: 'Sulabesi Selatan' },
    { label: 'Sulabesi Tengah', value: 'Sulabesi Tengah' },
    { label: 'Sulabesi Timur', value: 'Sulabesi Timur' }
  ],
  'Pulau Morotai': [
    { label: 'Morotai Jaya', value: 'Morotai Jaya' },
    { label: 'Morotai Selatan', value: 'Morotai Selatan' },
    { label: 'Morotai Selatan Barat', value: 'Morotai Selatan Barat' },
    { label: 'Morotai Timur', value: 'Morotai Timur' },
    { label: 'Morotai Utara', value: 'Morotai Utara' }
  ],
  'Pulau Taliabu': [
    { label: 'Lede', value: 'Lede' },
    { label: 'Tabona', value: 'Tabona' },
    { label: 'Taliabu Barat', value: 'Taliabu Barat' },
    { label: 'Taliabu Barat Laut', value: 'Taliabu Barat Laut' },
    { label: 'Taliabu Selatan', value: 'Taliabu Selatan' },
    { label: 'Taliabu Timur', value: 'Taliabu Timur' },
    { label: 'Taliabu Timur Selatan', value: 'Taliabu Timur Selatan' },
    { label: 'Taliabu Utara', value: 'Taliabu Utara' }
  ],
  Ternate: [
    { label: 'Moti (Pulau Moti)', value: 'Moti (Pulau Moti)' },
    { label: 'Pulau Batang Dua', value: 'Pulau Batang Dua' },
    { label: 'Pulau Hiri', value: 'Pulau Hiri' },
    { label: 'Pulau Ternate', value: 'Pulau Ternate' },
    { label: 'Ternate Selatan (Kota)', value: 'Ternate Selatan (Kota)' },
    { label: 'Ternate Tengah (Kota)', value: 'Ternate Tengah (Kota)' },
    { label: 'Ternate Utara (Kota)', value: 'Ternate Utara (Kota)' }
  ],
  'Tidore Kepulauan': [
    { label: 'Oba', value: 'Oba' },
    { label: 'Oba Selatan', value: 'Oba Selatan' },
    { label: 'Oba Tengah', value: 'Oba Tengah' },
    { label: 'Oba Utara', value: 'Oba Utara' },
    { label: 'Tidore', value: 'Tidore' },
    { label: 'Tidore (Pulau Tidore)', value: 'Tidore (Pulau Tidore)' },
    { label: 'Tidore Selatan', value: 'Tidore Selatan' },
    { label: 'Tidore Timur', value: 'Tidore Timur' },
    { label: 'Tidore Utara', value: 'Tidore Utara' }
  ],
  'Aceh Barat': [
    { label: 'Arongan Lambalek', value: 'Arongan Lambalek' },
    { label: 'Bubon', value: 'Bubon' },
    { label: 'Johan Pahlawan', value: 'Johan Pahlawan' },
    { label: 'Kaway XVI', value: 'Kaway XVI' },
    { label: 'Meureubo', value: 'Meureubo' },
    {
      label: 'Pante Ceureumen (Pantai Ceuremen)',
      value: 'Pante Ceureumen (Pantai Ceuremen)'
    },
    { label: 'Panton Reu', value: 'Panton Reu' },
    { label: 'Samatiga', value: 'Samatiga' },
    { label: 'Sungai Mas', value: 'Sungai Mas' },
    { label: 'Woyla', value: 'Woyla' },
    { label: 'Woyla Barat', value: 'Woyla Barat' },
    { label: 'Woyla Timur', value: 'Woyla Timur' }
  ],
  'Aceh Barat Daya': [
    { label: 'Babah Rot', value: 'Babah Rot' },
    { label: 'Blang Pidie', value: 'Blang Pidie' },
    { label: 'Jeumpa', value: 'Jeumpa' },
    { label: 'Kuala Batee', value: 'Kuala Batee' },
    { label: 'Lembah Sabil', value: 'Lembah Sabil' },
    { label: 'Manggeng', value: 'Manggeng' },
    { label: 'Setia', value: 'Setia' },
    { label: 'Susoh', value: 'Susoh' },
    { label: 'Tangan-Tangan', value: 'Tangan-Tangan' }
  ],
  'Aceh Besar': [
    { label: 'Baitussalam', value: 'Baitussalam' },
    { label: 'Blank Bintang', value: 'Blank Bintang' },
    { label: 'Darul Imarah', value: 'Darul Imarah' },
    { label: 'Darul Kamal', value: 'Darul Kamal' },
    { label: 'Darussalam', value: 'Darussalam' },
    { label: 'Indrapuri', value: 'Indrapuri' },
    { label: 'Ingin Jaya', value: 'Ingin Jaya' },
    {
      label: 'Kota Cot Glie (Kuta Cot Glie)',
      value: 'Kota Cot Glie (Kuta Cot Glie)'
    },
    { label: 'Kota Jantho', value: 'Kota Jantho' },
    {
      label: 'Kota Malaka (Kuta Malaka)',
      value: 'Kota Malaka (Kuta Malaka)'
    },
    { label: 'Krueng Barona Jaya', value: 'Krueng Barona Jaya' },
    { label: 'Kuta Baro', value: 'Kuta Baro' },
    { label: 'Lembah Seulawah', value: 'Lembah Seulawah' },
    { label: 'Leupung', value: 'Leupung' },
    { label: "Lhoknga (Lho'nga)", value: "Lhoknga (Lho'nga)" },
    { label: 'Lhoong', value: 'Lhoong' },
    { label: 'Mantasiek (Montasik)', value: 'Mantasiek (Montasik)' },
    { label: 'Mesjid Raya', value: 'Mesjid Raya' },
    { label: 'Peukan Bada', value: 'Peukan Bada' },
    { label: 'Pulo Aceh', value: 'Pulo Aceh' },
    { label: 'Seulimeum', value: 'Seulimeum' },
    { label: 'Simpang Tiga', value: 'Simpang Tiga' },
    { label: 'Suka Makmur', value: 'Suka Makmur' }
  ],
  'Aceh Jaya': [
    { label: 'Darul Hikmah', value: 'Darul Hikmah' },
    { label: 'Indra Jaya', value: 'Indra Jaya' },
    { label: 'Jaya', value: 'Jaya' },
    { label: 'Keude Panga', value: 'Keude Panga' },
    { label: 'Krueng Sabee', value: 'Krueng Sabee' },
    { label: 'Pasie Raya', value: 'Pasie Raya' },
    { label: 'Sampoiniet', value: 'Sampoiniet' },
    { label: 'Setia Bakti', value: 'Setia Bakti' },
    { label: 'Teunom', value: 'Teunom' }
  ],
  'Aceh Selatan': [
    { label: 'Bakongan', value: 'Bakongan' },
    { label: 'Bakongan Timur', value: 'Bakongan Timur' },
    { label: 'Kluet Selatan', value: 'Kluet Selatan' },
    { label: 'Kluet Tengah', value: 'Kluet Tengah' },
    { label: 'Kluet Timur', value: 'Kluet Timur' },
    { label: 'Kluet Utara', value: 'Kluet Utara' },
    { label: 'Kota Bahagia', value: 'Kota Bahagia' },
    { label: 'Labuhan Haji', value: 'Labuhan Haji' },
    { label: 'Labuhan Haji Barat', value: 'Labuhan Haji Barat' },
    { label: 'Labuhan Haji Timur', value: 'Labuhan Haji Timur' },
    { label: 'Meukek', value: 'Meukek' },
    { label: 'Pasie Raja', value: 'Pasie Raja' },
    { label: 'Sama Dua', value: 'Sama Dua' },
    { label: 'Sawang', value: 'Sawang' },
    { label: 'Tapak Tuan', value: 'Tapak Tuan' },
    { label: 'Trumon', value: 'Trumon' },
    { label: 'Trumon Tengah', value: 'Trumon Tengah' },
    { label: 'Trumon Timur', value: 'Trumon Timur' }
  ],
  'Aceh Singkil': [
    { label: 'Danau Paris', value: 'Danau Paris' },
    { label: 'Gunung Meriah (Mariah)', value: 'Gunung Meriah (Mariah)' },
    { label: 'Kota Baharu', value: 'Kota Baharu' },
    { label: 'Kuala Baru', value: 'Kuala Baru' },
    { label: 'Pulau Banyak', value: 'Pulau Banyak' },
    { label: 'Pulau Banyak Barat', value: 'Pulau Banyak Barat' },
    { label: 'Simpang Kanan', value: 'Simpang Kanan' },
    { label: 'Singkil', value: 'Singkil' },
    { label: 'Singkil Utara', value: 'Singkil Utara' },
    { label: 'Singkohor', value: 'Singkohor' },
    { label: 'Suro Makmur', value: 'Suro Makmur' }
  ],
  'Aceh Tamiang': [
    { label: 'Banda Mulia', value: 'Banda Mulia' },
    { label: 'Bandar Pusaka', value: 'Bandar Pusaka' },
    { label: 'Bendahara', value: 'Bendahara' },
    { label: 'Karang Baru', value: 'Karang Baru' },
    { label: 'Kejuruan Muda', value: 'Kejuruan Muda' },
    { label: 'Kota Kuala Simpang', value: 'Kota Kuala Simpang' },
    { label: 'Manyak Payed', value: 'Manyak Payed' },
    { label: 'Rantau', value: 'Rantau' },
    { label: 'Sekerak', value: 'Sekerak' },
    { label: 'Seruway', value: 'Seruway' },
    { label: 'Tamiang Hulu', value: 'Tamiang Hulu' },
    { label: 'Tenggulun', value: 'Tenggulun' }
  ],
  'Aceh Tengah': [
    { label: 'Atu Lintang', value: 'Atu Lintang' },
    { label: 'Bebesen', value: 'Bebesen' },
    { label: 'Bies', value: 'Bies' },
    { label: 'Bintang', value: 'Bintang' },
    { label: 'Celala', value: 'Celala' },
    { label: 'Jagong Jeget', value: 'Jagong Jeget' },
    { label: 'Kebayakan', value: 'Kebayakan' },
    { label: 'Ketol', value: 'Ketol' },
    { label: 'Kute Panang', value: 'Kute Panang' },
    { label: 'Linge', value: 'Linge' },
    { label: 'Lut Tawar', value: 'Lut Tawar' },
    { label: 'Pegasing', value: 'Pegasing' },
    { label: 'Rusip Antara', value: 'Rusip Antara' },
    { label: 'Silih Nara', value: 'Silih Nara' }
  ],
  'Aceh Tenggara': [
    { label: 'Babul Makmur', value: 'Babul Makmur' },
    { label: 'Babul Rahmah', value: 'Babul Rahmah' },
    { label: 'Babussalam', value: 'Babussalam' },
    { label: 'Badar', value: 'Badar' },
    { label: 'Bambel', value: 'Bambel' },
    { label: 'Bukit Tusam', value: 'Bukit Tusam' },
    { label: 'Darul Hasanah', value: 'Darul Hasanah' },
    { label: 'Deleng Pokhisen', value: 'Deleng Pokhisen' },
    { label: 'Ketambe', value: 'Ketambe' },
    { label: 'Lawe Alas', value: 'Lawe Alas' },
    { label: 'Lawe Bulan', value: 'Lawe Bulan' },
    { label: 'Lawe Sigala-Gala', value: 'Lawe Sigala-Gala' },
    { label: 'Lawe Sumur', value: 'Lawe Sumur' },
    { label: 'Leuser', value: 'Leuser' },
    { label: 'Semadam', value: 'Semadam' },
    { label: 'Tanah Alas', value: 'Tanah Alas' }
  ],
  'Aceh Timur': [
    { label: 'Banda Alam', value: 'Banda Alam' },
    { label: 'Birem Bayeun', value: 'Birem Bayeun' },
    { label: 'Darul Aman', value: 'Darul Aman' },
    { label: 'Darul Falah', value: 'Darul Falah' },
    { label: 'Darul Iksan (Ihsan)', value: 'Darul Iksan (Ihsan)' },
    { label: 'Idi Rayeuk', value: 'Idi Rayeuk' },
    { label: 'Idi Timur', value: 'Idi Timur' },
    { label: 'Idi Tunong', value: 'Idi Tunong' },
    { label: 'Indra Makmur', value: 'Indra Makmur' },
    { label: 'Julok', value: 'Julok' },
    { label: 'Madat', value: 'Madat' },
    { label: 'Nurussalam', value: 'Nurussalam' },
    { label: 'Pante Bidari', value: 'Pante Bidari' },
    { label: 'Peudawa', value: 'Peudawa' },
    { label: 'Peunaron', value: 'Peunaron' },
    { label: 'Peureulak', value: 'Peureulak' },
    { label: 'Peureulak Barat', value: 'Peureulak Barat' },
    { label: 'Peureulak Timur', value: 'Peureulak Timur' },
    { label: 'Rantau Selamat', value: 'Rantau Selamat' },
    { label: 'Ranto Peureulak', value: 'Ranto Peureulak' },
    { label: 'Serbajadi', value: 'Serbajadi' },
    { label: 'Simpang Jernih', value: 'Simpang Jernih' },
    { label: 'Simpang Ulim', value: 'Simpang Ulim' },
    { label: 'Sungai Raya', value: 'Sungai Raya' }
  ],
  'Aceh Utara': [
    { label: 'Baktiya', value: 'Baktiya' },
    { label: 'Baktiya Barat', value: 'Baktiya Barat' },
    { label: 'Banda Baro', value: 'Banda Baro' },
    { label: 'Cot Girek', value: 'Cot Girek' },
    { label: 'Dewantara', value: 'Dewantara' },
    { label: 'Geuredong Pase', value: 'Geuredong Pase' },
    { label: 'Kuta Makmur', value: 'Kuta Makmur' },
    { label: 'Langkahan', value: 'Langkahan' },
    { label: 'Lapang', value: 'Lapang' },
    { label: 'Lhoksukon', value: 'Lhoksukon' },
    { label: 'Matangkuli', value: 'Matangkuli' },
    { label: 'Meurah Mulia', value: 'Meurah Mulia' },
    { label: 'Muara Batu', value: 'Muara Batu' },
    { label: 'Nibong', value: 'Nibong' },
    { label: 'Nisam', value: 'Nisam' },
    { label: 'Nisam Antara', value: 'Nisam Antara' },
    { label: 'Paya Bakong', value: 'Paya Bakong' },
    { label: 'Pirak Timur', value: 'Pirak Timur' },
    { label: 'Samudera', value: 'Samudera' },
    { label: 'Sawang', value: 'Sawang' },
    { label: 'Seunuddon (Seunudon)', value: 'Seunuddon (Seunudon)' },
    {
      label: 'Simpang Kramat (Keramat)',
      value: 'Simpang Kramat (Keramat)'
    },
    { label: 'Syamtalira Aron', value: 'Syamtalira Aron' },
    { label: 'Syamtalira Bayu', value: 'Syamtalira Bayu' },
    { label: 'Tanah Jambo Aye', value: 'Tanah Jambo Aye' },
    { label: 'Tanah Luas', value: 'Tanah Luas' },
    { label: 'Tanah Pasir', value: 'Tanah Pasir' }
  ],
  'Banda Aceh': [
    { label: 'Baiturrahman', value: 'Baiturrahman' },
    { label: 'Banda Raya', value: 'Banda Raya' },
    { label: 'Jaya Baru', value: 'Jaya Baru' },
    { label: 'Kuta Alam', value: 'Kuta Alam' },
    { label: 'Kuta Raja', value: 'Kuta Raja' },
    { label: 'Lueng Bata', value: 'Lueng Bata' },
    { label: 'Meuraxa', value: 'Meuraxa' },
    { label: 'Syiah Kuala', value: 'Syiah Kuala' },
    { label: 'Ulee Kareng', value: 'Ulee Kareng' }
  ],
  'Bener Meriah': [
    { label: 'Bandar', value: 'Bandar' },
    { label: 'Bener Kelipah', value: 'Bener Kelipah' },
    { label: 'Bukit', value: 'Bukit' },
    { label: 'Gajah Putih', value: 'Gajah Putih' },
    { label: 'Mesidah', value: 'Mesidah' },
    { label: 'Permata', value: 'Permata' },
    { label: 'Pintu Rime Gayo', value: 'Pintu Rime Gayo' },
    { label: 'Syiah Utama', value: 'Syiah Utama' },
    { label: 'Timang Gajah', value: 'Timang Gajah' },
    { label: 'Wih Pesam', value: 'Wih Pesam' }
  ],
  Bireuen: [
    { label: 'Ganda Pura', value: 'Ganda Pura' },
    { label: 'Jangka', value: 'Jangka' },
    { label: 'Jeumpa', value: 'Jeumpa' },
    { label: 'Jeunieb', value: 'Jeunieb' },
    { label: 'Juli', value: 'Juli' },
    { label: 'Kota Juang', value: 'Kota Juang' },
    { label: 'Kuala', value: 'Kuala' },
    { label: 'Kuta Blang', value: 'Kuta Blang' },
    { label: 'Makmur', value: 'Makmur' },
    { label: 'Pandrah', value: 'Pandrah' },
    { label: 'Peudada', value: 'Peudada' },
    { label: 'Peulimbang (Plimbang)', value: 'Peulimbang (Plimbang)' },
    { label: 'Peusangan', value: 'Peusangan' },
    { label: 'Peusangan Selatan', value: 'Peusangan Selatan' },
    { label: 'Peusangan Siblah Krueng', value: 'Peusangan Siblah Krueng' },
    { label: 'Samalanga', value: 'Samalanga' },
    { label: 'Simpang Mamplam', value: 'Simpang Mamplam' }
  ],
  'Gayo Lues': [{ label: 'Blang Jerango', value: 'Blang Jerango' }],
  Lhokseumawe: [
    { label: 'Banda Sakti', value: 'Banda Sakti' },
    { label: 'Blang Mangat', value: 'Blang Mangat' },
    { label: 'Muara Dua', value: 'Muara Dua' },
    { label: 'Muara Satu', value: 'Muara Satu' }
  ],
  'Nagan Raya': [
    { label: 'Beutong', value: 'Beutong' },
    {
      label: 'Beutong Ateuh Banggalang',
      value: 'Beutong Ateuh Banggalang'
    },
    { label: 'Darul Makmur', value: 'Darul Makmur' },
    { label: 'Kuala', value: 'Kuala' },
    { label: 'Kuala Pesisir', value: 'Kuala Pesisir' },
    { label: 'Seunagan', value: 'Seunagan' },
    { label: 'Seunagan Timur', value: 'Seunagan Timur' },
    { label: 'Suka Makmue', value: 'Suka Makmue' },
    { label: 'Tadu Raya', value: 'Tadu Raya' },
    { label: 'Tripa Makmur', value: 'Tripa Makmur' }
  ],
  Pidie: [
    { label: 'Batee', value: 'Batee' },
    { label: 'Delima', value: 'Delima' },
    { label: 'Geumpang', value: 'Geumpang' },
    { label: 'Glumpang Baro', value: 'Glumpang Baro' },
    {
      label: 'Glumpang Tiga (Geulumpang Tiga)',
      value: 'Glumpang Tiga (Geulumpang Tiga)'
    },
    { label: 'Grong Grong', value: 'Grong Grong' },
    { label: 'Indrajaya', value: 'Indrajaya' },
    { label: 'Kembang Tanjong', value: 'Kembang Tanjong' },
    { label: 'Keumala', value: 'Keumala' },
    { label: 'Kota Sigli', value: 'Kota Sigli' },
    { label: 'Mane', value: 'Mane' },
    { label: 'Mila', value: 'Mila' },
    { label: 'Muara Tiga', value: 'Muara Tiga' },
    { label: 'Mutiara', value: 'Mutiara' },
    { label: 'Mutiara Timur', value: 'Mutiara Timur' },
    { label: 'Padang Tiji', value: 'Padang Tiji' },
    { label: 'Peukan Baro', value: 'Peukan Baro' },
    { label: 'Pidie', value: 'Pidie' },
    { label: 'Sakti', value: 'Sakti' },
    { label: 'Simpang Tiga', value: 'Simpang Tiga' },
    { label: 'Tangse', value: 'Tangse' },
    { label: 'Tiro/Truseb', value: 'Tiro/Truseb' },
    { label: 'Titeue', value: 'Titeue' }
  ],
  'Pidie Jaya': [
    { label: 'Bandar Baru', value: 'Bandar Baru' },
    { label: 'Bandar Dua', value: 'Bandar Dua' },
    { label: 'Jangka Buya', value: 'Jangka Buya' },
    { label: 'Meurah Dua', value: 'Meurah Dua' },
    { label: 'Meureudu', value: 'Meureudu' },
    { label: 'Panteraja', value: 'Panteraja' },
    { label: 'Trienggadeng', value: 'Trienggadeng' },
    { label: 'Ulim', value: 'Ulim' }
  ],
  Sabang: [
    { label: 'Sukajaya', value: 'Sukajaya' },
    { label: 'Sukakarya', value: 'Sukakarya' }
  ],
  Simeulue: [
    { label: 'Alapan (Alafan)', value: 'Alapan (Alafan)' },
    { label: 'Salang', value: 'Salang' },
    { label: 'Simeuleu Barat', value: 'Simeuleu Barat' },
    { label: 'Simeuleu Tengah', value: 'Simeuleu Tengah' },
    { label: 'Simeuleu Timur', value: 'Simeuleu Timur' },
    { label: 'Simeulue Cut', value: 'Simeulue Cut' },
    { label: 'Teluk Dalam', value: 'Teluk Dalam' },
    { label: 'Teupah Barat', value: 'Teupah Barat' },
    { label: 'Teupah Selatan', value: 'Teupah Selatan' },
    { label: 'Teupah Tengah', value: 'Teupah Tengah' }
  ],
  Subulussalam: [
    { label: 'Longkib', value: 'Longkib' },
    { label: 'Penanggalan', value: 'Penanggalan' },
    { label: 'Rundeng', value: 'Rundeng' },
    { label: 'Simpang Kiri', value: 'Simpang Kiri' },
    { label: 'Sultan Daulat', value: 'Sultan Daulat' }
  ],
  Bima: [
    { label: 'Asakota', value: 'Asakota' },
    { label: 'Mpunda', value: 'Mpunda' },
    { label: 'Raba', value: 'Raba' },
    { label: 'Rasanae Barat', value: 'Rasanae Barat' },
    { label: 'Rasanae Timur', value: 'Rasanae Timur' },
    { label: 'Ambalawi', value: 'Ambalawi' },
    { label: 'Belo', value: 'Belo' },
    { label: 'Bolo', value: 'Bolo' },
    { label: 'Donggo', value: 'Donggo' },
    { label: 'Lambitu', value: 'Lambitu' },
    { label: 'Lambu', value: 'Lambu' },
    { label: 'Langgudu', value: 'Langgudu' },
    { label: 'Madapangga', value: 'Madapangga' },
    { label: 'Monta', value: 'Monta' },
    { label: 'Palibelo', value: 'Palibelo' },
    { label: 'Parado', value: 'Parado' },
    { label: 'Sanggar', value: 'Sanggar' },
    { label: 'Sape', value: 'Sape' },
    { label: 'Soromandi', value: 'Soromandi' },
    { label: 'Tambora', value: 'Tambora' },
    { label: 'Wawo', value: 'Wawo' },
    { label: 'Wera', value: 'Wera' },
    { label: 'Woha', value: 'Woha' }
  ],
  Dompu: [
    { label: 'Dompu', value: 'Dompu' },
    { label: "Hu'u", value: "Hu'u" },
    { label: 'Kempo', value: 'Kempo' },
    { label: 'Kilo', value: 'Kilo' },
    { label: 'Manggalewa', value: 'Manggalewa' },
    { label: 'Pajo', value: 'Pajo' },
    { label: 'Pekat', value: 'Pekat' },
    { label: 'Woja', value: 'Woja' }
  ],
  'Lombok Barat': [
    { label: 'Batu Layar', value: 'Batu Layar' },
    { label: 'Gerung', value: 'Gerung' },
    { label: 'Gunungsari', value: 'Gunungsari' },
    { label: 'Kediri', value: 'Kediri' },
    { label: 'Kuripan', value: 'Kuripan' },
    { label: 'Labuapi', value: 'Labuapi' },
    { label: 'Lembar', value: 'Lembar' },
    { label: 'Lingsar', value: 'Lingsar' },
    { label: 'Narmada', value: 'Narmada' },
    { label: 'Sekotong', value: 'Sekotong' }
  ],
  'Lombok Tengah': [
    { label: 'Batukliang', value: 'Batukliang' },
    { label: 'Batukliang Utara', value: 'Batukliang Utara' },
    { label: 'Janapria', value: 'Janapria' },
    { label: 'Jonggat', value: 'Jonggat' },
    { label: 'Kopang', value: 'Kopang' },
    { label: 'Praya', value: 'Praya' },
    { label: 'Praya Barat', value: 'Praya Barat' },
    { label: 'Praya Barat Daya', value: 'Praya Barat Daya' },
    { label: 'Praya Tengah', value: 'Praya Tengah' },
    { label: 'Praya Timur', value: 'Praya Timur' },
    { label: 'Pringgarata', value: 'Pringgarata' },
    { label: 'Pujut', value: 'Pujut' }
  ],
  'Lombok Timur': [
    { label: 'Aikmel', value: 'Aikmel' },
    { label: 'Jerowaru', value: 'Jerowaru' },
    { label: 'Keruak', value: 'Keruak' },
    { label: 'Labuhan Haji', value: 'Labuhan Haji' },
    { label: 'Masbagik', value: 'Masbagik' },
    { label: 'Montong Gading', value: 'Montong Gading' },
    { label: 'Pringgabaya', value: 'Pringgabaya' },
    { label: 'Pringgasela', value: 'Pringgasela' },
    { label: 'Sakra', value: 'Sakra' },
    { label: 'Sakra Barat', value: 'Sakra Barat' },
    { label: 'Sakra Timur', value: 'Sakra Timur' },
    { label: 'Sambalia (Sambelia)', value: 'Sambalia (Sambelia)' },
    { label: 'Selong', value: 'Selong' },
    { label: 'Sembalun', value: 'Sembalun' },
    { label: 'Sikur', value: 'Sikur' },
    { label: 'Suela (Suwela)', value: 'Suela (Suwela)' },
    { label: 'Sukamulia', value: 'Sukamulia' },
    { label: 'Suralaga', value: 'Suralaga' },
    { label: 'Terara', value: 'Terara' },
    { label: 'Wanasaba', value: 'Wanasaba' }
  ],
  'Lombok Utara': [
    { label: 'Bayan', value: 'Bayan' },
    { label: 'Gangga', value: 'Gangga' },
    { label: 'Kayangan', value: 'Kayangan' },
    { label: 'Pemenang', value: 'Pemenang' },
    { label: 'Tanjung', value: 'Tanjung' }
  ],
  Mataram: [
    { label: 'Ampenan', value: 'Ampenan' },
    { label: 'Cakranegara', value: 'Cakranegara' },
    { label: 'Mataram', value: 'Mataram' },
    { label: 'Sandubaya (Sandujaya)', value: 'Sandubaya (Sandujaya)' },
    { label: 'Sekarbela', value: 'Sekarbela' },
    { label: 'Selaparang (Selaprang)', value: 'Selaparang (Selaprang)' }
  ],
  Sumbawa: [
    { label: 'Alas', value: 'Alas' },
    { label: 'Alas Barat', value: 'Alas Barat' },
    { label: 'Batulanteh', value: 'Batulanteh' },
    { label: 'Buer', value: 'Buer' },
    { label: 'Empang', value: 'Empang' },
    { label: 'Labangka', value: 'Labangka' },
    { label: 'Labuhan Badas', value: 'Labuhan Badas' },
    { label: 'Lantung', value: 'Lantung' },
    { label: 'Lape (Lape Lopok)', value: 'Lape (Lape Lopok)' },
    { label: 'Lenangguar', value: 'Lenangguar' },
    { label: 'Lopok', value: 'Lopok' },
    { label: 'Lunyuk', value: 'Lunyuk' },
    { label: 'Maronge', value: 'Maronge' },
    { label: 'Moyo Hilir', value: 'Moyo Hilir' },
    { label: 'Moyo Hulu', value: 'Moyo Hulu' },
    { label: 'Moyo Utara', value: 'Moyo Utara' },
    { label: 'Orong Telu', value: 'Orong Telu' },
    { label: 'Plampang', value: 'Plampang' },
    { label: 'Rhee', value: 'Rhee' },
    { label: 'Ropang', value: 'Ropang' },
    { label: 'Sumbawa', value: 'Sumbawa' },
    { label: 'Tarano', value: 'Tarano' },
    { label: 'Unter Iwes (Unterwiris)', value: 'Unter Iwes (Unterwiris)' },
    { label: 'Utan', value: 'Utan' }
  ],
  'Sumbawa Barat': [
    { label: 'Brang Ene', value: 'Brang Ene' },
    { label: 'Brang Rea', value: 'Brang Rea' },
    { label: 'Jereweh', value: 'Jereweh' },
    { label: 'Maluk', value: 'Maluk' },
    { label: 'Poto Tano', value: 'Poto Tano' },
    { label: 'Sateluk (Seteluk)', value: 'Sateluk (Seteluk)' },
    { label: 'Sekongkang', value: 'Sekongkang' },
    { label: 'Taliwang', value: 'Taliwang' }
  ],
  Alor: [
    { label: 'Alor Barat Daya', value: 'Alor Barat Daya' },
    { label: 'Alor Barat Laut', value: 'Alor Barat Laut' },
    { label: 'Alor Selatan', value: 'Alor Selatan' },
    { label: 'Alor Tengah Utara', value: 'Alor Tengah Utara' },
    { label: 'Alor Timur', value: 'Alor Timur' },
    { label: 'Alor Timur Laut', value: 'Alor Timur Laut' },
    { label: 'Kabola', value: 'Kabola' },
    { label: 'Lembur', value: 'Lembur' },
    { label: 'Mataru', value: 'Mataru' },
    { label: 'Pantar', value: 'Pantar' },
    { label: 'Pantar Barat', value: 'Pantar Barat' },
    { label: 'Pantar Barat Laut', value: 'Pantar Barat Laut' },
    { label: 'Pantar Tengah', value: 'Pantar Tengah' },
    { label: 'Pantar Timur', value: 'Pantar Timur' },
    { label: 'Pulau Pura', value: 'Pulau Pura' },
    { label: 'Pureman', value: 'Pureman' },
    { label: 'Teluk Mutiara', value: 'Teluk Mutiara' }
  ],
  Belu: [
    { label: 'Atambua Barat', value: 'Atambua Barat' },
    { label: 'Atambua Kota', value: 'Atambua Kota' },
    { label: 'Atambua Selatan', value: 'Atambua Selatan' },
    { label: 'Kakuluk Mesak', value: 'Kakuluk Mesak' },
    { label: 'Lamaknen', value: 'Lamaknen' },
    { label: 'Lamaknen Selatan', value: 'Lamaknen Selatan' },
    { label: 'Lasiolat', value: 'Lasiolat' },
    { label: 'Nanaet Duabesi', value: 'Nanaet Duabesi' },
    { label: 'Raihat', value: 'Raihat' },
    { label: 'Raimanuk', value: 'Raimanuk' },
    { label: 'Tasifeto Barat', value: 'Tasifeto Barat' },
    { label: 'Tasifeto Timur', value: 'Tasifeto Timur' }
  ],
  Ende: [
    { label: 'Detukeli', value: 'Detukeli' },
    { label: 'Detusoko', value: 'Detusoko' },
    { label: 'Ende', value: 'Ende' },
    { label: 'Ende Selatan', value: 'Ende Selatan' },
    { label: 'Ende Tengah', value: 'Ende Tengah' },
    { label: 'Ende Timur', value: 'Ende Timur' },
    { label: 'Ende Utara', value: 'Ende Utara' },
    { label: 'Kelimutu', value: 'Kelimutu' },
    { label: 'Kota Baru', value: 'Kota Baru' },
    { label: 'Lepembusu Kelisoke', value: 'Lepembusu Kelisoke' },
    { label: 'Lio Timur', value: 'Lio Timur' },
    { label: 'Maukaro', value: 'Maukaro' },
    { label: 'Maurole', value: 'Maurole' },
    { label: 'Nangapanda', value: 'Nangapanda' },
    { label: 'Ndona', value: 'Ndona' },
    { label: 'Ndona Timur', value: 'Ndona Timur' },
    { label: 'Ndori', value: 'Ndori' },
    { label: 'Pulau Ende', value: 'Pulau Ende' },
    { label: 'Wewaria', value: 'Wewaria' },
    { label: 'Wolojita', value: 'Wolojita' },
    { label: 'Wolowaru', value: 'Wolowaru' }
  ],
  'Flores Timur': [
    { label: 'Adonara', value: 'Adonara' },
    { label: 'Adonara Barat', value: 'Adonara Barat' },
    { label: 'Adonara Tengah', value: 'Adonara Tengah' },
    { label: 'Adonara Timur', value: 'Adonara Timur' },
    { label: 'Demon Pagong', value: 'Demon Pagong' },
    { label: 'Ile Boleng', value: 'Ile Boleng' },
    { label: 'Ile Bura', value: 'Ile Bura' },
    { label: 'Ile Mandiri', value: 'Ile Mandiri' },
    { label: 'Kelubagolit', value: 'Kelubagolit' },
    { label: 'Larantuka', value: 'Larantuka' },
    { label: 'Lewolema', value: 'Lewolema' },
    { label: 'Solor Barat', value: 'Solor Barat' },
    { label: 'Solor Selatan', value: 'Solor Selatan' },
    { label: 'Solor Timur', value: 'Solor Timur' },
    { label: 'Tanjung Bunga', value: 'Tanjung Bunga' },
    { label: 'Titehena', value: 'Titehena' },
    { label: 'Witihama', value: 'Witihama' },
    { label: 'Wotan Ulumando', value: 'Wotan Ulumando' },
    { label: 'Wulanggitang', value: 'Wulanggitang' }
  ],
  Kupang: [
    { label: 'Alak', value: 'Alak' },
    { label: 'Kelapa Lima', value: 'Kelapa Lima' },
    { label: 'Kota Lama', value: 'Kota Lama' },
    { label: 'Kota Raja', value: 'Kota Raja' },
    { label: 'Maulafa', value: 'Maulafa' },
    { label: 'Oebobo', value: 'Oebobo' },
    { label: 'Amabi Oefeto', value: 'Amabi Oefeto' },
    { label: 'Amabi Oefeto Timur', value: 'Amabi Oefeto Timur' },
    { label: 'Amarasi', value: 'Amarasi' },
    { label: 'Amarasi Barat', value: 'Amarasi Barat' },
    { label: 'Amarasi Selatan', value: 'Amarasi Selatan' },
    { label: 'Amarasi Timur', value: 'Amarasi Timur' },
    { label: 'Amfoang Barat Daya', value: 'Amfoang Barat Daya' },
    { label: 'Amfoang Barat Laut', value: 'Amfoang Barat Laut' },
    { label: 'Amfoang Selatan', value: 'Amfoang Selatan' },
    { label: 'Amfoang Tengah', value: 'Amfoang Tengah' },
    { label: 'Amfoang Timur', value: 'Amfoang Timur' },
    { label: 'Amfoang Utara', value: 'Amfoang Utara' },
    { label: 'Fatuleu', value: 'Fatuleu' },
    { label: 'Fatuleu Barat', value: 'Fatuleu Barat' },
    { label: 'Fatuleu Tengah', value: 'Fatuleu Tengah' },
    { label: 'Kupang Barat', value: 'Kupang Barat' },
    { label: 'Kupang Tengah', value: 'Kupang Tengah' },
    { label: 'Kupang Timur', value: 'Kupang Timur' },
    { label: 'Nekamese', value: 'Nekamese' },
    { label: 'Semau', value: 'Semau' },
    { label: 'Semau Selatan', value: 'Semau Selatan' },
    { label: 'Sulamu', value: 'Sulamu' },
    { label: 'Taebenu', value: 'Taebenu' },
    { label: 'Takari', value: 'Takari' }
  ],
  Lembata: [
    { label: 'Atadei', value: 'Atadei' },
    { label: 'Buyasuri', value: 'Buyasuri' },
    { label: 'Ile Ape', value: 'Ile Ape' },
    { label: 'Ile Ape Timur', value: 'Ile Ape Timur' },
    { label: 'Lebatukan', value: 'Lebatukan' },
    { label: 'Naga Wutung', value: 'Naga Wutung' },
    { label: 'Nubatukan', value: 'Nubatukan' },
    { label: 'Omesuri', value: 'Omesuri' },
    { label: 'Wulandoni', value: 'Wulandoni' }
  ],
  Malaka: [
    { label: 'Botin Leobele', value: 'Botin Leobele' },
    { label: 'Io Kufeu', value: 'Io Kufeu' },
    { label: 'Kobalima', value: 'Kobalima' },
    { label: 'Kobalima Timur', value: 'Kobalima Timur' },
    { label: 'Laenmanen', value: 'Laenmanen' },
    { label: 'Malaka Barat', value: 'Malaka Barat' },
    { label: 'Malaka Tengah', value: 'Malaka Tengah' },
    { label: 'Malaka Timur', value: 'Malaka Timur' },
    { label: 'Rinhat', value: 'Rinhat' },
    { label: 'Sasitamean', value: 'Sasitamean' },
    { label: 'Weliman', value: 'Weliman' },
    { label: 'Wewiku', value: 'Wewiku' }
  ],
  Manggarai: [
    { label: 'Cibal', value: 'Cibal' },
    { label: 'Cibal Barat', value: 'Cibal Barat' },
    { label: 'Langke Rembong', value: 'Langke Rembong' },
    { label: 'Lelak', value: 'Lelak' },
    { label: 'Rahong Utara', value: 'Rahong Utara' },
    { label: 'Reok', value: 'Reok' },
    { label: 'Reok Barat', value: 'Reok Barat' },
    { label: 'Ruteng', value: 'Ruteng' },
    { label: 'Satar Mese', value: 'Satar Mese' },
    { label: 'Satar Mese Barat', value: 'Satar Mese Barat' },
    { label: 'Wae Rii', value: 'Wae Rii' }
  ],
  'Manggarai Barat': [
    { label: 'Boleng', value: 'Boleng' },
    { label: 'Komodo', value: 'Komodo' },
    { label: 'Kuwus', value: 'Kuwus' },
    { label: 'Lembor', value: 'Lembor' },
    { label: 'Lembor Selatan', value: 'Lembor Selatan' },
    { label: 'Macang Pacar', value: 'Macang Pacar' },
    { label: 'Mbeliling', value: 'Mbeliling' },
    { label: 'Ndoso', value: 'Ndoso' },
    { label: 'Sano Nggoang', value: 'Sano Nggoang' },
    { label: 'Welak', value: 'Welak' }
  ],
  'Manggarai Timur': [
    { label: 'Borong', value: 'Borong' },
    { label: 'Elar', value: 'Elar' },
    { label: 'Elar Selatan', value: 'Elar Selatan' },
    { label: 'Kota Komba', value: 'Kota Komba' },
    { label: 'Lamba Leda', value: 'Lamba Leda' },
    { label: 'Poco Ranaka', value: 'Poco Ranaka' },
    { label: 'Poco Ranaka Timur', value: 'Poco Ranaka Timur' },
    { label: 'Rana Mese', value: 'Rana Mese' },
    { label: 'Sambi Rampas', value: 'Sambi Rampas' }
  ],
  Nagekeo: [
    { label: 'Aesesa', value: 'Aesesa' },
    { label: 'Aesesa Selatan', value: 'Aesesa Selatan' },
    { label: 'Boawae', value: 'Boawae' },
    { label: 'Keo Tengah', value: 'Keo Tengah' },
    { label: 'Mauponggo', value: 'Mauponggo' },
    { label: 'Nangaroro', value: 'Nangaroro' },
    { label: 'Wolowae', value: 'Wolowae' }
  ],
  Ngada: [
    { label: 'Aimere', value: 'Aimere' },
    { label: 'Bajawa', value: 'Bajawa' },
    { label: 'Bajawa Utara', value: 'Bajawa Utara' },
    { label: 'Golewa', value: 'Golewa' },
    { label: 'Golewa Barat', value: 'Golewa Barat' },
    { label: 'Golewa Selatan', value: 'Golewa Selatan' },
    { label: 'Inerie', value: 'Inerie' },
    { label: 'Jerebuu', value: 'Jerebuu' },
    { label: 'Riung', value: 'Riung' },
    { label: 'Riung Barat', value: 'Riung Barat' },
    { label: 'Soa', value: 'Soa' },
    { label: 'Wolomeze (Riung Selatan)', value: 'Wolomeze (Riung Selatan)' }
  ],
  'Rote Ndao': [
    { label: 'Landu Leko', value: 'Landu Leko' },
    { label: 'Lobalain', value: 'Lobalain' },
    { label: 'Ndao Nuse', value: 'Ndao Nuse' },
    { label: 'Pantai Baru', value: 'Pantai Baru' },
    { label: 'Rote Barat', value: 'Rote Barat' },
    { label: 'Rote Barat Daya', value: 'Rote Barat Daya' },
    { label: 'Rote Barat Laut', value: 'Rote Barat Laut' },
    { label: 'Rote Selatan', value: 'Rote Selatan' },
    { label: 'Rote Tengah', value: 'Rote Tengah' },
    { label: 'Rote Timur', value: 'Rote Timur' }
  ],
  'Sabu Raijua': [
    { label: 'Hawu Mehara', value: 'Hawu Mehara' },
    { label: 'Raijua', value: 'Raijua' },
    { label: 'Sabu Barat', value: 'Sabu Barat' },
    { label: 'Sabu Liae', value: 'Sabu Liae' },
    { label: 'Sabu Tengah', value: 'Sabu Tengah' },
    { label: 'Sabu Timur', value: 'Sabu Timur' }
  ],
  Sikka: [
    { label: 'Alok', value: 'Alok' },
    { label: 'Alok Barat', value: 'Alok Barat' },
    { label: 'Alok Timur', value: 'Alok Timur' },
    { label: 'Bola', value: 'Bola' },
    { label: 'Doreng', value: 'Doreng' },
    { label: 'Hewokloang', value: 'Hewokloang' },
    { label: 'Kangae', value: 'Kangae' },
    { label: 'Kewapante', value: 'Kewapante' },
    { label: 'Koting', value: 'Koting' },
    { label: 'Lela', value: 'Lela' },
    { label: 'Magepanda', value: 'Magepanda' },
    { label: 'Mapitara', value: 'Mapitara' },
    { label: 'Mego', value: 'Mego' },
    { label: 'Nelle (Maumerei)', value: 'Nelle (Maumerei)' },
    { label: 'Nita', value: 'Nita' },
    { label: 'Paga', value: 'Paga' },
    { label: 'Palue', value: 'Palue' },
    { label: 'Talibura', value: 'Talibura' },
    { label: 'Tana Wawo', value: 'Tana Wawo' },
    { label: 'Waiblama', value: 'Waiblama' },
    { label: 'Waigete', value: 'Waigete' }
  ],
  'Sumba Barat': [
    { label: 'Kota Waikabubak', value: 'Kota Waikabubak' },
    { label: 'Lamboya', value: 'Lamboya' },
    { label: 'Lamboya Barat', value: 'Lamboya Barat' },
    { label: 'Loli', value: 'Loli' },
    { label: 'Tana Righu', value: 'Tana Righu' },
    { label: 'Wanokaka', value: 'Wanokaka' }
  ],
  'Sumba Barat Daya': [
    { label: 'Kodi', value: 'Kodi' },
    { label: 'Kodi Balaghar', value: 'Kodi Balaghar' },
    { label: 'Kodi Bangedo', value: 'Kodi Bangedo' },
    { label: 'Kodi Utara', value: 'Kodi Utara' },
    { label: 'Kota Tambolaka', value: 'Kota Tambolaka' },
    { label: 'Loura (Laura)', value: 'Loura (Laura)' },
    { label: 'Wewewa Barat', value: 'Wewewa Barat' },
    { label: 'Wewewa Selatan', value: 'Wewewa Selatan' },
    {
      label: 'Wewewa Tengah (Wewera Tengah)',
      value: 'Wewewa Tengah (Wewera Tengah)'
    },
    { label: 'Wewewa Timur', value: 'Wewewa Timur' },
    { label: 'Wewewa Utara', value: 'Wewewa Utara' }
  ],
  'Sumba Tengah': [
    { label: 'Katiku Tana', value: 'Katiku Tana' },
    { label: 'Katikutana Selatan', value: 'Katikutana Selatan' },
    { label: 'Mamboro', value: 'Mamboro' },
    { label: 'Umbu Ratu Nggay', value: 'Umbu Ratu Nggay' },
    { label: 'Umbu Ratu Nggay Barat', value: 'Umbu Ratu Nggay Barat' }
  ],
  'Sumba Timur': [
    { label: 'Haharu', value: 'Haharu' },
    {
      label: 'Kahaunguweti (Kahaungu Eti)',
      value: 'Kahaunguweti (Kahaungu Eti)'
    },
    { label: 'Kambata Mapambuhang', value: 'Kambata Mapambuhang' },
    { label: 'Kambera', value: 'Kambera' },
    { label: 'Kanatang', value: 'Kanatang' },
    { label: 'Karera', value: 'Karera' },
    { label: 'Katala Hamu Lingu', value: 'Katala Hamu Lingu' },
    { label: 'Kota Waingapu', value: 'Kota Waingapu' },
    { label: 'Lewa', value: 'Lewa' },
    { label: 'Lewa Tidahu', value: 'Lewa Tidahu' },
    { label: 'Mahu', value: 'Mahu' },
    {
      label: 'Matawai Lappau (La Pawu)',
      value: 'Matawai Lappau (La Pawu)'
    },
    { label: 'Ngadu Ngala', value: 'Ngadu Ngala' },
    { label: 'Nggaha Oriangu', value: 'Nggaha Oriangu' },
    { label: 'Paberiwai', value: 'Paberiwai' },
    { label: 'Pahunga Lodu', value: 'Pahunga Lodu' },
    { label: 'Pandawai', value: 'Pandawai' },
    { label: 'Pinupahar (Pirapahar)', value: 'Pinupahar (Pirapahar)' },
    { label: 'Rindi', value: 'Rindi' },
    { label: 'Tabundung', value: 'Tabundung' },
    { label: 'Umalulu', value: 'Umalulu' },
    { label: 'Wula Waijelu', value: 'Wula Waijelu' }
  ],
  'Timor Tengah Selatan': [
    { label: 'Amanatun Selatan', value: 'Amanatun Selatan' },
    { label: 'Amanatun Utara', value: 'Amanatun Utara' },
    { label: 'Amanuban Barat', value: 'Amanuban Barat' },
    { label: 'Amanuban Selatan', value: 'Amanuban Selatan' },
    { label: 'Amanuban Tengah', value: 'Amanuban Tengah' },
    { label: 'Amanuban Timur', value: 'Amanuban Timur' },
    { label: 'Batu Putih', value: 'Batu Putih' },
    { label: 'Boking', value: 'Boking' },
    { label: 'Fatukopa', value: 'Fatukopa' },
    { label: 'Fatumnasi', value: 'Fatumnasi' },
    { label: 'Fautmolo', value: 'Fautmolo' },
    { label: "Kie (Ki'e)", value: "Kie (Ki'e)" },
    { label: 'Kok Baun', value: 'Kok Baun' },
    { label: 'Kolbano', value: 'Kolbano' },
    { label: 'Kot Olin', value: 'Kot Olin' },
    { label: 'Kota Soe', value: 'Kota Soe' },
    { label: 'Kualin', value: 'Kualin' },
    { label: 'Kuanfatu', value: 'Kuanfatu' },
    { label: 'Kuatnana', value: 'Kuatnana' },
    { label: 'Mollo Barat', value: 'Mollo Barat' },
    { label: 'Mollo Selatan', value: 'Mollo Selatan' },
    { label: 'Mollo Tengah', value: 'Mollo Tengah' },
    { label: 'Mollo Utara', value: 'Mollo Utara' },
    { label: 'Noebana', value: 'Noebana' },
    { label: 'Noebeba', value: 'Noebeba' },
    { label: 'Nunbena', value: 'Nunbena' },
    { label: 'Nunkolo', value: 'Nunkolo' },
    { label: 'Oenino', value: 'Oenino' },
    { label: 'Polen', value: 'Polen' },
    { label: 'Santian', value: 'Santian' },
    { label: 'Tobu', value: 'Tobu' },
    { label: 'Toianas', value: 'Toianas' }
  ],
  'Timor Tengah Utara': [
    { label: 'Biboki Anleu', value: 'Biboki Anleu' },
    { label: 'Biboki Feotleu', value: 'Biboki Feotleu' },
    { label: 'Biboki Moenleu', value: 'Biboki Moenleu' },
    { label: 'Biboki Selatan', value: 'Biboki Selatan' },
    { label: 'Biboki Tan Pah', value: 'Biboki Tan Pah' },
    { label: 'Biboki Utara', value: 'Biboki Utara' },
    { label: 'Bikomi Nilulat', value: 'Bikomi Nilulat' },
    { label: 'Bikomi Selatan', value: 'Bikomi Selatan' },
    { label: 'Bikomi Tengah', value: 'Bikomi Tengah' },
    { label: 'Bikomi Utara', value: 'Bikomi Utara' },
    { label: 'Insana', value: 'Insana' },
    { label: 'Insana Barat', value: 'Insana Barat' },
    { label: 'Insana Fafinesu', value: 'Insana Fafinesu' },
    { label: 'Insana Tengah', value: 'Insana Tengah' },
    { label: 'Insana Utara', value: 'Insana Utara' },
    { label: 'Kota Kefamenanu', value: 'Kota Kefamenanu' },
    { label: 'Miomafo Barat', value: 'Miomafo Barat' },
    { label: 'Miomafo Tengah', value: 'Miomafo Tengah' },
    { label: 'Miomafo Timur', value: 'Miomafo Timur' },
    { label: 'Musi', value: 'Musi' },
    { label: 'Mutis', value: 'Mutis' },
    { label: 'Naibenu', value: 'Naibenu' },
    { label: 'Noemuti', value: 'Noemuti' },
    { label: 'Noemuti Timur', value: 'Noemuti Timur' }
  ],
  Asmat: [
    { label: 'Agats', value: 'Agats' },
    { label: 'Akat', value: 'Akat' },
    { label: 'Atsy / Atsj', value: 'Atsy / Atsj' },
    { label: 'Ayip', value: 'Ayip' },
    { label: 'Betcbamu', value: 'Betcbamu' },
    { label: 'Der Koumur', value: 'Der Koumur' },
    { label: 'Fayit', value: 'Fayit' },
    { label: 'Jetsy', value: 'Jetsy' },
    { label: 'Joerat', value: 'Joerat' },
    { label: 'Kolf Braza', value: 'Kolf Braza' },
    { label: 'Kopay', value: 'Kopay' },
    { label: 'Pantai Kasuari', value: 'Pantai Kasuari' },
    { label: 'Pulau Tiga', value: 'Pulau Tiga' },
    { label: 'Safan', value: 'Safan' },
    { label: 'Sawa Erma', value: 'Sawa Erma' },
    { label: 'Sirets', value: 'Sirets' },
    { label: 'Suator', value: 'Suator' },
    { label: 'Suru-suru', value: 'Suru-suru' },
    { label: 'Unir Sirau', value: 'Unir Sirau' }
  ],
  'Biak Numfor': [
    { label: 'Aimando Padaido', value: 'Aimando Padaido' },
    { label: 'Andey', value: 'Andey' },
    { label: 'Biak Barat', value: 'Biak Barat' },
    { label: 'Biak Kota', value: 'Biak Kota' },
    { label: 'Biak Timur', value: 'Biak Timur' },
    { label: 'Biak Utara', value: 'Biak Utara' },
    { label: 'Bondifuar', value: 'Bondifuar' },
    { label: 'Bruyadori', value: 'Bruyadori' },
    { label: 'Numfor Barat', value: 'Numfor Barat' },
    { label: 'Numfor Timur', value: 'Numfor Timur' },
    { label: 'Oridek', value: 'Oridek' },
    { label: 'Orkeri', value: 'Orkeri' },
    { label: 'Padaido', value: 'Padaido' },
    { label: 'Poiru', value: 'Poiru' },
    { label: 'Samofa', value: 'Samofa' },
    { label: 'Swandiwe', value: 'Swandiwe' },
    { label: 'Warsa', value: 'Warsa' },
    { label: 'Yawosi', value: 'Yawosi' },
    { label: 'Yendidori', value: 'Yendidori' }
  ],
  'Boven Digoel': [
    { label: 'Ambatkwi (Ambatkui)', value: 'Ambatkwi (Ambatkui)' },
    { label: 'Arimop', value: 'Arimop' },
    { label: 'Bomakia', value: 'Bomakia' },
    { label: 'Firiwage', value: 'Firiwage' },
    { label: 'Fofi', value: 'Fofi' },
    { label: 'Iniyandit', value: 'Iniyandit' },
    { label: 'Jair', value: 'Jair' },
    { label: 'Kawagit', value: 'Kawagit' },
    { label: 'Ki', value: 'Ki' },
    { label: 'Kombay', value: 'Kombay' },
    { label: 'Kombut', value: 'Kombut' },
    { label: 'Kouh', value: 'Kouh' },
    { label: 'Mandobo', value: 'Mandobo' },
    { label: 'Manggelum', value: 'Manggelum' },
    { label: 'Mindiptana', value: 'Mindiptana' },
    { label: 'Ninati', value: 'Ninati' },
    { label: 'Sesnuk', value: 'Sesnuk' },
    { label: 'Subur', value: 'Subur' },
    { label: 'Waropko', value: 'Waropko' },
    { label: 'Yaniruma', value: 'Yaniruma' }
  ],
  Deiyai: [
    { label: 'Bowobado', value: 'Bowobado' },
    { label: 'Kapiraya', value: 'Kapiraya' },
    { label: 'Tigi', value: 'Tigi' },
    { label: 'Tigi Barat', value: 'Tigi Barat' },
    { label: 'Tigi Timur', value: 'Tigi Timur' }
  ],
  Dogiyai: [
    { label: 'Dogiyai', value: 'Dogiyai' },
    { label: 'Kamu', value: 'Kamu' },
    { label: 'Kamu Selatan', value: 'Kamu Selatan' },
    { label: 'Kamu Timur', value: 'Kamu Timur' },
    {
      label: 'Kamu Utara (Ikrar/Ikrat)',
      value: 'Kamu Utara (Ikrar/Ikrat)'
    },
    { label: 'Mapia', value: 'Mapia' },
    { label: 'Mapia Barat', value: 'Mapia Barat' },
    { label: 'Mapia Tengah', value: 'Mapia Tengah' },
    { label: 'Piyaiye (Sukikai)', value: 'Piyaiye (Sukikai)' },
    { label: 'Sukikai Selatan', value: 'Sukikai Selatan' }
  ],
  'Intan Jaya': [
    { label: 'Agisiga', value: 'Agisiga' },
    { label: 'Biandoga', value: 'Biandoga' },
    { label: 'Hitadipa', value: 'Hitadipa' },
    { label: 'Homeyo', value: 'Homeyo' },
    { label: 'Sugapa', value: 'Sugapa' },
    { label: 'Tomosiga', value: 'Tomosiga' },
    { label: 'Ugimba', value: 'Ugimba' },
    { label: 'Wandai', value: 'Wandai' }
  ],
  Jayapura: [
    { label: 'Abepura', value: 'Abepura' },
    { label: 'Heram', value: 'Heram' },
    { label: 'Jayapura Selatan', value: 'Jayapura Selatan' },
    { label: 'Jayapura Utara', value: 'Jayapura Utara' },
    { label: 'Muara Tami', value: 'Muara Tami' },
    { label: 'Airu', value: 'Airu' },
    { label: 'Demta', value: 'Demta' },
    { label: 'Depapre', value: 'Depapre' },
    { label: 'Ebungfau (Ebungfa)', value: 'Ebungfau (Ebungfa)' },
    { label: 'Gresi Selatan', value: 'Gresi Selatan' },
    { label: 'Kaureh', value: 'Kaureh' },
    { label: 'Kemtuk', value: 'Kemtuk' },
    { label: 'Kemtuk Gresi', value: 'Kemtuk Gresi' },
    { label: 'Nambluong', value: 'Nambluong' },
    { label: 'Nimbokrang', value: 'Nimbokrang' },
    { label: 'Nimboran', value: 'Nimboran' },
    { label: 'Ravenirara', value: 'Ravenirara' },
    { label: 'Sentani', value: 'Sentani' },
    { label: 'Sentani Barat', value: 'Sentani Barat' },
    { label: 'Sentani Timur', value: 'Sentani Timur' },
    { label: 'Unurum Guay', value: 'Unurum Guay' },
    { label: 'Waibu', value: 'Waibu' },
    { label: 'Yapsi', value: 'Yapsi' },
    { label: 'Yokari', value: 'Yokari' }
  ],
  Jayawijaya: [
    { label: 'Asologaima (Asalogaima)', value: 'Asologaima (Asalogaima)' },
    { label: 'Asolokobal', value: 'Asolokobal' },
    { label: 'Asotipo', value: 'Asotipo' },
    { label: 'Bolakme', value: 'Bolakme' },
    { label: 'Bpiri', value: 'Bpiri' },
    { label: 'Bugi', value: 'Bugi' },
    { label: 'Hubikiak', value: 'Hubikiak' },
    { label: 'Hubikosi (Hobikosi)', value: 'Hubikosi (Hobikosi)' },
    { label: 'Ibele', value: 'Ibele' },
    { label: 'Itlay Hisage', value: 'Itlay Hisage' },
    { label: 'Koragi', value: 'Koragi' },
    { label: 'Kurulu', value: 'Kurulu' },
    { label: 'Libarek', value: 'Libarek' },
    { label: 'Maima', value: 'Maima' },
    { label: 'Molagalome', value: 'Molagalome' },
    { label: 'Muliama', value: 'Muliama' },
    { label: 'Musatfak', value: 'Musatfak' },
    { label: 'Napua', value: 'Napua' },
    { label: 'Pelebaga', value: 'Pelebaga' },
    { label: 'Piramid', value: 'Piramid' },
    { label: 'Pisugi', value: 'Pisugi' },
    { label: 'Popugoba', value: 'Popugoba' },
    { label: 'Siepkosi', value: 'Siepkosi' },
    { label: 'Silo Karno Doga', value: 'Silo Karno Doga' },
    { label: 'Taelarek', value: 'Taelarek' },
    { label: 'Tagime', value: 'Tagime' },
    { label: 'Tagineri', value: 'Tagineri' },
    { label: 'Trikora', value: 'Trikora' },
    { label: 'Usilimo', value: 'Usilimo' },
    { label: 'Wadangku', value: 'Wadangku' },
    { label: 'Walaik', value: 'Walaik' },
    { label: 'Walelagama', value: 'Walelagama' },
    { label: 'Wame', value: 'Wame' },
    { label: 'Wamena', value: 'Wamena' },
    { label: 'Welesi', value: 'Welesi' },
    { label: 'Wesaput', value: 'Wesaput' },
    { label: 'Wita Waya', value: 'Wita Waya' },
    { label: 'Wollo (Wolo)', value: 'Wollo (Wolo)' },
    { label: 'Wouma', value: 'Wouma' },
    { label: 'Yalengga', value: 'Yalengga' }
  ],
  Keerom: [
    { label: 'Arso', value: 'Arso' },
    { label: 'Arso Timur', value: 'Arso Timur' },
    { label: 'Senggi', value: 'Senggi' },
    { label: 'Skanto', value: 'Skanto' },
    { label: 'Towe', value: 'Towe' },
    { label: 'Waris', value: 'Waris' },
    { label: 'Web', value: 'Web' }
  ],
  'Kepulauan Yapen': [
    { label: 'Angkaisera', value: 'Angkaisera' },
    { label: 'Kepulauan Ambai', value: 'Kepulauan Ambai' },
    { label: 'Kosiwo', value: 'Kosiwo' },
    { label: 'Poom', value: 'Poom' },
    { label: 'Pulau Kurudu', value: 'Pulau Kurudu' },
    { label: 'Pulau Yerui', value: 'Pulau Yerui' },
    { label: 'Raimbawi', value: 'Raimbawi' },
    { label: 'Teluk Ampimoi', value: 'Teluk Ampimoi' },
    { label: 'Windesi', value: 'Windesi' },
    { label: 'Wonawa', value: 'Wonawa' },
    { label: 'Yapen Barat', value: 'Yapen Barat' },
    { label: 'Yapen Selatan', value: 'Yapen Selatan' },
    { label: 'Yapen Timur', value: 'Yapen Timur' },
    { label: 'Yapen Utara', value: 'Yapen Utara' }
  ],
  'Lanny Jaya': [
    { label: 'Awina', value: 'Awina' },
    { label: 'Ayumnati', value: 'Ayumnati' },
    { label: 'Balingga', value: 'Balingga' },
    { label: 'Balingga Barat', value: 'Balingga Barat' },
    { label: 'Bruwa', value: 'Bruwa' },
    { label: 'Buguk Gona', value: 'Buguk Gona' },
    { label: 'Dimba', value: 'Dimba' },
    { label: 'Gamelia', value: 'Gamelia' },
    { label: 'Gelok Beam', value: 'Gelok Beam' },
    { label: 'Goa Balim', value: 'Goa Balim' },
    { label: 'Gollo', value: 'Gollo' },
    { label: 'Guna', value: 'Guna' },
    { label: 'Gupura', value: 'Gupura' },
    { label: 'Karu', value: 'Karu' },
    { label: 'Kelulome', value: 'Kelulome' },
    { label: 'Kolawa', value: 'Kolawa' },
    { label: 'Kuly Lanny', value: 'Kuly Lanny' },
    { label: 'Kuyawage', value: 'Kuyawage' },
    { label: 'Lannyna', value: 'Lannyna' },
    { label: 'Makki', value: 'Makki' },
    { label: 'Melagi', value: 'Melagi' },
    { label: 'Melagineri', value: 'Melagineri' },
    { label: 'Milimbo', value: 'Milimbo' },
    { label: 'Mokoni', value: 'Mokoni' },
    { label: 'Muara', value: 'Muara' },
    { label: 'Nikogwe', value: 'Nikogwe' },
    { label: 'Niname', value: 'Niname' },
    { label: 'Nogi', value: 'Nogi' },
    { label: 'Pirime', value: 'Pirime' },
    { label: 'Poga', value: 'Poga' },
    { label: 'Tiom', value: 'Tiom' },
    { label: 'Tiom Ollo', value: 'Tiom Ollo' },
    { label: 'Tiomneri', value: 'Tiomneri' },
    { label: 'Wano Barat', value: 'Wano Barat' },
    { label: 'Wereka', value: 'Wereka' },
    { label: 'Wiringgambut', value: 'Wiringgambut' },
    { label: 'Yiginua', value: 'Yiginua' },
    { label: 'Yiluk', value: 'Yiluk' },
    { label: 'Yugungwi', value: 'Yugungwi' }
  ],
  'Mamberamo Raya': [
    { label: 'Benuki', value: 'Benuki' },
    { label: 'Mamberamo Hilir/Ilir', value: 'Mamberamo Hilir/Ilir' },
    { label: 'Mamberamo Hulu', value: 'Mamberamo Hulu' },
    { label: 'Mamberamo Tengah', value: 'Mamberamo Tengah' },
    { label: 'Mamberamo Tengah Timur', value: 'Mamberamo Tengah Timur' },
    { label: 'Rufaer', value: 'Rufaer' },
    { label: 'Sawai', value: 'Sawai' },
    { label: 'Waropen Atas', value: 'Waropen Atas' }
  ],
  'Mamberamo Tengah': [
    { label: 'Eragayam', value: 'Eragayam' },
    { label: 'Ilugwa', value: 'Ilugwa' },
    { label: 'Kelila', value: 'Kelila' },
    { label: 'Kobakma', value: 'Kobakma' },
    { label: 'Megabilis (Megambilis)', value: 'Megabilis (Megambilis)' }
  ],
  Mappi: [
    { label: 'Assue', value: 'Assue' },
    { label: 'Bamgi', value: 'Bamgi' },
    { label: 'Citakmitak', value: 'Citakmitak' },
    { label: 'Edera', value: 'Edera' },
    { label: 'Haju', value: 'Haju' },
    { label: 'Kaibar', value: 'Kaibar' },
    { label: 'Minyamur', value: 'Minyamur' },
    {
      label: 'Nambioman Bapai (Mambioman)',
      value: 'Nambioman Bapai (Mambioman)'
    },
    { label: 'Obaa', value: 'Obaa' },
    { label: 'Passue', value: 'Passue' },
    { label: 'Passue Bawah', value: 'Passue Bawah' },
    { label: 'Syahcame', value: 'Syahcame' },
    { label: 'Ti Zain', value: 'Ti Zain' },
    { label: 'Venaha', value: 'Venaha' },
    { label: 'Yakomi', value: 'Yakomi' }
  ],
  Merauke: [
    { label: 'Animha', value: 'Animha' },
    { label: 'Elikobal', value: 'Elikobal' },
    { label: 'Ilyawab', value: 'Ilyawab' },
    { label: 'Jagebob', value: 'Jagebob' },
    { label: 'Kaptel', value: 'Kaptel' },
    { label: 'Kimaam', value: 'Kimaam' },
    { label: 'Kurik', value: 'Kurik' },
    { label: 'Malind', value: 'Malind' },
    { label: 'Merauke', value: 'Merauke' },
    { label: 'Muting', value: 'Muting' },
    { label: 'Naukenjerai', value: 'Naukenjerai' },
    { label: 'Ngguti', value: 'Ngguti' },
    { label: 'Okaba', value: 'Okaba' },
    { label: 'Semangga', value: 'Semangga' },
    { label: 'Sota', value: 'Sota' },
    { label: 'Tabonji', value: 'Tabonji' },
    { label: 'Tanah Miring', value: 'Tanah Miring' },
    { label: 'Tubang', value: 'Tubang' },
    { label: 'Ulilin', value: 'Ulilin' },
    { label: 'Waan', value: 'Waan' }
  ],
  Mimika: [
    { label: 'Agimuga', value: 'Agimuga' },
    { label: 'Alama', value: 'Alama' },
    { label: 'Amar', value: 'Amar' },
    { label: 'Hoya', value: 'Hoya' },
    { label: 'Iwaka', value: 'Iwaka' },
    { label: 'Jila', value: 'Jila' },
    { label: 'Jita', value: 'Jita' },
    { label: 'Kuala Kencana', value: 'Kuala Kencana' },
    { label: 'Kwamki Narama', value: 'Kwamki Narama' },
    { label: 'Mimika Barat', value: 'Mimika Barat' },
    { label: 'Mimika Barat Jauh', value: 'Mimika Barat Jauh' },
    { label: 'Mimika Barat Tengah', value: 'Mimika Barat Tengah' },
    { label: 'Mimika Baru', value: 'Mimika Baru' },
    { label: 'Mimika Tengah', value: 'Mimika Tengah' },
    { label: 'Mimika Timur', value: 'Mimika Timur' },
    { label: 'Mimika Timur Jauh', value: 'Mimika Timur Jauh' },
    { label: 'Tembagapura', value: 'Tembagapura' },
    { label: 'Wania', value: 'Wania' }
  ],
  Nabire: [
    { label: 'Dipa', value: 'Dipa' },
    { label: 'Makimi', value: 'Makimi' },
    { label: 'Menou', value: 'Menou' },
    { label: 'Moora', value: 'Moora' },
    { label: 'Nabire', value: 'Nabire' },
    { label: 'Nabire Barat', value: 'Nabire Barat' },
    { label: 'Napan', value: 'Napan' },
    { label: 'Siriwo', value: 'Siriwo' },
    { label: 'Teluk Kimi', value: 'Teluk Kimi' },
    { label: 'Teluk Umar', value: 'Teluk Umar' },
    { label: 'Uwapa', value: 'Uwapa' },
    { label: 'Wanggar', value: 'Wanggar' },
    { label: 'Wapoga', value: 'Wapoga' },
    { label: 'Yaro (Yaro Kabisay)', value: 'Yaro (Yaro Kabisay)' },
    { label: 'Yaur', value: 'Yaur' }
  ],
  Nduga: [
    { label: 'Alama', value: 'Alama' },
    { label: 'Dal', value: 'Dal' },
    { label: 'Embetpen', value: 'Embetpen' },
    { label: 'Gearek', value: 'Gearek' },
    { label: 'Geselma (Geselema)', value: 'Geselma (Geselema)' },
    { label: 'Inikgal', value: 'Inikgal' },
    { label: 'Iniye', value: 'Iniye' },
    { label: 'Kegayem', value: 'Kegayem' },
    { label: 'Kenyam', value: 'Kenyam' },
    { label: 'Kilmid', value: 'Kilmid' },
    { label: 'Kora', value: 'Kora' },
    { label: 'Koroptak', value: 'Koroptak' },
    { label: 'Krepkuri', value: 'Krepkuri' },
    { label: 'Mam', value: 'Mam' },
    { label: 'Mapenduma', value: 'Mapenduma' },
    { label: 'Mbua (Mbuga)', value: 'Mbua (Mbuga)' },
    { label: 'Mbua Tengah', value: 'Mbua Tengah' },
    { label: 'Mbulmu Yalma', value: 'Mbulmu Yalma' },
    { label: 'Mebarok', value: 'Mebarok' },
    { label: 'Moba', value: 'Moba' },
    { label: 'Mugi', value: 'Mugi' },
    { label: 'Nenggeagin', value: 'Nenggeagin' },
    { label: 'Nirkuri', value: 'Nirkuri' },
    { label: 'Paro', value: 'Paro' },
    { label: 'Pasir Putih', value: 'Pasir Putih' },
    { label: 'Pija', value: 'Pija' },
    { label: 'Wosak', value: 'Wosak' },
    { label: 'Wusi', value: 'Wusi' },
    { label: 'Wutpaga', value: 'Wutpaga' },
    { label: 'Yal', value: 'Yal' },
    { label: 'Yenggelo', value: 'Yenggelo' },
    { label: 'Yigi', value: 'Yigi' }
  ],
  Paniai: [
    { label: 'Aradide', value: 'Aradide' },
    { label: 'Bibida', value: 'Bibida' },
    { label: 'Bogobaida', value: 'Bogobaida' },
    { label: 'Dumadama', value: 'Dumadama' },
    { label: 'Ekadide', value: 'Ekadide' },
    { label: 'Kebo', value: 'Kebo' },
    { label: 'Paniai Barat', value: 'Paniai Barat' },
    { label: 'Paniai Timur', value: 'Paniai Timur' },
    { label: 'Siriwo', value: 'Siriwo' },
    { label: 'Yatamo', value: 'Yatamo' }
  ],
  'Pegunungan Bintang': [
    { label: 'Aboy', value: 'Aboy' },
    { label: 'Alemsom', value: 'Alemsom' },
    { label: 'Awinbon', value: 'Awinbon' },
    { label: 'Batani', value: 'Batani' },
    { label: 'Batom', value: 'Batom' },
    { label: 'Bime', value: 'Bime' },
    { label: 'Borme', value: 'Borme' },
    { label: 'Eipumek', value: 'Eipumek' },
    { label: 'Iwur (Okiwur)', value: 'Iwur (Okiwur)' },
    { label: 'Jetfa', value: 'Jetfa' },
    { label: 'Kalomdol', value: 'Kalomdol' },
    { label: 'Kawor', value: 'Kawor' },
    { label: 'Kiwirok', value: 'Kiwirok' },
    { label: 'Kiwirok Timur', value: 'Kiwirok Timur' },
    { label: 'Mofinop', value: 'Mofinop' },
    { label: 'Murkim', value: 'Murkim' },
    { label: 'Nongme', value: 'Nongme' },
    { label: 'Ok Aom', value: 'Ok Aom' },
    { label: 'Okbab', value: 'Okbab' },
    { label: 'Okbape', value: 'Okbape' },
    { label: 'Okbemtau', value: 'Okbemtau' },
    { label: 'Okbibab', value: 'Okbibab' },
    { label: 'Okhika', value: 'Okhika' },
    { label: 'Oklip', value: 'Oklip' },
    { label: 'Oksamol', value: 'Oksamol' },
    { label: 'Oksebang', value: 'Oksebang' },
    { label: 'Oksibil', value: 'Oksibil' },
    { label: 'Oksop', value: 'Oksop' },
    { label: 'Pamek', value: 'Pamek' },
    { label: 'Pepera', value: 'Pepera' },
    { label: 'Serambakon', value: 'Serambakon' },
    { label: 'Tarup', value: 'Tarup' },
    { label: 'Teiraplu', value: 'Teiraplu' },
    { label: 'Weime', value: 'Weime' }
  ],
  Puncak: [
    { label: 'Agadugume', value: 'Agadugume' },
    { label: 'Beoga', value: 'Beoga' },
    { label: 'Doufu', value: 'Doufu' },
    { label: 'Gome', value: 'Gome' },
    { label: 'Ilaga', value: 'Ilaga' },
    { label: 'Pogoma', value: 'Pogoma' },
    { label: 'Sinak', value: 'Sinak' },
    { label: 'Wangbe', value: 'Wangbe' }
  ],
  'Puncak Jaya': [
    { label: 'Dagai', value: 'Dagai' },
    { label: 'Dokome', value: 'Dokome' },
    { label: 'Fawi', value: 'Fawi' },
    { label: 'Gubume', value: 'Gubume' },
    { label: 'Gurage', value: 'Gurage' },
    { label: 'Ilamburawi', value: 'Ilamburawi' },
    { label: 'Ilu', value: 'Ilu' },
    { label: 'Irimuli', value: 'Irimuli' },
    { label: 'Kalome', value: 'Kalome' },
    { label: 'Kiyage', value: 'Kiyage' },
    { label: 'Lumo', value: 'Lumo' },
    { label: 'Mewoluk', value: 'Mewoluk' },
    { label: 'Molanikime', value: 'Molanikime' },
    { label: 'Muara', value: 'Muara' },
    { label: 'Mulia', value: 'Mulia' },
    { label: 'Nioga', value: 'Nioga' },
    { label: 'Nume', value: 'Nume' },
    { label: 'Pagaleme', value: 'Pagaleme' },
    { label: 'Taganombak', value: 'Taganombak' },
    { label: 'Tingginambut', value: 'Tingginambut' },
    { label: 'Torere', value: 'Torere' },
    { label: 'Waegi', value: 'Waegi' },
    { label: 'Wanwi', value: 'Wanwi' },
    { label: 'Yambi', value: 'Yambi' },
    { label: 'Yamo', value: 'Yamo' },
    { label: 'Yamoneri', value: 'Yamoneri' }
  ],
  Sarmi: [
    { label: 'Apawer Hulu', value: 'Apawer Hulu' },
    { label: 'Bonggo', value: 'Bonggo' },
    { label: 'Bonggo Timur', value: 'Bonggo Timur' },
    { label: 'Pantai Barat', value: 'Pantai Barat' },
    { label: 'Pantai Timur', value: 'Pantai Timur' },
    {
      label: 'Pantai Timur Bagian Barat',
      value: 'Pantai Timur Bagian Barat'
    },
    { label: 'Sarmi', value: 'Sarmi' },
    { label: 'Sarmi Selatan', value: 'Sarmi Selatan' },
    { label: 'Sarmi Timur', value: 'Sarmi Timur' },
    { label: 'Tor Atas', value: 'Tor Atas' }
  ],
  Supiori: [
    { label: 'Kepulauan Aruri', value: 'Kepulauan Aruri' },
    { label: 'Supiori Barat', value: 'Supiori Barat' },
    { label: 'Supiori Selatan', value: 'Supiori Selatan' },
    { label: 'Supiori Timur', value: 'Supiori Timur' },
    { label: 'Supiori Utara', value: 'Supiori Utara' }
  ],
  Tolikara: [
    { label: 'Airgaram', value: 'Airgaram' },
    { label: 'Anawi', value: 'Anawi' },
    { label: 'Aweku', value: 'Aweku' },
    { label: 'Bewani', value: 'Bewani' },
    { label: 'Biuk', value: 'Biuk' },
    { label: 'Bogonuk', value: 'Bogonuk' },
    { label: 'Bokondini', value: 'Bokondini' },
    { label: 'Bokoneri', value: 'Bokoneri' },
    { label: 'Danime', value: 'Danime' },
    { label: 'Dow', value: 'Dow' },
    { label: 'Dundu (Ndundu)', value: 'Dundu (Ndundu)' },
    { label: 'Egiam', value: 'Egiam' },
    { label: 'Geya', value: 'Geya' },
    { label: 'Gika', value: 'Gika' },
    {
      label: 'Gilubandu (Gilumbandu/Gilimbandu)',
      value: 'Gilubandu (Gilumbandu/Gilimbandu)'
    },
    { label: 'Goyage', value: 'Goyage' },
    { label: 'Gundagi (Gudage)', value: 'Gundagi (Gudage)' },
    { label: 'Kai', value: 'Kai' },
    { label: 'Kamboneri', value: 'Kamboneri' },
    { label: 'Kanggime (Kanggima )', value: 'Kanggime (Kanggima )' },
    { label: 'Karubaga', value: 'Karubaga' },
    { label: 'Kembu', value: 'Kembu' },
    { label: 'Kondaga (Konda)', value: 'Kondaga (Konda)' },
    { label: 'Kuari', value: 'Kuari' },
    { label: 'Kubu', value: 'Kubu' },
    { label: 'Li Anogomma', value: 'Li Anogomma' },
    { label: 'Nabunage', value: 'Nabunage' },
    { label: 'Nelawi', value: 'Nelawi' },
    { label: 'Numba', value: 'Numba' },
    { label: 'Nunggawi (Munggawi)', value: 'Nunggawi (Munggawi)' },
    { label: 'Panaga', value: 'Panaga' },
    { label: 'Poganeri', value: 'Poganeri' },
    { label: 'Tagime', value: 'Tagime' },
    { label: 'Tagineri', value: 'Tagineri' },
    { label: 'Telenggeme', value: 'Telenggeme' },
    { label: 'Timori', value: 'Timori' },
    { label: 'Umagi', value: 'Umagi' },
    { label: 'Wakuwo', value: 'Wakuwo' },
    { label: 'Wari (Taiyeve)', value: 'Wari (Taiyeve)' },
    { label: 'Wenam', value: 'Wenam' },
    { label: 'Wina', value: 'Wina' },
    { label: 'Wonoki (Woniki)', value: 'Wonoki (Woniki)' },
    { label: 'Wugi', value: 'Wugi' },
    { label: 'Wunin (Wumin)', value: 'Wunin (Wumin)' },
    { label: 'Yuko', value: 'Yuko' },
    { label: 'Yuneri', value: 'Yuneri' }
  ],
  Waropen: [
    { label: 'Demba', value: 'Demba' },
    { label: 'Inggerus', value: 'Inggerus' },
    { label: 'Kirihi', value: 'Kirihi' },
    { label: 'Masirei', value: 'Masirei' },
    { label: 'Oudate', value: 'Oudate' },
    { label: 'Risei Sayati', value: 'Risei Sayati' },
    { label: 'Soyoi Mambai', value: 'Soyoi Mambai' },
    { label: 'Urei Faisei', value: 'Urei Faisei' },
    { label: 'Wapoga', value: 'Wapoga' },
    { label: 'Waropen Bawah', value: 'Waropen Bawah' },
    { label: 'Wonti', value: 'Wonti' }
  ],
  Yahukimo: [
    { label: 'Amuma', value: 'Amuma' },
    { label: 'Anggruk', value: 'Anggruk' },
    { label: 'Bomela', value: 'Bomela' },
    { label: 'Dekai', value: 'Dekai' },
    { label: 'Dirwemna (Diruwena)', value: 'Dirwemna (Diruwena)' },
    { label: 'Duram', value: 'Duram' },
    { label: 'Endomen', value: 'Endomen' },
    { label: 'Hereapini (Hereanini)', value: 'Hereapini (Hereanini)' },
    { label: 'Hilipuk', value: 'Hilipuk' },
    { label: 'Hogio (Hugio)', value: 'Hogio (Hugio)' },
    { label: 'Holuon', value: 'Holuon' },
    {
      label: 'Kabianggama (Kabianggema)',
      value: 'Kabianggama (Kabianggema)'
    },
    { label: 'Kayo', value: 'Kayo' },
    { label: 'Kona', value: 'Kona' },
    { label: 'Koropun (Korupun)', value: 'Koropun (Korupun)' },
    { label: 'Kosarek', value: 'Kosarek' },
    { label: 'Kurima', value: 'Kurima' },
    { label: 'Kwelemdua (Kwelamdua)', value: 'Kwelemdua (Kwelamdua)' },
    { label: 'Kwikma', value: 'Kwikma' },
    { label: 'Langda', value: 'Langda' },
    { label: 'Lolat', value: 'Lolat' },
    { label: 'Mugi', value: 'Mugi' },
    { label: 'Musaik', value: 'Musaik' },
    { label: 'Nalca', value: 'Nalca' },
    { label: 'Ninia', value: 'Ninia' },
    { label: 'Nipsan', value: 'Nipsan' },
    { label: 'Obio', value: 'Obio' },
    { label: 'Panggema', value: 'Panggema' },
    { label: 'Pasema', value: 'Pasema' },
    { label: 'Pronggoli (Proggoli)', value: 'Pronggoli (Proggoli)' },
    { label: 'Puldama', value: 'Puldama' },
    { label: 'Samenage', value: 'Samenage' },
    { label: 'Sela', value: 'Sela' },
    { label: 'Seredela (Seredala)', value: 'Seredela (Seredala)' },
    { label: 'Silimo', value: 'Silimo' },
    { label: 'Soba', value: 'Soba' },
    { label: 'Sobaham', value: 'Sobaham' },
    { label: 'Soloikma', value: 'Soloikma' },
    { label: 'Sumo', value: 'Sumo' },
    { label: 'Suntamon', value: 'Suntamon' },
    { label: 'Suru Suru', value: 'Suru Suru' },
    { label: 'Talambo', value: 'Talambo' },
    { label: 'Tangma', value: 'Tangma' },
    { label: 'Ubahak', value: 'Ubahak' },
    { label: 'Ubalihi', value: 'Ubalihi' },
    { label: 'Ukha', value: 'Ukha' },
    { label: 'Walma', value: 'Walma' },
    { label: 'Werima', value: 'Werima' },
    { label: 'Wusuma', value: 'Wusuma' },
    { label: 'Yahuliambut', value: 'Yahuliambut' },
    { label: 'Yogosem', value: 'Yogosem' }
  ],
  Yalimo: [
    { label: 'Abenaho', value: 'Abenaho' },
    { label: 'Apalapsili', value: 'Apalapsili' },
    { label: 'Benawa', value: 'Benawa' },
    { label: 'Elelim', value: 'Elelim' },
    { label: 'Welarek', value: 'Welarek' }
  ],
  Fakfak: [
    { label: 'Arguni', value: 'Arguni' },
    { label: 'Bomberay', value: 'Bomberay' },
    { label: 'Fakfak', value: 'Fakfak' },
    { label: 'Fakfak Barat', value: 'Fakfak Barat' },
    { label: 'Fakfak Tengah', value: 'Fakfak Tengah' },
    { label: 'Fakfak Timur', value: 'Fakfak Timur' },
    { label: 'Fakfak Timur Tengah', value: 'Fakfak Timur Tengah' },
    { label: 'Furwagi', value: 'Furwagi' },
    { label: 'Karas', value: 'Karas' },
    { label: 'Kayauni', value: 'Kayauni' },
    { label: 'Kokas', value: 'Kokas' },
    { label: 'Kramongmongga', value: 'Kramongmongga' },
    { label: 'Mbahamdandara', value: 'Mbahamdandara' },
    { label: 'Pariwari', value: 'Pariwari' },
    { label: 'Teluk Patipi', value: 'Teluk Patipi' },
    { label: 'Tomage', value: 'Tomage' },
    { label: 'Wartutin', value: 'Wartutin' }
  ],
  Kaimana: [
    { label: 'Buruway', value: 'Buruway' },
    { label: 'Kaimana', value: 'Kaimana' },
    { label: 'Kambraw (Kamberau)', value: 'Kambraw (Kamberau)' },
    { label: 'Teluk Arguni Atas', value: 'Teluk Arguni Atas' },
    {
      label: 'Teluk Arguni Bawah (Yerusi)',
      value: 'Teluk Arguni Bawah (Yerusi)'
    },
    { label: 'Teluk Etna', value: 'Teluk Etna' },
    { label: 'Yamor', value: 'Yamor' }
  ],
  Manokwari: [
    { label: 'Manokwari Barat', value: 'Manokwari Barat' },
    { label: 'Manokwari Selatan', value: 'Manokwari Selatan' },
    { label: 'Manokwari Timur', value: 'Manokwari Timur' },
    { label: 'Manokwari Utara', value: 'Manokwari Utara' },
    { label: 'Masni', value: 'Masni' },
    { label: 'Prafi', value: 'Prafi' },
    { label: 'Sidey', value: 'Sidey' },
    { label: 'Tanah Rubuh', value: 'Tanah Rubuh' },
    { label: 'Warmare', value: 'Warmare' }
  ],
  'Manokwari Selatan': [
    { label: 'Dataran Isim', value: 'Dataran Isim' },
    { label: 'Momi Waren', value: 'Momi Waren' },
    { label: 'Neney', value: 'Neney' },
    { label: 'Oransbari', value: 'Oransbari' },
    { label: 'Ransiki', value: 'Ransiki' },
    { label: 'Tahota', value: 'Tahota' }
  ],
  Maybrat: [
    { label: 'Aifat', value: 'Aifat' },
    { label: 'Aifat Selatan', value: 'Aifat Selatan' },
    { label: 'Aifat Timur', value: 'Aifat Timur' },
    { label: 'Aifat Timur Jauh', value: 'Aifat Timur Jauh' },
    { label: 'Aifat Timur Selatan', value: 'Aifat Timur Selatan' },
    { label: 'Aifat Timur Tengah', value: 'Aifat Timur Tengah' },
    { label: 'Aifat Utara', value: 'Aifat Utara' },
    { label: 'Aitinyo', value: 'Aitinyo' },
    { label: 'Aitinyo Barat', value: 'Aitinyo Barat' },
    { label: 'Aitinyo Raya', value: 'Aitinyo Raya' },
    { label: 'Aitinyo Tengah', value: 'Aitinyo Tengah' },
    { label: 'Aitinyo Utara', value: 'Aitinyo Utara' },
    { label: 'Ayamaru', value: 'Ayamaru' },
    { label: 'Ayamaru Barat', value: 'Ayamaru Barat' },
    { label: 'Ayamaru Jaya', value: 'Ayamaru Jaya' },
    { label: 'Ayamaru Selatan', value: 'Ayamaru Selatan' },
    { label: 'Ayamaru Selatan Jaya', value: 'Ayamaru Selatan Jaya' },
    { label: 'Ayamaru Tengah', value: 'Ayamaru Tengah' },
    { label: 'Ayamaru Timur', value: 'Ayamaru Timur' },
    { label: 'Ayamaru Timur Selatan', value: 'Ayamaru Timur Selatan' },
    { label: 'Ayamaru Utara', value: 'Ayamaru Utara' },
    { label: 'Ayamaru Utara Timur', value: 'Ayamaru Utara Timur' },
    { label: 'Mare', value: 'Mare' },
    { label: 'Mare Selatan', value: 'Mare Selatan' }
  ],
  'Pegunungan Arfak': [
    { label: 'Anggi', value: 'Anggi' },
    { label: 'Anggi Gida', value: 'Anggi Gida' },
    { label: 'Catubouw', value: 'Catubouw' },
    { label: 'Didohu', value: 'Didohu' },
    { label: 'Hingk', value: 'Hingk' },
    { label: 'Membey', value: 'Membey' },
    { label: 'Minyambaouw', value: 'Minyambaouw' },
    { label: 'Sururey', value: 'Sururey' },
    { label: 'Taige', value: 'Taige' },
    { label: 'Testega', value: 'Testega' }
  ],
  'Raja Ampat': [
    { label: 'Ayau', value: 'Ayau' },
    { label: 'Batanta Selatan', value: 'Batanta Selatan' },
    { label: 'Batanta Utara', value: 'Batanta Utara' },
    { label: 'Kepulauan Ayau', value: 'Kepulauan Ayau' },
    { label: 'Kepulauan Sembilan', value: 'Kepulauan Sembilan' },
    { label: 'Kofiau', value: 'Kofiau' },
    { label: 'Kota Waisai', value: 'Kota Waisai' },
    { label: 'Meos Mansar', value: 'Meos Mansar' },
    { label: 'Misool (Misool Utara)', value: 'Misool (Misool Utara)' },
    { label: 'Misool Barat', value: 'Misool Barat' },
    { label: 'Misool Selatan', value: 'Misool Selatan' },
    { label: 'Misool Timur', value: 'Misool Timur' },
    { label: 'Salawati Barat', value: 'Salawati Barat' },
    { label: 'Salawati Tengah', value: 'Salawati Tengah' },
    { label: 'Salawati Utara (Samate)', value: 'Salawati Utara (Samate)' },
    { label: 'Supnin', value: 'Supnin' },
    { label: 'Teluk Mayalibit', value: 'Teluk Mayalibit' },
    { label: 'Tiplol Mayalibit', value: 'Tiplol Mayalibit' },
    { label: 'Waigeo Barat', value: 'Waigeo Barat' },
    { label: 'Waigeo Barat Kepulauan', value: 'Waigeo Barat Kepulauan' },
    { label: 'Waigeo Selatan', value: 'Waigeo Selatan' },
    { label: 'Waigeo Timur', value: 'Waigeo Timur' },
    { label: 'Waigeo Utara', value: 'Waigeo Utara' },
    { label: 'Warwabomi', value: 'Warwabomi' }
  ],
  Sorong: [
    { label: 'Klaurung', value: 'Klaurung' },
    { label: 'Maladum Mes', value: 'Maladum Mes' },
    { label: 'Malaimsimsa', value: 'Malaimsimsa' },
    { label: 'Sorong', value: 'Sorong' },
    { label: 'Sorong Barat', value: 'Sorong Barat' },
    { label: 'Sorong Kepulauan', value: 'Sorong Kepulauan' },
    { label: 'Sorong Kota', value: 'Sorong Kota' },
    { label: 'Sorong Manoi', value: 'Sorong Manoi' },
    { label: 'Sorong Timur', value: 'Sorong Timur' },
    { label: 'Sorong Utara', value: 'Sorong Utara' },
    { label: 'Aimas', value: 'Aimas' },
    { label: 'Beraur', value: 'Beraur' },
    { label: 'Klabot', value: 'Klabot' },
    { label: 'Klamono', value: 'Klamono' },
    { label: 'Klaso', value: 'Klaso' },
    { label: 'Klawak', value: 'Klawak' },
    { label: 'Klayili', value: 'Klayili' },
    { label: 'Makbon', value: 'Makbon' },
    { label: 'Mariat', value: 'Mariat' },
    { label: 'Maudus', value: 'Maudus' },
    { label: 'Mayamuk', value: 'Mayamuk' },
    { label: 'Moisegen', value: 'Moisegen' },
    { label: 'Salawati', value: 'Salawati' },
    { label: 'Salawati Selatan', value: 'Salawati Selatan' },
    { label: 'Sayosa', value: 'Sayosa' },
    { label: 'Seget', value: 'Seget' },
    { label: 'Segun', value: 'Segun' }
  ],
  'Sorong Selatan': [
    { label: 'Fokour', value: 'Fokour' },
    { label: 'Inanwatan (Inawatan)', value: 'Inanwatan (Inawatan)' },
    { label: 'Kais (Matemani Kais)', value: 'Kais (Matemani Kais)' },
    { label: 'Kokoda', value: 'Kokoda' },
    { label: 'Kokoda Utara', value: 'Kokoda Utara' },
    { label: 'Konda', value: 'Konda' },
    { label: 'Matemani', value: 'Matemani' },
    { label: 'Moswaren', value: 'Moswaren' },
    { label: 'Saifi', value: 'Saifi' },
    { label: 'Sawiat', value: 'Sawiat' },
    { label: 'Seremuk', value: 'Seremuk' },
    { label: 'Teminabuan', value: 'Teminabuan' },
    { label: 'Wayer', value: 'Wayer' }
  ],
  Tambrauw: [
    { label: 'Abun', value: 'Abun' },
    { label: 'Amberbaken', value: 'Amberbaken' },
    { label: 'Amberbaken Barat', value: 'Amberbaken Barat' },
    { label: 'Ases', value: 'Ases' },
    { label: 'Bamusbama', value: 'Bamusbama' },
    { label: 'Bikar', value: 'Bikar' },
    { label: 'Fef', value: 'Fef' },
    { label: 'Ireres', value: 'Ireres' },
    { label: 'Kasi', value: 'Kasi' },
    { label: 'Kebar', value: 'Kebar' },
    { label: 'Kebar Selatan', value: 'Kebar Selatan' },
    { label: 'Kebar Timur', value: 'Kebar Timur' },
    { label: 'Kwesefo', value: 'Kwesefo' },
    { label: 'Kwoor', value: 'Kwoor' },
    { label: 'Manekar', value: 'Manekar' },
    { label: 'Mawabuan', value: 'Mawabuan' },
    { label: 'Miyah', value: 'Miyah' },
    { label: 'Miyah Selatan', value: 'Miyah Selatan' },
    { label: 'Moraid', value: 'Moraid' },
    { label: 'Mpur', value: 'Mpur' },
    { label: 'Mubrani', value: 'Mubrani' },
    { label: 'Sausapor', value: 'Sausapor' },
    { label: 'Selemkai', value: 'Selemkai' },
    { label: 'Senopi', value: 'Senopi' },
    { label: 'Syujak', value: 'Syujak' },
    { label: 'Tinggouw', value: 'Tinggouw' },
    { label: 'Tobouw', value: 'Tobouw' },
    { label: 'Wilhem Roumbouts', value: 'Wilhem Roumbouts' },
    { label: 'Yembun', value: 'Yembun' }
  ],
  'Teluk Bintuni': [
    { label: 'Aranday', value: 'Aranday' },
    { label: 'Aroba', value: 'Aroba' },
    { label: 'Babo', value: 'Babo' },
    { label: 'Bintuni', value: 'Bintuni' },
    { label: 'Biscoop', value: 'Biscoop' },
    { label: 'Dataran Beimes', value: 'Dataran Beimes' },
    { label: 'Fafurwar (Irorutu)', value: 'Fafurwar (Irorutu)' },
    { label: 'Kaitaro', value: 'Kaitaro' },
    { label: 'Kamundan', value: 'Kamundan' },
    { label: 'Kuri', value: 'Kuri' },
    { label: 'Manimeri', value: 'Manimeri' },
    { label: 'Masyeta', value: 'Masyeta' },
    { label: 'Mayado', value: 'Mayado' },
    { label: 'Merdey', value: 'Merdey' },
    { label: 'Moskona Barat', value: 'Moskona Barat' },
    { label: 'Moskona Selatan', value: 'Moskona Selatan' },
    { label: 'Moskona Timur', value: 'Moskona Timur' },
    { label: 'Moskona Utara', value: 'Moskona Utara' },
    { label: 'Sumuri (Simuri)', value: 'Sumuri (Simuri)' },
    { label: 'Tembuni', value: 'Tembuni' },
    { label: 'Tomu', value: 'Tomu' },
    { label: 'Tuhiba', value: 'Tuhiba' },
    { label: 'Wamesa (Idoor)', value: 'Wamesa (Idoor)' },
    { label: 'Weriagar', value: 'Weriagar' }
  ],
  'Teluk Wondama': [
    { label: 'Kuri Wamesa', value: 'Kuri Wamesa' },
    { label: 'Naikere (Wasior Barat)', value: 'Naikere (Wasior Barat)' },
    { label: 'Nikiwar', value: 'Nikiwar' },
    { label: 'Rasiei', value: 'Rasiei' },
    { label: 'Roon', value: 'Roon' },
    { label: 'Roswar', value: 'Roswar' },
    { label: 'Rumberpon', value: 'Rumberpon' },
    { label: 'Soug Jaya', value: 'Soug Jaya' },
    {
      label: 'Teluk Duairi (Wasior Utara)',
      value: 'Teluk Duairi (Wasior Utara)'
    },
    { label: 'Wamesa', value: 'Wamesa' },
    { label: 'Wasior', value: 'Wasior' },
    { label: 'Windesi', value: 'Windesi' },
    {
      label: 'Wondiboy (Wasior Selatan)',
      value: 'Wondiboy (Wasior Selatan)'
    }
  ],
  Bengkalis: [
    { label: 'Bantan', value: 'Bantan' },
    { label: 'Bengkalis', value: 'Bengkalis' },
    { label: 'Bukit Batu', value: 'Bukit Batu' },
    { label: 'Mandau', value: 'Mandau' },
    { label: 'Pinggir', value: 'Pinggir' },
    { label: 'Rupat', value: 'Rupat' },
    { label: 'Rupat Utara', value: 'Rupat Utara' },
    { label: 'Siak Kecil', value: 'Siak Kecil' }
  ],
  Dumai: [
    { label: 'Bukit Kapur', value: 'Bukit Kapur' },
    { label: 'Dumai Barat', value: 'Dumai Barat' },
    { label: 'Dumai Kota', value: 'Dumai Kota' },
    { label: 'Dumai Selatan', value: 'Dumai Selatan' },
    { label: 'Dumai Timur', value: 'Dumai Timur' },
    { label: 'Medang Kampai', value: 'Medang Kampai' },
    { label: 'Sungai Sembilan', value: 'Sungai Sembilan' }
  ],
  'Indragiri Hilir': [
    { label: 'Batang Tuaka', value: 'Batang Tuaka' },
    { label: 'Concong', value: 'Concong' },
    { label: 'Enok', value: 'Enok' },
    { label: 'Gaung', value: 'Gaung' },
    { label: 'Gaung Anak Serka', value: 'Gaung Anak Serka' },
    { label: 'Kateman', value: 'Kateman' },
    { label: 'Kempas', value: 'Kempas' },
    { label: 'Kemuning', value: 'Kemuning' },
    { label: 'Keritang', value: 'Keritang' },
    { label: 'Kuala Indragiri', value: 'Kuala Indragiri' },
    { label: 'Mandah', value: 'Mandah' },
    { label: 'Pelangiran', value: 'Pelangiran' },
    { label: 'Pulau Burung', value: 'Pulau Burung' },
    { label: 'Reteh', value: 'Reteh' },
    { label: 'Sungai Batang', value: 'Sungai Batang' },
    { label: 'Tanah Merah', value: 'Tanah Merah' },
    { label: 'Teluk Belengkong', value: 'Teluk Belengkong' },
    { label: 'Tembilahan', value: 'Tembilahan' },
    { label: 'Tembilahan Hulu', value: 'Tembilahan Hulu' },
    { label: 'Tempuling', value: 'Tempuling' }
  ],
  'Indragiri Hulu': [
    { label: 'Batang Cenaku', value: 'Batang Cenaku' },
    { label: 'Batang Gansal', value: 'Batang Gansal' },
    { label: 'Batang Peranap', value: 'Batang Peranap' },
    { label: 'Kelayang', value: 'Kelayang' },
    { label: 'Kuala Cenaku', value: 'Kuala Cenaku' },
    { label: 'Lirik', value: 'Lirik' },
    { label: 'Lubuk Batu Jaya', value: 'Lubuk Batu Jaya' },
    { label: 'Pasir Penyu', value: 'Pasir Penyu' },
    { label: 'Peranap', value: 'Peranap' },
    { label: 'Rakit Kulim', value: 'Rakit Kulim' },
    { label: 'Rengat', value: 'Rengat' },
    { label: 'Rengat Barat', value: 'Rengat Barat' },
    { label: 'Seberida', value: 'Seberida' },
    { label: 'Sungai Lala', value: 'Sungai Lala' }
  ],
  Kampar: [
    { label: 'Bangkinang', value: 'Bangkinang' },
    { label: 'Bangkinang Kota', value: 'Bangkinang Kota' },
    { label: 'Gunung Sahilan', value: 'Gunung Sahilan' },
    { label: 'Kampar', value: 'Kampar' },
    { label: 'Kampar Kiri', value: 'Kampar Kiri' },
    { label: 'Kampar Kiri Hilir', value: 'Kampar Kiri Hilir' },
    { label: 'Kampar Kiri Hulu', value: 'Kampar Kiri Hulu' },
    { label: 'Kampar Kiri Tengah', value: 'Kampar Kiri Tengah' },
    { label: 'Kampar Timur', value: 'Kampar Timur' },
    { label: 'Kampar Utara', value: 'Kampar Utara' },
    { label: 'Koto Kampar Hulu', value: 'Koto Kampar Hulu' },
    { label: 'Kuok', value: 'Kuok' },
    { label: 'Perhentian Raja', value: 'Perhentian Raja' },
    { label: 'Rumbio Jaya', value: 'Rumbio Jaya' },
    { label: 'Salo', value: 'Salo' },
    { label: 'Siak Hulu', value: 'Siak Hulu' },
    { label: 'Tambang', value: 'Tambang' },
    { label: 'Tapung', value: 'Tapung' },
    { label: 'Tapung Hilir', value: 'Tapung Hilir' },
    { label: 'Tapung Hulu', value: 'Tapung Hulu' },
    { label: 'XIII Koto Kampar', value: 'XIII Koto Kampar' }
  ],
  'Kepulauan Meranti': [
    { label: 'Merbau', value: 'Merbau' },
    { label: 'Pulaumerbau', value: 'Pulaumerbau' },
    { label: 'Rangsang', value: 'Rangsang' },
    { label: 'Rangsang Barat', value: 'Rangsang Barat' },
    { label: 'Rangsang Pesisir', value: 'Rangsang Pesisir' },
    { label: 'Tasik Putri Puyu', value: 'Tasik Putri Puyu' },
    { label: 'Tebing Tinggi', value: 'Tebing Tinggi' },
    { label: 'Tebing Tinggi Barat', value: 'Tebing Tinggi Barat' },
    { label: 'Tebing Tinggi Timur', value: 'Tebing Tinggi Timur' }
  ],
  'Kuantan Singingi': [
    { label: 'Benai', value: 'Benai' },
    { label: 'Cerenti', value: 'Cerenti' },
    { label: 'Gunung Toar', value: 'Gunung Toar' },
    { label: 'Hulu Kuantan', value: 'Hulu Kuantan' },
    { label: 'Inuman', value: 'Inuman' },
    { label: 'Kuantan Hilir', value: 'Kuantan Hilir' },
    { label: 'Kuantan Hilir Seberang', value: 'Kuantan Hilir Seberang' },
    { label: 'Kuantan Mudik', value: 'Kuantan Mudik' },
    { label: 'Kuantan Tengah', value: 'Kuantan Tengah' },
    { label: 'Logas Tanah Darat', value: 'Logas Tanah Darat' },
    { label: 'Pangean', value: 'Pangean' },
    { label: 'Pucuk Rantau', value: 'Pucuk Rantau' },
    { label: 'Sentajo Raya', value: 'Sentajo Raya' },
    { label: 'Singingi', value: 'Singingi' },
    { label: 'Singingi Hilir', value: 'Singingi Hilir' }
  ],
  Pekanbaru: [
    { label: 'Bukit Raya', value: 'Bukit Raya' },
    { label: 'Lima Puluh', value: 'Lima Puluh' },
    { label: 'Marpoyan Damai', value: 'Marpoyan Damai' },
    { label: 'Payung Sekaki', value: 'Payung Sekaki' },
    { label: 'Pekanbaru Kota', value: 'Pekanbaru Kota' },
    { label: 'Rumbai', value: 'Rumbai' },
    { label: 'Rumbai Pesisir', value: 'Rumbai Pesisir' },
    { label: 'Sail', value: 'Sail' },
    { label: 'Senapelan', value: 'Senapelan' },
    { label: 'Sukajadi', value: 'Sukajadi' },
    { label: 'Tampan', value: 'Tampan' },
    { label: 'Tenayan Raya', value: 'Tenayan Raya' }
  ],
  Pelalawan: [
    { label: 'Bandar Petalangan', value: 'Bandar Petalangan' },
    { label: 'Bandar Sei Kijang', value: 'Bandar Sei Kijang' },
    { label: 'Bunut', value: 'Bunut' },
    { label: 'Kerumutan', value: 'Kerumutan' },
    { label: 'Kuala Kampar', value: 'Kuala Kampar' },
    { label: 'Langgam', value: 'Langgam' },
    { label: 'Pangkalan Kerinci', value: 'Pangkalan Kerinci' },
    { label: 'Pangkalan Kuras', value: 'Pangkalan Kuras' },
    { label: 'Pangkalan Lesung', value: 'Pangkalan Lesung' },
    { label: 'Pelalawan', value: 'Pelalawan' },
    { label: 'Teluk Meranti', value: 'Teluk Meranti' },
    { label: 'Ukui', value: 'Ukui' }
  ],
  'Rokan Hilir': [
    { label: 'Bagan Sinembah', value: 'Bagan Sinembah' },
    { label: 'Bangko', value: 'Bangko' },
    { label: 'Bangko Pusaka (Pusako)', value: 'Bangko Pusaka (Pusako)' },
    { label: 'Batu Hampar', value: 'Batu Hampar' },
    { label: 'Kubu', value: 'Kubu' },
    { label: 'Kubu Babussalam', value: 'Kubu Babussalam' },
    { label: 'Pasir Limau Kapas', value: 'Pasir Limau Kapas' },
    { label: 'Pekaitan', value: 'Pekaitan' },
    { label: 'Pujud', value: 'Pujud' },
    { label: 'Rantau Kopar', value: 'Rantau Kopar' },
    { label: 'Rimba Melintang', value: 'Rimba Melintang' },
    { label: 'Simpang Kanan', value: 'Simpang Kanan' },
    { label: 'Sinaboi (Senaboi)', value: 'Sinaboi (Senaboi)' },
    { label: 'Tanah Putih', value: 'Tanah Putih' },
    {
      label: 'Tanah Putih Tanjung Melawan',
      value: 'Tanah Putih Tanjung Melawan'
    }
  ],
  'Rokan Hulu': [
    { label: 'Bangun Purba', value: 'Bangun Purba' },
    { label: 'Bonai Darussalam', value: 'Bonai Darussalam' },
    { label: 'Kabun', value: 'Kabun' },
    { label: 'Kepenuhan', value: 'Kepenuhan' },
    { label: 'Kepenuhan Hulu', value: 'Kepenuhan Hulu' },
    { label: 'Kunto Darussalam', value: 'Kunto Darussalam' },
    {
      label: 'Pagaran Tapah Darussalam',
      value: 'Pagaran Tapah Darussalam'
    },
    { label: 'Pendalian IV Koto', value: 'Pendalian IV Koto' },
    { label: 'Rambah', value: 'Rambah' },
    { label: 'Rambah Hilir', value: 'Rambah Hilir' },
    { label: 'Rambah Samo', value: 'Rambah Samo' },
    { label: 'Rokan IV Koto', value: 'Rokan IV Koto' },
    { label: 'Tambusai', value: 'Tambusai' },
    { label: 'Tambusai Utara', value: 'Tambusai Utara' },
    { label: 'Tandun', value: 'Tandun' },
    { label: 'Ujung Batu', value: 'Ujung Batu' }
  ],
  Siak: [
    { label: 'Bunga Raya', value: 'Bunga Raya' },
    { label: 'Dayun', value: 'Dayun' },
    { label: 'Kandis', value: 'Kandis' },
    { label: 'Kerinci Kanan', value: 'Kerinci Kanan' },
    { label: 'Koto Gasib', value: 'Koto Gasib' },
    { label: 'Lubuk Dalam', value: 'Lubuk Dalam' },
    { label: 'Mempura', value: 'Mempura' },
    { label: 'Minas', value: 'Minas' },
    { label: 'Pusako', value: 'Pusako' },
    { label: 'Sabak Auh', value: 'Sabak Auh' },
    { label: 'Siak', value: 'Siak' },
    { label: 'Sungai Apit', value: 'Sungai Apit' },
    { label: 'Sungai Mandau', value: 'Sungai Mandau' },
    { label: 'Tualang', value: 'Tualang' }
  ],
  Majene: [
    { label: 'Banggae', value: 'Banggae' },
    { label: 'Banggae Timur', value: 'Banggae Timur' },
    { label: 'Malunda', value: 'Malunda' },
    { label: 'Pamboang', value: 'Pamboang' },
    { label: 'Sendana', value: 'Sendana' },
    { label: 'Tammeredo Sendana', value: 'Tammeredo Sendana' },
    { label: 'Tubo (Tubo Sendana)', value: 'Tubo (Tubo Sendana)' },
    { label: 'Ulumunda', value: 'Ulumunda' }
  ],
  Mamasa: [
    { label: 'Aralle', value: 'Aralle' },
    { label: 'Balla', value: 'Balla' },
    { label: 'Bambang', value: 'Bambang' },
    { label: 'Buntumalangka', value: 'Buntumalangka' },
    { label: 'Mamasa', value: 'Mamasa' },
    { label: 'Mambi', value: 'Mambi' },
    { label: 'Mehalaan', value: 'Mehalaan' },
    { label: 'Messawa', value: 'Messawa' },
    { label: 'Nosu', value: 'Nosu' },
    { label: 'Pana', value: 'Pana' },
    { label: 'Rantebulahan Timur', value: 'Rantebulahan Timur' },
    { label: 'Sesenapadang', value: 'Sesenapadang' },
    { label: 'Sumarorong', value: 'Sumarorong' },
    { label: 'Tabang', value: 'Tabang' },
    { label: 'Tabulahan', value: 'Tabulahan' },
    { label: 'Tanduk Kalua', value: 'Tanduk Kalua' },
    { label: 'Tawalian', value: 'Tawalian' }
  ],
  Mamuju: [
    { label: 'Bonehau', value: 'Bonehau' },
    { label: 'Kalukku', value: 'Kalukku' },
    { label: 'Kalumpang', value: 'Kalumpang' },
    { label: 'Kep. Bala Balakang', value: 'Kep. Bala Balakang' },
    { label: 'Mamuju', value: 'Mamuju' },
    { label: 'Papalang', value: 'Papalang' },
    { label: 'Sampaga', value: 'Sampaga' },
    { label: 'Simboro dan Kepulauan', value: 'Simboro dan Kepulauan' },
    { label: 'Tapalang', value: 'Tapalang' },
    { label: 'Tapalang Barat', value: 'Tapalang Barat' },
    { label: 'Tommo', value: 'Tommo' }
  ],
  'Mamuju Tengah': [
    { label: 'Budong-Budong', value: 'Budong-Budong' },
    { label: 'Karossa', value: 'Karossa' },
    { label: 'Pangale', value: 'Pangale' },
    { label: 'Tobadak', value: 'Tobadak' },
    { label: 'Topoyo', value: 'Topoyo' }
  ],
  'Mamuju Utara': [
    { label: 'Bambaira', value: 'Bambaira' },
    { label: 'Bambalamotu', value: 'Bambalamotu' },
    { label: 'Baras', value: 'Baras' },
    { label: 'Bulu Taba', value: 'Bulu Taba' },
    { label: 'Dapurang', value: 'Dapurang' },
    { label: 'Duripoku', value: 'Duripoku' },
    { label: 'Lariang', value: 'Lariang' },
    { label: 'Pasangkayu', value: 'Pasangkayu' },
    { label: 'Pedongga', value: 'Pedongga' },
    { label: 'Sarjo', value: 'Sarjo' },
    { label: 'Sarudu', value: 'Sarudu' },
    { label: 'Tikke Raya', value: 'Tikke Raya' }
  ],
  'Polewali Mandar': [
    { label: 'Alu (Allu)', value: 'Alu (Allu)' },
    { label: 'Anreapi', value: 'Anreapi' },
    { label: 'Balanipa', value: 'Balanipa' },
    { label: 'Binuang', value: 'Binuang' },
    { label: 'Bulo', value: 'Bulo' },
    { label: 'Campalagian', value: 'Campalagian' },
    { label: 'Limboro', value: 'Limboro' },
    { label: 'Luyo', value: 'Luyo' },
    { label: 'Mapilli', value: 'Mapilli' },
    { label: 'Matakali', value: 'Matakali' },
    { label: 'Matangnga', value: 'Matangnga' },
    { label: 'Polewali', value: 'Polewali' },
    { label: 'Tapango', value: 'Tapango' },
    { label: 'Tinambung', value: 'Tinambung' },
    {
      label: 'Tubbi Taramanu (Tutar/Tutallu)',
      value: 'Tubbi Taramanu (Tutar/Tutallu)'
    },
    { label: 'Wonomulyo', value: 'Wonomulyo' }
  ],
  Bantaeng: [
    { label: 'Bantaeng', value: 'Bantaeng' },
    { label: 'Bissappu', value: 'Bissappu' },
    { label: 'Eremerasa', value: 'Eremerasa' },
    {
      label: 'Gantarang Keke (Gantareng Keke)',
      value: 'Gantarang Keke (Gantareng Keke)'
    },
    { label: 'Pajukukang', value: 'Pajukukang' },
    { label: 'Sinoa', value: 'Sinoa' },
    { label: 'Tompobulu', value: 'Tompobulu' },
    { label: 'Uluere', value: 'Uluere' }
  ],
  Barru: [
    { label: 'Balusu', value: 'Balusu' },
    { label: 'Barru', value: 'Barru' },
    { label: 'Mallusetasi', value: 'Mallusetasi' },
    { label: 'Pujananting', value: 'Pujananting' },
    { label: 'Soppeng Riaja', value: 'Soppeng Riaja' },
    { label: 'Tanete Riaja', value: 'Tanete Riaja' },
    { label: 'Tanete Rilau', value: 'Tanete Rilau' }
  ],
  Bone: [
    { label: 'Ajangale', value: 'Ajangale' },
    { label: 'Amali', value: 'Amali' },
    { label: 'Awangpone', value: 'Awangpone' },
    { label: 'Barebbo', value: 'Barebbo' },
    { label: 'Bengo', value: 'Bengo' },
    { label: 'Bontocani', value: 'Bontocani' },
    { label: 'Cenrana', value: 'Cenrana' },
    { label: 'Cina', value: 'Cina' },
    { label: 'Dua Boccoe', value: 'Dua Boccoe' },
    { label: 'Kahu', value: 'Kahu' },
    { label: 'Kajuara', value: 'Kajuara' },
    { label: 'Lamuru', value: 'Lamuru' },
    { label: 'Lappariaja', value: 'Lappariaja' },
    { label: 'Libureng', value: 'Libureng' },
    { label: 'Mare', value: 'Mare' },
    { label: 'Palakka', value: 'Palakka' },
    { label: 'Patimpeng', value: 'Patimpeng' },
    { label: 'Ponre', value: 'Ponre' },
    { label: 'Salomekko', value: 'Salomekko' },
    { label: 'Sibulue', value: 'Sibulue' },
    { label: 'Tanete Riattang', value: 'Tanete Riattang' },
    { label: 'Tanete Riattang Barat', value: 'Tanete Riattang Barat' },
    { label: 'Tanete Riattang Timur', value: 'Tanete Riattang Timur' },
    { label: 'Tellu Limpoe', value: 'Tellu Limpoe' },
    { label: 'Tellu Siattinge', value: 'Tellu Siattinge' },
    { label: 'Tonra', value: 'Tonra' },
    { label: 'Ulaweng', value: 'Ulaweng' }
  ],
  Bulukumba: [
    { label: 'Bonto Bahari', value: 'Bonto Bahari' },
    { label: 'Bontotiro', value: 'Bontotiro' },
    { label: 'Bulukumba (Bulukumpa)', value: 'Bulukumba (Bulukumpa)' },
    {
      label: 'Gantorang/Gantarang (Gangking)',
      value: 'Gantorang/Gantarang (Gangking)'
    },
    {
      label: 'Hero Lange-Lange (Herlang)',
      value: 'Hero Lange-Lange (Herlang)'
    },
    { label: 'Kajang', value: 'Kajang' },
    { label: 'Kindang', value: 'Kindang' },
    { label: 'Rilau Ale', value: 'Rilau Ale' },
    { label: 'Ujung Bulu', value: 'Ujung Bulu' },
    { label: 'Ujung Loe', value: 'Ujung Loe' }
  ],
  Enrekang: [
    { label: 'Alla', value: 'Alla' },
    { label: 'Anggeraja', value: 'Anggeraja' },
    { label: 'Baraka', value: 'Baraka' },
    { label: 'Baroko', value: 'Baroko' },
    { label: 'Bungin', value: 'Bungin' },
    { label: 'Buntu Batu', value: 'Buntu Batu' },
    { label: 'Cendana', value: 'Cendana' },
    { label: 'Curio', value: 'Curio' },
    { label: 'Enrekang', value: 'Enrekang' },
    { label: 'Maiwa', value: 'Maiwa' },
    { label: 'Malua', value: 'Malua' },
    { label: 'Masalle', value: 'Masalle' }
  ],
  Gowa: [
    { label: 'Bajeng', value: 'Bajeng' },
    { label: 'Bajeng Barat', value: 'Bajeng Barat' },
    { label: 'Barombong', value: 'Barombong' },
    { label: 'Biringbulu', value: 'Biringbulu' },
    { label: 'Bontolempangang', value: 'Bontolempangang' },
    { label: 'Bontomarannu', value: 'Bontomarannu' },
    { label: 'Bontonompo', value: 'Bontonompo' },
    { label: 'Bontonompo Selatan', value: 'Bontonompo Selatan' },
    { label: 'Bungaya', value: 'Bungaya' },
    { label: 'Manuju', value: 'Manuju' },
    { label: 'Pallangga', value: 'Pallangga' },
    { label: 'Parangloe', value: 'Parangloe' },
    { label: 'Parigi', value: 'Parigi' },
    { label: 'Pattallassang', value: 'Pattallassang' },
    { label: 'Somba Opu (Upu)', value: 'Somba Opu (Upu)' },
    { label: 'Tinggimoncong', value: 'Tinggimoncong' },
    { label: 'Tombolo Pao', value: 'Tombolo Pao' },
    { label: 'Tompobulu', value: 'Tompobulu' }
  ],
  Jeneponto: [
    { label: 'Arungkeke', value: 'Arungkeke' },
    { label: 'Bangkala', value: 'Bangkala' },
    { label: 'Bangkala Barat', value: 'Bangkala Barat' },
    { label: 'Batang', value: 'Batang' },
    { label: 'Binamu', value: 'Binamu' },
    { label: 'Bontoramba', value: 'Bontoramba' },
    { label: 'Kelara', value: 'Kelara' },
    { label: 'Rumbia', value: 'Rumbia' },
    { label: 'Tamalatea', value: 'Tamalatea' },
    { label: 'Tarowang', value: 'Tarowang' },
    { label: 'Turatea', value: 'Turatea' }
  ],
  'Kepulauan Selayar': [
    { label: 'Benteng', value: 'Benteng' },
    { label: 'Bontoharu', value: 'Bontoharu' },
    { label: 'Bontomanai', value: 'Bontomanai' },
    { label: 'Bontomatene', value: 'Bontomatene' },
    { label: 'Bontosikuyu', value: 'Bontosikuyu' },
    { label: 'Buki', value: 'Buki' },
    { label: 'Pasilambena', value: 'Pasilambena' },
    { label: 'Pasimarannu', value: 'Pasimarannu' },
    { label: 'Pasimassunggu', value: 'Pasimassunggu' },
    { label: 'Pasimasunggu Timur', value: 'Pasimasunggu Timur' },
    { label: 'Takabonerate', value: 'Takabonerate' }
  ],
  Luwu: [
    { label: 'Bajo', value: 'Bajo' },
    { label: 'Bajo Barat', value: 'Bajo Barat' },
    { label: 'Basse Sangtempe Utara', value: 'Basse Sangtempe Utara' },
    {
      label: 'Bassesang Tempe (Bastem)',
      value: 'Bassesang Tempe (Bastem)'
    },
    { label: 'Belopa', value: 'Belopa' },
    { label: 'Belopa Utara', value: 'Belopa Utara' },
    { label: 'Bua', value: 'Bua' },
    { label: 'Bua Ponrang (Bupon)', value: 'Bua Ponrang (Bupon)' },
    { label: 'Kamanre', value: 'Kamanre' },
    { label: 'Lamasi', value: 'Lamasi' },
    { label: 'Lamasi Timur', value: 'Lamasi Timur' },
    { label: 'Larompong', value: 'Larompong' },
    { label: 'Larompong Selatan', value: 'Larompong Selatan' },
    { label: 'Latimojong', value: 'Latimojong' },
    { label: 'Ponrang', value: 'Ponrang' },
    { label: 'Ponrang Selatan', value: 'Ponrang Selatan' },
    { label: 'Suli', value: 'Suli' },
    { label: 'Suli Barat', value: 'Suli Barat' },
    { label: 'Walenrang', value: 'Walenrang' },
    { label: 'Walenrang Barat', value: 'Walenrang Barat' },
    { label: 'Walenrang Timur', value: 'Walenrang Timur' },
    { label: 'Walenrang Utara', value: 'Walenrang Utara' }
  ],
  'Luwu Timur': [
    { label: 'Angkona', value: 'Angkona' },
    { label: 'Burau', value: 'Burau' },
    { label: 'Kalaena', value: 'Kalaena' },
    { label: 'Malili', value: 'Malili' },
    { label: 'Mangkutana', value: 'Mangkutana' },
    { label: 'Nuha', value: 'Nuha' },
    { label: 'Tomoni', value: 'Tomoni' },
    { label: 'Tomoni Timur', value: 'Tomoni Timur' },
    { label: 'Towuti', value: 'Towuti' },
    { label: 'Wasuponda', value: 'Wasuponda' },
    { label: 'Wotu', value: 'Wotu' }
  ],
  'Luwu Utara': [
    { label: 'Baebunta', value: 'Baebunta' },
    { label: 'Bone-Bone', value: 'Bone-Bone' },
    { label: 'Limbong', value: 'Limbong' },
    { label: 'Malangke', value: 'Malangke' },
    { label: 'Malangke Barat', value: 'Malangke Barat' },
    { label: 'Mappedeceng', value: 'Mappedeceng' },
    { label: 'Masamba', value: 'Masamba' },
    { label: 'Rampi', value: 'Rampi' },
    { label: 'Sabbang', value: 'Sabbang' },
    { label: 'Seko', value: 'Seko' },
    { label: 'Sukamaju', value: 'Sukamaju' },
    { label: 'Tana Lili', value: 'Tana Lili' }
  ],
  Makassar: [
    { label: 'Biring Kanaya', value: 'Biring Kanaya' },
    { label: 'Bontoala', value: 'Bontoala' },
    { label: 'Makassar', value: 'Makassar' },
    { label: 'Mamajang', value: 'Mamajang' },
    { label: 'Manggala', value: 'Manggala' },
    { label: 'Mariso', value: 'Mariso' },
    { label: 'Panakkukang', value: 'Panakkukang' },
    { label: 'Rappocini', value: 'Rappocini' },
    { label: 'Tallo', value: 'Tallo' },
    { label: 'Tamalanrea', value: 'Tamalanrea' },
    { label: 'Tamalate', value: 'Tamalate' },
    { label: 'Ujung Pandang', value: 'Ujung Pandang' },
    { label: 'Ujung Tanah', value: 'Ujung Tanah' },
    { label: 'Wajo', value: 'Wajo' }
  ],
  Maros: [
    { label: 'Bantimurung', value: 'Bantimurung' },
    { label: 'Bontoa (Maros Utara)', value: 'Bontoa (Maros Utara)' },
    { label: 'Camba', value: 'Camba' },
    { label: 'Cenrana', value: 'Cenrana' },
    { label: 'Lau', value: 'Lau' },
    { label: 'Mallawa', value: 'Mallawa' },
    { label: 'Mandai', value: 'Mandai' },
    { label: 'Maros Baru', value: 'Maros Baru' },
    { label: 'Marusu', value: 'Marusu' },
    { label: 'Moncongloe', value: 'Moncongloe' },
    { label: 'Simbang', value: 'Simbang' },
    { label: 'Tanralili', value: 'Tanralili' },
    { label: 'Tompu Bulu', value: 'Tompu Bulu' },
    { label: 'Turikale', value: 'Turikale' }
  ],
  Palopo: [
    { label: 'Bara', value: 'Bara' },
    { label: 'Mungkajang', value: 'Mungkajang' },
    { label: 'Sendana', value: 'Sendana' },
    { label: 'Telluwanua', value: 'Telluwanua' },
    { label: 'Wara', value: 'Wara' },
    { label: 'Wara Barat', value: 'Wara Barat' },
    { label: 'Wara Selatan', value: 'Wara Selatan' },
    { label: 'Wara Timur', value: 'Wara Timur' },
    { label: 'Wara Utara', value: 'Wara Utara' }
  ],
  'Pangkajene Kepulauan': [
    { label: 'Balocci', value: 'Balocci' },
    { label: 'Bungoro', value: 'Bungoro' },
    { label: 'Labakkang', value: 'Labakkang' },
    {
      label: 'Liukang Kalmas (Kalukuang Masalima)',
      value: 'Liukang Kalmas (Kalukuang Masalima)'
    },
    { label: 'Liukang Tangaya', value: 'Liukang Tangaya' },
    { label: 'Liukang Tupabbiring', value: 'Liukang Tupabbiring' },
    {
      label: 'Liukang Tupabbiring Utara',
      value: 'Liukang Tupabbiring Utara'
    },
    { label: 'Mandalle', value: 'Mandalle' },
    { label: 'Marang (Ma Rang)', value: 'Marang (Ma Rang)' },
    { label: 'Minasa Tene', value: 'Minasa Tene' },
    { label: 'Pangkajene', value: 'Pangkajene' },
    { label: 'Segeri', value: 'Segeri' },
    { label: 'Tondong Tallasa', value: 'Tondong Tallasa' }
  ],
  Parepare: [
    { label: 'Bacukiki', value: 'Bacukiki' },
    { label: 'Bacukiki Barat', value: 'Bacukiki Barat' },
    { label: 'Soreang', value: 'Soreang' },
    { label: 'Ujung', value: 'Ujung' }
  ],
  Pinrang: [
    { label: 'Batulappa', value: 'Batulappa' },
    { label: 'Cempa', value: 'Cempa' },
    { label: 'Duampanua', value: 'Duampanua' },
    { label: 'Lanrisang', value: 'Lanrisang' },
    { label: 'Lembang', value: 'Lembang' },
    { label: 'Mattiro Bulu', value: 'Mattiro Bulu' },
    { label: 'Mattiro Sompe', value: 'Mattiro Sompe' },
    { label: 'Paleteang', value: 'Paleteang' },
    { label: 'Patampanua', value: 'Patampanua' },
    { label: 'Suppa', value: 'Suppa' },
    { label: 'Tiroang', value: 'Tiroang' },
    { label: 'Watang Sawitto', value: 'Watang Sawitto' }
  ],
  'Sidenreng Rappang': [
    { label: 'Baranti', value: 'Baranti' },
    { label: 'Dua Pitue', value: 'Dua Pitue' },
    { label: 'Kulo', value: 'Kulo' },
    { label: 'Maritengngae', value: 'Maritengngae' },
    { label: 'Panca Lautan (Lautang)', value: 'Panca Lautan (Lautang)' },
    { label: 'Panca Rijang', value: 'Panca Rijang' },
    { label: 'Pitu Raise/Riase', value: 'Pitu Raise/Riase' },
    { label: 'Pitu Riawa', value: 'Pitu Riawa' },
    { label: 'Tellu Limpoe', value: 'Tellu Limpoe' },
    { label: 'Watang Pulu', value: 'Watang Pulu' },
    {
      label: 'Wattang Sidenreng (Watang Sidenreng)',
      value: 'Wattang Sidenreng (Watang Sidenreng)'
    }
  ],
  Sinjai: [
    { label: 'Bulupoddo', value: 'Bulupoddo' },
    { label: 'Pulau Sembilan', value: 'Pulau Sembilan' },
    { label: 'Sinjai Barat', value: 'Sinjai Barat' },
    { label: 'Sinjai Borong', value: 'Sinjai Borong' },
    { label: 'Sinjai Selatan', value: 'Sinjai Selatan' },
    { label: 'Sinjai Tengah', value: 'Sinjai Tengah' },
    { label: 'Sinjai Timur', value: 'Sinjai Timur' },
    { label: 'Sinjai Utara', value: 'Sinjai Utara' },
    { label: 'Tellu Limpoe', value: 'Tellu Limpoe' }
  ],
  Soppeng: [
    { label: 'Citta', value: 'Citta' },
    { label: 'Donri-Donri', value: 'Donri-Donri' },
    { label: 'Ganra', value: 'Ganra' },
    { label: 'Lalabata', value: 'Lalabata' },
    { label: 'Lili Rilau', value: 'Lili Rilau' },
    { label: 'Liliraja (Lili Riaja)', value: 'Liliraja (Lili Riaja)' },
    { label: 'Mario Riawa', value: 'Mario Riawa' },
    { label: 'Mario Riwawo', value: 'Mario Riwawo' }
  ],
  Takalar: [
    { label: 'Galesong', value: 'Galesong' },
    { label: 'Galesong Selatan', value: 'Galesong Selatan' },
    { label: 'Galesong Utara', value: 'Galesong Utara' },
    { label: 'Mangara Bombang', value: 'Mangara Bombang' },
    { label: 'Mappakasunggu', value: 'Mappakasunggu' },
    { label: 'Patallassang', value: 'Patallassang' },
    {
      label: 'Polombangkeng Selatan (Polobangkeng)',
      value: 'Polombangkeng Selatan (Polobangkeng)'
    },
    {
      label: 'Polombangkeng Utara (Polobangkeng)',
      value: 'Polombangkeng Utara (Polobangkeng)'
    },
    { label: 'Sanrobone', value: 'Sanrobone' }
  ],
  'Tana Toraja': [
    { label: 'Bittuang', value: 'Bittuang' },
    { label: 'Bonggakaradeng', value: 'Bonggakaradeng' },
    { label: 'Gandang Batu Sillanan', value: 'Gandang Batu Sillanan' },
    { label: 'Kurra', value: 'Kurra' },
    { label: 'Makale', value: 'Makale' },
    { label: 'Makale Selatan', value: 'Makale Selatan' },
    { label: 'Makale Utara', value: 'Makale Utara' },
    { label: 'Malimbong Balepe', value: 'Malimbong Balepe' },
    { label: 'Mappak', value: 'Mappak' },
    { label: 'Masanda', value: 'Masanda' },
    { label: 'Mengkendek', value: 'Mengkendek' },
    { label: 'Rano', value: 'Rano' },
    { label: 'Rantetayo', value: 'Rantetayo' },
    { label: 'Rembon', value: 'Rembon' },
    { label: 'Saluputti', value: 'Saluputti' },
    { label: 'Sangalla (Sanggala)', value: 'Sangalla (Sanggala)' },
    { label: 'Sangalla Selatan', value: 'Sangalla Selatan' },
    { label: 'Sangalla Utara', value: 'Sangalla Utara' },
    { label: 'Simbuang', value: 'Simbuang' }
  ],
  'Toraja Utara': [
    { label: 'Awan Rante Karua', value: 'Awan Rante Karua' },
    { label: 'Balusu', value: 'Balusu' },
    { label: 'Bangkelekila', value: 'Bangkelekila' },
    { label: 'Baruppu', value: 'Baruppu' },
    { label: 'Buntao', value: 'Buntao' },
    { label: 'Buntu Pepasan', value: 'Buntu Pepasan' },
    { label: "Dende' Piongan Napo", value: "Dende' Piongan Napo" },
    {
      label: 'Kapalla Pitu (Kapala Pitu)',
      value: 'Kapalla Pitu (Kapala Pitu)'
    },
    { label: 'Kesu', value: 'Kesu' },
    { label: 'Nanggala', value: 'Nanggala' },
    { label: 'Rantebua', value: 'Rantebua' },
    { label: 'Rantepao', value: 'Rantepao' },
    { label: 'Rindingallo', value: 'Rindingallo' },
    { label: "Sa'dan", value: "Sa'dan" },
    { label: 'Sanggalangi', value: 'Sanggalangi' },
    { label: 'Sesean', value: 'Sesean' },
    { label: 'Sesean Suloara', value: 'Sesean Suloara' },
    { label: 'Sopai', value: 'Sopai' },
    { label: 'Tallunglipu', value: 'Tallunglipu' },
    { label: 'Tikala', value: 'Tikala' },
    { label: 'Tondon', value: 'Tondon' }
  ],
  Wajo: [
    { label: 'Belawa', value: 'Belawa' },
    { label: 'Bola', value: 'Bola' },
    { label: 'Gilireng', value: 'Gilireng' },
    { label: 'Keera', value: 'Keera' },
    { label: 'Majauleng', value: 'Majauleng' },
    { label: 'Maniangpajo', value: 'Maniangpajo' },
    { label: 'Pammana', value: 'Pammana' },
    { label: 'Penrang', value: 'Penrang' },
    { label: 'Pitumpanua', value: 'Pitumpanua' },
    { label: 'Sabangparu', value: 'Sabangparu' },
    { label: 'Sajoanging', value: 'Sajoanging' },
    { label: 'Takkalalla', value: 'Takkalalla' },
    { label: 'Tanasitolo', value: 'Tanasitolo' },
    { label: 'Tempe', value: 'Tempe' }
  ],
  Banggai: [
    { label: 'Balantak', value: 'Balantak' },
    { label: 'Balantak Selatan', value: 'Balantak Selatan' },
    { label: 'Balantak Utara', value: 'Balantak Utara' },
    { label: 'Batui', value: 'Batui' },
    { label: 'Batui Selatan', value: 'Batui Selatan' },
    { label: 'Bualemo (Boalemo)', value: 'Bualemo (Boalemo)' },
    { label: 'Bunta', value: 'Bunta' },
    { label: 'Kintom', value: 'Kintom' },
    { label: 'Lamala', value: 'Lamala' },
    { label: 'Lobu', value: 'Lobu' },
    { label: 'Luwuk', value: 'Luwuk' },
    { label: 'Luwuk Selatan', value: 'Luwuk Selatan' },
    { label: 'Luwuk Timur', value: 'Luwuk Timur' },
    { label: 'Luwuk Utara', value: 'Luwuk Utara' },
    { label: 'Mantoh', value: 'Mantoh' },
    { label: 'Masama', value: 'Masama' },
    { label: 'Moilong', value: 'Moilong' },
    { label: 'Nambo', value: 'Nambo' },
    { label: 'Nuhon', value: 'Nuhon' },
    { label: 'Pagimana', value: 'Pagimana' },
    { label: 'Simpang Raya', value: 'Simpang Raya' },
    { label: 'Toili', value: 'Toili' },
    { label: 'Toili Barat', value: 'Toili Barat' }
  ],
  'Banggai Kepulauan': [
    { label: 'Buko', value: 'Buko' },
    { label: 'Buko Selatan', value: 'Buko Selatan' },
    { label: 'Bulagi', value: 'Bulagi' },
    { label: 'Bulagi Selatan', value: 'Bulagi Selatan' },
    { label: 'Bulagi Utara', value: 'Bulagi Utara' },
    { label: 'Liang', value: 'Liang' },
    { label: 'Peling Tengah', value: 'Peling Tengah' },
    { label: 'Tinangkung', value: 'Tinangkung' },
    { label: 'Tinangkung Selatan', value: 'Tinangkung Selatan' },
    { label: 'Tinangkung Utara', value: 'Tinangkung Utara' },
    { label: 'Totikum (Totikung)', value: 'Totikum (Totikung)' },
    { label: 'Totikum Selatan', value: 'Totikum Selatan' }
  ],
  'Banggai Laut': [
    { label: 'Banggai', value: 'Banggai' },
    { label: 'Banggai Selatan', value: 'Banggai Selatan' },
    { label: 'Banggai Tengah', value: 'Banggai Tengah' },
    { label: 'Banggai Utara', value: 'Banggai Utara' },
    { label: 'Bangkurung', value: 'Bangkurung' },
    { label: 'Bokan Kepulauan', value: 'Bokan Kepulauan' },
    { label: 'Labobo', value: 'Labobo' }
  ],
  Buol: [
    { label: 'Biau', value: 'Biau' },
    { label: 'Bokat', value: 'Bokat' },
    { label: 'Bukal', value: 'Bukal' },
    { label: 'Bunobogu', value: 'Bunobogu' },
    { label: 'Gadung', value: 'Gadung' },
    { label: 'Karamat', value: 'Karamat' },
    { label: 'Lakea (Lipunoto)', value: 'Lakea (Lipunoto)' },
    { label: 'Momunu', value: 'Momunu' },
    { label: 'Paleleh', value: 'Paleleh' },
    { label: 'Paleleh Barat', value: 'Paleleh Barat' },
    { label: 'Tiloan', value: 'Tiloan' }
  ],
  Donggala: [
    { label: 'Balaesang', value: 'Balaesang' },
    { label: 'Balaesang Tanjung', value: 'Balaesang Tanjung' },
    { label: 'Banawa', value: 'Banawa' },
    { label: 'Banawa Selatan', value: 'Banawa Selatan' },
    { label: 'Banawa Tengah', value: 'Banawa Tengah' },
    { label: 'Dampelas', value: 'Dampelas' },
    { label: 'Labuan', value: 'Labuan' },
    { label: 'Panembani', value: 'Panembani' },
    { label: 'Rio Pakava', value: 'Rio Pakava' },
    { label: 'Sindue', value: 'Sindue' },
    { label: 'Sindue Tobata', value: 'Sindue Tobata' },
    { label: 'Sindue Tombusabora', value: 'Sindue Tombusabora' },
    { label: 'Sirenja', value: 'Sirenja' },
    { label: 'Sojol', value: 'Sojol' },
    { label: 'Sojol Utara', value: 'Sojol Utara' },
    { label: 'Tanantovea', value: 'Tanantovea' }
  ],
  Morowali: [
    { label: 'Bahodopi', value: 'Bahodopi' },
    { label: 'Bumi Raya', value: 'Bumi Raya' },
    { label: 'Bungku Barat', value: 'Bungku Barat' },
    { label: 'Bungku Pesisir', value: 'Bungku Pesisir' },
    { label: 'Bungku Selatan', value: 'Bungku Selatan' },
    { label: 'Bungku Tengah', value: 'Bungku Tengah' },
    { label: 'Bungku Timur', value: 'Bungku Timur' },
    { label: 'Menui Kepulauan', value: 'Menui Kepulauan' },
    { label: 'Petasia Barat', value: 'Petasia Barat' },
    { label: 'Wita Ponda', value: 'Wita Ponda' }
  ],
  'Morowali Utara': [
    { label: 'Bungku Utara', value: 'Bungku Utara' },
    { label: 'Lembo', value: 'Lembo' },
    { label: 'Lembo Raya', value: 'Lembo Raya' },
    { label: 'Mamosalato', value: 'Mamosalato' },
    { label: 'Mori Atas', value: 'Mori Atas' },
    { label: 'Mori Utara', value: 'Mori Utara' },
    { label: 'Petasia', value: 'Petasia' },
    { label: 'Petasia Timur', value: 'Petasia Timur' },
    { label: 'Soyo Jaya', value: 'Soyo Jaya' }
  ],
  Palu: [
    { label: 'Mantikulore', value: 'Mantikulore' },
    { label: 'Palu Barat', value: 'Palu Barat' },
    { label: 'Palu Selatan', value: 'Palu Selatan' },
    { label: 'Palu Timur', value: 'Palu Timur' },
    { label: 'Palu Utara', value: 'Palu Utara' },
    { label: 'Tatanga', value: 'Tatanga' },
    { label: 'Tawaeli', value: 'Tawaeli' },
    { label: 'Ulujadi', value: 'Ulujadi' }
  ],
  'Parigi Moutong': [
    { label: 'Ampibabo', value: 'Ampibabo' },
    { label: 'Balinggi', value: 'Balinggi' },
    { label: 'Bolano', value: 'Bolano' },
    { label: 'Bolano Lambunu', value: 'Bolano Lambunu' },
    { label: 'Kasimbar', value: 'Kasimbar' },
    { label: 'Mepanga', value: 'Mepanga' },
    { label: 'Moutong', value: 'Moutong' },
    { label: 'Ongka Malino', value: 'Ongka Malino' },
    { label: 'Palasa', value: 'Palasa' },
    { label: 'Parigi', value: 'Parigi' },
    { label: 'Parigi Barat', value: 'Parigi Barat' },
    { label: 'Parigi Selatan', value: 'Parigi Selatan' },
    { label: 'Parigi Tengah', value: 'Parigi Tengah' },
    { label: 'Parigi Utara', value: 'Parigi Utara' },
    { label: 'Sausu', value: 'Sausu' },
    { label: 'Sidoan', value: 'Sidoan' },
    { label: 'Siniu', value: 'Siniu' },
    { label: 'Taopa', value: 'Taopa' },
    { label: 'Tinombo', value: 'Tinombo' },
    { label: 'Tinombo Selatan', value: 'Tinombo Selatan' },
    { label: 'Tomini', value: 'Tomini' },
    { label: 'Toribulu', value: 'Toribulu' },
    { label: 'Torue', value: 'Torue' }
  ],
  Poso: [
    { label: 'Lage', value: 'Lage' },
    { label: 'Lore Barat', value: 'Lore Barat' },
    { label: 'Lore Piore', value: 'Lore Piore' },
    { label: 'Lore Selatan', value: 'Lore Selatan' },
    { label: 'Lore Tengah', value: 'Lore Tengah' },
    { label: 'Lore Timur', value: 'Lore Timur' },
    { label: 'Lore Utara', value: 'Lore Utara' },
    { label: 'Pamona Barat', value: 'Pamona Barat' },
    { label: 'Pamona Puselemba', value: 'Pamona Puselemba' },
    { label: 'Pamona Selatan', value: 'Pamona Selatan' },
    { label: 'Pamona Tenggara', value: 'Pamona Tenggara' },
    { label: 'Pamona Timur', value: 'Pamona Timur' },
    { label: 'Pamona Utara', value: 'Pamona Utara' },
    { label: 'Poso Kota', value: 'Poso Kota' },
    { label: 'Poso Kota Selatan', value: 'Poso Kota Selatan' },
    { label: 'Poso Kota Utara', value: 'Poso Kota Utara' },
    { label: 'Poso Pesisir', value: 'Poso Pesisir' },
    { label: 'Poso Pesisir Selatan', value: 'Poso Pesisir Selatan' },
    { label: 'Poso Pesisir Utara', value: 'Poso Pesisir Utara' }
  ],
  Sigi: [
    { label: 'Dolo', value: 'Dolo' },
    { label: 'Dolo Barat', value: 'Dolo Barat' },
    { label: 'Dolo Selatan', value: 'Dolo Selatan' },
    { label: 'Gumbasa', value: 'Gumbasa' },
    { label: 'Kinovaro', value: 'Kinovaro' },
    { label: 'Kinovaru', value: 'Kinovaru' },
    { label: 'Kulawi', value: 'Kulawi' },
    { label: 'Kulawi Selatan', value: 'Kulawi Selatan' },
    { label: 'Lindu', value: 'Lindu' },
    { label: 'Marawola', value: 'Marawola' },
    { label: 'Marawola Barat', value: 'Marawola Barat' },
    { label: 'Nokilalaki', value: 'Nokilalaki' },
    { label: 'Palolo', value: 'Palolo' },
    { label: 'Pipikoro', value: 'Pipikoro' },
    { label: 'Sigi Biromaru', value: 'Sigi Biromaru' },
    { label: 'Tanambulava', value: 'Tanambulava' }
  ],
  'Tojo Una-Una': [
    { label: 'Ampana Kota', value: 'Ampana Kota' },
    { label: 'Ampana Tete', value: 'Ampana Tete' },
    { label: 'Batudaka', value: 'Batudaka' },
    { label: 'Ratolindo', value: 'Ratolindo' },
    { label: 'Talatako', value: 'Talatako' },
    { label: 'Togean', value: 'Togean' },
    { label: 'Tojo', value: 'Tojo' },
    { label: 'Tojo Barat', value: 'Tojo Barat' },
    { label: 'Ulubongka', value: 'Ulubongka' },
    { label: 'Una Una', value: 'Una Una' },
    { label: 'Walea Besar', value: 'Walea Besar' },
    { label: 'Walea Kepulauan', value: 'Walea Kepulauan' }
  ],
  'Toli-Toli': [
    { label: 'Baolan', value: 'Baolan' },
    { label: 'Basidondo', value: 'Basidondo' },
    { label: 'Dako Pamean', value: 'Dako Pamean' },
    { label: 'Dampal Selatan', value: 'Dampal Selatan' },
    { label: 'Dampal Utara', value: 'Dampal Utara' },
    { label: 'Dondo', value: 'Dondo' },
    { label: 'Galang', value: 'Galang' },
    { label: 'Lampasio', value: 'Lampasio' },
    { label: 'Ogodeide', value: 'Ogodeide' },
    { label: 'Tolitoli Utara', value: 'Tolitoli Utara' }
  ],
  'Bau-Bau': [
    { label: 'Batupoaro', value: 'Batupoaro' },
    { label: 'Betoambari', value: 'Betoambari' },
    { label: 'Bungi', value: 'Bungi' },
    { label: 'Kokalukuna', value: 'Kokalukuna' },
    { label: 'Lea-Lea', value: 'Lea-Lea' },
    { label: 'Murhum', value: 'Murhum' },
    { label: 'Sora Walio (Sorowalio)', value: 'Sora Walio (Sorowalio)' },
    { label: 'Wolio', value: 'Wolio' }
  ],
  Bombana: [
    { label: 'Kabaena', value: 'Kabaena' },
    { label: 'Kabaena Barat', value: 'Kabaena Barat' },
    { label: 'Kabaena Selatan', value: 'Kabaena Selatan' },
    { label: 'Kabaena Tengah', value: 'Kabaena Tengah' },
    { label: 'Kabaena Timur', value: 'Kabaena Timur' },
    { label: 'Kabaena Utara', value: 'Kabaena Utara' },
    { label: 'Kepulauan Masaloka Raya', value: 'Kepulauan Masaloka Raya' },
    {
      label: 'Lentarai Jaya S. (Lantari Jaya)',
      value: 'Lentarai Jaya S. (Lantari Jaya)'
    },
    { label: 'Mata Oleo', value: 'Mata Oleo' },
    { label: 'Mata Usu', value: 'Mata Usu' },
    { label: 'Poleang', value: 'Poleang' },
    { label: 'Poleang Barat', value: 'Poleang Barat' },
    { label: 'Poleang Selatan', value: 'Poleang Selatan' },
    { label: 'Poleang Tengah', value: 'Poleang Tengah' },
    { label: 'Poleang Tenggara', value: 'Poleang Tenggara' },
    { label: 'Poleang Timur', value: 'Poleang Timur' },
    { label: 'Poleang Utara', value: 'Poleang Utara' },
    { label: 'Rarowatu', value: 'Rarowatu' },
    { label: 'Rarowatu Utara', value: 'Rarowatu Utara' },
    { label: 'Rumbia', value: 'Rumbia' },
    { label: 'Rumbia Tengah', value: 'Rumbia Tengah' },
    { label: 'Tontonunu (Tontonuwu)', value: 'Tontonunu (Tontonuwu)' }
  ],
  Buton: [
    { label: 'Kapontori', value: 'Kapontori' },
    { label: 'Lasalimu', value: 'Lasalimu' },
    { label: 'Lasalimu Selatan', value: 'Lasalimu Selatan' },
    { label: 'Pasar Wajo', value: 'Pasar Wajo' },
    { label: 'Siontapia (Siontapina)', value: 'Siontapia (Siontapina)' },
    { label: 'Wabula', value: 'Wabula' },
    { label: 'Wolowa', value: 'Wolowa' }
  ],
  'Buton Selatan': [
    { label: 'Batauga', value: 'Batauga' },
    { label: 'Batu Atas', value: 'Batu Atas' },
    { label: 'Kadatua', value: 'Kadatua' },
    { label: 'Lapandewa', value: 'Lapandewa' },
    { label: 'Sampolawa', value: 'Sampolawa' },
    { label: 'Siompu', value: 'Siompu' },
    { label: 'Siompu Barat', value: 'Siompu Barat' }
  ],
  'Buton Tengah': [
    { label: 'Gu', value: 'Gu' },
    { label: 'Lakudo', value: 'Lakudo' },
    { label: 'Mawasangka', value: 'Mawasangka' },
    { label: 'Mawasangka Tengah', value: 'Mawasangka Tengah' },
    { label: 'Mawasangka Timur', value: 'Mawasangka Timur' },
    { label: 'Sangia Wambulu', value: 'Sangia Wambulu' },
    { label: 'Talaga Raya', value: 'Talaga Raya' }
  ],
  'Buton Utara': [
    { label: 'Bonegunu', value: 'Bonegunu' },
    { label: 'Kambowa', value: 'Kambowa' },
    {
      label: 'Kulisusu (Kalingsusu/Kalisusu)',
      value: 'Kulisusu (Kalingsusu/Kalisusu)'
    },
    { label: 'Kulisusu Barat', value: 'Kulisusu Barat' },
    { label: 'Kulisusu Utara', value: 'Kulisusu Utara' },
    { label: 'Wakorumba Utara', value: 'Wakorumba Utara' }
  ],
  Kendari: [
    { label: 'Abeli', value: 'Abeli' },
    { label: 'Baruga', value: 'Baruga' },
    { label: 'Kadia', value: 'Kadia' },
    { label: 'Kambu', value: 'Kambu' },
    { label: 'Kendari', value: 'Kendari' },
    { label: 'Kendari Barat', value: 'Kendari Barat' },
    { label: 'Mandonga', value: 'Mandonga' },
    { label: 'Poasia', value: 'Poasia' },
    { label: 'Puuwatu', value: 'Puuwatu' },
    { label: 'Wua-Wua', value: 'Wua-Wua' }
  ],
  Kolaka: [
    { label: 'Baula', value: 'Baula' },
    { label: 'Iwoimendaa', value: 'Iwoimendaa' },
    { label: 'Kolaka', value: 'Kolaka' },
    { label: 'Latambaga', value: 'Latambaga' },
    { label: 'Polinggona', value: 'Polinggona' },
    { label: 'Pomalaa', value: 'Pomalaa' },
    { label: 'Samaturu', value: 'Samaturu' },
    { label: 'Tanggetada', value: 'Tanggetada' },
    { label: 'Toari', value: 'Toari' },
    { label: 'Watubangga', value: 'Watubangga' },
    { label: 'Wolo', value: 'Wolo' },
    { label: 'Wundulako', value: 'Wundulako' }
  ],
  'Kolaka Timur': [
    { label: 'Aere', value: 'Aere' },
    { label: 'Dangia', value: 'Dangia' },
    { label: 'Ladongi', value: 'Ladongi' },
    { label: 'Lalolae', value: 'Lalolae' },
    { label: 'Lambandia', value: 'Lambandia' },
    { label: 'Loea', value: 'Loea' },
    { label: 'Mowewe', value: 'Mowewe' },
    { label: 'Poli Polia', value: 'Poli Polia' },
    { label: 'Tinondo', value: 'Tinondo' },
    { label: 'Tirawuta', value: 'Tirawuta' },
    { label: 'Ueesi', value: 'Ueesi' },
    { label: 'Uluiwoi', value: 'Uluiwoi' }
  ],
  'Kolaka Utara': [
    { label: 'Batu Putih', value: 'Batu Putih' },
    { label: 'Katoi', value: 'Katoi' },
    { label: 'Kodeoha', value: 'Kodeoha' },
    { label: 'Lambai', value: 'Lambai' },
    { label: 'Lasusua', value: 'Lasusua' },
    { label: 'Ngapa', value: 'Ngapa' },
    { label: 'Pakue', value: 'Pakue' },
    { label: 'Pakue Tengah', value: 'Pakue Tengah' },
    { label: 'Pakue Utara', value: 'Pakue Utara' },
    { label: 'Porehu', value: 'Porehu' },
    { label: 'Rante Angin', value: 'Rante Angin' },
    { label: 'Tiwu', value: 'Tiwu' },
    { label: 'Tolala', value: 'Tolala' },
    { label: 'Watunohu', value: 'Watunohu' },
    { label: 'Wawo', value: 'Wawo' }
  ],
  Konawe: [
    { label: 'Abuki', value: 'Abuki' },
    { label: 'Amonggedo', value: 'Amonggedo' },
    { label: 'Anggaberi', value: 'Anggaberi' },
    { label: 'Asinua', value: 'Asinua' },
    { label: 'Besulutu', value: 'Besulutu' },
    { label: 'Bondoala', value: 'Bondoala' },
    { label: 'Kapoiala (Kapoyala)', value: 'Kapoiala (Kapoyala)' },
    { label: 'Konawe', value: 'Konawe' },
    { label: 'Lalonggasumeeto', value: 'Lalonggasumeeto' },
    { label: 'Lambuya', value: 'Lambuya' },
    { label: 'Latoma', value: 'Latoma' },
    { label: 'Meluhu', value: 'Meluhu' },
    { label: 'Onembute', value: 'Onembute' },
    { label: 'Pondidaha', value: 'Pondidaha' },
    { label: 'Puriala', value: 'Puriala' },
    { label: 'Routa', value: 'Routa' },
    { label: 'Sampara', value: 'Sampara' },
    { label: 'Soropia', value: 'Soropia' },
    { label: 'Tongauna', value: 'Tongauna' },
    { label: 'Uepai (Uwepai)', value: 'Uepai (Uwepai)' },
    { label: 'Unaaha', value: 'Unaaha' },
    { label: 'Wawotobi', value: 'Wawotobi' },
    { label: 'Wonggeduku', value: 'Wonggeduku' }
  ],
  'Konawe Kepulauan': [
    { label: 'Wawonii Barat', value: 'Wawonii Barat' },
    { label: 'Wawonii Selatan', value: 'Wawonii Selatan' },
    { label: 'Wawonii Tengah', value: 'Wawonii Tengah' },
    { label: 'Wawonii Tenggara', value: 'Wawonii Tenggara' },
    { label: 'Wawonii Timur', value: 'Wawonii Timur' },
    { label: 'Wawonii Timur Laut', value: 'Wawonii Timur Laut' },
    { label: 'Wawonii Utara', value: 'Wawonii Utara' }
  ],
  'Konawe Selatan': [
    { label: 'Andoolo', value: 'Andoolo' },
    { label: 'Angata', value: 'Angata' },
    { label: 'Baito', value: 'Baito' },
    { label: 'Basala', value: 'Basala' },
    { label: 'Benua', value: 'Benua' },
    { label: 'Buke', value: 'Buke' },
    { label: 'Kolono', value: 'Kolono' },
    { label: 'Konda', value: 'Konda' },
    { label: 'Laeya', value: 'Laeya' },
    { label: 'Lainea', value: 'Lainea' },
    { label: 'Lalembuu', value: 'Lalembuu' },
    { label: 'Landono', value: 'Landono' },
    { label: 'Laonti', value: 'Laonti' },
    { label: 'Moramo', value: 'Moramo' },
    { label: 'Moramo Utara', value: 'Moramo Utara' },
    { label: 'Mowila', value: 'Mowila' },
    { label: 'Palangga', value: 'Palangga' },
    { label: 'Palangga Selatan', value: 'Palangga Selatan' },
    { label: 'Ranomeeto', value: 'Ranomeeto' },
    { label: 'Ranomeeto Barat', value: 'Ranomeeto Barat' },
    { label: 'Tinanggea', value: 'Tinanggea' },
    { label: 'Wolasi', value: 'Wolasi' }
  ],
  'Konawe Utara': [
    { label: 'Andowia', value: 'Andowia' },
    { label: 'Asera', value: 'Asera' },
    { label: 'Langgikima', value: 'Langgikima' },
    { label: 'Lasolo', value: 'Lasolo' },
    { label: 'Lembo', value: 'Lembo' },
    { label: 'Molawe', value: 'Molawe' },
    { label: 'Motui', value: 'Motui' },
    { label: 'Oheo', value: 'Oheo' },
    { label: 'Sawa', value: 'Sawa' },
    { label: 'Wiwirano', value: 'Wiwirano' }
  ],
  Muna: [
    {
      label: 'Batalaiwaru (Batalaiworu)',
      value: 'Batalaiwaru (Batalaiworu)'
    },
    { label: 'Batukara', value: 'Batukara' },
    { label: 'Bone (Bone Tondo)', value: 'Bone (Bone Tondo)' },
    { label: 'Duruka', value: 'Duruka' },
    { label: 'Kabangka', value: 'Kabangka' },
    { label: 'Kabawo', value: 'Kabawo' },
    { label: 'Katobu', value: 'Katobu' },
    { label: 'Kontu Kowuna', value: 'Kontu Kowuna' },
    { label: 'Kontunaga', value: 'Kontunaga' },
    { label: 'Lasalepa', value: 'Lasalepa' },
    { label: 'Lohia', value: 'Lohia' },
    { label: 'Maligano', value: 'Maligano' },
    { label: 'Marobo', value: 'Marobo' },
    { label: 'Napabalano', value: 'Napabalano' },
    { label: 'Parigi', value: 'Parigi' },
    { label: 'Pasi Kolaga', value: 'Pasi Kolaga' },
    { label: 'Pasir Putih', value: 'Pasir Putih' },
    { label: 'Tongkuno', value: 'Tongkuno' },
    { label: 'Tongkuno Selatan', value: 'Tongkuno Selatan' },
    { label: 'Towea', value: 'Towea' },
    { label: 'Wakorumba Selatan', value: 'Wakorumba Selatan' },
    { label: 'Watopute', value: 'Watopute' }
  ],
  'Muna Barat': [
    { label: 'Barangka', value: 'Barangka' },
    { label: 'Kusambi', value: 'Kusambi' },
    { label: 'Lawa', value: 'Lawa' },
    { label: 'Maginti', value: 'Maginti' },
    { label: 'Napano Kusambi', value: 'Napano Kusambi' },
    { label: 'Sawerigadi', value: 'Sawerigadi' },
    { label: 'Tiworo Kepulauan', value: 'Tiworo Kepulauan' },
    { label: 'Tiworo Selatan', value: 'Tiworo Selatan' },
    { label: 'Tiworo Tengah', value: 'Tiworo Tengah' },
    { label: 'Tiworo Utara', value: 'Tiworo Utara' },
    { label: 'Wadaga', value: 'Wadaga' }
  ],
  Wakatobi: [
    { label: 'Binongko', value: 'Binongko' },
    { label: 'Kaledupa', value: 'Kaledupa' },
    { label: 'Kaledupa Selatan', value: 'Kaledupa Selatan' },
    { label: 'Togo Binongko', value: 'Togo Binongko' },
    { label: 'Tomia', value: 'Tomia' },
    { label: 'Tomia Timur', value: 'Tomia Timur' },
    { label: 'Wangi-Wangi', value: 'Wangi-Wangi' },
    { label: 'Wangi-Wangi Selatan', value: 'Wangi-Wangi Selatan' }
  ],
  Bitung: [
    {
      label: 'Aertembaga (Bitung Timur)',
      value: 'Aertembaga (Bitung Timur)'
    },
    { label: 'Girian', value: 'Girian' },
    {
      label: 'Lembeh Selatan (Bitung Selatan)',
      value: 'Lembeh Selatan (Bitung Selatan)'
    },
    { label: 'Lembeh Utara', value: 'Lembeh Utara' },
    { label: 'Madidir (Bitung Tengah)', value: 'Madidir (Bitung Tengah)' },
    { label: 'Maesa', value: 'Maesa' },
    { label: 'Matuari (Bitung Barat)', value: 'Matuari (Bitung Barat)' },
    { label: 'Ranowulu (Bitung Utara)', value: 'Ranowulu (Bitung Utara)' }
  ],
  'Bolaang Mongondow': [
    { label: 'Bilalang', value: 'Bilalang' },
    { label: 'Bolaang', value: 'Bolaang' },
    { label: 'Bolaang Timur', value: 'Bolaang Timur' },
    { label: 'Dumoga', value: 'Dumoga' },
    { label: 'Dumoga Barat', value: 'Dumoga Barat' },
    { label: 'Dumoga Tengah', value: 'Dumoga Tengah' },
    { label: 'Dumoga Tenggara', value: 'Dumoga Tenggara' },
    { label: 'Dumoga Timur', value: 'Dumoga Timur' },
    { label: 'Dumoga Utara', value: 'Dumoga Utara' },
    { label: 'Lolak', value: 'Lolak' },
    { label: 'Lolayan', value: 'Lolayan' },
    { label: 'Passi Barat', value: 'Passi Barat' },
    { label: 'Passi Timur', value: 'Passi Timur' },
    { label: 'Poigar', value: 'Poigar' },
    { label: 'Sang Tombolang', value: 'Sang Tombolang' }
  ],
  'Bolaang Mongondow Selatan': [
    { label: 'Bolaang Uki', value: 'Bolaang Uki' },
    { label: 'Pinolosian', value: 'Pinolosian' },
    { label: 'Pinolosian Tengah', value: 'Pinolosian Tengah' },
    { label: 'Pinolosian Timur', value: 'Pinolosian Timur' },
    { label: 'Posigadan', value: 'Posigadan' }
  ],
  'Bolaang Mongondow Timur': [
    { label: 'Kotabunan', value: 'Kotabunan' },
    { label: 'Modayag', value: 'Modayag' },
    { label: 'Modayag Barat', value: 'Modayag Barat' },
    { label: 'Nuangan', value: 'Nuangan' },
    { label: 'Tutuyan', value: 'Tutuyan' }
  ],
  'Bolaang Mongondow Utara': [
    { label: 'Bintauna', value: 'Bintauna' },
    { label: 'Bolang Itang Barat', value: 'Bolang Itang Barat' },
    { label: 'Bolang Itang Timur', value: 'Bolang Itang Timur' },
    { label: 'Kaidipang', value: 'Kaidipang' },
    { label: 'Pinogaluman', value: 'Pinogaluman' },
    { label: 'Sangkub', value: 'Sangkub' }
  ],
  'Kepulauan Sangihe': [
    { label: 'Kendahe', value: 'Kendahe' },
    { label: 'Kepulauan Marore', value: 'Kepulauan Marore' },
    { label: 'Manganitu', value: 'Manganitu' },
    { label: 'Manganitu Selatan', value: 'Manganitu Selatan' },
    { label: 'Nusa Tabukan', value: 'Nusa Tabukan' },
    { label: 'Tabukan Selatan', value: 'Tabukan Selatan' },
    { label: 'Tabukan Selatan Tengah', value: 'Tabukan Selatan Tengah' },
    {
      label: 'Tabukan Selatan Tenggara',
      value: 'Tabukan Selatan Tenggara'
    },
    { label: 'Tabukan Tengah', value: 'Tabukan Tengah' },
    { label: 'Tabukan Utara', value: 'Tabukan Utara' },
    { label: 'Tahuna', value: 'Tahuna' },
    { label: 'Tahuna Barat', value: 'Tahuna Barat' },
    { label: 'Tahuna Timur', value: 'Tahuna Timur' },
    { label: 'Tamako', value: 'Tamako' },
    { label: 'Tatoareng', value: 'Tatoareng' }
  ],
  'Kepulauan Siau Tagulandang Biaro (Sitaro)': [
    { label: 'Biaro', value: 'Biaro' },
    { label: 'Siau Barat', value: 'Siau Barat' },
    { label: 'Siau Barat Selatan', value: 'Siau Barat Selatan' },
    { label: 'Siau Barat Utara', value: 'Siau Barat Utara' },
    { label: 'Siau Tengah', value: 'Siau Tengah' },
    { label: 'Siau Timur', value: 'Siau Timur' },
    { label: 'Siau Timur Selatan', value: 'Siau Timur Selatan' },
    { label: 'Tagulandang', value: 'Tagulandang' },
    { label: 'Tagulandang Selatan', value: 'Tagulandang Selatan' },
    { label: 'Tagulandang Utara', value: 'Tagulandang Utara' }
  ],
  'Kepulauan Talaud': [
    { label: 'Beo', value: 'Beo' },
    { label: 'Beo Selatan', value: 'Beo Selatan' },
    { label: 'Beo Utara', value: 'Beo Utara' },
    { label: 'Damao (Damau)', value: 'Damao (Damau)' },
    { label: 'Essang', value: 'Essang' },
    { label: 'Essang Selatan', value: 'Essang Selatan' },
    { label: 'Gemeh', value: 'Gemeh' },
    { label: 'Kabaruan', value: 'Kabaruan' },
    { label: 'Kalongan', value: 'Kalongan' },
    { label: 'Lirung', value: 'Lirung' },
    { label: 'Melonguane', value: 'Melonguane' },
    { label: 'Melonguane Timur', value: 'Melonguane Timur' },
    { label: 'Miangas', value: 'Miangas' },
    { label: 'Moronge', value: 'Moronge' },
    { label: 'Nanusa', value: 'Nanusa' },
    { label: 'Pulutan', value: 'Pulutan' },
    { label: 'Rainis', value: 'Rainis' },
    { label: 'Salibabu', value: 'Salibabu' },
    { label: 'Tampan Amma', value: 'Tampan Amma' }
  ],
  Kotamobagu: [
    { label: 'Kotamobagu Barat', value: 'Kotamobagu Barat' },
    { label: 'Kotamobagu Selatan', value: 'Kotamobagu Selatan' },
    { label: 'Kotamobagu Timur', value: 'Kotamobagu Timur' },
    { label: 'Kotamobagu Utara', value: 'Kotamobagu Utara' }
  ],
  Manado: [
    { label: 'Bunaken', value: 'Bunaken' },
    { label: 'Bunaken Kepulauan', value: 'Bunaken Kepulauan' },
    { label: 'Malalayang', value: 'Malalayang' },
    { label: 'Mapanget', value: 'Mapanget' },
    { label: 'Paal Dua', value: 'Paal Dua' },
    { label: 'Sario', value: 'Sario' },
    { label: 'Singkil', value: 'Singkil' },
    { label: 'Tikala', value: 'Tikala' },
    { label: 'Tuminiting', value: 'Tuminiting' },
    { label: 'Wanea', value: 'Wanea' },
    { label: 'Wenang', value: 'Wenang' }
  ],
  Minahasa: [
    { label: 'Eris', value: 'Eris' },
    { label: 'Kakas', value: 'Kakas' },
    { label: 'Kakas Barat', value: 'Kakas Barat' },
    { label: 'Kawangkoan', value: 'Kawangkoan' },
    { label: 'Kawangkoan Barat', value: 'Kawangkoan Barat' },
    { label: 'Kawangkoan Utara', value: 'Kawangkoan Utara' },
    { label: 'Kombi', value: 'Kombi' },
    { label: 'Langowan Barat', value: 'Langowan Barat' },
    { label: 'Langowan Selatan', value: 'Langowan Selatan' },
    { label: 'Langowan Timur', value: 'Langowan Timur' },
    { label: 'Langowan Utara', value: 'Langowan Utara' },
    { label: 'Lembean Timur', value: 'Lembean Timur' },
    { label: 'Mandolang', value: 'Mandolang' },
    { label: 'Pineleng', value: 'Pineleng' },
    { label: 'Remboken', value: 'Remboken' },
    { label: 'Sonder', value: 'Sonder' },
    { label: 'Tombariri', value: 'Tombariri' },
    { label: 'Tombariri Timur', value: 'Tombariri Timur' },
    { label: 'Tombulu', value: 'Tombulu' },
    { label: 'Tompaso', value: 'Tompaso' },
    { label: 'Tompaso Barat', value: 'Tompaso Barat' },
    { label: 'Tondano Barat', value: 'Tondano Barat' },
    { label: 'Tondano Selatan', value: 'Tondano Selatan' },
    { label: 'Tondano Timur', value: 'Tondano Timur' },
    { label: 'Tondano Utara', value: 'Tondano Utara' }
  ],
  'Minahasa Selatan': [
    { label: 'Amurang', value: 'Amurang' },
    { label: 'Amurang Barat', value: 'Amurang Barat' },
    { label: 'Amurang Timur', value: 'Amurang Timur' },
    { label: 'Kumelembuai', value: 'Kumelembuai' },
    { label: 'Maesaan', value: 'Maesaan' },
    { label: 'Modoinding', value: 'Modoinding' },
    { label: 'Motoling', value: 'Motoling' },
    { label: 'Motoling Barat', value: 'Motoling Barat' },
    { label: 'Motoling Timur', value: 'Motoling Timur' },
    { label: 'Ranoyapo', value: 'Ranoyapo' },
    { label: 'Sinonsayang', value: 'Sinonsayang' },
    { label: 'Suluun Tareran', value: 'Suluun Tareran' },
    { label: 'Tareran', value: 'Tareran' },
    { label: 'Tatapaan', value: 'Tatapaan' },
    { label: 'Tenga', value: 'Tenga' },
    { label: 'Tompaso Baru', value: 'Tompaso Baru' },
    { label: 'Tumpaan', value: 'Tumpaan' }
  ],
  'Minahasa Tenggara': [
    { label: 'Belang', value: 'Belang' },
    { label: 'Pasan', value: 'Pasan' },
    { label: 'Pusomaen', value: 'Pusomaen' },
    { label: 'Ratahan', value: 'Ratahan' },
    { label: 'Ratahan Timur', value: 'Ratahan Timur' },
    { label: 'Ratatotok', value: 'Ratatotok' },
    { label: 'Silian Raya', value: 'Silian Raya' },
    { label: 'Tombatu', value: 'Tombatu' },
    { label: 'Tombatu Timur', value: 'Tombatu Timur' },
    { label: 'Tombatu Utara', value: 'Tombatu Utara' },
    { label: 'Touluaan', value: 'Touluaan' },
    { label: 'Touluaan Selatan', value: 'Touluaan Selatan' }
  ],
  'Minahasa Utara': [
    { label: 'Airmadidi', value: 'Airmadidi' },
    { label: 'Dimembe', value: 'Dimembe' },
    { label: 'Kalawat', value: 'Kalawat' },
    { label: 'Kauditan', value: 'Kauditan' },
    { label: 'Kema', value: 'Kema' },
    { label: 'Likupang Barat', value: 'Likupang Barat' },
    { label: 'Likupang Selatan', value: 'Likupang Selatan' },
    { label: 'Likupang Timur', value: 'Likupang Timur' },
    { label: 'Talawaan', value: 'Talawaan' },
    { label: 'Wori', value: 'Wori' }
  ],
  Tomohon: [
    { label: 'Tomohon Barat', value: 'Tomohon Barat' },
    { label: 'Tomohon Selatan', value: 'Tomohon Selatan' },
    { label: 'Tomohon Tengah', value: 'Tomohon Tengah' },
    { label: 'Tomohon Timur', value: 'Tomohon Timur' },
    { label: 'Tomohon Utara', value: 'Tomohon Utara' }
  ],
  Agam: [
    {
      label: 'Ampek Nagari (IV Nagari )',
      value: 'Ampek Nagari (IV Nagari )'
    },
    { label: 'Banuhampu', value: 'Banuhampu' },
    { label: 'Baso', value: 'Baso' },
    { label: 'Candung', value: 'Candung' },
    {
      label: 'IV Angkat Candung (Ampek Angkek)',
      value: 'IV Angkat Candung (Ampek Angkek)'
    },
    { label: 'IV Koto (Ampek Koto)', value: 'IV Koto (Ampek Koto)' },
    { label: 'Kamang Magek', value: 'Kamang Magek' },
    { label: 'Lubuk Basung', value: 'Lubuk Basung' },
    { label: 'Malakak', value: 'Malakak' },
    { label: 'Matur', value: 'Matur' },
    { label: 'Palembayan', value: 'Palembayan' },
    { label: 'Palupuh', value: 'Palupuh' },
    { label: 'Sungai Pua (Puar)', value: 'Sungai Pua (Puar)' },
    { label: 'Tanjung Mutiara', value: 'Tanjung Mutiara' },
    { label: 'Tanjung Raya', value: 'Tanjung Raya' },
    { label: 'Tilatang Kamang', value: 'Tilatang Kamang' }
  ],
  Bukittinggi: [
    { label: 'Aur Birugo Tigo Baleh', value: 'Aur Birugo Tigo Baleh' },
    {
      label: 'Guguk Panjang (Guguak Panjang)',
      value: 'Guguk Panjang (Guguak Panjang)'
    },
    { label: 'Mandiangin Koto Selayan', value: 'Mandiangin Koto Selayan' }
  ],
  Dharmasraya: [
    { label: 'Asam Jujuhan', value: 'Asam Jujuhan' },
    { label: 'Koto Baru', value: 'Koto Baru' },
    { label: 'Koto Besar', value: 'Koto Besar' },
    { label: 'Koto Salak', value: 'Koto Salak' },
    { label: 'Padang Laweh', value: 'Padang Laweh' },
    { label: 'Pulau Punjung', value: 'Pulau Punjung' },
    { label: 'Sembilan Koto', value: 'Sembilan Koto' },
    { label: 'Sitiung', value: 'Sitiung' },
    { label: 'Sungai Rumbai', value: 'Sungai Rumbai' },
    { label: 'Timpeh', value: 'Timpeh' },
    { label: 'Tiumang', value: 'Tiumang' }
  ],
  'Kepulauan Mentawai': [
    { label: 'Pagai Selatan', value: 'Pagai Selatan' },
    { label: 'Pagai Utara', value: 'Pagai Utara' },
    { label: 'Siberut Barat', value: 'Siberut Barat' },
    { label: 'Siberut Barat Daya', value: 'Siberut Barat Daya' },
    { label: 'Siberut Selatan', value: 'Siberut Selatan' },
    { label: 'Siberut Tengah', value: 'Siberut Tengah' },
    { label: 'Siberut Utara', value: 'Siberut Utara' },
    { label: 'Sikakap', value: 'Sikakap' },
    { label: 'Sipora Selatan', value: 'Sipora Selatan' },
    { label: 'Sipora Utara', value: 'Sipora Utara' }
  ],
  'Lima Puluh Kota': [
    { label: 'Akabiluru', value: 'Akabiluru' },
    { label: 'Bukik Barisan', value: 'Bukik Barisan' },
    { label: 'Guguak (Gugu)', value: 'Guguak (Gugu)' },
    {
      label: 'Gunuang Omeh (Gunung Mas)',
      value: 'Gunuang Omeh (Gunung Mas)'
    },
    { label: 'Harau', value: 'Harau' },
    { label: 'Kapur IX/Sembilan', value: 'Kapur IX/Sembilan' },
    { label: 'Lareh Sago Halaban', value: 'Lareh Sago Halaban' },
    { label: 'Luak (Luhak)', value: 'Luak (Luhak)' },
    { label: 'Mungka', value: 'Mungka' },
    { label: 'Pangkalan Koto Baru', value: 'Pangkalan Koto Baru' },
    { label: 'Payakumbuh', value: 'Payakumbuh' },
    {
      label: 'Situjuah Limo/Lima Nagari',
      value: 'Situjuah Limo/Lima Nagari'
    },
    { label: 'Suliki', value: 'Suliki' }
  ],
  Padang: [
    { label: 'Bungus Teluk Kabung', value: 'Bungus Teluk Kabung' },
    { label: 'Koto Tangah', value: 'Koto Tangah' },
    { label: 'Kuranji', value: 'Kuranji' },
    { label: 'Lubuk Begalung', value: 'Lubuk Begalung' },
    { label: 'Lubuk Kilangan', value: 'Lubuk Kilangan' },
    { label: 'Nanggalo', value: 'Nanggalo' },
    { label: 'Padang Barat', value: 'Padang Barat' },
    { label: 'Padang Selatan', value: 'Padang Selatan' },
    { label: 'Padang Timur', value: 'Padang Timur' },
    { label: 'Padang Utara', value: 'Padang Utara' },
    { label: 'Pauh', value: 'Pauh' }
  ],
  'Padang Panjang': [
    { label: 'Padang Panjang Barat', value: 'Padang Panjang Barat' },
    { label: 'Padang Panjang Timur', value: 'Padang Panjang Timur' }
  ],
  'Padang Pariaman': [
    { label: '2 X 11 Enam Lingkung', value: '2 X 11 Enam Lingkung' },
    { label: '2 X 11 Kayu Tanam', value: '2 X 11 Kayu Tanam' },
    { label: 'Batang Anai', value: 'Batang Anai' },
    { label: 'Batang Gasan', value: 'Batang Gasan' },
    { label: 'Enam Lingkung', value: 'Enam Lingkung' },
    { label: 'IV Koto Aur Malintang', value: 'IV Koto Aur Malintang' },
    { label: 'Lubuk Alung', value: 'Lubuk Alung' },
    { label: 'Nan Sabaris', value: 'Nan Sabaris' },
    { label: 'Padang Sago', value: 'Padang Sago' },
    { label: 'Patamuan', value: 'Patamuan' },
    {
      label: 'Sintuk/Sintuak Toboh Gadang',
      value: 'Sintuk/Sintuak Toboh Gadang'
    },
    {
      label: 'Sungai Geringging/Garingging',
      value: 'Sungai Geringging/Garingging'
    },
    { label: 'Sungai Limau', value: 'Sungai Limau' },
    { label: 'Ulakan Tapakih/Tapakis', value: 'Ulakan Tapakih/Tapakis' },
    { label: 'V Koto Kampung Dalam', value: 'V Koto Kampung Dalam' },
    { label: 'V Koto Timur', value: 'V Koto Timur' },
    { label: 'VII Koto Sungai Sarik', value: 'VII Koto Sungai Sarik' }
  ],
  Pariaman: [
    { label: 'Pariaman Selatan', value: 'Pariaman Selatan' },
    { label: 'Pariaman Tengah', value: 'Pariaman Tengah' },
    { label: 'Pariaman Timur', value: 'Pariaman Timur' },
    { label: 'Pariaman Utara', value: 'Pariaman Utara' }
  ],
  Pasaman: [
    { label: 'Bonjol', value: 'Bonjol' },
    { label: 'Duo Koto (II Koto)', value: 'Duo Koto (II Koto)' },
    { label: 'Lubuk Sikaping', value: 'Lubuk Sikaping' },
    { label: 'Mapat Tunggul', value: 'Mapat Tunggul' },
    { label: 'Mapat Tunggul Selatan', value: 'Mapat Tunggul Selatan' },
    { label: 'Padang Gelugur', value: 'Padang Gelugur' },
    { label: 'Panti', value: 'Panti' },
    { label: 'Rao', value: 'Rao' },
    { label: 'Rao Selatan', value: 'Rao Selatan' },
    { label: 'Rao Utara', value: 'Rao Utara' },
    { label: 'Simpang Alahan Mati', value: 'Simpang Alahan Mati' },
    { label: 'Tigo Nagari (III Nagari)', value: 'Tigo Nagari (III Nagari)' }
  ],
  'Pasaman Barat': [
    { label: 'Gunung Tuleh', value: 'Gunung Tuleh' },
    { label: 'Kinali', value: 'Kinali' },
    { label: 'Koto Balingka', value: 'Koto Balingka' },
    { label: 'Lembah Melintang', value: 'Lembah Melintang' },
    { label: 'Luhak Nan Duo', value: 'Luhak Nan Duo' },
    { label: 'Pasaman', value: 'Pasaman' },
    { label: 'Ranah Batahan', value: 'Ranah Batahan' },
    {
      label: 'Sasak Ranah Pasisir/Pesisie',
      value: 'Sasak Ranah Pasisir/Pesisie'
    },
    { label: 'Sei Beremas', value: 'Sei Beremas' },
    { label: 'Sungai Aur', value: 'Sungai Aur' },
    { label: 'Talamau', value: 'Talamau' }
  ],
  Payakumbuh: [
    { label: 'Lamposi Tigo Nagari', value: 'Lamposi Tigo Nagari' },
    { label: 'Payakumbuh Barat', value: 'Payakumbuh Barat' },
    { label: 'Payakumbuh Selatan', value: 'Payakumbuh Selatan' },
    { label: 'Payakumbuh Timur', value: 'Payakumbuh Timur' },
    { label: 'Payakumbuh Utara', value: 'Payakumbuh Utara' }
  ],
  'Pesisir Selatan': [
    { label: 'Airpura', value: 'Airpura' },
    { label: 'Basa Ampek Balai Tapan', value: 'Basa Ampek Balai Tapan' },
    { label: 'Batang Kapas', value: 'Batang Kapas' },
    { label: 'Bayang', value: 'Bayang' },
    { label: 'IV Jurai', value: 'IV Jurai' },
    { label: 'IV Nagari Bayang Utara', value: 'IV Nagari Bayang Utara' },
    { label: 'Koto XI Tarusan', value: 'Koto XI Tarusan' },
    { label: 'Lengayang', value: 'Lengayang' },
    { label: 'Linggo Sari Baganti', value: 'Linggo Sari Baganti' },
    { label: 'Lunang', value: 'Lunang' },
    { label: 'Pancung Soal', value: 'Pancung Soal' },
    { label: 'Ranah Ampek Hulu Tapan', value: 'Ranah Ampek Hulu Tapan' },
    { label: 'Ranah Pesisir', value: 'Ranah Pesisir' },
    { label: 'Silaut', value: 'Silaut' },
    { label: 'Sutera', value: 'Sutera' }
  ],
  'Sawah Lunto': [
    { label: 'Barangin', value: 'Barangin' },
    { label: 'Lembah Segar', value: 'Lembah Segar' },
    { label: 'Silungkang', value: 'Silungkang' },
    { label: 'Talawi', value: 'Talawi' }
  ],
  Sijunjung: [
    { label: 'IV Nagari', value: 'IV Nagari' },
    { label: 'Kamang Baru', value: 'Kamang Baru' },
    { label: 'Koto VII', value: 'Koto VII' },
    { label: 'Kupitan', value: 'Kupitan' },
    { label: 'Lubuak Tarok', value: 'Lubuak Tarok' },
    { label: 'Sijunjung', value: 'Sijunjung' },
    { label: 'Sumpur Kudus', value: 'Sumpur Kudus' },
    { label: 'Tanjung Gadang', value: 'Tanjung Gadang' }
  ],
  Solok: [
    { label: 'Lubuk Sikarah', value: 'Lubuk Sikarah' },
    { label: 'Tanjung Harapan', value: 'Tanjung Harapan' },
    { label: 'Bukit Sundi', value: 'Bukit Sundi' },
    { label: 'Danau Kembar', value: 'Danau Kembar' },
    { label: 'Gunung Talang', value: 'Gunung Talang' },
    { label: 'Hiliran Gumanti', value: 'Hiliran Gumanti' },
    { label: 'IX Koto Sungai Lasi', value: 'IX Koto Sungai Lasi' },
    { label: 'Junjung Sirih', value: 'Junjung Sirih' },
    { label: 'Kubung', value: 'Kubung' },
    { label: 'Lembah Gumanti', value: 'Lembah Gumanti' },
    { label: 'Lembang Jaya', value: 'Lembang Jaya' },
    { label: 'Pantai Cermin', value: 'Pantai Cermin' },
    { label: 'Payung Sekaki', value: 'Payung Sekaki' },
    { label: 'Tigo Lurah', value: 'Tigo Lurah' },
    { label: 'X Koto Diatas', value: 'X Koto Diatas' },
    { label: 'X Koto Singkarak', value: 'X Koto Singkarak' }
  ],
  'Solok Selatan': [
    {
      label: 'Koto Parik Gadang Diateh',
      value: 'Koto Parik Gadang Diateh'
    },
    { label: 'Pauh Duo', value: 'Pauh Duo' },
    { label: 'Sangir', value: 'Sangir' },
    { label: 'Sangir Balai Janggo', value: 'Sangir Balai Janggo' },
    { label: 'Sangir Batang Hari', value: 'Sangir Batang Hari' },
    { label: 'Sangir Jujuan', value: 'Sangir Jujuan' },
    { label: 'Sungai Pagu', value: 'Sungai Pagu' }
  ],
  'Tanah Datar': [
    { label: 'Batipuh', value: 'Batipuh' },
    { label: 'Batipuh Selatan', value: 'Batipuh Selatan' },
    { label: 'Lima Kaum', value: 'Lima Kaum' },
    { label: 'Lintau Buo', value: 'Lintau Buo' },
    { label: 'Lintau Buo Utara', value: 'Lintau Buo Utara' },
    { label: 'Padang Ganting', value: 'Padang Ganting' },
    { label: 'Pariangan', value: 'Pariangan' },
    { label: 'Rambatan', value: 'Rambatan' },
    { label: 'Salimpaung', value: 'Salimpaung' },
    { label: 'Sepuluh Koto (X Koto)', value: 'Sepuluh Koto (X Koto)' },
    { label: 'Sungai Tarab', value: 'Sungai Tarab' },
    { label: 'Sungayang', value: 'Sungayang' },
    { label: 'Tanjung Baru', value: 'Tanjung Baru' },
    { label: 'Tanjung Emas', value: 'Tanjung Emas' }
  ],
  Banyuasin: [
    { label: 'Air Kumbang', value: 'Air Kumbang' },
    { label: 'Air Salek', value: 'Air Salek' },
    { label: 'Banyuasin I', value: 'Banyuasin I' },
    { label: 'Banyuasin II', value: 'Banyuasin II' },
    { label: 'Banyuasin III', value: 'Banyuasin III' },
    { label: 'Betung', value: 'Betung' },
    { label: 'Makarti Jaya', value: 'Makarti Jaya' },
    { label: 'Muara Padang', value: 'Muara Padang' },
    { label: 'Muara Sugihan', value: 'Muara Sugihan' },
    { label: 'Muara Telang', value: 'Muara Telang' },
    { label: 'Pulau Rimau', value: 'Pulau Rimau' },
    { label: 'Rambutan', value: 'Rambutan' },
    { label: 'Rantau Bayur', value: 'Rantau Bayur' },
    { label: 'Sembawa', value: 'Sembawa' },
    { label: 'Suak Tapeh', value: 'Suak Tapeh' },
    { label: 'Sumber Marga Telang', value: 'Sumber Marga Telang' },
    { label: 'Talang Kelapa', value: 'Talang Kelapa' },
    { label: 'Tanjung Lago', value: 'Tanjung Lago' },
    { label: 'Tungkal Ilir', value: 'Tungkal Ilir' }
  ],
  'Empat Lawang': [
    { label: 'Lintang Kanan', value: 'Lintang Kanan' },
    { label: 'Muara Pinang', value: 'Muara Pinang' },
    { label: 'Pasemah Air Keruh', value: 'Pasemah Air Keruh' },
    { label: 'Pendopo', value: 'Pendopo' },
    { label: 'Pendopo Barat', value: 'Pendopo Barat' },
    { label: 'Saling', value: 'Saling' },
    { label: 'Sikap Dalam', value: 'Sikap Dalam' },
    { label: 'Talang Padang', value: 'Talang Padang' },
    { label: 'Tebing Tinggi', value: 'Tebing Tinggi' },
    { label: 'Ulu Musi', value: 'Ulu Musi' }
  ],
  Lahat: [
    { label: 'Gumay Talang', value: 'Gumay Talang' },
    { label: 'Gumay Ulu', value: 'Gumay Ulu' },
    { label: 'Jarai', value: 'Jarai' },
    { label: 'Kikim Barat', value: 'Kikim Barat' },
    { label: 'Kikim Selatan', value: 'Kikim Selatan' },
    { label: 'Kikim Tengah', value: 'Kikim Tengah' },
    { label: 'Kikim Timur', value: 'Kikim Timur' },
    { label: 'Kota Agung', value: 'Kota Agung' },
    { label: 'Lahat', value: 'Lahat' },
    { label: 'Merapi Barat', value: 'Merapi Barat' },
    { label: 'Merapi Selatan', value: 'Merapi Selatan' },
    { label: 'Merapi Timur', value: 'Merapi Timur' },
    { label: 'Muarapayang', value: 'Muarapayang' },
    { label: 'Mulak Ulu', value: 'Mulak Ulu' },
    { label: 'Pagar Gunung', value: 'Pagar Gunung' },
    { label: 'Pajar Bulan', value: 'Pajar Bulan' },
    { label: 'Pseksu', value: 'Pseksu' },
    { label: 'Pulau Pinang', value: 'Pulau Pinang' },
    { label: 'Sukamerindu', value: 'Sukamerindu' },
    { label: 'Tanjung Sakti Pumi', value: 'Tanjung Sakti Pumi' },
    { label: 'Tanjung Sakti Pumu', value: 'Tanjung Sakti Pumu' },
    { label: 'Tanjung Tebat', value: 'Tanjung Tebat' }
  ],
  'Lubuk Linggau': [
    {
      label: 'Lubuk Linggau Barat Dua (II)',
      value: 'Lubuk Linggau Barat Dua (II)'
    },
    {
      label: 'Lubuk Linggau Barat Satu (I)',
      value: 'Lubuk Linggau Barat Satu (I)'
    },
    {
      label: 'Lubuk Linggau Selatan Dua (II)',
      value: 'Lubuk Linggau Selatan Dua (II)'
    },
    {
      label: 'Lubuk Linggau Selatan Satu (I)',
      value: 'Lubuk Linggau Selatan Satu (I)'
    },
    {
      label: 'Lubuk Linggau Timur Dua (II)',
      value: 'Lubuk Linggau Timur Dua (II)'
    },
    {
      label: 'Lubuk Linggau Timur Satu (I)',
      value: 'Lubuk Linggau Timur Satu (I)'
    },
    {
      label: 'Lubuk Linggau Utara Dua (II)',
      value: 'Lubuk Linggau Utara Dua (II)'
    },
    {
      label: 'Lubuk Linggau Utara Satu (I)',
      value: 'Lubuk Linggau Utara Satu (I)'
    }
  ],
  'Muara Enim': [
    { label: 'Belida Darat', value: 'Belida Darat' },
    { label: 'Belimbing', value: 'Belimbing' },
    { label: 'Benakat', value: 'Benakat' },
    { label: 'Gelumbang', value: 'Gelumbang' },
    { label: 'Gunung Megang', value: 'Gunung Megang' },
    { label: 'Kelekar', value: 'Kelekar' },
    { label: 'Lawang Kidul', value: 'Lawang Kidul' },
    { label: 'Lembak', value: 'Lembak' },
    { label: 'Lubai', value: 'Lubai' },
    { label: 'Lubai Ulu', value: 'Lubai Ulu' },
    { label: 'Muara Belida', value: 'Muara Belida' },
    { label: 'Muara Enim', value: 'Muara Enim' },
    { label: 'Rambang', value: 'Rambang' },
    { label: 'Rambang Dangku', value: 'Rambang Dangku' },
    { label: 'Semende Darat Laut', value: 'Semende Darat Laut' },
    { label: 'Semende Darat Tengah', value: 'Semende Darat Tengah' },
    { label: 'Semende Darat Ulu', value: 'Semende Darat Ulu' },
    { label: 'Sungai Rotan', value: 'Sungai Rotan' },
    { label: 'Tanjung Agung', value: 'Tanjung Agung' },
    { label: 'Ujan Mas', value: 'Ujan Mas' }
  ],
  'Musi Banyuasin': [
    { label: 'Babat Supat', value: 'Babat Supat' },
    { label: 'Babat Toman', value: 'Babat Toman' },
    { label: 'Batanghari Leko', value: 'Batanghari Leko' },
    { label: 'Bayung Lencir', value: 'Bayung Lencir' },
    { label: 'Keluang', value: 'Keluang' },
    { label: 'Lais', value: 'Lais' },
    { label: 'Lalan (Sungai Lalan)', value: 'Lalan (Sungai Lalan)' },
    { label: 'Lawang Wetan', value: 'Lawang Wetan' },
    {
      label: 'Plakat Tinggi (Pelakat Tinggi)',
      value: 'Plakat Tinggi (Pelakat Tinggi)'
    },
    { label: 'Sanga Desa', value: 'Sanga Desa' },
    { label: 'Sekayu', value: 'Sekayu' },
    { label: 'Sungai Keruh', value: 'Sungai Keruh' },
    { label: 'Sungai Lilin', value: 'Sungai Lilin' },
    { label: 'Tungkal Jaya', value: 'Tungkal Jaya' }
  ],
  'Musi Rawas': [
    { label: 'BTS Ulu', value: 'BTS Ulu' },
    { label: 'Jaya Loka', value: 'Jaya Loka' },
    { label: 'Megang Sakti', value: 'Megang Sakti' },
    { label: 'Muara Beliti', value: 'Muara Beliti' },
    { label: 'Muara Kelingi', value: 'Muara Kelingi' },
    { label: 'Muara Lakitan', value: 'Muara Lakitan' },
    { label: 'Purwodadi', value: 'Purwodadi' },
    { label: 'Selangit', value: 'Selangit' },
    { label: 'Sukakarya', value: 'Sukakarya' },
    {
      label: 'Suku Tengah Lakitan Ulu Terawas',
      value: 'Suku Tengah Lakitan Ulu Terawas'
    },
    { label: 'Sumber Harta', value: 'Sumber Harta' },
    { label: 'Tiang Pumpung Kepungut', value: 'Tiang Pumpung Kepungut' },
    { label: 'Tuah Negeri', value: 'Tuah Negeri' },
    { label: 'Tugumulyo', value: 'Tugumulyo' }
  ],
  'Musi Rawas Utara': [
    { label: 'Karang Dapo', value: 'Karang Dapo' },
    { label: 'Karang Jaya', value: 'Karang Jaya' },
    { label: 'Nibung', value: 'Nibung' },
    { label: 'Rawas Ilir', value: 'Rawas Ilir' },
    { label: 'Rawas Ulu', value: 'Rawas Ulu' },
    { label: 'Rupit', value: 'Rupit' },
    { label: 'Ulu Rawas', value: 'Ulu Rawas' }
  ],
  'Ogan Ilir': [
    { label: 'Indralaya', value: 'Indralaya' },
    { label: 'Indralaya Selatan', value: 'Indralaya Selatan' },
    { label: 'Indralaya Utara', value: 'Indralaya Utara' },
    { label: 'Kandis', value: 'Kandis' },
    { label: 'Lubuk Keliat', value: 'Lubuk Keliat' },
    { label: 'Muara Kuang', value: 'Muara Kuang' },
    { label: 'Payaraman', value: 'Payaraman' },
    { label: 'Pemulutan', value: 'Pemulutan' },
    { label: 'Pemulutan Barat', value: 'Pemulutan Barat' },
    { label: 'Pemulutan Selatan', value: 'Pemulutan Selatan' },
    { label: 'Rambang Kuang', value: 'Rambang Kuang' },
    { label: 'Rantau Alai', value: 'Rantau Alai' },
    { label: 'Rantau Panjang', value: 'Rantau Panjang' },
    { label: 'Sungai Pinang', value: 'Sungai Pinang' },
    { label: 'Tanjung Batu', value: 'Tanjung Batu' },
    { label: 'Tanjung Raja', value: 'Tanjung Raja' }
  ],
  'Ogan Komering Ilir': [
    { label: 'Air Sugihan', value: 'Air Sugihan' },
    { label: 'Cengal', value: 'Cengal' },
    { label: 'Jejawi', value: 'Jejawi' },
    { label: 'Kayu Agung', value: 'Kayu Agung' },
    { label: 'Lempuing', value: 'Lempuing' },
    { label: 'Lempuing Jaya', value: 'Lempuing Jaya' },
    { label: 'Mesuji', value: 'Mesuji' },
    { label: 'Mesuji Makmur', value: 'Mesuji Makmur' },
    { label: 'Mesuji Raya', value: 'Mesuji Raya' },
    { label: 'Pampangan', value: 'Pampangan' },
    { label: 'Pangkalan Lampam', value: 'Pangkalan Lampam' },
    { label: 'Pedamaran', value: 'Pedamaran' },
    { label: 'Pedamaran Timur', value: 'Pedamaran Timur' },
    { label: 'Sirah Pulau Padang', value: 'Sirah Pulau Padang' },
    { label: 'Sungai Menang', value: 'Sungai Menang' },
    { label: 'Tanjung Lubuk', value: 'Tanjung Lubuk' },
    { label: 'Teluk Gelam', value: 'Teluk Gelam' },
    { label: 'Tulung Selapan', value: 'Tulung Selapan' }
  ],
  'Ogan Komering Ulu': [
    { label: 'Baturaja Barat', value: 'Baturaja Barat' },
    { label: 'Baturaja Timur', value: 'Baturaja Timur' },
    { label: 'Lengkiti', value: 'Lengkiti' },
    { label: 'Lubuk Batang', value: 'Lubuk Batang' },
    { label: 'Lubuk Raja', value: 'Lubuk Raja' },
    { label: 'Muara Jaya', value: 'Muara Jaya' },
    { label: 'Pengandonan', value: 'Pengandonan' },
    { label: 'Peninjauan', value: 'Peninjauan' },
    { label: 'Semidang Aji', value: 'Semidang Aji' },
    { label: 'Sinar Peninjauan', value: 'Sinar Peninjauan' },
    { label: 'Sosoh Buay Rayap', value: 'Sosoh Buay Rayap' },
    { label: 'Ulu Ogan', value: 'Ulu Ogan' }
  ],
  'Ogan Komering Ulu Selatan': [
    { label: 'Banding Agung', value: 'Banding Agung' },
    { label: 'Buana Pemaca', value: 'Buana Pemaca' },
    { label: 'Buay Pemaca', value: 'Buay Pemaca' },
    {
      label: 'Buay Pematang Ribu Ranau Tengah',
      value: 'Buay Pematang Ribu Ranau Tengah'
    },
    { label: 'Buay Rawan', value: 'Buay Rawan' },
    { label: 'Buay Runjung', value: 'Buay Runjung' },
    { label: 'Buay Sandang Aji', value: 'Buay Sandang Aji' },
    { label: 'Kisam Ilir', value: 'Kisam Ilir' },
    { label: 'Kisam Tinggi', value: 'Kisam Tinggi' },
    { label: 'Mekakau Ilir', value: 'Mekakau Ilir' },
    { label: 'Muaradua', value: 'Muaradua' },
    { label: 'Muaradua Kisam', value: 'Muaradua Kisam' },
    { label: 'Pulau Beringin', value: 'Pulau Beringin' },
    { label: 'Runjung Agung', value: 'Runjung Agung' },
    { label: 'Simpang', value: 'Simpang' },
    { label: 'Sindang Danau', value: 'Sindang Danau' },
    { label: 'Sungai Are', value: 'Sungai Are' },
    { label: 'Tiga Dihaji', value: 'Tiga Dihaji' },
    { label: 'Warkuk Ranau Selatan', value: 'Warkuk Ranau Selatan' }
  ],
  'Ogan Komering Ulu Timur': [
    { label: 'Belitang', value: 'Belitang' },
    { label: 'Belitang II', value: 'Belitang II' },
    { label: 'Belitang III', value: 'Belitang III' },
    { label: 'Belitang Jaya', value: 'Belitang Jaya' },
    { label: 'Belitang Madang Raya', value: 'Belitang Madang Raya' },
    { label: 'Belitang Mulya', value: 'Belitang Mulya' },
    { label: 'Buay Madang', value: 'Buay Madang' },
    { label: 'Buay Madang Timur', value: 'Buay Madang Timur' },
    { label: 'Buay Pemuka Bangsa Raja', value: 'Buay Pemuka Bangsa Raja' },
    { label: 'Buay Pemuka Peliung', value: 'Buay Pemuka Peliung' },
    { label: 'Bunga Mayang', value: 'Bunga Mayang' },
    { label: 'Cempaka', value: 'Cempaka' },
    { label: 'Jayapura', value: 'Jayapura' },
    { label: 'Madang Suku I', value: 'Madang Suku I' },
    { label: 'Madang Suku II', value: 'Madang Suku II' },
    { label: 'Madang Suku III', value: 'Madang Suku III' },
    { label: 'Martapura', value: 'Martapura' },
    { label: 'Semendawai Barat', value: 'Semendawai Barat' },
    { label: 'Semendawai Suku III', value: 'Semendawai Suku III' },
    { label: 'Semendawai Timur', value: 'Semendawai Timur' }
  ],
  'Pagar Alam': [
    { label: 'Dempo Selatan', value: 'Dempo Selatan' },
    { label: 'Dempo Tengah', value: 'Dempo Tengah' },
    { label: 'Dempo Utara', value: 'Dempo Utara' },
    { label: 'Pagar Alam Selatan', value: 'Pagar Alam Selatan' },
    { label: 'Pagar Alam Utara', value: 'Pagar Alam Utara' }
  ],
  Palembang: [
    { label: 'Alang-Alang Lebar', value: 'Alang-Alang Lebar' },
    { label: 'Bukit Kecil', value: 'Bukit Kecil' },
    { label: 'Gandus', value: 'Gandus' },
    { label: 'Ilir Barat I', value: 'Ilir Barat I' },
    { label: 'Ilir Barat II', value: 'Ilir Barat II' },
    { label: 'Ilir Timur I', value: 'Ilir Timur I' },
    { label: 'Ilir Timur II', value: 'Ilir Timur II' },
    { label: 'Kalidoni', value: 'Kalidoni' },
    { label: 'Kemuning', value: 'Kemuning' },
    { label: 'Kertapati', value: 'Kertapati' },
    { label: 'Plaju', value: 'Plaju' },
    { label: 'Sako', value: 'Sako' },
    { label: 'Seberang Ulu I', value: 'Seberang Ulu I' },
    { label: 'Seberang Ulu II', value: 'Seberang Ulu II' },
    { label: 'Sematang Borang', value: 'Sematang Borang' },
    { label: 'Sukarami', value: 'Sukarami' }
  ],
  'Penukal Abab Lematang Ilir': [
    { label: 'Abab', value: 'Abab' },
    { label: 'Penukal', value: 'Penukal' },
    { label: 'Penukal Utara', value: 'Penukal Utara' },
    { label: 'Talang Ubi', value: 'Talang Ubi' },
    { label: 'Tanah Abang', value: 'Tanah Abang' }
  ],
  Prabumulih: [
    { label: 'Cambai', value: 'Cambai' },
    { label: 'Prabumulih Barat', value: 'Prabumulih Barat' },
    { label: 'Prabumulih Selatan', value: 'Prabumulih Selatan' },
    { label: 'Prabumulih Timur', value: 'Prabumulih Timur' },
    { label: 'Prabumulih Utara', value: 'Prabumulih Utara' },
    { label: 'Rambang Kapak Tengah', value: 'Rambang Kapak Tengah' }
  ],
  Asahan: [
    { label: 'Aek Kuasan', value: 'Aek Kuasan' },
    { label: 'Aek Ledong', value: 'Aek Ledong' },
    { label: 'Aek Songsongan', value: 'Aek Songsongan' },
    { label: 'Air Batu', value: 'Air Batu' },
    { label: 'Air Joman', value: 'Air Joman' },
    { label: 'Bandar Pasir Mandoge', value: 'Bandar Pasir Mandoge' },
    { label: 'Bandar Pulau', value: 'Bandar Pulau' },
    { label: 'Buntu Pane', value: 'Buntu Pane' },
    { label: 'Kota Kisaran Barat', value: 'Kota Kisaran Barat' },
    { label: 'Kota Kisaran Timur', value: 'Kota Kisaran Timur' },
    { label: 'Meranti', value: 'Meranti' },
    { label: 'Pulau Rakyat', value: 'Pulau Rakyat' },
    { label: 'Pulo Bandring', value: 'Pulo Bandring' },
    { label: 'Rahuning', value: 'Rahuning' },
    { label: 'Rawang Panca Arga', value: 'Rawang Panca Arga' },
    { label: 'Sei Dadap', value: 'Sei Dadap' },
    { label: 'Sei Kepayang', value: 'Sei Kepayang' },
    { label: 'Sei Kepayang Barat', value: 'Sei Kepayang Barat' },
    { label: 'Sei Kepayang Timur', value: 'Sei Kepayang Timur' },
    { label: 'Setia Janji', value: 'Setia Janji' },
    { label: 'Silau Laut', value: 'Silau Laut' },
    { label: 'Simpang Empat', value: 'Simpang Empat' },
    { label: 'Tanjung Balai', value: 'Tanjung Balai' },
    { label: 'Teluk Dalam', value: 'Teluk Dalam' },
    { label: 'Tinggi Raja', value: 'Tinggi Raja' }
  ],
  'Batu Bara': [
    { label: 'Air Putih', value: 'Air Putih' },
    { label: 'Limapuluh', value: 'Limapuluh' },
    { label: 'Medang Deras', value: 'Medang Deras' },
    { label: 'Sei Balai', value: 'Sei Balai' },
    { label: 'Sei Suka', value: 'Sei Suka' },
    { label: 'Talawi', value: 'Talawi' },
    { label: 'Tanjung Tiram', value: 'Tanjung Tiram' }
  ],
  Binjai: [
    { label: 'Binjai Barat', value: 'Binjai Barat' },
    { label: 'Binjai Kota', value: 'Binjai Kota' },
    { label: 'Binjai Selatan', value: 'Binjai Selatan' },
    { label: 'Binjai Timur', value: 'Binjai Timur' },
    { label: 'Binjai Utara', value: 'Binjai Utara' }
  ],
  Dairi: [
    { label: 'Berampu (Brampu)', value: 'Berampu (Brampu)' },
    { label: 'Gunung Sitember', value: 'Gunung Sitember' },
    { label: 'Lae Parira', value: 'Lae Parira' },
    { label: 'Parbuluan', value: 'Parbuluan' },
    { label: 'Pegagan Hilir', value: 'Pegagan Hilir' },
    { label: 'Sidikalang', value: 'Sidikalang' },
    { label: 'Siempat Nempu', value: 'Siempat Nempu' },
    { label: 'Siempat Nempu Hilir', value: 'Siempat Nempu Hilir' },
    { label: 'Siempat Nempu Hulu', value: 'Siempat Nempu Hulu' },
    { label: 'Silahi Sabungan', value: 'Silahi Sabungan' },
    { label: 'Silima Pungga-Pungga', value: 'Silima Pungga-Pungga' },
    { label: 'Sitinjo', value: 'Sitinjo' },
    { label: 'Sumbul', value: 'Sumbul' },
    { label: 'Tanah Pinem', value: 'Tanah Pinem' },
    { label: 'Tiga Lingga', value: 'Tiga Lingga' }
  ],
  'Deli Serdang': [
    { label: 'Bangun Purba', value: 'Bangun Purba' },
    { label: 'Batang Kuis', value: 'Batang Kuis' },
    { label: 'Beringin', value: 'Beringin' },
    { label: 'Biru-Biru', value: 'Biru-Biru' },
    { label: 'Deli Tua', value: 'Deli Tua' },
    { label: 'Galang', value: 'Galang' },
    { label: 'Gunung Meriah', value: 'Gunung Meriah' },
    { label: 'Hamparan Perak', value: 'Hamparan Perak' },
    { label: 'Kutalimbaru', value: 'Kutalimbaru' },
    { label: 'Labuhan Deli', value: 'Labuhan Deli' },
    { label: 'Lubuk Pakam', value: 'Lubuk Pakam' },
    { label: 'Namo Rambe', value: 'Namo Rambe' },
    { label: 'Pagar Merbau', value: 'Pagar Merbau' },
    { label: 'Pancur Batu', value: 'Pancur Batu' },
    { label: 'Pantai Labu', value: 'Pantai Labu' },
    { label: 'Patumbak', value: 'Patumbak' },
    { label: 'Percut Sei Tuan', value: 'Percut Sei Tuan' },
    { label: 'Sibolangit', value: 'Sibolangit' },
    {
      label: 'Sinembah Tanjung Muda Hilir',
      value: 'Sinembah Tanjung Muda Hilir'
    },
    {
      label: 'Sinembah Tanjung Muda Hulu',
      value: 'Sinembah Tanjung Muda Hulu'
    },
    { label: 'Sunggal', value: 'Sunggal' },
    { label: 'Tanjung Morawa', value: 'Tanjung Morawa' }
  ],
  Gunungsitoli: [
    { label: 'Gunungsitoli', value: 'Gunungsitoli' },
    { label: "Gunungsitoli Alo'oa", value: "Gunungsitoli Alo'oa" },
    { label: 'Gunungsitoli Barat', value: 'Gunungsitoli Barat' },
    { label: 'Gunungsitoli Idanoi', value: 'Gunungsitoli Idanoi' },
    { label: 'Gunungsitoli Selatan', value: 'Gunungsitoli Selatan' },
    { label: 'Gunungsitoli Utara', value: 'Gunungsitoli Utara' }
  ],
  'Humbang Hasundutan': [
    { label: 'Bakti Raja', value: 'Bakti Raja' },
    { label: 'Dolok Sanggul', value: 'Dolok Sanggul' },
    { label: 'Lintong Nihuta', value: 'Lintong Nihuta' },
    { label: 'Onan Ganjang', value: 'Onan Ganjang' },
    { label: 'Pakkat', value: 'Pakkat' },
    { label: 'Paranginan', value: 'Paranginan' },
    { label: 'Parlilitan', value: 'Parlilitan' },
    { label: 'Pollung', value: 'Pollung' },
    { label: 'Sijama Polang', value: 'Sijama Polang' },
    { label: 'Tara Bintang', value: 'Tara Bintang' }
  ],
  Karo: [
    { label: 'Barus Jahe', value: 'Barus Jahe' },
    { label: 'Brastagi (Berastagi)', value: 'Brastagi (Berastagi)' },
    { label: 'Dolat Rayat', value: 'Dolat Rayat' },
    { label: 'Juhar', value: 'Juhar' },
    { label: 'Kabanjahe', value: 'Kabanjahe' },
    { label: 'Kuta Buluh', value: 'Kuta Buluh' },
    { label: 'Laubaleng', value: 'Laubaleng' },
    { label: 'Mardinding', value: 'Mardinding' },
    { label: 'Merdeka', value: 'Merdeka' },
    { label: 'Merek', value: 'Merek' },
    { label: 'Munte', value: 'Munte' },
    { label: 'Nama Teran', value: 'Nama Teran' },
    { label: 'Payung', value: 'Payung' },
    { label: 'Simpang Empat', value: 'Simpang Empat' },
    { label: 'Tiga Binanga', value: 'Tiga Binanga' },
    { label: 'Tiga Panah', value: 'Tiga Panah' },
    { label: 'Tiganderket', value: 'Tiganderket' }
  ],
  Labuhanbatu: [
    { label: 'Bilah Barat', value: 'Bilah Barat' },
    { label: 'Bilah Hilir', value: 'Bilah Hilir' },
    { label: 'Bilah Hulu', value: 'Bilah Hulu' },
    { label: 'Panai Hilir', value: 'Panai Hilir' },
    { label: 'Panai Hulu', value: 'Panai Hulu' },
    { label: 'Panai Tengah', value: 'Panai Tengah' },
    { label: 'Pangkatan', value: 'Pangkatan' },
    { label: 'Rantau Selatan', value: 'Rantau Selatan' },
    { label: 'Rantau Utara', value: 'Rantau Utara' }
  ],
  'Labuhanbatu Selatan': [
    { label: 'Kampung Rakyat', value: 'Kampung Rakyat' },
    { label: 'Kota Pinang', value: 'Kota Pinang' },
    { label: 'Sei/Sungai Kanan', value: 'Sei/Sungai Kanan' },
    { label: 'Silangkitang', value: 'Silangkitang' },
    { label: 'Torgamba', value: 'Torgamba' }
  ],
  'Labuhanbatu Utara': [
    { label: 'Aek Kuo', value: 'Aek Kuo' },
    { label: 'Aek Natas', value: 'Aek Natas' },
    { label: 'Kualuh Hilir', value: 'Kualuh Hilir' },
    { label: 'Kualuh Hulu', value: 'Kualuh Hulu' },
    { label: 'Kualuh Leidong', value: 'Kualuh Leidong' },
    { label: 'Kualuh Selatan', value: 'Kualuh Selatan' },
    { label: 'Marbau', value: 'Marbau' },
    { label: 'Na IX-X', value: 'Na IX-X' }
  ],
  Langkat: [
    { label: 'Babalan', value: 'Babalan' },
    { label: 'Bahorok', value: 'Bahorok' },
    { label: 'Batang Serangan', value: 'Batang Serangan' },
    { label: 'Besitang', value: 'Besitang' },
    { label: 'Binjai', value: 'Binjai' },
    { label: 'Brandan Barat', value: 'Brandan Barat' },
    { label: 'Gebang', value: 'Gebang' },
    { label: 'Hinai', value: 'Hinai' },
    { label: 'Kuala', value: 'Kuala' },
    { label: 'Kutambaru', value: 'Kutambaru' },
    { label: 'Padang Tualang', value: 'Padang Tualang' },
    { label: 'Pangkalan Susu', value: 'Pangkalan Susu' },
    { label: 'Pematang Jaya', value: 'Pematang Jaya' },
    { label: 'Salapian', value: 'Salapian' },
    { label: 'Sawit Seberang', value: 'Sawit Seberang' },
    { label: 'Secanggang', value: 'Secanggang' },
    { label: 'Sei Binge (Bingai)', value: 'Sei Binge (Bingai)' },
    { label: 'Sei Lepan', value: 'Sei Lepan' },
    { label: 'Selesai', value: 'Selesai' },
    { label: 'Sirapit (Serapit)', value: 'Sirapit (Serapit)' },
    { label: 'Stabat', value: 'Stabat' },
    { label: 'Tanjungpura', value: 'Tanjungpura' },
    { label: 'Wampu', value: 'Wampu' }
  ],
  'Mandailing Natal': [
    { label: 'Batahan', value: 'Batahan' },
    { label: 'Batang Natal', value: 'Batang Natal' },
    { label: 'Bukit Malintang', value: 'Bukit Malintang' },
    { label: 'Huta Bargot', value: 'Huta Bargot' },
    { label: 'Kotanopan', value: 'Kotanopan' },
    { label: 'Lembah Sorik Marapi', value: 'Lembah Sorik Marapi' },
    { label: 'Lingga Bayu', value: 'Lingga Bayu' },
    { label: 'Muara Batang Gadis', value: 'Muara Batang Gadis' },
    { label: 'Muara Sipongi', value: 'Muara Sipongi' },
    { label: 'Naga Juang', value: 'Naga Juang' },
    { label: 'Natal', value: 'Natal' },
    { label: 'Pakantan', value: 'Pakantan' },
    { label: 'Panyabungan', value: 'Panyabungan' },
    { label: 'Panyabungan Barat', value: 'Panyabungan Barat' },
    { label: 'Panyabungan Selatan', value: 'Panyabungan Selatan' },
    { label: 'Panyabungan Timur', value: 'Panyabungan Timur' },
    { label: 'Panyabungan Utara', value: 'Panyabungan Utara' },
    { label: 'Puncak Sorik Marapi', value: 'Puncak Sorik Marapi' },
    { label: 'Ranto Baek', value: 'Ranto Baek' },
    { label: 'Siabu', value: 'Siabu' },
    { label: 'Sinunukan', value: 'Sinunukan' },
    { label: 'Tambangan', value: 'Tambangan' },
    { label: 'Ulu Pungkut', value: 'Ulu Pungkut' }
  ],
  Medan: [
    { label: 'Medan Amplas', value: 'Medan Amplas' },
    { label: 'Medan Area', value: 'Medan Area' },
    { label: 'Medan Barat', value: 'Medan Barat' },
    { label: 'Medan Baru', value: 'Medan Baru' },
    { label: 'Medan Belawan Kota', value: 'Medan Belawan Kota' },
    { label: 'Medan Deli', value: 'Medan Deli' },
    { label: 'Medan Denai', value: 'Medan Denai' },
    { label: 'Medan Helvetia', value: 'Medan Helvetia' },
    { label: 'Medan Johor', value: 'Medan Johor' },
    { label: 'Medan Kota', value: 'Medan Kota' },
    { label: 'Medan Labuhan', value: 'Medan Labuhan' },
    { label: 'Medan Maimun', value: 'Medan Maimun' },
    { label: 'Medan Marelan', value: 'Medan Marelan' },
    { label: 'Medan Perjuangan', value: 'Medan Perjuangan' },
    { label: 'Medan Petisah', value: 'Medan Petisah' },
    { label: 'Medan Polonia', value: 'Medan Polonia' },
    { label: 'Medan Selayang', value: 'Medan Selayang' },
    { label: 'Medan Sunggal', value: 'Medan Sunggal' },
    { label: 'Medan Tembung', value: 'Medan Tembung' },
    { label: 'Medan Timur', value: 'Medan Timur' },
    { label: 'Medan Tuntungan', value: 'Medan Tuntungan' }
  ],
  Nias: [
    { label: 'Bawolato', value: 'Bawolato' },
    { label: 'Botomuzoi', value: 'Botomuzoi' },
    { label: 'Gido', value: 'Gido' },
    {
      label: 'Hili Serangkai (Hilisaranggu)',
      value: 'Hili Serangkai (Hilisaranggu)'
    },
    { label: 'Hiliduho', value: 'Hiliduho' },
    { label: 'Idano Gawo', value: 'Idano Gawo' },
    { label: "Ma'u", value: "Ma'u" },
    { label: 'Sogae Adu (Sogaeadu)', value: 'Sogae Adu (Sogaeadu)' },
    { label: 'Somolo-Molo (Samolo)', value: 'Somolo-Molo (Samolo)' },
    { label: 'Ulugawo', value: 'Ulugawo' }
  ],
  'Nias Barat': [
    { label: 'Lahomi (Gahori)', value: 'Lahomi (Gahori)' },
    { label: 'Lolofitu Moi', value: 'Lolofitu Moi' },
    { label: 'Mandrehe', value: 'Mandrehe' },
    { label: 'Mandrehe Barat', value: 'Mandrehe Barat' },
    { label: 'Mandrehe Utara', value: 'Mandrehe Utara' },
    { label: "Moro'o", value: "Moro'o" },
    { label: 'Sirombu', value: 'Sirombu' },
    { label: "Ulu Moro'o (Ulu Narwo)", value: "Ulu Moro'o (Ulu Narwo)" }
  ],
  'Nias Selatan': [
    { label: 'Amandraya', value: 'Amandraya' },
    { label: 'Aramo', value: 'Aramo' },
    { label: 'Boronadu', value: 'Boronadu' },
    { label: 'Fanayama', value: 'Fanayama' },
    { label: 'Gomo', value: 'Gomo' },
    { label: 'Hibala', value: 'Hibala' },
    { label: 'Hilimegai', value: 'Hilimegai' },
    {
      label: "Hilisalawa'ahe (Hilisalawaahe)",
      value: "Hilisalawa'ahe (Hilisalawaahe)"
    },
    { label: 'Huruna', value: 'Huruna' },
    { label: 'Lahusa', value: 'Lahusa' },
    { label: 'Lolomatua', value: 'Lolomatua' },
    { label: 'Lolowau', value: 'Lolowau' },
    { label: 'Maniamolo', value: 'Maniamolo' },
    { label: 'Mazino', value: 'Mazino' },
    { label: 'Mazo', value: 'Mazo' },
    { label: "O'o'u (Oou)", value: "O'o'u (Oou)" },
    { label: 'Onohazumba', value: 'Onohazumba' },
    { label: 'Pulau-Pulau Batu', value: 'Pulau-Pulau Batu' },
    { label: 'Pulau-Pulau Batu Barat', value: 'Pulau-Pulau Batu Barat' },
    { label: 'Pulau-Pulau Batu Timur', value: 'Pulau-Pulau Batu Timur' },
    { label: 'Pulau-Pulau Batu Utara', value: 'Pulau-Pulau Batu Utara' },
    { label: "Sidua'ori", value: "Sidua'ori" },
    { label: 'Simuk', value: 'Simuk' },
    { label: 'Somambawa', value: 'Somambawa' },
    { label: 'Susua', value: 'Susua' },
    { label: 'Tanah Masa', value: 'Tanah Masa' },
    { label: 'Teluk Dalam', value: 'Teluk Dalam' },
    { label: 'Toma', value: 'Toma' },
    { label: 'Ulunoyo', value: 'Ulunoyo' },
    { label: 'Ulususua', value: 'Ulususua' },
    { label: 'Umbunasi', value: 'Umbunasi' }
  ],
  'Nias Utara': [
    { label: 'Afulu', value: 'Afulu' },
    { label: 'Alasa', value: 'Alasa' },
    { label: 'Alasa Talumuzoi', value: 'Alasa Talumuzoi' },
    { label: 'Lahewa', value: 'Lahewa' },
    { label: 'Lahewa Timur', value: 'Lahewa Timur' },
    { label: 'Lotu', value: 'Lotu' },
    { label: 'Namohalu Esiwa', value: 'Namohalu Esiwa' },
    { label: 'Sawo', value: 'Sawo' },
    { label: 'Sitolu Ori', value: 'Sitolu Ori' },
    { label: 'Tugala Oyo', value: 'Tugala Oyo' },
    { label: 'Tuhemberua', value: 'Tuhemberua' }
  ],
  'Padang Lawas': [
    { label: 'Aek Nabara Barumun', value: 'Aek Nabara Barumun' },
    { label: 'Barumun', value: 'Barumun' },
    { label: 'Barumun Selatan', value: 'Barumun Selatan' },
    { label: 'Barumun Tengah', value: 'Barumun Tengah' },
    { label: 'Batang Lubu Sutam', value: 'Batang Lubu Sutam' },
    { label: 'Huristak', value: 'Huristak' },
    { label: 'Huta Raja Tinggi', value: 'Huta Raja Tinggi' },
    { label: 'Lubuk Barumun', value: 'Lubuk Barumun' },
    { label: 'Sihapas Barumun', value: 'Sihapas Barumun' },
    { label: 'Sosa', value: 'Sosa' },
    { label: 'Sosopan', value: 'Sosopan' },
    { label: 'Ulu Barumun', value: 'Ulu Barumun' }
  ],
  'Padang Lawas Utara': [
    { label: 'Batang Onang', value: 'Batang Onang' },
    { label: 'Dolok', value: 'Dolok' },
    { label: 'Dolok Sigompulon', value: 'Dolok Sigompulon' },
    { label: 'Halongonan', value: 'Halongonan' },
    { label: 'Hulu Sihapas', value: 'Hulu Sihapas' },
    { label: 'Padang Bolak', value: 'Padang Bolak' },
    { label: 'Padang Bolak Julu', value: 'Padang Bolak Julu' },
    { label: 'Portibi', value: 'Portibi' },
    { label: 'Simangambat', value: 'Simangambat' }
  ],
  'Padang Sidempuan': [
    {
      label: 'Padang Sidempuan Angkola Julu',
      value: 'Padang Sidempuan Angkola Julu'
    },
    {
      label: 'Padang Sidempuan Batunadua',
      value: 'Padang Sidempuan Batunadua'
    },
    {
      label: 'Padang Sidempuan Hutaimbaru',
      value: 'Padang Sidempuan Hutaimbaru'
    },
    {
      label: 'Padang Sidempuan Selatan',
      value: 'Padang Sidempuan Selatan'
    },
    {
      label: 'Padang Sidempuan Tenggara',
      value: 'Padang Sidempuan Tenggara'
    },
    {
      label: 'Padang Sidempuan Utara (Padangsidimpuan)',
      value: 'Padang Sidempuan Utara (Padangsidimpuan)'
    }
  ],
  'Pakpak Bharat': [
    { label: 'Kerajaan', value: 'Kerajaan' },
    { label: 'Pagindar', value: 'Pagindar' },
    {
      label: 'Pergetteng Getteng Sengkut',
      value: 'Pergetteng Getteng Sengkut'
    },
    { label: 'Salak', value: 'Salak' },
    { label: 'Siempat Rube', value: 'Siempat Rube' },
    { label: 'Sitellu Tali Urang Jehe', value: 'Sitellu Tali Urang Jehe' },
    { label: 'Sitellu Tali Urang Julu', value: 'Sitellu Tali Urang Julu' },
    { label: 'Tinada', value: 'Tinada' }
  ],
  'Pematang Siantar': [
    { label: 'Siantar Barat', value: 'Siantar Barat' },
    { label: 'Siantar Marihat', value: 'Siantar Marihat' },
    { label: 'Siantar Marimbun', value: 'Siantar Marimbun' },
    { label: 'Siantar Martoba', value: 'Siantar Martoba' },
    { label: 'Siantar Selatan', value: 'Siantar Selatan' },
    { label: 'Siantar Sitalasari', value: 'Siantar Sitalasari' },
    { label: 'Siantar Timur', value: 'Siantar Timur' },
    { label: 'Siantar Utara', value: 'Siantar Utara' }
  ],
  Samosir: [
    { label: 'Harian', value: 'Harian' },
    { label: 'Nainggolan', value: 'Nainggolan' },
    { label: 'Onan Runggu', value: 'Onan Runggu' },
    { label: 'Palipi', value: 'Palipi' },
    { label: 'Pangururan', value: 'Pangururan' },
    { label: 'Ronggur Nihuta', value: 'Ronggur Nihuta' },
    { label: 'Sianjur Mula Mula', value: 'Sianjur Mula Mula' },
    { label: 'Simanindo', value: 'Simanindo' },
    { label: 'Sitio-tio', value: 'Sitio-tio' }
  ],
  'Serdang Bedagai': [
    { label: 'Bandar Khalifah', value: 'Bandar Khalifah' },
    { label: 'Bintang Bayu', value: 'Bintang Bayu' },
    { label: 'Dolok Masihul', value: 'Dolok Masihul' },
    { label: 'Dolok Merawan', value: 'Dolok Merawan' },
    { label: 'Kotarih', value: 'Kotarih' },
    { label: 'Pantai Cermin', value: 'Pantai Cermin' },
    { label: 'Pegajahan', value: 'Pegajahan' },
    { label: 'Perbaungan', value: 'Perbaungan' },
    { label: 'Sei Bamban', value: 'Sei Bamban' },
    { label: 'Sei Rampah', value: 'Sei Rampah' },
    { label: 'Serba Jadi', value: 'Serba Jadi' },
    { label: 'Silinda', value: 'Silinda' },
    { label: 'Sipispis', value: 'Sipispis' },
    { label: 'Tanjung Beringin', value: 'Tanjung Beringin' },
    { label: 'Tebing Syahbandar', value: 'Tebing Syahbandar' },
    { label: 'Tebing Tinggi', value: 'Tebing Tinggi' },
    { label: 'Teluk Mengkudu', value: 'Teluk Mengkudu' }
  ],
  Sibolga: [
    { label: 'Sibolga Kota', value: 'Sibolga Kota' },
    { label: 'Sibolga Sambas', value: 'Sibolga Sambas' },
    { label: 'Sibolga Selatan', value: 'Sibolga Selatan' },
    { label: 'Sibolga Utara', value: 'Sibolga Utara' }
  ],
  Simalungun: [
    { label: 'Bandar', value: 'Bandar' },
    { label: 'Bandar Huluan', value: 'Bandar Huluan' },
    { label: 'Bandar Masilam', value: 'Bandar Masilam' },
    { label: 'Bosar Maligas', value: 'Bosar Maligas' },
    { label: 'Dolok Batu Nanggar', value: 'Dolok Batu Nanggar' },
    { label: 'Dolok Panribuan', value: 'Dolok Panribuan' },
    { label: 'Dolok Pardamean', value: 'Dolok Pardamean' },
    { label: 'Dolok Silau', value: 'Dolok Silau' },
    { label: 'Girsang Sipangan Bolon', value: 'Girsang Sipangan Bolon' },
    { label: 'Gunung Malela', value: 'Gunung Malela' },
    { label: 'Gunung Maligas', value: 'Gunung Maligas' },
    { label: 'Haranggaol Horison', value: 'Haranggaol Horison' },
    { label: 'Hatonduhan', value: 'Hatonduhan' },
    { label: 'Huta Bayu Raja', value: 'Huta Bayu Raja' },
    { label: 'Jawa Maraja Bah Jambi', value: 'Jawa Maraja Bah Jambi' },
    { label: 'Jorlang Hataran', value: 'Jorlang Hataran' },
    { label: 'Panei', value: 'Panei' },
    { label: 'Panombeian Panei', value: 'Panombeian Panei' },
    { label: 'Pematang Bandar', value: 'Pematang Bandar' },
    { label: 'Pematang Sidamanik', value: 'Pematang Sidamanik' },
    { label: 'Pematang Silima Huta', value: 'Pematang Silima Huta' },
    { label: 'Purba', value: 'Purba' },
    { label: 'Raya', value: 'Raya' },
    { label: 'Raya Kahean', value: 'Raya Kahean' },
    { label: 'Siantar', value: 'Siantar' },
    { label: 'Sidamanik', value: 'Sidamanik' },
    { label: 'Silimakuta', value: 'Silimakuta' },
    { label: 'Silou Kahean', value: 'Silou Kahean' },
    { label: 'Tanah Jawa', value: 'Tanah Jawa' },
    { label: 'Tapian Dolok', value: 'Tapian Dolok' },
    { label: 'Ujung Padang', value: 'Ujung Padang' }
  ],
  'Tanjung Balai': [
    { label: 'Datuk Bandar', value: 'Datuk Bandar' },
    { label: 'Datuk Bandar Timur', value: 'Datuk Bandar Timur' },
    { label: 'Sei Tualang Raso', value: 'Sei Tualang Raso' },
    { label: 'Tanjung Balai Selatan', value: 'Tanjung Balai Selatan' },
    { label: 'Tanjung Balai Utara', value: 'Tanjung Balai Utara' },
    { label: 'Teluk Nibung', value: 'Teluk Nibung' }
  ],
  'Tapanuli Selatan': [
    { label: 'Aek Bilah', value: 'Aek Bilah' },
    { label: 'Angkola Barat', value: 'Angkola Barat' },
    { label: 'Angkola Sangkunur', value: 'Angkola Sangkunur' },
    { label: 'Angkola Selatan', value: 'Angkola Selatan' },
    { label: 'Angkola Timur', value: 'Angkola Timur' },
    { label: 'Arse', value: 'Arse' },
    { label: 'Batang Angkola', value: 'Batang Angkola' },
    { label: 'Batang Toru', value: 'Batang Toru' },
    { label: 'Marancar', value: 'Marancar' },
    { label: 'Muara Batang Toru', value: 'Muara Batang Toru' },
    { label: 'Saipar Dolok Hole', value: 'Saipar Dolok Hole' },
    { label: 'Sayur Matinggi', value: 'Sayur Matinggi' },
    { label: 'Sipirok', value: 'Sipirok' },
    { label: 'Tano Tombangan Angkola', value: 'Tano Tombangan Angkola' }
  ],
  'Tapanuli Tengah': [
    { label: 'Andam Dewi', value: 'Andam Dewi' },
    { label: 'Badiri', value: 'Badiri' },
    { label: 'Barus', value: 'Barus' },
    { label: 'Barus Utara', value: 'Barus Utara' },
    { label: 'Kolang', value: 'Kolang' },
    { label: 'Lumut', value: 'Lumut' },
    { label: 'Manduamas', value: 'Manduamas' },
    { label: 'Pandan', value: 'Pandan' },
    { label: 'Pasaribu Tobing', value: 'Pasaribu Tobing' },
    { label: 'Pinangsori', value: 'Pinangsori' },
    { label: 'Sarudik', value: 'Sarudik' },
    { label: 'Sibabangun', value: 'Sibabangun' },
    { label: 'Sirandorung', value: 'Sirandorung' },
    { label: 'Sitahuis', value: 'Sitahuis' },
    { label: 'Sorkam', value: 'Sorkam' },
    { label: 'Sorkam Barat', value: 'Sorkam Barat' },
    { label: 'Sosor Gadong', value: 'Sosor Gadong' },
    { label: 'Suka Bangun', value: 'Suka Bangun' },
    { label: 'Tapian Nauli', value: 'Tapian Nauli' },
    { label: 'Tukka', value: 'Tukka' }
  ],
  'Tapanuli Utara': [
    { label: 'Adian Koting', value: 'Adian Koting' },
    { label: 'Garoga', value: 'Garoga' },
    { label: 'Muara', value: 'Muara' },
    { label: 'Pagaran', value: 'Pagaran' },
    { label: 'Pahae Jae', value: 'Pahae Jae' },
    { label: 'Pahae Julu', value: 'Pahae Julu' },
    { label: 'Pangaribuan', value: 'Pangaribuan' },
    { label: 'Parmonangan', value: 'Parmonangan' },
    { label: 'Purbatua', value: 'Purbatua' },
    { label: 'Siatas Barita', value: 'Siatas Barita' },
    { label: 'Siborong-Borong', value: 'Siborong-Borong' },
    { label: 'Simangumban', value: 'Simangumban' },
    { label: 'Sipahutar', value: 'Sipahutar' },
    { label: 'Sipoholon', value: 'Sipoholon' },
    { label: 'Tarutung', value: 'Tarutung' }
  ],
  'Tebing Tinggi': [
    { label: 'Bajenis', value: 'Bajenis' },
    { label: 'Padang Hilir', value: 'Padang Hilir' },
    { label: 'Padang Hulu', value: 'Padang Hulu' },
    { label: 'Rambutan', value: 'Rambutan' },
    { label: 'Tebing Tinggi Kota', value: 'Tebing Tinggi Kota' }
  ],
  'Toba Samosir': [
    { label: 'Ajibata', value: 'Ajibata' },
    { label: 'Balige', value: 'Balige' },
    { label: 'Bonatua Lunasi', value: 'Bonatua Lunasi' },
    { label: 'Bor-Bor', value: 'Bor-Bor' },
    { label: 'Habinsaran', value: 'Habinsaran' },
    { label: 'Laguboti', value: 'Laguboti' },
    { label: 'Lumban Julu', value: 'Lumban Julu' },
    { label: 'Nassau', value: 'Nassau' },
    { label: 'Parmaksian', value: 'Parmaksian' },
    { label: 'Pintu Pohan Meranti', value: 'Pintu Pohan Meranti' },
    { label: 'Porsea', value: 'Porsea' },
    { label: 'Siantar Narumonda', value: 'Siantar Narumonda' },
    { label: 'Sigumpar', value: 'Sigumpar' },
    { label: 'Silaen', value: 'Silaen' },
    { label: 'Tampahan', value: 'Tampahan' },
    { label: 'Uluan', value: 'Uluan' }
  ]
}
